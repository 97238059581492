export const AdminDashboardSelections = [
  {
    label: 'Groups',
    value: 'GROUPS',
  },
  {
    label: 'Users',
    value: 'USERS',
  },
  {
    label: 'Reels',
    value: 'REELS',
  },
  {
    label: 'Videos',
    value: 'VIDEOS',
  },
  {
    label: 'Documents',
    value: 'DOCUMENTS',
  },
  {
    label: 'Assessments',
    value: 'ASSESSMENTS',
  },
];

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-dropdown';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useApi from '../../../../Hooks/useApi';
import {
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CHANGE_USER_STATUS_REQUEST,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_FAIL,
  DOWNLOAD_USERS_LIST_SUCCESS,
  FETCH_USERS_SUCCESS,
  FETCH_GROUPS_ROLES_BY_USERS_SUCCESS,
} from '../../../../redux/actionTypes/createUser';
import { FETCH_GROUPS_BY_USER_SUCCESS } from '../../../../redux/actionTypes/Group';

import person from '../../../../assets/images/svg-images/icon-person.svg';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import Loader from '../../../../Components/Loader/loader';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import {
  getYearAndFullMonthNameFromDate,
  mergeTwoArraysByUniqueKey,
} from '../../../../Helper/SystemManager';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import { ToolTipConfig } from '../../../../Common/ToolTipConfig';
import AdminDashboardSelectionDropDown from '../../../../Components/Admin/AdminDashboardSelectionDropDown';
import useReporting from '../../../../Hooks/useReporting';
import ProceedMessageModal from '../../../../Components/Modal/ProceedMessageModal';
import PaginationView from '../../../../Components/Pagination/PaginationView';
import NoContent from '../../../../Components/NoContent/NoContent';
import useIsSuperAdmin from '../../../../Hooks/useIsSuperAdmin';

const PAGE_SIZE = 36;

export default function SearchUser({ history }) {
  const { t } = useTranslation();
  const [isSuperAdmin] = useIsSuperAdmin();
  const canEditUser = usePrivilegesApi(PrivilegeTypes.USER.EDIT_USER);
  const canCreateUser = usePrivilegesApi(PrivilegeTypes.USER.CREATE_USER);
  const canSuspendeUser = usePrivilegesApi(PrivilegeTypes.USER.SUSPEND_USER);
  const canDeleteUser = usePrivilegesApi(PrivilegeTypes.USER.DELETE_USER);
  const canViewUserRequests = usePrivilegesApi(
    PrivilegeTypes.USER.VIEW_USER_REQUESTS
  );

  const [isGridView, setIsGridView] = useState(true);
  const [searchName, setSearchName] = useState(null);
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUserId, setCurrentUserId] = useState('');
  const [userRows, setUserRows] = useState([]);
  const [filterRoles, setFilterRoles] = useState([]);

  const {
    allUsersData,
    allUsersDataPageCount,
    allUsersTotalCount,
    allUsersDataFetchLoading,
    deleteUserData,
    changeUserStatusData,
    userRolesFetchData = [],
    userRolesFetchLoading,
    usersListDownloadError,
    usersListDownloadLoading,
    userGroupRoles = [],
  } = useSelector(state => state?.user);
  const { emailLabel, userNameLabel, defaultProfileImageUrl } = useSelector(
    state => state?.auth?.themeInfo
  );
  const { userId } = useSelector(state => state?.auth?.userData);
  const { groupsByUser = [], groupsByUserLoading } = useSelector(
    state => state?.group
  );

  const [fetchUsers] = useFetchDataApi();
  const [deleteUserRequest] = useApi();
  const [changeUserStatusRequest] = useApi();
  const [fetchUserRoles] = useFetchDataApi();
  const [fetchGroups] = useFetchDataApi();
  const [fetchGroupRoleData] = useFetchDataApi();
  const [downloadUserReport] = useReporting();
  const dispatch = useDispatch();

  const fetchAllUsersList = () => {
    if (groupsByUser?.length) {
      fetchUsers({
        type: 'FETCH_ALL_USERS_BY_ADMIN',
        paginationData: {
          pageNo: currentPage,
          pageSize: PAGE_SIZE,
        },
        requestParams: {
          searchKeyWord: searchName,
          groupIds: groupsByUser.map(({ _id }) => _id),
          role: selectedUserRole?.value === '-1' ? '' : selectedUserRole?.value,
        },
      });
    }
  };

  const fetchAccessibleGroups = () => {
    fetchGroups({
      type: 'FETCH_GROUPS_BY_ADMIN',
      paginationData: {
        pageNo: 1,
        pageSize: 100,
      },
      requestParams: {
        userRoles: isSuperAdmin ? ['Super Admin'] : ['Admin'],
        userIds: [userId],
      },
    });
  };

  const fetchUserGroupAndRoles = () => {
    fetchGroupRoleData({
      type: 'FETCH_ROLES_AND_GROUPS_BY_USER_ID',
      paginationData: {
        pageNo: 1,
        pageSize: PAGE_SIZE,
      },
      requestParams: {
        userIdsForRoleGroups: allUsersData.map(({ _id }) => _id),
        groupIdsForRoleGroups: groupsByUser.map(({ _id }) => _id),
      },
    });
  };

  useEffect(() => {
    fetchAccessibleGroups();

    return () => {
      dispatch({
        type: FETCH_GROUPS_BY_USER_SUCCESS,
      });
      dispatch({
        type: FETCH_USERS_SUCCESS,
      });
      dispatch({
        type: FETCH_GROUPS_ROLES_BY_USERS_SUCCESS,
      });
    };
  }, []);

  useEffect(() => {
    if (groupsByUser?.length) {
      fetchUserRoles({ type: 'FETCH_USER_ROLES' });
    }
  }, [groupsByUser]);

  useEffect(() => {
    if (userRolesFetchData?.length) {
      const userRoles = [
        { label: t('All'), value: '-1' },
        ...userRolesFetchData
          .filter(userRole => userRole.id !== 4)
          .map(({ name }) => ({
            label: name === 'Admin' ? 'Administrator' : name,
            value: name.toUpperCase(),
          })),
        { label: t('Unassigned'), value: 'UNASSIGNED' },
      ];

      setFilterRoles(userRoles);
      setSelectedUserRole({ label: t('All'), value: '-1' });
    }
  }, [userRolesFetchData]);

  useEffect(() => {
    if (allUsersData?.length) {
      setUserRows(allUsersData);

      fetchUserGroupAndRoles();
    }
  }, [allUsersData]);

  useEffect(() => {
    if (selectedUserRole) {
      setUserRows([]);
      setCurrentPage(1);
      fetchAllUsersList();
    }
  }, [selectedUserRole]);

  useEffect(() => {
    if (filterRoles?.length) {
      setUserRows([]);
      fetchAllUsersList();
    }
  }, [currentPage]);

  useEffect(() => {
    if (filterRoles?.length && searchName === '') {
      setUserRows([]);
      setCurrentPage(1);
      fetchAllUsersList();
    }
  }, [searchName]);

  useEffect(() => {
    if (userGroupRoles?.length) {
      const updatedWithRolesAndGroups = mergeTwoArraysByUniqueKey(
        userRows,
        userGroupRoles,
        '_id'
      );
      setUserRows(updatedWithRolesAndGroups);
    }
  }, [userGroupRoles]);

  useEffect(() => {
    if (changeUserStatusData && currentUserId) {
      const users = userRows.map(user => {
        if (user._id === currentUserId) {
          user['enabled'] = !user.enabled;
        }
        return user;
      });

      setUserRows(users);
    }
  }, [changeUserStatusData]);

  useEffect(() => {
    if (deleteUserData) {
      setIsDeleteConfirmModalOpen(null);
      fetchAllUsersList();
    }
  }, [deleteUserData]);

  const handleDeleteUser = userId => {
    deleteUserRequest(
      `/user/deleteUser?userId=${userId}`,
      DELETE_USER_REQUEST,
      DELETE_USER_SUCCESS,
      DELETE_USER_FAIL,
      null,
      history,
      'POST',
      null,
      'isUserService'
    );

    setCurrentUserId(userId);
  };

  const handleChangeUserStatus = (userId, activeStatus) => {
    changeUserStatusRequest(
      `/user/changeUserState?userId=${userId}&enabled=${activeStatus}`,
      CHANGE_USER_STATUS_REQUEST,
      CHANGE_USER_STATUS_SUCCESS,
      CHANGE_USER_STATUS_FAIL,
      null,
      null,
      'POST',
      null,
      'isUserService'
    );

    setCurrentUserId(userId);
  };

  const handleClickOnUserRow = (event, userId) => {
    if (
      event.target.classList.contains('Dropdown-placeholder') ||
      event.target.classList.contains('Dropdown-control')
    ) {
      event.preventDefault();
    } else {
      history.push(`update-user/${userId}/view`);
    }
  };

  const setAllUserTableDropdownActions = (value, user) => {
    switch (value) {
      case 'Edit':
        history.push(`update-user/${user._id}/edit`);
        break;

      case 'Suspend':
        handleChangeUserStatus(user._id, false);
        break;

      case 'Delete':
        setIsDeleteConfirmModalOpen(user._id);
        break;

      case 'Activate':
        handleChangeUserStatus(user._id, true);
        break;

      default:
        break;
    }
  };

  const renderUserRoles = roles => {
    const roleNames =
      roles && roles.map(role => (role === 'Admin' ? 'Administrator' : role));

    return roleNames && roleNames.length > 0
      ? roleNames.join(', ').toString()
      : '-';
  };

  const downloadUsersList = () => {
    downloadUserReport({
      type: 'USERS_LIST_CSV_DOWNLOAD',
      reportName: 'All Users List',
    });
  };

  const handleSearch = event => {
    if (event?.key === 'Enter' && filterRoles?.length && event?.target?.value !== '') {
      setUserRows([]);
      setCurrentPage(1);
      fetchAllUsersList();
    }
  };

  const renderPageHeader = () => {
    return (
      <div className="all-users-table--page-title">
        <div className="all-users-table--page-selection">
          <AdminDashboardSelectionDropDown
            history={history}
            selected={'USERS'}
          />
        </div>
        <div className="all-users-table--page-settings">
          {usersListDownloadLoading ? (
            <button className="btn btn--primary btn--loader"></button>
          ) : (
            <button
              className="btn btn--primary btn--download"
              onClick={() => downloadUsersList()}
            >
              {t('Download List')}
            </button>
          )}
          <input
            className="form-input form-input--search"
            type="search"
            id="search"
            name="search"
            placeholder={t('Search')}
            value={searchName}
            onKeyDown={handleSearch}
            onChange={event => setSearchName(event.target.value)}
          />
          <Dropdown
            title="Filter By"
            options={filterRoles}
            value={selectedUserRole && selectedUserRole.label}
            onChange={option => setSelectedUserRole(option)}
          />
          {canViewUserRequests && (
            <button
              className="btn btn--tertiary btn--request"
              onClick={() => history.push('/search-users/signup')}
            >
              {t('Requests')}
            </button>
          )}
          {canCreateUser && (
            <button
              className="btn btn--primary btn--createnew"
              onClick={() => history.push('/create-user')}
            >
              {t('Create User')}
            </button>
          )}
          <div
            className={`all-users-table--image grid ${
              isGridView ? 'active' : ''
            }`}
            title={t(ToolTipConfig.general.gridView)}
            onClick={() => setIsGridView(true)}
          />
          <div
            className={`all-users-table--image list ${
              !isGridView ? 'active' : ''
            }`}
            title={t(ToolTipConfig.general.tableView)}
            onClick={() => setIsGridView(false)}
          />
        </div>
      </div>
    );
  };

  const renderGridView = () => {
    const userTypeLabel =
      selectedUserRole && selectedUserRole.value !== '-1'
        ? selectedUserRole.label + 's'
        : t('All Users');

    return (
      <div className="all-users-table--grid">
        <div className="all-users-table--grid-header">
          <p>{userTypeLabel}</p>
          <span>
            <img src={person} className="all-users-table--grid-personimg" />
            {allUsersTotalCount}
            {t(
              ` User${!allUsersTotalCount || allUsersTotalCount > 1 ? 's' : ''}`
            )}
          </span>
        </div>
        <div className="all-users-table--grid-wrapper">
          {userRows.length ? (
            userRows.map((user, index) => (
              <div className="all-users-table--grid-card" key={index}>
                <img
                  src={user.profilePicUrl || defaultProfileImageUrl}
                  className="all-users-table--grid-avatarimg"
                  onError={event => {
                    event.target.onerror = null;
                    event.target.src = defaultProfileImageUrl;
                  }}
                />
                <h5>{user.preferredUserName || user.username}</h5>
                <p>
                  {user.addedDateTime
                    ? 'Joined ' +
                      getYearAndFullMonthNameFromDate(user.addedDateTime)
                    : ''}
                </p>
                <button
                  className="btn btn--primary"
                  onClick={() => history.push(`update-user/${user._id}/view`)}
                >
                  {t('View Details')}
                </button>
              </div>
            ))
          ) : (
            <NoContent message="No Users Found" />
          )}
        </div>
      </div>
    );
  };

  const renderTableHeader = () => {
    return (
      <div className="all-users-table--table-header">
        <div className="all-users-table--table-cell"></div>
        <div className="all-users-table--table-cell">
          <h5>{t(userNameLabel)}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t(emailLabel)}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t('Roles')}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t('Groups')}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t('Status')}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t('Last Logon')}</h5>
        </div>
        {canDeleteUser && canEditUser && canSuspendeUser && (
          <div className="all-users-table--table-cell">
            <h5>{t('Actions')}</h5>
          </div>
        )}
      </div>
    );
  };

  const renderTableContents = () => {
    return (
      <div className="all-users-table--table-body">
        {userRows.length ? (
          userRows.map((user, index) => (
            <div
              className="all-users-table--table-row"
              key={index}
              onClick={event => handleClickOnUserRow(event, user._id)}
            >
              <div className="all-users-table--table-cell">
                <img
                  src={user.profilePicUrl || defaultProfileImageUrl}
                  className="all-users-table--table-avatarimg"
                  onError={event => {
                    event.target.onerror = null;
                    event.target.src = defaultProfileImageUrl;
                  }}
                />
              </div>
              <div className="all-users-table--table-cell">
                <p>{user.preferredUserName || user.username}</p>
              </div>
              <div className="all-users-table--table-cell">
                <p>{user.email}</p>
              </div>
              <div className="all-users-table--table-cell">
                <p>{renderUserRoles(user.availableRoles)}</p>
              </div>
              <div className="all-users-table--table-cell">
                <p>{user.numberOfGroups || '0'}</p>
              </div>
              <div className="all-users-table--table-cell">
                <p>{t(user.enabled ? 'Active' : 'Suspended')}</p>
              </div>
              <div className="all-users-table--table-cell">
                <p>
                  {user.lastActiveDateTime
                    ? moment
                        .utc(user.lastActiveDateTime)
                        .local()
                        .format('hh:mm A | DD/MM/YYYY')
                    : '-'}
                </p>
              </div>
              {canDeleteUser && canEditUser && canSuspendeUser && (
                <div className="all-users-table--table-cell">
                  <Dropdown
                    placeholder={t('Select')}
                    title="Edit"
                    options={[
                      { label: t('Edit'), value: 'Edit' },
                      {
                        label: t(user.enabled ? 'Suspend' : 'Activate'),
                        value: user.enabled ? 'Suspend' : 'Activate',
                      },
                      { label: t('Delete'), value: 'Delete' },
                    ]}
                    onChange={option =>
                      setAllUserTableDropdownActions(option.value, user)
                    }
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <NoContent message="No Users Found" />
        )}
      </div>
    );
  };

  const renderPagination = () => {
    return (
      <div className="all-users-table--table-footer">
        <PaginationView
          pageCount={allUsersDataPageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          currentPage={currentPage}
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
        />
      </div>
    );
  };

  const isLoading =
    allUsersDataFetchLoading || groupsByUserLoading || userRolesFetchLoading;

  return (
    <div className="all-users-table">
      <div
        className={`container loading-screen-parent ${
          isLoading ? 'setheight' : ''
        }`}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="all-users-table--wrapper">
              {renderPageHeader()}
              {isGridView ? (
                renderGridView()
              ) : (
                <div className="all-users-table--table">
                  {renderTableHeader()}
                  {renderTableContents()}
                </div>
              )}
            </div>
            {userRows?.length ? renderPagination() : null}
          </div>
        )}
      </div>
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={messageConfigs.deleteConfirm.title}
          message={messageConfigs.deleteConfirm.user}
          handleSuccess={() => handleDeleteUser(isDeleteConfirmModalOpen)}
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
        />
      )}
      {/* Users list downloading error modal */}
      {usersListDownloadError && (
        <ProceedMessageModal
          isOkayButtonShown={false}
          isSuccess={usersListDownloadError.success}
          message={usersListDownloadError.message}
          handleClose={() =>
            dispatch({
              type: DOWNLOAD_USERS_LIST_SUCCESS,
            })
          }
          handleError={() =>
            dispatch({
              type: DOWNLOAD_USERS_LIST_SUCCESS,
            })
          }
        />
      )}
      {/* Users list downloading error modal */}
    </div>
  );
}

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Notifications from '../../../Components/receiveNotification/notification';
import { useTranslation } from 'react-i18next';
import useFetchDataApi from '../../../Hooks/useFetchDataApi';
import useUpdateApi from '../../../Hooks/useUpdateApi';

const NavNotifDropdown = ({ isNotificationOpen, history }) => {
  const { t } = useTranslation();

  const { userId } = useSelector(state => state?.auth?.userData);
  const { headerNotifications, updateAllNotificatioReadSuccess } = useSelector(
    state => state?.notification
  );

  const [updateAllNotificationsViewed] = useUpdateApi();
  const [getRecentNotifications] = useFetchDataApi();

  useEffect(() => {
    if (isNotificationOpen) {
      getRecentNotifications({
        type: 'RECENT_HEADER_NOTIFICATION',
      });
    }
  }, [userId, isNotificationOpen]);

  useEffect(() => {
    if (updateAllNotificatioReadSuccess) {
      getRecentNotifications({
        type: 'RECENT_HEADER_NOTIFICATION',
      });
    }
  }, [updateAllNotificatioReadSuccess]);

  const handleAllNotificationRead = () => {
    updateAllNotificationsViewed({ type: 'MARK_ALL_AS_READ' });
  };

  return (
    <div className="dropdown">
      <div className={`dropdown-content ${isNotificationOpen ? 'open' : ''}`}>
        <div className="dropdown__header">
          <div className="titles">
            <p>{t('Notifications')}</p>
            <span>
              <span onClick={handleAllNotificationRead}>
                {t('Mark all as read')}
              </span>
              <div
                onClick={() => history.push('/settings/notification')}
                className="icon--settings"
              ></div>
            </span>
          </div>
        </div>
        <div className="dropdown__body">
          <Notifications notificationsList={headerNotifications} />
          <a
            className="view-all"
            onClick={() => history.push('/notifications-all')}
          >
            {t('View All')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavNotifDropdown;

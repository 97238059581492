export const CREATE_REEL_REQUEST = "CREATE_REEL_REQUEST";
export const CREATE_REEL_SUCCESS = "CREATE_REEL_SUCCESS";
export const CREATE_REEL_FAIL = "CREATE_REEL_FAIL";

export const UPDATE_REEL_REQUEST = "UPDATE_REEL_REQUEST";
export const UPDATE_REEL_SUCCESS = "UPDATE_REEL_SUCCESS";
export const UPDATE_REEL_FAIL = "UPDATE_REEL_FAIL";

export const EDIT_REEL_NAME_REQUEST = "EDIT_REEL_NAME_REQUEST";
export const EDIT_REEL_NAME_SUCCESS = "EDIT_REEL_NAME_SUCCESS";
export const EDIT_REEL_NAME_FAIL = "EDIT_REEL_NAME_FAIL";

export const SUSPEND_REEL_REQUEST = "SUSPEND_REEL_REQUEST";
export const SUSPEND_REEL_SUCCESS = "SUSPEND_REEL_SUCCESS";
export const SUSPEND_REEL_FAIL = "SUSPEND_REEL_FAIL";

export const DELETE_REEL_BY_ID_REQUEST = "DELETE_REEL_BY_ID_REQUEST";
export const DELETE_REEL_BY_ID_SUCCESS = "DELETE_REEL_BY_ID_SUCCESS";
export const DELETE_REEL_BY_ID_FAIL = "DELETE_REEL_BY_ID_FAIL";

export const FETCH_REELS_BY_GROUP_REQUEST = "FETCH_REELS_BY_GROUP_REQUEST";
export const FETCH_REELS_BY_GROUP_SUCCESS = "FETCH_REELS_BY_GROUP_SUCCESS";
export const FETCH_REELS_BY_GROUP_FAIL = "FETCH_REELS_BY_GROUP_FAIL";

export const COPY_REEL_REQUEST = "COPY_REEL_REQUEST";
export const COPY_REEL_SUCCESS = "COPY_REEL_SUCCESS";
export const COPY_REEL_FAIL = "COPY_REEL_FAIL";

export const REMOVE_USER_FOR_REEL_REQUEST = "REMOVE_USER_FOR_REEL_REQUEST";
export const REMOVE_USER_FOR_REEL_SUCCESS = "REMOVE_USER_FOR_REEL_SUCCESS";
export const REMOVE_USER_FOR_REEL_FAIL = "REMOVE_USER_FOR_REEL_FAIL";

export const FETCH_ALL_REELS_DATA_REQUEST = "FETCH_ALL_REELS_DATA_REQUEST";
export const FETCH_ALL_REELS_DATA_SUCCESS = "FETCH_ALL_REELS_DATA_SUCCESS";
export const FETCH_ALL_REELS_DATA_FAIL = "FETCH_ALL_REELS_DATA_FAIL";

export const FETCH_DOCUMENT_REELS_BY_GROUP_REQUEST = "FETCH_DOCUMENT_REELS_BY_GROUP_REQUEST";
export const FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS = "FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS";
export const FETCH_DOCUMENT_REELS_BY_GROUP_FAIL = "FETCH_DOCUMENT_REELS_BY_GROUP_FAIL";

export const FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_REQUEST = "FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_REQUEST";
export const FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS = "FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS";
export const FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_FAIL = "FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_FAIL";

export const ADD_CATEGORY_TO_REEL_REQUEST = "ADD_CATEGORY_TO_REEL_REQUEST";
export const ADD_CATEGORY_TO_REEL_SUCCESS = "ADD_CATEGORY_TO_REEL_SUCCESS";
export const ADD_CATEGORY_TO_REEL_FAIL = "ADD_CATEGORY_TO_REEL_FAIL";

export const FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_REQUEST = "FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_REQUEST";
export const FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_SUCCESS = "FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_SUCCESS";
export const FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_FAIL = "FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_FAIL";

export const FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_REQUEST
  = "FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_REQUEST";
export const FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_SUCCESS
  = "FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_SUCCESS";
export const FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_FAIL
  = "FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_FAIL";

export const FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_REQUEST = "FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_REQUEST";
export const FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_SUCCESS = "FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_SUCCESS";
export const FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_FAIL = "FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_FAIL";

export const ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_REQUEST
  = "ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_REQUEST";
export const ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_SUCCESS
  = "ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_SUCCESS";
export const ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_FAIL
  = "ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_FAIL";
export const ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_RESET
  = "ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_RESET";

export const FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_REQUEST = "FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_REQUEST";
export const FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_SUCCESS = "FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_SUCCESS";
export const FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_FAIL = "FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_FAIL";

export const ADD_VIDEOS_TO_CURRENT_CATEGORY_REQUEST = "ADD_VIDEOS_TO_CURRENT_CATEGORY_REQUEST";
export const ADD_VIDEOS_TO_CURRENT_CATEGORY_SUCCESS = "ADD_VIDEOS_TO_CURRENT_CATEGORY_SUCCESS";
export const ADD_VIDEOS_TO_CURRENT_CATEGORY_FAIL = "ADD_VIDEOS_TO_CURRENT_CATEGORY_FAIL";
export const ADD_VIDEOS_TO_CURRENT_CATEGORY_RESET = "ADD_VIDEOS_TO_CURRENT_CATEGORY_RESET";

export const ADD_DOCUMENTS_TO_CURRENT_CATEGORY_REQUEST = "ADD_DOCUMENTS_TO_CURRENT_CATEGORY_REQUEST";
export const ADD_DOCUMENTS_TO_CURRENT_CATEGORY_SUCCESS = "ADD_DOCUMENTS_TO_CURRENT_CATEGORY_SUCCESS";
export const ADD_DOCUMENTS_TO_CURRENT_CATEGORY_FAIL = "ADD_DOCUMENTS_TO_CURRENT_CATEGORY_FAIL";
export const ADD_DOCUMENTS_TO_CURRENT_CATEGORY_RESET = "ADD_DOCUMENTS_TO_CURRENT_CATEGORY_RESET";

export const FETCH_USERS_FOR_ACCESS_CONTROL_REQUEST = "FETCH_USERS_FOR_ACCESS_CONTROL_REQUEST";
export const FETCH_USERS_FOR_ACCESS_CONTROL_SUCCESS = "FETCH_USERS_FOR_ACCESS_CONTROL_SUCCESS";
export const FETCH_USERS_FOR_ACCESS_CONTROL_FAIL = "FETCH_USERS_FOR_ACCESS_CONTROL_FAIL";

export const INCLUDE_EXCLUDE_USERS_TO_REEL_REQUEST = "INCLUDE_EXCLUDE_USERS_TO_REEL_REQUEST";
export const INCLUDE_EXCLUDE_USERS_TO_REEL_SUCCESS = "INCLUDE_EXCLUDE_USERS_TO_REEL_SUCCESS";
export const INCLUDE_EXCLUDE_USERS_TO_REEL_FAIL = "INCLUDE_EXCLUDE_USERS_TO_REEL_FAIL";

export const FETCH_MANDATORY_REELS_BY_GROUP_REQUEST = "FETCH_MANDATORY_REELS_BY_GROUP_REQUEST";
export const FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS = "FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS";
export const FETCH_MANDATORY_REELS_BY_GROUP_FAIL = "FETCH_MANDATORY_REELS_BY_GROUP_FAIL";

export const FETCH_REEL_RENAME_WARNINGS_REQUEST = "FETCH_REEL_RENAME_WARNINGS_REQUEST";
export const FETCH_REEL_RENAME_WARNINGS_SUCCESS = "FETCH_REEL_RENAME_WARNINGS_SUCCESS";
export const FETCH_REEL_RENAME_WARNINGS_FAIL = "FETCH_REEL_RENAME_WARNINGS_FAIL";

import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const SummaryBox = ({ summaries, handleChange, initialObject }) => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('Summary')}</p>
      {summaries.map((summary, index) => {
        const tempSummary = [...summaries];
        return (
          <div
            className="admin__createvideo--summary-inner-container"
            key={index}
            style={{ marginBottom: '10px' }}
          >
            <h4>
              {t('Summary')} {index + 1}
            </h4>
            {summaries.length > 1 ? (
              <span
                className="inner-close-btn"
                onClick={() => {
                  tempSummary.splice(index, 1);
                  handleChange(tempSummary, 'summaries');
                }}
              >
                {' '}
                ×
              </span>
            ) : null}
            <input
              type="text"
              id="upload-summary"
              name="upload-summary"
              placeholder={t('Upload Summary')}
              value={summary.notes}
              onChange={e => {
                tempSummary[index].notes = e.target.value;
                handleChange(tempSummary, 'summaries');
              }}
            />
            <div className="admin__createassessment--placement-time">
              <p>{t('Start Time')}</p>
              <input
                type="number"
                id="starttime min"
                name="starttime min"
                placeholder="0"
                min={0}
                max={59}
                value={summary.startTimeMin}
                onChange={e => {
                  tempSummary[index].startTimeMin = e.target.value;
                  handleChange(tempSummary, 'summaries');
                }}
              />
              <p>{t('min')}</p>
              <input
                type="number"
                id="starttime sec"
                name="starttime sec"
                placeholder="0"
                min={0}
                max={59}
                value={summary.startTimeSec}
                onChange={e => {
                  tempSummary[index].startTimeSec = e.target.value;
                  handleChange(tempSummary, 'summaries');
                }}
              />
              <p>{t('sec')}</p>
            </div>
            <div className="admin__createassessment--placement-time">
              <p>{t('End Time')}</p>
              <input
                type="number"
                id="triggertime min"
                name="triggertime min"
                placeholder="0"
                min={0}
                max={59}
                value={summary.endTimeMin}
                onChange={e => {
                  tempSummary[index].endTimeMin = e.target.value;
                  handleChange(tempSummary, 'summaries');
                }}
              />
              <p>{t('min')}</p>
              <input
                type="number"
                id="triggertime sec"
                name="triggertime sec"
                placeholder="0"
                min={0}
                max={59}
                value={summary.endTimeSec}
                onChange={e => {
                  tempSummary[index].endTimeSec = e.target.value;
                  handleChange(tempSummary, 'summaries');
                }}
              />
              <p>{t('sec')}</p>
            </div>
          </div>
        );
      })}
      <p
        className="addanother"
        onClick={() =>
          handleChange([...summaries, _.clone(initialObject)], 'summaries')
        }
      >
        {t('Add another summary')}
      </p>
    </>
  );
};

export default SummaryBox;

import { systemConfig } from '../Common/SystemConstConfigs';
import { zones } from 'moment-timezone/data/meta/latest.json';

/**
 * Get child groups of the parent
 *
 * @param {array} data
 * @param {string} id
 */
export const getChildGroups = (data, id) => {
  // if id matches _id then return node and all childs.
  if (data._id === id) {
    return data;
  }

  // if node have children array, then looping through it to get the matched childs.
  if (data.children && Array.isArray(data.children)) {
    for (let child of data.children) {
      let result = getChildGroups(child, id);

      if (result) {
        return result;
      }
    }
  }
};

/**
 * Finding parent node ids of current given node id in the node tree
 *
 * @param {array} data
 * @param {string} nodeId
 */
export const findParentNodeIds = (node, nodeId) => {
  if (node._id === nodeId) {
    return [];
  }

  if (Array.isArray(node.children)) {
    for (let data of node.children) {
      const childNode = findParentNodeIds(data, nodeId);

      if (Array.isArray(childNode)) {
        return [node._id].concat(childNode);
      }
    }
  }
};

/**
 * Get all groups for the given user.
 *
 * @param {string} userName
 * @param {array} groupInfo
 * @param {array} allGroupsFetchData
 * @param {boolean} onlyAdminAccess
 */
export const getAllGroupInfoByCurrentUser = (
  userName,
  groupInfo,
  allGroupsFetchData,
  onlyAdminAccess
) => {
  const flattenedGroups = [];

  // Flattening tree nodes to one array
  const flatteningTreeNodes = node => {
    flattenedGroups.push(node);

    // Recursive for all childrens
    node.children && node.children.forEach(flatteningTreeNodes);
  };

  const { superAdmin } = systemConfig.roleIdByName;

  const isSuperAdmin = findRolesList(groupInfo)
    .map(({ id }) => id)
    .includes(superAdmin.roleReferenceId);

  // Checking if user is a superAdmin or not.
  if (!isSuperAdmin) {
    // Filting only Role Admin groups and getting group ids.
    const administratingGroups = groupInfo
      .filter(group => {
        return group.userData.some(roleInfo =>
          onlyAdminAccess
            ? roleInfo.roleReferenceId === 1
            : roleInfo.roleReferenceId === 1 ||
              roleInfo.roleReferenceId === 2 ||
              roleInfo.roleReferenceId === 5
        );
      })
      .map(group => group.divisionId);

    administratingGroups
      .filter((group, index) => {
        return administratingGroups.indexOf(group) === index;
      })
      .forEach(groupId => {
        const parentNode = getChildGroups(allGroupsFetchData, groupId);

        flatteningTreeNodes(parentNode);
      });
  } else {
    flatteningTreeNodes(allGroupsFetchData);
  }

  return flattenedGroups;
};

/**
 * Return organized string date in dd/mm/yyyy format
 *
 * @param {string} stringDate
 */
export const organizeDate = stringDate => {
  let organizedDate = new Date(stringDate);
  let year = organizedDate.getFullYear();
  let month = organizedDate.getMonth() + 1;
  let date = organizedDate.getDate();

  date = date < 10 ? '0' + date : date;
  month = month < 10 ? '0' + month : month;
  organizedDate = date + '/' + month + '/' + year;

  return organizedDate;
};

/**
 * Return organized string time in hh mm am/pm format
 *
 * @param {string} time
 */
export const organizeTimeInAMPM = time => {
  let date = new Date(time);

  return date.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

/**
 * Return number of days added date from today date as string.
 *
 * @param {integer} numberOfDays
 */
export function getNumberOfDaysDateAddedFromToday(numberOfDays) {
  let today = new Date();
  let dateFromToday = new Date(
    today.setDate(today.getDate() + numberOfDays || 0)
  );
  let dd = dateFromToday.getDate();
  let mm = dateFromToday.getMonth() + 1;
  let yyyy = dateFromToday.getFullYear();

  // If date is less than 10 append zero(Eg 01) to infront of the date
  if (dd < 10) {
    dd = '0' + dd;
  }

  // If month is less than 10 append zero(Eg 01) to infront of the month
  if (mm < 10) {
    mm = '0' + mm;
  }

  return `${yyyy}-${mm}-${dd}`;
}

/**
 * Removing a property from the given object
 *
 * @param {object} oldObject
 * @param {string} propertyName
 */
export const removePropertyFromObject = (oldObject, propertyName) => {
  return Object.keys(oldObject).reduce((newObject, key) => {
    if (key !== propertyName) {
      newObject[key] = oldObject[key];
    }

    return newObject;
  }, {});
};

/**
 * Capitilize the first letter of the word
 *
 * @param {string} word
 */
export const capitalizeFirstLetter = word => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return word;
};

/**
 * Get organized data for dropdown multi select in create edit pages
 *
 * @param {array} options
 */
export const getOrganizedListOfReelIdCategoryIdAndGroups = (options, reels) => {
  return options.map(value => {
    const groupNamesAdded = value.groups.map(groupValue => {
      return {
        ...groupValue,
        name: getGroupNameFromInit(groupValue._id, reels),
      };
    });

    return {
      ...value,
      groups: groupNamesAdded,
    };
  });
};

/**
 * Get group name for dropdown multi select in creat edit pages
 *
 * @param {string} groupId
 * @param {array} initReels
 */
export const getGroupNameFromInit = (groupId, initReels) => {
  if (!initReels.length) {
    return '';
  }

  let groupName = '';

  const isValidGroupName = initReels.some(reel => {
    return reel.allGroups.some(group => {
      groupName = group.name;
      return group._id === groupId;
    });
  });

  if (!isValidGroupName) {
    return '';
  }

  return groupName;
};

/**
 * Return organized string date in yyyy month format
 *
 * @param {string} stringDate
 */
export const getYearAndFullMonthNameFromDate = stringDate => {
  const date = new Date(stringDate);
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.toLocaleString('default', { year: 'numeric' });

  return `${year} ${month}`;
};

/**
 * Generate edit item page route
 *
 * @param { isNativeBannerAndFeatured, isBanner }
 */
export const getBannerFeaturedEditRoute = ({
  isNativeBannerAndFeatured,
  isBanner,
}) => {
  return isNativeBannerAndFeatured ? (isBanner ? 'banner/' : 'featured/') : '';
};

/**
 * Find distinct user roles from group info
 *
 * @param {array} groupInfo
 */
export const findRolesList = groupInfo => {
  if (!groupInfo) {
    return [];
  }

  let roleList = groupInfo
    .map(({ userData }) =>
      userData.map(({ roleReferenceId, roleName }) => ({
        id: roleReferenceId,
        roleName,
      }))
    )
    .flat()
    .reduce(
      (role, current) =>
        role.find(value => value.id === current.id)
          ? role
          : role.concat([current]),
      []
    );

  return roleList;
};

/**
 * Comparing two array by a property key and returning differnce of that two.
 *
 * @param {array} objectArray
 * @param {array} differsFrom
 * @param {string} propKey
 */
export const getDifferentTwoObjectArrays = (
  objectArray,
  differsFrom,
  propKey
) => {
  return objectArray.filter(data1 => {
    return !differsFrom.some(data2 => data1[propKey] === data2[propKey]);
  });
};

/**
 * Open urls in new tab or same tab
 *
 * @param {string} url
 * @param {boolean} isSametab
 */
export const openUrlInNewTab = (url, isSametab) => {
  const aTag = document.createElement('a');
  document.body.appendChild(aTag);

  aTag.style = 'display: none';
  aTag.href = url;

  if (!isSametab) {
    aTag.target = '_blank';
  }

  aTag.click();

  document.body.removeChild(aTag);
};

/**
 * Download files
 *
 * @param {*} bufferData
 * @param {string} contentType
 * @param {string} fileName
 */
export const downloadFile = (bufferData, contentType, fileName) => {
  const blob = new Blob([bufferData], { type: contentType });

  let tempFileName = fileName;

  switch (contentType) {
    case 'application/pdf':
      tempFileName += '.pdf';
      break;

    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      tempFileName += '.xlsx';
      break;

    default:
      break;
  }

  const aTag = document.createElement('a');
  document.body.appendChild(aTag);

  aTag.style = 'display: none';
  aTag.href = window.URL.createObjectURL(blob);
  aTag.download = tempFileName;
  aTag.click();

  document.body.removeChild(aTag);
};

/**
 * Convert given time to minutes and seconds
 *
 * @param {integer} time
 */
export const converTimeInMinAndSec = time => {
  const timeInMins = Math.floor(time / 60);
  const timeInSecs = time - timeInMins * 60;

  return { timeInMins, timeInSecs };
};

/**
 * Add leading zero to the one digit number
 *
 * @param {string|Integer} numberLessThanTen
 * @returns {string}
 */
export const addLeadingZero = numberLessThanTen => {
  if (parseInt(numberLessThanTen) >= 10) {
    return numberLessThanTen;
  } else if (
    parseInt(numberLessThanTen) < 10 &&
    parseInt(numberLessThanTen) > 0
  ) {
    return `0${numberLessThanTen}`;
  } else {
    return '0';
  }
};

/**
 * Converts seconds to mm:ss format
 *
 * @param {string} timeInSeconds
 * @returns {string}
 */
export const secondsToMMSS = timeInSeconds => {
  return `${addLeadingZero(
    converTimeInMinAndSec(timeInSeconds).timeInMins
  )}:${addLeadingZero(converTimeInMinAndSec(timeInSeconds).timeInSecs)}`;
};

/**
 * Getting country from time zone.
 *
 * @returns { timeZone, countryName, countryCode}
 */
export const getCountryFromTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const countryCode =
    timeZone && zones[timeZone] && zones[timeZone].countries[0];

  const countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(
    countryCode
  );

  return {
    timeZone,
    countryName,
    countryCode,
  };
};

/**
 * Check if the email is valid or not
 *
 * @param {string} email
 * @returns {boolean}
 */
export const CheckIsValidEmailAddress = email => {
  return !(email === '' || (!email.match(systemConfig.emailRegex) && email));
};

/**
 * Check if the username is valid or not
 *
 * @param {string} value
 * @returns {boolean}
 */
export const CheckIsValidUsername = value => {
  const regex = /^[a-zA-Z0-9]*$/;

  return !(value === '' || !value.match(regex));
};

/**
 * Check if the name is valid or not
 *
 * @param {string} value
 * @returns {boolean}
 */
export const CheckIsValidName = value => {
  const regex = /^([a-zA-Z0-9_-]+\s)*\w+$/;

  return !(value === '' || !value.match(regex));
};

/**
 * Constructing the s3 bucket object url
 *
 * @param {object} urlData
 * @returns {string} url
 */
export const getS3ObjectUrl = urlData => {
  if (!urlData?.bucketName || !urlData?.fileKey) {
    return '';
  }

  const { bucketName, fileKey } = urlData;

  return `https://${bucketName}.s3.amazonaws.com${fileKey}`;
};

/**
 * Get total page count for pagination
 *
 * @param {Integer} totalRecords
 * @param {Integer} pageSize
 * @returns {Integer}
 */
export const getTotalPageCount = (totalRecords, pageSize) => {
  return !isNaN(totalRecords) && !isNaN(pageSize)
    ? Math.ceil(totalRecords / pageSize)
    : 1;
};

/**
 * Merge two array of objects with same key
 *
 * @param {Array} arrayOne
 * @param {Array} arrayTwo
 * @returns {Array}
 */
export const mergeTwoArraysByUniqueKey = (arrayOne, arrayTwo, key) => {
  if (!arrayOne?.length || !arrayTwo?.length || !key) {
    return [];
  }

  let mergedArrray = [];

  for (let i = 0; i < arrayOne.length; i++) {
    mergedArrray.push({
      ...arrayOne[i],
      ...arrayTwo.find(itemInner => itemInner[key] === arrayOne[i][key]),
    });
  }

  return mergedArrray;
};

/**
 * Replace the word character with given string and transform it to camel case
 *
 * @param {string} replaceByCharacter
 * @param {string} replaceableString
 * @returns {string}
 */
export const stringReplaceWithSpaceAndCamelCase = (
  replaceByCharacter,
  replaceableString
) => {
  if (!replaceByCharacter || !replaceableString) {
    return '';
  }

  return replaceableString
    .replaceAll(replaceByCharacter, ' ')
    .replace(
      /(\w)(\w*)/g,
      (_, firstLetter, restOfTheWord) =>
        firstLetter + restOfTheWord.toLowerCase()
    );
};

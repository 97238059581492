import React, { useEffect, useState } from 'react';
// import { DropdownMultiple, Dropdown } from "reactjs-dropdown-component";
import Dropdown from 'react-dropdown';
import moment from 'moment';
import Scrollbar from 'react-smooth-scrollbar';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { api } from '../../settings';

const FeedbackTrainingRequestModal = ({
  onHandleCancel,
  TrainingRequestObj = null,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [trainingRequestObj, setTrainingRequestObj] = useState(null);
  useEffect(() => {
    if (TrainingRequestObj) {
      const tempTrainingRequestObj = { ...TrainingRequestObj };
      if (!tempTrainingRequestObj.feedback) {
        tempTrainingRequestObj.feedback = {
          q1: '',
          q2: '',
          q3: '',
          q4: '',
          q5: '',
          q6: '',
          q7: '',
          comments: '',
          trainingRequestId: tempTrainingRequestObj.id,
        };
      }
      setTrainingRequestObj(tempTrainingRequestObj);
    }
  }, [TrainingRequestObj]);
  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    setTimeout(() => {
      body.className.concat('popup-active');
      setIsActive(true);
    }, 0);
    return () => {
      body.className.replace('popup-active', '');
    };
  }, []);
  const onHandleFeedBackAns = (Answer, question) => {
    let tempTrainingRequestObj = { ...trainingRequestObj };
    tempTrainingRequestObj.feedback[question] = Answer;
    setTrainingRequestObj(tempTrainingRequestObj);
  };

  const quesOneAnsOptions = [
    'Extremely Unsatisfied',
    'Unsatisfied',
    'Neutral',
    'Satisfied',
    'Extremely Satisfied',
  ];
  const quesRemainAnsOptions = ['Yes', 'No'];
  const onHandleSubmitFeedback = () => {
    axios
      .post(`${api}/feedback/save`, {
        ...trainingRequestObj.feedback,
      })
      .then(response => {
        onHandleCancel(trainingRequestObj);
        axios
          .post(
            `${api}/TrainingRequest/statusUpdate`,

            {
              status: 'Completed',
              trainingRequestId: trainingRequestObj.id,
            }
          )
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
    axios
      .post(
        `${api}/TrainingRequest/statusUpdate`,

        {
          status: 'Completed',
          trainingRequestId: trainingRequestObj.id,
        }
      )
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <div
      className={`modal-popup ${isActive ? 'active' : ''}`}
      aria-hidden="true"
    >
      <div className={`modal-popup__dialog feedback dark`}>
        <></>
      </div>
      <div className={`modal-popup__dialog feedback light`}>
        {trainingRequestObj && (
          <>
            <div className="modal-popup__header">
              <h2>{t('Feedback')}</h2>
              <a
                href="javascript:void(0)"
                className="btn-close closemodale"
                aria-hidden="true"
                onClick={() => onHandleCancel(null)}
              >
                &times;
              </a>
            </div>
            <div className="modal-popup__banner">
              <div className="feedback-popup__column">
                <h3>
                  {trainingRequestObj.userEmail.replace('@gmail.com', '')}
                  <span className="feedback-popup__designation">
                    {trainingRequestObj.group}
                  </span>
                </h3>
                <p className="feedback-popup__info">
                  {t('Date')}:
                  <span>
                    {moment(trainingRequestObj.preferredDate).format(
                      'DD-MM-YYYY'
                    )}
                  </span>
                </p>
              </div>
              <div className="feedback-popup__column">
                <p className="feedback-popup__info">
                  {t('Title')}:<span>{trainingRequestObj.trainingTitle}</span>
                </p>
                <p className="feedback-popup__info">
                  {t('Cost')}:<span>${trainingRequestObj.cost}</span>
                </p>
                <p className="feedback-popup__info">
                  {t('Reason for Request?')}
                  <span>{trainingRequestObj.reasonForRequest}</span>
                </p>
              </div>
              <div className="feedback-popup__column">
                <p className="feedback-popup__info">
                  {t('Remark:')}
                  <span>{trainingRequestObj.remarks}</span>
                </p>
              </div>
            </div>
            <div className="modal-popup__body">
              <p>
                {t(
                  'We would love to hear your thoughts, concerns or problems with anything so we can improve!'
                )}
              </p>
              <Scrollbar className="feedback__scrollbar">
                <div className="feedback-popup__row">
                  <label>{t('How satisfied are you with the training?')}</label>
                  <Dropdown
                    options={quesOneAnsOptions}
                    onChange={Answer => {
                      onHandleFeedBackAns(Answer.value, 'q1');
                    }}
                    value={
                      trainingRequestObj.feedback
                        ? trainingRequestObj.feedback.q1
                        : ''
                    }
                    placeholder={t('Select an option')}
                    disabled={
                      trainingRequestObj.feedback &&
                      trainingRequestObj.feedback._id
                    }
                  />
                </div>
                <div className="feedback-popup__row">
                  <label>
                    {t('Was the trainer skilled in delivering the training?')}
                  </label>
                  <Dropdown
                    options={quesRemainAnsOptions}
                    onChange={Answer => {
                      onHandleFeedBackAns(Answer.value, 'q2');
                    }}
                    value={
                      trainingRequestObj.feedback
                        ? trainingRequestObj.feedback.q2
                        : ''
                    }
                    placeholder={t('Select an option')}
                    disabled={
                      trainingRequestObj.feedback &&
                      trainingRequestObj.feedback._id
                    }
                  />
                </div>
                <div className="feedback-popup__row">
                  <label>
                    {t(
                      'Was the material helpful in understanding the concepts?'
                    )}
                  </label>
                  <Dropdown
                    options={quesRemainAnsOptions}
                    onChange={Answer => {
                      onHandleFeedBackAns(Answer.value, 'q3');
                    }}
                    value={
                      trainingRequestObj.feedback
                        ? trainingRequestObj.feedback.q3
                        : ''
                    }
                    placeholder={t('Select an option')}
                    disabled={
                      trainingRequestObj.feedback &&
                      trainingRequestObj.feedback._id
                    }
                  />
                </div>
                <div className="feedback-popup__row">
                  <label>
                    {t(
                      'Are you able to undertake the job at hand better due to the training?'
                    )}
                  </label>
                  <Dropdown
                    options={quesRemainAnsOptions}
                    onChange={Answer => {
                      onHandleFeedBackAns(Answer.value, 'q4');
                    }}
                    value={
                      trainingRequestObj.feedback
                        ? trainingRequestObj.feedback.q4
                        : ''
                    }
                    placeholder={t('Select an option')}
                    disabled={
                      trainingRequestObj.feedback &&
                      trainingRequestObj.feedback._id
                    }
                  />
                </div>
                <div className="feedback-popup__row">
                  <label>
                    {t('Was the time allocated enough for the training?')}
                  </label>
                  <Dropdown
                    options={quesRemainAnsOptions}
                    onChange={Answer => {
                      onHandleFeedBackAns(Answer.value, 'q5');
                    }}
                    value={
                      trainingRequestObj.feedback
                        ? trainingRequestObj.feedback.q5
                        : ''
                    }
                    placeholder={t('Select an option')}
                    disabled={
                      trainingRequestObj.feedback &&
                      trainingRequestObj.feedback._id
                    }
                  />
                </div>
                <div className="feedback-popup__row">
                  <label>
                    {t('Were the facilities given at the training sufficient?')}
                  </label>
                  <Dropdown
                    options={quesRemainAnsOptions}
                    onChange={Answer => {
                      onHandleFeedBackAns(Answer.value, 'q6');
                    }}
                    value={
                      trainingRequestObj.feedback
                        ? trainingRequestObj.feedback.q6
                        : ''
                    }
                    placeholder={t('Select an option')}
                    disabled={
                      trainingRequestObj.feedback &&
                      trainingRequestObj.feedback._id
                    }
                  />
                </div>
                <div className="feedback-popup__row">
                  <label>
                    {t('Will you recommend the training to another colleague?')}
                  </label>
                  <Dropdown
                    options={quesRemainAnsOptions}
                    onChange={Answer => {
                      onHandleFeedBackAns(Answer.value, 'q7');
                    }}
                    value={
                      trainingRequestObj.feedback
                        ? trainingRequestObj.feedback.q7
                        : ''
                    }
                    placeholder={t('Select an option')}
                    disabled={
                      trainingRequestObj.feedback &&
                      trainingRequestObj.feedback._id
                    }
                  />
                </div>
                <div className="feedback-popup__row">
                  <label>{t('Comments')}</label>
                  <textarea
                    rows="4"
                    cols="50"
                    onChange={e => {
                      onHandleFeedBackAns(e.target.value, 'comments');
                    }}
                    value={
                      trainingRequestObj.feedback
                        ? trainingRequestObj.feedback.comments || ''
                        : ''
                    }
                    placeholder={t('Comment')}
                    disabled={
                      trainingRequestObj.feedback &&
                      trainingRequestObj.feedback._id
                    }
                  />
                </div>
              </Scrollbar>
              <div className="feedback-popup__buttons">
                <button
                  className="btn btn--secondary"
                  onClick={() => onHandleCancel(null)}
                >
                  {t('Cancel')}
                </button>
                {(!trainingRequestObj.feedback ||
                  (trainingRequestObj.feedback &&
                    !trainingRequestObj.feedback._id)) && (
                  <button
                    className="btn btn--primary"
                    onClick={onHandleSubmitFeedback}
                  >
                    {t('Send')}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackTrainingRequestModal;

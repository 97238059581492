import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import {
  FETCH_VIDEO_STATS_BY_GROUP_REQUEST,
  FETCH_VIDEO_STATS_BY_GROUP_SUCCESS,
  FETCH_VIDEO_STATS_BY_GROUP_FAIL,
  DOWNLOAD_STATS_SUCCESS,
  FETCH_VIDEO_STATS_BY_GROUP_RESET,
} from '../../../redux/actionTypes/video';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import BreadCrumbView from '../../../Components/BreadCrumbView';
import Loader from '../../../Components/Loader/loader';
import useReporting from '../../../Hooks/useReporting';
import ProceedMessageModal from '../../../Components/Modal/ProceedMessageModal';

export default function VideoStats({
  history,
  groupId,
  match,
  groupDetailsData,
}) {
  const { t } = useTranslation();
  const { reelId, videoAssessmentId } = match.params;
  const { groupName = null, parentGroupName = null } = groupDetailsData || {};

  const [searchName, setSearchName] = useState('');
  const [statsTableData, setStatsTableData] = useState([]);
  const [statsCardData, setStatsCardData] = useState({
    reelName: '',
    videoTitle: '',
    totalUsers: '',
    totalViews: '',
    totalReels: '',
    createdDate: '',
    totalGroups: '',
    publishedDate: '',
  });

  const {
    videoStatsByGroupData,
    videoStatsByGroupLoading,
    downloadingStatsLoading,
    downloadingStatsError,
  } = useSelector(state => state?.video);
  const [fetchVideoStatsData] = useApi();
  const [downloadStatsCSV] = useReporting();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch({ type: FETCH_VIDEO_STATS_BY_GROUP_RESET });
  }, []);

  useEffect(() => {
    let videoStatsUrl = `/video/get-video-stats-by-group?videoId=${videoAssessmentId}&reelId=${reelId}`;

    if (groupId) {
      videoStatsUrl += `&groupId=${groupId}`;
    }

    fetchVideoStatsData(
      videoStatsUrl,
      FETCH_VIDEO_STATS_BY_GROUP_REQUEST,
      FETCH_VIDEO_STATS_BY_GROUP_SUCCESS,
      FETCH_VIDEO_STATS_BY_GROUP_FAIL,
      null,
      '',
      'GET',
      null,
      'isReelService'
    );
  }, [groupId, reelId, videoAssessmentId]);

  useEffect(() => {
    if (videoStatsByGroupData) {
      const {
        createdDate,
        publishedDate,
        reelName,
        totalUsers,
        totalViews,
        videoTitle,
        userList,
        totalGroups,
        totalReels,
      } = videoStatsByGroupData;

      let tempStatsCardData = { ...statsCardData };

      tempStatsCardData = {
        ...tempStatsCardData,
        reelName,
        totalViews,
        totalUsers,
        createdDate,
        totalGroups,
        videoTitle,
        totalReels,
        publishedDate,
      };

      setStatsCardData(tempStatsCardData);
      setStatsTableData(userList);
    }
  }, [videoStatsByGroupData]);

  const renderStatsCardView = () => {
    return (
      <>
        {!groupId && (
          <>
            <div className="reel-view--page-data-cell">
              <p>{t('Total Groups')}</p>
              <span>{statsCardData.totalGroups}</span>
            </div>
            <div className="reel-view--page-data-cell">
              <p>{t('Total Reels')}</p>
              <span>{statsCardData.totalReels}</span>
            </div>
          </>
        )}
        <div className="reel-view--page-data-cell">
          <p>{t('Total Views')}</p>
          <span>{statsCardData.totalViews}</span>
        </div>
        {groupId && (
          <div className="reel-view--page-data-cell">
            <p>{t('Total Users')}</p>
            <span>{statsCardData.totalUsers}</span>
          </div>
        )}
        <div className="reel-view--page-data-cell">
          <p>{t('Created Date')}</p>
          <span>
            {statsCardData.createdDate
              ? moment
                  .utc(statsCardData.createdDate)
                  .local()
                  .format('DD/MM/YYYY')
              : '-'}
          </span>
        </div>
        <div className="reel-view--page-data-cell">
          <p>{t('Published Date')}</p>
          <span>
            {statsCardData.publishedDate
              ? moment
                  .utc(statsCardData.publishedDate)
                  .local()
                  .format('DD/MM/YYYY')
              : moment
                  .utc(statsCardData.createdDate)
                  .local()
                  .format('DD/MM/YYYY')}
          </span>
        </div>
      </>
    );
  };

  const rendStatsTableRowsView = () => {
    return statsTableData
      .filter(
        ({ username }) =>
          !searchName ||
          username?.toUpperCase().includes(searchName.toUpperCase())
      )
      .map((rowData, index) => {
        const { username, date, views, status, score, spentTime } = rowData;

        return (
          <div className="all-users-table--table-row" key={index}>
            <div className="all-users-table--table-cell">
              <p>{username}</p>
            </div>
            <div className="all-users-table--table-cell">
              <p>
                {date
                  ? moment.utc(date).local().format('hh:mm A | DD/MM/YYYY')
                  : '-'}
              </p>
            </div>
            <div className="all-users-table--table-cell">
              <p>{status}</p>
            </div>
            <div className="all-users-table--table-cell">
              <p>{views || '0'}</p>
            </div>
            <div className="all-users-table--table-cell">
              <p>{score && score !== '' ? score : '-'}</p>
            </div>
            <div className="all-users-table--table-cell">
              <p>{spentTime && spentTime !== '' ? spentTime : '-'}</p>
            </div>
          </div>
        );
      });
  };

  const downloadCsv = () => {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    downloadStatsCSV({
      type: 'DOWNLOAD_VIDEO_STATS',
      urlParams: {
        videoId: videoAssessmentId,
        reelId,
        groupId,
        zone,
      },
      reportName: `video-stats-${statsCardData.videoTitle}`,
    });
  };

  return videoStatsByGroupLoading ? (
    <Loader />
  ) : (
    <div className="reel-view--wrapper-column">
      <div className="reel-view--page-title">
        <div className="reel-view--page-selection">
          {parentGroupName && groupName && (
            <BreadCrumbView
              history={history}
              routeOptions={[
                {
                  title: parentGroupName ? parentGroupName : '',
                  path: '/node-tree',
                },
                {
                  title: groupName ? groupName : '',
                  path: `/home/${groupId}/`,
                },
                {
                  title: 'Reels',
                  path: `/group-dashboard/${groupId}/reels/video/`,
                },
                {
                  title: statsCardData.reelName,
                  path: `/group-dashboard/${groupId}/reels/video/${reelId}`,
                },
                {
                  title: statsCardData.videoTitle,
                  path: '#',
                },
              ]}
            />
          )}
          <h2>{statsCardData.videoTitle}</h2>
        </div>
        <div className="reel-view--page-settings">
          <input
            className="form-input form-input--search"
            type="search"
            id="search"
            name="search"
            placeholder={t('Search')}
            value={searchName}
            onChange={event => setSearchName(event.target.value)}
          />
          <button
            onClick={() => downloadCsv()}
            className={`btn btn--primary btn--downloadcsv btn--long reel-table-download ${
              downloadingStatsLoading ? 'btn--loader' : ''
            }`}
            disabled={downloadingStatsLoading}
          >
            {t('Download CSV')}
          </button>
        </div>
      </div>
      <div className="reel-view--page-data">{renderStatsCardView()}</div>
      <div className="all-users-table--table">
        <div className="all-users-table--table-header">
          <div className="all-users-table--table-cell">
            <h5>{t('Username')}</h5>
          </div>
          <div className="all-users-table--table-cell">
            <h5>{t('Time/Date')}</h5>
          </div>
          <div className="all-users-table--table-cell">
            <h5>{t('Status')}</h5>
          </div>
          <div className="all-users-table--table-cell">
            <h5>{t('Views')}</h5>
          </div>
          <div className="all-users-table--table-cell">
            <h5>{t('Assessment Score')}</h5>
          </div>
          <div className="all-users-table--table-cell">
            <h5>{t('Time Spent')}</h5>
          </div>
        </div>
        <div className="all-users-table--table-body">
          {rendStatsTableRowsView()}
        </div>
      </div>
      {/* Video stats downloading error modal */}
      {downloadingStatsError && (
        <ProceedMessageModal
          isOkayButtonShown={false}
          isSuccess={downloadingStatsError.success}
          message={downloadingStatsError.message}
          handleClose={() =>
            dispatch({
              type: DOWNLOAD_STATS_SUCCESS,
            })
          }
          handleError={() =>
            dispatch({
              type: DOWNLOAD_STATS_SUCCESS,
            })
          }
        />
      )}
      {/* Video stats downloading error modal */}
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

const ProceedMessageModal = ({
  message,
  isSuccess,
  handleSuccess,
  handleError,
  handleClose,
  isOkayButtonShown,
}) => {
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup message">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t(isSuccess ? 'Success' : 'Error')}</h3>
          {!isOkayButtonShown && (
            <a
              className="btn-close closemodale"
              aria-hidden="true"
              onClick={handleClose}
            >
              &times;
            </a>
          )}
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{t(message)}</label>
          </div>
          <div className="reel-view--popup-row">
            <div className="reel-view--popup-field">
              <button
                className="btn btn--primary"
                onClick={isSuccess ? handleSuccess : handleError}
              >
                {isSuccess
                  ? isOkayButtonShown
                    ? t('Okay')
                    : t('Proceed')
                  : t('Close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProceedMessageModal;

import { useState, useEffect } from 'react';
import { ServiceTypes } from '../Common/ServiceTypes';

import useApi from '../Hooks/useApi';
import {
  DOWNLOAD_ASSESSMENT_STATS_FAIL,
  DOWNLOAD_ASSESSMENT_STATS_REQUEST,
  DOWNLOAD_ASSESSMENT_STATS_SUCCESS,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_FAIL,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_REQUEST,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_SUCCESS,
} from '../redux/actionTypes/assesment';
import {
  DOWNLOAD_USERS_LIST_REQUEST,
  DOWNLOAD_USERS_LIST_SUCCESS,
  DOWNLOAD_USERS_LIST_FAIL,
  DOWNLOAD_USER_REPORT_REQUEST,
  DOWNLOAD_USER_REPORT_SUCCESS,
  DOWNLOAD_USER_REPORT_FAIL,
} from '../redux/actionTypes/createUser';
import {
  DOWNLOAD_STATS_FAIL,
  DOWNLOAD_STATS_REQUEST,
  DOWNLOAD_STATS_SUCCESS,
} from '../redux/actionTypes/video';

const useReporting = () => {
  const [requestData, setRequestData] = useState({
    urlParams: {},
    reportName: '',
    type: '',
  });
  const [downloadReport] = useApi();

  useEffect(() => {
    if (requestData?.type && requestData?.reportName) {
      const { type, urlParams, reportName } = requestData;

      const {
        transcriptId,
        assessmentId,
        reelId,
        groupId,
        userId,
        zone,
        videoId,
      } = urlParams;

      const preferredTimeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone;

      switch (type) {
        case 'USERS_LIST_CSV_DOWNLOAD':
          downloadReport(
            `/reporting/get-all-user-data/download?preferredTimeZone=${preferredTimeZone}`,
            DOWNLOAD_USERS_LIST_REQUEST,
            DOWNLOAD_USERS_LIST_SUCCESS,
            DOWNLOAD_USERS_LIST_FAIL,
            null,
            '',
            'GET',
            null,
            ServiceTypes.isReelService,
            {
              responseType: 'arraybuffer',
              fileName: reportName,
            }
          );
          break;

        case 'DOWNLOAD_ASSESSMENT_TRANSCRIPT':
          if (transcriptId) {
            downloadReport(
              `/reporting/${transcriptId}/assessment-transcript`,
              DOWNLOAD_ASSESSMENT_TRANSCRIPT_REQUEST,
              DOWNLOAD_ASSESSMENT_TRANSCRIPT_SUCCESS,
              DOWNLOAD_ASSESSMENT_TRANSCRIPT_FAIL,
              null,
              '',
              'GET',
              null,
              ServiceTypes.isReelService,
              {
                responseType: 'arraybuffer',
                fileName: reportName,
              }
            );
          }
          break;

        case 'DOWNLOAD_STANDALONE_ASSESSMENT_STATS':
          if (assessmentId && reelId) {
            downloadReport(
              `/assessment/get-standalone-stats-by-group/download?assessmentId=${assessmentId}&reelId=${reelId}${
                groupId ? `&groupId=${groupId}` : ''
              }`,
              DOWNLOAD_ASSESSMENT_STATS_REQUEST,
              DOWNLOAD_ASSESSMENT_STATS_SUCCESS,
              DOWNLOAD_ASSESSMENT_STATS_FAIL,
              null,
              '',
              'GET',
              null,
              ServiceTypes.isReelService,
              {
                responseType: 'arraybuffer',
                fileName: reportName,
              }
            );
          }
          break;

        case 'USER_REPORT_DOWNLOAD':
          if (userId && groupId) {
            downloadReport(
              `/video/get-user-statistic-by-group/download?divisionId=${groupId}&userId=${userId}`,
              DOWNLOAD_USER_REPORT_REQUEST,
              DOWNLOAD_USER_REPORT_SUCCESS,
              DOWNLOAD_USER_REPORT_FAIL,
              null,
              '',
              'GET',
              null,
              ServiceTypes.isReelService,
              {
                responseType: 'arraybuffer',
                fileName: reportName,
              }
            );
          }
          break;

        case 'DOWNLOAD_VIDEO_STATS':
          if (videoId && reelId && zone) {
            downloadReport(
              `/video/get-video-stats-by-group/download?videoId=${videoId}&reelId=${reelId}&zone=${zone}${
                groupId ? `&groupId=${groupId}` : ''
              }`,
              DOWNLOAD_STATS_REQUEST,
              DOWNLOAD_STATS_SUCCESS,
              DOWNLOAD_STATS_FAIL,
              null,
              '',
              'GET',
              null,
              ServiceTypes.isReelService,
              {
                responseType: 'arraybuffer',
                fileName: reportName,
              }
            );
          }
          break;

        default:
          break;
      }
    }
  }, [requestData]);

  const submitForm = ({ type, urlParams, reportName }) =>
    setRequestData({
      type,
      urlParams: urlParams || {},
      reportName,
    });

  return [submitForm];
};

export default useReporting;

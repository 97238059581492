import React from "react";

const BreadCrumbView = ({
  routeOptions,
  history,
}) => {
  if (!routeOptions.length) {
    return <p className="breadcrumbs"></p>
  }

  return (
    <p className="breadcrumbs">
      {
        routeOptions.map((option, index, {length}) => {
          if(index + 1 === length){
            return <a key={index} href="#">{option.title}</a>;
          }

          return <span key={index} onClick={() => history.push(option.path)}>{option.title} / </span>
        })
      }
    </p>
  )
};

export default BreadCrumbView;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import Loader from '../../../../Components/Loader/loader';
import ChangePasswordModal from './Modal/ChangePasswordModal';
import useApi from '../../../../Hooks/useApi';
import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  FETCH_USER_BY_ID_RESET,
} from '../../../../redux/actionTypes/createUser';

import {
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAIL,
} from '../../../../redux/actionTypes/auth.js';
import useFileUpload from '../../../../Hooks/useFileUpload';
import { systemConfig } from '../../../../Common/SystemConstConfigs';

const SUPER_ADMIN_ID = 4;

export default function ProfileView() {
  const { t } = useTranslation();
  const [videoFileURL, setVideoFileURL] = useState(null);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [userData, setUserData] = useState({
    userName: '',
    preferredUserName: '',
    userEmail: '',
    profileImage: '',
    designation: '',
    existingWelcomeVideoUrl: '',
    existWelcomeVideoTitle: '',
    welcomeVideoTitle: '',
    welcomeVideoURL: '',
    isNewWelcomeVideo: false,
    isUploadNewVideo: true,
    groupsWithRoles: [],
    isSuperAdmin: false,
  });
  const [imageFile, setImageFile] = useState(null);

  const [upateUserProfile] = useApi();
  const [userDataRequest] = useApi();
  const { userId } = useSelector(state => state?.auth?.userData);
  const { emailLabel, userNameLabel, defaultProfileImageUrl } = useSelector(
    state => state?.auth?.themeInfo
  );
  const {
    userInfo,
    userFetchingRequest,
    updateUserLoading,
    updateUserRequestSuccess,
  } = useSelector(state => state?.user);
  const [fetchUser] = useFetchDataApi();
  const dispatch = useDispatch();
  const [uploadProfileImage, uploadedProfileImageUrl] = useFileUpload();

  useEffect(() => {
    fetchUser({ type: 'FETCH_USER_BY_ID', objectId: userId });

    return () => {
      dispatch({
        type: FETCH_USER_BY_ID_RESET,
      });
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      const {
        designation,
        email,
        id,
        profilePicUrl,
        preferredUserName,
        username,
        newWelcomeVideo,
        welcomeVideoTitle,
        welcomeVideoUrl,
        roles,
      } = userInfo.userData;

      setUserData({
        ...userData,
        id,
        userName: username,
        preferredUserName: preferredUserName || '',
        userEmail: email,
        profileImage: profilePicUrl || defaultProfileImageUrl,
        designation,
        existingWelcomeVideoUrl: !newWelcomeVideo ? welcomeVideoUrl : '',
        existWelcomeVideoTitle: !newWelcomeVideo ? welcomeVideoTitle : '',
        welcomeVideoTitle: newWelcomeVideo ? welcomeVideoTitle : '',
        welcomeVideoURL: newWelcomeVideo ? welcomeVideoUrl : '',
        isNewWelcomeVideo: newWelcomeVideo,
        groupsWithRoles: userInfo.groupsWithRoles || [],
        isSuperAdmin:
          roles && roles.length
            ? roles.some(({ id }) => id === SUPER_ADMIN_ID)
            : false,
      });

      if (newWelcomeVideo) {
        setVideoFileURL(welcomeVideoUrl);
      }
    }
  }, [userId, userInfo]);

  useEffect(() => {
    if (updateUserRequestSuccess) {
      setImageFile(null);
      setUserData({
        ...userData,
        profileImage: URL.createObjectURL(imageFile),
      });
      userDataRequest(
        `/user/getUserData?username=${userName}`,
        FETCH_USER_DATA_REQUEST,
        FETCH_USER_DATA_SUCCESS,
        FETCH_USER_DATA_FAIL,
        null,
        null,
        'GET',
        '',
        'isUserService'
      );
    }

    return () => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
      });
    };
  }, [updateUserRequestSuccess]);

  const {
    id,
    userName,
    preferredUserName,
    userEmail,
    profileImage,
    designation,
    existingWelcomeVideoUrl,
    welcomeVideoURL,
    isNewWelcomeVideo,
    groupsWithRoles,
    isSuperAdmin,
  } = userData;

  const handleImageUpload = event => {
    const imageFile = event.target.files[0];
    const maxSize = 1048576;

    let img;

    if (imageFile) {
      img = new Image();
      img.src = URL.createObjectURL(imageFile);
      img.onload = () => {
        if (img.width !== img.height) {
          alert(
            t(
              'Please make sure the image is in the ratio of 1:1 (eg:- 100px x 100px).'
            )
          );
          return;
        } else if (maxSize <= imageFile.size) {
          alert(t('Please make sure the image is less than 1MB'));
          return;
        } else {
          setImageFile(imageFile);
          uploadProfileImage(imageFile);
        }
      };

      img.onerror = () => {
        alert(t('Please upload an image file'));
        return;
      };
    }
  };

  const handleProfileImageUpload = () => {
    const formData = new FormData();

    upateUserProfile(
      `/user/updateProfileImage?userId=${userId}${
        imageFile && uploadedProfileImageUrl
          ? '&profileImage=' + uploadedProfileImageUrl
          : ''
      }`,
      UPDATE_USER_REQUEST,
      UPDATE_USER_SUCCESS,
      UPDATE_USER_FAIL,
      formData,
      '',
      'POST',
      null,
      'isUserService'
    );
  };

  if (userFetchingRequest) {
    return <Loader />;
  }

  return (
    <div className="create-user">
      <div className="container">
        <div className="create-user--wrapper">
          <div className="create-user--page-title">
            <div className="create-user--page-row">
              <h2>{t('My Profile')}</h2>
              <div className="create-user--download edituser">
                {imageFile && (
                  <button
                    className={`btn btn--secondary ${
                      updateUserLoading ? 'btn--loader' : ''
                    }`}
                    onClick={handleProfileImageUpload}
                  >
                    {t('Change Profile Image')}
                  </button>
                )}
                {!isSuperAdmin && (
                  <button
                    className="btn btn--secondary"
                    onClick={() => setIsChangePasswordModalOpen(true)}
                  >
                    {t('Change Password')}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="create-user--page-content">
            <div className="create-user--page-row credentials">
              <div className="create-user--page-column">
                <div className="create-user--uploadphoto">
                  <div className="upload-btn-wrapper">
                    <img
                      src={
                        imageFile
                          ? URL.createObjectURL(imageFile)
                          : profileImage
                      }
                      alt="camera"
                      className="create-user--image"
                    />
                    <input
                      type="file"
                      accept="image/gif, image/jpeg, image/png"
                      name="myfile"
                      onChange={handleImageUpload}
                    />
                  </div>
                </div>
              </div>
              <div className="create-user--page-column">
                <div className="create-user--field username">
                  <input
                    type="text"
                    name=""
                    value={preferredUserName}
                    disabled
                  />
                </div>
                <div className="create-user--field designation">
                  <label>{t(userNameLabel)}</label>
                  <input
                    type="text"
                    name=""
                    placeholder={t(userNameLabel)}
                    value={designation}
                    disabled
                  />
                </div>
                <div className="create-user--field">
                  <label>{t(emailLabel)}</label>
                  <input
                    type="email"
                    name=""
                    placeholder={'name@example.com'}
                    value={userEmail}
                    disabled
                  />
                </div>
              </div>
              <div className="create-user--page-column">
                {!isSuperAdmin && (
                  <div className="create-user--field">
                    <label>{t('Video Preview')}</label>
                    <ReactPlayer
                      url={
                        (isNewWelcomeVideo && welcomeVideoURL) ||
                        (isNewWelcomeVideo && videoFileURL) ||
                        (!isNewWelcomeVideo && existingWelcomeVideoUrl) ||
                        'alt'
                      }
                      width="100%"
                      height="100%"
                      controls
                      playing={false}
                      config={systemConfig.playerNoDownload}
                    />
                  </div>
                )}
              </div>
            </div>
            {!isSuperAdmin && (
              <div className="create-user--page-row groups">
                <h4>{t('Groups')}</h4>
                <div className="create-user--page-row--inner">
                  {groupsWithRoles.length
                    ? groupsWithRoles.map(
                        ({ divisionName, userData }, index) => (
                          <div className="create-user--page-column" key={index}>
                            <div className="create-user--group">
                              <h5>{divisionName}</h5>
                              <span>
                                {userData.length
                                  ? userData
                                      .map(({ roleName }) =>
                                        roleName === 'Admin'
                                          ? 'Administrator'
                                          : roleName
                                      )
                                      .join(', ')
                                  : null}
                              </span>
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          userId={id}
          handleClose={() => setIsChangePasswordModalOpen(null)}
        />
      )}
    </div>
  );
}

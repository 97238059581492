import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import useApi from '../../../../Hooks/useApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import {
  CHANGE_USER_STATUS_REQUEST,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_FAIL,
  REMOVE_USER_FROM_GROUP_REQUEST,
  REMOVE_USER_FROM_GROUP_SUCCESS,
  REMOVE_USER_FROM_GROUP_FAIL,
} from '../../../../redux/actionTypes/createUser';
import MultiSelect from 'react-multi-select-component';
import $ from 'jquery';

import person from '../../../../assets/images/svg-images/icon-person.svg';
import AddUserModal from '../Modal/addUserModal';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import BreadCrumbView from '../../../../Components/BreadCrumbView';
import Loader from '../../../../Components/Loader/loader';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import { getYearAndFullMonthNameFromDate } from '../../../../Helper/SystemManager';
import { ToolTipConfig } from '../../../../Common/ToolTipConfig';
import { systemConfig } from '../../../../Common/SystemConstConfigs';

const USER_ROLE_ID = 3;

export default function GroupUsers({ history, groupId, groupDetailsData }) {
  const { t } = useTranslation();
  const canAddUserToGroup = usePrivilegesApi(
    PrivilegeTypes.GROUP.ADD_USER_TO_GROUP,
    groupId
  );
  const canEditUserToGroup = usePrivilegesApi(
    PrivilegeTypes.GROUP.EDIT_USERS_OF_GROUP,
    groupId
  );
  const canRemoveUserToGroup = usePrivilegesApi(
    PrivilegeTypes.GROUP.REMOVE_USER_FROM_GROUP,
    groupId
  );

  const [isGridView, setIsGridView] = useState(true);
  const [searchName, setSearchName] = useState(null);
  const [isAddUserModalShow, setIsAddUserModalShow] = useState(false);
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(null);
  const {
    groupUsersData,
    userRolesFetchData,
    removeUserFromGroupSuccess,
    changeUserStatusData,
    groupUsersDataFetchLoading,
  } = useSelector(state => state?.user);
  const { emailLabel, userNameLabel, defaultProfileImageUrl } = useSelector(
    state => state?.auth?.themeInfo
  );
  const [categoriedUsers, setCategoriedUsers] = useState(null);

  const { groupName = null, parentGroupName = null } = groupDetailsData || {};

  const [fetchGroupUsers] = useFetchDataApi();
  const [fetchUserRoles] = useFetchDataApi();
  const [changeUserStatusRequest] = useApi();
  const [removeUserRequest] = useApi();

  const fetchUsers = () => {
    fetchGroupUsers({ type: 'FETCH_USERS_BY_GROUP', groupId });
  };

  useEffect(() => {
    fetchUsers();
    fetchUserRoles({ type: 'FETCH_USER_ROLES' });
  }, []);

  useEffect(() => {
    if (removeUserFromGroupSuccess) {
      fetchUsers();
      setIsDeleteConfirmModalOpen(null);
    }
  }, [removeUserFromGroupSuccess]);

  useEffect(() => {
    if (changeUserStatusData) {
      fetchUsers();
    }
  }, [changeUserStatusData]);

  useEffect(() => {
    setTimeout(() => {
      // var allPanels = $('.group-users-table--grid-wrapper').hide();
      // $('.group-users-table--grid-header.accordion').click(function () {
      //   allPanels.slideUp();
      //   $('.group-users-table--grid-header.accordion').removeClass('closed');
      //   $(this).addClass('closed');
      //   $(this).parent().children('.group-users-table--grid-wrapper').slideDown();
      //   return false;
      // });

      var allPanels = $('.group-users-table--grid-wrapper');
      $('.group-users-table--grid-header.accordion').click(function () {
        $(this).parent().children('.group-users-table--grid-wrapper').slideUp();
        $(this).addClass('closed');
        // allPanels.slideUp();
        if (
          $(this)
            .parent()
            .children('.group-users-table--grid-wrapper')
            .css('display') == 'none'
        ) {
          $(this)
            .parent()
            .children('.group-users-table--grid-wrapper')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });
    }, 2000);
  }, []);

  useEffect(() => {
    const usersCategories = {
      Administrator: [],
      Moderator: [],
      Editor: [],
      User: [],
    };
    if (groupUsersData.length) {
      for (let key in usersCategories) {
        if (
          !selectedUserRoles.length ||
          selectedUserRoles.filter(({ label }) => {
            return label === key;
          }).length
        ) {
          usersCategories[key] = groupUsersData.filter(
            ({ preferredUserName, username, roles, email }) =>
              (!searchName ||
                (preferredUserName || username)
                  .toUpperCase()
                  .includes(searchName.toUpperCase()) ||
                email?.toUpperCase().includes(searchName.toUpperCase())) &&
              roles &&
              roles.length &&
              roles.filter(userRole => {
                if (userRole.roleName === 'Admin') {
                  return 'Administrator' === key;
                } else {
                  return userRole.roleName === key;
                }
              }).length
          );
        }
      }
    }
    setCategoriedUsers(usersCategories);
  }, [groupUsersData, searchName, selectedUserRoles]);

  const setActionDropdown = (value, user) => {
    switch (value) {
      case 'Edit':
        history.push(`/group-dashboard/${groupId}/users/${user.id}/edit`);
        break;

      case 'Suspend':
        handleChangeUserStatus(user.id, false);
        break;

      case 'Remove':
        setIsDeleteConfirmModalOpen(user.id);
        break;

      case 'Activate':
        handleChangeUserStatus(user.id, true);
        break;

      default:
        break;
    }
  };

  const handleRemoveUser = userId => {
    removeUserRequest(
      `/division/removeUserFromAGroup?userId=${userId}&divisionId=${groupId}`,
      REMOVE_USER_FROM_GROUP_REQUEST,
      REMOVE_USER_FROM_GROUP_SUCCESS,
      REMOVE_USER_FROM_GROUP_FAIL,
      null,
      history,
      'DELETE',
      null,
      'isGroupService'
    );
  };

  const handleChangeUserStatus = (userId, activeStatus) => {
    changeUserStatusRequest(
      `/user/changeUserState?userId=${userId}&enabled=${activeStatus}`,
      CHANGE_USER_STATUS_REQUEST,
      CHANGE_USER_STATUS_SUCCESS,
      CHANGE_USER_STATUS_FAIL,
      null,
      null,
      'POST',
      null,
      'isUserService'
    );
  };

  const renderUserList = () => {
    const UserListComponent = [];
    for (let key in categoriedUsers) {
      const NoOfUsers = categoriedUsers[key];
      if (NoOfUsers.length) {
        UserListComponent.push(
          <div className="group-users-table--grid" key={key}>
            <div className="group-users-table--grid-header accordion">
              <p>{key}s</p>
              <span>
                <img
                  src={person}
                  className="group-users-table--grid-personimg"
                />
                {NoOfUsers.length} {key}
                {NoOfUsers.length > 1 ? 's' : ''}
              </span>
            </div>

            <div className="group-users-table--grid-wrapper">
              {NoOfUsers.map((user, index) => (
                <div className="group-users-table--grid-card" key={index}>
                  {canEditUserToGroup && canRemoveUserToGroup && (
                    <Dropdown
                      title="Edit"
                      placeholder={t('Select')}
                      options={[
                        { label: t('Edit'), value: 'Edit' },
                        { label: t('Remove'), value: 'Remove' },
                      ]}
                      onChange={option => setActionDropdown(option.value, user)}
                    />
                  )}
                  <img
                    src={user.profilePicUrl || defaultProfileImageUrl}
                    className="group-users-table--grid-avatarimg"
                    onError={event => {
                      event.target.onerror = null;
                      event.target.src = defaultProfileImageUrl;
                    }}
                  />
                  <h5>{user.preferredUserName || user.username}</h5>
                  <p>
                    {user.addedDateTime
                      ? 'Joined ' +
                        getYearAndFullMonthNameFromDate(user.addedDateTime)
                      : ''}
                  </p>
                  <button
                    className="btn btn--primary"
                    onClick={() =>
                      history.push(
                        `/group-dashboard/${groupId}/users/${user.id}/view`
                      )
                    }
                  >
                    {t('View Details')}
                  </button>
                </div>
              ))}
            </div>
          </div>
        );
      }
    }
    return UserListComponent;
  };

  const handleClickOnUserRow = (event, userId) => {
    if (
      event.target.classList.contains('Dropdown-placeholder') ||
      event.target.classList.contains('Dropdown-control')
    ) {
      event.preventDefault();
    } else {
      history.push(`/group-dashboard/${groupId}/users/${userId}/view`);
    }
  };

  const renderUserRoles = roles => {
    const roleNames = roles.map(({ roleName }) =>
      roleName === 'Admin' ? 'Administrator' : roleName
    );

    return roleNames.length > 0 ? roleNames.join(', ') : '-';
  };

  const isHaveUserRole = roles => {
    const isAnUser = roles.some(
      ({ roleReferenceId }) => roleReferenceId === USER_ROLE_ID
    );

    return isAnUser ? '0' : '-';
  };

  return (
    <div className="group-users-table--wrapper-column loading-screen-parent">
      <div className="group-users-table--page-title">
        <div className="group-users-table--page-selection ">
          <BreadCrumbView
            history={history}
            routeOptions={[
              {
                title: parentGroupName ? parentGroupName : '',
                path: `/node-tree`,
              },
              {
                title: groupName ? groupName : '',
                path: `/home/${groupId}/`,
              },
              {
                title: 'Users',
                path: '#',
              },
            ]}
          />
          <h2>{t('Users')}</h2>
        </div>
        <div className="group-users-table--page-settings">
          <input
            className="form-input form-input--search"
            type="search"
            id="search"
            name="search"
            placeholder={t('Search')}
            value={searchName}
            onChange={event => setSearchName(event.target.value)}
          />
          {/* <Dropdown
            title="Filter By"
            options={
              userRolesFetchData && userRolesFetchData.length
                ? userRolesFetchData
                    .filter((userRole) => userRole.id !== 4)
                    .map((role) => role.name)
                : []
            }
            value={selectedUserRole}
            onChange={(option) => setSelectedUserRole(option.value)}
          /> */}
          <MultiSelect
            options={
              userRolesFetchData
                ? userRolesFetchData
                    .filter(userRole => userRole.id !== 4)
                    .map(role => ({
                      label:
                        role.name === 'Admin' ? 'Administrator' : role.name,
                      value: role.id,
                    }))
                : []
            }
            value={selectedUserRoles}
            onChange={setSelectedUserRoles}
            labelledBy={'Select'}
            hasSelectAll={false}
            disableSearch={true}
          />
          {canAddUserToGroup && (
            <button
              className="btn btn--primary btn--createnew"
              onClick={() => setIsAddUserModalShow(true)}
            >
              {t('Add User')}
            </button>
          )}
          <div
            className={`group-users-table--image grid ${
              isGridView ? 'active' : ''
            }`}
            title={t(ToolTipConfig.general.gridView)}
            onClick={() => setIsGridView(true)}
          />
          <div
            className={`group-users-table--image list ${
              !isGridView ? 'active' : ''
            }`}
            title={t(ToolTipConfig.general.tableView)}
            onClick={() => setIsGridView(false)}
          />
        </div>
      </div>
      {groupUsersDataFetchLoading ? (
        <Loader />
      ) : (
        <>
          {isGridView ? (
            renderUserList()
          ) : (
            <div className="group-users-table--table">
              <div className="group-users-table--table-header">
                <div className="group-users-table--table-cell">
                  {/* User Image Column*/}
                </div>
                <div className="group-users-table--table-cell">
                  <h5>{userNameLabel}</h5>
                </div>
                <div className="group-users-table--table-cell">
                  <h5>{emailLabel}</h5>
                </div>
                <div className="group-users-table--table-cell">
                  <h5>{t('Roles')}</h5>
                </div>
                <div className="group-users-table--table-cell">
                  <h5>{t('Reels')}</h5>
                </div>
                <div className="group-users-table--table-cell">
                  <h5>{t('Status')}</h5>
                </div>
                <div className="group-users-table--table-cell">
                  <h5>{t('Last Logon')}</h5>
                </div>
                {canEditUserToGroup && canRemoveUserToGroup && (
                  <div className="group-users-table--table-cell">
                    {/* Dropdown column */}
                  </div>
                )}
              </div>
              <div className="group-users-table--table-body">
                {groupUsersData.length
                  ? groupUsersData
                      .filter(
                        ({ roles }) =>
                          !roles ||
                          !roles
                            .map(({ roleReferenceId }) => roleReferenceId)
                            .includes(
                              systemConfig.roleIdByName.superAdmin
                                .roleReferenceId
                            )
                      )
                      .filter(
                        ({ preferredUserName, username, roles, email }) =>
                          (!searchName ||
                            (preferredUserName || username)
                              .toUpperCase()
                              .includes(searchName.toUpperCase()) ||
                            email
                              ?.toUpperCase()
                              .includes(searchName.toUpperCase())) &&
                          (!selectedUserRoles.length ||
                            selectedUserRoles.filter(
                              ({ label }) =>
                                roles.filter(({ roleName }) => {
                                  if (roleName === 'Admin') {
                                    return label === 'Administrator';
                                  } else {
                                    return label === roleName;
                                  }
                                }).length
                            ).length)
                      )
                      .map((user, index) => (
                        <div
                          key={index}
                          className="group-users-table--table-row"
                          onClick={event =>
                            handleClickOnUserRow(event, user.id)
                          }
                        >
                          <div className="group-users-table--table-cell">
                            <img
                              src={user.profilePicUrl || defaultProfileImageUrl}
                              className="group-users-table--table-avatarimg"
                              onError={event => {
                                event.target.onerror = null;
                                event.target.src = defaultProfileImageUrl;
                              }}
                            />
                          </div>
                          <div className="group-users-table--table-cell">
                            <p>{user.preferredUserName || user.username}</p>
                          </div>
                          <div className="group-users-table--table-cell">
                            <p>{user.email}</p>
                          </div>
                          <div className="group-users-table--table-cell">
                            <p>{renderUserRoles(user.roles)}</p>
                          </div>
                          <div className="group-users-table--table-cell">
                            <p>
                              {user.reelCount || isHaveUserRole(user.roles)}
                            </p>
                          </div>
                          <div className="group-users-table--table-cell">
                            <p>{user.enabled ? 'Active' : 'Suspended'}</p>
                          </div>
                          <div className="group-users-table--table-cell">
                            <p>
                              {user.lastActiveDateTime
                                ? moment
                                    .utc(user.lastActiveDateTime)
                                    .local()
                                    .format('hh:mm A | DD/MM/YYYY')
                                : '-'}
                            </p>
                          </div>
                          {canEditUserToGroup && canRemoveUserToGroup && (
                            <div className="group-users-table--table-cell">
                              <Dropdown
                                title="Edit"
                                placeholder={t('Select')}
                                options={[
                                  { label: t('Edit'), value: 'Edit' },
                                  { label: t('Remove'), value: 'Remove' },
                                ]}
                                onChange={option =>
                                  setActionDropdown(option.value, user)
                                }
                              />
                            </div>
                          )}
                        </div>
                      ))
                  : null}
              </div>
            </div>
          )}
        </>
      )}
      {isAddUserModalShow && (
        <AddUserModal
          handleSubmit={() => console.log('submit')}
          handleClose={() => setIsAddUserModalShow(false)}
          fetchUsers={() => fetchUsers()}
          groupId={groupId}
        />
      )}
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={messageConfigs.deleteConfirm.title}
          message={messageConfigs.deleteConfirm.userFromGroup}
          handleSuccess={() => handleRemoveUser(isDeleteConfirmModalOpen)}
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
        />
      )}
    </div>
  );
}

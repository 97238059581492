import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MultiSelect from 'react-multi-select-component';
import $ from 'jquery';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import previewimg from '../../../../assets/images/document-preview-img-background.png';
import updocimg from '../../../../assets/images/upload-document.png';

import {
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAIL,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAIL,
  CREATE_DOCUMENT_RESET,
  UPDATE_DOCUMENT_RESET,
} from '../../../../redux/actionTypes/documentData';
import useApi from '../../../../Hooks/useApi';
import ViewPDFModal from '../../Group/Modal/viewPDFModal';
import { getOrganizedListOfReelIdCategoryIdAndGroups } from '../../../../Helper/SystemManager';
import ProceedMessageModal from '../../../../Components/Modal/ProceedMessageModal';
import AddReelCategoryGroup from '../Common/AddReelCategoryGroup';
import Loader from '../../../../Components/Loader/loader';
import { messageConfigs } from '../../../../Helper/PopupMessageConfig';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import ErrorMessageModal from '../../../../Components/Modal/ErrorMessageModal';
import { validateFields } from '../../../../Helper/ValidationsManager';
import PreviewImageView from '../../../../Components/CreateEditComponents/PreviewImageView';
import MandatoryMark from '../../../../Components/validation/MandatoryMark';
import useFileUpload from '../../../../Hooks/useFileUpload';
import UploadItem from '../../../../Components/UploadProgressBar/UploadProgressBar';
import { systemConfig } from '../../../../Common/SystemConstConfigs';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';

const reelCategoryGroupObj = {
  reelId: '',
  categoryId: '',
  groups: [],
};

const initialState = {
  title: '',
  description: '',
  author: '',
  isNativeBannerAndFeatured: false,
  isFeatured: false,
  isBanner: false,
  isPublished: '',
  tags: '',
  previewImgUrl: '',
  documentUrl: '',
  isPreviewImgViaUrl: false,
  isDocumentViaUrl: false,
  selectedVideos: [],
  selectedAssessments: [],
  allowDownload: false,
  listOfReelIdCategoryIdAndGroups: [_.clone(reelCategoryGroupObj)],
};

const CreateDocument = ({ match, history }) => {
  const { t } = useTranslation();
  const { documentId, reelId, groupId } = match.params;
  const isHavePublishAccess = usePrivilegesApi(
    PrivilegeTypes.DOCUMENT.UPLOAD_DOCUMENT
  );
  const [documentData, setDocumentData] = useState(_.cloneDeep(initialState));
  const [isPublishProcess, setIsPublishProcess] = useState(false);
  const [isSaveProcess, setIsSaveProcess] = useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(null);
  const [isUpdateProcess, setIsUpdateProcess] = useState(false);
  const [isValidationError, setIsValidationError] = useState(null);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const [fetchData] = useFetchDataApi();
  const [createDocument] = useApi();
  const [updateDocument] = useApi();
  const [findDocumentById] = useFetchDataApi();
  const dispatch = useDispatch();

  const {
    initializeDocumentData,
    fetchDocumentToEditData,
    updateDocumentLoading,
    initializeDocumentLoading,
    createDocumentLoading,
    createDocumentSuccess,
    updateDocumentSuccess,
    createDocumentError,
    updateDocumentError,
  } = useSelector(state => state?.documentData);
  const { themeInfo } = useSelector(state => state?.auth);
  const pdfSelected = themeInfo?.defaultUploadedDocumentUrl;

  const {
    reels = [],
    relatedVideos = [],
    relatedAssessments = [],
  } = initializeDocumentData || {};
  const [isPdfViewModalOpen, setIsPdfViewModalOpen] = useState(false);
  const [documentDataFiles, setDocumentDataFiles] = useState({
    previewImage: '',
    documentFile: '',
  });

  const [uploadDocument, uploadedDocumentUrl, documentUploadProgress] =
    useFileUpload();

  useEffect(() => {
    setShowProgressBar(false);
    if (documentUploadProgress !== 0) {
      setShowProgressBar(true);
    } else if (showProgressBar) {
      setShowProgressBar(false);
    }
  }, [documentUploadProgress]);

  useEffect(() => {
    if (reels && reels.length && reelId && groupId) {
      const createFromGroup = {
        reelId: reelId,
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
          },
        ],
      };

      const intialDocumentData = { ...documentData };
      intialDocumentData['listOfReelIdCategoryIdAndGroups'] =
        getOrganizedListOfReelIdCategoryIdAndGroups(
          [_.clone(createFromGroup)],
          reels
        );

      setDocumentData(intialDocumentData);
    }
  }, [reels, reelId, groupId]);

  useEffect(() => {
    if (createDocumentSuccess) {
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message: isPublishProcess
          ? messageConfigs.success.document.PUBLISH
          : messageConfigs.success.document.SAVE,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () => dispatch({ type: CREATE_DOCUMENT_RESET });
    }
  }, [createDocumentSuccess]);

  useEffect(() => {
    if (createDocumentError) {
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () => dispatch({ type: CREATE_DOCUMENT_RESET });
    }
  }, [createDocumentError]);

  useEffect(() => {
    if (updateDocumentSuccess) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message: messageConfigs.success.document.UPDATE,
      });

      return () => dispatch({ type: UPDATE_DOCUMENT_RESET });
    }
  }, [updateDocumentSuccess]);

  useEffect(() => {
    if (updateDocumentError) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      return () => dispatch({ type: UPDATE_DOCUMENT_RESET });
    }
  }, [updateDocumentError]);

  const handleDocumentData = (value, property) => {
    const tempDocumentData = { ...documentData };
    tempDocumentData[property] = value;
    setDocumentData(tempDocumentData);
  };

  const handleDocumentFileData = (value, property) => {
    const tempDocumentDataFiles = { ...documentDataFiles };
    tempDocumentDataFiles[property] = value;
    setDocumentDataFiles(tempDocumentDataFiles);
  };

  const handleFileAccept = (event, property) => {
    if (event?.target?.files?.length) {
      handleDocumentFileData(event.target.files[0], property);
      uploadDocument(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (uploadedDocumentUrl) {
      handleDocumentData(uploadedDocumentUrl, 'documentUrl');
    }
  }, [uploadedDocumentUrl]);

  useEffect(() => {
    setTimeout(() => {
      var allPanels = $('.accordion .admin__adddocument--inner-container');
      $('.accordion h3').unbind();
      $('.accordion h3').click(function () {
        $(this)
          .parent()
          .children('.admin__adddocument--inner-container')
          .slideUp();
        $(this).addClass('closed');
        if (
          $(this)
            .parent()
            .children('.admin__adddocument--inner-container')
            .css('display') == 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__adddocument--inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });

      $('.accordion h3').click(function () {
        $(this)
          .parent()
          .children('.admin__createvideo--inner-container')
          .slideUp();
        $(this).addClass('closed');
        if (
          $(this)
            .parent()
            .children('.admin__createvideo--inner-container')
            .css('display') == 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__createvideo--inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });
    }, 0);
  });

  useEffect(() => {
    fetchData({ type: 'INTIALIZE_CREATE_DOCUMENT' });
  }, []);

  useEffect(() => {
    if (
      documentId &&
      Object.keys(initializeDocumentData).length !== 0 &&
      initializeDocumentData.constructor === Object
    ) {
      findDocumentById({ objectId: documentId, type: 'FIND_DOCUMENT' });
    }
  }, [initializeDocumentData]);

  useEffect(() => {
    if (fetchDocumentToEditData && documentId) {
      const {
        _id,
        title,
        author,
        description,
        documentUrl,
        isBanner,
        isDocumentViaUrl,
        isFeatured,
        isNativeBannerAndFeatured,
        isPreviewImgViaUrl,
        previewImgUrl,
        standaloneAssessmentIds,
        tags,
        videoIds,
        isPublished,
        download,
      } = fetchDocumentToEditData;

      const selectedAssessmentsOptions = relatedAssessments
        .filter(({ _id }) => standaloneAssessmentIds.includes(_id))
        .map(assessment => ({
          label: assessment.title,
          value: assessment._id,
        }));

      const selectedVideoOptions = relatedVideos
        .filter(({ _id }) => videoIds.includes(_id))
        .map(video => ({
          label: video.title,
          value: video._id,
        }));

      const editDocumentData = { ...documentData };
      editDocumentData['_id'] = _id;
      editDocumentData['title'] = title;
      editDocumentData['description'] = description;
      editDocumentData['author'] = author;
      editDocumentData['isNativeBannerAndFeatured'] = isNativeBannerAndFeatured;
      editDocumentData['isFeatured'] = isFeatured;
      editDocumentData['isBanner'] = isBanner;
      editDocumentData['isPublished'] = isPublished;
      editDocumentData['tags'] = tags.join();
      editDocumentData['previewImgUrl'] = previewImgUrl;
      editDocumentData['documentUrl'] = documentUrl;
      editDocumentData['allowDownload'] = download;
      editDocumentData['isPreviewImgViaUrl'] = isPreviewImgViaUrl;
      editDocumentData['isDocumentViaUrl'] = isDocumentViaUrl;
      editDocumentData['selectedVideos'] = selectedVideoOptions;
      editDocumentData['selectedAssessments'] = selectedAssessmentsOptions;
      editDocumentData['listOfReelIdCategoryIdAndGroups'] =
        getOrganizedListOfReelIdCategoryIdAndGroups(
          fetchDocumentToEditData.listOfReelIdCategoryIdAndGroups,
          reels
        );

      setDocumentData(editDocumentData);
    }
  }, [fetchDocumentToEditData]);

  const {
    _id,
    title,
    description,
    author,
    isNativeBannerAndFeatured,
    isFeatured,
    isBanner,
    tags,
    previewImgUrl,
    documentUrl,
    allowDownload,
    isPreviewImgViaUrl,
    isDocumentViaUrl,
    relatedAssessmentIds,
    relatedVideoIds,
    listOfReelIdCategoryIdAndGroups,
    selectedVideos,
    selectedAssessments,
    isPublished,
  } = documentData;
  const { previewImage, documentFile } = documentDataFiles;

  const handleCreateDocument = (isPublished = false) => {
    const formErrors = validateFields({
      type: 'DOCUMENT',
      formData: {
        title,
        listOfReelIdCategoryIdAndGroups,
        documentFileData: isDocumentViaUrl ? documentUrl : uploadedDocumentUrl,
      },
    });

    if (!isValidUrl && isPreviewImgViaUrl) {
      return;
    }

    if (formErrors) {
      setIsValidationError(formErrors);
      return;
    }

    const formData = new FormData();

    const documentInfo = {
      title,
      description,
      author,
      isNativeBannerAndFeatured,
      isFeatured,
      isBanner,
      isEdit: !!documentId,
      tags: tags.split(','),
      isPublished,
      previewImgUrl,
      download: allowDownload,
      documentUrl: isDocumentViaUrl ? documentUrl : uploadedDocumentUrl || '',
      isPreviewImgViaUrl: isPreviewImgViaUrl,
      isDocumentViaUrl: true,
      standaloneAssessmentIds: selectedAssessments.map(({ value }) => value),
      videoIds: selectedVideos.map(({ value }) => value),
      listOfReelIdCategoryIdAndGroups: listOfReelIdCategoryIdAndGroups.map(
        reel => {
          const tempReel = reel;
          if (!tempReel.categoryId) {
            delete tempReel.categoryId;
          }
          tempReel.groups.map(group => {
            delete group.name;
            return group;
          });

          return tempReel;
        }
      ),
      isPublished,
    };

    formData.append(
      'docs',
      new Blob([JSON.stringify(documentInfo)], { type: 'application/json' })
    );

    createDocument(
      `/document/add-document`,
      CREATE_DOCUMENT_REQUEST,
      CREATE_DOCUMENT_SUCCESS,
      CREATE_DOCUMENT_FAIL,
      formData,
      '',
      'POST',
      null,
      'isReelService'
    );
  };

  const handleUpdateDocument = (isPublished = false) => {
    const formErrors = validateFields({
      type: 'DOCUMENT',
      formData: {
        title,
        listOfReelIdCategoryIdAndGroups,
      },
    });

    if (!isValidUrl & isPreviewImgViaUrl) {
      return;
    }

    if (formErrors) {
      setIsValidationError(formErrors);
      return;
    }

    const formData = new FormData();

    const documentInfo = {
      _id,
      title,
      description,
      author,
      isNativeBannerAndFeatured,
      isFeatured,
      isBanner,
      isEdit: !!documentId,
      tags: tags.split(','),
      isPublished,
      previewImgUrl,
      download: allowDownload,
      documentUrl: isDocumentViaUrl ? documentUrl : uploadedDocumentUrl || '',
      isPreviewImgViaUrl,
      isDocumentViaUrl: true,
      standaloneAssessmentIds: selectedAssessments.map(({ value }) => value),
      videoIds: selectedVideos.map(({ value }) => value),
      listOfReelIdCategoryIdAndGroups: listOfReelIdCategoryIdAndGroups.map(
        reel => {
          const tempReel = reel;
          if (!tempReel.categoryId) {
            delete tempReel.categoryId;
          }
          tempReel.groups.map(group => {
            delete group.name;
            return group;
          });

          return tempReel;
        }
      ),
    };

    formData.append(
      'docs',
      new Blob([JSON.stringify(documentInfo)], { type: 'application/json' })
    );

    updateDocument(
      `/document/add-document`,
      UPDATE_DOCUMENT_REQUEST,
      UPDATE_DOCUMENT_SUCCESS,
      UPDATE_DOCUMENT_FAIL,
      formData,
      '',
      'POST',
      null,
      'isReelService'
    );
  };

  const renderSelectedVideoOrDocument = property => {
    const selectedVideoOrAssessmentArray =
      property === 'selectedVideos' ? selectedVideos : selectedAssessments;
    return (
      <div className="admin__createassessment--tags-selected">
        {selectedVideoOrAssessmentArray.length
          ? selectedVideoOrAssessmentArray.map(({ label, value }, index) => (
              <p key={index}>
                {label}
                <span className="close-icon">
                  <a
                    className="btn-close"
                    aria-hidden="true"
                    onClick={() => {
                      const tempSelectedVideosOrAssessment = [
                        ...selectedVideoOrAssessmentArray,
                      ];
                      handleDocumentData(
                        tempSelectedVideosOrAssessment.filter(
                          selectedVideoOrAssessment =>
                            selectedVideoOrAssessment.value !== value
                        ),
                        property
                      );
                    }}
                  >
                    &times;
                  </a>
                </span>
              </p>
            ))
          : null}
      </div>
    );
  };

  const resetFields = () => {
    const resetState = _.cloneDeep(initialState);

    if (groupId) {
      const createFromGroup = {
        reelId: reelId,
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
          },
        ],
      };

      resetState['listOfReelIdCategoryIdAndGroups'] =
        getOrganizedListOfReelIdCategoryIdAndGroups(
          [_.clone(createFromGroup)],
          reels
        );
    }

    setDocumentData(resetState);
  };

  return (
    <div>
      <div
        className={`admin__adddocument loading-screen-parent ${
          initializeDocumentLoading ? 'setheight' : ''
        }`}
      >
        {initializeDocumentLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="admin__adddocument--wrapper">
              <div className="admin__adddocument--page-title">
                <p className="btn--back" onClick={() => history.goBack()}>
                  {t('Back to Documents')}
                </p>
                <h2>
                  {documentId ? 'Edit' : 'Create'} {t('Document')}
                </h2>
              </div>
              <div className="admin__adddocument--container">
                <div className="admin__adddocument--left-col">
                  <div className="admin__adddocument--information">
                    <input
                      type="text"
                      id="document-titl"
                      name="document-titl"
                      placeholder={t('Document Title *')}
                      value={title}
                      onChange={e =>
                        handleDocumentData(e.target.value, 'title')
                      }
                    />
                    <input
                      type="text"
                      id="document-description"
                      name="document-description"
                      placeholder={t('Description')}
                      value={description}
                      onChange={e =>
                        handleDocumentData(e.target.value, 'description')
                      }
                    />
                    <input
                      type="text"
                      id="document-author"
                      name="document-author"
                      placeholder={t('Author')}
                      value={author}
                      onChange={e =>
                        handleDocumentData(e.target.value, 'author')
                      }
                    />
                    <input
                      type="checkbox"
                      className="form-input--checkbox"
                      id="enddocument"
                      name="enddocument"
                      checked={isBanner}
                    />
                    <label
                      onClick={e => handleDocumentData(!isBanner, 'isBanner')}
                    >
                      {t('Add to Banner')}
                    </label>
                    <input
                      type="checkbox"
                      className="form-input--checkbox"
                      id="enddocument"
                      name="enddocument"
                      checked={isFeatured}
                    />
                    <label
                      onClick={e =>
                        handleDocumentData(!isFeatured, 'isFeatured')
                      }
                    >
                      {t('Add to Featured')}
                    </label>
                  </div>
                  <AddReelCategoryGroup
                    reels={reels}
                    listOfReelIdCategoryIdAndGroups={
                      listOfReelIdCategoryIdAndGroups
                    }
                    reelCategoryGroupObj={reelCategoryGroupObj}
                    handleData={handleDocumentData}
                  />

                  <div className="admin__adddocument--assessments accordion">
                    <h3>{t('Related Assessment')}</h3>
                    <div className="admin__adddocument--inner-container">
                      <p className="label">{t('Assessment')}</p>
                      <MultiSelect
                        title="Video"
                        options={
                          relatedAssessments && relatedAssessments.length
                            ? relatedAssessments.map(assessment => ({
                                label: assessment.title,
                                value: assessment._id,
                              }))
                            : []
                        }
                        value={selectedAssessments}
                        onChange={option =>
                          handleDocumentData(option, 'selectedAssessments')
                        }
                        placeholder={t('Select Assessment')}
                      />{' '}
                      {renderSelectedVideoOrDocument('selectedAssessments')}
                    </div>
                  </div>

                  <div className="admin__adddocument--documents accordion">
                    <h3>{t('Related Videos')}</h3>

                    <div className="admin__adddocument--inner-container">
                      <p className="label">video</p>
                      <MultiSelect
                        title="Video"
                        options={
                          relatedVideos && relatedVideos.length
                            ? relatedVideos.map(video => ({
                                label: video.title,
                                value: video._id,
                              }))
                            : []
                        }
                        value={selectedVideos}
                        onChange={option =>
                          handleDocumentData(option, 'selectedVideos')
                        }
                        placeholder={t('Select Video')}
                      />{' '}
                      {renderSelectedVideoOrDocument('selectedVideos')}
                    </div>
                  </div>
                </div>
                <div className="admin__adddocument--right-col">
                  <PreviewImageView
                    title={'Document Preview Image'}
                    imageSrc={previewImgUrl || previewimg}
                    isImageViaUrl={isPreviewImgViaUrl}
                    imageUrl={previewImgUrl}
                    handleData={handleDocumentData}
                    // handleFileAccept={handleFileAccept}
                    isImageViaUrlPropertyName={'isPreviewImgViaUrl'}
                    previewImgUrlPropertyName={'previewImgUrl'}
                    previewImagePropertyName={'previewImage'}
                    setIsValidUrl={setIsValidUrl}
                    isValidUrl={isValidUrl}
                  />
                  <div className="admin__adddocument--document-theatermode">
                    <p>
                      {t('Upload Document')} <MandatoryMark />
                    </p>
                    <img
                      src={documentFile || documentUrl ? pdfSelected : updocimg}
                      alt=""
                      className="admin__adddocument--document-preview-img"
                      onClick={() => setIsPdfViewModalOpen(true)}
                    />
                    {showProgressBar && (
                      <UploadItem progress={documentUploadProgress} />
                    )}
                    <input
                      type="radio"
                      className="form-input--checkbox"
                      id="enddocument"
                      name="enddocument"
                      checked={!isDocumentViaUrl}
                    />
                    <label
                      onClick={() =>
                        handleDocumentData(false, 'isDocumentViaUrl')
                      }
                    >
                      {t('Upload Documents')}
                    </label>
                    <input
                      type="file"
                      id="upload-preview-vid"
                      name="upload-preview-vid"
                      placeholder={t('File Name')}
                      accept={systemConfig.documentFileTypes}
                      onChange={e => handleFileAccept(e, 'documentFile')}
                    />

                    <input
                      type="radio"
                      className="form-input--checkbox"
                      id="enddocument"
                      name="enddocument"
                      checked={isDocumentViaUrl}
                    />
                    <label
                      onClick={() =>
                        handleDocumentData(true, 'isDocumentViaUrl')
                      }
                    >
                      {t('Upload Document via URL')}
                    </label>
                    <input
                      type="text"
                      id="upload-preview-vid-url"
                      name="upload-preview-vid-url"
                      placeholder={t('URL')}
                      value={documentUrl}
                      onChange={e =>
                        handleDocumentData(e.target.value, 'documentUrl')
                      }
                    />
                  </div>
                  <div className="admin__adddocument--tags">
                    <p>{t('Tags')}</p>
                    <input
                      type="text"
                      id="upload-tags"
                      name="upload-tags"
                      placeholder={t('Seperate your tags with a comma')}
                      value={tags}
                      onChange={e => handleDocumentData(e.target.value, 'tags')}
                    />
                    <div className="admin__adddocument--tags-selected">
                      {tags &&
                        tags.split(',').map((tag, index) => (
                          <p key={index}>
                            {tag}
                            <span className="close-icon">
                              <a
                                className="btn-close"
                                aria-hidden="true"
                                onClick={() => {
                                  const tempTags = tags.split(',');
                                  handleDocumentData(
                                    tempTags
                                      .filter(tempTag => tempTag !== tag)
                                      .join(),
                                    'tags'
                                  );
                                }}
                              >
                                &times;
                              </a>
                            </span>
                          </p>
                        ))}
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="form-input--checkbox"
                      id="enddocument"
                      name="enddocument"
                      checked={allowDownload}
                    />
                    <label
                      onClick={e =>
                        handleDocumentData(!allowDownload, 'allowDownload')
                      }
                    >
                      {t('Allow Download')}
                    </label>
                  </div>
                </div>
                <div className="admin__adddocument--buttons">
                  {!documentId ? (
                    <>
                      <button
                        className="btn btn--tertiary"
                        onClick={() => resetFields()}
                      >
                        {t('Reset')}
                      </button>
                      <button
                        className={`
                        btn btn--secondary
                        ${
                          createDocumentLoading && isSaveProcess
                            ? 'btn--loader'
                            : ''
                        }
                      `}
                        onClick={() => {
                          setIsSaveProcess(true);
                          handleCreateDocument(false);
                        }}
                      >
                        {t('Save')}
                      </button>
                      {isHavePublishAccess && (
                        <button
                          className={`
                        btn btn--primary btn--last
                        ${
                          createDocumentLoading && isPublishProcess
                            ? 'btn--loader'
                            : ''
                        }
                      `}
                          onClick={() => {
                            setIsPublishProcess(true);
                            handleCreateDocument(true);
                          }}
                        >
                          {t('Publish')}
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className={`btn btn--secondary ${
                        updateDocumentLoading && isUpdateProcess
                          ? 'btn--loader'
                          : ''
                      }`}
                      onClick={() => {
                        setIsUpdateProcess(true);
                        handleUpdateDocument(documentData.isPublished);
                      }}
                      disabled={updateDocumentLoading && isUpdateProcess}
                    >
                      {t('Update')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isPdfViewModalOpen &&
        (documentFile !== '' || (isDocumentViaUrl && documentUrl !== '')) && (
          <ViewPDFModal
            pdfUrl={documentUrl}
            handleClose={() => setIsPdfViewModalOpen(false)}
          />
        )}
      {isSubmitSuccessModalOpen && (
        <ProceedMessageModal
          isSuccess={isSubmitSuccessModalOpen.isSuccess}
          message={isSubmitSuccessModalOpen.message}
          handleClose={() => setIsSubmitSuccessModalOpen(null)}
          handleSuccess={() => {
            setIsSubmitSuccessModalOpen(null);
            history.goBack();
          }}
          handleError={() => setIsSubmitSuccessModalOpen(null)}
        />
      )}
      {isValidationError && (
        <ErrorMessageModal
          errorData={isValidationError}
          handleSubmit={() => setIsValidationError(null)}
        />
      )}
    </div>
  );
};

export default CreateDocument;

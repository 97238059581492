import $ from 'jquery';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import previewimg from '../../../../assets/images/sample-img.png';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import PreviewImageView from '../../../../Components/CreateEditComponents/PreviewImageView';
import PreviewVideoView from '../../../../Components/CreateEditComponents/PreviewVideoView';
import SummaryBox from '../../../../Components/CreateEditComponents/SummaryBox';
import TheatreModeVideoView from '../../../../Components/CreateEditComponents/TheatreModeVideoView';
import Loader from '../../../../Components/Loader/loader';
import ErrorMessageModal from '../../../../Components/Modal/ErrorMessageModal';
import ProceedMessageModal from '../../../../Components/Modal/ProceedMessageModal';
import { messageConfigs } from '../../../../Helper/PopupMessageConfig';
import { getOrganizedListOfReelIdCategoryIdAndGroups } from '../../../../Helper/SystemManager';
import { validateFields } from '../../../../Helper/ValidationsManager';
import useApi from '../../../../Hooks/useApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import useFileUpload from '../../../../Hooks/useFileUpload';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import {
  CREATE_VIDEO_FAIL,
  CREATE_VIDEO_REQUEST,
  CREATE_VIDEO_RESET,
  CREATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAIL,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_RESET,
  UPDATE_VIDEO_SUCCESS,
} from '../../../../redux/actionTypes/video';
import AddReelCategoryGroup from '../Common/AddReelCategoryGroup';

const summaryObj = {
  notes: '',
  startTimeMin: '0',
  endTimeMin: '0',
  startTimeSec: '0',
  endTimeSec: '0',
};

const reelCategoryGroupObj = {
  reelId: '',
  categoryId: '',
  groups: [],
};

const selectedAssessmentObj = {
  isMidVideo: true,
  id: null,
  startTimeMin: '',
  triggerTimeMin: '',
  startTimeSec: '',
  triggerTimeSec: '',
};

const initialState = {
  title: '',
  description: '',
  author: '',
  isNativeBannerAndFeatured: false,
  isFeatured: false,
  isBanner: false,
  tags: '',
  videoUrl: '',
  previewVideoUrl: '',
  previewImgUrl: '',
  previewImgFile: null,
  isVideoViaUrl: false,
  isPreviewVideoViaUrl: false,
  isPreviewImgViaUrl: false,
  isPublished: '',
  subtitleFile: '',
  summaries: [_.clone(summaryObj)],
  listOfReelIdCategoryIdAndGroups: [_.clone(reelCategoryGroupObj)],
  selectedAssessments: [_.clone(selectedAssessmentObj)],
  selectedDocuments: [],
};

const CreateVideo = ({ match, history }) => {
  const { t } = useTranslation();
  const { videoId, reelId, groupId } = match.params;
  const isHavePublishAccess = usePrivilegesApi(
    PrivilegeTypes.VIDEO.UPLOAD_VIDEO
  );

  const [videoData, setVideoData] = useState(_.cloneDeep(initialState));
  const [isValidationError, setIsValidationError] = useState(null);
  const [isPublishProcess, setIsPublishProcess] = useState(false);
  const [isSaveProcess, setIsSaveProcess] = useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(null);
  const [isUpdateProcess, setIsUpdateProcess] = useState(false);
  const [isValidPreImageUrl, setIsValidPreImageUrl] = useState(true);
  const [isValidPreVideoUrl, setIsValidPreVideoUrl] = useState(true);
  const [isValidTheatreVideoUrl, setIsValidTheatreVideoUrl] = useState(true);
  const [videoDataFiles, setVideoDataFiles] = useState({
    previewImage: '',
    previewVideo: '',
    theatreVideo: '',
  });

  const dispatch = useDispatch();
  const [fetchData] = useFetchDataApi();
  const [createVideo] = useApi();
  const [fetchVideoDataById] = useFetchDataApi();
  const {
    findVideoData,
    intializeVideoData,
    updateVideoLoading,
    intializeVideoLoading,
    updateVideoSuccess,
    createVideoLoading,
    createVideoSuccess,
    createVideoFail,
    updateVideoFail,
    fileUploadingPercentage,
  } = useSelector(state => state?.video);

  const {
    documents = [],
    reels = [],
    endAssessment = [],
    midAssessment = [],
  } = intializeVideoData;

  const [subtitleUpload, subtitleUploadUrl] = useFileUpload();

  useEffect(() => {
    if (reels && reels.length && reelId && groupId) {
      const createFromGroup = {
        reelId: reelId,
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
          },
        ],
      };

      const intialVideoData = { ...videoData };
      intialVideoData['listOfReelIdCategoryIdAndGroups'] =
        getOrganizedListOfReelIdCategoryIdAndGroups(
          [_.clone(createFromGroup)],
          reels
        );

      setVideoData(intialVideoData);
    }
  }, [reels, reelId, groupId]);

  useEffect(() => {
    if (createVideoSuccess) {
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message: isPublishProcess
          ? messageConfigs.success.video.PUBLISH
          : messageConfigs.success.video.SAVE,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () => dispatch({ type: CREATE_VIDEO_RESET });
    }
  }, [createVideoSuccess]);

  useEffect(() => {
    if (createVideoFail) {
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () => dispatch({ type: CREATE_VIDEO_RESET });
    }
  }, [createVideoFail]);

  useEffect(() => {
    if (updateVideoSuccess) {
      setIsUpdateProcess(false);
      setIsPublishProcess(false);
      setIsSaveProcess(false);

      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message: messageConfigs.success.video.UPDATE,
      });

      return () => dispatch({ type: UPDATE_VIDEO_RESET });
    }
  }, [updateVideoSuccess]);

  useEffect(() => {
    if (updateVideoFail) {
      setIsUpdateProcess(false);
      setIsPublishProcess(false);
      setIsSaveProcess(false);

      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      return () => dispatch({ type: UPDATE_VIDEO_RESET });
    }
  }, [updateVideoFail]);

  useEffect(() => {
    fetchData({ type: 'INTIALIZE_CREATE_VIDEO' });
  }, []);

  useEffect(() => {
    if (
      videoId &&
      Object.keys(intializeVideoData).length !== 0 &&
      intializeVideoData.constructor === Object
    ) {
      fetchVideoDataById({ objectId: videoId, type: 'FIND_VIDEO' });
    }
  }, [intializeVideoData]);

  useEffect(() => {
    if (findVideoData && videoId) {
      const {
        _id,
        title,
        author,
        description,
        tags,
        documentIds,
        endVideoAssessmentIds,
        isBanner,
        isFeatured,
        isNativeBannerAndFeatured,
        isPreviewImgViaUrl,
        isPreviewVideoViaUrl,
        isVideoViaUrl,
        midVideoAssessmentIds,
        previewImgUrl,
        previewVideoUrl,
        summaries,
        videoUrl,
        isPublished,
        subtitleS3BucketUrl,
      } = findVideoData;

      const selectedMidAssessmentsOptions = midAssessment
        .filter(({ _id }) => midVideoAssessmentIds.includes(_id))
        .map(assessment => ({
          id: assessment._id,
          startTimeMin:
            assessment.startTime !== ''
              ? assessment.startTime.split(':')[0]
              : '',
          startTimeSec:
            assessment.startTime !== ''
              ? assessment.startTime.split(':')[1]
              : '',
          triggerTimeMin:
            assessment.triggerTime !== ''
              ? assessment.triggerTime.split(':')[0]
              : '',
          triggerTimeSec:
            assessment.triggerTime !== ''
              ? assessment.triggerTime.split(':')[1]
              : '',
          isMidVideo: true,
        }));

      const selectedEndAssessmentsOptions = endAssessment
        .filter(({ _id }) => endVideoAssessmentIds.includes(_id))
        .map(assessment => ({
          id: assessment._id,
          startTimeMin: '',
          startTimeSec: '',
          triggerTimeMin: '',
          triggerTimeSec: '',
          isMidVideo: false,
        }));

      const selectedDocumentOptions = documents
        .filter(({ _id }) => documentIds.includes(_id))
        .map(documentFile => ({
          label: documentFile.title,
          value: documentFile._id,
        }));

      const selectedAssessmentOptions = [
        ...selectedEndAssessmentsOptions,
        ...selectedMidAssessmentsOptions,
      ];

      const orgnaizedSummary = summaries.map(summary => {
        const { notes, endTime, startTime } = summary;

        return {
          notes,
          endTimeMin: endTime !== '' ? endTime.split(':')[0] : 0,
          endTimeSec: endTime !== '' ? endTime.split(':')[1] : 0,
          startTimeMin: startTime !== '' ? startTime.split(':')[0] : 0,
          startTimeSec: startTime !== '' ? startTime.split(':')[1] : 0,
        };
      });

      const editVideoData = { ...videoData };
      editVideoData['_id'] = _id;
      editVideoData['title'] = title;
      editVideoData['description'] = description;
      editVideoData['author'] = author;
      editVideoData['isNativeBannerAndFeatured'] = isNativeBannerAndFeatured;
      editVideoData['isFeatured'] = isFeatured;
      editVideoData['isBanner'] = isBanner;
      editVideoData['tags'] = tags.join();
      editVideoData['previewImgUrl'] = previewImgUrl;
      editVideoData['isPreviewImgViaUrl'] = isPreviewImgViaUrl;
      editVideoData['videoUrl'] = videoUrl;
      editVideoData['previewVideoUrl'] = previewVideoUrl;
      editVideoData['previewImgFile'] = previewImgFile;
      editVideoData['isVideoViaUrl'] = isVideoViaUrl;
      editVideoData['isPreviewVideoViaUrl'] = isPreviewVideoViaUrl;
      editVideoData['subtitleFile'] = subtitleS3BucketUrl;
      editVideoData['summaries'] = orgnaizedSummary;
      editVideoData['selectedDocuments'] = selectedDocumentOptions;
      editVideoData['selectedAssessments'] = selectedAssessmentOptions;
      editVideoData['isPublished'] = isPublished;
      editVideoData['listOfReelIdCategoryIdAndGroups'] =
        getOrganizedListOfReelIdCategoryIdAndGroups(
          findVideoData.listOfReelIdCategoryIdAndGroups,
          reels
        );

      setVideoData(editVideoData);
    }
  }, [findVideoData]);

  useEffect(() => {
    setTimeout(() => {
      var allPanels = $('.accordion .admin__createvideo--inner-container');
      $('.accordion h3').unbind();
      $('.accordion h3').click(function () {
        $(this)
          .parent()
          .children('.admin__createvideo--inner-container')
          .slideUp();
        $(this).addClass('closed');
        if (
          $(this)
            .parent()
            .children('.admin__createvideo--inner-container')
            .css('display') == 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__createvideo--inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });
    }, 0);
  });

  const {
    _id,
    title,
    description,
    author,
    isNativeBannerAndFeatured,
    isFeatured,
    isBanner,
    tags,
    videoUrl,
    previewVideoUrl,
    previewImgUrl,
    previewImgFile,
    isVideoViaUrl,
    isPreviewVideoViaUrl,
    isPreviewImgViaUrl,
    subtitleFile,
    summaries,
    listOfReelIdCategoryIdAndGroups,
    selectedAssessments,
    selectedDocuments,
  } = videoData;

  const { previewImage, previewVideo, theatreVideo } = videoDataFiles;

  const handleVideoData = (value, property) => {
    const tempVideoData = { ...videoData };
    tempVideoData[property] = value;
    setVideoData(tempVideoData);
  };

  const handleVideoFileData = (value, property) => {
    const tempVideoDataFiles = { ...videoDataFiles };
    tempVideoDataFiles[property] = value;
    setVideoDataFiles(tempVideoDataFiles);
  };

  const handleFileAccept = event => {
    const file = event.target.files[0];
    subtitleUpload(file);
  };

  useEffect(() => {
    subtitleUploadUrl && handleVideoData(subtitleUploadUrl, 'subtitleFile');
  }, [subtitleUploadUrl]);

  const handleCreateVideo = isPublished => {
    const formErrors = validateFields({
      type: 'VIDEO',
      formData: {
        title,
        listOfReelIdCategoryIdAndGroups,
        theatreVideoData: videoUrl,
      },
    });

    if (formErrors) {
      setIsValidationError(formErrors);
      return;
    }
    if (
      (!isValidPreImageUrl && isPreviewImgViaUrl) ||
      (!isValidPreVideoUrl && isPreviewVideoViaUrl) ||
      (!isValidTheatreVideoUrl && isVideoViaUrl)
    ) {
      return;
    }

    const formData = new FormData();
    const templistOfReelIdCategoryIdAndGroups = [
      ...listOfReelIdCategoryIdAndGroups,
    ];

    const videoInfo = {
      title,
      description,
      author,
      isNativeBannerAndFeatured: false,
      isFeatured,
      isBanner,
      isEdit: !!videoId,
      tags: tags.split(','),
      videoUrl,
      previewVideoUrl,
      previewImgUrl,
      isVideoViaUrl: true,
      isPreviewVideoViaUrl: isPreviewImgViaUrl,
      isPreviewImgViaUrl,
      documentIds: selectedDocuments.map(({ value }) => value),
      summaries: summaries.map(summary => {
        const { notes, startTimeMin, startTimeSec, endTimeMin, endTimeSec } =
          summary;

        return {
          notes,
          startTime: `${startTimeMin}:${startTimeSec}`,
          endTime: `${endTimeMin}:${endTimeSec}`,
        };
      }),
      listOfReelIdCategoryIdAndGroups: templistOfReelIdCategoryIdAndGroups.map(
        reel => {
          const tempReel = reel;
          if (!tempReel.categoryId) {
            delete tempReel.categoryId;
          }
          tempReel.groups.map(group => {
            delete group.name;
            return group;
          });
          return tempReel;
        }
      ),
      midVideoAssessmentIds: selectedAssessments
        .filter(({ isMidVideo, id }) => id && isMidVideo)
        .map(({ id }) => id),
      endVideoAssessmentIds: selectedAssessments
        .filter(({ isMidVideo, id }) => id && !isMidVideo)
        .map(({ id }) => id),
      documentIds: selectedDocuments.length
        ? selectedDocuments.map(({ value }) => value)
        : [],
      isPublished,
    };

    formData.append(
      'video',
      new Blob([JSON.stringify(videoInfo)], { type: 'application/json' })
    );

    let subTitleParam = '';

    if (subtitleFile) {
      subTitleParam = `?files=subtitle-${subtitleFile}`;
    }

    createVideo(
      `/video/add-video${subTitleParam}`,
      CREATE_VIDEO_REQUEST,
      CREATE_VIDEO_SUCCESS,
      CREATE_VIDEO_FAIL,
      formData,
      '',
      'POST',
      null,
      'isReelService'
    );
  };

  const handleUpdateVideo = isPublished => {
    const formErrors = validateFields({
      type: 'VIDEO',
      formData: {
        title,
        listOfReelIdCategoryIdAndGroups,
      },
    });

    if (formErrors) {
      setIsValidationError(formErrors);
      return;
    }

    if (
      (!isValidPreImageUrl && isPreviewImgViaUrl) ||
      (!isValidPreVideoUrl && isPreviewVideoViaUrl) ||
      (!isValidTheatreVideoUrl && isVideoViaUrl)
    ) {
      return;
    }

    const formData = new FormData();
    const templistOfReelIdCategoryIdAndGroups = _.cloneDeep(
      listOfReelIdCategoryIdAndGroups
    );

    const videoInfo = {
      _id,
      title,
      description,
      author,
      isNativeBannerAndFeatured: false,
      isFeatured,
      isBanner,
      isEdit: !!videoId,
      tags: tags.split(','),
      videoUrl,
      isPublished,
      previewVideoUrl,
      previewImgUrl,
      isVideoViaUrl: true,
      isPreviewVideoViaUrl: true,
      isPreviewImgViaUrl,
      documentIds: selectedDocuments.map(({ value }) => value),
      summaries: summaries.map(summary => {
        const { notes, startTimeMin, startTimeSec, endTimeMin, endTimeSec } =
          summary;

        return {
          notes,
          startTime: `${startTimeMin}:${startTimeSec}`,
          endTime: `${endTimeMin}:${endTimeSec}`,
        };
      }),
      listOfReelIdCategoryIdAndGroups: templistOfReelIdCategoryIdAndGroups.map(
        reel => {
          const tempReel = { ...reel };

          if (!tempReel.categoryId) {
            delete tempReel.categoryId;
          }

          tempReel.groups.map(group => {
            delete group.name;

            return group;
          });

          return tempReel;
        }
      ),
      midVideoAssessmentIds: selectedAssessments
        .filter(({ isMidVideo, id }) => id && isMidVideo)
        .map(({ id }) => id),
      endVideoAssessmentIds: selectedAssessments
        .filter(({ isMidVideo, id }) => id && !isMidVideo)
        .map(({ id }) => id),
    };

    formData.append(
      'video',
      new Blob([JSON.stringify(videoInfo)], { type: 'application/json' })
    );

    let subTitleParam = '';
    if (subtitleFile) {
      subTitleParam = `?files=subtitle-${subtitleFile}`;
    }

    createVideo(
      `/video/add-video${subTitleParam}`,
      UPDATE_VIDEO_REQUEST,
      UPDATE_VIDEO_SUCCESS,
      UPDATE_VIDEO_FAIL,
      formData,
      '',
      'POST',
      null,
      'isReelService'
    );
  };

  const resetFields = () => {
    const resetState = _.cloneDeep(initialState);

    if (groupId && reels) {
      const createFromGroup = {
        reelId: reelId,
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
          },
        ],
      };

      resetState['listOfReelIdCategoryIdAndGroups'] =
        getOrganizedListOfReelIdCategoryIdAndGroups(
          [_.clone(createFromGroup)],
          reels
        );
    }

    setVideoData(resetState);
  };

  return (
    <div>
      <div
        className={`admin__createvideo loading-screen-parent ${
          intializeVideoLoading ? 'setheight' : ''
        }`}
      >
        {intializeVideoLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="admin__createvideo--wrapper">
              <div className="admin__createvideo--page-title">
                <p className="btn--back" onClick={() => history.goBack()}>
                  {t('Back to Videos')}
                </p>
                <h2>{t(`${!videoId ? 'Create' : 'Edit'} Video`)}</h2>
              </div>
              <div className="admin__createvideo--container">
                <div className="admin__createvideo--left-col">
                  <div className="admin__createvideo--information">
                    <input
                      type="text"
                      id="video-titl"
                      name="video-titl"
                      placeholder={t('Video Title *')}
                      value={title}
                      onChange={e => handleVideoData(e.target.value, 'title')}
                    />
                    <input
                      type="text"
                      id="video-description"
                      name="video-description"
                      placeholder={t('Description')}
                      value={description}
                      onChange={e =>
                        handleVideoData(e.target.value, 'description')
                      }
                    />
                    <input
                      type="text"
                      id="video-author"
                      name="video-author"
                      placeholder={t('Author')}
                      value={author}
                      onChange={e => handleVideoData(e.target.value, 'author')}
                    />
                    <input
                      type="checkbox"
                      className="form-input--checkbox"
                      id="endvideo"
                      name="endvideo"
                      checked={isBanner}
                      onChange={() => handleVideoData(!isBanner, 'isBanner')}
                    />
                    <label
                      onClick={() => handleVideoData(!isBanner, 'isBanner')}
                    >
                      {t('Add to Banner')}
                    </label>
                    <input
                      type="checkbox"
                      className="form-input--checkbox"
                      id="endvideo"
                      name="endvideo"
                      checked={isFeatured}
                      onChange={() =>
                        handleVideoData(!isFeatured, 'isFeatured')
                      }
                    />
                    <label
                      onClick={() => handleVideoData(!isFeatured, 'isFeatured')}
                    >
                      {t('Add to Featured')}
                    </label>
                  </div>
                  <AddReelCategoryGroup
                    reels={reels}
                    listOfReelIdCategoryIdAndGroups={
                      listOfReelIdCategoryIdAndGroups
                    }
                    reelCategoryGroupObj={reelCategoryGroupObj}
                    handleData={handleVideoData}
                  />

                  <div className="admin__createvideo--assessments accordion">
                    <h3>{t('Assessment')}</h3>
                    {selectedAssessments &&
                      selectedAssessments.map(
                        (
                          {
                            id,
                            isMidVideo,
                            startTimeMin,
                            triggerTimeMin,
                            startTimeSec,
                            triggerTimeSec,
                          },
                          index
                        ) => (
                          <div
                            className="admin__createvideo--inner-container"
                            key={index}
                          >
                            <p className="label">{t('Assessment')}</p>
                            <input
                              readOnly
                              type="radio"
                              className="form-input--checkbox"
                              checked={isMidVideo}
                            />
                            <label
                              onClick={() => {
                                const tempSelectedAssessments = [
                                  ...selectedAssessments,
                                ];
                                tempSelectedAssessments[
                                  index
                                ].isMidVideo = true;
                                handleVideoData(
                                  tempSelectedAssessments,
                                  'selectedAssessments'
                                );
                              }}
                            >
                              {t('Mid Video')}
                            </label>
                            <input
                              readOnly
                              type="radio"
                              className="form-input--checkbox"
                              checked={!isMidVideo}
                            />
                            <label
                              onClick={() => {
                                const tempSelectedAssessments = [
                                  ...selectedAssessments,
                                ];
                                tempSelectedAssessments[
                                  index
                                ].isMidVideo = false;
                                handleVideoData(
                                  tempSelectedAssessments,
                                  'selectedAssessments'
                                );
                              }}
                            >
                              {t('End Video')}
                            </label>

                            <div className="section--right-column createassessment">
                              <Dropdown
                                title="Edit"
                                options={
                                  isMidVideo
                                    ? midAssessment.map(({ title, _id }) => ({
                                        label: title,
                                        value: _id,
                                      }))
                                    : endAssessment.map(({ title, _id }) => ({
                                        label: title,
                                        value: _id,
                                      }))
                                }
                                value={id}
                                onChange={({ value }) => {
                                  const tempSelectedAssessments = [
                                    ...selectedAssessments,
                                  ];
                                  const assessment = midAssessment.find(
                                    assessment => assessment._id === value
                                  );

                                  if (assessment) {
                                    const { startTime, triggerTime } =
                                      assessment;

                                    tempSelectedAssessments[index] = {
                                      isMidVideo: true,
                                      startTimeMin:
                                        startTime !== ''
                                          ? startTime.split(':')[0]
                                          : '',
                                      startTimeSec:
                                        startTime !== ''
                                          ? startTime.split(':')[1]
                                          : '',
                                      triggerTimeMin:
                                        triggerTime !== ''
                                          ? triggerTime.split(':')[0]
                                          : '',
                                      triggerTimeSec:
                                        triggerTime !== ''
                                          ? triggerTime.split(':')[1]
                                          : '',
                                    };
                                  }

                                  tempSelectedAssessments[index].id = value;
                                  handleVideoData(
                                    tempSelectedAssessments,
                                    'selectedAssessments'
                                  );
                                }}
                                placeholder={t('Assign Assessment')}
                              />
                              {isMidVideo && (
                                <>
                                  <div className="admin__createassessment--placement-time">
                                    <p>{t('Start Time')}</p>
                                    <input
                                      disabled
                                      type="number"
                                      id="starttime min"
                                      name="starttime min"
                                      placeholder="0"
                                      value={startTimeMin}
                                    />
                                    <p>{t('min')}</p>
                                    <input
                                      disabled
                                      type="number"
                                      id="starttime sec"
                                      name="starttime sec"
                                      placeholder="0"
                                      value={startTimeSec}
                                    />
                                    <p>{t('sec')}</p>
                                  </div>
                                  <div className="admin__createassessment--placement-time">
                                    <p>{t('Trigger Time')}</p>
                                    <input
                                      disabled
                                      type="number"
                                      id="triggertime min"
                                      name="triggertime min"
                                      placeholder="0"
                                      value={triggerTimeMin}
                                    />
                                    <p>{t('min')}</p>
                                    <input
                                      disabled
                                      type="number"
                                      id="triggertime sec"
                                      name="triggertime sec"
                                      placeholder="00"
                                      value={triggerTimeSec}
                                    />
                                    <p>{t('sec')}</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    <p
                      className="addanother"
                      onClick={() => {
                        handleVideoData(
                          [
                            ...selectedAssessments,
                            _.clone(selectedAssessmentObj),
                          ],
                          'selectedAssessments'
                        );
                      }}
                    >
                      {t('Add to another Assessment')}
                    </p>
                  </div>

                  <div className="admin__createvideo--documents accordion">
                    <h3>{t('Assign to Documents')}</h3>
                    <div className="admin__createvideo--inner-container">
                      <p className="label">{t('Documents')}</p>
                      <MultiSelect
                        title="Document"
                        options={
                          documents && documents.length
                            ? documents.map(document => ({
                                label: document.title,
                                value: document._id,
                              }))
                            : []
                        }
                        value={selectedDocuments}
                        onChange={option =>
                          handleVideoData(option, 'selectedDocuments')
                        }
                        placeholder={t('Select documents')}
                      />
                      <div className="admin__createassessment--tags-selected">
                        {selectedDocuments.length
                          ? selectedDocuments.map(({ label, value }, index) => (
                              <p key={index}>
                                {label}
                                <span className="close-icon">
                                  <a
                                    className="btn-close"
                                    aria-hidden="true"
                                    onClick={() => {
                                      const tempSelectedDocuments = [
                                        ...selectedDocuments,
                                      ];
                                      handleVideoData(
                                        tempSelectedDocuments.filter(
                                          selectedDocument =>
                                            selectedDocument.value !== value
                                        ),
                                        'selectedDocuments'
                                      );
                                    }}
                                  >
                                    &times;
                                  </a>
                                </span>
                              </p>
                            ))
                          : null}
                      </div>
                      {/* <div className="section--right-column documents">
                    <p className="addanother">Add another Document</p>
                  </div> */}
                    </div>
                  </div>
                </div>
                <div className="admin__createvideo--right-col">
                  <PreviewImageView
                    title={'Video Preview Image'}
                    imageSrc={previewImgUrl || previewimg}
                    isImageViaUrl={isPreviewImgViaUrl}
                    imageUrl={previewImgUrl}
                    handleData={handleVideoData}
                    isImageViaUrlPropertyName={'isPreviewImgViaUrl'}
                    previewImgUrlPropertyName={'previewImgUrl'}
                    previewImagePropertyName={'previewImage'}
                    setIsValidUrl={setIsValidPreImageUrl}
                    isValidUrl={isValidPreImageUrl}
                  />
                  <PreviewVideoView
                    title={'Video Preview'}
                    videoUrl={previewVideoUrl}
                    videoUrlPropertyName={'previewVideoUrl'}
                    isVideoViaUrl={isPreviewVideoViaUrl}
                    isVideoViaUrlPropertyName={'isPreviewVideoViaUrl'}
                    handleData={handleVideoData}
                    videoFilepropertyName={'previewVideo'}
                    setIsValidUrl={setIsValidPreVideoUrl}
                    isValidUrl={isValidPreVideoUrl}
                  />
                  <TheatreModeVideoView
                    title={'Theatre Mode Video'}
                    videoUrl={videoUrl}
                    videoUrlPropertyName={'videoUrl'}
                    isVideoViaUrl={isVideoViaUrl}
                    isVideoViaUrlPropertyName={'isVideoViaUrl'}
                    handleData={handleVideoData}
                    videoFilepropertyName={'theatreVideo'}
                    setIsValidUrl={setIsValidTheatreVideoUrl}
                    isValidUrl={isValidTheatreVideoUrl}
                    subtitleFile={subtitleFile}
                  />
                  <div className="admin__createvideo--summary">
                    <SummaryBox
                      summaries={summaries}
                      handleChange={handleVideoData}
                      initialObject={summaryObj}
                    />
                  </div>
                  {!isVideoViaUrl && (
                    <div className="admin__createvideo--subtitle">
                      <p>{t('Subtitle')}</p>
                      <input
                        type="file"
                        id="upload-subtitle"
                        name="upload-subtitle"
                        placeholder={t('Upload Subtitle')}
                        accept=".srt"
                        onChange={e => handleFileAccept(e)}
                      />
                    </div>
                  )}
                  <div className="admin__createvideo--tags">
                    <p>{t('Tags')}</p>
                    <input
                      type="text"
                      id="upload-tags"
                      name="upload-tags"
                      placeholder={t('Seperate your tags with a comma')}
                      value={tags}
                      onChange={e => handleVideoData(e.target.value, 'tags')}
                    />
                    <div className="admin__createvideo--tags-selected">
                      {tags &&
                        tags.split(',').map((tag, index) => (
                          <p key={index}>
                            {tag}
                            <span className="close-icon">
                              <a
                                className="btn-close"
                                aria-hidden="true"
                                onClick={() => {
                                  const tempTags = tags.split(',');
                                  handleVideoData(
                                    tempTags
                                      .filter(tempTag => tempTag !== tag)
                                      .join(),
                                    'tags'
                                  );
                                }}
                              >
                                &times;
                              </a>
                            </span>
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="admin__createvideo--buttons">
                  {!videoId ? (
                    <>
                      <button
                        className="btn btn--tertiary"
                        onClick={() => resetFields()}
                      >
                        {t('Reset')}
                      </button>
                      <button
                        className={`
                        btn btn--secondary
                        ${
                          createVideoLoading && isSaveProcess
                            ? 'btn--loader'
                            : ''
                        }
                      `}
                        onClick={() => {
                          setIsSaveProcess(true);
                          handleCreateVideo(false);
                        }}
                      >
                        {t('Save')}
                      </button>
                      {isHavePublishAccess && (
                        <button
                          className={`
                        btn btn--primary btn--last
                        ${
                          createVideoLoading && isPublishProcess
                            ? 'btn--loader'
                            : ''
                        }
                      `}
                          onClick={() => {
                            setIsPublishProcess(true);
                            handleCreateVideo(true);
                          }}
                        >
                          {t('Publish')}
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className={`btn btn--secondary ${
                        updateVideoLoading && isUpdateProcess
                          ? 'btn--loader'
                          : ''
                      }`}
                      onClick={() => {
                        setIsUpdateProcess(true);
                        handleUpdateVideo(videoData.isPublished);
                      }}
                      disabled={updateVideoLoading && isUpdateProcess}
                    >
                      {t('Update')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isSubmitSuccessModalOpen && (
        <ProceedMessageModal
          isSuccess={isSubmitSuccessModalOpen.isSuccess}
          message={isSubmitSuccessModalOpen.message}
          handleClose={() => setIsSubmitSuccessModalOpen(null)}
          handleSuccess={() => {
            setIsSubmitSuccessModalOpen(null);
            history.goBack();
          }}
          handleError={() => setIsSubmitSuccessModalOpen(null)}
        />
      )}
      {isValidationError && (
        <ErrorMessageModal
          errorData={isValidationError}
          handleSubmit={() => setIsValidationError(null)}
        />
      )}
    </div>
  );
};

export default CreateVideo;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import useApi from '../../Hooks/useApi';
import {
  SEND_ASSESSMENT_RESULT_FAIL,
  SEND_ASSESSMENT_RESULT_REQUEST,
  SEND_ASSESSMENT_RESULT_SUCCESS,
  UPDATE_ASSESSMENT_VIEWS_FAIL,
  UPDATE_ASSESSMENT_VIEWS_REQUEST,
  UPDATE_ASSESSMENT_VIEWS_SUCCESS,
  RESET_ASSESSMENT,
} from '../../redux/actionTypes/assesment';
import { converTimeInMinAndSec } from '../../Helper/SystemManager';

const noToWord = ['', 'one', 'two', 'three'];

const MidVideoAssessment = ({
  videoId,
  assessmentId,
  isMidVideoQuesionEnd,
  isMidVideoAssessmentAttempt,
  hanldeMidVideoEnd,
  handleMidVideoStart,
  handleContinueMidVideo,
  handleMideoVideoEnd,
  isAllAnswersCorrect,
  handleResult,
  handleAttemptExam,
  midVideoQuestions = [],
}) => {
  const { t } = useTranslation();
  const [sampleMidVideoQuestion, setSampleMidVideoQuestion] = useState([]);
  const [midVideoQuestionNo, setMidVideoQuestionNo] = useState(0);
  const [isMidVideoAssessmentCountDown, setIsMidVideoAssessmentCountDown] =
    useState(true);
  const [isQuestionVisible, setIsQuestionVisible] = useState(true);
  const [isViewCorrectAnswer, setIsViewCorrectAnswer] = useState(false);
  const [assessmentAttendingTime, setAssessmentAttendingTime] = useState(0);

  const [sendAssessmentResult] = useApi();
  const [saveAttemptCount] = useApi();
  const { isAdminView } = useSelector(state => state?.auth);
  const {
    sendAssessmentResultSuccess,
    updateAssessmentViewSuccess,
    updateAssessmentViewError,
  } = useSelector(state => state?.assessment);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsMidVideoAssessmentCountDown(false);
    }, 2000);
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      setAssessmentAttendingTime(seconds => seconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [assessmentAttendingTime]);

  // Updating assessment view count
  useEffect(() => {
    dispatch({ type: SEND_ASSESSMENT_RESULT_SUCCESS, payload: null });
    if (!isAdminView) {
      updateAttemptCount();
    }

    return () => {
      dispatch({ type: SEND_ASSESSMENT_RESULT_SUCCESS, payload: null });
      dispatch({ type: UPDATE_ASSESSMENT_VIEWS_SUCCESS, payload: null });
    };
  }, []);

  // Iniating assessment results
  useEffect(() => {
    if (
      !isAdminView &&
      (updateAssessmentViewSuccess || updateAssessmentViewError)
    ) {
      handleSaveAssessmentResult();
    }

    return () =>
      dispatch({ type: SEND_ASSESSMENT_RESULT_SUCCESS, payload: null });
  }, [updateAssessmentViewError, updateAssessmentViewSuccess]);

  const handleSaveAssessmentResult = () => {
    if (!isAdminView) {
      let updatedResult = {
        score: `${
          sampleMidVideoQuestion.filter(
            question => question.correctAnswer === question.providedAns
          ).length
        }/${midVideoQuestions.length}`,
        spentTime: `${
          converTimeInMinAndSec(assessmentAttendingTime).timeInMins
        }:${converTimeInMinAndSec(assessmentAttendingTime).timeInSecs} min`,
        assessmentId,
        videoId,
      };

      // If Assessment started iniate session with empty provided answers
      // Else set user provided answers on submit
      if (assessmentAttendingTime > 0) {
        updatedResult = {
          ...updatedResult,
          answers: sampleMidVideoQuestion.map(({ _id, providedAns }) => ({
            questionId: _id,
            userAnswer: providedAns,
          })),
        };
      } else {
        updatedResult = {
          ...updatedResult,
          answers: [],
        };
      }

      // Adding result id on submit
      if (sendAssessmentResultSuccess) {
        updatedResult = {
          ...updatedResult,
          _id: sendAssessmentResultSuccess?._id,
        };
      }

      sendAssessmentResult(
        '/assessment/update-result',
        SEND_ASSESSMENT_RESULT_REQUEST,
        SEND_ASSESSMENT_RESULT_SUCCESS,
        SEND_ASSESSMENT_RESULT_FAIL,
        updatedResult,
        '',
        'PUT',
        null,
        'isReelService'
      );
    }
  };

  const updateAttemptCount = () => {
    if (!isAdminView) {
      saveAttemptCount(
        `/assessment/update-standalone-views?assessmentId=${assessmentId}`,
        UPDATE_ASSESSMENT_VIEWS_REQUEST,
        UPDATE_ASSESSMENT_VIEWS_SUCCESS,
        UPDATE_ASSESSMENT_VIEWS_FAIL,
        null,
        '',
        'PUT',
        null,
        'isReelService'
      );
    }
  };

  useEffect(() => {
    const tempQuestions = _.cloneDeep(midVideoQuestions).map(question => {
      const tempQuestion = _.cloneDeep(question);
      tempQuestion.providedAns = '';
      tempQuestion.correctAnswer = parseInt(question.correctAnswer);
      return tempQuestion;
    });
    setSampleMidVideoQuestion(tempQuestions);
  }, [midVideoQuestions]);

  const onHandleAnswer = (questionIndex, providedAnsweredIndex) => {
    const tempMidVideoQuestion = [...sampleMidVideoQuestion];
    tempMidVideoQuestion[questionIndex].providedAns = providedAnsweredIndex;
    setSampleMidVideoQuestion(tempMidVideoQuestion);
  };

  return (
    <div
      className="theatre__assessment-wrapper"
      style={{ zIndex: 10, pointerEvents: 'all' }}
    >
      <ul className="theatre__assessment-pagination">
        {sampleMidVideoQuestion.map((question, index) => (
          <li
            className={`${midVideoQuestionNo === index ? 'active' : ''}`}
            key={index}
          />
        ))}
      </ul>
      <div className="theatre__assessment">
        {!isMidVideoQuesionEnd && isMidVideoAssessmentCountDown ? (
          <div className="theatre__assessment-col theatre__assessment-question">
            <h3>{t('You are about to start a mid video assessment...')}</h3>
          </div>
        ) : (
          <div>
            {isQuestionVisible && !isMidVideoQuesionEnd && (
              <>
                {' '}
                {sampleMidVideoQuestion.map(
                  (Question, index) =>
                    index === midVideoQuestionNo && (
                      <div
                        className={`theatre__assessment-col-wrapper ${
                          Question.orientation
                            ? `oriented orientation-${Question.orientation}`
                            : ''
                        }`}
                        key={index}
                      >
                        {Question.questionImageS3BucketURL && (
                          <div className="theatre__assessment-col">
                            <div className="theatre__assessment-question--image">
                              <img src={Question.questionImageS3BucketURL} />
                            </div>
                          </div>
                        )}
                        <div className="theatre__assessment-col theatre__assessment-question">
                          {window?.screen?.width < 992 ? (
                            <div className="assessment__scrollbar">
                              <h4>
                                {t('Question 0')}
                                {index + 1}
                              </h4>
                              <div className="theatre__assessment-question--text">
                                <h3>{Question.quiz}</h3>
                              </div>
                              <form>
                                {Question.options.map((Answer, key) => (
                                  <div className="form-item" key={uuidv4()}>
                                    <input
                                      type="radio"
                                      name="radio-group"
                                      id={key}
                                      checked={Question.providedAns === key}
                                      onChange={() => console.log('')}
                                    />
                                    <label
                                      onClick={() => onHandleAnswer(index, key)}
                                    >
                                      {Answer}
                                    </label>
                                  </div>
                                ))}
                              </form>
                            </div>
                          ) : (
                            <div className="assessment__scrollbar">
                              <h4>
                                {t('Question 0')}
                                {index + 1}
                              </h4>
                              <div className="theatre__assessment-question--text">
                                <h3>{Question.quiz}</h3>
                              </div>
                              <form>
                                {Question.options.map((Answer, key) => (
                                  <div className="form-item" key={uuidv4()}>
                                    <input
                                      type="radio"
                                      name="radio-group"
                                      id={key}
                                      checked={Question.providedAns === key}
                                      onChange={() => console.log('')}
                                    />
                                    <label
                                      onClick={() => onHandleAnswer(index, key)}
                                    >
                                      {Answer}
                                    </label>
                                  </div>
                                ))}
                              </form>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                )}
                <div className="form-actions form-actions--right">
                  <button
                    className="btn btn--primary"
                    style={{ zIndex: 10 }}
                    onClick={() => {
                      if (
                        typeof sampleMidVideoQuestion[midVideoQuestionNo]
                          .providedAns === 'number'
                      ) {
                        setIsViewCorrectAnswer(true);
                        setIsQuestionVisible(false);
                      }
                    }}
                  >
                    {t('Submit')}
                  </button>
                </div>
              </>
            )}
          </div>
        )}

        {isMidVideoQuesionEnd && !isAllAnswersCorrect && (
          <>
            {' '}
            <div className="theatre__assessment-col-wrapper">
              <div className="theatre__assessment-col">
                <h2>
                  {t('Seems like you got')}{' '}
                  {
                    noToWord[
                      sampleMidVideoQuestion.filter(
                        question =>
                          question.correctAnswer !== question.providedAns
                      ).length
                    ]
                  }{' '}
                  {t('wrong')}
                </h2>
                <h3>{t('Would you like to re-watch the last section?')}</h3>
              </div>
            </div>
            <div className="form-actions form-actions--right">
              <button
                className="btn btn--primary"
                style={{ zIndex: 10 }}
                onClick={() => {
                  setIsQuestionVisible(true);
                  setIsViewCorrectAnswer(false);

                  setMidVideoQuestionNo(0);
                  handleMidVideoStart();
                  handleMideoVideoEnd(false);
                }}
              >
                {t('Go Back')}
              </button>
              <button
                className="btn btn--secondary"
                style={{ zIndex: 10 }}
                onClick={() => {
                  setIsQuestionVisible(true);
                  setIsViewCorrectAnswer(false);
                  setMidVideoQuestionNo(0);
                  hanldeMidVideoEnd();
                  handleMideoVideoEnd(false);
                }}
              >
                {t('Continue')}
              </button>
            </div>
          </>
        )}

        {isMidVideoQuesionEnd && isAllAnswersCorrect && (
          <>
            <div className="theatre__assessment-col-wrapper">
              <div className="theatre__assessment-col">
                <h2>
                  {isMidVideoAssessmentAttempt
                    ? 'You attempted this quiz and answered all correctly'
                    : 'You got all correct!'}
                </h2>

                <h3>{t('Please click continue to resume the video')}</h3>
              </div>
            </div>

            <div className="form-actions">
              <button
                className="btn btn--primary"
                style={{ zIndex: 10 }}
                onClick={() => {
                  handleSaveAssessmentResult();
                  setIsQuestionVisible(false);

                  setIsViewCorrectAnswer(false);
                  setMidVideoQuestionNo(0);
                  handleContinueMidVideo();
                  handleMideoVideoEnd(true);
                  handleAttemptExam();
                }}
              >
                {t('Continue')}
              </button>
            </div>
          </>
        )}

        {isViewCorrectAnswer &&
          sampleMidVideoQuestion[midVideoQuestionNo].correctAnswer !==
            sampleMidVideoQuestion[midVideoQuestionNo].providedAns && (
            <div key={uuidv4()}>
              <div className="theatre__assessment-col-wrapper answers-screen">
                <div className="theatre__assessment-col">
                  <h4>
                    {t('Question 0')}
                    {midVideoQuestionNo + 1}
                  </h4>
                  <h3>{sampleMidVideoQuestion[midVideoQuestionNo].quiz}</h3>
                </div>
                <div className="theatre__assessment-col theatre__assessment-question">
                  <div className="theatre__assessment-question--answer">
                    <h4 className="theatre__assessment-question--answer-youranswer">
                      <span>{t('Your Answer:')}</span>
                      {
                        sampleMidVideoQuestion[midVideoQuestionNo].options[
                          sampleMidVideoQuestion[midVideoQuestionNo].providedAns
                        ]
                      }
                    </h4>
                    <h4 className="theatre__assessment-question--answer-correct">
                      <span>{t('Correct Answer:')}</span>
                      {
                        sampleMidVideoQuestion[midVideoQuestionNo].options[
                          sampleMidVideoQuestion[midVideoQuestionNo]
                            .correctAnswer
                        ]
                      }
                    </h4>
                  </div>
                </div>
              </div>

              <div className="form-actions">
                <button
                  className="btn btn--primary"
                  style={{ zIndex: 10 }}
                  onClick={() => {
                    if (
                      midVideoQuestionNo <
                      sampleMidVideoQuestion.length - 1
                    ) {
                      if (
                        typeof sampleMidVideoQuestion[midVideoQuestionNo]
                          .providedAns === 'number'
                      ) {
                        setMidVideoQuestionNo(midVideoQuestionNo + 1);
                        setIsQuestionVisible(true);
                      }
                    } else {
                      handleSaveAssessmentResult();
                      setIsQuestionVisible(false);
                      handleMideoVideoEnd(true);
                      handleResult(
                        sampleMidVideoQuestion.filter(
                          question =>
                            question.correctAnswer === question.providedAns
                        ).length === sampleMidVideoQuestion.length
                      );
                    }

                    setIsViewCorrectAnswer(false);
                  }}
                >
                  {t('Continue')}
                </button>
              </div>
            </div>
          )}

        {isViewCorrectAnswer &&
          sampleMidVideoQuestion[midVideoQuestionNo].correctAnswer ===
            sampleMidVideoQuestion[midVideoQuestionNo].providedAns && (
            <div className="theatre__assessment-col-wrapper answers-screen">
              <div className="theatre__assessment-col">
                <h4>
                  {t('Question 0')}
                  {midVideoQuestionNo + 1}
                </h4>
                <h3>{sampleMidVideoQuestion[midVideoQuestionNo].quiz}</h3>
              </div>
              <div className="theatre__assessment-col theatre__assessment-question">
                <div className="theatre__assessment-question--answer">
                  <h4 className="theatre__assessment-question--answer-correct">
                    <span>{t('Correct Answer:')}</span>
                    {
                      sampleMidVideoQuestion[midVideoQuestionNo].options[
                        sampleMidVideoQuestion[midVideoQuestionNo].correctAnswer
                      ]
                    }
                  </h4>
                </div>
              </div>
              <div className="form-actions">
                <button
                  className="btn btn--primary"
                  style={{ zIndex: 10 }}
                  onClick={() => {
                    if (
                      midVideoQuestionNo <
                      sampleMidVideoQuestion.length - 1
                    ) {
                      if (
                        typeof sampleMidVideoQuestion[midVideoQuestionNo]
                          .providedAns === 'number'
                      ) {
                        setMidVideoQuestionNo(midVideoQuestionNo + 1);
                        setIsQuestionVisible(true);
                      }
                    } else {
                      handleSaveAssessmentResult();
                      setIsQuestionVisible(false);
                      handleMideoVideoEnd(true);
                      handleResult(
                        sampleMidVideoQuestion.filter(
                          question =>
                            question.correctAnswer === question.providedAns
                        ).length === sampleMidVideoQuestion.length
                      );
                    }
                    setIsViewCorrectAnswer(false);
                  }}
                >
                  {t('Continue')}
                </button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default MidVideoAssessment;

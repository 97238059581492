export const ToolTipConfig = {
  general:{
    gridView: 'Tile view',
    tableView: 'Table view',
  },
  adminHome: {
    banner: {
      add: 'Create banner content',
      bin: 'Delete banner item',
      edit: 'Edit banner item',
      unpublish: 'Unpublish banner item',
      publish: 'Publish banner item',
    },
    adminSettings: 'To advanced settings',
    mandatoryReel: {
      editAbove: 'Edit mandatory reel',
      edit: 'Edit',
      bin: 'Remove from the mandatory reel',
      stats: 'Statistics',
    },
    reel: {
      editAbove: 'Edit reel',
      binAbove: 'Remove reel from group',
      edit: 'Edit',
      suspend: 'Unpublish item',
      publish: 'Publish item',
      bin: 'Remove item from the reel',
      stats: 'Statistics',
      addReel: 'Add a reel from another group',
    },
    featured: {
      add: 'Create featured content',
      bin: 'Delete featured item',
      edit: 'Edit featured item',
      unpublish: 'Unpublish featured item',
      publish: 'Publish featured item',
    },
    documentReel: {
      editAbove: 'Edit reel',
      binAbove: 'Remove reel from group',
      addDocumentReel: 'Add a document reel from another group',
    },
  },
  mandatoryReelBulkView: {
    edit: 'Edit',
    bin: 'Remove from the mandatory reel',
    stats: 'Statistics',
  },
  reelBulkView: {
    editReel: 'Edit reel',
    bin: 'Remove reel from group',
    edit: 'Edit',
    suspend: 'Unpublish item',
    publish: 'Publish item',
    bin: 'Remove item from the reel',
    stats: 'Statistics',
  },
  adminSettings: {
    reelTile: {
      edit: 'Rename reel',
      suspend: 'Unpublish reel',
      publish: 'Publish reel',
      bin: 'Remove reel from group',
      stats: 'Reel statictics',
      userAccess: 'Click to edit user access to this reel',
      duplicate: 'Clone reel',
      addReel: 'Add reels and content from other groups',
    },
    mandatoryReel: {
      edit: 'Edit',
      bin: 'Remove from the mandatory reel',
      stats: 'Statistics',
    },
    category: {
      edit: 'Edit category name',
      changeImage: 'Change category image',
    },
    videoTile: {
      edit: 'Edit',
      suspend: 'Unpublish',
      publish: 'Publish',
      bin: 'Remove video from reel',
      stats: 'Statistics',
    },
    assessmentTile: {
      edit: 'Edit',
      suspend: 'Unpublish',
      publish: 'Publish',
      bin: 'Remove assessment from reel',
      stats: 'Statistics',
    },
    documentTile: {
      edit: 'Edit',
      suspend: 'Unpublish',
      publish: 'Publish',
      bin: 'Remove document from reel',
    },
  },
};

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function ReelStatsModal({
  reel,
  isOpen,
  handleClose,
  handleUserAccessControl,
}) {
  const { t } = useTranslation();
  const node = useRef();
  const {
    dateCreated,
    totalViews,
    numberOfUsers,
    numberOfVideos,
    numberOfDocuments,
  } = reel;

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleClickOutside = event => {
    if (node.current.contains(event.target)) {
      return;
    }

    handleClose();
  };

  return (
    <div
      className="reel-view--grid-video-settings-stats-datawrapper"
      ref={node}
    >
      <div className="reel-view--grid-video-settings-stats-data">
        <p>{t('Date Created')}</p>
        <span>{dateCreated}</span>
      </div>
      <div className="reel-view--grid-video-settings-stats-data">
        <p onClick={handleUserAccessControl}>User Access</p>
        <span onClick={handleUserAccessControl}>{numberOfUsers}</span>
      </div>
      <div className="reel-view--grid-video-settings-stats-data">
        <p>
          {t('Number of')}{' '}
          {numberOfVideos !== undefined ? 'Videos' : 'Documents'}
        </p>
        <span>
          {numberOfVideos !== undefined ? numberOfVideos : numberOfDocuments}
        </span>
      </div>
      <div className="reel-view--grid-video-settings-stats-data">
        <p>{t('Total Views')}</p>
        <span>{totalViews}</span>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import messageConfigs from '../../Helper/PopupMessageConfig';
import logo from '../../assets/images/owlonly.png';

export default function ForgotPassword({ history }) {
  const { t } = useTranslation();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isForgotPasswordEmailSend, setIsForgotPasswordEmailSend] =
    useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { themeInfo } = useSelector(state => state?.auth);

  let forgotPasswordBackground = themeInfo?.loginBackgroundUrl;
  const pageLoadingLogo = themeInfo?.pageLoadingLogoUrl;

  const handleForgetPassword = () => {
    if (userName) {
      setIsLoading(true);
      Auth.forgotPassword(userName)
        .then(() => {
          setIsForgotPasswordEmailSend(true);
          setIsLoading(false);
        })
        .catch(err => {
          generateError(err);
          setIsLoading(false);
        });
    }
  };
  const generateError = passwordError => {
    switch (passwordError?.code) {
      case 'NotAuthorizedException':
        setErrorMessage('Current password is incorrect');
        break;
      case 'InvalidPasswordException':
        setErrorMessage(passwordError?.message);
        break;
      case 'InvalidParameterException':
        setErrorMessage('Enter a valid password');
        break;
      case 'LimitExceededException':
        setErrorMessage(passwordError?.message);
        break;
      case 'CodeMismatchException':
        setErrorMessage(passwordError?.message);
        break;
      default:
        setErrorMessage('Something went wrong. Try again later');
        break;
    }
  };

  const handleEmailVerification = () => {
    setIsSubmit(true);
    if (
      verificationCode &&
      password &&
      confirmPassword &&
      password === confirmPassword &&
      password?.length >= 8
    ) {
      setIsLoading(true);
      setErrorMessage('');
      Auth.forgotPasswordSubmit(userName, verificationCode, password)
        .then(() => history.push('/'))
        .catch(err => {
          generateError(err);
          setIsLoading(false);
        });
    }
  };

  const showHidePassword = formElement => {
    if (formElement.type === 'password') {
      formElement.type = 'text';
    } else {
      formElement.type = 'password';
    }
  };

  return (
    <div
      className="forgot-wrapper"
      style={{
        backgroundImage: `url(${forgotPasswordBackground})`,
      }}
    >
      <img
        src={pageLoadingLogo || logo}
        alt="logo-icon"
        className="icon--logo"
      />
      <div className="forgot-form">
        {isForgotPasswordEmailSend ? (
          <>
            <div className="forgot-form_field">
              <span className="forgot-form_label">
                {t(messageConfigs.success.user.FORGOT_PASSWORD_EMAIL_SENT)}
              </span>
            </div>
            <div className="forgot-form_field">
              <span className="forgot-form_label">
                {t('Verification Code')}
              </span>
              <input
                value={verificationCode}
                onChange={event => setVerificationCode(event.target.value)}
                className="forgot-form_input"
              />
              {isSubmit && !verificationCode && (
                <div className="reel-view--popup-row warning">
                  <h4>
                    <span className="forgot-form_label">{t('Warning:')}</span>
                    {messageConfigs.error.USER.INVALID_CODE}
                  </h4>
                </div>
              )}
            </div>
            <div className="forgot-form_field password">
              <span className="forgot-form_label">{t('Password')}</span>
              <input
                type="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                className="forgot-form_input"
                id="forgot-password"
              />
              <button
                className="top-button"
                onClick={() =>
                  showHidePassword(document.getElementById('forgot-password'))
                }
              >
                {t('show/hide password')}
              </button>
              {isSubmit && password?.length < 8 && (
                <div className="reel-view--popup-row warning">
                  <h4>
                    <span className="forgot-form_label">{t('Warning:')}</span>
                    {messageConfigs.error.USER.INVALID_PASSWORD}
                  </h4>
                </div>
              )}
            </div>
            <div className="forgot-form_field password">
              <span className="forgot-form_label">{t('ConfirmPassword')}</span>
              <input
                type="password"
                value={confirmPassword}
                onChange={event => setConfirmPassword(event.target.value)}
                className="forgot-form_input"
                id="forgot-confirm-password"
              />
              <button
                className="top-button"
                onClick={() =>
                  showHidePassword(
                    document.getElementById('forgot-confirm-password')
                  )
                }
              >
                {t('show/hide password')}
              </button>
              {isSubmit && !confirmPassword && (
                <div className="reel-view--popup-row warning">
                  <h4>
                    <span className="forgot-form_label">{t('Warning:')}</span>
                    {messageConfigs.error.USER.WRONG_CONFIRM_PASSWORD}
                  </h4>
                </div>
              )}
            </div>
            {password && confirmPassword && password !== confirmPassword && (
              <div className="reel-view--popup-row warning">
                <h4>
                  <span className="forgot-form_label">{t('Warning:')}</span>
                  {messageConfigs.error.USER.PASSWORD_NOT_MATCH}
                </h4>
              </div>
            )}
          </>
        ) : (
          <div className="forgot-form_field">
            <h3 className="forgot-form_title">{t('Forgot password?')}</h3>
            <span className="forgot-form_label">
              {t(messageConfigs.success.user.FORGOT_PASSWORD)}
            </span>
            <input
              value={userName}
              onChange={event => setUserName(event.target.value)}
              className="forgot-form_input"
            />
          </div>
        )}
        <button
          onClick={() =>
            isForgotPasswordEmailSend
              ? handleEmailVerification()
              : handleForgetPassword()
          }
          className={`btn btn--primary ${isLoading ? 'btn--loader' : ''}`}
        >
          {t('Submit')}
        </button>
      </div>
      {errorMessage && (
        <div className="reel-view--popup-row warning">
          <h4>
            <span className="forgot-form_label">{t('Warning:')}</span>
            {errorMessage}
          </h4>
        </div>
      )}
    </div>
  );
}

import React from 'react';

import VideoSlider from './VideoSlider';

const VideoSliderWrapper = ({
  handleDelete,
  videoReels = [],
  groupId,
  deleteReelPermission,
  deleteVideoPermission,
  editVideoPermission,
  suspenPublishVideoPermission,
  hanldeIsPublishedStatus,
}) => {
  const VideoReelCategory = () =>
    videoReels.length
      ? videoReels.map((reel, key) => (
          <VideoSlider
            title={reel.reelName}
            videos={reel.videos}
            itemKey={reel.reelName.replace(/ /g, '_')}
            key={key}
            reelId={reel._id}
            groupId={groupId}
            handleDelete={handleDelete}
            deleteReelPermission={deleteReelPermission}
            deleteVideoPermission={deleteVideoPermission}
            editVideoPermission={editVideoPermission}
            suspenPublishVideoPermission={suspenPublishVideoPermission}
            hanldeIsPublishedStatus={hanldeIsPublishedStatus}
          />
        ))
      : null;
  return (
    <section
      className={`home-screen ${!VideoReelCategory() ? 'empty-element' : ''}`}
    >
      {VideoReelCategory()}
    </section>
  );
};

export default VideoSliderWrapper;

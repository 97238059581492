import React from 'react';
import MultiSelect from 'react-multi-select-component';
import { getNumberOfDaysDateAddedFromToday } from '../../Helper/SystemManager';
import { useTranslation } from 'react-i18next';

const GroupMultiSelectWithMandatoryDate = ({
  listOfGroups,
  selectGroupOptions,
  handleChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {listOfGroups.map((reelObj, index) => (
        <div className="admin__createvideo--inner-container" key={index}>
          <div className="admin__createvideo--common">
            <div className="section--right-column reel">
              <MultiSelect
                title="Edit"
                options={selectGroupOptions}
                labelledBy={'Select'}
                hasSelectAll={false}
                onChange={selectedOptions => {
                  const tempListOfGroups = [...listOfGroups];

                  tempListOfGroups[index].groups = selectedOptions.map(
                    selectedOption => {
                      const selectedGroup = listOfGroups.map(groupObject =>
                        groupObject['groups'].find(
                          group => group._id === selectedOption.value
                        )
                      )[0];

                      return {
                        _id: selectedOption.value,
                        name: selectedOption.label,
                        isMandatory:
                          (selectedGroup && selectedGroup.isMandatory) || false,
                        dueDate: (selectedGroup && selectedGroup.dueDate) || '',
                      };
                    }
                  );

                  handleChange(tempListOfGroups, 'listOfGroups');
                }}
                value={reelObj.groups.map(({ name, _id }) => ({
                  label: name,
                  value: _id,
                }))}
                disableSearch
                placeholder={t('Select Group / Sub Group')}
              />

              {reelObj.groups.length
                ? reelObj.groups.map((group, groupIndex) => (
                    <div
                      className="admin__createvideo--common-sub"
                      key={groupIndex}
                    >
                      <p className="label">{group.name}</p>
                      <a
                        className="btn-close closemodale"
                        aria-hidden="true"
                        onClick={() => {
                          const tempListOfGroups = [...listOfGroups];
                          tempListOfGroups[index].groups.splice(groupIndex, 1);
                          handleChange(tempListOfGroups, 'listOfGroups');
                        }}
                      >
                        &times;
                      </a>
                      <p className="label">{t('Mandatory')}</p>
                      <input
                        type="checkbox"
                        className="form-input--checkbox"
                        id="mandatory-yes"
                        name="mandatory-yes"
                        checked={group.isMandatory}
                      />
                      <label
                        onClick={() => {
                          const tempListOfGroups = [...listOfGroups];
                          tempListOfGroups[index].groups[
                            groupIndex
                          ].isMandatory = true;
                          handleChange(tempListOfGroups, 'listOfGroups');
                        }}
                      >
                        {t('Yes')}
                      </label>
                      <input
                        type="date"
                        min={getNumberOfDaysDateAddedFromToday(1)}
                        id="mandatory-duedate"
                        name="mandatory-duedate"
                        value={group.dueDate}
                        onChange={e => {
                          const tempListOfGroups = [...listOfGroups];
                          tempListOfGroups[index].groups[groupIndex].dueDate =
                            e.target.value;
                          handleChange(tempListOfGroups, 'listOfGroups');
                        }}
                      />
                      <input
                        type="checkbox"
                        className="form-input--checkbox"
                        id="mandatory-no"
                        name="mandatory-no"
                        checked={!group.isMandatory}
                      />
                      <label
                        onClick={() => {
                          const tempListOfGroups = [...listOfGroups];
                          tempListOfGroups[index].groups[
                            groupIndex
                          ].isMandatory = false;
                          handleChange(tempListOfGroups, 'listOfGroups');
                        }}
                      >
                        {t('No')}
                      </label>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default GroupMultiSelectWithMandatoryDate;

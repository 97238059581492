import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useApi from '../Hooks/useApi';
import {
  SUSPEND_VIDEO_REQUEST,
  SUSPEND_VIDEO_SUCCESS,
  SUSPEND_VIDEO_FAIL,
  FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS,
} from '../redux/actionTypes/video';
import {
  SUSPEND_DOCUMENT_REQUEST,
  SUSPEND_DOCUMENT_SUCCESS,
  SUSPEND_DOCUMENT_FAIL,
  FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS,
} from '../redux/actionTypes/documentData';
import {
  SUSPEND_ASSESSMENT_REQUEST,
  SUSPEND_ASSESSMENT_SUCCESS,
  SUSPEND_ASSESSMENT_FAIL,
  FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS,
  FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA,
} from '../redux/actionTypes/assesment';
import {
  SUSPEND_IMAGE_REQUEST,
  SUSPEND_IMAGE_SUCCESS,
  SUSPEND_IMAGE_FAIL,
} from '../redux/actionTypes/bannerAndFeatured';
import {
  SUSPEND_REEL_REQUEST,
  SUSPEND_REEL_SUCCESS,
  SUSPEND_REEL_FAIL,
  FETCH_REELS_BY_GROUP_SUCCESS,
  FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS,
  FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
} from '../redux/actionTypes/reels';
import {
  INTIALIZE_HOME_DATA_SUCCESS,
} from '../redux/actionTypes/homeData';

function useChangePublishedStatusApi() {
  const [publishedStatusChangeData, setPublishedStatusChangeData] = useState({
    _id: '',
    type: '',
    reelType: '',
    assessmentType: '',
    viewType: '',
    itemPosition: '',
  });
  const [updateIsPublishedStatus] = useApi();
  const dispatch = useDispatch();

  const {
    reelsByGroupSuccess,
    documentReelsFetchDataSuccess,
    suspendReelSuccess,
    allCategoriesFetchDataSuccess,
  } = useSelector(state => state?.reel);
  const {
    suspendVideoSuccess,
    fetchAllVideosByGroupData,
  } = useSelector(state => state?.video);
  const {
    suspendDocumentSuccess,
    fetchAllDocumentsByGroupData,
  } = useSelector(state => state?.documentData);
  const {
    suspendAssessmentSuccess,
    midEndAssementsByGroupFetchData,
    standaloneAssementsByGroupFetchData,
  } = useSelector(state => state?.assessment);
  const {
    suspendImageSuccess,
  } = useSelector(state => state?.bannerAndFeatured);
  const {
    intializehomeDataData,
    videoReelsForLandingPageData,
  } = useSelector(state => state?.homeData);

  useEffect(() => {
    if (suspendReelSuccess) {
      if (publishedStatusChangeData && publishedStatusChangeData.reelType === 'VIDEO') {
        const updatedReels = reelsByGroupSuccess && reelsByGroupSuccess.map(reel =>
          reel._id === suspendReelSuccess._id ?
            { ...reel, isPublished: suspendReelSuccess.isPublished } :
            reel
        );

        dispatch({
          type: FETCH_REELS_BY_GROUP_SUCCESS,
          payload: updatedReels,
        });
      }

      if (publishedStatusChangeData && publishedStatusChangeData.reelType === 'DOCUMENT') {
        const updatedReels = documentReelsFetchDataSuccess && documentReelsFetchDataSuccess.map(reel =>
          reel._id === suspendReelSuccess._id ?
            { ...reel, isPublished: suspendReelSuccess.isPublished } :
            reel
        );

        dispatch({
          type: FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS,
          payload: updatedReels,
        });
      }
    }
  }, [suspendReelSuccess]);

  useEffect(() => {
    if (suspendVideoSuccess) {
      switch (publishedStatusChangeData.viewType) {
        case 'BULK_MANDATORY':
          const updatedBulkViewMandatoryData = updateHomeData(
            'MANDATORY',
            suspendVideoSuccess
          );

          dispatch({
            type: INTIALIZE_HOME_DATA_SUCCESS,
            payload: updatedBulkViewMandatoryData,
          });
          break;

        case 'BULK_VIEW_VIDEO_REEL':
          const updatedBulkViewData = updateCategoryViewData(
            allCategoriesFetchDataSuccess,
            'videos',
            suspendVideoSuccess
          );

          dispatch({
            type: FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
            payload: updatedBulkViewData,
          });
          break;

        case 'ADMIN_HOME':
          const updatedHomeData = updateHomeData(
            publishedStatusChangeData.itemPosition,
            suspendVideoSuccess
          );

          dispatch({
            type: INTIALIZE_HOME_DATA_SUCCESS,
            payload: updatedHomeData,
          });
          break;

        case 'GROUP_SETTINGS':
          const updatedVideosByCategory = updateCategoryViewData(
            allCategoriesFetchDataSuccess,
            'videos',
            suspendVideoSuccess
          );

          dispatch({
            type: FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
            payload: updatedVideosByCategory,
          });
          break;

        default:
          const updatedVideos = fetchAllVideosByGroupData &&
            fetchAllVideosByGroupData.map(video =>
              video._id === suspendVideoSuccess._id ?
                { ...video, isPublished: suspendVideoSuccess.isPublished } :
                video
          );

          dispatch({
            type: FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS,
            payload: updatedVideos,
          });
          break;
      }
    }
  }, [suspendVideoSuccess]);

  useEffect(() => {
    if (suspendDocumentSuccess) {
      switch (publishedStatusChangeData.viewType) {
        case 'BULK_MANDATORY':
          const updatedBulkViewMandatoryData = updateHomeData(
            'MANDATORY',
            suspendDocumentSuccess
          );

          dispatch({
            type: INTIALIZE_HOME_DATA_SUCCESS,
            payload: updatedBulkViewMandatoryData,
          });
          break;

        case 'ADMIN_HOME':
          const updatedHomeData = updateHomeData(
            publishedStatusChangeData.itemPosition,
            suspendDocumentSuccess
          );

          dispatch({
            type: INTIALIZE_HOME_DATA_SUCCESS,
            payload: updatedHomeData,
          });
          break;

        case 'GROUP_SETTINGS':
          const updatedDocumentsByCategory = updateCategoryViewData(
            allCategoriesFetchDataSuccess,
            'documents',
            suspendDocumentSuccess
          );

          dispatch({
            type: FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
            payload: updatedDocumentsByCategory,
          });
          break;

        default:
          const updatedDocuments = fetchAllDocumentsByGroupData &&
            fetchAllDocumentsByGroupData.map(documentData =>
              documentData._id === suspendDocumentSuccess._id ?
                { ...documentData, isPublished: suspendDocumentSuccess.isPublished } :
                documentData
          );

          dispatch({
            type: FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS,
            payload: updatedDocuments,
          });
          break;
      }
    }
  }, [suspendDocumentSuccess]);

  useEffect(() => {
    if (suspendAssessmentSuccess) {
      switch (publishedStatusChangeData.viewType) {
        case 'BULK_MANDATORY':
          const updatedBulkViewMandatoryData = updateHomeData(
            'MANDATORY',
            suspendAssessmentSuccess
          );

          dispatch({
            type: INTIALIZE_HOME_DATA_SUCCESS,
            payload: updatedBulkViewMandatoryData,
          });
          break;

        case 'BULK_VIEW_VIDEO_REEL':
          const updatedBulkViewData = updateCategoryViewData(
            allCategoriesFetchDataSuccess,
            'assessments',
            suspendAssessmentSuccess
          );

          dispatch({
            type: FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
            payload: updatedBulkViewData,
          });
          break;

        case 'ADMIN_HOME':
          const updatedHomeData = updateHomeData(
            publishedStatusChangeData.itemPosition,
            suspendAssessmentSuccess
          );

          dispatch({
            type: INTIALIZE_HOME_DATA_SUCCESS,
            payload: updatedHomeData,
          });
          break;

        case 'GROUP_SETTINGS':
          const updatedAssessmentsByCategory = updateCategoryViewData(
            allCategoriesFetchDataSuccess,
            'assessments',
            suspendAssessmentSuccess
          );

          dispatch({
            type: FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
            payload: updatedAssessmentsByCategory,
          });
          break;

        default:
          if (suspendAssessmentSuccess.placement === 'STANDALONE') {
            const updatedStandaloneAssessments
              = standaloneAssementsByGroupFetchData && standaloneAssementsByGroupFetchData
              .map(assessment =>
                assessment._id === suspendAssessmentSuccess._id ?
                  { ...assessment, isPublished: suspendAssessmentSuccess.isPublished } :
                  assessment
              );

            dispatch({
              type: FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS,
              payload: updatedStandaloneAssessments,
            });
          } else {
            const updatedMidEndAssessments
              = midEndAssementsByGroupFetchData && midEndAssementsByGroupFetchData
              .map(assessment =>
                assessment._id === suspendAssessmentSuccess._id ?
                  { ...assessment, isPublished: suspendAssessmentSuccess.isPublished } :
                  assessment
              );

            dispatch({
              type: FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA,
              payload: updatedMidEndAssessments,
            });
          }
          break;
      }
    }
  }, [suspendAssessmentSuccess]);

  useEffect(() => {
    if (suspendImageSuccess) {
      const updatedHomeData = updateHomeData(
        publishedStatusChangeData.itemPosition || 'MANDATORY',
        suspendImageSuccess
      );

      dispatch({
        type: INTIALIZE_HOME_DATA_SUCCESS,
        payload: updatedHomeData,
      });
    }
  }, [suspendImageSuccess]);

  useEffect(() => {
    const {
      _id,
      type,
    } = publishedStatusChangeData;

    if (_id !== '') {
      switch (type) {
        case 'REEL':
          updateIsPublishedStatus(
            `/reel/update-publish?reelId=${_id}`,
            SUSPEND_REEL_REQUEST,
            SUSPEND_REEL_SUCCESS,
            SUSPEND_REEL_FAIL,
            null,
            '',
            'PUT',
            null,
            'isReelService'
          );
          break;

        case 'VIDEO':
          updateIsPublishedStatus(
            `/video/update-video-published?videoId=${_id}`,
            SUSPEND_VIDEO_REQUEST,
            SUSPEND_VIDEO_SUCCESS,
            SUSPEND_VIDEO_FAIL,
            null,
            '',
            'PUT',
            null,
            'isReelService'
          );
          break;

        case 'DOCUMENT':
          updateIsPublishedStatus(
            `/document/update-document-published?documentId=${_id}`,
            SUSPEND_DOCUMENT_REQUEST,
            SUSPEND_DOCUMENT_SUCCESS,
            SUSPEND_DOCUMENT_FAIL,
            null,
            '',
            'PUT',
            null,
            'isReelService'
          );
          break;

        case 'ASSESSMENT':
          updateIsPublishedStatus(
            `/assessment/update-assessment-published?assessmentId=${_id}`,
            SUSPEND_ASSESSMENT_REQUEST,
            SUSPEND_ASSESSMENT_SUCCESS,
            SUSPEND_ASSESSMENT_FAIL,
            null,
            '',
            'PUT',
            null,
            'isReelService'
          );
          break;

        case 'IMAGE':
          updateIsPublishedStatus(
            `/image/update-image-published?imageId=${_id}`,
            SUSPEND_IMAGE_REQUEST,
            SUSPEND_IMAGE_SUCCESS,
            SUSPEND_IMAGE_FAIL,
            null,
            '',
            'PUT',
            null,
            'isReelService'
          );
          break;

        default:
          break;
      }
    }
  }, [publishedStatusChangeData]);

  const updateCategoryViewData = (
    categoryData,
    itemType,
    updatedItem
  ) => {
    return categoryData && categoryData.map(( categoryValue ) => {
      const tempCategory = { ...categoryValue };

      const tempUpdatedCategory = tempCategory['category'].map(( itemValues ) => {
        const tempItemValues = { ...itemValues };

        const tempUpdatedItemValues = tempItemValues[itemType].map(itemData => {
          return itemData._id === updatedItem._id ?
            {
              ...itemData,
              isPublished: updatedItem.isPublished,
            } :
            itemData
        });

        if (itemType === 'documents') {
          return { ...tempItemValues, documents: tempUpdatedItemValues };
        } else if (itemType === 'videos') {
          return { ...tempItemValues, videos: tempUpdatedItemValues };
        } else {
          return { ...tempItemValues, assessments: tempUpdatedItemValues };
        }
      });

      return { ...tempCategory, category: tempUpdatedCategory};
    });
  }

  const updateHomeData = (type, updatedItem) => {
    switch (type) {
      case 'VIDEO_REELS':
        const updatedHomeVideos = updateHomeViewVideoData(
          intializehomeDataData.videoReels,
          updatedItem
        );

        return  {
          ...intializehomeDataData,
          videoReels: updatedHomeVideos,
        };

      case 'MANDATORY':
        const updatedMandatoryItems = intializehomeDataData &&
          intializehomeDataData['mandatory'].map(mandatoryValue =>
            mandatoryValue._id === updatedItem._id ?
              { ...mandatoryValue, isPublished: updatedItem.isPublished } :
              mandatoryValue
        );

        return  {
          ...intializehomeDataData,
          mandatory: updatedMandatoryItems,
        };

      case 'BANNER':
        const updatedBannerItems = intializehomeDataData &&
          intializehomeDataData['listBanners'].map(banner =>
            banner._id === updatedItem._id ?
              { ...banner, isPublished: updatedItem.isPublished } :
              banner
        );

        return  {
          ...intializehomeDataData,
          listBanners: updatedBannerItems,
        };

      case 'FEATURED':
        const updatedFeaturedItems = intializehomeDataData &&
          intializehomeDataData['listFeatured'].map(featured =>
            featured._id === updatedItem._id ?
              { ...featured, isPublished: updatedItem.isPublished } :
              featured
        );

        return  {
          ...intializehomeDataData,
          listFeatured: updatedFeaturedItems,
        };

      default:
        return  { ...intializehomeDataData };
    }
  }

  const updateHomeViewVideoData = (
    homeVideoData,
    updatedItem
  ) => {
    return homeVideoData && homeVideoData.map(( homeVideos ) => {
      const tempHomeVideos = { ...homeVideos };

      const tempUpdatedHomeVideos = tempHomeVideos['videos'].map(( itemData ) => (
        itemData._id === updatedItem._id ?
          { ...itemData, isPublished: updatedItem.isPublished } : itemData
      ));

      return { ...tempHomeVideos, videos: tempUpdatedHomeVideos};
    });
  }

  const submitForm = ({
    _id,
    type,
    reelType,
    assessmentType,
    viewType,
    itemPosition,
  }) => {
    setPublishedStatusChangeData({
      _id: _id || '',
      type: type || '',
      reelType: reelType || '',
      assessmentType: assessmentType || '',
      viewType: viewType || '',
      itemPosition: itemPosition || '',
    });
  };

  return [submitForm];
};

export default useChangePublishedStatusApi;

export const ADD_VIDEO_TO_CURRENT_CATEGORY_REQUEST =
  'ADD_VIDEO_TO_CURRENT_CATEGORY_REQUEST';
export const ADD_VIDEO_TO_CURRENT_CATEGORY_SUCCESS =
  'ADD_VIDEO_TO_CURRENT_CATEGORY_SUCCESS';
export const ADD_VIDEO_TO_CURRENT_CATEGORY_FAIL =
  'ADD_VIDEO_TO_CURRENT_CATEGORY_FAIL';

export const ADD_VIDEO_REQUEST = 'ADD_VIDEO_REQUEST';
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_SUCCESS';
export const ADD_VIDEO_FAIL = 'ADD_VIDEO_FAIL';

export const CREATE_VIDEO_REQUEST = 'CREATE_VIDEO_REQUEST';
export const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS';
export const CREATE_VIDEO_FAIL = 'CREATE_VIDEO_FAIL';
export const CREATE_VIDEO_RESET = 'CREATE_VIDEO_RESET';

export const FIND_VIDEO_BY_ID_REQUEST = 'FIND_VIDEO_BY_ID_REQUEST';
export const FIND_VIDEO_BY_ID_SUCCESS = 'FIND_VIDEO_BY_ID_SUCCESS';
export const FIND_VIDEO_BY_ID_FAIL = 'FIND_VIDEO_BY_ID_FAIL';

export const INTIALIZE_VIDEO_REQUEST = 'INTIALIZE_VIDEO_REQUEST';
export const INTIALIZE_VIDEO_SUCCESS = 'INTIALIZE_VIDEO_SUCCESS';
export const INTIALIZE_VIDEO_FAIL = 'INTIALIZE_VIDEO_FAIL';

export const FETCH_VIDEO_TO_PLAY_REQUEST = 'FETCH_VIDEO_TO_PLAY_REQUEST';
export const FETCH_VIDEO_TO_PLAY_SUCCESS = 'FETCH_VIDEO_TO_PLAY_SUCCESS';
export const FETCH_VIDEO_TO_PLAY_FAIL = 'FETCH_VIDEO_TO_PLAY_FAIL';

export const SUSPEND_VIDEO_REQUEST = 'SUSPEND_VIDEO_REQUEST';
export const SUSPEND_VIDEO_SUCCESS = 'SUSPEND_VIDEO_SUCCESS';
export const SUSPEND_VIDEO_FAIL = 'SUSPEND_VIDEO_FAIL';

export const FETCH_ALL_VIDEOS_BY_GROUP_REQUEST =
  'FETCH_ALL_VIDEOS_BY_GROUP_REQUEST';
export const FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS =
  'FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS';
export const FETCH_ALL_VIDEOS_BY_GROUP_FAIL = 'FETCH_ALL_VIDEOS_BY_GROUP_FAIL';

export const UPDATE_VIDEO_REQUEST = 'UPDATE_VIDEO_REQUEST';
export const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS';
export const UPDATE_VIDEO_FAIL = 'UPDATE_VIDEO_FAIL';
export const UPDATE_VIDEO_RESET = 'UPDATE_VIDEO_RESET';

export const UPDATE_VIDEO_VIEW_REQUEST = 'UPDATE_VIDEO_VIEW_REQUEST';
export const UPDATE_VIDEO_VIEW_SUCCESS = 'UPDATE_VIDEO_VIEW_SUCCESS';
export const UPDATE_VIDEO_VIEW_FAIL = 'UPDATE_VIDEO_VIEW_FAIL';

export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAIL = 'DELETE_VIDEO_FAIL';

export const FETCH_VIDEO_STATS_BY_GROUP_REQUEST =
  'FETCH_VIDEO_STATS_BY_GROUP_REQUEST';
export const FETCH_VIDEO_STATS_BY_GROUP_SUCCESS =
  'FETCH_VIDEO_STATS_BY_GROUP_SUCCESS';
export const FETCH_VIDEO_STATS_BY_GROUP_FAIL =
  'FETCH_VIDEO_STATS_BY_GROUP_FAIL';
export const FETCH_VIDEO_STATS_BY_GROUP_RESET =
  'FETCH_VIDEO_STATS_BY_GROUP_RESET';

export const UPDATE_FILE_UPLOADING_PERCENTAGE =
  'UPDATE_FILE_UPLOADING_PERCENTAGE';

export const DOWNLOAD_STATS_REQUEST = 'DOWNLOAD_STATS_REQUEST';
export const DOWNLOAD_STATS_SUCCESS = 'DOWNLOAD_STATS_SUCCESS';
export const DOWNLOAD_STATS_FAIL = 'DOWNLOAD_STATS_FAIL';

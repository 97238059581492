import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';

import AdminDashboardSelectionDropDown from '../../../../Components/Admin/AdminDashboardSelectionDropDown';
import useApi from '../../../../Hooks/useApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import {
  UPDATE_SIGNUP_REQUEST,
  UPDATE_SIGNUP_SUCCESS,
  UPDATE_SIGNUP_FAIL,
} from '../../../../redux/actionTypes/createUser';
import ReadMoreModal from '../../../../Components/Modal/ReadMoreModal';
import ProceedMessageModal from '../../../../Components/Modal/ProceedMessageModal';
import Loader from '../../../../Components/Loader/loader';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import { getS3ObjectUrl } from '../../../../Helper/SystemManager';
import PaginationView from '../../../../Components/Pagination/PaginationView';
import { systemConfig } from '../../../../Common/SystemConstConfigs';
import NoContent from '../../../../Components/NoContent/NoContent';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';

const characterLimit = 35;
const { superAdmin } = systemConfig.roleIdByName;

export default function UserRequests({ history }) {
  const { t } = useTranslation();
  const unassigned = [
    {
      label: t('Unassigned'),
      value: '-1',
    },
  ];
  const [searchText, setSearchText] = useState(null);
  const [showMoreText, setShowMoreText] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAccept, setIsAccepted] = useState(false);
  const [organizedUserData, setOrganizedUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allSelected, setAllSelected] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);
  const [numberOfRowsSelected, setNumberOfRowsSelected] = useState(0);
  const [fetchUsers] = useFetchDataApi();
  const [acceptUserRequest] = useApi();
  const [rejectUserRequest] = useApi();
  const [fetchGroupsByAdminAPI] = useFetchDataApi();
  const dispatch = useDispatch();

  const {
    selfSignupUsersData = [],
    selfSignupUserPageCount,
    selfSignupUsersDataFetchLoading,
    updateSignupRequestSuccess,
    updateSignupRequestError,
    updateSignupRequestLoading,
  } = useSelector(state => state?.user);
  const { emailLabel, nameLabel, userNameLabel, defaultProfileImageUrl } =
    useSelector(state => state?.auth?.themeInfo);
  const { signupEnabledGroupsLoading, groupsByUser } = useSelector(
    state => state?.group
  );
  const { roleList } = useSelector(state => state?.auth?.userData);

  const fetchSignUpUserList = () => {
    fetchUsers({
      type: 'FETCH_SELF_SIGNUP_USERS',
      paginationData: {
        pageNo: currentPage,
        pageSize: 10,
        searchKey: searchText || '',
      },
    });
  };

  const fetchAccessibleGroups = () => {
    fetchGroupsByAdminAPI({
      type: 'FETCH_GROUPS_BY_ADMIN',
      paginationData: {
        pageNo: 1,
        pageSize: 100,
      },
      requestParams: {
        userRoles: isSuperAdmin ? ['Super Admin'] : ['Admin'],
      },
    });
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: UPDATE_SIGNUP_SUCCESS,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (roleList && roleList.length > 0) {
      setIsSuperAdmin(
        [...roleList].map(({ id }) => id).includes(superAdmin.roleReferenceId)
      );
    }
  }, [roleList]);

  useEffect(() => {
    fetchAccessibleGroups();
  }, [isSuperAdmin]);

  useEffect(() => {
    fetchSignUpUserList();
  }, [groupsByUser]);

  useEffect(() => {
    if (selfSignupUsersData?.length && groupsByUser?.length) {
      const organizedData = selfSignupUsersData.map(signupUser => {
        const divisionData = signupUser.divisions.map(divisionId => {
          const matchDivison = groupsByUser.find(
            ({ _id }) => _id === divisionId
          );
          return matchDivison
            ? {
                _id: matchDivison._id,
                title: matchDivison.title,
              }
            : [];
        });

        return {
          ...signupUser,
          divisions: divisionData,
          isChecked: false,
        };
      });

      setOrganizedUserData(organizedData);
    }
  }, [selfSignupUsersData, groupsByUser]);

  useEffect(() => {
    const tempUserData = [...organizedUserData];
    setOrganizedUserData(
      tempUserData.map(userData => ({
        ...userData,
        isChecked: allSelected && userData?.divisions?.length,
      }))
    );
  }, [allSelected]);

  useEffect(() => {
    setOrganizedUserData([]);
    fetchSignUpUserList();
    setAllSelected(false);
  }, [currentPage]);

  useEffect(() => {
    if (searchText === '') {
      setOrganizedUserData([]);
      fetchSignUpUserList();
      setCurrentPage(1);
      setAllSelected(false);
    }
  }, [searchText]);

  useEffect(() => {
    if (updateSignupRequestSuccess) {
      setIsConfirm(null);
      setCurrentUser(null);
      fetchSignUpUserList();
    }
  }, [updateSignupRequestSuccess]);

  useEffect(() => {
    if (updateSignupRequestError) {
      setIsConfirm(null);
    }
  }, [updateSignupRequestError]);

  useEffect(() => {
    if (organizedUserData?.length) {
      const rowsSelected = organizedUserData.filter(
        ({ isChecked }) => isChecked
      );

      setNumberOfRowsSelected(rowsSelected?.length);

      if (!rowsSelected?.length) {
        setAllSelected(false);
      }
    }
  }, [organizedUserData]);

  const handleUserRequest = (user, isAccepted) => {
    setCurrentUser(user);
    setIsAccepted(isAccepted);

    if (isAccepted) {
      acceptUserRequest(
        '/external-users',
        UPDATE_SIGNUP_REQUEST,
        UPDATE_SIGNUP_SUCCESS,
        UPDATE_SIGNUP_FAIL,
        [
          {
            _id: user._id,
            divisions: user.divisions.map(({ _id }) => _id),
            type: 'SELF_SIGNUP_USER',
          },
        ],
        '',
        'PUT',
        null,
        'isUserService'
      );
    } else {
      rejectUserRequest(
        `/external-users?requestIds=${[user._id].toString()}`,
        UPDATE_SIGNUP_REQUEST,
        UPDATE_SIGNUP_SUCCESS,
        UPDATE_SIGNUP_FAIL,
        null,
        '',
        'DELETE',
        null,
        'isUserService'
      );
    }
  };

  const acceptAllRequests = () => {
    const selcetedUsers = organizedUserData
      .filter(({ isChecked }) => isChecked)
      .map(user => ({
        _id: user._id,
        divisions: user.divisions.map(({ _id }) => _id),
        type: 'SELF_SIGNUP_USER',
      }));

    if (selcetedUsers?.length) {
      acceptUserRequest(
        '/external-users',
        UPDATE_SIGNUP_REQUEST,
        UPDATE_SIGNUP_SUCCESS,
        UPDATE_SIGNUP_FAIL,
        selcetedUsers,
        '',
        'PUT',
        null,
        'isUserService'
      );
    }
  };

  const rejectAllRequests = () => {
    const rejectedUserIds = organizedUserData
      .filter(({ isChecked }) => isChecked)
      .map(({ _id }) => _id);

    if (rejectedUserIds?.length) {
      rejectUserRequest(
        `/external-users?requestIds=${rejectedUserIds.toString()}`,
        UPDATE_SIGNUP_REQUEST,
        UPDATE_SIGNUP_SUCCESS,
        UPDATE_SIGNUP_FAIL,
        null,
        '',
        'DELETE',
        null,
        'isUserService'
      );
    }
  };

  const renderComment = text => {
    if (text.length <= characterLimit) return text;

    if (text.length > characterLimit) {
      return (
        <>
          {`${text.slice(0, characterLimit)}... `}
          <a className="btn-read-more" onClick={() => setShowMoreText(text)}>
            {t('More')}
          </a>
        </>
      );
    }
  };

  const handleSearch = event => {
    if (event?.key === 'Enter' && event?.target?.value !== '') {
      setOrganizedUserData([]);
      fetchSignUpUserList();
      setCurrentPage(1);
      setAllSelected(false);
    }
  };

  const renderPageHeader = () => {
    return (
      <div className="all-users-table--page-title">
        <div className="all-users-table--page-selection">
          <AdminDashboardSelectionDropDown
            history={history}
            selected={'USERS'}
          />
        </div>
        <div className="all-users-table--page-settings">
          <input
            className="form-input form-input--search"
            type="search"
            id="search"
            name="search"
            placeholder={t('Search')}
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
            onKeyDown={handleSearch}
          />
        </div>
      </div>
    );
  };

  const renderTableHeader = () => {
    return (
      <div className="all-users-table--table-header">
        <div className="all-users-table--table-cell">
          <input
            readOnly
            type="checkbox"
            className="form-input--checkbox"
            name="user-request"
            id="user-request"
            checked={allSelected}
            disabled={!organizedUserData.length}
          />
          <label
            htmlFor="user-request"
            onClick={() =>
              organizedUserData.length &&
              setAllSelected(isAllSelected => !isAllSelected)
            }
          ></label>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t(userNameLabel)}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t(emailLabel)}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t(nameLabel)}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t('Group')}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t('Comment')}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t('Received Date')}</h5>
        </div>
        <div className="all-users-table--table-cell">
          <h5>{t('Actions')}</h5>
        </div>
      </div>
    );
  };

  const renderTableContents = () => {
    return (
      <div className="all-users-table--table-body">
        {organizedUserData.length ? (
          organizedUserData.map((user, index) => (
            <div className="all-users-table--table-row" key={index}>
              <div className="all-users-table--table-cell">
                <input
                  readOnly
                  type="checkbox"
                  className="form-input--checkbox"
                  name={`user-request${index}`}
                  id={`user-request${index}`}
                  checked={user.isChecked}
                  disabled={!user.divisions.length}
                />
                <label
                  title={
                    !user?.divisions?.length
                      ? t('Please select a group')
                      : t('Select this user')
                  }
                  htmlFor={`user-request${index}`}
                  onClick={() => {
                    if (user?.divisions?.length) {
                      const tempOrganizedData = [...organizedUserData];

                      tempOrganizedData.forEach((userData, index) => {
                        if (userData._id == user._id)
                          tempOrganizedData[index].isChecked =
                            !tempOrganizedData[index].isChecked;
                      });

                      setOrganizedUserData(tempOrganizedData);
                    }
                  }}
                ></label>
              </div>
              <div className="all-users-table--table-cell">
                <img
                  src={
                    getS3ObjectUrl(user.profilePicUrl) || defaultProfileImageUrl
                  }
                  className="all-users-table--table-avatarimg"
                  onError={event => {
                    event.target.onerror = null;
                    event.target.src = defaultProfileImageUrl;
                  }}
                />
                <p>{user.preferredUsername}</p>
              </div>
              <div className="all-users-table--table-cell">
                <p>{user.email}</p>
              </div>
              <div className="all-users-table--table-cell">
                <p>{user.name}</p>
              </div>
              <div className="all-users-table--table-cell">
                {isSuperAdmin ? (
                  <Dropdown
                    title="Edit"
                    value={user.divisions.length ? user.divisions[0]._id : '-1'}
                    options={[
                      ...unassigned,
                      ...groupsByUser.map(({ _id, title }) => ({
                        label: title,
                        value: _id,
                      })),
                    ]}
                    onChange={({ value, label }) => {
                      const tempData = [...organizedUserData];
                      const organizedTempData = tempData.map(signupUser =>
                        signupUser._id === user._id
                          ? {
                              ...signupUser,
                              divisions:
                                value !== '-1'
                                  ? [{ _id: value, title: label }]
                                  : [],
                            }
                          : signupUser
                      );
                      setOrganizedUserData(organizedTempData);
                    }}
                  />
                ) : (
                  <label className="group-label">
                    {user?.divisions[0]?.title || ''}
                  </label>
                )}
              </div>

              <div className="all-users-table--table-cell">
                <p>{user.comment ? renderComment(user.comment) : '-'}</p>
              </div>
              <div className="all-users-table--table-cell">
                <p>{moment(user.addedDateTime).format('LLL')}</p>
              </div>
              <div className="all-users-table--table-cell">
                {updateSignupRequestLoading && currentUser?._id === user._id ? (
                  <button className="btn btn--primary btn--loader">
                    {t('Loading')}
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn--primary requestbutton"
                      onClick={() =>
                        user?.divisions?.length && handleUserRequest(user, true)
                      }
                      disabled={!user?.divisions?.length}
                      title={
                        !user?.divisions?.length
                          ? t('Please select a group')
                          : t('Accept the user')
                      }
                    >
                      {t('Accept')}
                    </button>
                    <button
                      className="btn btn--tertiary requestbutton"
                      onClick={() => handleUserRequest(user, false)}
                    >
                      {t('Reject')}
                    </button>
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <NoContent message="No Users Found" />
        )}
      </div>
    );
  };

  const renderTableFooter = () => {
    return (
      <div className="all-users-table--table-footer">
        <div>
          <button
            className="btn btn--primary btn--curved"
            disabled={!organizedUserData.length}
            onClick={() =>
              numberOfRowsSelected > 0 &&
              setIsConfirm({
                type: 'ACCEPT',
                message: t(
                  'Are you sure you wish to Accept all selected Users?'
                ),
              })
            }
          >
            {t('Accept All')}
          </button>
          <button
            className="btn btn--tertiary btn--curved"
            onClick={() => rejectAllRequests()}
            disabled={!organizedUserData.length}
            onClick={() =>
              numberOfRowsSelected > 0 &&
              setIsConfirm({
                type: 'REJECT',
                message: t(
                  'Are you sure you wish to Reject all selected Users?'
                ),
              })
            }
          >
            {t('Reject All')}
          </button>
        </div>
        <PaginationView
          pageCount={selfSignupUserPageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          currentPage={currentPage}
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
        />
      </div>
    );
  };

  return (
    <div
      className={`all-users-table userrequests ${
        selfSignupUsersDataFetchLoading || signupEnabledGroupsLoading
          ? 'setheight'
          : ''
      }`}
    >
      {selfSignupUsersDataFetchLoading || signupEnabledGroupsLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="all-users-table--wrapper">
            {renderPageHeader()}
            <div className="all-users-table--table">
              {renderTableHeader()}
              {renderTableContents()}
            </div>
          </div>
          {renderTableFooter()}
        </div>
      )}
      {showMoreText && (
        <ReadMoreModal
          handleClose={() => setShowMoreText(null)}
          description={showMoreText}
          title={'Comment'}
        />
      )}
      {/* Signup request accept deny error modal */}
      {updateSignupRequestError && (
        <ProceedMessageModal
          isOkayButtonShown={false}
          isSuccess={updateSignupRequestError.success}
          message={
            (updateSignupRequestError.message &&
              updateSignupRequestError.message[0]) ||
            messageConfigs.error.CONTACT_SUPPORT
          }
          handleClose={() => {
            fetchSignUpUserList();
            dispatch({
              type: UPDATE_SIGNUP_SUCCESS,
            });
          }}
          handleError={() =>
            dispatch({
              type: UPDATE_SIGNUP_SUCCESS,
            })
          }
        />
      )}
      {/* Signup request accept deny error modal */}
      {isConfirm && (
        <ConfirmActionModal
          title="Confirm"
          isLoading={updateSignupRequestLoading}
          message={isConfirm.message}
          handleSuccess={() =>
            isConfirm.type === 'ACCEPT'
              ? acceptAllRequests()
              : rejectAllRequests()
          }
          handleClose={() => setIsConfirm(null)}
          isConfirmationModal={true}
        />
      )}
    </div>
  );
}

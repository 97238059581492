import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import facebook from '../../assets/images/svg-images/icon-facebook.svg';
import facebook_light from '../../assets/images/svg-images/icon-facebook-light.svg';
import twitter from '../../assets/images/svg-images/icon-twitter.svg';
import twitter_light from '../../assets/images/svg-images/icon-twitter-light.svg';
import instagram from '../../assets/images/svg-images/icon-instagram.svg';
import instagram_light from '../../assets/images/svg-images/icon-instagram-light.svg';
import website from '../../assets/images/svg-images/icon-www.svg';
import website_light from '../../assets/images/svg-images/icon-www-light.svg';

const Footer = () => {
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const {
    footerLogoUrl,
    facebookLink,
    twitterLink,
    websiteLink,
    instagramLink,
  } = useSelector(state => state?.auth?.themeInfo);

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        setIsScrolled(false);
      } else {
        if (!isScrolled) {
          setIsScrolled(true);
        }
      }
    };
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__logo">
            <img src={footerLogoUrl} alt="logo-icon" className="icon--logo" />
          </div>
          <div className="footer__social-menu">
            <p>{t('Follow Us')}</p>
            <ul>
              {facebookLink && (
                <li>
                  <a
                    href={facebookLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={facebook}
                      alt="facebook-icon"
                      className="dark-icon icon--facebook"
                    />
                    <img
                      src={facebook_light}
                      alt="facebook-icon"
                      className="light-icon icon--facebook"
                    />
                  </a>
                </li>
              )}
              {twitterLink && (
                <li>
                  <a
                    href={twitterLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={twitter}
                      alt="twitter-icon"
                      className="dark-icon icon--twitter"
                    />
                    <img
                      src={twitter_light}
                      alt="twitter-icon"
                      className="light-icon icon--twitter"
                    />
                  </a>
                </li>
              )}
              {instagramLink && (
                <li>
                  <a
                    href={instagramLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={instagram}
                      alt="instagram-icon"
                      className="dark-icon icon--instagram"
                    />
                    <img
                      src={instagram_light}
                      alt="instagram-icon"
                      className="light-icon icon--instagram"
                    />
                  </a>
                </li>
              )}
              {websiteLink && (
                <li>
                  <a
                    href={websiteLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={website}
                      alt="website-icon"
                      className="dark-icon icon--website"
                    />
                    <img
                      src={website_light}
                      alt="website-icon"
                      className="light-icon icon--website"
                    />
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div
            className={`footer__group ${isScrolled ? '' : 'hide'}`}
            onClick={() => window.scrollTo(0, 0)}
          >
            <div className="icon--goup"></div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

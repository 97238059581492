import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import UploadItem from '../../Components/UploadProgressBar/UploadProgressBar';
import useFileUpload from '../../Hooks/useFileUpload';

const PreviewVideoView = ({
  title,
  videoUrl,
  videoUrlPropertyName,
  isVideoViaUrl,
  isVideoViaUrlPropertyName,
  handleData,
  videoFilepropertyName,
  isValidUrl,
  setIsValidUrl,
}) => {
  const { t } = useTranslation();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [localFileUrl, setLocalFileUrl] = useState('');

  const [uploadFile, fileUrl, fileUploadingProgress] = useFileUpload();

  useEffect(() => {
    setIsValidUrl(true);
    setLocalFileUrl(videoUrl);
  }, [videoUrl]);

  const handleFileAccept = event => {
    if (event?.target?.files?.length) {
      const file = event.target.files[0];
      setLocalFileUrl(URL.createObjectURL(file));
      uploadFile(file);
    }
  };

  const addFileFromUrl = (value, name) => {
    setLocalFileUrl(value);
    handleData(value, name);
  };

  useEffect(() => {
    setShowProgressBar(false);
    if (fileUploadingProgress !== 0) {
      setShowProgressBar(true);
    } else if (showProgressBar) {
      setShowProgressBar(false);
    }
  }, [fileUploadingProgress]);

  useEffect(() => {
    handleData(fileUrl, videoUrlPropertyName);
  }, [fileUrl]);

  return (
    <div className="admin__createvideo--video-theatermode">
      <p>{t(title)}</p>
      <ReactPlayer
        url={localFileUrl || 'alt'}
        controls
        playing={false}
        onError={() => {
          if (videoUrl) {
            setIsValidUrl(false);
          } else {
            setIsValidUrl(true);
          }
        }}
      />
      {showProgressBar && <UploadItem progress={fileUploadingProgress} />}
      <input
        readOnly
        type="radio"
        className="form-input--checkbox"
        id="endvideo"
        name="endvideo"
        checked={!isVideoViaUrl}
      />
      <label onClick={() => handleData(false, isVideoViaUrlPropertyName)}>
        {t('Upload Preview Video')}
      </label>
      <input
        type="file"
        id="upload-preview-vid"
        name="upload-preview-vid"
        placeholder={t('Image Title')}
        accept="video/*"
        onChange={e => handleFileAccept(e, videoFilepropertyName)}
      />
      <input
        readOnly
        type="radio"
        className="form-input--checkbox"
        id="endvideo"
        name="endvideo"
        checked={isVideoViaUrl}
      />
      <label onClick={() => handleData(true, isVideoViaUrlPropertyName)}>
        {t('Upload Preview Video via URL')}
      </label>
      <input
        type="text"
        id="upload-preview-vid-url"
        name="upload-preview-vid-url"
        placeholder={t('URL')}
        value={videoUrl}
        onChange={e => addFileFromUrl(e.target.value, videoUrlPropertyName)}
      />
      {!isValidUrl && (
        <h5 className="error-message">
          {t('Please provide a valid preview video url')}
        </h5>
      )}
    </div>
  );
};

export default PreviewVideoView;

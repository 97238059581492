import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dropdown from 'react-dropdown';
import MultiSelect from 'react-multi-select-component';
import {
  ADD_USER_TO_GROUP_REQUEST,
  ADD_USER_TO_GROUP_SUCCESS,
  ADD_USER_TO_GROUP_FAIL,
} from '../../../../redux/actionTypes/Group';

import useApi from '../../../../Hooks/useApi';
import { getAllGroupInfoByCurrentUser } from '../../../../Helper/SystemManager';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';

const AssignUserToGroupModal = ({
  handleClose,
  handleSuccess,
  userId,
  assignedGroups,
}) => {
  const { t } = useTranslation();
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [userRolesOption, setUserRolesOption] = useState([]);
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [AddUserToGroup] = useApi();
  const [fetchAllGroups] = useFetchDataApi();
  const [fetchParentGroupRoles] = useFetchDataApi();

  const dispatch = useDispatch();
  const { userRolesFetchData } = useSelector(state => state?.user);
  const {
    addUserToGroupLoading,
    addUserToGroupSuccess,
    parentGroupRolesData,
    parentGroupRolesLoading,
  } = useSelector(state => state?.group);
  const { groupInfo, userName } = useSelector(state => state?.auth?.userData);
  const { allGroupsFetchData } = useSelector(state => state?.group);

  useEffect(() => {
    if (addUserToGroupSuccess) {
      dispatch({ type: ADD_USER_TO_GROUP_REQUEST });
      handleClose();
      handleSuccess();
    }
  }, [addUserToGroupSuccess]);

  useEffect(() => {
    fetchAllGroups({ type: 'ALL_GROUPS' });
  }, []);

  useEffect(() => {
    if (!Array.isArray(allGroupsFetchData) && allGroupsFetchData && groupInfo) {
      const flattenedGroups = getAllGroupInfoByCurrentUser(
        userName,
        groupInfo,
        allGroupsFetchData,
        true
      );

      // Constructing select group dropdown values and removing current group
      const organizeSelectOptions = optionsArray => {
        return optionsArray
          .filter(group => {
            return !assignedGroups.includes(group._id);
          })
          .map(group => {
            return { value: group._id, label: group.title };
          });
      };

      const selectGroupOptions = organizeSelectOptions(flattenedGroups);

      setSelectGroupOptions(selectGroupOptions);
    }
  }, [allGroupsFetchData]);

  useEffect(() => {
    if (userRolesFetchData && userRolesFetchData.length) {
      setUserRolesOption(
        userRolesFetchData
          .filter(userRole => userRole.id !== 4)
          .map(role => ({
            label: role.name === 'Admin' ? 'Administrator' : role.name,
            value: role.id,
          }))
      );
    }
  }, [userRolesFetchData]);

  useEffect(() => {
    if (selectedGroup && selectedGroup.value) {
      fetchParentGroupRoles({
        groupId: selectedGroup.value,
        objectId: userId,
        type: 'FETCH_PARENT_GROUP_ROLES',
      });
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (parentGroupRolesData) {
      let parentGroupRoles = [];
      const userRoles = ['Admin', 'Moderator', 'Editor'];

      if (parentGroupRolesData.includes('Admin')) {
        parentGroupRoles = userRoles;
      }

      setUserRolesOption(
        userRolesFetchData
          .filter(
            userRole =>
              userRole.id !== 4 && !parentGroupRoles.includes(userRole.name)
          )
          .map(role => ({
            label: role.name === 'Admin' ? 'Administrator' : role.name,
            value: role.id,
          }))
      );
    }
  }, [parentGroupRolesData]);

  const handleUserRoleSelection = selectedRoles => {
    if (selectedRoles.length < 3) {
      if (selectedRoles.length < 2) {
        setSelectedUserRoles(selectedRoles);
      } else if (selectedRoles.filter(role => role.value === 3).length) {
        setSelectedUserRoles(selectedRoles);
      }
    }
  };

  const handleSubmit = () => {
    setIsSubmitClicked(true);

    const userIdNo = parseInt(userId);

    if (!isNaN(userIdNo) && selectedUserRoles?.length && selectedGroup?.value) {
      AddUserToGroup(
        '/division/assignUser',
        ADD_USER_TO_GROUP_REQUEST,
        ADD_USER_TO_GROUP_SUCCESS,
        ADD_USER_TO_GROUP_FAIL,
        {
          divisionId: selectedGroup.value,
          roleReferenceWrapper: selectedUserRoles.map(selectedUser => ({
            roleName:
              selectedUser.label === 'Administrator'
                ? 'Admin'
                : selectedUser.label,
            roleReferenceId: selectedUser.value,
          })),
          userId: [userIdNo],
        },
        '',
        'POST',
        null,
        'isGroupService'
      );
    }
  };

  return (
    <div className="group-users-table--popup">
      <div className="group-users-table--popup-container">
        <div className="group-users-table--popup-header">
          <h3>{t('Assign a group to the user')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            &times;
          </a>
        </div>
        <div className="group-users-table--popup-body">
          <div className="group-users-table--popup-row">
            <label>{t('Groups')}</label>
            <div className="group-users-table--popup-field">
              <Dropdown
                title="Select Group"
                value={selectedGroup ? selectedGroup.value : ''}
                options={selectGroupOptions}
                onChange={option => setSelectedGroup(option)}
              />
            </div>
          </div>
          <div className="group-users-table--popup-row">
            {!parentGroupRolesLoading && (
              <>
                <label>{t('User Role')}</label>
                <div
                  className="group-users-table--popup-field"
                  style={{ zIndex: 10 }}
                >
                  <MultiSelect
                    options={userRolesOption}
                    value={selectedUserRoles}
                    onChange={handleUserRoleSelection}
                    labelledBy={'Select'}
                    hasSelectAll={false}
                    disableSearch={true}
                  />
                </div>
              </>
            )}
          </div>
          <div className="group-users-table--popup-row">
            {/*Hiding button temporarily*/}
            {/*<button className="btn btn--secondary">View User Role Privileges</button>*/}
            <button
              className={`
                btn btn--primary
                ${addUserToGroupLoading && isSubmitClicked ? 'btn--loader' : ''}
              `}
              onClick={handleSubmit}
              disabled={!(selectedUserRoles.length && selectedGroup)}
            >
              {t('Assign Group')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignUserToGroupModal;

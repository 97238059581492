import React from 'react';
import { useTranslation } from 'react-i18next';

const NoContent = ({ message }) => {
  const { t } = useTranslation();
  return (
    <div className="noContent--wrapper">
      <h2>{message || t('No Contents Found')}</h2>
    </div>
  );
};

export default React.memo(NoContent);

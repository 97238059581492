export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAIL = 'CREATE_DOCUMENT_FAIL';
export const CREATE_DOCUMENT_RESET = 'CREATE_DOCUMENT_RESET';

export const INTIALIZE_DOCUMENT_REQUEST = 'INTIALIZE_DOCUMENT_REQUEST';
export const INTIALIZE_DOCUMENT_SUCCESS = 'INTIALIZE_DOCUMENT_SUCCESS';
export const INTIALIZE_DOCUMENT_FAIL = 'INTIALIZE_DOCUMENT_FAIL';

export const SUSPEND_DOCUMENT_REQUEST = 'SUSPEND_DOCUMENT_REQUEST';
export const SUSPEND_DOCUMENT_SUCCESS = 'SUSPEND_DOCUMENT_SUCCESS';
export const SUSPEND_DOCUMENT_FAIL = 'SUSPEND_DOCUMENT_FAIL';

export const FETCH_DOCUMENT_TO_VIEW_REQUEST = 'FETCH_DOCUMENT_TO_VIEW_REQUEST';
export const FETCH_DOCUMENT_TO_VIEW_SUCCESS = 'FETCH_DOCUMENT_TO_VIEW_SUCCESS';
export const FETCH_DOCUMENT_TO_VIEW_FAIL = 'FETCH_DOCUMENT_TO_VIEW_FAIL';

export const FIND_DOCUMENT_BY_ID_REQUEST = 'FIND_DOCUMENT_BY_ID_REQUEST';
export const FIND_DOCUMENT_BY_ID_DATA = 'FIND_DOCUMENT_BY_ID_DATA';
export const FIND_DOCUMENT_BY_ID_FAIL = 'FIND_DOCUMENT_BY_ID_FAIL';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAIL = 'UPDATE_DOCUMENT_FAIL';
export const UPDATE_DOCUMENT_RESET = 'UPDATE_DOCUMENT_RESET';

export const FETCH_ALL_DOCUMENTS_BY_GROUP_REQUEST = 'FETCH_ALL_DOCUMENTS_BY_GROUP_REQUEST';
export const FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS = 'FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS';
export const FETCH_ALL_DOCUMENTS_BY_GROUP_FAIL = 'FETCH_ALL_DOCUMENTS_BY_GROUP_FAIL';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAIL = 'DELETE_DOCUMENT_FAIL';

export const FETCH_DOCUMENT_URL_BY_ID_REQUEST = 'FETCH_DOCUMENT_URL_BY_ID_REQUEST';
export const FETCH_DOCUMENT_URL_BY_ID_SUCCESS = 'FETCH_DOCUMENT_URL_BY_ID_SUCCESS';
export const FETCH_DOCUMENT_URL_BY_ID_FAIL = 'FETCH_DOCUMENT_URL_BY_ID_FAIL';

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { organizeDate } from '../../Helper/SystemManager';
import ActionDropdown from '../Admin/ActionDropdown';

const MandatoryReelListView = ({
  isSuperAdmin,
  canEditMandatoryReel,
  canDeleteMandatoryReel,
  mandatoryReels,
  handleRowOnclick,
  handleDropdownOnchange,
  seachName,
}) => {
  const { t } = useTranslation();
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;
  const defaultDocument = themeInfo?.documentImageUrl;
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;

  const handleClickOnTableRow = (event, data) => {
    if (
      event.target.classList.contains('Dropdown-placeholder') ||
      event.target.classList.contains('Dropdown-control')
    ) {
      event.preventDefault();
    } else {
      handleRowOnclick(data);
    }
  };

  const currentReels = mandatoryReels['currentReels']
    .filter(
      ({ title }) =>
        seachName === '' ||
        title?.toUpperCase().includes(seachName.toUpperCase())
    )
    .map((mandatoryReel, index) => {
      const { imageUrl, title, date, dueDate, type } = mandatoryReel;

      return (
        <div
          key={index}
          className="reel-view--table-row"
          onClick={event => handleClickOnTableRow(event, mandatoryReel)}
        >
          <div className="reel-view--table-cell">
            <img
              src={imageUrl}
              className="reel-view--table-backgroundimg"
              onError={event => {
                event.target.onerror = null;
                event.target.src =
                  type === 'VIDEO'
                    ? defaultVideo
                    : type === 'DOCUMENT'
                    ? defaultDocument
                    : defaultAssessment;
              }}
            />
          </div>
          <div className="reel-view--table-cell">
            <p>{title}</p>
          </div>
          <div className="reel-view--table-cell">
            <p>{date ? organizeDate(date) : '-'}</p>
          </div>
          <div className="reel-view--table-cell">
            <p>{organizeDate(dueDate)}</p>
          </div>
          <div className="reel-view--table-cell">
            {(isSuperAdmin ||
              canEditMandatoryReel ||
              canDeleteMandatoryReel) && (
              <ActionDropdown
                ishaveEdit={isSuperAdmin || canEditMandatoryReel}
                ishaveDelete={isSuperAdmin || canDeleteMandatoryReel}
                handleOnchange={option =>
                  handleDropdownOnchange(option.value, mandatoryReel)
                }
              />
            )}
          </div>
        </div>
      );
    });

  const deadLineExceededReels = mandatoryReels['deadLineExceeded']
    .filter(
      ({ title }) =>
        seachName === '' ||
        title?.toUpperCase()?.includes(seachName.toUpperCase())
    )
    .map((mandatoryReel, index) => {
      const { imageUrl, title, date, dueDate, type } = mandatoryReel;

      return (
        <div
          key={index}
          className="reel-view--table-row"
          onClick={event => handleClickOnTableRow(event, mandatoryReel)}
        >
          <div className="reel-view--table-cell">
            <img
              src={imageUrl}
              className="reel-view--table-backgroundimg"
              onError={event => {
                event.target.onerror = null;
                event.target.src =
                  type === 'VIDEO'
                    ? defaultVideo
                    : type === 'DOCUMENT'
                    ? defaultDocument
                    : defaultAssessment;
              }}
            />
          </div>
          <div className="reel-view--table-cell">
            <p>{title}</p>
          </div>
          <div className="reel-view--table-cell">
            <p>{date ? organizeDate(date) : '-'}</p>
          </div>
          <div className="reel-view--table-cell">
            <p>{organizeDate(dueDate)}</p>
          </div>
          <div className="reel-view--table-cell">
            {(isSuperAdmin ||
              canEditMandatoryReel ||
              canDeleteMandatoryReel) && (
              <ActionDropdown
                ishaveEdit={isSuperAdmin || canEditMandatoryReel}
                ishaveDelete={isSuperAdmin || canDeleteMandatoryReel}
                handleOnchange={option =>
                  handleDropdownOnchange(option.value, mandatoryReel)
                }
              />
            )}
          </div>
        </div>
      );
    });

  const mandatoryReelsTableHeaders = () => {
    return (
      <div className="all-users-table--table-header">
        <div className="reel-view--table-cell">
          <h5>{t('Preview')}</h5>
        </div>
        <div className="reel-view--table-cell">
          <h5>{t('Title')}</h5>
        </div>
        <div className="reel-view--table-cell">
          <h5>{t('Date Created')}</h5>
        </div>
        <div className="reel-view--table-cell">
          <h5>{t('Due Date')}</h5>
        </div>
        <div className="all-users-table--table-cell">
          {/* Dropdown column */}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="reel-view--grid">
        <h3>{t('CURRENT')}</h3>
        <div className="all-users-table--table">
          {mandatoryReelsTableHeaders()}
          <div className="reel-view--table-body">
            {currentReels.length ? (
              currentReels
            ) : (
              <p className="reel-view--grid-noitem">{t('No Image')}</p>
            )}
          </div>
        </div>
      </div>
      <div className="reel-view--grid">
        <h3>{t('DEADLINE EXCEEDED')}</h3>
        <div className="all-users-table--table">
          {mandatoryReelsTableHeaders()}
          <div className="reel-view--table-body">
            {deadLineExceededReels.length ? (
              deadLineExceededReels
            ) : (
              <p className="reel-view--grid-noitem">{t('No Image')}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MandatoryReelListView;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useDeleteApi from '../../../../Hooks/useDeleteApi';
import useChangePublishedStatusApi from '../../../../Hooks/useChangePublishedStatusApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';

import deleteIcon_white from '../../../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../../../assets/images/svg-images/icon-edit-white.svg';
import stats from '../../../../assets/images/svg-images/icon-stats.svg';
import warning from '../../../../assets/images/svg-images/icon-warning.svg';
import plus from '../../../../assets/images/svg-images/add.svg';
import copy from '../../../../assets/images/svg-images/icon-copy.svg';
import publish from '../../../../assets/images/svg-images/icon-upload.svg';

import CreateReelModal from '../Modal/createReelModal';
import AddReelModal from '../Modal/addReelModal';
import UpdateReelModal from '../Modal/updateReelModal';
import CloneReelModal from '../Modal/cloneReelModal';
import {
  organizeDate,
  getBannerFeaturedEditRoute,
} from '../../../../Helper/SystemManager';
import ReelStatsModal from '../Modal/reelStatsModal';
import UserControlModal from '../Modal/userControlModal';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import MandatoryReelGridView from '../../../../Components/MandatoryReel/GridView';
import MandatoryReelListView from '../../../../Components/MandatoryReel/ListView';
import ViewPDFModal from '../Modal/viewPDFModal';
import BreadCrumbView from '../../../../Components/BreadCrumbView';
import Loader from '../../../../Components/Loader/loader';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import { ToolTipConfig } from '../../../../Common/ToolTipConfig';
import { getUrlExtension } from '../../../../Helper/getFileUrlExtension';
import ActionDropdown from '../../../../Components/Admin/ActionDropdown';

export default function GroupReels({
  match,
  history,
  groupId,
  groupDetailsData,
  handleChangeReelType,
}) {
  const { t } = useTranslation();
  const urlType = match.params.reelType;
  const canRenameReel = usePrivilegesApi(
    PrivilegeTypes.REEL.RENAME_REEL,
    groupId
  );
  const canDeleteReel = usePrivilegesApi(
    PrivilegeTypes.REEL.DELETE_REEL,
    groupId
  );
  const canSuspendPublishReel = usePrivilegesApi(
    PrivilegeTypes.REEL.SUSPEND_PUBLISH_REEL,
    groupId
  );
  const canEditMandatoryReel = usePrivilegesApi(
    PrivilegeTypes.MANDATORY_REEL_AND_DEAD_LINE.EDIT_MANDATORY_REEL,
    groupId
  );
  const canDeleteMandatoryReel = usePrivilegesApi(
    PrivilegeTypes.MANDATORY_REEL_AND_DEAD_LINE.DELETE_MANDATORY_REEL,
    groupId
  );

  const { groupName = null, parentGroupName = null } = groupDetailsData || {};
  const [isGridView, setIsGridView] = useState(true);
  const [searchReelName, setSearchReelName] = useState('');
  const [isCreateReelOpen, setIsCreateReelOpen] = useState(false);
  const [isAddReelModalOpen, setIsAddReelModalOpen] = useState(false);
  const [isUpdateReelModalOpen, setIsUpdateReelModalOpen] = useState(false);
  const [isCloneReelModalOpen, setIsCloneReelModalOpen] = useState(false);
  const [isViewStatsModalOpen, setIsViewStatsModalOpen] = useState(null);
  const [isUserControlModalOpen, setIsUserControlModalOpen] = useState(false);
  const [selectedReel, setSelectedReel] = useState(null);
  const [organizedReels, setOrganizedReels] = useState([]);
  const [organizedDocumentReels, setOrganizedDocumentReels] = useState([]);
  const [reelType, setReelType] = useState();
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(null);
  const [organizedMandatoryReels, setOrganizedMandatoryReels] = useState({
    currentReels: [],
    deadLineExceeded: [],
  });
  const [isPdfViewModalOpen, setIsPdfViewModalOpen] = useState(null);
  const {
    reelsByGroupSuccess,
    documentReelsFetchDataSuccess,
    deleteReelLoading,
    mandatoryReelsFetchData,
    reelsByGroupLoading,
    documentReelsFetchDataLoading,
    fetchMandatoryReelsLoading,
    suspendReelLoading,
  } = useSelector(state => state?.reel);
  const { deleteVideoLoading } = useSelector(state => state?.video);
  const { deleteStandaloneAssessmentLoading } = useSelector(
    state => state?.assessment
  );
  const { deleteImageLoading } = useSelector(state => state?.bannerAndFeatured);
  const { deleteDocumentLoading } = useSelector(state => state?.documentData);
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;
  const defaultDocument = themeInfo?.documentImageUrl;
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;

  const [fetchData] = useFetchDataApi();
  const [updateIsPublishedStatus] = useChangePublishedStatusApi();
  const [deleteReel] = useDeleteApi();

  const fetchReels = () => fetchData({ groupId, type: 'VIDEO_REELS' });
  const fetchDocumentReels = () =>
    fetchData({ groupId, type: 'DOCUMENT_REELS' });
  const getMandatoryReels = () =>
    fetchData({ groupId, type: 'MANDATORY_REELS' });

  useEffect(() => {
    if (urlType) {
      setReelType(urlType.toUpperCase());
    }
  }, [urlType]);

  useEffect(() => {
    const currentUrl = window.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');
    switch (reelType) {
      case 'VIDEO':
        history.push(`${currentUrl}/video`);
        fetchReels();
        handleChangeReelType('VIDEO');
        break;

      case 'DOCUMENT':
        history.push(`${currentUrl}/document`);
        fetchDocumentReels();
        handleChangeReelType('DOCUMENT');
        break;

      case 'MANDATORY':
        history.push(`${currentUrl}/mandatory`);
        getMandatoryReels();
        handleChangeReelType('MANDATORY');
        break;

      default:
        break;
    }
  }, [reelType]);

  useEffect(() => {
    if (reelsByGroupSuccess) {
      const organizedReels = reelsByGroupSuccess.map(reel => {
        const {
          _id,
          date,
          includeUser,
          noOfGroups,
          isPublished,
          noOfVideos,
          views,
          name,
          previewImageUrl,
        } = reel;

        return {
          _id,
          name,
          isPublished,
          isEmpty: noOfVideos === 0,
          dateCreated: organizeDate(date),
          totalViews: views,
          numberOfUsers: includeUser,
          numberOfGroups: noOfGroups,
          numberOfVideos: noOfVideos,
          imageUrl: previewImageUrl || defaultVideo,
        };
      });

      setOrganizedReels(organizedReels);
    }
  }, [reelsByGroupSuccess]);

  useEffect(() => {
    if (documentReelsFetchDataSuccess) {
      const organizedDocumentReels = documentReelsFetchDataSuccess.map(
        documentReel => {
          const {
            _id,
            name,
            date,
            image,
            views,
            isPublished,
            noOfGroups,
            includeUser,
            noOfDocuments,
          } = documentReel;

          return {
            _id,
            name,
            isPublished,
            isEmpty: noOfDocuments === 0,
            dateCreated: organizeDate(date),
            totalViews: views,
            numberOfUsers: includeUser,
            numberOfGroups: noOfGroups,
            numberOfDocuments: noOfDocuments,
            imageUrl: image || defaultDocument,
          };
        }
      );

      setOrganizedDocumentReels(organizedDocumentReels);
    }
  }, [documentReelsFetchDataSuccess]);

  useEffect(() => {
    if (mandatoryReelsFetchData) {
      const currentReels = [];
      const deadLineExceeded = [];

      mandatoryReelsFetchData.forEach(mandatoryReel => {
        const dueDate = new Date(mandatoryReel.dueDate);
        const { type, tileImageUrl, previewImgUrl } = mandatoryReel;
        let imageUrl = '';

        switch (type) {
          case 'ASSESSMENT':
            imageUrl = tileImageUrl || defaultAssessment;
            break;

          case 'VIDEO':
            imageUrl = previewImgUrl || defaultVideo;
            break;

          case 'DOCUMENT':
            imageUrl = previewImgUrl || defaultDocument;
            break;

          case 'IMAGE':
            imageUrl = mandatoryReel.imageUrl;
            break;

          default:
            break;
        }

        if (dueDate < new Date()) {
          deadLineExceeded.push({
            ...mandatoryReel,
            imageUrl,
          });
        } else {
          currentReels.push({
            ...mandatoryReel,
            imageUrl,
          });
        }
      });

      setOrganizedMandatoryReels({
        currentReels,
        deadLineExceeded,
      });
    }
  }, [mandatoryReelsFetchData]);

  useEffect(() => {
    if (!deleteReelLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteReelLoading]);

  useEffect(() => {
    if (!deleteVideoLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteVideoLoading]);

  useEffect(() => {
    if (!deleteDocumentLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteDocumentLoading]);

  useEffect(() => {
    if (!deleteStandaloneAssessmentLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteStandaloneAssessmentLoading]);

  useEffect(() => {
    if (!deleteImageLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteImageLoading]);

  const renderReelsView = () => {
    switch (reelType) {
      case 'VIDEO':
        return organizedReels
          .filter(
            ({ name }) =>
              searchReelName === '' ||
              name?.toUpperCase().includes(searchReelName.toUpperCase())
          )
          .map((reel, index) => {
            if (isGridView) {
              return renderGridTile(reel, index);
            } else {
              return renderTableRow(reel, index);
            }
          });

      case 'DOCUMENT':
        return organizedDocumentReels
          .filter(
            ({ name }) =>
              searchReelName === '' ||
              name?.toUpperCase().includes(searchReelName.toUpperCase())
          )
          .map((documentReel, index) => {
            if (isGridView) {
              return renderGridTile(documentReel, index);
            } else {
              return renderTableRow(documentReel, index);
            }
          });

      default:
        break;
    }
  };

  const renderGridTile = (gridObject, index) => {
    const { _id, name, imageUrl, isPublished, isEmpty } = gridObject;
    const attachUrl = reelType === 'VIDEO' ? 'video' : 'document';

    return (
      <div className="reel-view--grid-video-wrapper" key={index}>
        <div
          className={`reel-view--grid-video ${
            !isPublished ? 'unpublished' : ''
          }`}
        >
          <div
            className="reel-view--grid-video-unpublished"
            onClick={() =>
              history.push(
                `/group-dashboard/${groupId}/reels/${attachUrl}/${_id}`
              )
            }
          ></div>
          {!isEmpty ? (
            <img
              src={imageUrl}
              alt="reel-thumbnail"
              className="reel-thumbnail"
              onClick={() =>
                history.push(
                  `/group-dashboard/${groupId}/reels/${attachUrl}/${_id}`
                )
              }
              onError={event => {
                event.target.onerror = null;
                event.target.src =
                  reelType === 'VIDEO' ? defaultVideo : defaultDocument;
              }}
            />
          ) : (
            <div
              className={`reel-view--grid-video ${
                reelType === 'VIDEO' ? 'emptyreel' : 'emptyreel-document'
              }`}
              onClick={() =>
                history.push(
                  `/group-dashboard/${groupId}/reels/${attachUrl}/${_id}`
                )
              }
            ></div>
          )}
          <div
            className={`reel-view--grid-video-settings ${
              isViewStatsModalOpen === _id ? 'dataModal' : ''
            }`}
          >
            {canRenameReel && (
              <img
                src={edit_white}
                alt="edit-icon"
                className="icon--edit-white"
                title={t(ToolTipConfig.adminSettings.reelTile.edit)}
                onClick={() => handleEditReelNameData(true, gridObject)}
              />
            )}
            {canSuspendPublishReel && (
              <img
                src={isPublished ? warning : publish}
                alt={isPublished ? 'warning-icon' : 'publish-icon'}
                className={isPublished ? 'icon--warning' : 'icon--publish'}
                title={
                  isPublished
                    ? ToolTipConfig.adminSettings.reelTile.suspend
                    : ToolTipConfig.adminSettings.reelTile.publish
                }
                onClick={() => hanldeIsPublishedStatus(_id)}
              />
            )}
            {canDeleteReel && (
              <img
                src={deleteIcon_white}
                alt="delete-icon"
                className="icon--delete-white"
                title={t(ToolTipConfig.adminSettings.reelTile.bin)}
                onClick={() => handleDelete(_id)}
              />
            )}
            <div className="reel-view--grid-video-settings-stats">
              <img
                src={stats}
                alt="stats-icon"
                className="icon--stats"
                title={t(ToolTipConfig.adminSettings.reelTile.stats)}
                onClick={() => handleViewStats(gridObject)}
              />
            </div>
            <img
              src={copy}
              alt="delete-icon"
              className="icon--copy"
              title={t(ToolTipConfig.adminSettings.reelTile.duplicate)}
              onClick={() => handleCloneReelData(true, gridObject)}
            />
          </div>
          {isViewStatsModalOpen && isViewStatsModalOpen === _id && (
            <ReelStatsModal
              reel={selectedReel}
              isOpen={isViewStatsModalOpen === _id}
              handleUserAccessControl={() => setIsUserControlModalOpen(true)}
              handleClose={() => setIsViewStatsModalOpen(null)}
              fetchReels={
                reelType === 'VIDEO'
                  ? () => fetchReels()
                  : () => fetchDocumentReels()
              }
            />
          )}
        </div>
        <p>{name}</p>
      </div>
    );
  };

  const renderTableRow = (rowItem, index) => {
    const {
      _id,
      name,
      dateCreated,
      imageUrl,
      totalViews,
      numberOfUsers,
      numberOfDocuments,
      numberOfVideos,
      isPublished,
    } = rowItem;

    return (
      <div
        key={index}
        className="reel-view--table-row"
        onClick={event => handleClickOnTableRow(event, _id)}
      >
        <div className="reel-view--table-cell">
          <img
            src={imageUrl}
            className="reel-view--table-backgroundimg"
            onError={event => {
              event.target.onerror = null;
              event.target.src =
                reelType === 'VIDEO' ? defaultVideo : defaultDocument;
            }}
          />
        </div>
        <div className="reel-view--table-cell">
          <p>{name}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>{dateCreated}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>{numberOfUsers}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>
            {numberOfVideos !== undefined ? numberOfVideos : numberOfDocuments}
          </p>
        </div>
        {reelType === 'VIDEO' && (
          <div className="reel-view--table-cell">
            <p>{totalViews}</p>
          </div>
        )}
        <div className="reel-view--table-cell">
          <ActionDropdown
            ishaveRename={canRenameReel}
            ishaveSuspend={canSuspendPublishReel && isPublished}
            ishavePublish={canSuspendPublishReel && !isPublished}
            ishaveDelete={canDeleteReel}
            ishaveDuplicate={true}
            handleOnchange={option =>
              setReelListTableActions(option.value, rowItem)
            }
          />
        </div>
      </div>
    );
  };

  const handleEditReelNameData = (modalStatus, reel) => {
    setIsUpdateReelModalOpen(modalStatus);

    if (modalStatus) {
      setSelectedReel(reel);
    } else {
      setSelectedReel(null);
    }
  };

  const handleViewStats = reel => {
    setIsViewStatsModalOpen(reel._id);
    setSelectedReel(reel);
  };

  const handleCloneReelData = (modalStatus, reel) => {
    setIsCloneReelModalOpen(modalStatus);

    if (modalStatus) {
      setSelectedReel(reel);
    } else {
      setSelectedReel(null);
    }
  };

  const hanldeIsPublishedStatus = reelId => {
    updateIsPublishedStatus({
      groupId,
      _id: reelId,
      type: 'REEL',
      reelType: reelType,
    });
  };

  const setReelListTableActions = (value, reel) => {
    switch (value) {
      case 'Rename':
        handleEditReelNameData(true, reel);
        break;

      case 'Duplicate':
        handleCloneReelData(true, reel);
        break;

      case 'Suspend':
        hanldeIsPublishedStatus(reel._id);
        break;

      case 'Publish':
        hanldeIsPublishedStatus(reel._id);
        break;

      case 'Delete':
        handleDelete(reel._id);
        break;

      default:
        break;
    }
  };

  const handleClickOnTableRow = (event, id) => {
    if (
      event.target.classList.contains('Dropdown-placeholder') ||
      event.target.classList.contains('Dropdown-control')
    ) {
      event.preventDefault();
    } else {
      const attachUrl = reelType === 'VIDEO' ? 'video' : 'document';

      history.push(`/group-dashboard/${groupId}/reels/${attachUrl}/${id}`);
    }
  };

  const handleDelete = id => {
    let deleteRequestData = {
      id,
      groupId,
      reelType,
      type: 'REEL',
    };

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const renderMandatoryReels = () => {
    if (isGridView) {
      return (
        <MandatoryReelGridView
          canEditMandatoryReel={canEditMandatoryReel}
          canDeleteMandatoryReel={canDeleteMandatoryReel}
          mandatoryReels={organizedMandatoryReels}
          handleGridOnclick={handleMandatoryReelView}
          handleEdit={handleMandatoryReelEdit}
          handleDelete={handleMandatoryReelDelete}
          seachName={searchReelName}
        />
      );
    } else {
      return (
        <MandatoryReelListView
          canEditMandatoryReel={canEditMandatoryReel}
          canDeleteMandatoryReel={canDeleteMandatoryReel}
          mandatoryReels={organizedMandatoryReels}
          handleRowOnclick={handleMandatoryReelView}
          handleDropdownOnchange={handleMandatoryActionsDropdown}
          seachName={searchReelName}
        />
      );
    }
  };

  const handleMandatoryReelView = data => {
    switch (data.type) {
      case 'DOCUMENT':
        setIsPdfViewModalOpen(data);
        break;

      case 'VIDEO':
        if (data?._id?._id) {
          history.push(`/theatre/${data._id._id}`);
        }
        break;

      case 'ASSESSMENT':
        if (data?._id?._id) {
          history.push(`/assessment/${data._id._id}`);
        }
        break;

      case 'IMAGE':
        if (data.assignUrl) {
          window.open(data.assignUrl, '_blank');
        }
        break;

      default:
        break;
    }
  };

  const handleMandatoryReelEdit = data => {
    switch (data.type) {
      case 'DOCUMENT':
        history.push(
          `/edit-document/${getBannerFeaturedEditRoute(data)}${data?._id?._id}`
        );
        break;

      case 'VIDEO':
        history.push(
          `/edit-video/${getBannerFeaturedEditRoute(data)}${data?._id?._id}`
        );
        break;

      case 'ASSESSMENT':
        history.push(
          `/edit-assessment/${getBannerFeaturedEditRoute(data)}${
            data?._id?._id
          }`
        );
        break;

      case 'IMAGE':
        history.push(
          `/edit-image/${getBannerFeaturedEditRoute(data)}${data?._id?._id}`
        );
        break;

      default:
        break;
    }
  };

  const handleMandatoryActionsDropdown = (value, data) => {
    switch (value) {
      case 'Edit':
        handleMandatoryReelEdit(data);
        break;

      case 'Delete':
        handleMandatoryReelDelete(data);
        break;

      default:
        break;
    }
  };

  const handleMandatoryReelDelete = data => {
    let deleteRequestData = {
      groupId,
      reelId: data.reelId,
      categoryId: data.categoryId,
      isMandatory: true,
      vid_doc_standalone_Id: data?._id?._id,
      viewType: 'MANDATORY_GROUP_VIEW',
    };

    switch (data.type) {
      case 'DOCUMENT':
        deleteRequestData = {
          ...deleteRequestData,
          type: 'DOCUMENT',
        };
        break;

      case 'VIDEO':
        deleteRequestData = {
          ...deleteRequestData,
          type: 'VIDEO',
        };
        break;

      case 'ASSESSMENT':
        deleteRequestData = {
          ...deleteRequestData,
          type: 'STANDALONE_ASSESSMENT',
        };
        break;

      case 'IMAGE':
        deleteRequestData = {
          ...deleteRequestData,
          id: data?._id?._id,
          type: 'IMAGE',
        };
        break;

      default:
        break;
    }

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const isLoading =
    reelsByGroupLoading ||
    documentReelsFetchDataLoading ||
    fetchMandatoryReelsLoading ||
    suspendReelLoading;

  return (
    <div className="group-users-table--wrapper-column">
      <div className="reel-view--wrapper">
        <div
          className={`reel-view--wrapper-column loading-screen-parent ${
            isLoading ? 'setheight' : ''
          }`}
        >
          <div className="reel-view--page-title">
            <div className="reel-view--page-selection">
              <BreadCrumbView
                history={history}
                routeOptions={[
                  {
                    title: parentGroupName ? parentGroupName : '',
                    path: `/node-tree`,
                  },
                  {
                    title: groupName ? groupName : '',
                    path: `/home/${groupId}/`,
                  },
                  {
                    title:
                      reelType === 'VIDEO'
                        ? 'Reels'
                        : reelType === 'DOCUMENT'
                        ? 'Document Reels'
                        : 'Mandatory Reels',
                    path: '#',
                  },
                ]}
              />
              <h2>
                {reelType === 'DOCUMENT' && 'Document '}
                {reelType === 'MANDATORY' && 'Mandatory '}
                {t('Reels')}
              </h2>
            </div>
            <div className="reel-view--page-settings">
              <input
                className="form-input form-input--search"
                type="search"
                id="search"
                name="search"
                placeholder={t('Search')}
                value={searchReelName}
                onChange={event => setSearchReelName(event.target.value)}
              />
              {/* <Dropdown title="Filter By" options={[]} /> */}
              {reelType !== 'MANDATORY' && (
                <button
                  className="btn btn--primary btn--createnew"
                  onClick={() => setIsCreateReelOpen(true)}
                >
                  {t('Create Reel')}
                </button>
              )}
              <div
                className={`reel-view--image grid ${
                  isGridView ? 'active' : ''
                }`}
                title={t(ToolTipConfig.general.gridView)}
                onClick={() => setIsGridView(true)}
              />
              <div
                className={`reel-view--image list ${
                  !isGridView ? 'active' : ''
                }`}
                title={t(ToolTipConfig.general.tableView)}
                onClick={() => setIsGridView(false)}
              />
            </div>
          </div>
          <div className="reel-view--page-reelselection">
            <p
              className={reelType === 'VIDEO' ? 'active' : ''}
              onClick={() => setReelType('VIDEO')}
            >
              {t('Reels')}
            </p>
            <p
              className={reelType === 'DOCUMENT' ? 'active' : ''}
              onClick={() => setReelType('DOCUMENT')}
            >
              {t('Document Reels')}
            </p>
            <p
              className={reelType === 'MANDATORY' ? 'active' : ''}
              onClick={() => setReelType('MANDATORY')}
            >
              {t('Mandatory Reel')}
            </p>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {isGridView ? (
                reelType !== 'MANDATORY' ? (
                  <div className="reel-view--grid">
                    <div className="reel-view--grid-wrapper">
                      {renderReelsView()}
                      <div
                        className="reel-view--grid-video-wrapper"
                        onClick={() => setIsAddReelModalOpen(true)}
                      >
                        <div className="reel-view--grid-video addbutton">
                          <img
                            src={plus}
                            alt="edit-icon"
                            className="icon--plus"
                          />
                          <p>{t('Add Reel')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  renderMandatoryReels()
                )
              ) : reelType !== 'MANDATORY' ? (
                <div className="reel-view--table">
                  <div className="reel-view--table-header">
                    <div className="reel-view--table-cell">
                      <h5>{t('Preview')}</h5>
                    </div>
                    <div className="reel-view--table-cell">
                      <h5>{t('Title')}</h5>
                    </div>
                    <div className="reel-view--table-cell">
                      <h5>{t('Date Created')}</h5>
                    </div>
                    <div className="reel-view--table-cell">
                      <h5>{t('User Access')}</h5>
                    </div>
                    <div className="reel-view--table-cell">
                      <h5>
                        {t('No. of')}{' '}
                        {reelType === 'VIDEO' ? 'Videos' : 'Documents'}
                      </h5>
                    </div>
                    {reelType === 'VIDEO' && (
                      <div className="reel-view--table-cell">
                        <h5>{t('Total Views')}</h5>
                      </div>
                    )}
                    <div className="reel-view--table-cell">
                      {/* Dropdown column */}
                    </div>
                  </div>
                  <div className="reel-view--table-body">
                    <div className="reel-view--grid-video-wrapper">
                      <div
                        className="reel-view--grid-video addbutton"
                        onClick={() => setIsAddReelModalOpen(true)}
                      >
                        <img
                          src={plus}
                          alt="edit-icon"
                          className="icon--plus"
                        />
                        <p>{t('Add Reel')}</p>
                      </div>
                    </div>
                    {renderReelsView()}
                  </div>
                </div>
              ) : (
                renderMandatoryReels()
              )}
            </>
          )}
        </div>
        {isCreateReelOpen && (
          <CreateReelModal
            type={reelType}
            groupId={groupId}
            currentReelNames={
              reelType === 'VIDEO'
                ? [...organizedReels].map(({ name }) => name)
                : [...organizedDocumentReels].map(({ name }) => name)
            }
            fetchReels={
              reelType === 'VIDEO'
                ? () => fetchReels()
                : () => fetchDocumentReels()
            }
            handleClose={() => setIsCreateReelOpen(false)}
          />
        )}
        {isAddReelModalOpen && (
          <AddReelModal
            reelType={reelType}
            groupId={groupId}
            handleClose={() => setIsAddReelModalOpen(false)}
            handleSuccess={
              reelType === 'VIDEO'
                ? () => fetchReels()
                : () => fetchDocumentReels()
            }
          />
        )}
        {isUpdateReelModalOpen && (
          <UpdateReelModal
            type={reelType}
            selectedReel={selectedReel}
            groupId={groupId}
            handleClose={() => setIsUpdateReelModalOpen(false)}
            fetchReels={
              reelType === 'VIDEO'
                ? () => fetchReels()
                : () => fetchDocumentReels()
            }
            currentReelNames={
              reelType === 'VIDEO'
                ? [...organizedReels]
                    .filter(({ name }) => name !== selectedReel.name)
                    .map(({ name }) => name)
                : [...organizedDocumentReels]
                    .filter(({ name }) => name !== selectedReel.name)
                    .map(({ name }) => name)
            }
          />
        )}
        {isCloneReelModalOpen && (
          <CloneReelModal
            cloningReelId={selectedReel._id}
            handleClose={() => setIsCloneReelModalOpen(false)}
            fetchReels={
              reelType === 'VIDEO'
                ? () => fetchReels()
                : () => fetchDocumentReels()
            }
            currentReelNames={
              reelType === 'VIDEO'
                ? [...organizedReels].map(({ name }) => name)
                : [...organizedDocumentReels].map(({ name }) => name)
            }
          />
        )}
        {isUserControlModalOpen && (
          <UserControlModal
            groupId={groupId}
            reelId={selectedReel._id}
            reelName={selectedReel.name}
            handleClose={() => setIsUserControlModalOpen(false)}
            handleSuccess={
              reelType === 'VIDEO'
                ? () => fetchReels()
                : () => fetchDocumentReels()
            }
          />
        )}
        {isDeleteConfirmModalOpen && (
          <ConfirmActionModal
            title={
              isDeleteConfirmModalOpen.viewType === 'MANDATORY_GROUP_VIEW'
                ? messageConfigs.mandatoryReelRemoveConfirm.title
                : messageConfigs.deleteConfirm.title
            }
            message={
              isDeleteConfirmModalOpen.viewType === 'MANDATORY_GROUP_VIEW'
                ? isDeleteConfirmModalOpen.type === 'VIDEO'
                  ? messageConfigs.mandatoryReelRemoveConfirm.video
                  : isDeleteConfirmModalOpen.type === 'DOCUMENT'
                  ? messageConfigs.mandatoryReelRemoveConfirm.document
                  : isDeleteConfirmModalOpen.type === 'IMAGE'
                  ? messageConfigs.mandatoryReelRemoveConfirm.image
                  : messageConfigs.mandatoryReelRemoveConfirm.assessment
                : messageConfigs.deleteConfirm.reel
            }
            handleSuccess={() => deleteReel(isDeleteConfirmModalOpen)}
            handleClose={() => setIsDeleteConfirmModalOpen(null)}
            isLoading={
              deleteReelLoading ||
              deleteImageLoading ||
              deleteVideoLoading ||
              deleteDocumentLoading ||
              deleteStandaloneAssessmentLoading
            }
          />
        )}
        {isPdfViewModalOpen && (
          <ViewPDFModal
            pdfTitle={isPdfViewModalOpen.title}
            pdfUrl={isPdfViewModalOpen.documentUrl}
            handleClose={() => setIsPdfViewModalOpen(null)}
          />
        )}
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import { Document, pdfjs, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { SizeMe } from 'react-sizeme';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewerModal = ({ pdfURL, scale }) => {
  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <div>
      <SizeMe
        monitorWidth
        render={({ size }) => (
          <Scrollbar className="assessment__scrollbar">
            <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={scale}
                  width={size.width}
                />
              ))}
            </Document>
          </Scrollbar>
        )}
      />
    </div>
  );
};

export default PDFViewerModal;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { Auth, Signer } from 'aws-amplify';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { WEBSOCKET_MESSAGE_RECEIVED } from '../../redux/actionTypes/webSocketTypes';
import { systemConfig } from '../../Common/SystemConstConfigs';
import { IgnoredEventTypes } from '../../Common/WebsocketEvents';

const { notificationViewType } = systemConfig;

const Websocket = () => {
  const [wsClient, setWsClient] = useState(null);

  const dispatch = useDispatch();
  const { access_token, isAdminView } = useSelector(state => state?.auth);

  const handleTriggerConnection = async () => {
    const credentials = await Auth.currentCredentials();

    const accessInfo = {
      access_key: credentials.accessKeyId,
      secret_key: credentials.secretAccessKey,
      session_token: credentials.sessionToken,
    };

    const signedUrl = Signer.signUrl(
      process.env.REACT_APP_WEBSOCKET_URL,
      accessInfo
    );
    setWsClient(new W3CWebSocket(signedUrl));
  };

  useEffect(() => {
    if (wsClient) {
      wsClient.onerror = event => {
        console.error('Error opening websocket connection', event);
      };
      wsClient.onopen = () => {
        console.log('WebSocket connection established successfully');
      };

      wsClient.onmessage = event => {
        if (typeof event.data === 'string') {
          const data = JSON.parse(event.data);
          const correctNotificationTypeForUser =
            data?.notificationType === notificationViewType?.rms
              ? notificationViewType?.rms
              : isAdminView
              ? notificationViewType.admin
              : notificationViewType.user;
          if (
            data?.message &&
            data?.notificationType === correctNotificationTypeForUser &&
            !IgnoredEventTypes.includes(data?.eventType)
          ) {
            displayToastMessage(data.message);
          }

          dispatch({
            type: WEBSOCKET_MESSAGE_RECEIVED,
            payload: data,
          });
        }
      };

      wsClient.onclose = () => {
        console.log('The websocket connection has been closed successfully.');
      };
    }
  }, [wsClient]);

  const closeWebSocket = () => {
    if (wsClient && wsClient?.readyState === wsClient?.OPEN) {
      wsClient.close(1000);
    }
  };

  useEffect(() => {
    access_token && handleTriggerConnection();
    !access_token && closeWebSocket();
  }, [access_token]);

  const displayToastMessage = message => {
    toast.dark(message);
  };

  return <ToastContainer autoClose={4000} />;
};

export default Websocket;

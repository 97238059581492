import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useDeleteApi from '../../../../Hooks/useDeleteApi';
import useChangePublishedStatusApi from '../../../../Hooks/useChangePublishedStatusApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';

import deleteIcon_white from '../../../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../../../assets/images/svg-images/icon-edit-white.svg';
import picture from '../../../../assets/images/svg-images/icon-picture.svg';
import stats from '../../../../assets/images/svg-images/icon-stats.svg';
import warning from '../../../../assets/images/svg-images/icon-warning.svg';
import publish from '../../../../assets/images/svg-images/icon-upload.svg';
import plus from '../../../../assets/images/svg-images/add.svg';
import CreateCategoryModal from '../Modal/createCategoryModal';
import AddVideoToReelModal from '../Modal/addVideoToReelModal';
import ViewPDFModal from '../Modal/viewPDFModal';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import BreadCrumbView from '../../../../Components/BreadCrumbView';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import { getBannerFeaturedEditRoute } from '../../../../Helper/SystemManager';
import Loader from '../../../../Components/Loader/loader';
import { ToolTipConfig } from '../../../../Common/ToolTipConfig.js';
import ActionDropdown from '../../../../Components/Admin/ActionDropdown';

export default function GroupReel({
  history,
  groupId,
  reelId,
  groupDetailsData,
  match,
}) {
  const { t } = useTranslation();
  const canSuspendPublishVideo = usePrivilegesApi(
    PrivilegeTypes.VIDEO.SUSPEND_PUBLISH_VIDEO,
    groupId
  );
  const canEditVideo = usePrivilegesApi(
    PrivilegeTypes.VIDEO.EDIT_VIDEO,
    groupId
  );
  const canDeleteVideo = usePrivilegesApi(
    PrivilegeTypes.VIDEO.DELETE_VIDEO,
    groupId
  );

  const reelType = match.params.reelType
    ? match.params.reelType.toUpperCase()
    : '';
  const { groupName = null, parentGroupName = null } = groupDetailsData || {};
  const [isGridView, setIsGridView] = useState(true);
  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] =
    useState(false);
  const [isAddVideoToReelModalOpen, setIsAddVideoToReelModalOpen] =
    useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState('');
  const [isPdfViewModalOpen, setIsPdfViewModalOpen] = useState(false);
  const [organizedCategories, setOrganizedCategories] = useState([]);
  const [reelName, setReelName] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(null);
  const [searchName, setSearchName] = useState('');
  const { allCategoriesFetchDataSuccess, allCategoriesFetchDataLoading } =
    useSelector(state => state?.reel);
  const { deleteVideoLoading, suspendVideoLoading } = useSelector(
    state => state?.video
  );
  const { deleteDocumentLoading, suspendDocumentLoading } = useSelector(
    state => state?.documentData
  );
  const { suspendAssessmentLoading, deleteStandaloneAssessmentLoading } =
    useSelector(state => state?.assessment);
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;
  const defaultDocument = themeInfo?.documentImageUrl;
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;
  const defaultDocumentCategory = themeInfo?.documentCategoryImageUrl;

  const [fetchData] = useFetchDataApi();
  const [updateVideoIsPublishedStatus] = useChangePublishedStatusApi();
  const [deleteReelItem] = useDeleteApi();

  useEffect(() => {
    if (reelId) {
      fetchCategoriesDataByReel();
    }
  }, [reelId]);

  const fetchCategoriesDataByReel = () =>
    fetchData({ groupId, reelId, type: 'REEL_DATA' });

  useEffect(() => {
    if (allCategoriesFetchDataSuccess) {
      const organizedArray = [];

      allCategoriesFetchDataSuccess.forEach(allCategories => {
        setReelName(allCategories.name);

        for (let category of allCategories['category']) {
          const { _id, name, videos, documents, assessments } = category;

          if (!name || !_id) {
            continue;
          }

          const files = [];

          if (videos.length > 0) {
            videos.forEach(video => {
              const { title, previewImgUrl } = video;

              files.push({
                ...video,
                name: title,
                previewImage: previewImgUrl || defaultVideo,
                type: 'VIDEO',
              });
            });
          }

          if (assessments.length > 0) {
            assessments.forEach(assessment => {
              const { title, tileImageUrl } = assessment;

              files.push({
                ...assessment,
                name: title,
                previewImage: tileImageUrl || defaultAssessment,
                type: 'ASSESSMENT',
              });
            });
          }

          if (documents.length > 0) {
            documents.forEach(documentFile => {
              const { title, previewImgUrl, documentUrl } = documentFile;

              files.push({
                ...documentFile,
                name: title,
                type: 'DOCUMENT',
                documentUrl: documentUrl ? documentUrl : '',
                previewImage: previewImgUrl || defaultDocument,
              });
            });
          }

          organizedArray.push({
            _id,
            name,
            files,
          });
        }
      });

      setOrganizedCategories(organizedArray);
    }
  }, [allCategoriesFetchDataSuccess]);

  useEffect(() => {
    if (!deleteVideoLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteVideoLoading]);

  useEffect(() => {
    if (!deleteDocumentLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteDocumentLoading]);

  useEffect(() => {
    if (!deleteStandaloneAssessmentLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteStandaloneAssessmentLoading]);

  const renderCategoryGridViews = () => {
    return organizedCategories.map((category, index) => {
      const { _id, name, files } = category;

      return (
        <div key={index}>
          {reelType === 'VIDEO' && <h3>{name}</h3>}
          <div className="reel-view--grid groups">
            {reelType === 'DOCUMENT' && (
              <div
                className="reel-view--grid__reel-title"
                style={{
                  backgroundImage: `url(${defaultDocumentCategory})`,
                }}
              >
                <p>{name}</p>
              </div>
            )}
            <div className="reel-view--grid-wrapper">
              {renderGridViewTiles(files, _id)}
              <div className="reel-view--grid-video-wrapper">
                <div
                  className="reel-view--grid-video addbutton"
                  onClick={() => {
                    setCurrentCategoryId(_id);
                    setIsAddVideoToReelModalOpen(true);
                  }}
                >
                  <img src={plus} alt="edit-icon" className="icon--plus" />
                  <p>
                    {reelType === 'VIDEO'
                      ? 'Add Video / Assessment'
                      : 'Add Document'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const handleIsPublishedStatus = (id, reelType) => {
    updateVideoIsPublishedStatus({
      _id: id,
      type: reelType,
      viewType: 'GROUP_SETTINGS',
    });
  };

  const renderGridViewTiles = (files, categoryId) => {
    return files
      .filter(
        ({ name }) =>
          !searchName || name?.toUpperCase().includes(searchName.toUpperCase())
      )
      .map((file, index) => {
        const { _id, name, type, previewImage, isPublished } = file;

        return (
          <div className="reel-view--grid-video-wrapper" key={index}>
            <div
              className={`reel-view--grid-video ${
                !isPublished ? 'unpublished' : ''
              }`}
            >
              <div
                className="reel-view--grid-video-unpublished"
                onClick={
                  reelType === 'VIDEO'
                    ? type === 'ASSESSMENT'
                      ? () => history.push(`/assessment/${_id}`)
                      : () => history.push(`/theatre/${_id}`)
                    : () => handleViewPDF(file)
                }
              ></div>
              <div
                className="reel-thumbnail"
                onClick={
                  reelType === 'VIDEO'
                    ? type === 'ASSESSMENT'
                      ? () => history.push(`/assessment/${_id}`)
                      : () => history.push(`/theatre/${_id}`)
                    : () => handleViewPDF(file)
                }
                style={{
                  backgroundImage: `url(${previewImage})`,
                }}
              ></div>
              <div className="reel-view--grid-video-settings">
                {canEditVideo && (
                  <img
                    src={edit_white}
                    alt="edit-icon"
                    className="icon--edit-white"
                    onClick={() => handleEdit(file)}
                    title={renderToolTip('EDIT', type)}
                  />
                )}
                {canSuspendPublishVideo && (
                  <img
                    src={isPublished ? warning : publish}
                    alt={isPublished ? 'warning-icon' : 'publish-icon'}
                    className={isPublished ? 'icon--warning' : 'icon--publish'}
                    onClick={() => handleIsPublishedStatus(_id, type)}
                    title={renderToolTip(
                      isPublished ? 'SUSPEND' : 'PUBLISH',
                      type
                    )}
                  />
                )}
                {canDeleteVideo && (
                  <img
                    src={deleteIcon_white}
                    alt="delete-icon"
                    className="icon--delete-white"
                    onClick={() => handleDelete(_id, type, categoryId)}
                    title={renderToolTip('DELETE', type)}
                  />
                )}
                {type !== 'DOCUMENT' && (
                  <div className="reel-view--grid-video-settings-stats">
                    <img
                      src={stats}
                      alt="stats-icon"
                      className="icon--stats"
                      onClick={() => handleViewStats(_id, type)}
                      title={renderToolTip('STATS', type)}
                    />
                  </div>
                )}
              </div>
            </div>
            <p>{name}</p>
          </div>
        );
      });
  };

  const renderCategoryListViews = () => {
    return organizedCategories.map((category, index) => {
      const { _id, name, files } = category;

      return (
        <div className="reel-view--table" key={index}>
          <p className="reel-view--table__category-name">{name}</p>
          {renderListTableHeaders()}
          <div className="reel-view--table-body">
            <div className="reel-view--grid-video-wrapper">
              <div
                className="reel-view--grid-video addbutton"
                onClick={() => setIsAddVideoToReelModalOpen(true)}
              >
                <img src={plus} alt="edit-icon" className="icon--plus" />
                <p>
                  {reelType === 'VIDEO'
                    ? 'Add Video / Assessment'
                    : 'Add Document'}
                </p>
              </div>
            </div>
            {renderListViewTableRows(files, _id)}
          </div>
        </div>
      );
    });
  };

  const renderListTableHeaders = () => {
    return (
      <div className="reel-view--table-header">
        <div className="reel-view--table-cell">
          <h5>{t('Preview')}</h5>
        </div>
        <div className="reel-view--table-cell">
          <h5>{t('Title')}</h5>
        </div>
        <div className="reel-view--table-cell">
          <h5>{t('Date Created')}</h5>
        </div>
        {reelType === 'VIDEO' && (
          <div className="reel-view--table-cell">
            <h5>{t('Assessment')}</h5>
          </div>
        )}
        {reelType === 'VIDEO' && (
          <div className="reel-view--table-cell">
            <h5>{t('Total Views')}</h5>
          </div>
        )}
        <div className="reel-view--table-cell">{/* Dropdown column */}</div>
      </div>
    );
  };

  const renderListViewTableRows = (files, categoryId) => {
    return files
      .filter(
        ({ name }) =>
          !searchName || name?.toUpperCase().includes(searchName.toUpperCase())
      )
      .map((file, index) => {
        const {
          name,
          previewImage,
          isPublished,
          isAssessmentAvailable,
          date,
          views,
          type,
        } = file;

        return (
          <div className="reel-view--table-row" key={index}>
            <div className="reel-view--table-cell">
              <img
                src={previewImage}
                className="reel-view--table-backgroundimg"
              />
            </div>
            <div className="reel-view--table-cell">
              <p>{name}</p>
            </div>
            <div className="reel-view--table-cell">
              <p>
                {date ? moment.utc(date).local().format('DD/MM/YYYY') : '-'}
              </p>
            </div>
            {reelType === 'VIDEO' && (
              <div className="reel-view--table-cell">
                {type === 'VIDEO' ? (
                  <p>{isAssessmentAvailable ? 'Yes' : 'No'}</p>
                ) : (
                  '-'
                )}
              </div>
            )}
            {reelType === 'VIDEO' && (
              <div className="reel-view--table-cell">
                <p>{views || '0'}</p>
              </div>
            )}
            <div className="reel-view--table-cell">
              <ActionDropdown
                ishaveEdit={canEditVideo}
                ishaveSuspend={canSuspendPublishVideo && isPublished}
                ishavePublish={canSuspendPublishVideo && !isPublished}
                ishaveDelete={canDeleteVideo}
                handleOnchange={option =>
                  setTableActions(option.value, file, categoryId)
                }
              />
            </div>
          </div>
        );
      });
  };

  const handleViewStats = (id, type) => {
    switch (type) {
      case 'VIDEO':
        history.push(`/group-dashboard/${groupId}/reels/${reelId}/video/${id}`);
        break;

      case 'ASSESSMENT':
        history.push(
          `/group-dashboard/${groupId}/assessments/standalone/${reelId}/${id}`
        );
        break;

      default:
        break;
    }
  };

  const setTableActions = (value, file, categoryId) => {
    switch (value) {
      case 'Suspend':
        handleIsPublishedStatus(file._id, file.type);
        break;

      case 'Publish':
        handleIsPublishedStatus(file._id, file.type);
        break;

      case 'Edit':
        handleEdit(file);
        break;

      case 'Delete':
        handleDelete(file._id, file.type, categoryId);
        break;

      default:
        break;
    }
  };

  const handleViewPDF = documentReel => {
    if (documentReel.type === 'DOCUMENT') {
      setIsPdfViewModalOpen(true);
      setPdfUrl(documentReel.documentUrl);
      setPdfTitle(documentReel.name);
    }
  };

  const handleEdit = data => {
    switch (data.type) {
      case 'DOCUMENT':
        history.push(
          `/edit-document/${getBannerFeaturedEditRoute(data)}${data._id}`
        );
        break;

      case 'VIDEO':
        history.push(
          `/edit-video/${getBannerFeaturedEditRoute(data)}${data._id}`
        );
        break;

      case 'ASSESSMENT':
        history.push(
          `/edit-assessment/${getBannerFeaturedEditRoute(data)}${data._id}`
        );
        break;

      default:
        break;
    }
  };

  const handleDelete = (id, type, categoryId) => {
    let deleteRequestData = {
      vid_doc_standalone_Id: id,
      reelId,
      groupId,
      categoryId,
      viewType: 'REEL_CATEGORY_VIEW',
    };

    switch (type) {
      case 'DOCUMENT':
        deleteRequestData = {
          ...deleteRequestData,
          type: 'DOCUMENT',
        };
        break;

      case 'VIDEO':
        deleteRequestData = {
          ...deleteRequestData,
          type: 'VIDEO',
        };
        break;

      case 'ASSESSMENT':
        deleteRequestData = {
          ...deleteRequestData,
          type: 'STANDALONE_ASSESSMENT',
        };
        break;

      default:
        break;
    }

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const renderToolTip = (iconType, type) => {
    const { assessmentTile, videoTile, documentTile } =
      ToolTipConfig.adminSettings;

    switch (iconType) {
      case 'DELETE':
        return reelType === 'VIDEO'
          ? type === 'ASSESSMENT'
            ? assessmentTile.bin
            : videoTile.bin
          : documentTile.bin;

      case 'EDIT':
        return reelType === 'VIDEO'
          ? type === 'ASSESSMENT'
            ? assessmentTile.edit
            : videoTile.edit
          : documentTile.edit;

      case 'SUSPEND':
        return reelType === 'VIDEO'
          ? type === 'ASSESSMENT'
            ? assessmentTile.suspend
            : videoTile.suspend
          : documentTile.suspend;

      case 'PUBLISH':
        return reelType === 'VIDEO'
          ? type === 'ASSESSMENT'
            ? assessmentTile.publish
            : videoTile.publish
          : documentTile.publish;

      case 'STATS':
        return type === 'ASSESSMENT' ? assessmentTile.stats : videoTile.stats;

      default:
        break;
    }
  };

  const organizeBreadCrumbOptions = () => {
    return [
      {
        title: parentGroupName ? parentGroupName : '',
        path: `/node-tree`,
      },
      {
        title: groupName ? groupName : '',
        path: `/home/${groupId}/`,
      },
      {
        title: (reelType === 'DOCUMENT' ? 'Document ' : '') + 'Reels',
        path: `/group-dashboard/${groupId}/reels/${reelType.toLowerCase()}`,
      },
      {
        title: reelName,
        path: '#',
      },
    ];
  };

  const reelTypeName = reelType === 'VIDEO' ? 'Video' : 'Document';
  const isPageLoading =
    allCategoriesFetchDataLoading ||
    suspendDocumentLoading ||
    suspendVideoLoading ||
    suspendAssessmentLoading;

  return isPageLoading ? (
    <Loader />
  ) : (
    <div className="group-users-table--wrapper-column">
      <div className="reel-view--wrapper">
        <div className="reel-view--wrapper-column">
          <div className="reel-view--page-title">
            <div className="reel-view--page-selection">
              <BreadCrumbView
                history={history}
                routeOptions={organizeBreadCrumbOptions()}
              />
              <h2>{reelName}</h2>
            </div>
            <div className="reel-view--page-settings">
              <input
                className="form-input form-input--search"
                type="search"
                id="search"
                name="search"
                placeholder={t('Search')}
                value={searchName}
                onChange={event => setSearchName(event.target.value)}
              />
              {/* <Dropdown title="Filter By" options={[]} /> */}
              <button
                className="btn btn--primary btn--createnew btn--long"
                onClick={() =>
                  history.push(
                    `/create-${reelTypeName.toLowerCase()}/group/${groupId}/reel/${reelId}`
                  )
                }
              >
                {t('Create')} {reelTypeName}
              </button>
              <div
                className={`reel-view--image grid ${
                  isGridView ? 'active' : ''
                }`}
                title={t(ToolTipConfig.general.gridView)}
                onClick={() => setIsGridView(true)}
              />
              <div
                className={`reel-view--image list ${
                  !isGridView ? 'active' : ''
                }`}
                title={t(ToolTipConfig.general.tableView)}
                onClick={() => setIsGridView(false)}
              />
            </div>
          </div>
          {isGridView ? renderCategoryGridViews() : renderCategoryListViews()}
        </div>
      </div>

      {/*<button
        className="btn btn--primary"
        onClick={() => setIsCreateCategoryModalOpen(true)}
      >Create Category</button>*/}

      <p
        className="addanother"
        onClick={() => setIsCreateCategoryModalOpen(true)}
      >
        {t('Create Category')}
      </p>

      {isCreateCategoryModalOpen && (
        <CreateCategoryModal
          reelId={reelId}
          handleClose={() => setIsCreateCategoryModalOpen(false)}
          fetchCategories={() => fetchCategoriesDataByReel()}
        />
      )}
      {isAddVideoToReelModalOpen && (
        <AddVideoToReelModal
          groupId={groupId}
          reelType={reelType}
          reelId={reelId}
          groupId={groupId}
          categoryId={currentCategoryId}
          handleSuccess={() => fetchCategoriesDataByReel()}
          handleClose={() => setIsAddVideoToReelModalOpen(false)}
        />
      )}
      {isPdfViewModalOpen && (
        <ViewPDFModal
          pdfTitle={pdfTitle}
          pdfUrl={pdfUrl}
          handleClose={() => setIsPdfViewModalOpen(false)}
        />
      )}
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={messageConfigs.deleteConfirm.title}
          message={
            isDeleteConfirmModalOpen.type === 'VIDEO'
              ? messageConfigs.deleteConfirm.video
              : isDeleteConfirmModalOpen.type === 'DOCUMENT'
              ? messageConfigs.deleteConfirm.document
              : messageConfigs.deleteConfirm.assessment
          }
          handleSuccess={() => deleteReelItem(isDeleteConfirmModalOpen)}
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
          isLoading={
            deleteVideoLoading ||
            deleteDocumentLoading ||
            deleteStandaloneAssessmentLoading
          }
        />
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Scrollbar from 'react-smooth-scrollbar';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';

import useApi from '../../../../Hooks/useApi';
import {
  FETCH_GROUP_DETAILS_REQUEST,
  FETCH_GROUP_DETAILS_SUCCESS,
  FETCH_GROUP_DETAILS_FAIL,
} from '../../../../redux/actionTypes/Group';

// import group from "../../../assets/images/svg-images/icon-group.svg";
// import user from "../../../assets/images/svg-images/icon-user.svg";
// import reel from "../../../assets/images/svg-images/icon-reel.svg";
// import assessment from "../../../assets/images/svg-images/icon-assessment.svg";

import GroupUsers from './GroupUsers';
import GroupDetails from './GroupDetails';
import CreateUser from '../../Global/Users/CreateUser';
import GroupReels from './GroupReels';
import GroupReel from './GroupReel';
import GroupAssessments from './GroupAssessments';
import GroupVideoStats from '../../Stats/VideoStats';
import GroupAssessmentStats from '../../Stats/AssessmentStats';

export default function GroupDashboard({ history, match }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('group');
  const [reelType, setReelType] = useState('VIDEO');

  const { groupDetailsData } = useSelector(state => state?.group);

  const dispatch = useDispatch();

  const [fetchGroupDetails] = useApi();

  const groupId = match.params.groupId;
  const {
    userId,
    reelId,
    videoAssessmentId,
    assessmentType,
    assessmentId,
  } = match.params;

  const isCreateGroup =
    history && history.location && history.location.pathname
      ? history.location.pathname.search('create-group') > -1
      : null;

  useEffect(() => {
    if (match.path.includes('users')) {
      setActiveTab('users');
    } else if (match.path.includes('reels')) {
      setActiveTab('reels');
    } else if (match.path.includes('assessments')) {
      setActiveTab('assessments');
    } else {
      setActiveTab('group');
    }
  }, [match]);

  useEffect(() => {
    fetchGroupDetails(
      `/division/getDivisionWithParent?divisionId=${groupId}`,
      FETCH_GROUP_DETAILS_REQUEST,
      FETCH_GROUP_DETAILS_SUCCESS,
      FETCH_GROUP_DETAILS_FAIL,
      null,
      '',
      'GET',
      null,
      'isGroupService'
    );

    return () => dispatch({ type: FETCH_GROUP_DETAILS_REQUEST });
  }, [groupId]);

  const renderReelRoute = () => {
    if (reelId) {
      if (videoAssessmentId) {
        return (
          <GroupVideoStats
            history={history}
            groupId={groupId}
            match={match}
            groupDetailsData={groupDetailsData}
          />
        );
      }

      return (
        <GroupReel
          history={history}
          match={match}
          groupId={groupId}
          reelId={reelId}
          groupDetailsData={groupDetailsData}
          reelType={reelType}
        />
      );
    }
    return (
      <GroupReels
        history={history}
        match={match}
        groupId={groupId}
        groupDetailsData={groupDetailsData}
        handleChangeReelType={type => setReelType(type)}
      />
    );
  };

  return (
    <div className="group-users-table">
      <div className="container">
        <p
          className="btn--back"
          onClick={() => history.push(`/home/${groupId}/`)}
        >
          {t('Go Home')}
        </p>
        <div className="group-users-table--wrapper">
          <div className="group-users-table--wrapper-column">
            <div className="group-users-table--options">
              <div
                className={`group-users-table--option ${
                  activeTab === 'group' ? 'active' : ''
                }`}
                onClick={event => {
                  if (isCreateGroup) {
                    event.preventDefault();
                  } else {
                    setActiveTab('group');
                    history.push(`/group-dashboard/${groupId}/group-details`);
                  }
                }}
              >
                <div className="group-users-table--option-icon groupimg" />
                <h3>{t('Group')}</h3>
              </div>
              <div
                className={`group-users-table--option ${
                  isCreateGroup ? 'disabled' : ''
                }${activeTab === 'users' ? 'active' : ''}`}
                onClick={event => {
                  if (isCreateGroup) {
                    event.preventDefault();
                  } else {
                    setActiveTab('users');
                    history.push(`/group-dashboard/${groupId}/users`);
                  }
                }}
              >
                <div className="group-users-table--option-icon userimg" />
                <h3>{t('Users')}</h3>
              </div>
              <div
                className={`group-users-table--option ${
                  isCreateGroup ? 'disabled' : ''
                }${activeTab === 'reels' ? 'active' : ''}`}
                onClick={event => {
                  if (isCreateGroup) {
                    event.preventDefault();
                  } else {
                    setActiveTab('reels');
                    history.push(`/group-dashboard/${groupId}/reels/video`);
                  }
                }}
              >
                <div className="group-users-table--option-icon reelimg" />
                <h3>{t('Reels')}</h3>
              </div>
              <div
                className={`group-users-table--option ${
                  isCreateGroup ? 'disabled' : ''
                }${activeTab === 'assessments' ? 'active' : ''}`}
                onClick={event => {
                  if (isCreateGroup) {
                    event.preventDefault();
                  } else {
                    setActiveTab('assessments');
                    history.push(
                      `/group-dashboard/${groupId}/assessments/mid-video`
                    );
                  }
                }}
              >
                <div className="group-users-table--option-icon assessmentimg" />
                <h3>{t('Assessment')}</h3>
              </div>
            </div>
          </div>
          {activeTab === 'group' && (
            <GroupDetails
              groupId={groupId}
              history={history}
              groupDetailsData={groupDetailsData}
            />
          )}
          {activeTab === 'users' &&
            (userId ? (
              <CreateUser match={match} history={history} />
            ) : (
              <GroupUsers
                history={history}
                groupId={groupId}
                groupDetailsData={groupDetailsData}
              />
            ))}
          {activeTab === 'reels' && renderReelRoute()}
          {activeTab === 'assessments' &&
            (videoAssessmentId ? (
              <GroupAssessmentStats
                history={history}
                groupId={groupId}
                match={match}
                groupDetailsData={groupDetailsData}
              />
            ) : assessmentId && assessmentType ? (
              <GroupAssessmentStats
                history={history}
                groupId={groupId}
                match={match}
                groupDetailsData={groupDetailsData}
              />
            ) : (
              <GroupAssessments
                groupId={groupId}
                history={history}
                groupDetailsData={groupDetailsData}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MultiSelect from 'react-multi-select-component';
import $ from 'jquery';
import _, { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import previewimg from '../../../../assets/images/sample-img.png';
import SaveChangesModal from './Modal/saveChangesModal';
import AddReelCategoryGroup from '../Common/AddReelCategoryGroup';

import {
  CREATE_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_FAIL,
  CREATE_ASSESSMENT_RESET,
} from '../../../../redux/actionTypes/assesment';

import useApi from '../../../../Hooks/useApi';
import { getOrganizedListOfReelIdCategoryIdAndGroups } from '../../../../Helper/SystemManager';
import ProceedMessageModal from '../../../../Components/Modal/ProceedMessageModal';
import Loader from '../../../../Components/Loader/loader';
import { messageConfigs } from '../../../../Helper/PopupMessageConfig';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import ErrorMessageModal from '../../../../Components/Modal/ErrorMessageModal';
import { validateFields } from '../../../../Helper/ValidationsManager';
import MandatoryMark from '../../../../Components/validation/MandatoryMark';
import QuestionsView from '../../../../Components/CreateEditComponents/QuestionsView';
import PreviewImageView from '../../../../Components/CreateEditComponents/PreviewImageView';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';

const questionObj = {
  type: 'mcq',
  isTextOnly: true,
  orientation: 1,
  quiz: '',
  options: [''],
  correctAnswer: 0,
  questionImageS3BucketURL: '',
  isQuestionImageViaUrl: false,
};

const reelCategoryGroupObj = {
  reelId: '',
  categoryId: '',
  groups: [],
};

const initailAssessmentData = {
  title: '',
  description: '',
  author: '',
  isNativeBannerAndFeatured: false,
  isFeatured: false,
  isBanner: false,
  tags: '',
  isPublished: false,
  duration: '',
  startTimeMin: 0,
  startTimeSec: 0,
  triggerTimeMin: 0,
  triggerTimeSec: 0,
  tileImageUrl: '',
  tileImageFile: null,
  isTileImageViaUrl: false,
  questions: [_.cloneDeep(questionObj)],
  listOfReelIdCategoryIdAndGroups: [_.clone(reelCategoryGroupObj)],
  selectedVideos: [],
  documentIds: [],
};

const CreateAssessment = ({ match, history }) => {
  const { t } = useTranslation();
  const isHavePublishAccess = usePrivilegesApi(
    PrivilegeTypes.ASSESSMENT.CREATE_ASSESSMENT
  );
  const { assessmentId, assessmentType } = match.params;

  const getPlaceType = () => {
    switch (assessmentType) {
      case 'mid-video':
        return {
          placement: 'MID',
        };

      case 'end-video':
        return {
          placement: 'END',
        };

      case 'standalone':
        return {
          placement: 'STANDALONE',
        };

      default:
        return;
    }
  };
  const [assessmentData, setAssessmentData] = useState(
    _.cloneDeep({ ...initailAssessmentData, ...getPlaceType() })
  );

  const [isReplicate, setIsReplicate] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isPublishProcess, setIsPublishProcess] = useState(false);
  const [isSaveProcess, setIsSaveProcess] = useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(null);
  const [isValidationError, setIsValidationError] = useState(null);
  const [isValidUrl, setIsValidUrl] = useState(true);

  const [fetchData] = useFetchDataApi();
  const [fetchAssessmentById] = useFetchDataApi();
  const [createAssessment] = useApi();
  const dispatch = useDispatch();

  const {
    addAssessmentSuccess,
    findAssessmentLoading,
    findAssessmentData,
    addAssessmentLoading,
    createAssessmentRelatedInfoloading,
    addAssessmentError,
  } = useSelector(state => state?.assessment);

  const {
    relatedDocuments = [],
    VideoReels = [],
    relatedVideos = [],
  } = useSelector(state => state?.assessment?.createAssessmentRelatedInfoData);

  const reels = useMemo(() => [...VideoReels], [VideoReels]);
  const isLoading = findAssessmentLoading || createAssessmentRelatedInfoloading;

  useEffect(() => {
    if (addAssessmentSuccess) {
      setIsSaved(true);
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message: isPublishProcess
          ? assessmentType === 'standalone' || placement === 'STANDALONE'
            ? messageConfigs.success.standaloneAssessment.PUBLISH
            : assessmentType === 'mid-video' || placement === 'MID'
            ? messageConfigs.success.midVideoAssessment.PUBLISH
            : messageConfigs.success.endVideoAssessment.PUBLISH
          : assessmentType === 'standalone' || placement === 'STANDALONE'
          ? assessmentId
            ? messageConfigs.success.standaloneAssessment.UPDATE
            : messageConfigs.success.standaloneAssessment.SAVE
          : assessmentType === 'mid-video' || placement === 'MID'
          ? assessmentId
            ? messageConfigs.success.midVideoAssessment.UPDATE
            : messageConfigs.success.midVideoAssessment.SAVE
          : assessmentId
          ? messageConfigs.success.endVideoAssessment.UPDATE
          : messageConfigs.success.endVideoAssessment.SAVE,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      dispatch({
        type: CREATE_ASSESSMENT_RESET,
      });
    }
  }, [addAssessmentSuccess]);

  useEffect(() => {
    if (addAssessmentError) {
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      dispatch({
        type: CREATE_ASSESSMENT_RESET,
      });
    }
  }, [addAssessmentError]);

  useEffect(() => {
    setTimeout(() => {
      var allPanels = $('.accordion .admin__createassessment--inner-container');
      $('.accordion h3').unbind();
      $('.accordion h3').click(function () {
        $(this)
          .parent()
          .children('.admin__createassessment--inner-container')
          .slideUp();
        $(this).addClass('closed');
        // allPanels.slideUp();
        if (
          $(this)
            .parent()
            .children('.admin__createassessment--inner-container')
            .css('display') === 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__createassessment--inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });

      // Commented to solve bug mentioned in ticker VTP 366
      // var allQuestionPanels = $(
      //   '.accordion .admin__createassessment--question_inner-container'
      // ).hide();
      $('.admin__createassessment--question-title').unbind();
      $('.admin__createassessment--question-title').click(function () {
        $(this)
          .parent()
          .children('.admin__createassessment--question_inner-container')
          .slideUp();
        $(this).addClass('closed');
        allPanels.slideUp();
        if (
          $(this)
            .parent()
            .children('.admin__createassessment--question_inner-container')
            .css('display') === 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__createassessment--question_inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });
    }, 0);
  });

  useEffect(() => {
    if (assessmentId && VideoReels.length) {
      fetchAssessmentById({ type: 'FIND_ASSESSMENT', objectId: assessmentId });
    }
  }, [assessmentId, VideoReels]);

  useEffect(() => {
    fetchData({ type: 'INTIALIZE_CREATE_ASSESSMENT' });
  }, []);

  useEffect(() => {
    if (findAssessmentData && assessmentId) {
      const {
        _id,
        title,
        description,
        author,
        date,
        isActive,
        isPublished,
        isNativeBannerAndFeatured,
        isFeatured,
        isBanner,
        listOfReelIdCategoryIdAndGroups = [],
        tags,
        isEdit,
        placement,
        startTime,
        triggerTime,
        duration,
        tileImageUrl,
        isTileImageViaUrl,
        questions,
        videoIdsForMidAssessment = [],
        videoIdsForEndAssessment = [],
        videoIdsForStandaloneAssessment,
        documentIds,
        relatedVideoIds = [],
      } = findAssessmentData;

      const tempSelectedVideos = [
        ...videoIdsForMidAssessment,
        ...videoIdsForEndAssessment,
        ...relatedVideoIds,
      ];
      const selectedVideosOptions = relatedVideos
        .filter(({ _id }) => tempSelectedVideos.includes(_id))
        .map(video => ({
          label: video.title,
          value: video._id,
        }));

      const selectedDocumentsOptions = relatedDocuments
        .filter(({ _id }) => documentIds?.includes(_id))
        .map(document => ({
          label: document.title,
          value: document._id,
        }));
      setAssessmentData({
        ...assessmentData,
        _id,
        title,
        description,
        author,
        date,
        isActive,
        isPublished,
        isNativeBannerAndFeatured,
        isFeatured,
        isBanner,
        listOfReelIdCategoryIdAndGroups: listOfReelIdCategoryIdAndGroups.length
          ? getOrganizedListOfReelIdCategoryIdAndGroups(
              listOfReelIdCategoryIdAndGroups,
              reels
            )
          : [_.clone(reelCategoryGroupObj)],
        tags: tags && tags.length && tags.join(),
        isEdit,
        placement,
        startTimeMin: startTime ? startTime.split(':')[0] : 0,
        startTimeSec: startTime ? startTime.split(':')[1] : 0,
        triggerTimeMin: triggerTime ? triggerTime.split(':')[0] : 0,
        triggerTimeSec: triggerTime ? triggerTime.split(':')[1] : 0,
        duration,
        tileImageUrl,
        isTileImageViaUrl,
        questions: questions.map(question => {
          if (question.questionImageS3BucketURL) {
            question.isTextOnly = false;
          } else {
            question.isTextOnly = true;
          }
          return question;
        }),
        selectedVideos: selectedVideosOptions,
        documentIds: selectedDocumentsOptions,
        relatedVideoIds,
      });
    }
  }, [findAssessmentData]);

  const {
    title,
    description,
    author,
    isNativeBannerAndFeatured,
    isFeatured,
    isBanner,
    tags,
    isPublished,
    placement,
    duration,
    startTimeMin,
    startTimeSec,
    triggerTimeMin,
    triggerTimeSec,
    tileImageUrl,
    tileImageFile,
    isTileImageViaUrl,
    questions,
    // listOfReelCategoryVideoIds,
    listOfReelIdCategoryIdAndGroups,
    selectedVideos,
    documentIds,
  } = assessmentData;

  useEffect(() => {
    setIsValidUrl(true);
  }, [tileImageUrl]);

  const changeCurrentUrl = value => {
    const currentUrl = window.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');

    switch (value) {
      case 'MID':
        history.push(`${currentUrl}/mid-video`);
        break;

      case 'END':
        history.push(`${currentUrl}/end-video`);
        break;

      case 'STANDALONE':
        history.push(`${currentUrl}/standalone`);
        break;

      default:
        break;
    }
  };

  const handleAssessmentData = useCallback((value, property) => {
    if (property === 'placement') {
      changeCurrentUrl(value);
    }
    setAssessmentData(assessmentData => {
      const tempAssessmentData = { ...assessmentData };
      tempAssessmentData[property] = value;
      return tempAssessmentData;
    });
    if (isSaved) {
      setIsSaved(false);
    }
  }, []);

  const handleQuestionData = (index, property, value) => {
    const tempQuestions = [...questions];
    tempQuestions[index][property] = value;
    handleAssessmentData(tempQuestions, 'questions');
  };

  const handleCreateAssessment = isPublishedStatus => {
    const formErrors = validateFields({
      type: `${placement}_ASSESSMENT`,
      formData: {
        title,
        questions,
        listOfReelIdCategoryIdAndGroups,
        assignedVideos: selectedVideos,
        startTime: [startTimeSec, startTimeMin],
        triggerTime: [triggerTimeMin, triggerTimeSec],
      },
    });

    if (!isValidUrl && isTileImageViaUrl) {
      return;
    }

    if (formErrors) {
      setIsValidationError(formErrors);
      return;
    }

    const formData = new FormData();
    const tempQuestions = [...questions];

    const assessmentInfo = {
      title,
      description,
      tags: tags.split(','),
      isPublished: assessmentId ? isPublished : isPublishedStatus,
      isEdit: !!assessmentId,
      isFeatured,
      isBanner,
      placement,
      startTime: `${startTimeMin}:${startTimeSec}`,
      triggerTime: `${triggerTimeMin}:${triggerTimeSec}`,
      duration,
      tileImageUrl,
      isTileImageViaUrl: true,
      questions: tempQuestions.map(tempQuestion => {
        const question = { ...tempQuestion };

        question.isQuestionImageViaUrl = true;
        if (!question.questionImageS3BucketURL || question.isTextOnly) {
          question.isQuestionImageViaUrl = false;
          delete question.questionImageS3BucketURL;
        }

        delete question.isTextOnly;
        return question;
      }),
    };
    if (assessmentId) {
      assessmentInfo._id = assessmentId;
    }
    if (placement === 'MID') {
      assessmentInfo.videoIdsForMidAssessment = selectedVideos.map(
        selectedVideo => selectedVideo.value
      );
    }
    if (placement === 'END') {
      assessmentInfo.videoIdsForEndAssessment = selectedVideos.map(
        selectedVideo => selectedVideo.value
      );
    }
    if (placement === 'STANDALONE') {
      assessmentInfo.relatedVideoIds = selectedVideos.map(
        selectedVideo => selectedVideo.value
      );
      assessmentInfo.documentIds = documentIds.map(
        selectedDocument => selectedDocument.value
      );
      assessmentInfo.listOfReelIdCategoryIdAndGroups =
        listOfReelIdCategoryIdAndGroups.map(reel => {
          const tempReel = reel;
          if (!tempReel.categoryId) {
            delete tempReel.categoryId;
          }
          tempReel.groups.map(group => {
            delete group.name;
            return group;
          });
          return tempReel;
        });
    }

    formData.append(
      'assessment',
      new Blob([JSON.stringify(assessmentInfo)], { type: 'application/json' })
    );

    createAssessment(
      '/assessment/add-assessment',
      CREATE_ASSESSMENT_REQUEST,
      CREATE_ASSESSMENT_SUCCESS,
      CREATE_ASSESSMENT_FAIL,
      formData,
      '',
      'POST',
      null,
      'isReelService'
    );
  };

  const renderSelectedVideoOrDocument = useMemo(
    () => property => {
      const selectedVideoOrDocumentArray =
        property === 'selectedVideos' ? selectedVideos : documentIds;

      return (
        <div className="admin__createassessment--tags-selected">
          {selectedVideoOrDocumentArray.length
            ? selectedVideoOrDocumentArray
                .filter(
                  selectedVideoOrDocument =>
                    selectedVideoOrDocument &&
                    typeof selectedVideoOrDocument === 'object'
                )
                .map(({ label, value }, index) => (
                  <p key={index}>
                    {label}
                    <span className="close-icon">
                      <a
                        className="btn-close"
                        aria-hidden="true"
                        onClick={() => {
                          const tempSelectedVideosOrDocuments = [
                            ...selectedVideoOrDocumentArray,
                          ];
                          handleAssessmentData(
                            tempSelectedVideosOrDocuments.filter(
                              selectedVideoOrDocument =>
                                selectedVideoOrDocument.value !== value
                            ),
                            property
                          );
                        }}
                      >
                        &times;
                      </a>
                    </span>
                  </p>
                ))
            : null}
        </div>
      );
    },
    [selectedVideos, documentIds]
  );

  const replicateAssessmentData = () => {
    setAssessmentData({ ...initailAssessmentData, questions });
    setIsSaved(false);
  };

  return (
    <div>
      <div
        className={`admin__createassessment loading-screen-parent ${
          isLoading ? 'setheight' : ''
        }`}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="admin__createassessment--wrapper">
              <div className="admin__createassessment--page-title">
                <p className="btn--back" onClick={() => history.goBack()}>
                  {t('Back to Assessments')}
                </p>
                <h2>
                  {assessmentId ? 'Edit' : 'Create'} {t('Assessment')}
                </h2>
              </div>
              <div className="admin__createassessment--container">
                <div className="admin__createassessment--left-col">
                  <div className="admin__createassessment--information">
                    <input
                      type="text"
                      id="document-titl"
                      name="document-titl"
                      placeholder={t('Assessment Title *')}
                      value={title}
                      onChange={e =>
                        handleAssessmentData(e.target.value, 'title')
                      }
                    />
                    <input
                      type="text"
                      id="document-description"
                      name="document-description"
                      placeholder={t('Description')}
                      value={description}
                      onChange={e =>
                        handleAssessmentData(e.target.value, 'description')
                      }
                    />
                    {placement === 'STANDALONE' && (
                      <>
                        <input
                          type="checkbox"
                          className="form-input--checkbox"
                          id="endvideo"
                          name="endvideo"
                          checked={isBanner}
                          onChange={() => {}}
                        />
                        <label
                          onClick={() =>
                            handleAssessmentData(!isBanner, 'isBanner')
                          }
                        >
                          {t('Add to Banner')}
                        </label>
                        <input
                          type="checkbox"
                          className="form-input--checkbox"
                          id="endvideo"
                          name="endvideo"
                          checked={isFeatured}
                          onChange={() => {}}
                        />
                        <label
                          onClick={() =>
                            handleAssessmentData(!isFeatured, 'isFeatured')
                          }
                        >
                          {t('Add to Featured')}
                        </label>
                      </>
                    )}
                  </div>

                  <div className="admin__createassessment--placement accordion">
                    <h3>{t('Placement')}</h3>
                    <div className="admin__createassessment--inner-container">
                      <p className="label">{t('Type')}</p>

                      <div className="section--right-column createassessment">
                        <input
                          type="radio"
                          className="form-input--checkbox"
                          id="midvideo"
                          name="midvideo"
                          checked={placement === 'MID'}
                          onChange={() => {}}
                        />
                        <label
                          onClick={e =>
                            handleAssessmentData('MID', 'placement')
                          }
                        >
                          {t('Mid Video')}
                        </label>
                        <input
                          type="radio"
                          className="form-input--checkbox"
                          id="endvideo"
                          name="endvideo"
                          checked={placement === 'END'}
                          onChange={() => {}}
                        />
                        <label
                          onClick={e =>
                            handleAssessmentData('END', 'placement')
                          }
                        >
                          {t('End Video')}
                        </label>
                        <input
                          type="radio"
                          className="form-input--checkbox"
                          id="standalone"
                          name="standalone"
                          checked={placement === 'STANDALONE'}
                          onChange={() => {}}
                        />
                        <label
                          onClick={e =>
                            handleAssessmentData('STANDALONE', 'placement')
                          }
                        >
                          {t('Stand Alone')}
                        </label>
                        {placement === 'MID' && (
                          <>
                            <div className="admin__createassessment--placement-time">
                              <p>{t('Start Time')}</p>
                              <input
                                type="number"
                                min="0"
                                id="starttime min"
                                name="starttime min"
                                placeholder="0"
                                value={startTimeMin}
                                onChange={e =>
                                  handleAssessmentData(
                                    e.target.value,
                                    'startTimeMin'
                                  )
                                }
                              />
                              <p>{t('min')}</p>
                              <input
                                type="number"
                                min="0"
                                id="starttime sec"
                                name="starttime sec"
                                placeholder="0"
                                value={startTimeSec}
                                onChange={e =>
                                  handleAssessmentData(
                                    e.target.value,
                                    'startTimeSec'
                                  )
                                }
                              />
                              <p>{t('sec')}</p>
                            </div>
                            <div className="admin__createassessment--placement-time">
                              <p>{t('Trigger Time')}</p>
                              <input
                                type="number"
                                min="0"
                                id="triggertime min"
                                name="triggertime min"
                                placeholder="0"
                                value={triggerTimeMin}
                                onChange={e =>
                                  handleAssessmentData(
                                    e.target.value,
                                    'triggerTimeMin'
                                  )
                                }
                              />
                              <p>{t('min')}</p>
                              <input
                                type="number"
                                min="0"
                                id="triggertime sec"
                                name="triggertime sec"
                                placeholder="0"
                                value={triggerTimeSec}
                                onChange={e =>
                                  handleAssessmentData(
                                    e.target.value,
                                    'triggerTimeSec'
                                  )
                                }
                              />
                              <p>{t('sec')}</p>
                            </div>
                          </>
                        )}
                        {placement !== 'MID' && (
                          <>
                            <p className="admin__createassessment--placement-label">
                              {t('Duration')}
                            </p>
                            <div className="admin__createassessment--placement-time duration">
                              <input
                                type="number"
                                min="0"
                                id="starttime min"
                                name="starttime min"
                                placeholder="00"
                                value={duration}
                                onChange={e =>
                                  handleAssessmentData(
                                    e.target.value,
                                    'duration'
                                  )
                                }
                              />
                              <p>{t('min')}</p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {reels.length && placement === 'STANDALONE' ? (
                    <AddReelCategoryGroup
                      reels={reels}
                      listOfReelIdCategoryIdAndGroups={
                        listOfReelIdCategoryIdAndGroups
                      }
                      reelCategoryGroupObj={reelCategoryGroupObj}
                      handleData={handleAssessmentData}
                    />
                  ) : null}

                  {placement !== 'STANDALONE' ? (
                    <div className="admin__createassessment--documents accordion">
                      <h3>
                        {t('Assigning to video')} <MandatoryMark />
                      </h3>
                      <div className="admin__createassessment--inner-container">
                        <p className="label">{t('Video')}</p>
                        <MultiSelect
                          title="Video"
                          options={
                            relatedVideos && relatedVideos.length
                              ? relatedVideos.map(video => ({
                                  label: video.title,
                                  value: video._id,
                                }))
                              : []
                          }
                          value={selectedVideos.filter(
                            selectedVideo =>
                              selectedVideo && typeof selectedVideo === 'object'
                          )}
                          onChange={option =>
                            handleAssessmentData(option, 'selectedVideos')
                          }
                          placeholder={t('Select video')}
                        />
                        {renderSelectedVideoOrDocument('selectedVideos')}
                      </div>
                    </div>
                  ) : null}

                  {placement === 'STANDALONE' ? (
                    <div className="admin__createassessment--documents accordion">
                      <h3>{t('Assigning Related Video')}</h3>
                      <div className="admin__createassessment--inner-container">
                        <p className="label">{t('Video')}</p>
                        <MultiSelect
                          title="Video"
                          options={
                            relatedVideos && relatedVideos.length
                              ? relatedVideos.map(video => ({
                                  label: video.title,
                                  value: video._id,
                                }))
                              : []
                          }
                          value={selectedVideos.filter(
                            selectedVideo =>
                              selectedVideo &&
                              typeof selectedVideos === 'object'
                          )}
                          onChange={option =>
                            handleAssessmentData(option, 'selectedVideos')
                          }
                          placeholder={t('Select Video')}
                        />{' '}
                        {renderSelectedVideoOrDocument('selectedVideos')}
                      </div>
                    </div>
                  ) : null}

                  {placement === 'STANDALONE' ? (
                    <div className="admin__createassessment--documents accordion">
                      <h3>{t('Assigning Related Document')}</h3>
                      <div className="admin__createassessment--inner-container">
                        <p className="label">{t('Document')}</p>
                        <MultiSelect
                          title="Document"
                          options={
                            relatedDocuments && relatedDocuments.length
                              ? relatedDocuments.map(document => ({
                                  label: document.title,
                                  value: document._id,
                                }))
                              : []
                          }
                          value={documentIds.filter(
                            selectedDocument =>
                              selectedDocument &&
                              typeof selectedDocument === 'object'
                          )}
                          onChange={option =>
                            handleAssessmentData(option, 'documentIds')
                          }
                          placeholder={t('Select documents')}
                        />
                        {renderSelectedVideoOrDocument('documentIds')}
                      </div>
                    </div>
                  ) : null}

                  {placement === 'STANDALONE' && (
                    <div className="admin__createassessment--tags">
                      <h3>{t('Tags')}</h3>
                      <div className="admin__createassessment--inner-container">
                        <input
                          type="text"
                          id="upload-tags"
                          name="upload-tags"
                          placeholder={t('Seperate your tags with a comma')}
                          value={tags}
                          onChange={e =>
                            handleAssessmentData(e.target.value, 'tags')
                          }
                        />
                        <div className="admin__createassessment--tags-selected">
                          {tags &&
                            tags.split(',').map(tag => (
                              <p>
                                {tag}
                                <span className="close-icon">
                                  <a
                                    className="btn-close"
                                    aria-hidden="true"
                                    onClick={() => {
                                      const tempTags = tags.split(',');
                                      handleAssessmentData(
                                        tempTags
                                          .filter(tempTag => tempTag !== tag)
                                          .join(),
                                        'tags'
                                      );
                                    }}
                                  >
                                    &times;
                                  </a>
                                </span>
                              </p>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="admin__createassessment--right-col">
                  {placement === 'STANDALONE' && (
                    <PreviewImageView
                      title={'Preview Picture'}
                      imageSrc={tileImageUrl || previewimg}
                      isImageViaUrl={isTileImageViaUrl}
                      imageUrl={tileImageUrl}
                      handleData={handleAssessmentData}
                      handleFileAccept={handleAssessmentData}
                      isImageViaUrlPropertyName={'isTileImageViaUrl'}
                      previewImgUrlPropertyName={'tileImageUrl'}
                      previewImagePropertyName={'tileImageFile'}
                      setIsValidUrl={setIsValidUrl}
                      isValidUrl={isValidUrl}
                    />
                  )}
                  <QuestionsView
                    assessmentId={assessmentId}
                    questions={questions}
                    handleQuestionData={handleQuestionData}
                    handleAssessmentData={handleAssessmentData}
                    questionObj={questionObj}
                  />
                </div>
                <div className="admin__createassessment--buttons">
                  <button
                    onClick={() => handleCreateAssessment(false)}
                    disabled={addAssessmentLoading}
                    className={`
                    btn btn--secondary
                    ${
                      addAssessmentLoading && isSaveProcess ? 'btn--loader' : ''
                    }
                  `}
                    onClick={() => {
                      setIsSaveProcess(true);
                      handleCreateAssessment(false);
                    }}
                  >
                    {assessmentId ? 'Update' : 'Save'}
                  </button>
                  <button
                    className="btn btn--secondary"
                    onClick={() => {
                      if (isSaved) {
                        replicateAssessmentData();
                      } else {
                        setIsReplicate(true);
                      }
                    }}
                  >
                    {t('Replicate')}
                  </button>
                  {!assessmentId && isHavePublishAccess && (
                    <button
                      className={`btn btn--tertiary ${
                        addAssessmentLoading && isPublishProcess
                          ? 'btn--loader'
                          : ''
                      }`}
                      onClick={() => {
                        setIsPublishProcess(true);
                        handleCreateAssessment(true);
                      }}
                      disabled={addAssessmentLoading}
                    >
                      {t('Publish')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isReplicate && (
        <SaveChangesModal
          handleClose={() => {
            replicateAssessmentData();
            setIsReplicate(false);
          }}
          handleSubmit={() => handleCreateAssessment(false)}
        />
      )}
      {isSubmitSuccessModalOpen && (
        <ProceedMessageModal
          isSuccess={isSubmitSuccessModalOpen.isSuccess}
          message={isSubmitSuccessModalOpen.message}
          handleClose={() => setIsSubmitSuccessModalOpen(null)}
          handleSuccess={() => {
            setIsSubmitSuccessModalOpen(null);
            history.goBack();
          }}
          handleError={() => setIsSubmitSuccessModalOpen(null)}
        />
      )}
      {isValidationError && (
        <ErrorMessageModal
          errorData={isValidationError}
          handleSubmit={() => setIsValidationError(null)}
        />
      )}
    </div>
  );
};

export default CreateAssessment;

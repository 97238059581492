import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import previewimg from '../../assets/images/sample-img.png';
import MandatoryMark from '../validation/MandatoryMark';
import PreviewImageView from './PreviewImageView';

const ansOrders = ['A', 'B', 'C', 'D', 'E'];

const QuestionsView = ({
  assessmentId,
  questions,
  handleQuestionData,
  handleAssessmentData,
  questionObj,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('Questions')}</p>
      {questions.map((question, index) => (
        <div
          className="admin__createassessment--question accordion"
          key={index}
        >
          <div className="admin__createassessment--question-title">
            <p>
              {t('Question')} {index + 1}
            </p>
          </div>
          <div className="admin__createassessment--question_inner-container">
            <input
              type="radio"
              className="form-input--checkbox"
              checked={question.isTextOnly}
              onChange={() => {}}
            />
            <label
              onClick={() => handleQuestionData(index, 'isTextOnly', true)}
            >
              {t('Text Only')}
            </label>
            <input
              type="radio"
              className="form-input--checkbox"
              checked={!question.isTextOnly}
              onChange={() => {}}
            />
            <label
              onClick={() => handleQuestionData(index, 'isTextOnly', false)}
            >
              {t('With Image')}
            </label>

            {!question.isTextOnly && (
              <PreviewImageView
                title={'Video Preview Image'}
                imageSrc={question.questionImageS3BucketURL || previewimg}
                isImageViaUrl={question.isQuestionImageViaUrl}
                imageUrl={question.questionImageS3BucketURL}
                handleData={(value, name) => {
                  handleQuestionData(index, name, value);
                }}
                isImageViaUrlPropertyName={'isQuestionImageViaUrl'}
                previewImgUrlPropertyName={'questionImageS3BucketURL'}
                previewImagePropertyName={'previewImage'}
                setIsValidUrl={() => console.log()}
                isValidUrl={true}
              />
            )}
            {!question.isTextOnly && (
              <>
                <p className="admin__createassessment--question-label">
                  {t('Choose Orientation')}
                </p>
                <div
                  className={`orientation-radiobutton ${
                    question.orientation === 1 ? 'selected' : ''
                  }`}
                >
                  <input
                    type="radio"
                    className="form-input--checkbox hidelabel"
                    checked={question.orientation === 1}
                    onChange={() => {}}
                  />
                  <label
                    onClick={() => handleQuestionData(index, 'orientation', 1)}
                  ></label>
                </div>
                <div
                  className={`orientation-radiobutton ${
                    question.orientation === 2 ? 'selected' : ''
                  }`}
                >
                  <input
                    type="radio"
                    className="form-input--checkbox hidelabel"
                    checked={question.orientation === 2}
                    onChange={() => {}}
                  />
                  <label
                    onClick={() => handleQuestionData(index, 'orientation', 2)}
                  ></label>
                </div>
                <div className="orientation-radiobutton">
                  <input
                    type="radio"
                    className="form-input--checkbox hidelabel"
                    checked={question.orientation === 3}
                    onChange={() => {}}
                  />
                  <label
                    onClick={() => handleQuestionData(index, 'orientation', 3)}
                  ></label>
                </div>
              </>
            )}
            <p className="admin__createassessment--question-label">
              {t('Question')} <MandatoryMark />
            </p>
            <input
              type="text"
              className="admin__createassessment--question-field form-input"
              id="question"
              name="question"
              value={question.quiz}
              onChange={e => handleQuestionData(index, 'quiz', e.target.value)}
            />
            <div className="admin__createassessment--question-answers">
              <p className="admin__createassessment--question-label">
                {t('Answers')} <MandatoryMark />
              </p>
              <p className="admin__createassessment--question-label">
                {t('Correct')}
              </p>
              {question.options.map((answer, ansIndex) => (
                <div
                  className="admin__createassessment--question-answer"
                  key={ansIndex}
                >
                  <p>{ansOrders[ansIndex]}</p>
                  <input
                    type="text"
                    className="form-input"
                    id="answer"
                    name="answer"
                    placeholder={t('Your Answer')}
                    value={answer}
                    onChange={e => {
                      const tempAnswers = [...question.options];
                      tempAnswers[ansIndex] = e.target.value;
                      handleQuestionData(index, 'options', tempAnswers);
                    }}
                  />
                  <a
                    className="btn-close closemodale"
                    aria-hidden="true"
                    onClick={() => {
                      const tempAnswers = [...question.options];
                      tempAnswers.splice(ansIndex, 1);
                      handleQuestionData(index, 'options', tempAnswers);
                    }}
                  >
                    &times;
                  </a>
                  <input
                    type="radio"
                    id={index}
                    className="form-input--checkbox hidelabel"
                    checked={ansIndex === parseInt(question.correctAnswer)}
                    onChange={() => {}}
                  />
                  <label
                    onClick={() =>
                      handleQuestionData(index, 'correctAnswer', ansIndex)
                    }
                  ></label>
                </div>
              ))}
              {question.options.length < 5 && (
                <p
                  className="addanother"
                  onClick={() =>
                    handleQuestionData(index, 'options', [
                      ...question.options,
                      '',
                    ])
                  }
                >
                  {t('Add another Answer')}
                </p>
              )}
            </div>
            <div className="delete-item">
              <span
                onClick={() => {
                  const tempQuestions = [...questions];
                  tempQuestions.splice(index, 1);
                  handleAssessmentData(tempQuestions, 'questions');
                }}
              >
                {t('Delete Question')}{' '}
              </span>
            </div>
          </div>
        </div>
      ))}
      <button
        className="btn btn--primary addquestion"
        onClick={() => {
          const tempQuestions = _.cloneDeep(questions);
          handleAssessmentData(
            [...tempQuestions, _.cloneDeep(questionObj)],
            'questions'
          );
        }}
      >
        {t('Add another Question')}
      </button>
    </>
  );
};

export default QuestionsView;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useApi from '../../../../Hooks/useApi';
import {
  CREATE_GROUP_INITIAL,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  FETCH_GROUP_DETAILS_SUCCESS,
  DELETE_GROUP_FAIL,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
} from '../../../../redux/actionTypes/Group';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import BreadCrumbView from '../../../../Components/BreadCrumbView';
import Loader from '../../../../Components/Loader/loader';
import DeleteGroupModal from '../../../../Components/Modal/DeleteGroupModal';
import { getChildGroups } from '../../../../Helper/SystemManager';

export default function GroupDetails({ history, groupId, groupDetailsData }) {
  const { t } = useTranslation();
  const canEditGroup = usePrivilegesApi(
    PrivilegeTypes.GROUP.EDIT_USER_GROUP,
    groupId
  );
  const canAllowSelfSignup = usePrivilegesApi(
    PrivilegeTypes.GROUP.ALLOW_SELF_SIGNUP,
    groupId
  );
  const isCreateGroup =
    history && history.location && history.location.pathname
      ? history.location.pathname.search('create-group') > -1
      : null;

  const [groupName, setGroupName] = useState('');
  const [parentGroupName, setParentGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [isAllowForSignup, setIsAllowForSignup] = useState(
    groupDetailsData?.isAllowForSignup !== undefined
  );
  const [isEditMode, setIsEditMode] = useState(isCreateGroup);
  const [deleteGroupConfirm, setDeleteGroupConfirm] = useState(false);

  const [CreateGroup] = useApi(
    isCreateGroup ? `/group-dashboard/${groupId}` : null
  );
  const [deleteGroupApi] = useApi();

  const {
    createGroupSuccessData,
    createGroupLoading,
    deleteGroupSuccess,
    deleteGroupLoading,
    deleteGroupError,
    allGroupsFetchData,
  } = useSelector(state => state?.group);
  const dispatch = useDispatch();

  useEffect(() => {
    if (createGroupSuccessData) {
      history.push(
        `/group-dashboard/${createGroupSuccessData._id}/group-details`
      );

      dispatch({ type: CREATE_GROUP_INITIAL });
      dispatch({
        type: FETCH_GROUP_DETAILS_SUCCESS,
        payload: {
          ...groupDetailsData,
          groupName: createGroupSuccessData.title,
          groupDiscription: createGroupSuccessData.divisionDescription,
          isAllowForSignup: createGroupSuccessData.isAllowForSignup,
        },
      });

      setIsEditMode(false);
    }
  }, [createGroupSuccessData]);

  useEffect(() => {
    if (groupDetailsData) {
      const { groupDiscription, groupName, parentGroupName, isAllowForSignup } =
        groupDetailsData;
      if (!isCreateGroup) {
        setGroupName(groupName);
        setDescription(groupDiscription);
        setParentGroupName(parentGroupName);
        setIsAllowForSignup(isAllowForSignup);
      } else {
        setParentGroupName(groupName);
      }
    }
  }, [groupDetailsData, history]);

  const handleGroupCreation = () => {
    const division = {
      divisionDescription: description,
      title: groupName,
      isAllowForSignup: isAllowForSignup || false,
    };
    if (!isCreateGroup && groupDetailsData && groupDetailsData.groupName) {
      division._id = groupId;
    }

    const DivisionWrapper = { division };
    if (isCreateGroup) {
      DivisionWrapper.parentDivisionId = groupId;
    }

    if (groupName !== '') {
      CreateGroup(
        '/division',
        CREATE_GROUP_REQUEST,
        CREATE_GROUP_SUCCESS,
        CREATE_GROUP_FAIL,
        {
          ...DivisionWrapper,
        },
        '',
        'POST',
        null,
        'isGroupService'
      );
    }
  };

  const deleteGroup = () => {
    deleteGroupApi(
      `/division/deleteGroup?divisionId=${groupId}&divisionName=${groupName}`,
      DELETE_GROUP_REQUEST,
      DELETE_GROUP_SUCCESS,
      DELETE_GROUP_FAIL,
      '',
      '',
      'DELETE',
      null,
      'isGroupService'
    );
  };

  return (
    <div className="group-users-table--wrapper-column loading-screen-parent">
      {!groupDetailsData ? (
        <Loader />
      ) : (
        <>
          <div className="group-users-table--page-title">
            <div className="group-users-table--page-selection">
              <BreadCrumbView
                history={history}
                routeOptions={[
                  {
                    title: parentGroupName ? parentGroupName : '',
                    path: `/node-tree`,
                  },
                  {
                    title: groupName ? groupName : '',
                    path: `/home/${groupId}/`,
                  },
                ]}
              />
              <div className="group-users-table--page-button-title">
                <h2>
                  {t(
                    `${
                      groupDetailsData && !isCreateGroup ? 'Update' : 'Create'
                    } Group`
                  )}
                </h2>
                <div>
                  {groupDetailsData &&
                    !isCreateGroup &&
                    parentGroupName &&
                    !isEditMode && (
                      <button
                        className="btn btn--secondary"
                        onClick={() => setIsEditMode(true)}
                      >
                        {t('Edit Group')}
                      </button>
                    )}
                  {groupDetailsData && !isCreateGroup && parentGroupName && (
                    <button
                      className="btn btn--primary"
                      onClick={() => setDeleteGroupConfirm(true)}
                    >
                      {t('Delete Group!')}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="group-users-table--creategroup">
            <div className="group-users-table--row">
              <input
                type="text"
                placeholder={t('Group Name')}
                value={groupName}
                disabled={!(canEditGroup && isEditMode)}
                onChange={e => setGroupName(e.target.value)}
              />
            </div>
            <div className="group-users-table--row">
              <input
                type="text"
                placeholder={t('Description')}
                value={description}
                disabled={!(canEditGroup && isEditMode)}
                onChange={e => setDescription(e.target.value)}
              />
            </div>
            <div className="group-users-table--row">
              <input
                type="checkbox"
                className="form-input--checkbox"
                checked={isAllowForSignup}
                disabled={!(canAllowSelfSignup && isEditMode)}
                onChange={() =>
                  setIsAllowForSignup(isAllowForSignup => !isAllowForSignup)
                }
              />
              <label
                onClick={() =>
                  canAllowSelfSignup &&
                  isEditMode &&
                  setIsAllowForSignup(isAllowForSignup => !isAllowForSignup)
                }
              >
                {t('Allow for User Sign Up')}
                {!canAllowSelfSignup ? (
                  <span className="error-message">
                    {t('Please contact your super admin to change this')}
                  </span>
                ) : null}
              </label>
            </div>
            {canEditGroup && isEditMode && (
              <>
                {!createGroupLoading ? (
                  <div className="group-users-table--row">
                    <button
                      className="btn btn--secondary"
                      onClick={() => {
                        setGroupName('');
                        setDescription('');
                        setIsAllowForSignup(isAllowForSignup);
                      }}
                      disabled={createGroupLoading}
                    >
                      {t('Reset')}
                    </button>
                    <button
                      onClick={handleGroupCreation}
                      disabled={createGroupLoading || groupName === ''}
                      className="btn btn--primary"
                    >
                      {t(
                        groupDetailsData && !isCreateGroup ? 'Update' : 'Create'
                      )}
                    </button>
                    {!isCreateGroup && (
                      <button
                        onClick={() => {
                          setIsEditMode(false);
                          setIsAllowForSignup(isAllowForSignup);
                        }}
                        className="btn btn--secondary"
                      >
                        {t('Cancel')}
                      </button>
                    )}
                  </div>
                ) : (
                  <button className="btn btn--primary btn--loader">
                    {t('Loading')}
                  </button>
                )}
              </>
            )}
          </div>
        </>
      )}
      {deleteGroupConfirm && (
        <DeleteGroupModal
          onConfirm={() => deleteGroup()}
          handleSuccess={() => {
            setDeleteGroupConfirm(null);
            dispatch({
              type: DELETE_GROUP_SUCCESS,
              payload: null,
            });
            history.push('/node-tree');
          }}
          handleClose={() => {
            setDeleteGroupConfirm(null);
            dispatch({
              type: DELETE_GROUP_SUCCESS,
              payload: null,
            });
          }}
          isLoading={deleteGroupLoading}
          isHaveChildGroup={
            getChildGroups(allGroupsFetchData, groupId)?.children?.length
          }
          isSuccess={deleteGroupSuccess}
          isError={deleteGroupError}
          groupName={groupName}
        />
      )}
    </div>
  );
}

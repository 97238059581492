import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Analytics, AWSKinesisFirehoseProvider } from 'aws-amplify';
import moment from 'moment';

Analytics.addPluggable(new AWSKinesisFirehoseProvider());

const useAnalytics = () => {
  const [analyticsData, setAanalyticsData] = useState(null);

  const { isAdminView, geoLocation } = useSelector(state => state?.auth);
  const { userName, preferredUsername, userId, userDevice } = useSelector(
    state => state?.auth?.userData
  );

  const sendAnalyticsData = analyticsData => {
    if (
      process.env.REACT_APP_AWS_FIREHOUSE_NAME &&
      process.env.REACT_APP_AWS_FIREHOUSE_REGION &&
      analyticsData?.type
    ) {
      setAanalyticsData({
        metricType: analyticsData?.type,
        metricData: {
          ...analyticsData,
        },
        userData: {
          userId,
          username: userName,
          preferredUsername,
          userMode: isAdminView ? 'ADMIN' : 'USER',
        },
        timestamp: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss:SSZ'),
        userDevice,
        geoLocation,
      });
    }
  };

  const sendData = () => {
    Analytics.record(
      {
        data: analyticsData,
        streamName: process.env.REACT_APP_AWS_FIREHOUSE_NAME,
      },
      'AWSKinesisFirehose'
    )
      .then(() => setAanalyticsData(null))
      .catch(() => setAanalyticsData(null));
  };

  useEffect(() => {
    if (analyticsData) {
      sendData();
    }
  }, [analyticsData]);

  return sendAnalyticsData;
};

export default useAnalytics;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import sort_decending from '../../assets/images/svg-images/icon-decending.svg';

import Loader from '../../Components/Loader/loader';
import useFetchDataApi from '../../Hooks/useFetchDataApi';
import ViewPDFModal from '../AdminDashboard/Group/Modal/viewPDFModal';

const DocumentPage = ({ match }) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [fetchData] = useFetchDataApi();
  const {
    documentReelsForLandingPageData,
    documentReelsForLandingPageLoading,
  } = useSelector(state => state?.homeData);

  const { groupId, reelId, categoryId } = match.params;

  const findReelName =
    documentReelsForLandingPageData && documentReelsForLandingPageData.length
      ? documentReelsForLandingPageData[0].name
      : '';

  const getHomeDocumentReelData = () => {
    fetchData({
      reelId,
      groupId,
      type: 'DOCUMENT_REEL_BULK_VIEW',
    });
  };

  useEffect(() => {
    getHomeDocumentReelData();
  }, [groupId]);

  useEffect(() => {
    if (
      categoryId &&
      documentReelsForLandingPageData &&
      documentReelsForLandingPageData.length &&
      documentReelsForLandingPageData[0].category.length
    ) {
      const element = document.getElementById(categoryId);
      if (element) {
        const offset = 65;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [documentReelsForLandingPageData]);

  const renderView = () => {
    return documentReelsForLandingPageData[0].category
      .filter(({ documents }) => documents && documents.length)
      .map(({ _id, name, documents }, key) => (
        <div className="tilegrid__row" key={key}>
          <div className="tilegrid__row--top">
            <h3 className="tilegrid__subtitle" id={_id}>
              {name}
            </h3>
            <img
              src={sort_decending}
              alt="sort_decending-icon"
              className="icon--sort_decending"
            />
          </div>
          <div className="tilegrid__row--bottom">
            {documents && documents.length
              ? documents.map((document, index) => (
                  <div className="tilegrid__content" key={index}>
                    <div
                      className="tilegrid__content--image"
                      onClick={() => {
                        setPdfFile({
                          url: document.documentUrl,
                          title: document.title,
                          allowDownload: document.download
                        });
                      }}
                    ></div>
                    <div className="tilegrid__content--title">
                      <p>{document.title}</p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      ));
  };

  return documentReelsForLandingPageLoading ? (
    <Loader />
  ) : (
    <div className="tilegrid policies">
      <div className="container">
        <div className="tilegrid__wrapper">
          <h2 className="tilegrid__title">{findReelName}</h2>
          {documentReelsForLandingPageData &&
          documentReelsForLandingPageData.length &&
          documentReelsForLandingPageData[0].category
            ? renderView()
            : null}
        </div>
      </div>
      {pdfFile && (
        <ViewPDFModal
          pdfTitle={pdfFile.title}
          pdfUrl={pdfFile.url}
          allowDownload={pdfFile.allowDownload}
          handleClose={() => setPdfFile(null)}
        />
      )}
    </div>
  );
};

export default DocumentPage;

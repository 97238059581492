import React from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';

import { AdminDashboardSelections } from '../../Common/AdminDashboard';

export default function AdminDashboardSelectionDropDown({ history, selected }) {
  const { t } = useTranslation();
  return (
    <Dropdown
      title="AdminDashBoardSelect"
      value={selected}
      options={AdminDashboardSelections.map(({ label, value }) => ({
        label: t(label),
        value,
      }))}
      onChange={({ value }) => {
        if (value === 'GROUPS') {
          history.push('/node-tree');
        } else if (value === 'USERS') {
          history.push('/search-users');
        } else if (value === 'REELS') {
          history.push('/search-reels/video');
        } else if (value === 'VIDEOS') {
          history.push('/search-videos');
        } else if (value === 'ASSESSMENTS') {
          history.push('/search-assessments/mid-video');
        } else if (value === 'DOCUMENTS') {
          history.push('/search-documents');
        }
      }}
    />
  );
}

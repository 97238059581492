import { getTotalPageCount } from '../../Helper/SystemManager';
import { ADD_NOTIFICATION, UPDATE_NOTIFICATION } from '../action/actionTypes';
import {
  FETCH_NOTIFICATION_SETTINGS_REQUEST,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS_FAIL,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_FAIL,
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION_STATUS_REQUEST,
  UPDATE_NOTIFICATION_STATUS_SUCCESS,
  UPDATE_NOTIFICATION_STATUS_FAIL,
  FETCH_ALL_NOTIFICATION_REQUEST,
  FETCH_ALL_NOTIFICATION_SUCCESS,
  FETCH_ALL_NOTIFICATION_FAIL,
  UPDATE_ALL_NOTICATIONS_READ_REQUEST,
  UPDATE_ALL_NOTICATIONS_READ_SUCCESS,
  UPDATE_ALL_NOTICATIONS_READ_FAIL,
  NOTIFICATION_ICON_COUNT_REQUEST,
  NOTIFICATION_ICON_COUNT_SUCCESS,
  NOTIFICATION_ICON_COUNT_FAIL,
  REMOVE_NOTIFICATION_REQUEST,
  REMOVE_NOTIFICATION_SUCCESS,
  REMOVE_NOTIFICATION_FAIL,
  FETCH_EMAIL_NOTICATION_STATUS_REQUEST,
  FETCH_EMAIL_NOTICATION_STATUS_SUCCESS,
  FETCH_EMAIL_NOTICATION_STATUS_FAIL,
  UPDATE_EMAIL_NOTICATION_STATUS_REQUEST,
  UPDATE_EMAIL_NOTICATION_STATUS_SUCCESS,
  UPDATE_EMAIL_NOTICATION_STATUS_FAIL,
  UPDATE_EMAIL_NOTICATION_STATUS_RESET,
} from '../actionTypes/notification';

const notificationReducer = (
  state = {
    notifications: [],
    notificationSettingsLoading: false,
    notificationSettings: null,
    notificationSettingsError: null,
    updateNotificationSettingsLoading: false,
    updateNotificationSettingsSuccess: null,
    updateNotificationSettingsError: null,
    headerNotificationsLoading: false,
    headerNotifications: [],
    headerNotificationError: null,
    updateNotificationStatusLoading: false,
    updateNotificationStatusSuccess: null,
    updateNotificationStatusError: null,
    allNotificationsLoading: false,
    allNotificationsData: [],
    allNotificationsTotalCount: 0,
    allNotificationPageCount: 0,
    allNotificationsError: null,
    updateAllNotificatioReadLoading: false,
    updateAllNotificatioReadSuccess: false,
    updateAllNotificatioReadFail: false,
    notificationIconCount: 0,
    deleteNotificationSuccess: false,
    emailNotificationStatus: [],
    emailNotificationStatusloading: false,
    updateEmailNotificationStatus: null,
  },
  action
) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };

    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload,
      };

    case FETCH_NOTIFICATION_SETTINGS_REQUEST:
      return {
        ...state,
        notificationSettingsLoading: true,
        notificationSettings: null,
        notificationSettingsError: null,
      };

    case FETCH_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        notificationSettingsLoading: false,
        notificationSettings: action.payload,
        notificationSettingsError: null,
      };

    case FETCH_NOTIFICATION_SETTINGS_FAIL:
      return {
        ...state,
        notificationSettingsLoading: false,
        notificationSettings: null,
        notificationSettingsError: action.payload,
      };

    case UPDATE_NOTIFICATION_SETTINGS_REQUEST:
      return {
        ...state,
        updateNotificationSettingsLoading: true,
        updateNotificationSettingsSuccess: null,
        updateNotificationSettingsError: null,
      };

    case UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        updateNotificationSettingsLoading: false,
        updateNotificationSettingsSuccess: action.payload,
        updateNotificationSettingsError: null,
      };

    case UPDATE_NOTIFICATION_SETTINGS_FAIL:
      return {
        ...state,
        updateNotificationSettingsLoading: false,
        updateNotificationSettingsSuccess: null,
        updateNotificationSettingsError: action.payload,
      };

    case FETCH_NOTIFICATION_REQUEST:
      return {
        ...state,
        headerNotificationsLoading: true,
        headerNotifications: state?.headerNotifications,
        headerNotificationError: null,
      };

    case FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        headerNotificationsLoading: false,
        headerNotifications: action.payload?.data || [],
        headerNotificationError: null,
      };

    case FETCH_NOTIFICATION_FAIL:
      return {
        ...state,
        headerNotificationsLoading: false,
        headerNotifications: [],
        headerNotificationError: action.payload,
      };

    case UPDATE_NOTIFICATION_STATUS_REQUEST:
      return {
        ...state,
        updateNotificationStatusLoading: true,
        updateNotificationStatusSuccess: null,
        updateNotificationStatusError: null,
      };

    case UPDATE_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        updateNotificationStatusLoading: false,
        updateNotificationStatusSuccess: action.payload,
        updateNotificationStatusError: null,
        notificationIconCount:
          state?.notificationIconCount > 0
            ? state?.notificationIconCount - 1
            : 0,
      };

    case UPDATE_NOTIFICATION_STATUS_FAIL:
      return {
        ...state,
        updateNotificationStatusLoading: false,
        updateNotificationStatusSuccess: null,
        updateNotificationStatusError: action.payload,
      };

    case FETCH_ALL_NOTIFICATION_REQUEST:
      return {
        ...state,
        allNotificationsLoading: true,
        allNotificationsData: state?.allNotificationsData,
        allNotificationsTotalCount: state?.allNotificationsTotalCount,
        allNotificationPageCount: state?.allNotificationPageCount,
        allNotificationsError: null,
      };

    case FETCH_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        allNotificationsLoading: false,
        allNotificationsData: action.payload?.data || [],
        allNotificationsTotalCount: action.payload?.totalRecords || 0,
        allNotificationPageCount: getTotalPageCount(
          action.payload?.totalRecords,
          action.payload?.size
        ),
        allNotificationsError: null,
      };

    case FETCH_ALL_NOTIFICATION_FAIL:
      return {
        ...state,
        allNotificationsLoading: false,
        allNotificationsData: [],
        allNotificationsTotalCount: 0,
        allNotificationPageCount: 0,
        allNotificationsError: action.payload,
      };

    case UPDATE_ALL_NOTICATIONS_READ_REQUEST:
      return {
        ...state,
        updateAllNotificatioReadLoading: true,
        updateAllNotificatioReadSuccess: false,
        updateAllNotificatioReadFail: false,
      };

    case UPDATE_ALL_NOTICATIONS_READ_SUCCESS:
      return {
        ...state,
        updateAllNotificatioReadLoading: false,
        updateAllNotificatioReadSuccess: true,
        updateAllNotificatioReadFail: false,
        notificationIconCount: 0,
      };

    case UPDATE_ALL_NOTICATIONS_READ_FAIL:
      return {
        ...state,
        updateAllNotificatioReadLoading: false,
        updateAllNotificatioReadSuccess: false,
        updateAllNotificatioReadFail: true,
      };

    case NOTIFICATION_ICON_COUNT_REQUEST:
      return {
        ...state,
        notificationIconCount: state?.notificationIconCount,
      };

    case NOTIFICATION_ICON_COUNT_SUCCESS:
      return {
        ...state,
        notificationIconCount: action.payload?.totalRecords || 0,
      };

    case NOTIFICATION_ICON_COUNT_FAIL:
      return {
        ...state,
        notificationIconCount: state?.notificationIconCount,
      };

    case REMOVE_NOTIFICATION_REQUEST:
      return {
        ...state,
        deleteNotificationSuccess: false,
      };

    case REMOVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        deleteNotificationSuccess: action?.payload || true,
      };

    case REMOVE_NOTIFICATION_FAIL:
      return {
        ...state,
        deleteNotificationSuccess: false,
      };

    case FETCH_EMAIL_NOTICATION_STATUS_REQUEST:
      return {
        ...state,
        emailNotificationStatusloading: true,
        emailNotificationStatus: [],
      };

    case FETCH_EMAIL_NOTICATION_STATUS_SUCCESS:
      return {
        ...state,
        emailNotificationStatusloading: false,
        emailNotificationStatus: action?.payload?.data || [],
      };

    case FETCH_EMAIL_NOTICATION_STATUS_FAIL:
      return {
        ...state,
        emailNotificationStatusloading: false,
        emailNotificationStatus: [],
      };

    case UPDATE_EMAIL_NOTICATION_STATUS_REQUEST:
      return {
        ...state,
        updateEmailNotificationStatus: null,
      };

    case UPDATE_EMAIL_NOTICATION_STATUS_SUCCESS:
      return {
        ...state,
        updateEmailNotificationStatus: {
          isSuccess: true,
          message: 'Email Notifications Changed',
        },
      };

    case UPDATE_EMAIL_NOTICATION_STATUS_RESET:
      return {
        ...state,
        updateEmailNotificationStatus: null,
      };

    case UPDATE_EMAIL_NOTICATION_STATUS_FAIL:
      return {
        ...state,
        updateEmailNotificationStatus: {
          isSuccess: false,
          message: 'Email Notifications Changing Failed',
        },
      };

    default:
      return state;
  }
};

export default notificationReducer;

export const systemConfig = {
  roleIdByName: {
    admin: {
      roleReferenceId: 1,
      roleName: 'Admin',
    },
    editor: {
      roleReferenceId: 2,
      roleName: 'Editor',
    },
    user: {
      roleReferenceId: 3,
      roleName: 'User',
    },
    superAdmin: {
      roleReferenceId: 4,
      roleName: 'SuperAdmin',
    },
    moderator: {
      roleReferenceId: 5,
      roleName: 'Moderator',
    },
  },
  emailRegex: /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,8})+$/,
  errorcode: {
    NotAuthorizedException: 'NotAuthorizedException',
  },
  playerNoDownload: {
    file: {
      attributes: { controlsList: 'nodownload' },
    },
  },
  documentFileTypes: '.xlsx, .xls, .doc, .docx,.ppt, .pptx, .txt, .pdf',
  timeInterval: {
    updateLastActivity: 1000 * 60 * 30,
    getNewNotification: 1000 * 60,
    updateUserData: 1000 * 60,
  },
  notificationViewType: {
    user: 'USER_NOTIFICATION',
    admin: 'ADMIN_NOTIFICATION',
    rms: 'RMS_NOTIFICATION',
  },
  notificationFilters: {
    all: {
      label: 'All',
      value: '-1',
    },
    user: [
      {
        label: 'Mandatory Reel',
        value: 'MANDATORY_REEL',
      },
      {
        label: 'Group',
        value: 'GROUP',
      },
      {
        label: 'Content',
        value: 'CONTENT',
      },
    ],
    admin: [
      {
        label: 'Admin',
        value: 'ADMIN',
      },
    ],
  },
};

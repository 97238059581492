export const FETCH_NOTIFICATION_SETTINGS_REQUEST =
  'FETCH_NOTIFICATION_SETTINGS_REQUEST';
export const FETCH_NOTIFICATION_SETTINGS_SUCCESS =
  'FETCH_NOTIFICATION_SETTINGS_SUCCESS';
export const FETCH_NOTIFICATION_SETTINGS_FAIL =
  'FETCH_NOTIFICATION_SETTINGS_FAIL';

export const UPDATE_NOTIFICATION_SETTINGS_REQUEST =
  'UPDATE_NOTIFICATION_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS =
  'UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_NOTIFICATION_SETTINGS_FAIL =
  'UPDATE_NOTIFICATION_SETTINGS_FAIL';

export const FETCH_NOTIFICATION_REQUEST = 'FETCH_NOTIFICATION_REQUEST';
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAIL = 'FETCH_NOTIFICATION_FAIL';

export const UPDATE_NOTIFICATION_STATUS_REQUEST =
  'UPDATE_NOTIFICATION_STATUS_REQUEST';
export const UPDATE_NOTIFICATION_STATUS_SUCCESS =
  'UPDATE_NOTIFICATION_STATUS_SUCCESS';
export const UPDATE_NOTIFICATION_STATUS_FAIL =
  'UPDATE_NOTIFICATION_STATUS_FAIL';

export const FETCH_ALL_NOTIFICATION_REQUEST = 'FETCH_ALL_NOTIFICATION_REQUEST';
export const FETCH_ALL_NOTIFICATION_SUCCESS = 'FETCH_ALL_NOTIFICATION_SUCCESS';
export const FETCH_ALL_NOTIFICATION_FAIL = 'FETCH_ALL_NOTIFICATION_FAIL';

export const UPDATE_ALL_NOTICATIONS_READ_REQUEST =
  'UPDATE_ALL_NOTICATIONS_READ_REQUEST';
export const UPDATE_ALL_NOTICATIONS_READ_SUCCESS =
  'UPDATE_ALL_NOTICATIONS_READ_SUCCESS';
export const UPDATE_ALL_NOTICATIONS_READ_FAIL =
  'UPDATE_ALL_NOTICATIONS_READ_FAIL';

export const NOTIFICATION_ICON_COUNT_REQUEST =
  'NOTIFICATION_ICON_COUNT_REQUEST';
export const NOTIFICATION_ICON_COUNT_SUCCESS =
  'NOTIFICATION_ICON_COUNT_SUCCESS';
export const NOTIFICATION_ICON_COUNT_FAIL = 'NOTIFICATION_ICON_COUNT_FAIL';

export const REMOVE_NOTIFICATION_REQUEST = 'REMOVE_NOTIFICATION_REQUEST';
export const REMOVE_NOTIFICATION_SUCCESS = 'REMOVE_NOTIFICATION_SUCCESS';
export const REMOVE_NOTIFICATION_FAIL = 'REMOVE_NOTIFICATION_FAIL';

export const UPDATE_EMAIL_NOTICATION_STATUS_REQUEST =
  'UPDATE_EMAIL_NOTICATION_STATUS_REQUEST';
export const UPDATE_EMAIL_NOTICATION_STATUS_SUCCESS =
  'UPDATE_EMAIL_NOTICATION_STATUS_SUCCESS';
export const UPDATE_EMAIL_NOTICATION_STATUS_FAIL =
  'UPDATE_EMAIL_NOTICATION_STATUS_FAIL';

export const FETCH_EMAIL_NOTICATION_STATUS_REQUEST =
  'FETCH_EMAIL_NOTICATION_STATUS_REQUEST';
export const FETCH_EMAIL_NOTICATION_STATUS_SUCCESS =
  'FETCH_EMAIL_NOTICATION_STATUS_SUCCESS';
export const FETCH_EMAIL_NOTICATION_STATUS_FAIL =
  'FETCH_EMAIL_NOTICATION_STATUS_FAIL';
export const UPDATE_EMAIL_NOTICATION_STATUS_RESET =
  'UPDATE_EMAIL_NOTICATION_STATUS_RESET';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useChangePublishedStatusApi from '../../../../Hooks/useChangePublishedStatusApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import useDeleteApi from '../../../../Hooks/useDeleteApi';

import deleteIcon_white from '../../../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../../../assets/images/svg-images/icon-edit-white.svg';
import stats from '../../../../assets/images/svg-images/icon-stats.svg';
import warning from '../../../../assets/images/svg-images/icon-warning.svg';
import publish from '../../../../assets/images/svg-images/icon-upload.svg';

import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import BreadCrumbView from '../../../../Components/BreadCrumbView';
import Loader from '../../../../Components/Loader/loader';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import { getBannerFeaturedEditRoute } from '../../../../Helper/SystemManager';
import { ToolTipConfig } from '../../../../Common/ToolTipConfig.js';
import ActionDropdown from '../../../../Components/Admin/ActionDropdown';
import { SET_STATS_CURRENT_ASSESSMENT } from '../../../../redux/actionTypes/assesment';

export default function GroupAssessments({
  history,
  groupId,
  groupDetailsData,
}) {
  const { t } = useTranslation();
  const canSuspendPublishAssessment = usePrivilegesApi(
    PrivilegeTypes.ASSESSMENT.SUSPEND_PUBLISH_ASSESSMENT,
    groupId
  );
  const canEditAssessment = usePrivilegesApi(
    PrivilegeTypes.ASSESSMENT.EDIT_ASSESSMENT,
    groupId
  );
  const canDeleteAssessement = usePrivilegesApi(
    PrivilegeTypes.ASSESSMENT.DELETE_ASSESSMENT,
    groupId
  );

  const { groupName = null, parentGroupName = null } = groupDetailsData || {};
  const [isGridView, setIsGridView] = useState(true);
  const [assessmentType, setAssessmentType] = useState('MID_VIDEO');
  const [searchAssessmentName, setSearchAssessmentName] = useState('');
  const [organizedAssessments, setOrganizedAssessments] = useState([]);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(
    null
  );
  const {
    suspendAssessmentLoading,
    midEndAssementsByGroupFetchData,
    standaloneAssementsByGroupFetchData,
    deleteStandaloneAssessmentSuccess,
    deleteMidEndAssessmentSuccess,
    midEndAssementsByGroupFetchDataLoading,
    standaloneAssementsByGroupFetchDataLoading,
  } = useSelector(state => state?.assessment);
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;

  const [updateIsPublishedStatus] = useChangePublishedStatusApi();
  const [fetchData] = useFetchDataApi();
  const [deleteAssessment] = useDeleteApi();
  const dispatch = useDispatch();

  const getAllAssessments = placementType =>
    fetchData({ groupId, type: placementType });

  useEffect(() => {
    if (standaloneAssementsByGroupFetchData) {
      const organizedAssessments = standaloneAssementsByGroupFetchData.map(
        assessment => {
          const { title, tileImageUrl } = assessment;

          return {
            ...assessment,
            type: assessmentType,
            name: title,
            imageUrl: tileImageUrl || defaultAssessment,
          };
        }
      );

      setOrganizedAssessments(organizedAssessments);
    }
  }, [standaloneAssementsByGroupFetchData]);

  useEffect(() => {
    if (midEndAssementsByGroupFetchData) {
      const organizedAssessments = midEndAssementsByGroupFetchData.map(
        assessment => {
          const { title, previewImgUrl } = assessment;

          return {
            ...assessment,
            type: assessmentType,
            name: title,
            imageUrl: previewImgUrl || defaultAssessment,
          };
        }
      );

      setOrganizedAssessments(organizedAssessments);
    }
  }, [midEndAssementsByGroupFetchData]);

  useEffect(() => {
    const currentUrl = window.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');
    switch (assessmentType) {
      case 'MID_VIDEO':
        history.push(`${currentUrl}/mid-video`);
        break;

      case 'END_VIDEO':
        history.push(`${currentUrl}/end-video`);
        break;

      case 'STANDALONE':
        history.push(`${currentUrl}/standalone`);
        break;

      default:
        break;
    }
  }, [assessmentType]);

  useEffect(() => {
    if (deleteStandaloneAssessmentSuccess) {
      setIsDeleteConfirmModalOpen(null);
      getAllAssessments(assessmentType);
    }
  }, [deleteStandaloneAssessmentSuccess]);

  useEffect(() => {
    if (deleteMidEndAssessmentSuccess) {
      setIsDeleteConfirmModalOpen(null);
      getAllAssessments(assessmentType.split('_')[0]);
    }
  }, [deleteMidEndAssessmentSuccess]);

  const setActiveTab = assessmentType => {
    const currentUrl = window.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');

    switch (assessmentType) {
      case 'MID_VIDEO':
        history.push(`${currentUrl}/mid-video`);
        setOrganizedAssessments([]);
        getAllAssessments('MID');
        break;

      case 'END_VIDEO':
        history.push(`${currentUrl}/end-video`);
        setOrganizedAssessments([]);
        getAllAssessments('END');
        break;

      case 'STANDALONE':
        history.push(`${currentUrl}/standalone`);
        setOrganizedAssessments([]);
        getAllAssessments('STANDALONE');
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setActiveTab(assessmentType);
  }, [assessmentType]);

  const hanldeIsPublishedStatus = assessmentId => {
    updateIsPublishedStatus({
      _id: assessmentId,
      type: 'ASSESSMENT',
    });
  };

  const setAssessmentListTableActions = (value, assessment) => {
    switch (value) {
      case 'Edit':
        history.push(
          `/edit-assessment/${getBannerFeaturedEditRoute(assessment)}${
            assessment._id
          }`
        );
        break;

      case 'Suspend':
        hanldeIsPublishedStatus(assessment._id);
        break;

      case 'Publish':
        hanldeIsPublishedStatus(assessment._id);
        break;

      case 'Delete':
        handleDelete(assessment._id, assessment.videoId);
        break;

      default:
        break;
    }
  };

  const handleDelete = (id, videoId) => {
    let deleteRequestData = {};

    switch (assessmentType) {
      case 'STANDALONE':
        deleteRequestData = {
          ...deleteRequestData,
          groupId,
          vid_doc_standalone_Id: id,
          type: 'STANDALONE_ASSESSMENT',
        };
        break;

      case 'MID_VIDEO':
        deleteRequestData = {
          ...deleteRequestData,
          videoId,
          assessmentId: id,
          placement: 'MID',
          type: 'MID_END_ASSESSMENT',
        };
        break;

      case 'END_VIDEO':
        deleteRequestData = {
          ...deleteRequestData,
          videoId,
          assessmentId: id,
          placement: 'END',
          type: 'MID_END_ASSESSMENT',
        };
        break;

      default:
        break;
    }

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const renderAssessmentView = () => {
    return organizedAssessments
      .filter(
        ({ name }) =>
          searchAssessmentName === '' ||
          name?.toUpperCase().includes(searchAssessmentName.toUpperCase())
      )
      .map((assessment, index) => {
        if (isGridView) {
          return renderGridTile(assessment, index);
        } else {
          return renderTableRow(assessment, index);
        }
      });
  };

  const renderGridTile = (gridObject, index) => {
    const { _id, name, imageUrl, videoId, isPublished, type } = gridObject;

    return (
      <div className="reel-view--grid-video-wrapper" key={index}>
        <div
          className={`reel-view--grid-video ${
            !isPublished ? 'unpublished' : ''
          }`}
        >
          <div
            className="reel-view--grid-video-unpublished"
            onClick={() => {
              if (videoId) {
                history.push(`/theatre/${videoId}`);
              } else {
                history.push(`/assessment/${_id}`);
              }
            }}
          ></div>
          <img
            src={imageUrl}
            alt="reel-thumbnail"
            className="reel-thumbnail"
            onError={event => {
              event.target.onerror = null;
              event.target.src = defaultAssessment;
            }}
            onClick={() => {
              if (videoId) {
                history.push(`/theatre/${videoId}`);
              } else {
                history.push(`/assessment/${_id}`);
              }
            }}
          />
          <div className="reel-view--grid-video-settings">
            {canEditAssessment && (
              <img
                src={edit_white}
                alt="edit-icon"
                className="icon--edit-white"
                onClick={() =>
                  history.push(
                    `/edit-assessment/${getBannerFeaturedEditRoute(
                      gridObject
                    )}${_id}`
                  )
                }
              />
            )}
            {canSuspendPublishAssessment && (
              <img
                src={isPublished ? warning : publish}
                alt={isPublished ? 'warning-icon' : 'publish-icon'}
                className={isPublished ? 'icon--warning' : 'icon--publish'}
                onClick={() => hanldeIsPublishedStatus(_id)}
              />
            )}
            {canDeleteAssessement && (
              <img
                src={deleteIcon_white}
                alt="delete-icon"
                className="icon--delete-white"
                onClick={() => handleDelete(_id, videoId)}
              />
            )}
            <div className="reel-view--grid-video-settings-stats">
              <img
                src={stats}
                alt="stats-icon"
                className="icon--stats"
                onClick={() => handleViewStats(gridObject)}
              />
            </div>
          </div>
        </div>
        <p>{name}</p>
      </div>
    );
  };

  const renderTableRow = (rowItem, index) => {
    const { name, imageUrl, isPublished, date, completedCount } = rowItem;

    return (
      <div key={index} className="reel-view--table-row">
        <div className="reel-view--table-cell">
          <img
            src={imageUrl}
            className="reel-view--table-backgroundimg"
            onError={event => {
              event.target.onerror = null;
              event.target.src = defaultAssessment;
            }}
          />
        </div>
        <div className="reel-view--table-cell">
          <p>{name}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>{date ? moment.utc(date).local().format('DD/MM/YYYY') : '-'}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>{completedCount || '0'}</p>
        </div>
        <div className="reel-view--table-cell">
          {(canDeleteAssessement ||
            canEditAssessment ||
            canSuspendPublishAssessment) && (
            <ActionDropdown
              ishaveEdit={canEditAssessment}
              ishaveSuspend={canSuspendPublishAssessment && isPublished}
              ishavePublish={canSuspendPublishAssessment && !isPublished}
              ishaveDelete={canDeleteAssessement}
              handleOnchange={option =>
                setAssessmentListTableActions(option.value, rowItem)
              }
            />
          )}
        </div>
      </div>
    );
  };

  const handleViewStats = assessment => {
    dispatch({
      type: SET_STATS_CURRENT_ASSESSMENT,
      payload: assessment
    });
    switch (assessment.type) {
      case 'STANDALONE':
        history.push(
          `/group-dashboard/${groupId}/assessments/standalone/${assessment.reelId}/${assessment._id}`
        );
        break;

      case 'MID_VIDEO':
        history.push(
          `/group-dashboard/${groupId}/assessments/mid-video/${assessment._id}`
        );
        break;

      case 'END_VIDEO':
        history.push(
          `/group-dashboard/${groupId}/assessments/end-video/${assessment._id}`
        );
        break;

      default:
        break;
    }
  };

  const getCreateAssessmentType = () => {
    switch (assessmentType) {
      case 'MID_VIDEO':
        return 'mid-video';

      case 'END_VIDEO':
        return 'end-video';

      case 'STANDALONE':
        return 'standalone';

      default:
        break;
    }
  };

  const isLoading =
    midEndAssementsByGroupFetchDataLoading ||
    standaloneAssementsByGroupFetchDataLoading ||
    suspendAssessmentLoading;

  return (
    <div className="group-users-table--wrapper-column">
      <div className="reel-view--wrapper">
        <div
          className={`reel-view--wrapper-column loading-screen-parent ${
            isLoading ? 'setheight' : ''
          }`}
        >
          <div className="reel-view--page-title">
            <div className="reel-view--page-selection">
              <BreadCrumbView
                history={history}
                routeOptions={[
                  {
                    title: parentGroupName ? parentGroupName : '',
                    path: `/node-tree`,
                  },
                  {
                    title: groupName ? groupName : '',
                    path: `/home/${groupId}/`,
                  },
                  {
                    title: 'Assessments',
                    path: '/#',
                  },
                ]}
              />
              <h2>{t('Assessments')}</h2>
            </div>
            <div className="reel-view--page-settings">
              <input
                className="form-input form-input--search"
                type="search"
                id="search"
                name="search"
                placeholder={t('Search')}
                value={searchAssessmentName}
                onChange={event => setSearchAssessmentName(event.target.value)}
              />
              {/* <Dropdown title="Filter By" options={[]} /> */}
              <button
                className="btn btn--primary btn--createnew btn--long"
                onClick={() =>
                  history.push(
                    `/create-assessment/${getCreateAssessmentType()}`
                  )
                }
              >
                {t('Create Assessment')}
              </button>
              <div
                className={`reel-view--image grid ${
                  isGridView ? 'active' : ''
                }`}
                title={t(ToolTipConfig.general.gridView)}
                onClick={() => setIsGridView(true)}
              />
              <div
                className={`reel-view--image list ${
                  !isGridView ? 'active' : ''
                }`}
                title={t(ToolTipConfig.general.tableView)}
                onClick={() => setIsGridView(false)}
              />
            </div>
          </div>
          <div className="reel-view--page-reelselection">
            <p
              className={assessmentType === 'MID_VIDEO' ? 'active' : ''}
              onClick={() => setAssessmentType('MID_VIDEO')}
            >
              {t('Mid Video')}
            </p>
            <p
              className={assessmentType === 'END_VIDEO' ? 'active' : ''}
              onClick={() => setAssessmentType('END_VIDEO')}
            >
              {t('End Video')}
            </p>
            <p
              className={assessmentType === 'STANDALONE' ? 'active' : ''}
              onClick={() => setAssessmentType('STANDALONE')}
            >
              {t('Stand Alone')}
            </p>
          </div>
          {isLoading ? (
            <Loader />
          ) : isGridView ? (
            <div className="reel-view--grid">
              <div className="reel-view--grid-wrapper">
                {renderAssessmentView()}
                <div className="reel-view--grid-video-wrapper">
                  {/*<div className="reel-view--grid-video addbutton">
                    <img src={plus} alt="edit-icon" className="icon--plus" />
                    <p>Add Assessment</p>
                  </div>*/}
                </div>
              </div>
            </div>
          ) : (
            <div className="reel-view--table">
              <div className="reel-view--table-header">
                <div className="reel-view--table-cell">
                  <h5>{t('Preview')}</h5>
                </div>
                <div className="reel-view--table-cell">
                  <h5>{t('Title')}</h5>
                </div>
                <div className="reel-view--table-cell">
                  <h5>{t('Date Created')}</h5>
                </div>
                <div className="reel-view--table-cell">
                  <h5>{t('Total Completed')}</h5>
                </div>
                <div className="reel-view--table-cell">
                  {/* Dropdown column */}
                </div>
              </div>
              <div className="reel-view--table-body">
                <div className="reel-view--grid-video-wrapper">
                  {/*<div className="reel-view--grid-video addbutton">
                    <img src={plus} alt="edit-icon" className="icon--plus" />
                    <p>Add Assessment</p>
                  </div>*/}
                </div>
                {renderAssessmentView()}
              </div>
            </div>
          )}
        </div>
      </div>
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={messageConfigs.deleteConfirm.title}
          message={messageConfigs.deleteConfirm.assessment}
          handleSuccess={() => deleteAssessment(isDeleteConfirmModalOpen)}
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
        />
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import previewimg from '../../../assets/images/sample-img.png';
import SaveChangesModal from '../../AdminDashboard/Global/Assessment/Modal/saveChangesModal';
import {
  getAllGroupInfoByCurrentUser,
  capitalizeFirstLetter,
} from '../../../Helper/SystemManager';

import {
  CREATE_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_FAIL,
  UPDATE_ASSESSMENT_REQUEST,
  UPDATE_ASSESSMENT_SUCCESS,
  UPDATE_ASSESSMENT_FAIL,
  UPDATE_ASSESSMENT_RESET,
  CREATE_ASSESSMENT_RESET,
} from '../../../redux/actionTypes/assesment';

import useApi from '../../../Hooks/useApi';
import GroupMultiSelectWithMandatoryDate from '../../../Components/CreateEditComponents/GroupSelect';
import TagSelection from '../../../Components/CreateEditComponents/TagSelection';
import AssignRelatedMultiSelect from '../../../Components/CreateEditComponents/AssignRelatedMultiSelect';
import ProceedMessageModal from '../../../Components/Modal/ProceedMessageModal';
import Loader from '../../../Components/Loader/loader';
import messageConfigs from '../../../Helper/PopupMessageConfig';
import useFetchDataApi from '../../../Hooks/useFetchDataApi';
import ErrorMessageModal from '../../../Components/Modal/ErrorMessageModal';
import { validateFields } from '../../../Helper/ValidationsManager';
import MandatoryMark from '../../../Components/validation/MandatoryMark';
import QuestionsView from '../../../Components/CreateEditComponents/QuestionsView';
import PreviewImageView from '../../../Components/CreateEditComponents/PreviewImageView';
import usePrivilegesApi from '../../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../../Common/UserPrivileges';

const questionObj = {
  type: 'mcq',
  isTextOnly: true,
  orientation: 1,
  quiz: '',
  options: [''],
  correctAnswer: 0,
  questionImageS3BucketURL: '',
  isQuestionImageViaUrl: false,
};

const groupsObj = {
  groups: [],
};

const initailAssessmentData = {
  title: '',
  description: '',
  author: '',
  isNativeBannerAndFeatured: true,
  isFeatured: false,
  isBanner: false,
  tags: '',
  isPublished: false,
  placement: 'STANDALONE',
  duration: '',
  startTimeMin: '',
  startTimeSec: '',
  triggerTimeMin: '',
  triggerTimeSec: '',
  tileImageUrl: '',
  tileImageFile: null,
  isTileImageViaUrl: false,
  questions: [_.clone(questionObj)],
  listOfGroups: [_.clone(groupsObj)],
  selectedVideos: [],
  selectedDocuments: [],
};

const ansOrders = ['A', 'B', 'C', 'D', 'E'];

const CreateAssessment = ({ match, history }) => {
  const { t } = useTranslation();
  const assessmentType = match.params.type;
  const assessmentId = match.params.id;
  const groupId = match.params.groupId;
  const isHavePublishAccess = usePrivilegesApi(
    PrivilegeTypes.ASSESSMENT.CREATE_ASSESSMENT
  );
  const [assessmentData, setAssessmentData] = useState(
    _.clone(initailAssessmentData)
  );
  const [isReplicate, setIsReplicate] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [isPublishProcess, setIsPublishProcess] = useState(false);
  const [isSaveProcess, setIsSaveProcess] = useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(null);
  const [isUpdateProcess, setIsUpdateProcess] = useState(false);
  const [isValidationError, setIsValidationError] = useState(null);
  const [isValidUrl, setIsValidUrl] = useState(true);

  const [fetchCreateAssessmentRelatedData] = useFetchDataApi();
  const [createAssessment] = useApi();
  const [updateAssessment] = useApi();
  const [fetchAllGroups] = useFetchDataApi();
  const [fetchAssessmentDataById] = useFetchDataApi();
  const dispatch = useDispatch();

  const { groupInfo, userName } = useSelector(state => state?.auth?.userData);
  const { allGroupsFetchData } = useSelector(state => state?.group);
  const {
    findAssessmentData,
    addAssessmentSuccess,
    updateAssessmentLoading,
    createAssessmentRelatedInfoData,
    createAssessmentRelatedInfoloading,
    addAssessmentError,
    addAssessmentLoading,
    updateAssessmentSuccess,
    updateAssessmentError,
  } = useSelector(state => state?.assessment);

  const { relatedDocuments = [], relatedVideos = [] } =
    createAssessmentRelatedInfoData;

  useEffect(() => {
    if (selectGroupOptions.length && groupId) {
      const createFromGroup = {
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
            name: selectGroupOptions.find(option => option.value === groupId)
              .label,
          },
        ],
      };

      const intialData = { ...assessmentData };
      intialData['listOfGroups'] = [_.clone(createFromGroup)];

      setAssessmentData(intialData);
    }
  }, [selectGroupOptions, groupId]);

  useEffect(() => {
    if (addAssessmentSuccess) {
      setIsSaved(true);
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message: isPublishProcess
          ? assessmentType === 'banner'
            ? messageConfigs.success.standaloneAssessment.PUBLISH_BANNER
            : messageConfigs.success.standaloneAssessment.PUBLISH_FEATURED
          : assessmentType === 'banner'
          ? messageConfigs.success.standaloneAssessment.SAVE_BANNER
          : messageConfigs.success.standaloneAssessment.SAVE_FEATURED,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      dispatch({
        type: CREATE_ASSESSMENT_RESET,
      });
    }
  }, [addAssessmentSuccess]);

  useEffect(() => {
    if (addAssessmentError) {
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      dispatch({
        type: CREATE_ASSESSMENT_RESET,
      });
    }
  }, [addAssessmentError]);

  useEffect(() => {
    if (updateAssessmentSuccess) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message:
          assessmentType === 'banner'
            ? messageConfigs.success.standaloneAssessment.UPDATE_BANNER
            : messageConfigs.success.standaloneAssessment.UPDATE_FEATURED,
      });

      dispatch({
        type: UPDATE_ASSESSMENT_RESET,
      });
    }
  }, [updateAssessmentSuccess]);

  useEffect(() => {
    if (updateAssessmentError) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      dispatch({
        type: UPDATE_ASSESSMENT_RESET,
      });
    }
  }, [updateAssessmentError]);

  useEffect(() => {
    setTimeout(() => {
      var allPanels = $('.accordion .admin__createassessment--inner-container');
      $('.accordion h3').unbind();
      $('.accordion h3').click(function () {
        $(this)
          .parent()
          .children('.admin__createassessment--inner-container')
          .slideUp();
        $(this).addClass('closed');
        // allPanels.slideUp();
        if (
          $(this)
            .parent()
            .children('.admin__createassessment--inner-container')
            .css('display') == 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__createassessment--inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });

      // Commented to solve bug mentioned in ticker VTP 366
      // var allQuestionPanels = $(
      //   '.accordion .admin__createassessment--question_inner-container'
      // ).hide();
      $('.admin__createassessment--question-title').unbind();
      $('.admin__createassessment--question-title').click(function () {
        $(this)
          .parent()
          .children('.admin__createassessment--question_inner-container')
          .slideUp();
        $(this).addClass('closed');
        allPanels.slideUp();
        if (
          $(this)
            .parent()
            .children('.admin__createassessment--question_inner-container')
            .css('display') == 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__createassessment--question_inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });
    }, 0);
  }, []);

  useEffect(() => {
    fetchAllGroups({ type: 'ALL_GROUPS' });
  }, []);

  useEffect(() => {
    if (!Array.isArray(allGroupsFetchData) && allGroupsFetchData && groupInfo) {
      const flattenedGroups = getAllGroupInfoByCurrentUser(
        userName,
        groupInfo,
        allGroupsFetchData
      );

      // Constructing select group dropdown values and removing current group
      const organizeSelectOptions = optionsArray => {
        return optionsArray.map(group => {
          return { value: group._id, label: group.title };
        });
      };

      const selectGroupOptions = organizeSelectOptions(flattenedGroups);

      setSelectGroupOptions(selectGroupOptions);

      fetchCreateAssessmentRelatedData({ type: 'INTIALIZE_CREATE_ASSESSMENT' });
    }
  }, [allGroupsFetchData]);

  useEffect(() => {
    if (
      assessmentId &&
      Object.keys(createAssessmentRelatedInfoData).length !== 0 &&
      createAssessmentRelatedInfoData.constructor === Object
    ) {
      fetchAssessmentDataById({
        type: 'FIND_ASSESSMENT',
        objectId: assessmentId,
      });
    }
  }, [createAssessmentRelatedInfoData]);

  useEffect(() => {
    if (findAssessmentData && assessmentId) {
      const {
        _id,
        title,
        duration,
        author,
        description,
        documentIds,
        isBanner,
        isFeatured,
        isNativeBannerAndFeatured,
        isTileImageViaUrl,
        listOfReelIdCategoryIdAndGroups,
        tileImageUrl,
        questions,
        tags,
        relatedVideoIds,
        isPublished,
      } = findAssessmentData;

      const selectedVideoOptions = relatedVideos
        .filter(({ _id }) => relatedVideoIds.includes(_id))
        .map(video => ({
          label: video.title,
          value: video._id,
        }));

      const selectedDocumentOptions = relatedDocuments
        .filter(({ _id }) => documentIds.includes(_id))
        .map(documentFile => ({
          label: documentFile.title,
          value: documentFile._id,
        }));

      const selectedGroups = listOfReelIdCategoryIdAndGroups[0].groups.map(
        group => {
          const { _id, dueDate, isMandatory } = group;

          return {
            _id,
            dueDate,
            isMandatory,
            name:
              selectGroupOptions &&
              selectGroupOptions.length &&
              selectGroupOptions.find(option => option.value === _id).label,
          };
        }
      );

      const questionsOrgnized = questions.map(question => {
        if (question.questionImageS3BucketURL) {
          question.isTextOnly = false;
        } else {
          question.isTextOnly = true;
        }
        return question;
      });

      const editAssessmentData = { ...assessmentData };
      editAssessmentData['_id'] = _id;
      editAssessmentData['title'] = title;
      editAssessmentData['description'] = description;
      editAssessmentData['author'] = author;
      editAssessmentData['duration'] = duration ? duration : '';
      editAssessmentData['isNativeBannerAndFeatured'] =
        isNativeBannerAndFeatured;
      editAssessmentData['isFeatured'] = isFeatured;
      editAssessmentData['isBanner'] = isBanner;
      editAssessmentData['tags'] = tags.join();
      editAssessmentData['tileImageUrl'] = tileImageUrl;
      editAssessmentData['isTileImageViaUrl'] = isTileImageViaUrl;
      editAssessmentData['questions'] = questionsOrgnized;
      editAssessmentData['selectedVideos'] = selectedVideoOptions;
      editAssessmentData['isPublished'] = isPublished;
      editAssessmentData['selectedDocuments'] = selectedDocumentOptions;
      editAssessmentData['listOfGroups'] = [{ groups: selectedGroups }];

      setAssessmentData(editAssessmentData);
    }
  }, [findAssessmentData]);

  const {
    title,
    description,
    isNativeBannerAndFeatured,
    tags,
    placement,
    duration,
    tileImageUrl,
    tileImageFile,
    isTileImageViaUrl,
    questions,
    listOfGroups,
    selectedVideos,
    selectedDocuments,
  } = assessmentData;

  useEffect(() => {
    setIsValidUrl(true);
  }, [tileImageUrl]);

  const handleAssessmentData = (value, property) => {
    const tempAssessmentData = { ...assessmentData };
    tempAssessmentData[property] = value;
    setAssessmentData(tempAssessmentData);
    if (isSaved) {
      setIsSaved(false);
    }
  };

  const handleQuestionData = (index, property, value) => {
    const tempQuestions = [...questions];
    tempQuestions[index][property] = value;
    handleAssessmentData(tempQuestions, 'questions');
  };

  const handleCreateAssessment = isPublished => {
    const formErrors = validateFields({
      type: 'STANDALONE_ASSESSMENT',
      formData: {
        title,
        questions,
        listOfGroups,
      },
    });

    if (!isValidUrl && isTileImageViaUrl) {
      return;
    }

    if (formErrors) {
      setIsValidationError(formErrors);
      return;
    }

    const formData = new FormData();
    const tempQuestions = [...questions];

    const assessmentInfo = {
      title,
      description,
      duration,
      tags: tags.split(','),
      isPublished,
      isEdit: !!assessmentId,
      isNativeBannerAndFeatured,
      isFeatured: assessmentType === 'featured',
      isBanner: assessmentType === 'banner',
      placement,
      tileImageUrl,
      isTileImageViaUrl: isTileImageViaUrl,
      questions: tempQuestions.map(tempQuestion => {
        const question = { ...tempQuestion };
        question.isQuestionImageViaUrl = true;
        if (!question.questionImageS3BucketURL || question.isTextOnly) {
          question.isQuestionImageViaUrl = false;
          delete question.questionImageS3BucketURL;
        }

        delete question.isTextOnly;
        return question;
      }),
      relatedVideoIds: selectedVideos.map(selectedVideo => selectedVideo.value),
      documentIds: selectedDocuments.map(
        selectedDocument => selectedDocument.value
      ),
      listOfReelIdCategoryIdAndGroups: listOfGroups.map(value => {
        const groupData = value.groups.map(group => {
          return {
            _id: group._id,
            dueDate: group.dueDate,
            isMandatory: group.isMandatory,
          };
        });

        return { groups: groupData };
      }),
    };

    if (assessmentData._id) {
      assessmentInfo._id = assessmentData._id;
    }

    formData.append(
      'assessment',
      new Blob([JSON.stringify(assessmentInfo)], { type: 'application/json' })
    );

    if (assessmentInfo._id) {
      updateAssessment(
        '/assessment/add-assessment',
        UPDATE_ASSESSMENT_REQUEST,
        UPDATE_ASSESSMENT_SUCCESS,
        UPDATE_ASSESSMENT_FAIL,
        formData,
        '',
        'POST',
        null,
        'isReelService'
      );
    } else {
      createAssessment(
        '/assessment/add-assessment',
        CREATE_ASSESSMENT_REQUEST,
        CREATE_ASSESSMENT_SUCCESS,
        CREATE_ASSESSMENT_FAIL,
        formData,
        '',
        'POST',
        null,
        'isReelService'
      );
    }
  };

  const replicateAssessmentData = () => {
    setAssessmentData({ ...initailAssessmentData, questions });
    setIsSaved(false);
  };

  return (
    <div>
      <div
        className={`admin__createassessment banner loading-screen-parent ${
          createAssessmentRelatedInfoloading ? 'setheight' : ''
        }`}
      >
        {createAssessmentRelatedInfoloading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="admin__createassessment--wrapper">
              <div className="admin__createassessment--page-title">
                <p className="btn--back" onClick={() => history.goBack()}>
                  {t('Back to Home')}
                </p>
                <h2>
                  {assessmentId ? 'Edit' : 'Create'}{' '}
                  {capitalizeFirstLetter(assessmentType)} Assessment
                </h2>
              </div>
              <div className="admin__createassessment--container">
                <div className="admin__createassessment--left-col">
                  <div className="admin__createassessment--information">
                    <input
                      type="text"
                      id="document-titl"
                      name="document-titl"
                      placeholder={t('Assessment Title *')}
                      value={title}
                      onChange={e =>
                        handleAssessmentData(e.target.value, 'title')
                      }
                    />
                    <input
                      type="text"
                      id="document-description"
                      name="document-description"
                      placeholder={t('Description')}
                      value={description}
                      onChange={e =>
                        handleAssessmentData(e.target.value, 'description')
                      }
                    />
                  </div>

                  <div className="admin__createassessment--placement accordion">
                    <div className="admin__createassessment--inner-container">
                      <p className="label">Type: Stand Alone</p>

                      {/* <div className="section--right-column createassessment">
                      {placement === 'STANDALONE' && (
                        <>
                          <p className="admin__createassessment--placement-label">Duration</p>
                          <div className="admin__createassessment--placement-time duration">
                            <input
                              type="number"
                              id="starttime min"
                              name="starttime min"
                              placeholder="00"
                              value={duration}
                              onChange={e => handleAssessmentData(e.target.value, 'duration')}
                            />
                            <p>min</p>
                          </div>
                        </>
                      )}
                    </div> */}
                    </div>
                  </div>
                  {placement === 'STANDALONE' && (
                    <div className="admin__createassessment--reels accordion">
                      <h3>
                        {t('Groups')} <MandatoryMark />
                      </h3>
                      <GroupMultiSelectWithMandatoryDate
                        listOfGroups={listOfGroups}
                        selectGroupOptions={selectGroupOptions}
                        handleChange={handleAssessmentData}
                      />
                    </div>
                  )}
                  {placement === 'STANDALONE' ? (
                    <div className="admin__createassessment--documents accordion">
                      <h3>{t('Assigning Related Video')}</h3>
                      <div className="admin__createassessment--inner-container">
                        <AssignRelatedMultiSelect
                          title={'Videos'}
                          selectOptions={relatedVideos}
                          selectedValues={selectedVideos}
                          valueType={'selectedVideos'}
                          handleChange={handleAssessmentData}
                        />
                      </div>
                    </div>
                  ) : null}

                  {placement === 'STANDALONE' ? (
                    <div className="admin__createassessment--documents accordion">
                      <h3>{t('Assigning Related Document')}</h3>
                      <div className="admin__createassessment--inner-container">
                        <AssignRelatedMultiSelect
                          title={'Documents'}
                          selectOptions={relatedDocuments}
                          selectedValues={selectedDocuments}
                          valueType={'selectedDocuments'}
                          handleChange={handleAssessmentData}
                        />
                      </div>
                    </div>
                  ) : null}

                  {placement === 'STANDALONE' && (
                    <div className="admin__createassessment--tags">
                      <h3>{t('Tags')}</h3>
                      <div className="admin__createassessment--inner-container">
                        <TagSelection
                          tags={tags}
                          customClassName={
                            'admin__createassessment--tags-selected'
                          }
                          handleChange={handleAssessmentData}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="admin__createassessment--right-col">
                  {placement === 'STANDALONE' && (
                    <PreviewImageView
                      title={'Preview Picture'}
                      imageSrc={tileImageUrl || previewimg}
                      isImageViaUrl={isTileImageViaUrl}
                      imageUrl={tileImageUrl}
                      handleData={handleAssessmentData}
                      handleFileAccept={handleAssessmentData}
                      isImageViaUrlPropertyName={'isTileImageViaUrl'}
                      previewImgUrlPropertyName={'tileImageUrl'}
                      previewImagePropertyName={'tileImageFile'}
                      setIsValidUrl={setIsValidUrl}
                      isValidUrl={isValidUrl}
                    />
                  )}
                  <QuestionsView
                    assessmentId={assessmentId}
                    questions={questions}
                    handleQuestionData={handleQuestionData}
                    handleAssessmentData={handleAssessmentData}
                    questionObj={questionObj}
                  />
                </div>
                <div className="admin__createassessment--buttons">
                  {!assessmentId ? (
                    <>
                      <button
                        className={`
                        btn btn--secondary
                        ${
                          addAssessmentLoading && isSaveProcess
                            ? 'btn--loader'
                            : ''
                        }
                      `}
                        onClick={() => {
                          setIsSaveProcess(true);
                          handleCreateAssessment(false);
                        }}
                      >
                        {t('Save')}
                      </button>
                      <button
                        className="btn btn--secondary"
                        onClick={() => {
                          if (isSaved) {
                            replicateAssessmentData();
                          } else {
                            setIsReplicate(true);
                          }
                        }}
                      >
                        {t('Replicate')}
                      </button>
                      {isHavePublishAccess && (
                        <button
                          className={`btn btn--tertiary ${
                            addAssessmentLoading && isPublishProcess
                              ? 'btn--loader'
                              : ''
                          }`}
                          onClick={() => {
                            setIsPublishProcess(true);
                            handleCreateAssessment(true);
                          }}
                        >
                          {t('Publish')}
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className={`btn btn--secondary ${
                        updateAssessmentLoading && isUpdateProcess
                          ? 'btn--loader'
                          : ''
                      }`}
                      onClick={() => {
                        setIsUpdateProcess(true);
                        handleCreateAssessment(assessmentData.isPublished);
                      }}
                      disabled={updateAssessmentLoading && isUpdateProcess}
                    >
                      {t('Update')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isReplicate && (
        <SaveChangesModal
          handleClose={() => {
            replicateAssessmentData();
            setIsReplicate(false);
          }}
          handleSubmit={() => handleCreateAssessment(false)}
        />
      )}
      {isSubmitSuccessModalOpen && (
        <ProceedMessageModal
          isSuccess={isSubmitSuccessModalOpen.isSuccess}
          message={isSubmitSuccessModalOpen.message}
          handleClose={() => setIsSubmitSuccessModalOpen(null)}
          handleSuccess={() => {
            setIsSubmitSuccessModalOpen(null);
            history.goBack();
          }}
          handleError={() => setIsSubmitSuccessModalOpen(null)}
        />
      )}
      {isValidationError && (
        <ErrorMessageModal
          errorData={isValidationError}
          handleSubmit={() => setIsValidationError(null)}
        />
      )}
    </div>
  );
};

export default CreateAssessment;

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAIL = 'FETCH_USER_DATA_FAIL';

export const SET_WELCOME_VIDEO_VIEWED = 'SET_WELCOME_VIDEO_VIEWED';

export const CLEAR_SESSION = 'CLEAR_SESSION';

export const FETCH_RESET_PASSWORD_DATA_REQUEST =
  'FETCH_RESET_PASSWORD_DATA_REQUEST';
export const FETCH_RESET_PASSWORD_DATA_SUCCESS =
  'FETCH_RESET_PASSWORD_DATA_SUCCESS';
export const FETCH_RESET_PASSWORD_DATA_FAIL = 'FETCH_RESET_PASSWORD_DATA_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const FETCH_COMPANY_PROFILE_REQUEST = 'FETCH_COMPANY_PROFILE_REQUEST';
export const FETCH_COMPANY_PROFILE_SUCCESS = 'FETCH_COMPANY_PROFILE_SUCCESS';
export const FETCH_COMPANY_PROFILE_FAIL = 'FETCH_COMPANY_PROFILE_FAIL';

export const SYNC_FEDERATE_LOGIN_DATA_REQUEST =
  'SYNC_FEDERATE_LOGIN_DATA_REQUEST';
export const SYNC_FEDERATE_LOGIN_DATA_SUCCESS =
  'SYNC_FEDERATE_LOGIN_DATA_SUCCESS';
export const SYNC_FEDERATE_LOGIN_DATA_FAIL = 'SYNC_FEDERATE_LOGIN_DATA_FAIL';

export const SET_IS_FEDERATED_LOGIN = 'SET_IS_FEDERATED_LOGIN';

export const SET_GEO_LOCATION = 'SET_GEO_LOCATION';

export const FETCH_S3_BUCKET_DATA_REQUEST = 'FETCH_S3_BUCKET_DATA_REQUEST';
export const FETCH_S3_BUCKET_DATA_SUCCESS = 'FETCH_S3_BUCKET_DATA_SUCCESS';
export const FETCH_S3_BUCKET_DATA_FAIL = 'FETCH_S3_BUCKET_DATA_FAIL';

export const FETCH_SSO_USER_VERIFY_REQUEST = 'FETCH_SSO_USER_VERIFY_REQUEST';
export const FETCH_SSO_USER_VERIFY_SUCCESS = 'FETCH_SSO_USER_VERIFY_SUCCESS';
export const FETCH_SSO_USER_VERIFY_FAIL = 'FETCH_SSO_USER_VERIFY_FAIL';
export const FETCH_SSO_USER_VERIFY_RESET = 'FETCH_SSO_USER_VERIFY_RESET';

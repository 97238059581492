import { WEBSOCKET_MESSAGE_RECEIVED } from '../actionTypes/webSocketTypes';

const INITIAL_STATE = {
  messages: [],
};

const webSocketReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WEBSOCKET_MESSAGE_RECEIVED:
      return {
        ...state,
        messages: [...state.messages, action.payload],
        lastMessage: action.payload,
      };

    default:
      return state;
  }
};

export default webSocketReducer;

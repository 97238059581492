import React from 'react';

import edit_white from '../../assets/images/svg-images/icon-edit-white.svg';
import upload from '../../assets/images/svg-images/icon-upload.svg';
import deleteicon from '../../assets/images/svg-images/icon-delete-white.svg';
import stats from '../../assets/images/svg-images/icon-stats.svg';

const MiniDocument = ({ title, image, history, categoryId, reelId, groupId }) => {
  return (
    <div className="policy__reel">
      <div
        className="policy__tile"
        style={{ backgroundImage: `url(${image})` }}
        onClick={() => {
          // history.push(`/reel-bulk-view/policies-page/${title.replace(/\s/g, '')}`);
          history.push(
            `/reel-bulk-view${groupId ? `/${groupId}` : ''}/document/${reelId}/${categoryId}`
          );
        }}
      >
        <p>{title}</p>
      </div>
      <span className="policy__icons">
        <img src={edit_white} alt="edit-icon" className="icon--edit-white" />
        <img src={deleteicon} alt="delete-icon" className="icon--delete" />
        <img src={upload} alt="upload-icon" className="icon--upload" />
        <img src={stats} alt="stats-icon" className="icon--stats" />
      </span>
    </div>
  );
};

export default MiniDocument;

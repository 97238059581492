import React from 'react';
import MultiSelect from 'react-multi-select-component';
import { useTranslation } from 'react-i18next';

const AssignRelatedMultiSelect = ({
  title,
  selectOptions,
  selectedValues,
  valueType,
  placeholder,
  handleChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="label">{t(title)}</p>
      <MultiSelect
        title={title}
        options={
          selectOptions && selectOptions.length
            ? selectOptions.map(option => ({
                label: option.title,
                value: option._id,
              }))
            : []
        }
        value={selectedValues}
        onChange={option => handleChange(option, valueType)}
        placeholder={placeholder ? placeholder : t('Search')}
      />
      <div className="admin__createassessment--tags-selected">
        {selectedValues.length
          ? selectedValues.map(({ label, value }, index) => (
              <p key={index}>
                {label}
                <span className="close-icon">
                  <a
                    className="btn-close"
                    aria-hidden="true"
                    onClick={() => {
                      const tempSelectedValues = [...selectedValues];
                      handleChange(
                        tempSelectedValues.filter(
                          selectedValue => selectedValue.value !== value
                        ),
                        valueType
                      );
                    }}
                  >
                    &times;
                  </a>
                </span>
              </p>
            ))
          : null}
      </div>
    </>
  );
};

export default AssignRelatedMultiSelect;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dropdown from 'react-dropdown';
import useApi from '../../../../Hooks/useApi';
import {
  FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_SUCCESS,
  ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_REQUEST,
  ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_SUCCESS,
  ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_FAIL,
  ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_RESET,
} from '../../../../redux/actionTypes/reels';
import CheckBoxPanel from '../../../../Components/CheckBoxPanel/CheckBoxPanel';
import { getAllGroupInfoByCurrentUser } from '../../../../Helper/SystemManager';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';

export default function AddReelModal({
  reelType,
  groupId,
  handleClose,
  handleSuccess,
}) {
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedReel, setSelectedReel] = useState(null);
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [isSelectByCatagoryView, setIsSelectByCatagoryView] = useState(false);
  const [organizedReelData, setOrganizedReelData] = useState([]);
  const [checkedCategoryValues, setCheckedCategoryValues] = useState([]);
  const [checkedContentByCategory, setCheckedContentByCategory] = useState(
    null
  );
  const [
    childContentCountByCategory,
    setChildContentCountByCategory,
  ] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectNone, setSelectNone] = useState(false);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);

  const { groupInfo, userName } = useSelector(state => state?.auth?.userData);
  const { allGroupsFetchData } = useSelector(state => state?.group);
  const {
    allReelsCategoriesFetchData,
    addVideoDocumentFromOtherReelSuccess,
    addVideoDocumentFromOtherReelLoading,
    allReelsCategoriesFetchDataLoading,
  } = useSelector(state => state?.reel);
  const [fetchAllGroups] = useFetchDataApi();
  const [fetchData] = useFetchDataApi();
  const [addContentToReel] = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllGroups({ type: 'ALL_GROUPS' });
  }, []);

  useEffect(() => {
    if (!Array.isArray(allGroupsFetchData) && allGroupsFetchData && groupInfo) {
      const flattenedGroups = getAllGroupInfoByCurrentUser(
        userName,
        groupInfo,
        allGroupsFetchData
      );

      // Constructing select group dropdown values and removing current group
      const organizeSelectOptions = optionsArray => {
        return optionsArray
          .filter((group, index, self) => {
            return (
              index ===
              self.findIndex(
                key => key._id === group._id && key._id !== groupId
              )
            );
          })
          .map(group => {
            return { value: group._id, label: group.title };
          });
      };

      const selectGroupOptions = organizeSelectOptions(flattenedGroups);

      setSelectGroupOptions(selectGroupOptions);
    }
  }, [allGroupsFetchData]);

  useEffect(() => {
    if (selectedGroup) {
      fetchData({
        reelType,
        type: 'ADD_REEL_DATA_TO_GROUP',
        groupId: selectedGroup.value,
      });
    }
  }, [selectedGroup, reelType]);

  useEffect(() => {
    if (allReelsCategoriesFetchData) {
      organizeReelData(allReelsCategoriesFetchData);
    }

    return () =>
      dispatch({
        type: FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_SUCCESS,
        payload: null,
      });
  }, [allReelsCategoriesFetchData]);

  useEffect(() => {
    if (addVideoDocumentFromOtherReelSuccess) {
      setIsAddButtonClicked(false);
      handleClose();
      handleSuccess();
    }

    return () =>
      dispatch({ type: ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_RESET });
  }, [addVideoDocumentFromOtherReelSuccess]);

  useEffect(() => {
    if (selectedReel) {
      const contentByCategory = {};
      const childContentCount = {};
      selectedReel.categories.forEach(category => {
        contentByCategory[category.id] = [];
        childContentCount[category.id] = category.childrenCheckBoxes.length;
      });

      setCheckedContentByCategory(contentByCategory);
      setChildContentCountByCategory(childContentCount);
    }
  }, [selectedReel]);

  const organizeReelData = dataArray => {
    const organizedReelData = [];

    dataArray.forEach(reel => {
      const { _id, name, category } = reel;
      const categoryData = [];

      category.forEach(value => {
        const { _id, name, videos, documents, assessments } = value;

        const childrenCheckBoxes = [];

        if (videos.length) {
          videos.forEach(videoData => {
            const { _id, title } = videoData;

            childrenCheckBoxes.push({ id: _id, label: title, type: 'VIDEO' });
          });
        }

        if (documents.length) {
          documents.forEach(documentData => {
            const { _id, title } = documentData;

            childrenCheckBoxes.push({
              id: _id,
              label: title,
              type: 'DOCUMENT',
            });
          });
        }

        if (assessments.length) {
          assessments.forEach(assessmentData => {
            const { _id, title } = assessmentData;

            childrenCheckBoxes.push({
              id: _id,
              label: title,
              type: 'ASSESSMENT',
            });
          });
        }

        if (childrenCheckBoxes.length) {
          categoryData.push({ id: _id, label: name, childrenCheckBoxes });
        }
      });

      if (category.length) {
        organizedReelData.push({
          value: _id,
          label: name,
          categories: categoryData,
        });
      }
    });

    setOrganizedReelData(organizedReelData);
  };

  const getCategoryCheckedValues = categoryId => {
    const tempCheckedCategoryValues = [...checkedCategoryValues];
    const tempCheckedContentByCategory = { ...checkedContentByCategory };

    if (tempCheckedCategoryValues.includes(categoryId)) {
      let index = tempCheckedCategoryValues.indexOf(categoryId);

      tempCheckedCategoryValues[categoryId] = [];

      tempCheckedCategoryValues.splice(index, 1);
    } else {
      tempCheckedCategoryValues.push(categoryId);

      const selectedCategoryData = selectedReel.categories.find(
        category => category.id === categoryId
      );

      tempCheckedContentByCategory[
        categoryId
      ] = selectedCategoryData.childrenCheckBoxes.map(({ id }) => id);
    }

    setSelectAll(false);
    setSelectNone(false);
    setCheckedCategoryValues(tempCheckedCategoryValues);
    setCheckedContentByCategory(tempCheckedContentByCategory);
  };

  const getContentCheckedValues = (contentId, categoryId) => {
    const tempCheckedCategoryValues = [...checkedCategoryValues];
    const tempCheckedContentByCategory = { ...checkedContentByCategory };

    if (tempCheckedContentByCategory[categoryId].includes(contentId)) {
      let index = tempCheckedContentByCategory[categoryId].indexOf(contentId);

      tempCheckedContentByCategory[categoryId].splice(index, 1);
    } else {
      tempCheckedContentByCategory[categoryId].push(contentId);
    }

    if (
      childContentCountByCategory[categoryId] ===
      tempCheckedContentByCategory[categoryId].length
    ) {
      tempCheckedCategoryValues.push(categoryId);
    } else {
      let index = tempCheckedCategoryValues.indexOf(categoryId);

      tempCheckedCategoryValues.splice(index, 1);
    }

    setSelectAll(false);
    setSelectNone(false);
    setCheckedCategoryValues(tempCheckedCategoryValues);
    setCheckedContentByCategory(tempCheckedContentByCategory);
  };

  const setAllChecked = () => {
    const tempCheckedContentByCategory = {};
    const tempCheckedCategoryValues = [];

    selectedReel.categories.forEach(category => {
      tempCheckedCategoryValues.push(category.id);

      tempCheckedContentByCategory[
        category.id
      ] = category.childrenCheckBoxes.map(({ id }) => id);
    });

    setCheckedContentByCategory(tempCheckedContentByCategory);
    setCheckedCategoryValues(tempCheckedCategoryValues);
    setSelectNone(false);
  };

  const setAllUnChecked = () => {
    const tempCheckedContentByCategory = { ...checkedContentByCategory };

    Object.keys(tempCheckedContentByCategory).forEach(key => {
      tempCheckedContentByCategory[key] = [];
    });

    setCheckedContentByCategory(tempCheckedContentByCategory);
    setCheckedCategoryValues([]);
    setSelectAll(false);
  };

  const addContent = () => {
    const catergoryData = [];

    let addToReelData = {
      type: reelType,
      reelId: selectedReel.value,
      groupId: groupId,
      fromGroupId: selectedGroup.value,
      category: [],
    };

    for (const [key, value] of Object.entries(checkedContentByCategory)) {
      if (value.length) {
        const vid_doc_ids = [];
        const assessmentIds = [];
        const selectedCategoryData = selectedReel.categories.find(
          category => category.id === key
        );

        selectedCategoryData.childrenCheckBoxes.forEach(childValue => {
          if (value.includes(childValue.id)) {
            if (childValue.type === 'VIDEO' || childValue.type === 'DOCUMENT') {
              vid_doc_ids.push(childValue.id);
            } else {
              assessmentIds.push(childValue.id);
            }
          }
        });

        catergoryData.push({
          _id: key,
          vid_doc_ids,
          assessmentIds,
        });
      }
    }

    addToReelData = {
      ...addToReelData,
      category: catergoryData,
    };

    if (catergoryData.length) {
      setIsAddButtonClicked(true);

      addContentToReel(
        '/reel/add-vid-doc-from-another-reel-to-current-group',
        ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_REQUEST,
        ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_SUCCESS,
        ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_FAIL,
        addToReelData,
        '',
        'PUT',
        null,
        'isReelService'
      );
    }
  };

  const renderDropDownView = () => {
    return (
      <>
        <div className="reel-view--popup-row">
          <label>{t('Group')}</label>
          <div className="reel-view--popup-field">
            <Dropdown
              title="Select Group"
              value={selectedGroup ? selectedGroup.value : ''}
              options={selectGroupOptions}
              onChange={option => setSelectedGroup(option)}
            />
          </div>
        </div>
        <div className="reel-view--popup-row">
          {!allReelsCategoriesFetchDataLoading && (
            <>
              <label>{t('Reel')}</label>
              <div className="reel-view--popup-field">
                <Dropdown
                  title="Select Reel"
                  value={selectedReel ? selectedReel.value : ''}
                  options={organizedReelData.map(reel => {
                    const { value, label } = reel;

                    return { value, label };
                  })}
                  onChange={option =>
                    setSelectedReel(
                      organizedReelData.find(
                        reel => reel.value === option.value
                      )
                    )
                  }
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const renderSelectByCategoryView = () => {
    return (
      <div className="reel-view--popup-row" style={{ maxHeight: '300px' }}>
        <div
          className="reel-view--popup-field"
          style={{
            maxHeight: '250px',
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
        >
          {selectedReel.categories.map((category, index) => {
            return (
              <CheckBoxPanel
                key={index}
                parentCheckBox={category}
                checkedParentValues={checkedCategoryValues}
                checkedChildrenValues={checkedContentByCategory}
                getParentCheckedValues={getCategoryCheckedValues}
                getChildrenCheckedValues={getContentCheckedValues}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="reel-view--popup addreel">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          {isSelectByCatagoryView ? (
            <h3>{t('Select Content')}</h3>
          ) : (
            <h3>
              {t('Add')} {reelType === 'DOCUMENT' ? 'Document' : ''} {t('Reel')}
            </h3>
          )}
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          {isSelectByCatagoryView
            ? renderSelectByCategoryView()
            : renderDropDownView()}
          <div className="reel-view--popup-row">
            {isSelectByCatagoryView ? (
              <>
                <input
                  type="checkbox"
                  className="form-input--checkbox"
                  checked={selectAll}
                  onChange={() => setSelectAll(!selectAll)}
                />
                <label
                  onClick={() => {
                    setSelectAll(!selectAll);
                    setAllChecked();
                  }}
                >
                  {t('Select All')}
                </label>
                <input
                  type="checkbox"
                  className="form-input--checkbox"
                  checked={selectNone}
                  onChange={() => setSelectNone(!selectNone)}
                />
                <label
                  onClick={() => {
                    setSelectNone(!selectNone);
                    setAllUnChecked();
                  }}
                >
                  {t('Select None')}
                </label>
                <button
                  className={`
                      btn btn--primary
                      ${
                        addVideoDocumentFromOtherReelLoading &&
                        isAddButtonClicked
                          ? 'btn--loader'
                          : ''
                      }
                    `}
                  onClick={() => addContent()}
                  disabled={false}
                >
                  {t('Add')}
                </button>
              </>
            ) : (
              <button
                className={`
                    btn btn--primary
                    ${allReelsCategoriesFetchDataLoading ? 'btn--loader' : ''}
                  `}
                disabled={!selectedReel}
                onClick={() => setIsSelectByCatagoryView(true)}
              >
                {t('Next')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import { API, Auth } from 'aws-amplify';
import qs from 'querystring';
import { UPDATE_FILE_UPLOADING_PERCENTAGE } from '../redux/actionTypes/video';

const apiName = 'execute-api';

const getAccessToken = async () =>
  Auth.currentAuthenticatedUser()
    .then(user => user.getSignInUserSession().getAccessToken().getJwtToken())
    .catch(e => console.error(e));

export const get = async (url, token, serviceType, responseType) => {
  const AccessToken = token ? await getAccessToken() : '';

  let customResponseType = 'json';

  if (responseType) {
    customResponseType = responseType;
  }

  const config = {
    headers: {
      AccessToken,
      'Content-Type': 'application/json',
    },
    response: true,
    responseType: customResponseType,
  };

  const path = `/${serviceType}${url}`;

  return API.get(apiName, path, config)
    .then(res => res)
    .catch(error => error);
};

export const post = async (
  url,
  token,
  data,
  isEnCoded,
  serviceType,
  dispatch
) => {
  const AccessToken = await getAccessToken();

  const config = {
    headers: {
      AccessToken,
      'Content-Type': isEnCoded
        ? 'application/x-www-form-urlencoded'
        : 'application/json',
    },
    onUploadProgress: progress => {
      const { loaded, total } = progress;

      const percentageProgress = Math.floor((loaded / total) * 100);
      dispatch({
        type: UPDATE_FILE_UPLOADING_PERCENTAGE,
        payload: percentageProgress,
      });
    },
    response: true,
    body: isEnCoded ? qs.stringify(data) : data,
  };

  const path = `/${serviceType}${url}`;

  return API.post(apiName, path, config)
    .then(res => res)
    .catch(error => error.response);
};

export const put = async (url, token, data, isEnCoded, serviceType) => {
  const AccessToken = await getAccessToken();

  const config = {
    headers: {
      AccessToken,
      'Content-Type': isEnCoded
        ? 'application/x-www-form-urlencoded'
        : 'application/json',
    },
    response: true,
    body: isEnCoded ? qs.stringify(data) : data,
  };

  const path = `/${serviceType}${url}`;

  return API.put(apiName, path, config)
    .then(res => res)
    .catch(error => error.response);
};

export const deleteRequest = async (url, token, data, serviceType) => {
  const AccessToken = await getAccessToken();

  const config = {
    headers: {
      AccessToken,
      'Content-Type': 'application/json',
    },
    response: true,
    body: data,
  };

  const path = `/${serviceType}${url}`;

  return API.del(apiName, path, config)
    .then(res => res)
    .catch(error => error.response);
};

import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useApi from '../../Hooks/useApi';
import {
  SELF_SIGNUP_INITIAL,
  SELF_SIGNUP_REQUEST,
  SELF_SIGNUP_SUCCESS,
  SELF_SIGNUP_FAIL,
} from '../../redux/actionTypes/createUser';
import messageConfigs from '../../Helper/PopupMessageConfig';
import useFileUpload from '../../Hooks/useFileUpload';
import MandatoryMark from '../../Components/validation/MandatoryMark';
import useFetchDataApi from '../../Hooks/useFetchDataApi';
import {
  CheckIsValidEmailAddress,
  CheckIsValidName,
  CheckIsValidUsername,
} from '../../Helper/SystemManager';

import SignupDefaultProfileImage from '../../assets/images/signup-default-profile.png';

export default function SignUpForm({ history }) {
  const { t } = useTranslation();
  const unassigned = [
    {
      label: t('UCFB Student'),
      value: '-1',
    },
  ];

  const [isSignupConfirmationModalOpen, setIsSignupConfirmationModalOpen] =
    useState(null);
  const [isValidEmail, setsIsValidEmail] = useState(true);
  const [isValidUsername, setsIsValidUsername] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [profileImageFile, setProfileImageFile] = useState('');

  const [signupUserData, setSignupUserData] = useState({
    preferredUsername: '',
    email: '',
    name: '',
    type: 'SELF_SIGNUP_USER',
    comment: '',
    divisions: ['-1'],
    profilePicUrl: {
      bucketName: '',
      fileKey: '',
    },
  });

  const { preferredUsername, comment, divisions, email, name, profilePicUrl } =
    signupUserData;

  const {
    selfSignupRequestLoading,
    selfSignupRequestSuccessData,
    selfSignupRequestFail,
  } = useSelector(state => state?.user);
  const { signupBackgroundUrl, emailLabel, nameLabel, userNameLabel } =
    useSelector(state => state?.auth?.themeInfo);
  const { selfAssignedEnabledGroups } = useSelector(state => state?.group);
  const { s3BucketData } = useSelector(state => state?.auth);

  const [signupRequest] = useApi();
  const [getSelfAssignedGroups] = useFetchDataApi();
  const [getS3bucketData] = useFetchDataApi();
  const dispatch = useDispatch();

  const [uploadProfileImage, uploadedProfileImageUrl] = useFileUpload();

  useEffect(() => {
    getSelfAssignedGroups({ type: 'SELF_ASSIGNED_GROUPS' });
    getS3bucketData({ type: 'GET_S3_BUCKET_NAME' });
  }, []);

  useEffect(() => {
    if (s3BucketData) {
      setSignupUserData(signupUserData => ({
        ...signupUserData,
        profilePicUrl: {
          bucketName: s3BucketData.bucketName,
          fileKey: '',
        },
      }));
    }
  }, [s3BucketData]);

  useEffect(() => {
    if (profileImageFile) {
      uploadProfileImage(
        profileImageFile,
        profilePicUrl.bucketName,
        profilePicUrl.fileKey
      );
    }
  }, [profileImageFile]);

  useEffect(() => {
    if (uploadedProfileImageUrl) {
      setSignupUserData(signupUserData => ({
        ...signupUserData,
        profilePicUrl: {
          bucketName: s3BucketData?.bucketName || '',
          fileKey: uploadedProfileImageUrl.split('com')[1],
        },
      }));
    }
  }, [uploadedProfileImageUrl]);

  useEffect(() => {
    if (selfSignupRequestSuccessData) {
      setIsSignupConfirmationModalOpen({
        isSuccess: true,
        message: messageConfigs.success.user.SELF_SIGNUP,
      });
      dispatch({ type: SELF_SIGNUP_INITIAL });
    }
  }, [selfSignupRequestSuccessData]);

  useEffect(() => {
    if (selfSignupRequestFail) {
      setIsSignupConfirmationModalOpen({
        isSuccess: false,
        message:
          (selfSignupRequestFail.length && selfSignupRequestFail) ||
          messageConfigs.error.COMMON,
      });
      dispatch({ type: SELF_SIGNUP_INITIAL });
    }
  }, [selfSignupRequestFail]);

  const handleUserData = (value, property) => {
    if (property === 'preferredUsername') {
      setsIsValidUsername(CheckIsValidUsername(value));
    }

    if (property === 'email') {
      setsIsValidEmail(CheckIsValidEmailAddress(value));
    }

    if (property === 'name') {
      setIsValidName(CheckIsValidName(value));
    }

    setSignupUserData(signupUserData => ({
      ...signupUserData,
      [property]: value,
    }));
  };

  const handleCreateUserRequest = () => {
    if (!preferredUsername) {
      setsIsValidUsername(false);
      return;
    }

    if (!email) {
      setsIsValidEmail(false);
      return;
    }

    if (!name) {
      setIsValidName(false);
      return;
    }

    const postData = {
      ...signupUserData,
    };

    if (!profileImageFile || !s3BucketData) {
      postData.profilePicUrl = null;
    }

    if (divisions[0] === '-1') {
      postData.divisions = [];
    }

    if (isValidEmail && isValidName && isValidUsername) {
      signupRequest(
        '/external-users',
        SELF_SIGNUP_REQUEST,
        SELF_SIGNUP_SUCCESS,
        SELF_SIGNUP_FAIL,
        postData,
        history,
        'POST',
        null,
        'isUserService'
      );
    }
  };

  const renderSignupConfirmationModal = () => {
    return (
      <>
        <div className="signup-form__form-header">
          <h3>
            {isSignupConfirmationModalOpen.isSuccess ? 'Success' : 'Error'}
          </h3>
          {!isSignupConfirmationModalOpen.isSuccess && (
            <a
              className="btn-close closemodale"
              aria-hidden="true"
              onClick={() => setIsSignupConfirmationModalOpen(null)}
            >
              &times;
            </a>
          )}
        </div>
        <div className="signup-form__form-body">
          <div className="signup-form__form-row">
            <label>{isSignupConfirmationModalOpen.message}</label>
          </div>
          <div className="signup-form__form-row">
            <button
              className="btn btn--primary signupbutton"
              onClick={
                isSignupConfirmationModalOpen.isSuccess
                  ? () => {
                      setIsSignupConfirmationModalOpen(null);
                      history.push('/');
                    }
                  : () => setIsSignupConfirmationModalOpen(null)
              }
            >
              {isSignupConfirmationModalOpen.isSuccess ? 'Okay' : 'Close'}
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderSignupForm = () => {
    return (
      <>
        <div className="signup-form__form-header">
          <h3>{t('Sign Up')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => {
              setIsSignupConfirmationModalOpen(null);
              history.push('/');
            }}
          >
            &times;
          </a>
        </div>
        <div className="signup-form__form-body">
          <div className="signup-form__form-row userimage">
            <div className="create-user--uploadphoto">
              <div className="upload-btn-wrapper">
                <img
                  src={
                    profileImageFile
                      ? URL.createObjectURL(profileImageFile)
                      : SignupDefaultProfileImage
                  }
                  alt="signup-default-image"
                />
              </div>
            </div>
          </div>
          <div className="signup-form__form-row uploadsection">
            <label>{t('Upload Profile Picture')}</label>
            <input
              type="file"
              id="upload-preview-img"
              name="upload-preview-img"
              placeholder={t('Image Title')}
              accept="image/*"
              onChange={event => setProfileImageFile(event?.target?.files?.[0])}
            />
          </div>
          <div className="signup-form__form-row">
            <label>
              {t(userNameLabel)} <MandatoryMark />
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={preferredUsername}
              onChange={event =>
                handleUserData(event.target.value, 'preferredUsername')
              }
            />
            {!isValidUsername ? (
              <span className="error-message">
                {messageConfigs.error.SELF_SIGNUP.INVALID_USER_NAME}
              </span>
            ) : null}
          </div>
          <div className="signup-form__form-row">
            <label>
              {t(emailLabel)} <MandatoryMark />
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={event => handleUserData(event.target.value, 'email')}
            />
            {!isValidEmail ? (
              <span className="error-message">
                {messageConfigs.error.SELF_SIGNUP.INVALID_EMAIL}
              </span>
            ) : null}
          </div>
          <div className="signup-form__form-row">
            <label>
              {t(nameLabel)} <MandatoryMark />
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              value={name}
              onChange={event => handleUserData(event.target.value, 'name')}
            />
            {!isValidName ? (
              <span className="error-message">
                {messageConfigs.error.SELF_SIGNUP.INVALID_NAME}
              </span>
            ) : null}
          </div>
          <div className="signup-form__form-row">
            <label>{t('Select Category')} <MandatoryMark /></label>
            <Dropdown
              title="SignupGroupsSelect"
              value={divisions[0]}
              options={[
                ...unassigned,
                ...selfAssignedEnabledGroups.map(({ _id, title }) => ({
                  label: title,
                  value: _id,
                })),
              ]}
              onChange={({ value }) =>
                setSignupUserData(signupUserData => ({
                  ...signupUserData,
                  divisions: [value],
                }))
              }
            />
          </div>
          <div className="signup-form__form-row">
            <label>{t('Additional Categories of Interest')}</label>
            <input
              type="text"
              id="signup-comment"
              name="signup-comment"
              value={comment}
              onChange={event => handleUserData(event.target.value, 'comment')}
            />
          </div>
          <div className="signup-form__form-row">
            {selfSignupRequestLoading ? (
              <button className="btn btn--primary btn--loader"></button>
            ) : (
              <button
                className="btn btn--primary signupbutton"
                onClick={handleCreateUserRequest}
                disabled={!isValidEmail || !isValidUsername || !isValidName}
              >
                {t('Send Request')}
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className="signup-form"
      style={{
        backgroundImage: `url(${signupBackgroundUrl})`,
      }}
    >
      <div className="container">
        <div
          className={`signup-form__wrapper ${
            isSignupConfirmationModalOpen ? 'error-form' : ''
          }`}
        >
          <div className="signup-form__form form">
            {isSignupConfirmationModalOpen
              ? renderSignupConfirmationModal()
              : renderSignupForm()}
          </div>
        </div>
      </div>
    </div>
  );
}

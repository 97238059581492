import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import pause from '../../assets/images/svg-images/assessment-pause.svg';
import {
  CHANGE_LIKES_VIDEOS,
  CHANGE_WATCH_LATER_VIDEOS,
  ADD_PLAYING_INFO,
  ADD_SMOOTH_TRANSITION_MODE,
} from '../../redux/action/actionTypes';
import { CustomizePostition } from '../../Helper/CustomizePositioning';

const VideoPreview = ({
  videoInfoData,
  closeVideoInfoBox,
  videoInfoActiveClass,
  videoInfoAnimationClass,
  addToWatchLater,
  removeWatchLater,
  addToFavorite,
  removeFavorite,
}) => {
  const { t } = useTranslation();
  const {
    previewVideoUrl,
    previewImgUrl,
    title,
    isPublished,
    _id = null,
    videoId = null,
    dueDate,
    isFavourite,
    isWatchLater,
    author = null,
    descritption = null,
  } = videoInfoData;
  console.log(videoInfoData, 'videoInfoData');
  let previewPlayerElement = useRef(null);
  let videoInfoElement = useRef(null);
  const [player, setPlayer] = useState(null);
  const [isPlay, setIsPlay] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [isAudioOn, setIsAudioOn] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAdminView } = useSelector(state => state.auth);

  useEffect(() => {
    setTimeout(() => {
      setIsPlay(true);
      setIsAudioOn(true);
    }, 1500);
  }, []);

  const playVideo = () => {
    if (!isPlay) {
      dispatch({
        type: ADD_PLAYING_INFO,
        payload: [
          {
            id: videoInfoData.id,
            title: videoInfoData.title,
            preview: true,
          },
        ],
      });
    } else {
      dispatch({
        type: ADD_PLAYING_INFO,
        payload: [],
      });
    }
    setIsPlay(!isPlay);
  };

  const muteVideoSound = boolean => {
    if (!boolean) {
      setIsAudioOn(false);
    } else {
      setIsAudioOn(true);
    }
  };

  const likeBtnClick = isLike => {
    if (isLike) {
      addToFavorite(videoId || _id);
    } else {
      removeFavorite(videoId || _id);
    }
  };
  const addToMyList = isAddToWatchLater => {
    if (isAddToWatchLater) {
      addToWatchLater(videoId || _id);
    } else {
      removeWatchLater(videoId || _id);
    }
  };
  // useEffect(() => {
  //   CustomizePostition(videoInfoData.id);
  // }, [videoInfoData.id]);

  const handleTheatreMode = () => {
    dispatch({
      type: ADD_SMOOTH_TRANSITION_MODE,
    });
    setTimeout(() => {
      history.push(`/theatre/${videoId || _id}`);
    }, 2000);
  };
  return (
    <div
      className={`video-info ${videoInfoAnimationClass} ${videoInfoActiveClass}`}
      ref={videoInfoElement}
      id={videoInfoData.id}
    >
      <div className="video-info__close-icon" onClick={closeVideoInfoBox}>
        <svg className="icon icon--red">
          <use
            xlinkHref={
              require('../../assets/images/sprite/sprite.svg') + '#icon-close'
            }
          ></use>
        </svg>
      </div>
      <div className="video-info__content">
        <div className="video-info__container">
          <div className="video-info__info">
            <div className="video-info__teacher">{author}</div>
            <div
              className="video-info__title"
              style={{ cursor: 'pointer' }}
              onClick={() => handleTheatreMode()}
            >
              {title}
            </div>
            <div className="video-info__synopsis">{descritption}</div>

            <div className="video-info__action">
              <div className="video-info__action--play btn">
                <div className="video-info__action--title">
                  <svg className="icon">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-play-solid'
                      }
                    ></use>
                  </svg>
                  {t('Play')}
                </div>
              </div>
              <div className="video-info__action--my-list btn">
                <div className="video-info__action--title">
                  <svg className="icon">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-plus-solid'
                      }
                    ></use>
                  </svg>
                  {t('Watch Later')}
                </div>
              </div>
              <div className="video-info__action--more-info btn">
                <div className="video-info__action--title">
                  <svg className="icon">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-info-circle-solid'
                      }
                    ></use>
                  </svg>
                  {t('More Info')}
                </div>
              </div>
            </div>

            <div className="video-info__meta-lists">
              <div className="video-info__cast inline-list" label="Starring:">
                <span className="list-label">Starring:</span>
                <span className="list-items">
                  <a href="">Cillian Murphy</a>,<a href="">Sam Neill</a>,
                  <a href="">Helen McCrory</a>
                </span>
              </div>
              <div className="video-info__genres inline-list" label="Genres:">
                <span className="list-label">Genres:</span>
                <span className="list-items">
                  <a href="">British TV Shows</a>,<a href="">TV Dramas</a>,
                  <a href="">Crime TV Shows</a>
                </span>
              </div>
              <div
                className="video-info__tags inline-list"
                label="This show is:"
              >
                <span className="list-label">This show is:</span>
                <span className="list-items">
                  <a href="">Violent</a>
                </span>
              </div>
            </div>

            <div className="player_section__controls">
              <div
                className="player_section__controls--play"
                onClick={() => playVideo()}
              >
                {!isPlay ? (
                  <svg className="icon icon--white">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-play-solid'
                      }
                    ></use>
                  </svg>
                ) : (
                  <img src={pause} alt="pause-icon" className="icon--pause" />
                )}
              </div>
              <div
                className={`player_section__controls--audio-off  ${
                  isAudioOn ? 'hide' : ''
                }`}
                onClick={() => muteVideoSound(true)}
              >
                <svg className="icon icon--white">
                  <use
                    xlinkHref={
                      require('../../assets/images/sprite/sprite.svg') +
                      '#icon-audio-off'
                    }
                  ></use>
                </svg>
              </div>
              <div
                className={`player_section__controls--audio-on ${
                  !isAudioOn ? 'hide' : ''
                }`}
                onClick={() => muteVideoSound(false)}
              >
                <svg className="icon icon--white">
                  <use
                    xlinkHref={
                      require('../../assets/images/sprite/sprite.svg') +
                      '#icon-audio-on'
                    }
                  ></use>
                </svg>
              </div>
              {!isAdminView && !isFavourite && (
                <div
                  className="player_section__controls--thumb-up likeBtn"
                  onClick={() => likeBtnClick(true)}
                >
                  <svg className="icon icon--white">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-thumb-up'
                      }
                    ></use>
                  </svg>
                </div>
              )}
              {!isAdminView && isFavourite && (
                <div
                  className="player_section__controls--thumb-down likeBtn"
                  onClick={() => likeBtnClick(false)}
                >
                  <svg className="icon icon--white">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-thumb-down'
                      }
                    ></use>
                  </svg>
                </div>
              )}
              {!isAdminView && (
                <div className="player_section__controls--myList">
                  <div
                    className={`player_section__controls--myList-add ${
                      !isWatchLater ? '' : 'hide'
                    }`}
                    onClick={() => addToMyList(true)}
                  >
                    <svg className="icon icon--white">
                      <use
                        xlinkHref={
                          require('../../assets/images/sprite/sprite.svg') +
                          '#icon-mylist-add'
                        }
                      ></use>
                    </svg>
                  </div>
                  <div
                    className={`player_section__controls--myList-added ${
                      isWatchLater ? '' : 'hide'
                    }`}
                    onClick={() => addToMyList(false)}
                  >
                    <svg className="icon icon--white">
                      <use
                        xlinkHref={
                          require('../../assets/images/sprite/sprite.svg') +
                          '#icon-mylist-added'
                        }
                      ></use>
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="video-info__video-wrapper"
            style={{
              cursor: 'pointer',
              display: 'inline-block',
              width: '100%',
            }}
            onClick={() => handleTheatreMode()}
          >
            <ReactPlayer
              className="video-info__video-player"
              width="100%"
              height="100%"
              muted={!isAudioOn}
              url={previewVideoUrl}
              playing={isPlay}
              style={{
                display: 'inline-block',
                height: '100%',
              }}
            />
          </div>
        </div>

        <ul className="video-info__nav">
          <li>
            Overview
            <span></span>
          </li>
          <li>
            Episodes
            <span></span>
          </li>
          <li>
            Details
            <span></span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VideoPreview;

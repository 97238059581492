import React from 'react';

const UploadItem = props => {
  const { file = 'test', progress } = props;

  return (
    <div className="wrapperItem">
      <div className="leftSide">
        <div className="progressBar">
          <div style={{ width: `${progress}%` }} />
        </div>
        <label>{file.name}</label>
      </div>
      <span className="percentage">{progress}%</span>
    </div>
  );
};

export default UploadItem;

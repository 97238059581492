import messageConfigs from '../../Helper/PopupMessageConfig';
import {
  CREATE_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_FAIL,
  CREATE_ASSESSMENT_RESET,
  UPDATE_ASSESSMENT_REQUEST,
  UPDATE_ASSESSMENT_SUCCESS,
  UPDATE_ASSESSMENT_FAIL,
  UPDATE_ASSESSMENT_RESET,
  ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_REQUEST,
  ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_SUCCESS,
  ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_FAIL,
  ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_RESET,
  FIND_ASSESSMENT_BY_ID_REQUEST,
  FIND_ASSESSMENT_BY_ID_SUCCESS,
  FIND_ASSESSMENT_BY_ID_FAIL,
  FETCH_ALL_STANDALONE_ASSESSMENTS_REQUEST,
  FETCH_ALL_STANDALONE_ASSESSMENTS_SUCCESS,
  FETCH_ALL_STANDALONE_ASSESSMENTS_FAIL,
  FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_REQUEST,
  FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS,
  FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_FAIL,
  FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_REQUEST,
  FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_SUCCESS,
  FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_FAIL,
  SUSPEND_ASSESSMENT_REQUEST,
  SUSPEND_ASSESSMENT_SUCCESS,
  SUSPEND_ASSESSMENT_FAIL,
  FETCH_CREATE_ASSESSMENT_RELATED_INFO_REQUEST,
  FETCH_CREATE_ASSESSMENT_RELATED_INFO_DATA,
  FETCH_CREATE_ASSESSMENT_RELATED_INFO_FAIL,
  FETCH_MID_END_ASSESSMENTS_BY_GROUP_REQUEST,
  FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA,
  FETCH_MID_END_ASSESSMENTS_BY_GROUP_FAIL,
  DELETE_STANDALONE_ASSESSMENT_REQUEST,
  DELETE_STANDALONE_ASSESSMENT_SUCCESS,
  DELETE_STANDALONE_ASSESSMENT_FAIL,
  DELETE_MID_END_ASSESSMENT_REQUEST,
  DELETE_MID_END_ASSESSMENT_SUCCESS,
  DELETE_MID_END_ASSESSMENT_FAIL,
  SEND_ASSESSMENT_RESULT_REQUEST,
  SEND_ASSESSMENT_RESULT_SUCCESS,
  SEND_ASSESSMENT_RESULT_FAIL,
  REMIND_LATER_REQUEST,
  REMIND_LATER_SUCCESS,
  REMIND_LATER_FAIL,
  FETCH_ASSESSMENT_STATS_BY_GROUP_REQUEST,
  FETCH_ASSESSMENT_STATS_BY_GROUP_SUCCESS,
  FETCH_ASSESSMENT_STATS_BY_GROUP_FAIL,
  FETCH_ASSESSMENT_STATS_BY_GROUP_RESET,
  UPDATE_ASSESSMENT_VIEWS_REQUEST,
  UPDATE_ASSESSMENT_VIEWS_SUCCESS,
  UPDATE_ASSESSMENT_VIEWS_FAIL,
  FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_REQUEST,
  FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_SUCCESS,
  FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_FAIL,
  SET_STATS_CURRENT_ASSESSMENT,
  DOWNLOAD_ASSESSMENT_STATS_SUCCESS,
  DOWNLOAD_ASSESSMENT_STATS_REQUEST,
  DOWNLOAD_ASSESSMENT_STATS_FAIL,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_REQUEST,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_SUCCESS,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_FAIL,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_RESET,
  FETCH_ASSESSMENT_SCORES_REQUEST,
  FETCH_ASSESSMENT_SCORES_SUCCESS,
  FETCH_ASSESSMENT_SCORES_FAIL,
  FETCH_ASSESSMENT_SCORES_RESET,
} from '../actionTypes/assesment';

const assessmentReducer = (
  state = {
    addAssessmentLoading: false,
    addAssessmentSuccess: null,
    addAssessmentError: null,
    updateAssessmentLoading: false,
    updateAssessmentSuccess: null,
    updateAssessmentError: null,
    addAssessmentToCategoryLoading: false,
    addAssessmentToCategorySuccess: null,
    addAssessmentToCategoryError: null,
    findAssessmentLoading: false,
    findAssessmentData: null,
    findAssessmentError: null,
    allStandaloneAssementsFetchDataLoading: false,
    allStandaloneAssementsFetchDataSuccess: null,
    allStandaloneAssementsFetchDataError: null,
    standaloneAssementsByGroupFetchDataLoading: false,
    standaloneAssementsByGroupFetchData: null,
    standaloneAssementsByGroupFetchDataError: null,
    allAssessmentsByPlacementFetchDataLoading: false,
    allAssessmentsByPlacementFetchDataSuccess: null,
    allAssessmentsByPlacementFetchDataError: null,
    suspendAssessmentLoading: false,
    suspendAssessmentSuccess: null,
    suspendAssessmentError: null,
    createAssessmentRelatedInfoloading: false,
    createAssessmentRelatedInfoData: {},
    createAssessmentRelatedInfoFail: false,
    midEndAssementsByGroupFetchDataLoading: false,
    midEndAssementsByGroupFetchData: null,
    midEndAssementsByGroupFetchDataError: null,
    deleteStandaloneAssessmentLoading: false,
    deleteStandaloneAssessmentSuccess: null,
    deleteStandaloneAssessmentError: null,
    deleteMidEndAssessmentLoading: false,
    deleteMidEndAssessmentSuccess: null,
    deleteMidEndAssessmentError: null,
    sendAssessmentResultRequest: false,
    sendAssessmentResultSuccess: null,
    sendAssessmentResultFail: false,
    remindLaterRquest: false,
    remindLaterSuccess: false,
    remindLaterFail: false,
    assessmentStatsByGroupLoading: false,
    assessmentStatsByGroupData: null,
    assessmentStatsByGroupError: null,
    assessmentViewLoading: false,
    updateAssessmentViewLoading: false,
    updateAssessmentViewSuccess: null,
    updateAssessmentViewError: null,
    assignedVideosForMidEndAssessmentLoading: false,
    assignedVideosForMidEndAssessmentData: null,
    assignedVideosForMidEndAssessmentError: null,
    currentStatAssessment: null,
    downloadingStatsLoading: false,
    downloadingStatsError: null,
    downloadingTranscriptLoading: false,
    downloadingTranscriptError: null,
    assessmentScoresLoading: false,
    assessmentScoresData: null,
    assessmentScoresError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        addAssessmentLoading: true,
        addAssessmentSuccess: null,
        addAssessmentError: null,
      };

    case CREATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        addAssessmentLoading: false,
        addAssessmentSuccess: true,
        addAssessmentError: null,
      };

    case CREATE_ASSESSMENT_FAIL:
      return {
        ...state,
        addAssessmentLoading: false,
        addAssessmentSuccess: null,
        addAssessmentError: true,
      };

    case CREATE_ASSESSMENT_RESET:
      return {
        ...state,
        addAssessmentLoading: false,
        addAssessmentSuccess: false,
        addAssessmentError: false,
      };

    case UPDATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        updateAssessmentLoading: true,
        updateAssessmentSuccess: null,
        updateAssessmentError: null,
      };

    case UPDATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        updateAssessmentLoading: false,
        updateAssessmentSuccess: true,
        updateAssessmentError: null,
      };

    case UPDATE_ASSESSMENT_FAIL:
      return {
        ...state,
        updateAssessmentLoading: false,
        updateAssessmentSuccess: null,
        updateAssessmentError: true,
      };

    case UPDATE_ASSESSMENT_RESET:
      return {
        ...state,
        updateAssessmentLoading: false,
        updateAssessmentSuccess: null,
        updateAssessmentError: null,
      };

    case ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_REQUEST:
      return {
        ...state,
        addAssessmentToCategoryLoading: true,
        addAssessmentToCategorySuccess: null,
        addAssessmentToCategoryError: null,
      };

    case ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_SUCCESS:
      return {
        ...state,
        addAssessmentToCategoryLoading: false,
        addAssessmentToCategorySuccess: true,
        addAssessmentToCategoryError: null,
      };

    case ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_RESET:
      return {
        ...state,
        addAssessmentToCategoryLoading: false,
        addAssessmentToCategorySuccess: null,
        addAssessmentToCategoryError: null,
      };

    case ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_FAIL:
      return {
        ...state,
        addAssessmentToCategoryLoading: false,
        addAssessmentToCategorySuccess: null,
        addAssessmentToCategoryError: true,
      };

    case FIND_ASSESSMENT_BY_ID_REQUEST:
      return {
        ...state,
        findAssessmentLoading: true,
        findAssessmentData: null,
        findAssessmentError: null,
      };

    case FIND_ASSESSMENT_BY_ID_SUCCESS:
      return {
        ...state,
        findAssessmentLoading: false,
        findAssessmentData: action?.payload,
        findAssessmentError: null,
      };

    case FIND_ASSESSMENT_BY_ID_FAIL:
      return {
        ...state,
        findAssessmentLoading: false,
        findAssessmentData: null,
        findAssessmentError: true,
      };

    case FETCH_ALL_STANDALONE_ASSESSMENTS_REQUEST:
      return {
        ...state,
        allStandaloneAssementsFetchDataLoading: true,
        allStandaloneAssementsFetchDataSuccess: null,
        allStandaloneAssementsFetchDataError: null,
      };

    case FETCH_ALL_STANDALONE_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        allStandaloneAssementsFetchDataLoading: false,
        allStandaloneAssementsFetchDataSuccess: action.payload,
        allStandaloneAssementsFetchDataError: null,
      };

    case FETCH_ALL_STANDALONE_ASSESSMENTS_FAIL:
      return {
        ...state,
        allStandaloneAssementsFetchDataLoading: false,
        allStandaloneAssementsFetchDataSuccess: null,
        allStandaloneAssementsFetchDataError: true,
      };

    case FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_REQUEST:
      return {
        ...state,
        standaloneAssementsByGroupFetchDataLoading: true,
        standaloneAssementsByGroupFetchData: null,
        standaloneAssementsByGroupFetchDataError: null,
      };

    case FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS:
      return {
        ...state,
        standaloneAssementsByGroupFetchDataLoading: false,
        standaloneAssementsByGroupFetchData: action.payload,
        standaloneAssementsByGroupFetchDataError: null,
      };

    case FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_FAIL:
      return {
        ...state,
        standaloneAssementsByGroupFetchDataLoading: false,
        standaloneAssementsByGroupFetchData: null,
        standaloneAssementsByGroupFetchDataError: true,
      };

    case FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_REQUEST:
      return {
        ...state,
        allAssessmentsByPlacementFetchDataLoading: true,
        allAssessmentsByPlacementFetchDataSuccess: null,
        allAssessmentsByPlacementFetchDataError: null,
      };

    case FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_SUCCESS:
      return {
        ...state,
        allAssessmentsByPlacementFetchDataLoading: false,
        allAssessmentsByPlacementFetchDataSuccess: action.payload,
        allAssessmentsByPlacementFetchDataError: null,
      };

    case FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_FAIL:
      return {
        ...state,
        allAssessmentsByPlacementFetchDataLoading: false,
        allAssessmentsByPlacementFetchDataSuccess: null,
        standaloneAssementsByGroupFetchDataError: true,
      };

    case SUSPEND_ASSESSMENT_REQUEST:
      return {
        ...state,
        suspendAssessmentLoading: true,
        suspendAssessmentSuccess: null,
        suspendAssessmentError: null,
      };

    case SUSPEND_ASSESSMENT_SUCCESS:
      return {
        ...state,
        suspendAssessmentLoading: false,
        suspendAssessmentSuccess: action.payload,
        suspendAssessmentError: null,
      };

    case SUSPEND_ASSESSMENT_FAIL:
      return {
        ...state,
        suspendAssessmentLoading: false,
        suspendAssessmentSuccess: null,
        suspendAssessmentError: true,
      };
    case FETCH_CREATE_ASSESSMENT_RELATED_INFO_REQUEST:
      return {
        ...state,
        createAssessmentRelatedInfoloading: true,
        createAssessmentRelatedInfoData: {},
        createAssessmentRelatedInfoFail: false,
      };

    case FETCH_CREATE_ASSESSMENT_RELATED_INFO_DATA:
      return {
        ...state,
        createAssessmentRelatedInfoloading: false,
        createAssessmentRelatedInfoData: action?.payload,
        createAssessmentRelatedInfoFail: false,
      };

    case FETCH_CREATE_ASSESSMENT_RELATED_INFO_FAIL:
      return {
        ...state,
        createAssessmentRelatedInfoloading: false,
        createAssessmentRelatedInfoData: {},
        createAssessmentRelatedInfoFail: action.payload,
      };

    case FETCH_MID_END_ASSESSMENTS_BY_GROUP_REQUEST:
      return {
        ...state,
        midEndAssementsByGroupFetchDataLoading: true,
        midEndAssementsByGroupFetchData: null,
        midEndAssementsByGroupFetchDataError: null,
      };

    case FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA:
      return {
        ...state,
        midEndAssementsByGroupFetchDataLoading: false,
        midEndAssementsByGroupFetchData: action.payload,
        midEndAssementsByGroupFetchDataError: null,
      };

    case FETCH_MID_END_ASSESSMENTS_BY_GROUP_FAIL:
      return {
        ...state,
        midEndAssementsByGroupFetchDataLoading: false,
        midEndAssementsByGroupFetchData: null,
        midEndAssementsByGroupFetchDataError: true,
      };

    case DELETE_STANDALONE_ASSESSMENT_REQUEST:
      return {
        ...state,
        deleteStandaloneAssessmentLoading: true,
        deleteStandaloneAssessmentSuccess: null,
        deleteStandaloneAssessmentError: null,
      };

    case DELETE_STANDALONE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        deleteStandaloneAssessmentLoading: false,
        deleteStandaloneAssessmentSuccess: true,
        deleteStandaloneAssessmentError: null,
      };

    case DELETE_STANDALONE_ASSESSMENT_FAIL:
      return {
        ...state,
        deleteStandaloneAssessmentLoading: false,
        deleteStandaloneAssessmentSuccess: null,
        deleteStandaloneAssessmentError: true,
      };

    case DELETE_MID_END_ASSESSMENT_REQUEST:
      return {
        ...state,
        deleteMidEndAssessmentLoading: true,
        deleteMidEndAssessmentSuccess: null,
        deleteMidEndAssessmentError: null,
      };

    case DELETE_MID_END_ASSESSMENT_SUCCESS:
      return {
        ...state,
        deleteMidEndAssessmentLoading: false,
        deleteMidEndAssessmentSuccess: true,
        deleteMidEndAssessmentError: null,
      };

    case DELETE_MID_END_ASSESSMENT_FAIL:
      return {
        ...state,
        deleteMidEndAssessmentLoading: false,
        deleteMidEndAssessmentSuccess: null,
        deleteMidEndAssessmentError: true,
      };

    case SEND_ASSESSMENT_RESULT_REQUEST:
      return {
        ...state,
        sendAssessmentResultRequest: true,
        sendAssessmentResultSuccess: null,
        sendAssessmentResultFail: false,
      };

    case SEND_ASSESSMENT_RESULT_SUCCESS:
      return {
        ...state,
        sendAssessmentResultRequest: false,
        sendAssessmentResultSuccess: action?.payload,
        sendAssessmentResultFail: false,
      };

    case SEND_ASSESSMENT_RESULT_FAIL:
      return {
        ...state,
        sendAssessmentResultRequest: false,
        sendAssessmentResultSuccess: null,
        sendAssessmentResultFail: true,
      };

    case REMIND_LATER_REQUEST:
      return {
        ...state,
        remindLaterRquest: true,
        remindLaterSuccess: false,
        remindLaterFail: false,
      };

    case REMIND_LATER_SUCCESS:
      return {
        ...state,
        remindLaterRquest: false,
        remindLaterSuccess: true,
        remindLaterFail: false,
      };

    case REMIND_LATER_FAIL:
      return {
        ...state,
        remindLaterRquest: false,
        remindLaterSuccess: false,
        remindLaterFail: true,
      };

    case FETCH_ASSESSMENT_STATS_BY_GROUP_REQUEST:
      return {
        ...state,
        assessmentStatsByGroupLoading: true,
        assessmentStatsByGroupData: null,
        assessmentStatsByGroupError: null,
      };

    case FETCH_ASSESSMENT_STATS_BY_GROUP_SUCCESS:
      return {
        ...state,
        assessmentStatsByGroupLoading: false,
        assessmentStatsByGroupData: action.payload,
        assessmentStatsByGroupError: null,
      };

    case FETCH_ASSESSMENT_STATS_BY_GROUP_FAIL:
      return {
        ...state,
        assessmentStatsByGroupLoading: false,
        assessmentStatsByGroupData: null,
        assessmentStatsByGroupError: true,
      };

    case FETCH_ASSESSMENT_STATS_BY_GROUP_RESET:
      return {
        ...state,
        assessmentStatsByGroupLoading: false,
        assessmentStatsByGroupData: null,
        assessmentStatsByGroupError: null,
      };

    case UPDATE_ASSESSMENT_VIEWS_REQUEST:
      return {
        ...state,
        updateAssessmentViewLoading: true,
        updateAssessmentViewSuccess: null,
        updateAssessmentViewError: null,
      };

    case UPDATE_ASSESSMENT_VIEWS_SUCCESS:
      return {
        ...state,
        updateAssessmentViewLoading: false,
        updateAssessmentViewSuccess: action?.payload,
        updateAssessmentViewError: null,
      };

    case UPDATE_ASSESSMENT_VIEWS_FAIL:
      return {
        ...state,
        updateAssessmentViewLoading: false,
        updateAssessmentViewSuccess: null,
        updateAssessmentViewError: action?.payload,
      };

    case FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_REQUEST:
      return {
        ...state,
        assignedVideosForMidEndAssessmentLoading: true,
        assignedVideosForMidEndAssessmentData: null,
        assignedVideosForMidEndAssessmentError: null,
      };

    case FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        assignedVideosForMidEndAssessmentLoading: false,
        assignedVideosForMidEndAssessmentData: action.payload,
        assignedVideosForMidEndAssessmentError: null,
      };

    case FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_FAIL:
      return {
        ...state,
        assignedVideosForMidEndAssessmentLoading: false,
        assignedVideosForMidEndAssessmentData: null,
        assignedVideosForMidEndAssessmentError: true,
      };

    case DOWNLOAD_ASSESSMENT_STATS_REQUEST:
      return {
        ...state,
        downloadingStatsLoading: true,
        downloadingStatsError: null,
      };

    case DOWNLOAD_ASSESSMENT_STATS_SUCCESS:
      return {
        ...state,
        downloadingStatsLoading: false,
        downloadingStatsError: null,
      };

    case DOWNLOAD_ASSESSMENT_STATS_FAIL:
      return {
        ...state,
        downloadingStatsLoading: false,
        downloadingStatsError: {
          message: messageConfigs.error.STATS.ASSESSMENT,
          success: false,
        },
      };

    case DOWNLOAD_ASSESSMENT_TRANSCRIPT_REQUEST:
      return {
        ...state,
        downloadingTranscriptLoading: true,
        downloadingTranscriptError: null,
      };

    case DOWNLOAD_ASSESSMENT_TRANSCRIPT_SUCCESS:
      return {
        ...state,
        downloadingTranscriptLoading: false,
        downloadingTranscriptError: null,
      };

    case DOWNLOAD_ASSESSMENT_TRANSCRIPT_FAIL:
      return {
        ...state,
        downloadingTranscriptLoading: false,
        downloadingTranscriptError: {
          message: messageConfigs.error.STATS.TRANSCRIPT,
          success: false,
        },
      };

    case DOWNLOAD_ASSESSMENT_TRANSCRIPT_RESET:
      return {
        ...state,
        downloadingTranscriptLoading: false,
        downloadingTranscriptError: null,
      };

    case SET_STATS_CURRENT_ASSESSMENT:
      return {
        ...state,
        currentStatAssessment: action?.payload,
      };

    case FETCH_ASSESSMENT_SCORES_REQUEST:
      return {
        ...state,
        assessmentScoresLoading: true,
        assessmentScoresData: null,
        assessmentScoresError: null,
      };

    case FETCH_ASSESSMENT_SCORES_SUCCESS:
      return {
        ...state,
        assessmentScoresLoading: false,
        assessmentScoresData: action.payload,
        assessmentScoresError: null,
      };

    case FETCH_ASSESSMENT_SCORES_FAIL:
      return {
        ...state,
        assessmentScoresLoading: false,
        assessmentScoresData: null,
        assessmentScoresError: true,
      };

    case FETCH_ASSESSMENT_SCORES_RESET:
      return {
        ...state,
        assessmentScoresLoading: false,
        assessmentScoresData: null,
        assessmentScoresError: null,
      };

    default:
      return state;
  }
};

export default assessmentReducer;

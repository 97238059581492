import React from 'react';

const DocumentViewIframe = ({ documentUrl }) => {
  const iFrameUrl =
    'https://docs.google.com/gview?url=' + documentUrl + '&embedded=true';

  return (
    <div>
      <iframe
        src={iFrameUrl}
        width="100%"
        height="600"
        frameBorder="0"
        sandbox="allow-scripts allow-same-origin"
      ></iframe>
    </div>
  );
};

export default DocumentViewIframe;

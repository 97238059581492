import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'react-multi-select-component';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorTypes } from '../../../../Common/ErrorTypes';
import { systemConfig } from '../../../../Common/SystemConstConfigs';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import BreadCrumbView from '../../../../Components/BreadCrumbView';
import Loader from '../../../../Components/Loader/loader';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import ProceedMessageModal from '../../../../Components/Modal/ProceedMessageModal';
import UploadItem from '../../../../Components/UploadProgressBar/UploadProgressBar';
import MandatoryMark from '../../../../Components/validation/MandatoryMark';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import {
  capitalizeFirstLetter,
  getDifferentTwoObjectArrays,
} from '../../../../Helper/SystemManager';
import { isValidUrl } from '../../../../Helper/ValidationsManager';
import useApi from '../../../../Hooks/useApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import useFileUpload from '../../../../Hooks/useFileUpload';
import usePrivilegesApi from '../../../../Hooks/usePrivilegesApi';
import useReporting from '../../../../Hooks/useReporting';
import {
  CHANGE_USER_STATUS_FAIL,
  CHANGE_USER_STATUS_REQUEST,
  CHANGE_USER_STATUS_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DOWNLOAD_USER_REPORT_SUCCESS,
  FETCH_USER_BY_ID_RESET,
  REMOVE_USER_FROM_GROUP_FAIL,
  REMOVE_USER_FROM_GROUP_REQUEST,
  REMOVE_USER_FROM_GROUP_SUCCESS,
  REMOVE_WELCOME_VIDEO_FAIL,
  REMOVE_WELCOME_VIDEO_REQUEST,
  REMOVE_WELCOME_VIDEO_SUCCESS,
  RESET_DELETE_USER_MODAL_DETAILS,
  RESET_PASSWORD_LINK_FAIL,
  RESET_PASSWORD_LINK_REQUEST,
  RESET_PASSWORD_LINK_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../../../../redux/actionTypes/createUser';
import AssignUserToGroupModal from '../../Group/Modal/assignUserToGroupModal';

const { REACT_APP_IS_FEDETRATE_LOGIN_AVAILABLE } = process.env;

export default function CreateUser({ history, match }) {
  const { t } = useTranslation();
  const canEditUserFromGroup = usePrivilegesApi(
    PrivilegeTypes.USER.EDIT_USER_FROM_GROUP
  );
  const canEdit = usePrivilegesApi(PrivilegeTypes.USER.EDIT_USER);
  // const canViewActivityLog = usePrivilegesApi(PrivilegeTypes.STATISTICS.ACCESS_USER_STATISTICS);
  const canViewActivityLog = false;
  const canCreate = usePrivilegesApi(PrivilegeTypes.USER.CREATE_USER);
  const suspendUserFromGroup = usePrivilegesApi(
    PrivilegeTypes.USER.SUSPEND_USER_FROM_GROUP
  );
  const suspendUser = usePrivilegesApi(PrivilegeTypes.USER.SUSPEND_USER);

  const [userData, setUserData] = useState({
    userName: '',
    userEmail: '',
    profileImage: '',
    designation: '',
    existingWelcomeVideoUrl: '',
    existWelcomeVideoTitle: '',
    welcomeVideo: null,
    welcomeVideoTitle: '',
    welcomeVideoURL: '',
    isNewWelcomeVideo: false,
    isUploadNewVideo: true,
    groupsWithRoles: [],
    selectedUserRoles: [],
    reelItemCounts: [],
    welcomeVideoText: '',
    signupComments: '',
    isFederatedIdentity: false,
  });

  const [existingWelcomeVideos, setExistingWelcomeVideos] = useState([]);
  const [videoFileURL, setVideoFileURL] = useState(null);
  const [imageFileURL, setImageFileURL] = useState(null);
  const [isView, setIsView] = useState(false);
  const [onSubmitClicked, setOnSubmitClicked] = useState(false);
  const [isValidEmail, setsIsValidEmail] = useState(true);
  const [isValidDesignation, setsIsValidDesignation] = useState(true);
  const [isValidUsername, setsIsValidUsername] = useState(true);
  const [isUserNameExist, setIsUserNameExist] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [activeStatus, setActiveStatus] = useState(true);
  const [breadCrumbTitle, setBreadCrumbTitle] = useState('Create User');
  const [userRolesOption, setUserRolesOption] = useState([]);
  const [previousUserRole, setPreviousUserRole] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [welcomVideoTextErrorMessage, setWelcomVideoTextErrorMessage] =
    useState(null);
  const [isAssignGroupToUserModalShow, setIsAssignGroupToUserModalShow] =
    useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [isResetPasswordSuccessModalOpen, setIsResetPasswordSuccessModalOpen] =
    useState(null);

  const dispatch = useDispatch();

  const [uploadProfileImage, uploadedProfileImageUrl] = useFileUpload();
  const [
    uploadWelcomeVideo,
    uploadedWelcomeVideoUrl,
    welcomeVideoUploadProgress,
  ] = useFileUpload();

  useEffect(() => {
    if (
      welcomeVideoUploadProgress === 0 ||
      welcomeVideoUploadProgress === 100
    ) {
      setIsFileUploading(false);
    } else if (!isFileUploading) {
      setIsFileUploading(true);
    }
  }, [welcomeVideoUploadProgress]);

  const welcomeVideoFetchData =
    useSelector(state => state?.user?.welcomeVideoFetchData) || [];
  const {
    userInfo,
    allUsersDataForValidation,
    changeUserStatusData,
    createUserSuccess,
    createUserLoading,
    createUserError,
    userRolesFetchData,
    updateUserRequestSuccess,
    updateUserLoading,
    updateUserFail,
    changeUserStatusLoading,
    resetPasswordLinkRequestLoading,
    resetPasswordLinkRequestData,
    resetPasswordLinkRequestFail,
    deleteUserLoading,
    deleteUserMessage,
    removeUserFromGroupLoading,
    userFetchingRequest,
    removeWelcomeVideoLoading,
    removeWelcomeVideoSuccess,
    removeWelcomeVideoError,
    userReportDownloadLoading,
    userReportDownloadError,
  } = useSelector(state => state?.user);
  const { groupDetailsData, parentGroupRolesData } = useSelector(
    state => state?.group
  );
  const { emailLabel, nameLabel, userNameLabel, defaultProfileImageUrl } =
    useSelector(state => state?.auth?.themeInfo);
  const { groupName = null, parentGroupName = null } = groupDetailsData || {};

  const isFederatedLoginAvailable = REACT_APP_IS_FEDETRATE_LOGIN_AVAILABLE;
  const { userId, isEdit } = match.params;
  const groupId = match.params.groupId;

  const [fetchWelcomeVideos] = useFetchDataApi();
  const [createUserRequest] = useApi('/search-users');
  const [deleteUserRequest] = useApi('/search-users');
  const [removeUserRequest] = useApi(`/group-dashboard/${groupId}/users`);
  const [removeWelcomeVideo] = useApi();
  const [changeUserStatusRequest] = useApi();
  const [resetPasswordLinkRequest] = useApi();
  const [updateUserRequest] = useApi();
  const [fetchUser] = useFetchDataApi();
  const [fetchUsers] = useFetchDataApi();
  const [fetchUserRoles] = useFetchDataApi();
  const [fetchParentGroupRoles] = useFetchDataApi();
  const [downloadUserReport] = useReporting();

  useEffect(() => {
    fetchWelcomeVideos({ type: 'FETCH_WELCOME_VIDEOS' });
    fetchUserRoles({ type: 'FETCH_USER_ROLES' });

    return () => {
      dispatch({ type: FETCH_USER_BY_ID_RESET });
    };
  }, []);

  useEffect(() => {
    if (userId) {
      if (isEdit === 'edit') {
        setIsView(false);
      } else {
        setIsView(true);
      }
    }
  }, [userId, isEdit]);

  useEffect(() => {
    if (updateUserRequestSuccess) {
      dispatch({ type: UPDATE_USER_SUCCESS, payload: null });
      setIsView(true);

      if (userId) {
        getUserData();
      }
    }
  }, [updateUserRequestSuccess]);

  const getUserData = () => {
    fetchUser({ type: 'FETCH_USER_BY_ID', objectId: userId, groupId });
  };

  useEffect(() => {
    if (userId) {
      getUserData();
    }

    fetchUsers({ type: 'FETCH_ALL_USERS_FOR_VALIDATION' });
  }, []);

  useEffect(() => {
    if (groupId && userId) {
      fetchParentGroupRoles({
        groupId,
        objectId: userId,
        type: 'FETCH_PARENT_GROUP_ROLES',
      });
    }
  }, [groupId, userId]);

  useEffect(() => {
    if (userRolesFetchData) {
      let parentGroupRoles = [];

      if (parentGroupRolesData) {
        const userRoles = ['Admin', 'Moderator', 'Editor'];

        if (parentGroupRolesData.includes('Admin')) {
          parentGroupRoles = userRoles;
        }
      }

      setUserRolesOption(
        userRolesFetchData
          .filter(
            userRole =>
              userRole.id !== 4 && !parentGroupRoles.includes(userRole.name)
          )
          .map(role => ({
            label: role.name === 'Admin' ? 'Administrator' : role.name,
            value: role.id,
          }))
      );
    }
  }, [parentGroupRolesData, userRolesFetchData]);

  useEffect(() => {
    if (welcomeVideoFetchData) {
      let videoData = welcomeVideoFetchData;

      videoData = videoData.filter(
        (item, index, self) =>
          index === self.findIndex(key => key.url === item.url)
      );

      setExistingWelcomeVideos(
        videoData
          .filter(({ title, url }) => title && url)
          .map(item => ({
            label: item.title,
            value: item.url,
          }))
      );
    }
  }, [welcomeVideoFetchData]);

  useEffect(() => {
    if (createUserSuccess) {
      history.push('/search-users');
      dispatch({ type: CREATE_USER_SUCCESS, payload: null });
    }
  }, [createUserSuccess]);

  useEffect(() => {
    if (changeUserStatusData) {
      setActiveStatus(!activeStatus);
    }
  }, [changeUserStatusData]);

  useEffect(() => {
    if (resetPasswordLinkRequestData) {
      setIsResetPasswordSuccessModalOpen({
        status: true,
        message: `An email containing the Reset Password link was sent to ${userEmail}`,
      });

      return () =>
        dispatch({
          type: RESET_PASSWORD_LINK_SUCCESS,
          payload: null,
        });
    }
  }, [resetPasswordLinkRequestData]);

  useEffect(() => {
    if (resetPasswordLinkRequestFail) {
      setIsResetPasswordSuccessModalOpen({
        status: false,
        message: messageConfigs.error.TRY_LATER,
      });

      return () =>
        dispatch({
          type: RESET_PASSWORD_LINK_SUCCESS,
          payload: null,
        });
    }
  }, [resetPasswordLinkRequestFail]);

  const resetValidationsOnEdit = propertyName => {
    switch (propertyName) {
      case 'userName':
        setsIsValidUsername(true);
        setIsUserNameExist(false);
        break;

      case 'userEmail':
        setsIsValidEmail(true);
        break;

      case 'designation':
        setsIsValidDesignation(true);
        break;

      default:
        break;
    }
  };

  const handleUserData = (value, property) => {
    resetValidationsOnEdit(property);

    const tempUserData = { ...userData };
    tempUserData[property] = value;
    setUserData(tempUserData);
  };

  const handleVideoUpload = event => {
    if (event?.target?.files?.length) {
      setVideoFileURL(URL.createObjectURL(event.target.files[0]));
      uploadWelcomeVideo(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (uploadedWelcomeVideoUrl) {
      handleUserData(uploadedWelcomeVideoUrl, 'welcomeVideo');
    }
  }, [uploadedWelcomeVideoUrl]);
  useEffect(() => {
    if (uploadedProfileImageUrl) {
      handleUserData(uploadedProfileImageUrl, 'profileImage');
    }
  }, [uploadedProfileImageUrl]);

  const handleImageUpload = event => {
    const imageFile = event.target.files[0];

    let img;

    if (imageFile) {
      img = new Image();
      img.src = URL.createObjectURL(imageFile);
      img.onload = () => {
        if (img.width !== img.height) {
          alert(
            t(
              'Please make sure the image is in the ratio of 1:1 (eg:- 100px x 100px).'
            )
          );
          return;
        } else if (maxSize <= imageFile.size) {
          alert(t('Please make sure the image is less than 1MB'));
          return;
        } else {
          setImageFileURL(URL.createObjectURL(imageFile));
          uploadProfileImage(imageFile);
        }
      };

      img.onerror = () => {
        alert(t('Please upload an image file'));
        return;
      };
    }

    const maxSize = 1048576;
  };
  const handleUserRoleSelection = selectedRoles => {
    if (selectedRoles.length < 3) {
      if (selectedRoles.length < 2) {
        handleUserData(selectedRoles, 'selectedUserRoles');
      } else if (selectedRoles.filter(role => role.value === 3).length) {
        handleUserData(selectedRoles, 'selectedUserRoles');
      }
    }
  };

  useEffect(() => {
    if (welcomeVideoUploadProgress !== 0) {
      setShowProgressBar(true);
    } else if (showProgressBar) {
      setShowProgressBar(false);
    }
  }, [welcomeVideoUploadProgress]);

  useEffect(() => {
    if (userInfo && userId && userInfo?.userData) {
      const {
        designation,
        email,
        enabled,
        id,
        profilePicUrl,
        preferredUserName,
        username,
        newWelcomeVideo,
        welcomeVideoTitle,
        welcomeVideoUrl,
        roles,
        welcomeVideoText,
        comment,
        isFederatedIdentity,
      } = userInfo.userData;
      const { groupsWithRoles } = userInfo;
      const reelItemCounts = userInfo.reelData || [];

      const currentUserRoles =
        groupsWithRoles && groupsWithRoles.length && groupId
          ? groupsWithRoles
              .find(({ divisionId }) => divisionId === groupId)
              .userData?.map(({ roleName, roleReferenceId }) => ({
                label: roleName === 'Admin' ? 'Administrator' : roleName,
                value: roleReferenceId,
              }))
          : [];

      setPreviousUserRole(currentUserRoles);

      setUserData({
        ...userData,
        id,
        userName: preferredUserName || username,
        userEmail: email,
        profileImage: profilePicUrl,
        designation,
        existingWelcomeVideoUrl: !newWelcomeVideo ? welcomeVideoUrl : '',
        existWelcomeVideoTitle: !newWelcomeVideo ? welcomeVideoTitle : '',
        welcomeVideo: null,
        welcomeVideoTitle: newWelcomeVideo ? welcomeVideoTitle : '',
        welcomeVideoURL: newWelcomeVideo ? welcomeVideoUrl : '',
        isNewWelcomeVideo: newWelcomeVideo,
        groupsWithRoles: userInfo.groupsWithRoles || [],
        selectedUserRoles: currentUserRoles,
        reelItemCounts,
        welcomeVideoText,
        signupComments: comment || '',
        isFederatedIdentity,
      });
      if (newWelcomeVideo) {
        setVideoFileURL(welcomeVideoUrl);
      }

      setActiveStatus(enabled);
      setBreadCrumbTitle(preferredUserName || username);
    }
    if (userInfo) {
      setShowProgressBar(false);
    }
  }, [userId, userInfo]);

  const {
    id,
    userName,
    userEmail,
    profileImage,
    designation,
    existingWelcomeVideoUrl,
    existWelcomeVideoTitle,
    welcomeVideo,
    welcomeVideoTitle,
    welcomeVideoURL,
    isNewWelcomeVideo,
    isUploadNewVideo,
    groupsWithRoles,
    selectedUserRoles,
    reelItemCounts,
    welcomeVideoText,
    signupComments,
    isFederatedIdentity,
  } = userData;
  const CheckIsValidEmailAddress = email => {
    if (id && email === userEmail) {
      return true;
    }

    if (email === '' || (!email.match(systemConfig.emailRegex) && email)) {
      setsIsValidEmail(false);

      return false;
    } else {
      setsIsValidEmail(true);
    }

    let isExist = false;

    if (allUsersDataForValidation) {
      isExist = allUsersDataForValidation.some(user => {
        return (
          id !== user.id &&
          user.email.toLowerCase().trim() === email.toLowerCase().trim()
        );
      });
    }

    if (isExist) {
      setIsEmailExist(true);
      return false;
    } else {
      setIsEmailExist(false);
    }

    return true;
  };

  const CheckIsValidUsername = value => {
    const regex = /^[a-zA-Z0-9]*$/;

    if (value === '' || !value.match(regex)) {
      setsIsValidUsername(false);

      return false;
    } else {
      setsIsValidUsername(true);
    }

    let isExist = false;

    if (allUsersDataForValidation) {
      isExist = allUsersDataForValidation.some(user => {
        return (
          (!id || id !== user.id) &&
          (user.preferredUserName || user.username)?.toLowerCase().trim() ===
            value.toLowerCase().trim()
        );
      });
    }

    setIsUserNameExist(isExist);

    return true;
  };

  const CheckIsValidDesignation = value => {
    const regex = /^([a-zA-Z0-9_-]+\s)*\w+$/;

    if (value === '') {
      setsIsValidDesignation(false);

      return false;
    }

    if (!value.match(regex)) {
      setsIsValidDesignation(false);
      return false;
    }

    return true;
  };

  const handleCreateUserRequest = () => {
    setOnSubmitClicked(true);

    if (
      !CheckIsValidUsername(userName) ||
      !CheckIsValidDesignation(designation) ||
      !CheckIsValidEmailAddress(userEmail) ||
      isUserNameExist ||
      (welcomeVideoTitle === '' && isNewWelcomeVideo && !welcomeVideoURL)
    ) {
      return;
    }

    // validation true
    const formData = new FormData();

    if (!isUserNameExist) {
      createUserRequest(
        `/user/createUser?email=${userEmail}&username=${userName}&designation=${designation}&welcomeVdoTitle=${
          isNewWelcomeVideo ? welcomeVideoTitle : getExistingVideoTitle()
        }${
          isNewWelcomeVideo && welcomeVideoURL
            ? '&newWelcomeVideoUrl=' + (welcomeVideoURL || '')
            : ''
        }${
          !isNewWelcomeVideo
            ? '&existingWelcomeVdoUrl=' + (existingWelcomeVideoUrl || '')
            : ''
        }${profileImage ? '&file=' + profileImage : ''}${
          isNewWelcomeVideo && !welcomeVideoURL
            ? '&welcomeVideo=' + (welcomeVideo || '')
            : ''
        }${welcomeVideoText ? '&welcomeVideoText=' + welcomeVideoText : ''}${
          isFederatedLoginAvailable
            ? '&isFederatedIdentity=' + isFederatedIdentity
            : ''
        }`,
        CREATE_USER_REQUEST,
        CREATE_USER_SUCCESS,
        CREATE_USER_FAIL,
        formData,
        '',
        'POST',
        null,
        'isUserService'
      );
    }
  };

  const handleUpdateUserRequest = () => {
    setOnSubmitClicked(true);

    if (
      !CheckIsValidUsername(userName) ||
      !CheckIsValidDesignation(designation) ||
      !CheckIsValidEmailAddress(userEmail) ||
      isUserNameExist
    ) {
      return;
    }

    const formData = new FormData();

    if (selectedUserRoles?.length) {
      const changedRoles = getDifferentTwoObjectArrays(
        previousUserRole,
        selectedUserRoles,
        'value'
      );

      if (
        changedRoles?.length ||
        selectedUserRoles.length > previousUserRole?.length
      ) {
        const roleInfo = {
          divisionId: groupId,
          userId: [parseInt(userId)],
          roleReferenceWrapper: selectedUserRoles.length
            ? selectedUserRoles.map(({ label, value }) => ({
                roleName: label === 'Administrator' ? 'Admin' : label,
                roleReferenceId: value,
              }))
            : [],
          previousRoleReferenceWrapper: changedRoles.map(
            ({ label, value }) => ({
              roleName: label === 'Administrator' ? 'Admin' : label,
              roleReferenceId: value,
            })
          ),
        };

        formData.append(
          'assignUserToDivisionWrapper',
          new Blob([JSON.stringify(roleInfo)], { type: 'application/json' })
        );
      }
    } else {
      if (groupId) {
        return;
      }
    }

    if (!isUserNameExist) {
      updateUserRequest(
        `/user/createUser?email=${userEmail}${
          id ? `&userId=${id}` : ''
        }&username=${userName}&designation=${designation}&welcomeVdoTitle=${
          isNewWelcomeVideo ? welcomeVideoTitle : getExistingVideoTitle()
        }${
          isNewWelcomeVideo && welcomeVideoURL
            ? '&newWelcomeVideoUrl=' + (welcomeVideoURL || '')
            : ''
        }${
          !isNewWelcomeVideo
            ? '&existingWelcomeVdoUrl=' + (existingWelcomeVideoUrl || '')
            : ''
        }${
          profileImage && isValidUrl(profileImage)
            ? '&file=' + profileImage
            : ''
        }${
          isNewWelcomeVideo && !welcomeVideoURL
            ? '&welcomeVideo=' + (welcomeVideo || '')
            : ''
        }${welcomeVideoText ? '&welcomeVideoText=' + welcomeVideoText : ''}${
          isFederatedLoginAvailable
            ? '&isFederatedIdentity=' + !!isFederatedIdentity
            : ''
        }`,
        UPDATE_USER_REQUEST,
        UPDATE_USER_SUCCESS,
        UPDATE_USER_FAIL,
        formData,
        '',
        'POST',
        null,
        'isUserService'
      );
    }
  };

  const handleDeleteUser = () => {
    deleteUserRequest(
      `/user/deleteUser?userId=${userId}`,
      DELETE_USER_REQUEST,
      DELETE_USER_SUCCESS,
      DELETE_USER_FAIL,
      null,
      history,
      'POST',
      null,
      'isUserService'
    );
  };
  const clearDeleteModalPropsAndClose = () => {
    dispatch({ type: RESET_DELETE_USER_MODAL_DETAILS });
    setIsDeleteConfirmModalOpen(null);
  };

  const handleRemoveUser = () => {
    removeUserRequest(
      `/division/removeUserFromAGroup?userId=${userId}&divisionId=${groupId}`,
      REMOVE_USER_FROM_GROUP_REQUEST,
      REMOVE_USER_FROM_GROUP_SUCCESS,
      REMOVE_USER_FROM_GROUP_FAIL,
      null,
      history,
      'DELETE',
      null,
      'isGroupService'
    );
  };

  const handleResetPasswordLinkRequest = () => {
    resetPasswordLinkRequest(
      `/reset/resetPassword?email=${userEmail}&username=${userName}&userId=${id}`,
      RESET_PASSWORD_LINK_REQUEST,
      RESET_PASSWORD_LINK_SUCCESS,
      RESET_PASSWORD_LINK_FAIL,
      null,
      null,
      'POST',
      null,
      'isUserService'
    );
  };

  const handleChangeUserStatus = () => {
    changeUserStatusRequest(
      `/user/changeUserState?userId=${userId}&enabled=${!activeStatus}`,
      CHANGE_USER_STATUS_REQUEST,
      CHANGE_USER_STATUS_SUCCESS,
      CHANGE_USER_STATUS_FAIL,
      null,
      null,
      'POST',
      null,
      'isUserService'
    );
  };

  const handleRemoveWelcomeVideo = () => {
    removeWelcomeVideo(
      `/user/deleteWelcomeVideo?userName=${userInfo?.userData?.username}`,
      REMOVE_WELCOME_VIDEO_REQUEST,
      REMOVE_WELCOME_VIDEO_SUCCESS,
      REMOVE_WELCOME_VIDEO_FAIL,
      null,
      null,
      'PUT',
      '',
      'isUserService'
    );
  };

  const updateRemoveWelcomeVideoProcess = () => {
    getUserData();

    dispatch({
      type: removeWelcomeVideoSuccess
        ? REMOVE_WELCOME_VIDEO_SUCCESS
        : REMOVE_WELCOME_VIDEO_FAIL,
      payload: null,
    });
  };

  const getExistingVideoTitle = () => {
    let existingWelcomeVideo = existingWelcomeVideos.filter(item => {
      return item.value === existingWelcomeVideoUrl;
    });

    return existingWelcomeVideo.length > 0 ? existingWelcomeVideo[0].label : '';
  };

  if (userFetchingRequest) {
    return <Loader />;
  }

  const downloadUserCsv = () => {
    downloadUserReport({
      type: 'USER_REPORT_DOWNLOAD',
      urlParams: {
        groupId,
        userId,
      },
      reportName: `user-data-${userName}`,
    });
  };
  return (
    <div
      className={`create-user ${userId && !isView ? 'update-user' : ''} ${
        userId && isView ? 'view-user' : ''
      }`}
    >
      <div className="container">
        <div className="create-user--wrapper">
          <div className="create-user--page-title">
            <div className="create-user--page-row">
              {groupId ? (
                <BreadCrumbView
                  history={history}
                  routeOptions={[
                    {
                      title: parentGroupName ? parentGroupName : '',
                      path: `/node-tree`,
                    },
                    {
                      title: groupName ? groupName : '',
                      path: `/home/${groupId}/`,
                    },
                    {
                      title: 'Users',
                      path: `/group-dashboard/${groupId}/users`,
                    },
                    {
                      title: breadCrumbTitle,
                      path: '#',
                    },
                  ]}
                />
              ) : (
                <BreadCrumbView
                  history={history}
                  routeOptions={[
                    {
                      title: 'Users',
                      path: '/search-users',
                    },
                    {
                      title: breadCrumbTitle,
                      path: '#',
                    },
                  ]}
                />
              )}
            </div>
            {userId ? (
              <div className="create-user--page-row">
                <h2>{t(isView ? 'View Profile' : 'Edit User')}</h2>
                <div className="create-user--download edituser">
                  {!isView && (
                    <>
                      {/* <button
                        className={`
                          btn btn--secondary
                          ${
                            resetPasswordLinkRequestLoading ? 'btn--loader' : ''
                          }
                        `}
                        onClick={handleResetPasswordLinkRequest}
                      >
                        {t('Reset Password')}
                      </button> */}
                      <button
                        className="btn btn--secondary"
                        onClick={() => setIsDeleteConfirmModalOpen(true)}
                      >
                        {t(`${groupId ? 'Remove ' : 'Delete '} User`)}
                      </button>
                      {/* {((groupId && suspendUserFromGroup) || suspendUser) && ( */}
                      {/* Uncomment upper code and delete below code if suspending from group is done */}
                      {!groupId && (
                        <button
                          className={`
                            btn btn--tertiary
                            ${changeUserStatusLoading ? 'btn--loader' : ''}
                          `}
                          onClick={handleChangeUserStatus}
                        >
                          {t(activeStatus ? 'Suspend' : 'Activate')}
                        </button>
                      )}
                    </>
                  )}
                  {groupId && isView && (
                    <button
                      className={`
                        btn btn--primary
                        ${userReportDownloadLoading ? 'btn--loader' : ''}
                      `}
                      onClick={() => downloadUserCsv()}
                    >
                      {t('Download Stats')}
                    </button>
                  )}
                  {((groupId && canEditUserFromGroup) || canEdit) && (
                    <button
                      className={`
                        btn btn--primary
                        ${updateUserLoading && !isView ? 'btn--loader' : ''}
                      `}
                      onClick={() => {
                        if (isView) {
                          setIsView(false);
                        } else {
                          handleUpdateUserRequest();
                        }
                      }}
                    >
                      {t(isView ? 'Edit' : 'Update')}
                    </button>
                  )}
                  {(welcomeVideoURL || existingWelcomeVideoUrl) && isView && (
                    <button
                      className={`
                        btn btn--primary
                        ${removeWelcomeVideoLoading ? 'btn--loader' : ''}
                      `}
                      onClick={() => handleRemoveWelcomeVideo()}
                    >
                      {t('Remove Welcome Video')}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="create-user--page-row">
                <h2>{t('Create User')}</h2>
                <div className="create-user--download">
                  {canCreate && (
                    <button
                      className={`
                        btn btn--primary
                        ${createUserLoading ? 'btn--loader' : ''}
                      `}
                      onClick={handleCreateUserRequest}
                      disabled={createUserLoading || isFileUploading}
                    >
                      {t('Create')}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="create-user--page-content">
            <div className="create-user--page-row credentials">
              <div className="create-user--page-column">
                <div className="create-user--uploadphoto">
                  <div className="upload-btn-wrapper">
                    {imageFileURL || profileImage || defaultProfileImageUrl ? (
                      <img
                        src={imageFileURL || profileImage || defaultProfileImageUrl}
                        alt="profile image"
                        className="create-user--image"
                        onError={event => {
                          if (isView) {
                            event.target.onerror = null;
                            event.target.src = defaultProfileImageUrl;
                          }
                        }}
                      />
                    ) : (
                      <button className="btn--upload">
                        {t('Upload Profile Picture')}
                      </button>
                    )}
                    <input
                      type="file"
                      accept="image/jpeg, image/png"
                      name="myfile"
                      onChange={handleImageUpload}
                      disabled={isView && userId}
                    />
                  </div>
                </div>
              </div>
              <div className="create-user--page-column">
                <div className="create-user--field username">
                  <input
                    type="text"
                    name=""
                    placeholder={t(userNameLabel) || '' + ' *'}
                    value={userName}
                    onChange={event =>
                      handleUserData(event.target.value, 'userName')
                    }
                    disabled={isView && userId}
                    autoFocus
                  />
                  {!isValidUsername && onSubmitClicked ? (
                    <span className="error-message">
                      {t('Please provide a valid username')}
                    </span>
                  ) : null}
                  <br />
                  {isUserNameExist && onSubmitClicked ? (
                    <span className="error-message">
                      {t('Username already exists')}
                    </span>
                  ) : null}
                </div>
                <div className="create-user--field designation">
                  <label>
                    {t(nameLabel)} <MandatoryMark />
                  </label>
                  <input
                    type="text"
                    name=""
                    placeholder={t(nameLabel)}
                    value={designation}
                    onChange={event =>
                      handleUserData(event.target.value, 'designation')
                    }
                    disabled={isView && userId}
                  />
                  {!isValidDesignation && onSubmitClicked ? (
                    <span className="error-message">
                      {t('Please remove any spaces or symbols at the end of your full name to proceed.')}
                    </span>
                  ) : null}
                </div>
                <div className="create-user--field">
                  <label>
                    {t(emailLabel)} <MandatoryMark />
                  </label>
                  <input
                    type="email"
                    name=""
                    placeholder={t('name@example.com')}
                    value={userEmail}
                    onChange={event =>
                      handleUserData(event.target.value, 'userEmail')
                    }
                    disabled={userId}
                  />
                  {!isValidEmail && onSubmitClicked ? (
                    <span className="error-message">
                      {t('Please provide a valid email address')}
                    </span>
                  ) : null}
                  <br />
                  {isEmailExist && onSubmitClicked ? (
                    <span className="error-message">
                      {t('Email already exists!')}
                    </span>
                  ) : null}
                </div>
                {isFederatedLoginAvailable && !userId && (
                  <div className="create-user--field">
                    <input
                      type="checkbox"
                      className="form-input--checkbox"
                      id="endvideo"
                      name="endvideo"
                      checked={isFederatedIdentity}
                      onChange={() =>
                        handleUserData(
                          !isFederatedIdentity,
                          'isFederatedIdentity'
                        )
                      }
                    />
                    <label
                      onClick={() =>
                        handleUserData(
                          !isFederatedIdentity,
                          'isFederatedIdentity'
                        )
                      }
                    >
                      SSO
                    </label>
                  </div>
                )}
                <div className="create-user--field">
                  <label>{t('Comments')}</label>
                  <textarea
                    className="create-user--text-area"
                    rows="5"
                    cols="38"
                    value={signupComments}
                    disabled
                  />
                </div>
                {userId && groupId && (
                  <div className="create-user--field designation">
                    <label>{t('User Role')}</label>
                    {isView &&
                      selectedUserRoles.map(({ label }, index) => (
                        <input key={index} type="text" value={label} disabled />
                      ))}
                    <div
                      className="group-users-table--popup-field"
                      style={{ zIndex: 10 }}
                    >
                      {!isView && (
                        <>
                          <MultiSelect
                            options={userRolesOption}
                            value={selectedUserRoles}
                            onChange={handleUserRoleSelection}
                            labelledBy={'Select'}
                            overrideStrings={{
                              allItemsAreSelected: t('All roles selected'),
                              selectSomeItems: t('Select a role'),
                            }}
                            hasSelectAll={false}
                            disableSearch
                            disabled={isView}
                          />
                          {!selectedUserRoles?.length && (
                            <span className="error-message">
                              {t('Please select a user role')}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}

                {/* <div className="create-user--field password">
                  <label>Password</label>
                  <input type="Password" name="" placeholder="************" />
                  <span>
                    <img src={lock} alt="lock" className="create-user--image" />
                    Change Password
                  </span>
                </div> */}
              </div>
              {!isView && (
                <div className="create-user--page-column center-column">
                  <div className="create-user--field">
                    <input
                      type="radio"
                      className="form-input--checkbox"
                      id="exisiting-video"
                      name="welcomeVideoSelection"
                      value="exisitingVideo"
                      checked={!isNewWelcomeVideo}
                    />
                    <label
                      onClick={() => handleUserData(false, 'isNewWelcomeVideo')}
                    >
                      {t('Use existing video')}
                    </label>
                  </div>
                  <div className="create-user--field ">
                    <input
                      type="radio"
                      className="form-input--checkbox"
                      id="new-video"
                      name="welcomeVideoSelection"
                      value="newVideo"
                      checked={isNewWelcomeVideo}
                    />
                    <label
                      onClick={() => handleUserData(true, 'isNewWelcomeVideo')}
                    >
                      {t('Add new video')}
                    </label>
                  </div>
                  {!isNewWelcomeVideo ? (
                    <div className="create-user--field selectvideo">
                      <label>{t('Select Video')}</label>
                      <Dropdown
                        title="Welcome videos"
                        options={existingWelcomeVideos}
                        value={existingWelcomeVideoUrl}
                        onChange={videoData =>
                          handleUserData(
                            videoData.value,
                            'existingWelcomeVideoUrl'
                          )
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div className="create-user--field">
                        <label>
                          {t('Video Title')} <MandatoryMark />
                        </label>
                        <input
                          type="text"
                          name=""
                          placeholder={t('Title')}
                          value={welcomeVideoTitle}
                          onChange={event =>
                            handleUserData(
                              event.target.value,
                              'welcomeVideoTitle'
                            )
                          }
                        />
                      </div>
                      <div className="create-user--field">
                        <input
                          type="radio"
                          className="form-input--checkbox"
                          id="uploadvideo"
                          name="uploadvideo"
                          checked={isUploadNewVideo}
                        />
                        <label
                          onClick={() =>
                            handleUserData(true, 'isUploadNewVideo')
                          }
                        >
                          {t('Upload Video')}
                        </label>
                      </div>
                      <div className="create-user--field ">
                        <input
                          type="radio"
                          className="form-input--checkbox"
                          id="uploadvideourl"
                          name="uploadvideourl"
                          checked={!isUploadNewVideo}
                        />
                        <label
                          onClick={() =>
                            handleUserData(false, 'isUploadNewVideo')
                          }
                        >
                          {t('Upload via URL')}
                        </label>
                      </div>

                      {isUploadNewVideo ? (
                        <div className="create-user--field">
                          <div className="upload-btn-wrapper">
                            <button className="btn--upload-video">
                              {t('Upload Video')}
                            </button>
                            <input
                              type="file"
                              accept="video/*"
                              name=""
                              onChange={handleVideoUpload}
                            />
                          </div>
                          {showProgressBar && (
                            <UploadItem progress={welcomeVideoUploadProgress} />
                          )}
                        </div>
                      ) : (
                        <div className="create-user--field url">
                          <input
                            type="text"
                            name=""
                            placeholder={t('URL')}
                            value={welcomeVideoURL}
                            onChange={event =>
                              handleUserData(
                                event.target.value,
                                'welcomeVideoURL'
                              )
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              <div className="create-user--page-column">
                <div className="create-user--field">
                  <label>{t('Video Preview')}</label>
                  <ReactPlayer
                    url={
                      (isNewWelcomeVideo && welcomeVideoURL) ||
                      (isNewWelcomeVideo && videoFileURL) ||
                      (!isNewWelcomeVideo && existingWelcomeVideoUrl) ||
                      'alt'
                    }
                    width="100%"
                    height="100%"
                    controls
                    playing={false}
                    // playing={videoFileURL !== ""}
                  />
                </div>
                <div className="create-user--field">
                  <label>{t('Welcome Video Text')}</label>
                  <input
                    type="text"
                    name=""
                    disabled={isView}
                    placeholder={!isView ? t('Welcome Video Text') : ''}
                    value={welcomeVideoText}
                    onChange={event => {
                      if (event?.target?.value?.length > 40) {
                        setWelcomVideoTextErrorMessage(
                          messageConfigs.error.USER.WELCOME_VIDEO_LENGTH
                        );
                      } else {
                        setWelcomVideoTextErrorMessage(null);
                        handleUserData(
                          event?.target?.value,
                          'welcomeVideoText'
                        );
                      }
                    }}
                  />
                  {welcomVideoTextErrorMessage && (
                    <span className="error-message">
                      {t(welcomVideoTextErrorMessage)}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {!groupId && isEdit && (
              <div className="create-user--page-row groups">
                <h4>{t('Groups')}</h4>
                <p
                  className="addanother"
                  onClick={() => setIsAssignGroupToUserModalShow(true)}
                >
                  {t('Add group')}
                </p>
                <div className="create-user--page-row--inner">
                  {groupsWithRoles.length
                    ? groupsWithRoles.map(
                        ({ divisionId, divisionName, userData }, index) => (
                          <div className="create-user--page-column" key={index}>
                            <div
                              className={`create-user--group ${
                                !isView ? 'edituser' : ''
                              }`}
                              onClick={event => {
                                if (!isView) {
                                  history.push(
                                    `/group-dashboard/${divisionId}/users/${userId}/edit`
                                  );
                                } else {
                                  event.preventDefault();
                                }
                              }}
                            >
                              <h5>{divisionName}</h5>
                              <span>
                                {userData?.length
                                  ? userData
                                      .map(({ roleName }) =>
                                        roleName === 'Admin'
                                          ? 'Administrator'
                                          : roleName
                                      )
                                      .join(', ')
                                  : null}
                              </span>
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            )}
            {groupId && isView && reelItemCounts.length > 0 && (
              <div className="create-user--page-row groups">
                <h4>{t('Reels')}</h4>
                <div className="create-user--page-row--inner">
                  {reelItemCounts.map(
                    ({ reelName, type, noOfItems, noOfAssessments }, key) => (
                      <div className="create-user--page-column" key={key}>
                        <div className="create-user--group">
                          <h5>{reelName}</h5>
                          <span>
                            {noOfItems
                              ? (noOfItems < 10 ? '0' : '') +
                                noOfItems +
                                (' ' +
                                  capitalizeFirstLetter(type.toLowerCase()) +
                                  (noOfItems > 1 ? 's' : ''))
                              : 'No ' +
                                capitalizeFirstLetter(type.toLowerCase()) +
                                's'}
                          </span>
                          {noOfAssessments && (
                            <span>
                              {(noOfAssessments < 10 ? ' & 0' : ' & ') +
                                noOfAssessments +
                                (' Assessment' +
                                  (noOfAssessments > 1 ? 's' : ''))}
                            </span>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
            {canViewActivityLog && (
              <div className="create-user--page-row activity">
                <h4>{t('Activity Log')}</h4>
                <div className="create-user--page-row--inner table">
                  <div className="create-user--table">
                    <div className="create-user--table-header">
                      <div className="create-user--table-cell">
                        <h5>{t('Activity Type')}</h5>
                      </div>
                      <div className="create-user--table-cell">
                        <h5>{t('Activity')}</h5>
                      </div>
                      <div className="create-user--table-cell">
                        <h5>{t('Status')}</h5>
                      </div>
                      <div className="create-user--table-cell">
                        <h5>{t('Date')}</h5>
                      </div>
                      <div className="create-user--table-cell">
                        <h5>{t('Time')}</h5>
                      </div>
                    </div>
                    <div className="create-user--table-body">
                      <div className="create-user--table-row">
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="creprofilePicUrlate-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                      </div>
                      <div className="create-user--table-row">
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                      </div>
                      <div className="create-user--table-row">
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                        <div className="create-user--table-cell">
                          <p> - </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isAssignGroupToUserModalShow && userId && (
        <AssignUserToGroupModal
          handleClose={() => setIsAssignGroupToUserModalShow(false)}
          handleSuccess={getUserData}
          userId={userId}
          assignedGroups={
            groupsWithRoles.length
              ? groupsWithRoles.map(({ divisionId }) => divisionId)
              : []
          }
        />
      )}
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={messageConfigs.deleteConfirm.title}
          message={
            groupId
              ? messageConfigs.deleteConfirm.userFromGroup
              : deleteUserMessage ===
                ErrorTypes.USER_ACTIVELY_EXIST_IN_SUB_MODULE
              ? messageConfigs.deleteConfirm?.errorMessageStarting +
                userName +
                messageConfigs.deleteConfirm?.errorMessageEnding
              : messageConfigs.deleteConfirm.user
          }
          handleSuccess={
            groupId
              ? () => handleRemoveUser()
              : () => {
                  deleteUserMessage ===
                  ErrorTypes.USER_ACTIVELY_EXIST_IN_SUB_MODULE
                    ? clearDeleteModalPropsAndClose()
                    : handleDeleteUser();
                }
          }
          handleClose={() => clearDeleteModalPropsAndClose()}
          isLoading={groupId ? removeUserFromGroupLoading : deleteUserLoading}
          isJustErrorMessage={
            deleteUserMessage === ErrorTypes.USER_ACTIVELY_EXIST_IN_SUB_MODULE
          }
        />
      )}
      {isResetPasswordSuccessModalOpen && (
        <ProceedMessageModal
          isOkayButtonShown={true}
          isSuccess={isResetPasswordSuccessModalOpen.status}
          message={isResetPasswordSuccessModalOpen.message}
          handleClose={() => setIsResetPasswordSuccessModalOpen(null)}
          handleSuccess={() => setIsResetPasswordSuccessModalOpen(null)}
          handleError={() => setIsResetPasswordSuccessModalOpen(null)}
        />
      )}
      {/* Remove Welcome video success and error modal */}
      {(removeWelcomeVideoSuccess || removeWelcomeVideoError) && (
        <ProceedMessageModal
          isOkayButtonShown={true}
          isSuccess={removeWelcomeVideoSuccess}
          message={
            removeWelcomeVideoSuccess
              ? messageConfigs.success.user.WELCOME_VIDEO_REMOVED
              : messageConfigs.error.TRY_LATER
          }
          handleClose={() => updateRemoveWelcomeVideoProcess()}
          handleSuccess={() => updateRemoveWelcomeVideoProcess()}
          handleError={() => updateRemoveWelcomeVideoProcess()}
        />
      )}
      {/* Remove Welcome video success and error modal */}
      {/* User report downloading error modal */}
      {userReportDownloadError && (
        <ProceedMessageModal
          isOkayButtonShown={false}
          isSuccess={userReportDownloadError.success}
          message={userReportDownloadError.message}
          handleClose={() =>
            dispatch({
              type: DOWNLOAD_USER_REPORT_SUCCESS,
            })
          }
          handleError={() =>
            dispatch({
              type: DOWNLOAD_USER_REPORT_SUCCESS,
            })
          }
        />
      )}
      {/* User report downloading error modal */}
      {/* Create user request error modal */}
      {(createUserError || updateUserFail) && (
        <ProceedMessageModal
          isOkayButtonShown={true}
          isSuccess={false}
          message={
            (createUserError?.length && createUserError) ||
            (updateUserFail?.length && updateUserFail) ||
            messageConfigs.error.TRY_LATER
          }
          handleError={() => {
            dispatch({
              type: createUserError ? CREATE_USER_SUCCESS : UPDATE_USER_SUCCESS,
            });
          }}
        />
      )}
      {/* Create user request error modal */}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

import {
  AUTH_REQUEST,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAIL,
  FETCH_RESET_PASSWORD_DATA_REQUEST,
  FETCH_RESET_PASSWORD_DATA_SUCCESS,
  FETCH_RESET_PASSWORD_DATA_FAIL,
  CLEAR_SESSION,
  AUTH_SUCCESS,
  SYNC_FEDERATE_LOGIN_DATA_REQUEST,
  SYNC_FEDERATE_LOGIN_DATA_SUCCESS,
  SYNC_FEDERATE_LOGIN_DATA_FAIL,
  SET_IS_FEDERATED_LOGIN,
  SET_GEO_LOCATION,
  FETCH_SSO_USER_VERIFY_REQUEST,
  FETCH_SSO_USER_VERIFY_SUCCESS,
  FETCH_SSO_USER_VERIFY_FAIL,
  FETCH_SSO_USER_VERIFY_RESET,
} from '../../redux/actionTypes/auth.js';
import {
  CHANGE_ADMIN_VIEW,
  ADD_ADMIN_USER,
} from '../../redux//action/actionTypes';
import useApi from '../../Hooks/useApi';
import ProceedMessageModal from '../../Components/Modal/ProceedMessageModal.js';
import ContactSupportModal from '../../Components/Modal/ContactSupportModal.js';
import messageConfigs from '../../Helper/PopupMessageConfig.js';
import DottedLoader from '../../Components/Loader/DottedLoader.js';
import useAuth from '../../Hooks/useAuth.js';
import { systemConfig } from '../../Common/SystemConstConfigs.js';
import { getCountryFromTimeZone } from '../../Helper/SystemManager.js';
import useFetchDataApi from '../../Hooks/useFetchDataApi';
import SsoVerifyModal from '../../Components/Modal/SsoVerifyModal.js';
import {
  UPDATE_LAST_ACTIVE_FAIL,
  UPDATE_LAST_ACTIVE_REQUEST,
  UPDATE_LAST_ACTIVE_SUCCESS,
} from '../../redux/actionTypes/createUser.js';

const {
  REACT_APP_IS_FEDETRATE_LOGIN_AVAILABLE,
  REACT_APP_CLIENT_ID,
  REACT_APP_IDENTITY_PROVIDER,
} = process.env;

const Login = ({ history, location }) => {
  const isLoginScreen = location.pathname !== '/reset/changePassword';
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginErrorModalOpen, setIsLoginErrorModalOpen] = useState(null);
  const [isContactSUpportModalOpen, setIsContactSUpportModalOpen] =
    useState(null);
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [isPasswordTokenExpired, setIsPasswordTokenExpired] = useState(null);
  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    token: '',
    userId: '',
    newPassword: '',
    confirmPassword: '',
    profileImage: '',
    userName: '',
    userEmail: '',
  });
  const [isSsoVerifyModalOpen, setIsSsoVerifyModalOpen] = useState(false);
  const dispatch = useDispatch();

  const signIn = useAuth();
  const [userDataRequest] = useApi();
  const [getResetPasswordUserData] = useApi();
  const [resetPasswordRequest] = useApi('/');
  const [syncFederatedLoginData] = useApi();
  const [updateLoginTime] = useApi();
  const [getS3bucketData] = useFetchDataApi();
  const [verifySsoUser] = useApi();
  const {
    access_token,
    userData,
    resetPasswordUserData,
    auth_error,
    authUserDataLoading,
    resetPasswordUserDataRequestLoading,
    resetPasswordUserDataError,
    themeInfo,
    federateLoginData,
    isFederateLogin,
    federatedLoginError,
    authUserDataError,
    ssoUserVerifyLoading,
    ssoUserVerified,
    ssoUserVerifyError,
  } = useSelector(state => state?.auth);

  const logo = themeInfo?.headerLogoUrl;
  const welcomeMsg = themeInfo?.welcomeText;
  const defaultProfileImageUrl = themeInfo?.defaultProfileImageUrl;
  const supportEmail = themeInfo?.supportEmail;
  let loginBackground = themeInfo?.loginBackgroundUrl;

  const {
    token,
    userId,
    newPassword,
    confirmPassword,
    profileImage,
    name,
    userEmail,
  } = resetPasswordInfo;

  // Changing site language according to backend language code
  useEffect(() => {
    if (themeInfo?.languageCode) {
      i18n.changeLanguage(themeInfo?.languageCode);
    }
  }, [themeInfo]);

  useEffect(() => {
    position();
    dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
  }, []);

  const sendLastLoginTime = () => {
    updateLoginTime(
      '/user/updateUser',
      UPDATE_LAST_ACTIVE_REQUEST,
      UPDATE_LAST_ACTIVE_SUCCESS,
      UPDATE_LAST_ACTIVE_FAIL,
      null,
      null,
      'PUT',
      '',
      'isUserService'
    );
  };

  const position = async () => {
    const locationZoneData = getCountryFromTimeZone();
    await navigator.geolocation.getCurrentPosition(
      position => {
        dispatch({
          type: SET_GEO_LOCATION,
          payload: {
            ...locationZoneData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        });
      },
      err => {
        dispatch({
          type: SET_GEO_LOCATION,
          payload: {
            ...locationZoneData,
            latitude: null,
            longitude: null,
          },
        });
      }
    );
  };

  useEffect(() => {
    if (resetPasswordUserData) {
      const { email, profilePicUrl, username } = resetPasswordUserData;

      setResetPasswordInfo({
        ...resetPasswordInfo,
        profileImage: profilePicUrl || defaultProfileImageUrl,
        name: username,
        userEmail: email,
      });
    }
  }, [resetPasswordUserData]);

  useEffect(() => {
    if (resetPasswordUserDataError) {
      setIsPasswordTokenExpired(
        messageConfigs.error.USER.RESET_PASSWORD_TOKEN_EXPRIRED
      );
    }
  }, [resetPasswordUserDataError]);

  useEffect(() => {
    if (!isLoginScreen) {
      const { token, userId } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        parseNumbers: true,
      });
      setResetPasswordInfo({ ...resetPasswordInfo, token, userId });
      getResetPasswordUserData(
        `/reset/getUserDataForResetPassword?userId=${userId}`,
        FETCH_RESET_PASSWORD_DATA_REQUEST,
        FETCH_RESET_PASSWORD_DATA_SUCCESS,
        FETCH_RESET_PASSWORD_DATA_FAIL,
        null,
        null,
        'GET',
        '',
        'isUserService'
      );
    }
  }, [isLoginScreen]);

  const fetchUserData = uuidUserName => {
    userDataRequest(
      `/user/getUserData?username=${uuidUserName}`,
      FETCH_USER_DATA_REQUEST,
      FETCH_USER_DATA_SUCCESS,
      FETCH_USER_DATA_FAIL,
      null,
      null,
      'GET',
      '',
      'isUserService'
    );
  };

  useEffect(() => {
    if (
      !isFederateLogin &&
      userData?.userName &&
      access_token &&
      isLoginScreen
    ) {
      sendLastLoginTime();
      fetchUserData(userData?.userName);
      getS3bucketData({ type: 'GET_S3_BUCKET_NAME' });
    }
  }, [access_token, userData]);

  useEffect(() => {
    if (authUserDataError && isLoginClicked) {
      setIsContactSUpportModalOpen(authUserDataError?.message);

      setIsLoginClicked(false);
    }
  }, [authUserDataError]);

  useEffect(() => {
    if (isFederateLogin && access_token && !federateLoginData) {
      syncFederatedLoginData(
        `/user/syncFederatedUserData`,
        SYNC_FEDERATE_LOGIN_DATA_REQUEST,
        SYNC_FEDERATE_LOGIN_DATA_SUCCESS,
        SYNC_FEDERATE_LOGIN_DATA_FAIL,
        null,
        '',
        'PUT',
        '',
        'isUserService'
      );
    }
  }, [access_token]);

  useEffect(() => {
    if (isFederateLogin && access_token && federateLoginData?.username) {
      sendLastLoginTime();
      fetchUserData(federateLoginData?.username);
    }
  }, [access_token, federateLoginData]);

  useEffect(() => {
    if (userData) {
      const { userName, groupInfo, roleList } = userData;

      if (groupInfo?.length && access_token && isLoginScreen) {
        if (
          roleList &&
          roleList.length &&
          roleList.some(({ id }) => id === 3)
        ) {
          if (roleList.some(({ id }) => id === 1 || id === 2 || id === 5)) {
            dispatch({ type: ADD_ADMIN_USER, payload: true });
          }
          dispatch({ type: CHANGE_ADMIN_VIEW, payload: false });
          history.push('/home');
        } else {
          dispatch({ type: CHANGE_ADMIN_VIEW, payload: true });
          dispatch({ type: ADD_ADMIN_USER, payload: true });
          history.push('/node-tree');
        }
      } else if (
        access_token &&
        (!groupInfo || (groupInfo && groupInfo.length === 0)) &&
        roleList &&
        !roleList.length &&
        !authUserDataLoading
      ) {
        setIsLoginClicked(false);
        setIsContactSUpportModalOpen(
          messageConfigs.error.USER.GROUPS_NOT_ASSIGNED
        );
      }
    }
  }, [userData, access_token, authUserDataLoading]);

  useEffect(() => {
    if (auth_error) {
      setIsLoginClicked(false);

      // Suspended user message
      if (
        auth_error.code === systemConfig.errorcode.NotAuthorizedException &&
        auth_error.message === messageConfigs.error.USER.DISABLED
      ) {
        setIsContactSUpportModalOpen(messageConfigs.error.USER.SUSPENDED_USER);
        // Incorrect login creditials message
      } else if (
        auth_error.code === systemConfig.errorcode.NotAuthorizedException &&
        auth_error.message ===
          messageConfigs.error.USER.INCORRECT_USERNAME_PASSWORD
      ) {
        setIsLoginErrorModalOpen({
          isSuccess: false,
          message: messageConfigs.error.USER.INCORRECT_USERNAME_PASSWORD,
        });
        // Other login failures
      } else {
        setIsLoginErrorModalOpen({
          isSuccess: false,
          message: messageConfigs.error.TRY_LATER,
        });
      }

      dispatch({ type: AUTH_REQUEST });
    }
  }, [auth_error]);

  const onLogin = () => {
    if (userName && password) {
      setIsLoginClicked(true);
      signIn(userName, password);
    }
  };
  const handleResetPasswordInfo = (value, field) => {
    setIsPasswordTokenExpired(null);

    const tempResetPasswordInfo = { ...resetPasswordInfo };
    tempResetPasswordInfo[field] = value;
    setResetPasswordInfo(tempResetPasswordInfo);
  };

  const handleChangePassword = () => {
    if (
      confirmPassword &&
      newPassword &&
      confirmPassword === newPassword &&
      newPassword.length >= 8
    ) {
      resetPasswordRequest(
        `/reset/savePassword?token=${token}&newPassword=${newPassword}`,
        FETCH_RESET_PASSWORD_DATA_REQUEST,
        FETCH_RESET_PASSWORD_DATA_SUCCESS,
        FETCH_RESET_PASSWORD_DATA_FAIL,
        null,
        history,
        'POST',
        '',
        'isUserService'
      );
    }
  };

  useEffect(() => {
    if (!access_token) {
      getUser();
    }
  }, [access_token]);

  async function getUser() {
    await Auth.currentAuthenticatedUser()
      .then(userData => {
        Auth.currentCredentials().then(credential => {
          const { accessKeyId, secretAccessKey, sessionToken } = credential;
          dispatch({
            type: AUTH_SUCCESS,
            payload: {
              accessKeyId,
              secretAccessKey,
              sessionToken,
              userName: userData.getUsername(),
              preferredUsername: userData?.attributes?.preferred_username,
              refreshToken: userData
                .getSignInUserSession()
                .getRefreshToken()
                .getToken(),
              accessToken: userData
                .getSignInUserSession()
                .getAccessToken()
                .getJwtToken(),
            },
          });
        });
      })
      .catch();
  }

  const onClickSsoUserContinue = () => {
    dispatch({
      type: SET_IS_FEDERATED_LOGIN,
    });

    Auth.federatedSignIn({
      provider: REACT_APP_IDENTITY_PROVIDER,
    });
    setIsSsoVerifyModalOpen(false);
    dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
  };

  const onClickVerifySsoUser = email => {
    verifySsoUser(
      `/user/${email}/federatedLogin`,
      FETCH_SSO_USER_VERIFY_REQUEST,
      FETCH_SSO_USER_VERIFY_SUCCESS,
      FETCH_SSO_USER_VERIFY_FAIL,
      null,
      null,
      'GET',
      '',
      'isUserService'
    );
  };

  const renderResetPasswordModal = () => {
    return (
      <div className="loginscreen__resetpassword">
        <div className="loginscreen__resetpassword--page-title">
          <p>{t('Reset Password')}</p>
        </div>
        {resetPasswordUserDataRequestLoading ? (
          <DottedLoader />
        ) : (
          <div className="loginscreen__resetpassword--page-body">
            <div className="loginscreen__resetpassword--page-row">
              <div className="loginscreen__resetpassword--page-column">
                <img
                  src={profileImage || defaultProfileImageUrl}
                  alt="userimage"
                  className="loginscreen__resetpassword--page-userimage"
                  onError={event => {
                    event.target.onerror = null;
                    event.target.src = defaultProfileImageUrl;
                  }}
                />
              </div>
              <div className="loginscreen__resetpassword--page-column">
                <h4>{name}</h4>
                <p>{userEmail}</p>
              </div>
            </div>
            <div className="loginscreen__resetpassword--page-row password-field">
              {confirmPassword && confirmPassword !== newPassword && (
                <h3>
                  <span>{t('Warning')}:</span>
                  {messageConfigs.error.USER.PASSWORD_NOT_MATCH}
                </h3>
              )}
              {isPasswordTokenExpired && (
                <h3>
                  <span>{t('Warning')}:</span> {isPasswordTokenExpired}
                </h3>
              )}
              {newPassword && newPassword.length < 8 && (
                <h3>
                  <span>{t('Warning')}:</span>
                  {messageConfigs.error.USER.INVALID_PASSWORD}
                </h3>
              )}
              <label>{t('Password')}</label>
              <input
                id="resetpassword"
                type="password"
                name=""
                value={newPassword}
                onChange={e =>
                  handleResetPasswordInfo(e.target.value, 'newPassword')
                }
                autoComplete={false}
              />
              <button
                className="top-button"
                onClick={() => {
                  const x = document.getElementById('resetpassword');
                  if (x.type === 'password') {
                    x.type = 'text';
                  } else {
                    x.type = 'password';
                  }
                }}
              >
                {t('show/hide password')}
              </button>
            </div>
            <div className="loginscreen__resetpassword--page-row password-field">
              <label>{t('Confirm Password')}</label>
              <input
                id="confirmpassword"
                type="password"
                name=""
                value={confirmPassword}
                onChange={e =>
                  handleResetPasswordInfo(e.target.value, 'confirmPassword')
                }
              />
              <button
                onClick={() => {
                  const x = document.getElementById('confirmpassword');
                  if (x.type === 'password') {
                    x.type = 'text';
                  } else {
                    x.type = 'password';
                  }
                }}
              >
                {t(' show/hide password')}
              </button>
            </div>
            <div className="loginscreen__resetpassword--page-row">
              <button
                className="btn btn--primary"
                onClick={handleChangePassword}
              >
                {t('Reset Password')}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderLoginModal = () => {
    const renderLoginLogo = () => {
      switch (process.env.REACT_APP_LOGIN_PAGE) {
        case 'TYPE-2':
          return (
            <div className="loginscreen__logo">
              <img src={logo} alt="logo-icon" className="icon--logo" />
            </div>
          );

        default:
          return <img src={logo} alt="logo-icon" className="icon--logo" />;
      }
    };

    return (
      <div className="loginscreen__form form">
        {renderLoginLogo()}
        <h2>{welcomeMsg}</h2>
        <h4>{t('Enter your details below')}</h4>
        <div className="form__form--fields">
          <div className="form--field">
            <label>{t('Username or Email Address')}</label>
            <input
              className="form-input"
              value={userName}
              onChange={e => setUserName(e.target.value.trim())}
            />
          </div>
          <div className="form--field password-field">
            <label>{t('Password')}</label>
            <input
              id="password"
              className="form-input"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <button
              onClick={() => {
                const x = document.getElementById('password');
                if (x.type === 'password') {
                  x.type = 'text';
                } else {
                  x.type = 'password';
                }
              }}
            >
              {t('show/hide password')}
            </button>
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/forgotPassword')}
            >
              Forgot your password?
            </a>
          </div>
          {/* <div className="form--field last">
            <input
              className="form-input--checkbox"
              type="checkbox"
              id="remember-me"
              name="remember-me"
              value="Bike"
            />
            <label for="remember-me">Remember Me</label>
          </div> */}
          <div className="form--buttons">
            <button
              className={`
                btn btn--primary
                ${isLoginClicked ? 'btn--loader' : ''}
              `}
              onClick={onLogin}
            >
              {t('Login')}
            </button>
            <button
              className="btn btn--primary"
              onClick={() => history.push('/signup')}
            >
              {t('Sign Up')}
            </button>
          </div>
          {REACT_APP_IS_FEDETRATE_LOGIN_AVAILABLE && (
            <>
              <div className="form--field text">Or</div>

              <button
                className="btn btn--primary form--field federate-sign-button"
                onClick={() => setIsSsoVerifyModalOpen(true)}
              >
                Sign in with your {REACT_APP_CLIENT_ID} account
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="loginscreen"
      style={{
        backgroundImage: `url(${loginBackground})`,
      }}
    >
      <div className="container">
        {/* Add class "password" to wrapper on reset password */}
        <div className="loginscreen__wrapper">
          {isLoginScreen ? renderLoginModal() : renderResetPasswordModal()}
        </div>
      </div>
      {isLoginErrorModalOpen && (
        <ProceedMessageModal
          isSuccess={isLoginErrorModalOpen.isSuccess}
          message={isLoginErrorModalOpen.message}
          handleClose={() => setIsLoginErrorModalOpen(null)}
          handleSuccess={() => setIsLoginErrorModalOpen(null)}
          handleError={() => setIsLoginErrorModalOpen(null)}
        />
      )}
      {isContactSUpportModalOpen && (
        <ContactSupportModal
          message={isContactSUpportModalOpen}
          supportEmail={supportEmail}
          handleClose={() => {
            setIsContactSUpportModalOpen(null);
            Auth.signOut();
            dispatch({
              type: CLEAR_SESSION,
            });
          }}
        />
      )}
      {federatedLoginError && (
        <ContactSupportModal
          message={messageConfigs.error.USER.FEDERATE_LOGIN_FAIL}
          supportEmail={supportEmail}
          handleClose={() => {
            Auth.signOut();
            setIsContactSUpportModalOpen(null);
            dispatch({
              type: CLEAR_SESSION,
            });
          }}
        />
      )}
      {isSsoVerifyModalOpen && (
        <SsoVerifyModal
          handleClose={() => {
            setIsSsoVerifyModalOpen(false);
            dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
          }}
          onClickVerifySsoUser={email => onClickVerifySsoUser(email)}
          onClickContinueSsoUser={onClickSsoUserContinue}
        />
      )}
    </div>
  );
};

export default Login;

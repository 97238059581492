import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import VTTConverter from 'srt-webvtt';
import MandatoryMark from '../validation/MandatoryMark';
import { useTranslation } from 'react-i18next';
import UploadItem from '../../Components/UploadProgressBar/UploadProgressBar';
import useFileUpload from '../../Hooks/useFileUpload';
const TheatreModeVideoView = ({
  title,
  videoUrl,
  videoUrlPropertyName,
  isVideoViaUrl,
  isVideoViaUrlPropertyName,
  handleData,
  videoFilepropertyName,
  isValidUrl,
  setIsValidUrl,
  subtitleFile,
}) => {
  const { t } = useTranslation();
  const [subtitleUrl, setSubtitleUrl] = useState(null);
  const [isHidePlayer, setIsHidePlayer] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [localFileUrl, setLocalFileUrl] = useState('');

  const [uploadFile, fileUrl, fileUploadingProgress] = useFileUpload();

  useEffect(() => {
    setIsValidUrl(true);
    setLocalFileUrl(videoUrl);
  }, [videoUrl]);

  const handleFileAccept = event => {
    if (event?.target?.files?.length) {
      const file = event.target.files[0];
      setLocalFileUrl(URL.createObjectURL(file));
      uploadFile(file);
    }
  };

  const addFileFromUrl = (value, name) => {
    setLocalFileUrl(value);
    handleData(value, name);
  };

  useEffect(() => {
    setShowProgressBar(false);
    if (fileUploadingProgress !== 0) {
      setShowProgressBar(true);
    } else if (showProgressBar) {
      setShowProgressBar(false);
    }
  }, [fileUploadingProgress]);

  useEffect(() => {
    handleData(fileUrl, videoUrlPropertyName);
  }, [fileUrl]);

  useEffect(() => {
    if (subtitleFile) {
      setIsHidePlayer(true);
      const vttConverter = new VTTConverter(subtitleFile);
      vttConverter
        .getURL()
        .then(function (url) {
          if (url) {
            setSubtitleUrl(url);
          }
        })
        .catch(function (err) {
          console.error(err);
        });
      setTimeout(() => {
        setIsHidePlayer(false);
      }, 1000);
    }
  }, [subtitleFile]);
  return (
    <>
      <div className="admin__createvideo--video-theatermode">
        <p>
          {t(title)} <MandatoryMark />
        </p>
        {!isHidePlayer && (
          <ReactPlayer
            url={localFileUrl || 'alt'}
            controls
            playing={false}
            config={{
              file: {
                tracks: [
                  {
                    kind: 'subtitles',
                    src: subtitleUrl,
                    srcLang: 'en',
                    default: true,
                  },
                ],
              },
            }}
            onError={e => {
              if (videoUrl) {
                setIsValidUrl(false);
              } else {
                setIsValidUrl(true);
              }
            }}
          />
        )}
        {showProgressBar && <UploadItem progress={fileUploadingProgress} />}
      </div>
      <div className="admin__createvideo--video-theatermode">
        <input
          readOnly
          type="radio"
          className="form-input--checkbox"
          checked={!isVideoViaUrl}
        />
        <label onClick={() => handleData(false, isVideoViaUrlPropertyName)}>
          {t('Upload Theater Mode Video')}
        </label>
        <input
          type="file"
          id="upload-theater-vid"
          name="upload-theater-vid"
          placeholder={t('Image Title')}
          accept="video/*"
          onChange={e => handleFileAccept(e, videoFilepropertyName)}
        />
        <input
          readOnly
          type="radio"
          className="form-input--checkbox"
          checked={isVideoViaUrl}
        />
        <label onClick={() => handleData(true, isVideoViaUrlPropertyName)}>
          {t('Upload Theater Mode Video via URL')}
        </label>
        <input
          type="text"
          id="upload-theater-vid-url"
          name="upload-theater-vid-url"
          placeholder={t('URL')}
          value={videoUrl || ''}
          onChange={e => addFileFromUrl(e.target.value, videoUrlPropertyName)}
        />
        {!isValidUrl && (
          <h5 className="error-message">
            {t('Please provide a valid theatre video url')}
          </h5>
        )}
      </div>
    </>
  );
};

export default TheatreModeVideoView;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import "../../assets/scss/scss_web/style.scss";

const DiscardChangesApplyModal = ({
  onHandleConfirm,
  onHandleCancel,
  onHandleExit,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    setTimeout(() => {
      body.className.concat('popup-active');
      setIsActive(true);
    }, 0);
    return () => {
      body.className.replace('popup-active', '');
    };
  }, []);
  return (
    <div
      className={`modal-popup ${isActive ? 'active' : ''}`}
      aria-hidden="true"
    >
      <div className={`modal-popup__dialog do-later-popup`}>
        <>
          <div className="modal-popup__header">
            <h2>
              {t(
                'You will lose all your progress! Are you sure you want to proceed?'
              )}
            </h2>
            {/* <a
              href="javascript:void(0)"
              className="btn-close closemodale"
              aria-hidden="true"
              onClick={onHandleCancel}
            >
              &times;
            </a> */}
          </div>
          <div className="assessment__endvideo--submission">
            <button className="btn" onClick={onHandleCancel}>
              {t('Cancel')}
            </button>

            <button className="btn btn--primary" onClick={onHandleConfirm}>
              {t('Proceed')}
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default DiscardChangesApplyModal;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PDFViewer from '../../../../Components/Modal/PDFViewerModal';
import zoomIn from '../../../../assets/images/svg-images/icon-zoom.svg';
import zoomOut from '../../../../assets/images/svg-images/icon-zoomout.svg';
import download from '../../../../assets/images/svg-images/icon-document-download.svg';
import DocumentViewIframe from '../../../../Components/Document/DocumentViewer';
import { getUrlExtension } from '../../../../Helper/getFileUrlExtension';

export default function ViewPDFModal({
  pdfUrl,
  pdfTitle,
  allowDownload,
  handleClose,
}) {
  const [scale, setScale] = useState(1.0);
  const [isActive, setIsActive] = useState(false);
  const [fileExtension, setFileExtension] = useState('');
  const { isAdminView } = useSelector(state => state?.auth);

  useEffect(() => {
    setFileExtension(getUrlExtension(pdfUrl));
  }, [pdfUrl]);

  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    setTimeout(() => {
      body.className.concat('popup-active');
      setIsActive(true);
    }, 0);
    return () => {
      body.className.replace('popup-active', '');
    };
  }, []);

  return (
    <div
      className={`modal-popup ${isActive ? 'active' : ''}`}
      aria-hidden="true"
    >
      <div className="modal-popup__dialog pdf-modal">
        <div className="modal-popup__title">
          <p>{pdfTitle}</p>
        </div>
        <div className="modal-popup__header">
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            &times;
          </a>
          {(allowDownload || isAdminView) && (
            <a
              href="#"
              className="btn-close btn-bigscreen"
              onClick={() => window.open(pdfUrl, '_blank')}
            >
              <img
                src={download}
                alt="fullscreen-icon"
                className="icon--fullscreen"
              />
            </a>
          )}
          {fileExtension === 'pdf' && (
            <>
              <a
                href="#"
                className="btn-close btn-zoomin"
                onClick={() => setScale(scale < 3.3 ? scale + 0.1 : scale)}
              >
                <img src={zoomIn} alt="zoomin-icon" className="icon--zoomin" />
              </a>
              <a
                href="#"
                className="btn-close btn-zoomout"
                onClick={() => setScale(scale > 0.5 ? scale - 0.1 : scale)}
              >
                <img
                  src={zoomOut}
                  alt="zoomout-icon"
                  className="icon--zoomout"
                />
              </a>
            </>
          )}
        </div>
        {fileExtension === 'pdf' ? (
          <div className="modal-popup__pdfscreen">
            <PDFViewer pdfURL={pdfUrl} scale={scale} />
          </div>
        ) : (
          <DocumentViewIframe documentUrl={pdfUrl} />
        )}
      </div>
    </div>
  );
}

import { useSelector } from 'react-redux';
import { systemConfig } from '../Common/SystemConstConfigs';

const { superAdmin } = systemConfig.roleIdByName;

const useIsSuperAdmin = () => {
  const { roleList } = useSelector(state => state?.auth?.userData);

  return [
    roleList?.length
      ? [...roleList].map(({ id }) => id).includes(superAdmin.roleReferenceId)
      : false,
  ];
};

export default useIsSuperAdmin;

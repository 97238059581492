import React from 'react';
import DocumentSlider from '../../Components/Document/DocumentSlider';

export default function DocumentWrapper({ handleDelete, documentReels = [], groupId, deleteReelPermission }) {
  return (
    <>
      {documentReels.length
        ? documentReels.map((documentReel, index) => (
            <DocumentSlider
              deleteReelPermission={deleteReelPermission}
              documentReel={documentReel}
              groupId={groupId}
              key={index}
              handleDelete={handleDelete}
            />
          ))
        : null}
    </>
  );
}

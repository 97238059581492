import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { deviceDetect } from 'react-device-detect';

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAIL,
} from '../redux/actionTypes/auth';

const useAuth = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const signIn = (username, password) => {
    dispatch({ type: AUTH_REQUEST });
    setUsername(username);
    setPassword(password);
  };

  useEffect(() => {
    const login = () => {
      Auth.signIn(username, password)
        .then(user => {
          Auth.currentCredentials().then(credential => {
            const { accessKeyId, secretAccessKey, sessionToken } = credential;
            dispatch({
              type: AUTH_SUCCESS,
              payload: {
                accessKeyId,
                secretAccessKey,
                sessionToken,
                userName: user.getUsername(),
                preferredUsername: user?.attributes?.preferred_username,
                refreshToken: user
                  .getSignInUserSession()
                  .getRefreshToken()
                  .getToken(),
                accessToken: user
                  .getSignInUserSession()
                  .getAccessToken()
                  .getJwtToken(),
                deviceData: deviceDetect(),
              },
            });
          });
          setUsername(null);
        })
        .catch(error => {
          setUsername(null);
          dispatch({
            type: AUTH_FAIL,
            payload: error,
          });
        });
    };
    if (username && password) {
      login();
    }
  }, [username, password]);

  return signIn;
};

export default useAuth;

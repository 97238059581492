import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import DottedLoader from '../Loader/DottedLoader';

export default function ViewScoresModal({
  handleClose,
  isLoading,
  scores,
  username,
  downloadAssessment,
  downloadError,
  downloadReset,
}) {
  const { t } = useTranslation();

  const renderScoresTable = () => {
    return (
      <div className="all-users-table--table">
        <div className="all-users-table--table-header">
          <div className="reel-view--table-cell">
            <h5>{t('Time/Date')}</h5>
          </div>
          <div className="reel-view--table-cell">
            <h5>{t('Assessment Score')}</h5>
          </div>
          <div className="reel-view--table-cell">
            <h5>{t('Time Spent')}</h5>
          </div>
        </div>
        <div className="all-users-table--table-body">
          {scores.map(({ id, date, score, spentTime }) => (
            <div className="reel-view--table-row" key={id}>
              <div className="reel-view--table-cell">
                <p>
                  {date
                    ? moment.utc(date).local().format('hh:mm A | DD/MM/YYYY')
                    : '-'}
                </p>
              </div>
              <div className="reel-view--table-cell">
                <a
                  onClick={() =>
                    spentTime !== '0:0 min' && downloadAssessment(id)
                  }
                >
                  <p
                    className={
                      spentTime !== '0:0 min' ? 'btn-stats-download' : ''
                    }
                  >
                    {spentTime !== '0:0 min' ? score : t('Not Completed')}
                  </p>
                </a>
              </div>
              <div className="reel-view--table-cell">
                <p>{spentTime !== '0:0 min' ? spentTime : '-'}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="reel-view--popup createreel">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{username}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            ×
          </a>
        </div>
        {isLoading ? (
          <DottedLoader />
        ) : downloadError && scores?.length ? (
          <div className="reel-view--popup-body">
            <div className="reel-view--popup-row">
              <label>{t(downloadError.message)}</label>
            </div>
            <div className="reel-view--popup-row">
              <button className="btn btn--primary" onClick={downloadReset}>
                Back
              </button>
            </div>
          </div>
        ) : (
          <div className="reel-view--popup-body noSidePadding">
            <div className="reel-view--popup-row body--row2">
              {renderScoresTable()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactPlayer from 'react-player';
import VisibilitySensor from 'react-visibility-sensor';

import {
  ADD_NOTIFICATION,
  ADD_PLAYING_INFO,
  ADD_SMOOTH_TRANSITION_MODE,
} from '../../redux/action/actionTypes';

import pause from '../../assets/images/svg-images/assessment-pause.svg';
import pause_light from '../../assets/images/svg-images/assessment-pause-light.svg';
import WatchReminderModal from '../../Components/Modal/WatchReminderModal';
import { systemConfig } from '../../Common/SystemConstConfigs';

const HomePlayerSection = ({
  bannerId = false,
  content = {},
  getWatchLaterVideos,
  history,
  isActiveSlide,
  bannerVideo = null,
  isBanner,
  groupId,
  addToWatchLater,
  removeWatchLater,
  addToFavorite,
  removeFavorite,
}) => {
  const [player, setPlayer] = useState(null);
  const [isPlay, setIsPlay] = useState(false);
  const [isPlayerStartedPlay, setIPlayerStartedPlay] = useState(false);
  const [isUserStopped, setIsUserStopped] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const [isAudioOn, setIsAudioOn] = useState(false);
  const [watchLaterVideoId, setWatchLaterVideoId] = useState(null);
  const playerInfo = useSelector(
    state => state.video && state.video.playerInfo
  );

  const {
    _id = null,
    videoId = null,
    previewVideoUrl = '',
    previewImgUrl = '',
    title = '',
    description = '',
    isPublished,
    type = '',
    isFavourite,
    isWatchLater,
    isWatched,
  } = content;
  const dispatch = useDispatch();

  const { themeInfo } = useSelector(state => state?.auth);
  const playerLoadingImageUrl = themeInfo?.playerLoadingImageUrl;

  useEffect(() => {
    if (isBanner && isActiveSlide) {
      setIsPlay(true);
    }
  }, [isBanner, isActiveSlide]);

  useEffect(() => {
    if (isPlay) {
      setIPlayerStartedPlay(true);
    }
  }, [isPlay]);

  const playVideo = () => {
    if (!isPlay) {
      dispatch({
        type: ADD_PLAYING_INFO,
        payload: [
          {
            id: bannerId || content.id,
            banner: bannerId,
            feature: !bannerId,
          },
        ],
      });
      bannerId && setIsUserStopped(false);
    } else {
      dispatch({
        type: ADD_PLAYING_INFO,
        payload: [],
      });
      bannerId && setIsUserStopped(true);
    }
    setIsPlay(!isPlay);
  };
  const muteVideoSound = boolean => {
    setIsAudioOn(boolean);
  };

  const addToMyList = isAddToWatchLater => {
    if (isAddToWatchLater) {
      addToWatchLater(videoId || _id);
    } else {
      removeWatchLater(videoId || _id);
    }
  };

  const likeBtnClick = isLike => {
    if (isLike) {
      addToFavorite(videoId || _id);
    } else {
      removeFavorite(videoId || _id);
    }
  };
  const handleTheatreMode = () => {
    dispatch({
      type: ADD_SMOOTH_TRANSITION_MODE,
    });
    setTimeout(() => {
      history.push(`/theatre${groupId ? `/${groupId}` : ''}/${videoId || _id}`);
    }, 2000);
  };

  return (
    <section className="player_section">
      <div className="container">
        <div className="player_section--wrapper">
          <VisibilitySensor
            partialVisibility
            onChange={isVisible => {
              if (!isVisible) {
                setIsPlay(false);
              }
            }}
          >
            <div
              className={`player_section__video ${
                !isPublished ? 'unpublished' : ''
              } ${isPlayerStartedPlay ? 'remove-loading-image' : ''}`}
              style={{
                backgroundImage:
                  'url(' + previewImgUrl || playerLoadingImageUrl + ')',
              }}
            >
              <div
                className="player_section__video-click"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleTheatreMode()}
                onMouseOver={() => {
                  if (!isPlay) {
                    playVideo();
                  }
                }}
              ></div>
              {(isPlay || isPlayerStartedPlay) && (
                <ReactPlayer
                  width="100%"
                  height="auto%"
                  url={previewVideoUrl}
                  playing={isVideoLoaded && isPlay}
                  muted={!isAudioOn}
                  onEnded={() => setIsPlay(false)}
                  onReady={() => setIsVideoLoaded(true)}
                  config={systemConfig.playerNoDownload}
                />
              )}
            </div>
          </VisibilitySensor>
          <div className="player_section__info">
            <div
              className={`player_section__title ${
                !groupId && isWatched ? 'watched' : ''
              }`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleTheatreMode()}
            >
              {title}
            </div>
            <div className="player_section__synopsis">{description}</div>

            {(!content || !content.isImage || !content.isLInk) && (
              <div className="player_section__controls">
                <div
                  className="player_section__controls--play"
                  onClick={() => playVideo()}
                >
                  <svg className={`icon icon--white ${!isPlay ? '' : 'hide'}`}>
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-play-solid'
                      }
                    ></use>
                  </svg>
                  <img
                    src={pause}
                    alt="pause-icon"
                    className={`dark-icon icon--pause ${isPlay ? '' : 'hide'}`}
                  />

                  <img
                    src={pause_light}
                    alt="pause-icon"
                    className={`light-icon icon--pause ${isPlay ? '' : 'hide'}`}
                  />
                </div>
                <div
                  className={`player_section__controls--audio-off  ${
                    isAudioOn ? 'hide' : ''
                  }`}
                  onClick={() => muteVideoSound(true)}
                >
                  <svg className="icon icon--white">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-audio-off'
                      }
                    ></use>
                  </svg>
                </div>
                <div
                  className={`player_section__controls--audio-on ${
                    !isAudioOn ? 'hide' : ''
                  }`}
                  onClick={() => muteVideoSound(false)}
                >
                  <svg className="icon icon--white">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-audio-on'
                      }
                    ></use>
                  </svg>
                </div>
                {!groupId && (
                  <>
                    {!isFavourite ? (
                      <div
                        className="player_section__controls--thumb-up likeBtn"
                        onClick={() => likeBtnClick(true)}
                      >
                        <svg className="icon icon--white">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-thumb-up'
                            }
                          ></use>
                        </svg>
                      </div>
                    ) : (
                      <div
                        className="player_section__controls--thumb-down likeBtn"
                        onClick={() => likeBtnClick(false)}
                      >
                        <svg className="icon icon--white">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-thumb-down'
                            }
                          ></use>
                        </svg>
                      </div>
                    )}
                    <div className="player_section__controls--myList">
                      <div
                        className={`player_section__controls--myList-add ${
                          !isWatchLater ? '' : 'hide'
                        }`}
                        onClick={() => addToMyList(true)}
                      >
                        <svg className="icon icon--white">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-mylist-add'
                            }
                          ></use>
                        </svg>
                      </div>
                      <div
                        className={`player_section__controls--myList-added ${
                          isWatchLater ? '' : 'hide'
                        }`}
                        onClick={() => addToMyList(false)}
                      >
                        <svg className="icon icon--white">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-mylist-added'
                            }
                          ></use>
                        </svg>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {watchLaterVideoId && (
        <WatchReminderModal
          onHandleConfirm={value => {
            const date = new Date(
              value.year,
              value.month,
              value.date,
              value.meridiem === 'AM' ? value.hr : parseInt(value.hr) + 12,
              value.min,
              value.sec
            );
            // schedule.scheduleJob(date, () =>
            dispatch({
              type: ADD_NOTIFICATION,
              payload: {
                videoId: bannerId || content.id,
              },
            });
            setWatchLaterVideoId(null);
            getWatchLaterVideos('add', watchLaterVideoId, 'watchLater');
          }}
          onHandleExit={() => {
            setWatchLaterVideoId(null);
            getWatchLaterVideos('add', watchLaterVideoId, 'watchLater');
          }}
          onHandleCancel={() => setWatchLaterVideoId(null)}
        />
      )}
    </section>
  );
};

export default HomePlayerSection;

import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux'; // Import Redux
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reduxThunk from 'redux-thunk'; // Import Middleware
import rootReducer from './reducers/index';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const deps = {
  history: createBrowserHistory(),
};
const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['auth'],
};
const persistedReducer = persistReducer(
  persistConfig,
  rootReducer(deps.history)
);

let middleware = [routerMiddleware(deps.history), reduxThunk];

if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware];
}

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

const persistor = persistStore(store);

export { persistor, store };

import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  FETCH_ASSESSMENT_STATS_BY_GROUP_REQUEST,
  FETCH_ASSESSMENT_STATS_BY_GROUP_SUCCESS,
  FETCH_ASSESSMENT_STATS_BY_GROUP_FAIL,
  FETCH_ASSESSMENT_STATS_BY_GROUP_RESET,
  SET_STATS_CURRENT_ASSESSMENT,
  DOWNLOAD_ASSESSMENT_STATS_SUCCESS,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_RESET,
  FETCH_ASSESSMENT_SCORES_RESET,
} from '../../../redux/actionTypes/assesment';
import useApi from '../../../Hooks/useApi';
import BreadCrumbView from '../../../Components/BreadCrumbView';
import useFetchDataApi from '../../../Hooks/useFetchDataApi';
import AssignedVideosViewModal from '../../../Components/Modal/AssignedVideosViewModal';
import ViewScoresModal from '../../../Components/Modal/ViewScoresModal';
import Loader from '../../../Components/Loader/loader';
import messageConfigs from '../../../Helper/PopupMessageConfig';
import ProceedMessageModal from '../../../Components/Modal/ProceedMessageModal';
import useReporting from '../../../Hooks/useReporting';

export default function AssessmentStats({
  history,
  groupId,
  match,
  groupDetailsData,
}) {
  const { t } = useTranslation();
  const {
    videoAssessmentId,
    reelId,
    assessmentType,
    assessmentId,
  } = match.params;
  const { groupName = null, parentGroupName = null } = groupDetailsData || {};

  const [isMidEnd, setIsMidEnd] = useState(false);
  const [assignedVideoId, setAssignedVideoId] = useState(null);
  const [searchName, setSearchName] = useState('');
  const [statsTableData, setStatsTableData] = useState([]);
  const [isViewScoresModalOpen, setIsViewScoresModalOpen] = useState(null);
  const [
    isViewAssignedVideoModalOpen,
    setIsViewAssignedVideoModalOpen,
  ] = useState(null);
  const [statsCardData, setStatsCardData] = useState({
    assessmentTitle: '',
    totalUsers: '',
    totalCompleted: '',
    createdDate: '',
    totalCompleted: '',
    reelName: '',
    totalViews: '',
    publishedDate: '',
  });

  const {
    currentStatAssessment,
    assessmentStatsByGroupData,
    assessmentStatsByGroupError,
    assessmentStatsByGroupLoading,
    assignedVideosForMidEndAssessmentLoading,
    downloadingStatsLoading,
    downloadingStatsError,
    assignedVideosForMidEndAssessmentData,
    downloadingTranscriptLoading,
    downloadingTranscriptError,
    assessmentScoresLoading,
    assessmentScoresData,
  } = useSelector(state => state?.assessment);
  const [fetchAssessmentStatsData] = useApi();
  const [downloadStatsCSV] = useReporting();
  const [downloadResultPdf] = useReporting();
  const [fetchAssessmentScores] = useFetchDataApi();
  const [fetchAssignedVideos] = useFetchDataApi();
  const dispatch = useDispatch();

  useEffect(() => {
    if (assessmentId) {
      fetchAssignedVideos({
        groupId,
        objectId: assessmentId,
        type: 'ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENT',
      });
    }
  }, [assessmentId]);

  useEffect(() => {
    setIsMidEnd(assessmentType && assessmentId);

    return () => {
      dispatch({
        type: SET_STATS_CURRENT_ASSESSMENT,
      });
    };
  }, [assessmentType, assessmentId]);

  useEffect(() => {
    if (isViewScoresModalOpen && (videoAssessmentId || assessmentId)) {
      fetchAssessmentScores({
        groupId,
        type: 'ASSESSMENT_SCORES_BY_USER',
        idList: {
          assessmentId: videoAssessmentId || assessmentId,
          userId: isViewScoresModalOpen.userId,
          videoId: assignedVideoId?.value,
        },
      });
    }

    return () => {
      dispatch({
        type: FETCH_ASSESSMENT_SCORES_RESET,
      });
    };
  }, [isViewScoresModalOpen, assessmentId]);

  useEffect(() => {
    let url = '';

    if (isMidEnd && assignedVideoId) {
      url = `/assessment/get-mid-end-assessment-stats-by-group?assessmentId=${assessmentId}${
        groupId ? `&groupId=${groupId}` : ''
      }&videoId=${assignedVideoId?.value}`;
    }

    if (!isMidEnd && videoAssessmentId && reelId) {
      url = `/assessment/get-standalone-stats-by-group?assessmentId=${videoAssessmentId}&reelId=${reelId}${
        groupId ? `&groupId=${groupId}` : ''
      }`;
    }

    if (url) {
      fetchAssessmentStatsData(
        url,
        FETCH_ASSESSMENT_STATS_BY_GROUP_REQUEST,
        FETCH_ASSESSMENT_STATS_BY_GROUP_SUCCESS,
        FETCH_ASSESSMENT_STATS_BY_GROUP_FAIL,
        null,
        '',
        'GET',
        null,
        'isReelService'
      );
    }

    return () => {
      dispatch({
        type: FETCH_ASSESSMENT_STATS_BY_GROUP_RESET,
      });
    };
  }, [groupId, videoAssessmentId, isMidEnd, assignedVideoId]);

  useEffect(() => {
    if (assessmentStatsByGroupData) {
      const {
        createdDate,
        publishedDate,
        reelName,
        totalUsers,
        totalViews,
        userList,
        assessmentTitle,
        totalCompleted,
      } = assessmentStatsByGroupData;

      let tempStatsCardData = { ...statsCardData };

      tempStatsCardData = {
        ...tempStatsCardData,
        reelName,
        totalViews,
        totalUsers,
        createdDate,
        totalCompleted,
        assessmentTitle,
        publishedDate,
      };

      setStatsCardData(tempStatsCardData);
      setStatsTableData(userList);
    }
  }, [assessmentStatsByGroupData]);

  const downloadAssessmentResult = resultId => {
    downloadResultPdf({
      type: 'DOWNLOAD_ASSESSMENT_TRANSCRIPT',
      urlParams: {
        transcriptId: resultId,
      },
      reportName: `assessment-trancscript-${
        statsCardData.assessmentTitle || currentStatAssessment?.name
      }-${isViewScoresModalOpen?.username || ''}`,
    });
  };

  const renderStatsCardView = () => {
    return (
      <>
        <div className="reel-view--page-data-cell">
          <p>{t('Total Users')}</p>
          <span>{statsCardData.totalUsers}</span>
        </div>
        <div className="reel-view--page-data-cell">
          <p>{t('Total Completed')}</p>
          <span>{statsCardData.totalCompleted}</span>
        </div>
        <div className="reel-view--page-data-cell">
          <p>{t('Created Date')}</p>
          <span>
            {statsCardData.createdDate
              ? moment
                  .utc(statsCardData.createdDate)
                  .local()
                  .format('DD/MM/YYYY')
              : '-'}
          </span>
        </div>
        <div className="reel-view--page-data-cell">
          <p>{t('Published Date')}</p>
          <span>
            {statsCardData.publishedDate
              ? moment
                  .utc(statsCardData.publishedDate)
                  .local()
                  .format('DD/MM/YYYY')
              : moment
                  .utc(statsCardData.createdDate)
                  .local()
                  .format('DD/MM/YYYY')}
          </span>
        </div>
        {isMidEnd && (
          <div
            className="reel-view--page-data-cell"
            onClick={() => setIsViewAssignedVideoModalOpen(true)}
          >
            <p>{t('Assigned Videos')}</p>
            <span>{assignedVideosForMidEndAssessmentData?.length || '0'}</span>
          </div>
        )}
      </>
    );
  };

  const downloadCsv = () => {
    downloadStatsCSV({
      type: 'DOWNLOAD_STANDALONE_ASSESSMENT_STATS',
      urlParams: {
        assessmentId: videoAssessmentId,
        reelId,
        groupId,
      },
    });
  };

  const rendStatsTableRowsView = () => {
    return statsTableData
      .filter(
        ({ username }) =>
          !searchName ||
          username?.toUpperCase().includes(searchName.toUpperCase())
      )
      .map((rowData, index) => {
        const {
          username,
          date,
          views,
          status,
          score,
          spentTime,
          attempts,
        } = rowData;

        return (
          <div
            className="all-users-table--table-row"
            key={index}
            onClick={() =>
              (views || attempts) && setIsViewScoresModalOpen(rowData)
            }
          >
            <div className="all-users-table--table-cell">
              <p>{username}</p>
            </div>
            <div className="all-users-table--table-cell">
              <p>
                {date
                  ? moment.utc(date).local().format('hh:mm A | DD/MM/YYYY')
                  : '-'}
              </p>
            </div>
            <div className="all-users-table--table-cell">
              <p>{status}</p>
            </div>
            <div className="all-users-table--table-cell">
              <p className={views || attempts ? 'btn-stats-download' : ''}>
                {views || attempts || '0'}
              </p>
            </div>
            <div className="all-users-table--table-cell">
              <p>{score && score !== '' ? score : '-'}</p>
            </div>
            <div className="all-users-table--table-cell">
              <p>{spentTime && spentTime !== '' ? spentTime : '-'}</p>
            </div>
          </div>
        );
      });
  };

  const renderTableView = () => {
    return (
      <>
        <div className="reel-view--page-data">{renderStatsCardView()}</div>
        <div className="all-users-table--table">
          <div className="all-users-table--table-header">
            <div className="all-users-table--table-cell">
              <h5>{t('Username')}</h5>
            </div>
            <div className="all-users-table--table-cell">
              <h5>{t('Time/Date')}</h5>
            </div>
            <div className="all-users-table--table-cell">
              <h5>{t('Status')}</h5>
            </div>
            <div className="all-users-table--table-cell">
              <h5>{t('Attempts')}</h5>
            </div>
            <div className="all-users-table--table-cell">
              <h5>{t('Assessment Score')}</h5>
            </div>
            <div className="all-users-table--table-cell">
              <h5>{t('Time Spent')}</h5>
            </div>
          </div>
          <div className="all-users-table--table-body">
            {rendStatsTableRowsView()}
          </div>
        </div>
      </>
    );
  };

  const renderErrorMessage = () => {
    return (
      <div className="reel-view--page-info-message">
        <h5>
          {assessmentStatsByGroupError
            ? t(messageConfigs.error.TRY_LATER)
            : t('Select an assigned video')}
        </h5>
      </div>
    );
  };

  return (
    <div
      className={`reel-view--wrapper-column loading-screen-parent ${
        assessmentStatsByGroupLoading ? 'setheight' : ''
      }`}
    >
      {assessmentStatsByGroupLoading ? (
        <Loader />
      ) : (
        <>
          <div className="reel-view--page-title">
            <div className="reel-view--page-selection">
              {parentGroupName && groupName && (
                <BreadCrumbView
                  history={history}
                  routeOptions={[
                    {
                      title: parentGroupName ? parentGroupName : '',
                      path: `/node-tree`,
                    },
                    {
                      title: groupName ? groupName : '',
                      path: `/home/${groupId}/`,
                    },
                    {
                      title: `${
                        assessmentType === 'mid-video'
                          ? 'Mid Video'
                          : assessmentType === 'end-video'
                          ? 'End Video'
                          : 'Standalone'
                      } Assessments`,
                      path: `/group-dashboard/${groupId}/assessments/${
                        assessmentType === 'mid-video'
                          ? 'mid-video'
                          : assessmentType === 'end-video'
                          ? 'end-video'
                          : 'standalone'
                      }`,
                    },
                    {
                      title:
                        statsCardData.assessmentTitle ||
                        currentStatAssessment?.name,
                      path: '#',
                    },
                  ]}
                />
              )}
              <h2>
                {statsCardData.assessmentTitle || currentStatAssessment?.name}
              </h2>
            </div>
            <div className="reel-view--page-settings">
              {isMidEnd && (
                <Dropdown
                  title="Filter By"
                  options={
                    assignedVideosForMidEndAssessmentData?.map(
                      ({ id, title }) => ({
                        value: id,
                        label: title,
                      })
                    ) || []
                  }
                  value={
                    assignedVideoId?.label?.length > 25
                      ? `${assignedVideoId.label.slice(0, 25)}...`
                      : `${assignedVideoId?.label || ''}`
                  }
                  onChange={option => setAssignedVideoId(option)}
                />
              )}
              <input
                className="form-input form-input--search"
                type="search"
                id="search"
                name="search"
                placeholder={t('Search')}
                value={searchName}
                onChange={event => setSearchName(event.target.value)}
              />
              {/* Hidden - not implemented */}
              {/*(!isMidEnd && !assessmentStatsByGroupError) && (
                <button
                  onClick={() => downloadCsv()}
                  className={`btn btn--primary btn--downloadcsv btn--long reel-table-download ${
                    downloadingStatsLoading ? 'btn--loader' : ''
                  }`}
                  disabled={downloadingStatsLoading}
                >
                  {t('Download CSV')}
                </button>
              )*/}
            </div>
          </div>
          {!isMidEnd
            ? renderTableView()
            : assignedVideoId && !assessmentStatsByGroupError
            ? renderTableView()
            : renderErrorMessage()}
        </>
      )}
      {isViewAssignedVideoModalOpen && (
        <AssignedVideosViewModal
          isLoading={assignedVideosForMidEndAssessmentLoading}
          handleClose={() => setIsViewAssignedVideoModalOpen(null)}
          assignedVideos={assignedVideosForMidEndAssessmentData || []}
          numberOfVideos={assignedVideosForMidEndAssessmentData?.length || '0'}
          assessmentTitle={
            statsCardData.assessmentTitle || currentStatAssessment?.name
          }
        />
      )}
      {isViewScoresModalOpen && (
        <ViewScoresModal
          downloadError={downloadingTranscriptError}
          isLoading={downloadingTranscriptLoading || assessmentScoresLoading}
          downloadAssessment={resultId => downloadAssessmentResult(resultId)}
          scores={assessmentScoresData || []}
          downloadReset={() =>
            dispatch({
              type: DOWNLOAD_ASSESSMENT_TRANSCRIPT_RESET,
            })
          }
          username={isViewScoresModalOpen?.username}
          handleClose={() => {
            setIsViewScoresModalOpen(null);
            dispatch({
              type: DOWNLOAD_ASSESSMENT_TRANSCRIPT_RESET,
            });
          }}
        />
      )}
      {/* Assessment stat downloading error modal */}
      {downloadingStatsError && (
        <ProceedMessageModal
          isOkayButtonShown={false}
          isSuccess={downloadingStatsError.success}
          message={downloadingStatsError.message}
          handleClose={() =>
            dispatch({
              type: DOWNLOAD_ASSESSMENT_STATS_SUCCESS,
            })
          }
          handleError={() =>
            dispatch({
              type: DOWNLOAD_ASSESSMENT_STATS_SUCCESS,
            })
          }
        />
      )}
      {/* Assessment stat downloading error modal */}
    </div>
  );
}

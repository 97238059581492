export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const FETCH_WELCOME_VIDEOS_REQUEST = 'FETCH_WELCOME_VIDEOS_REQUEST';
export const FETCH_WELCOME_VIDEOS_SUCCESS = 'FETCH_WELCOME_VIDEOS_SUCCESS';
export const FETCH_WELCOME_VIDEOS_FAIL = 'FETCH_WELCOME_VIDEOS_FAIL';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_GROUP_USERS_REQUEST = 'FETCH_GROUP_USERS_REQUEST';
export const FETCH_GROUP_USERS_SUCCESS = 'FETCH_GROUP_USERS_SUCCESS';
export const FETCH_GROUP_USERS_FAIL = 'FETCH_GROUP_USERS_FAIL';

export const FETCH_SELF_SIGUNUP_USERS_REQUEST =
  'FETCH_SELF_SIGUNUP_USERS_REQUEST';
export const FETCH_SELF_SIGUNUP_USERS_SUCCESS =
  'FETCH_SELF_SIGUNUP_USERS_SUCCESS';
export const FETCH_SELF_SIGUNUP_USERS_FAIL = 'FETCH_SELF_SIGUNUP_USERS_FAIL';

export const CHANGE_USER_STATUS_REQUEST = 'CHANGE_USER_STATUS_REQUEST';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_FAIL = 'CHANGE_USER_STATUS_FAIL';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const RESET_PASSWORD_LINK_REQUEST = 'RESET_PASSWORD_LINK_REQUEST';
export const RESET_PASSWORD_LINK_SUCCESS = 'RESET_PASSWORD_LINK_SUCCESS';
export const RESET_PASSWORD_LINK_FAIL = 'RESET_PASSWORD_LINK_FAIL';

export const CHECK_LINK_VALIDATION_REQUEST = 'CHECK_LINK_VALIDATION_REQUEST';
export const CHECK_LINK_VALIDATION_SUCCESS = 'CHECK_LINK_VALIDATION_SUCCESS';
export const CHECK_LINK_VALIDATION_FAIL = 'CHECK_LINK_VALIDATION_FAIL';

export const FETCH_USERS_ROLES_REQUEST = 'FETCH_USERS_ROLES_REQUEST';
export const FETCH_USERS_ROLES_SUCCESS = 'FETCH_USERS_ROLES_SUCCESS';
export const FETCH_USERS_ROLES_FAIL = 'FETCH_USERS_ROLES_FAIL';

export const REMOVE_USER_FROM_GROUP_REQUEST = 'REMOVE_USER_FROM_GROUP_REQUEST';
export const REMOVE_USER_FROM_GROUP_SUCCESS = 'REMOVE_USER_FROM_GROUP_SUCCESS';
export const REMOVE_USER_FROM_GROUP_FAIL = 'REMOVE_USER_FROM_GROUP_FAIL';

export const FETCH_USER_BY_ID_REQUEST = 'FETCH_USER_BY_ID_REQUEST';
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS';
export const FETCH_USER_BY_ID_FAIL = 'FETCH_USER_BY_ID_FAIL';
export const FETCH_USER_BY_ID_RESET = 'FETCH_USER_BY_ID_RESET';

export const SELF_SIGNUP_INITIAL = 'SELF_SIGNUP_INITIAL';
export const SELF_SIGNUP_REQUEST = 'SELF_SIGNUP_REQUEST';
export const SELF_SIGNUP_SUCCESS = 'SELF_SIGNUP_SUCCESS';
export const SELF_SIGNUP_FAIL = 'SELF_SIGNUP_FAIL';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const REMOVE_WELCOME_VIDEO_REQUEST = 'REMOVE_WELCOME_VIDEO_REQUEST';
export const REMOVE_WELCOME_VIDEO_SUCCESS = 'REMOVE_WELCOME_VIDEO_SUCCESS';
export const REMOVE_WELCOME_VIDEO_FAIL = 'REMOVE_WELCOME_VIDEO_FAIL';

export const DOWNLOAD_USER_REPORT_REQUEST = 'DOWNLOAD_USER_REPORT_REQUEST';
export const DOWNLOAD_USER_REPORT_SUCCESS = 'DOWNLOAD_USER_REPORT_SUCCESS';
export const DOWNLOAD_USER_REPORT_FAIL = 'DOWNLOAD_USER_REPORT_FAIL';

export const DOWNLOAD_USERS_LIST_REQUEST = 'DOWNLOAD_USERS_LIST_REQUEST';
export const DOWNLOAD_USERS_LIST_SUCCESS = 'DOWNLOAD_USERS_LIST_SUCCESS';
export const DOWNLOAD_USERS_LIST_FAIL = 'DOWNLOAD_USERS_LIST_FAIL';

export const UPDATE_SIGNUP_REQUEST = 'UPDATE_SIGNUP_REQUEST';
export const UPDATE_SIGNUP_SUCCESS = 'UPDATE_SIGNUP_SUCCESS';
export const UPDATE_SIGNUP_FAIL = 'UPDATE_SIGNUP_FAIL';

export const FETCH_ELIGIBLE_USERS_FOR_GROUP_REQUEST =
  'FETCH_ELIGIBLE_USERS_FOR_GROUP_REQUEST';
export const FETCH_ELIGIBLE_USERS_FOR_GROUP_SUCCESS =
  'FETCH_ELIGIBLE_USERS_FOR_GROUP_SUCCESS';
export const FETCH_ELIGIBLE_USERS_FOR_GROUP_FAIL =
  'FETCH_ELIGIBLE_USERS_FOR_GROUP_FAIL';

export const UPDATE_LAST_ACTIVE_REQUEST = 'UPDATE_LAST_ACTIVE_REQUEST';
export const UPDATE_LAST_ACTIVE_SUCCESS = 'UPDATE_LAST_ACTIVE_SUCCESS';
export const UPDATE_LAST_ACTIVE_FAIL = 'UPDATE_LAST_ACTIVE_FAIL';

export const FETCH_GROUPS_ROLES_BY_USERS_REQUEST =
  'FETCH_GROUPS_ROLES_BY_USERS_REQUEST';
export const FETCH_GROUPS_ROLES_BY_USERS_SUCCESS =
  'FETCH_GROUPS_ROLES_BY_USERS_SUCCESS';
export const FETCH_GROUPS_ROLES_BY_USERS_FAIL =
  'FETCH_GROUPS_ROLES_BY_USERS_FAIL';

export const FETCH_USERS_FOR_VALIDATION_REQUEST =
  'FETCH_USERS_FOR_VALIDATION_REQUEST';
export const FETCH_USERS_FOR_VALIDATION_SUCCESS =
  'FETCH_USERS_FOR_VALIDATION_SUCCESS';
export const FETCH_USERS_FOR_VALIDATION_FAIL =
  'FETCH_USERS_FOR_VALIDATION_FAIL';

export const RESET_DELETE_USER_MODAL_DETAILS =
  'RESET_DELETE_USER_MODAL_DETAILS';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { systemConfig } from '../Common/SystemConstConfigs';

import useApi from '../Hooks/useApi';
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from '../redux/actionTypes/createUser';
import {
  UPDATE_ALL_NOTICATIONS_READ_FAIL,
  UPDATE_ALL_NOTICATIONS_READ_REQUEST,
  UPDATE_ALL_NOTICATIONS_READ_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_FAIL,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_STATUS_FAIL,
  UPDATE_NOTIFICATION_STATUS_REQUEST,
  UPDATE_NOTIFICATION_STATUS_SUCCESS,
  UPDATE_EMAIL_NOTICATION_STATUS_REQUEST,
  UPDATE_EMAIL_NOTICATION_STATUS_SUCCESS,
  UPDATE_EMAIL_NOTICATION_STATUS_FAIL,
} from '../redux/actionTypes/notification';

const { notificationViewType } = systemConfig;

const useUpdateApi = () => {
  const [requestData, setRequestData] = useState();
  const [updateData] = useApi();

  const { isAdminView } = useSelector(state => state?.auth);
  const { userId: loggedInUserId } = useSelector(
    state => state?.auth?.userData
  );

  useEffect(() => {
    if (requestData) {
      switch (requestData.type) {
        case 'CHANGE_PASSWORD':
          updateData(
            `/user/resetPasswordByUser`,
            CHANGE_PASSWORD_REQUEST,
            CHANGE_PASSWORD_SUCCESS,
            CHANGE_PASSWORD_FAIL,
            requestData.data,
            '',
            'POST',
            null,
            'isUserService'
          );
          break;

        case 'UPDATE_NOTIFICATION':
          updateData(
            `/notification/updateUserNotificationSettings`,
            UPDATE_NOTIFICATION_SETTINGS_REQUEST,
            UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
            UPDATE_NOTIFICATION_SETTINGS_FAIL,
            requestData.data,
            '',
            'POST',
            null,
            'isNotificationService'
          );
          break;

        case 'UPDATE_NOTIFICATION_STATUS':
          updateData(
            `/users/${loggedInUserId}/notifications?types=${
              requestData?.notificationType === notificationViewType.rms
                ? requestData?.notificationType
                : isAdminView
                ? notificationViewType.admin
                : notificationViewType.user
            }`,
            UPDATE_NOTIFICATION_STATUS_REQUEST,
            UPDATE_NOTIFICATION_STATUS_SUCCESS,
            UPDATE_NOTIFICATION_STATUS_FAIL,
            requestData?.notificationIds,
            '',
            'PUT',
            null,
            'isWebsocketService'
          );
          break;

        case 'MARK_ALL_AS_READ':
          updateData(
            `/users/${loggedInUserId}/notifications?types=${
              isAdminView
                ? notificationViewType.admin
                : notificationViewType.user
            }${
              process.env.REACT_APP_IS_RMS_ENABLED?.toLowerCase() === 'true'
                ? `,${notificationViewType.rms}`
                : ''
            }`,
            UPDATE_ALL_NOTICATIONS_READ_REQUEST,
            UPDATE_ALL_NOTICATIONS_READ_SUCCESS,
            UPDATE_ALL_NOTICATIONS_READ_FAIL,
            null,
            '',
            'PUT',
            null,
            'isWebsocketService'
          );
          break;

        case 'UPDATE_EMAIL_NOTIFICATION_STATUS':
          updateData(
            `/users/${loggedInUserId}/user-notification-settings`,
            UPDATE_EMAIL_NOTICATION_STATUS_REQUEST,
            UPDATE_EMAIL_NOTICATION_STATUS_SUCCESS,
            UPDATE_EMAIL_NOTICATION_STATUS_FAIL,
            requestData.data,
            '',
            'PUT',
            null,
            'isWebsocketService'
          );
          break;

        default:
          break;
      }
    }
  }, [requestData]);

  const submitForm = requestData => setRequestData(requestData);

  return [submitForm];
};

export default useUpdateApi;

import React from 'react';
import ReactPaginate from 'react-paginate';

const PaginationView = ({
  pageCount,
  marginPagesDisplayed,
  pageRangeDisplayed,
  currentPage,
  onPageChange,
}) => {
  return (
    <ReactPaginate
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      forcePage={currentPage - 1}
      onPageChange={onPageChange}
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      containerClassName={'react-paginate'}
      activeClassName={'active'}
      breakClassName={'break-me'}
    />
  );
};

export default React.memo(PaginationView);

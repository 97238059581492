import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  CREATE_VIDEO_REQUEST,
  CREATE_VIDEO_SUCCESS,
  CREATE_VIDEO_FAIL,
  UPDATE_VIDEO_FAIL,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_RESET,
  CREATE_VIDEO_RESET,
} from '../../../redux/actionTypes/video';

import useApi from '../../../Hooks/useApi';

import Dropdown from 'react-dropdown';
import _ from 'lodash';
import $ from 'jquery';
import previewimg from '../../../assets/images/sample-img.png';
import {
  getAllGroupInfoByCurrentUser,
  capitalizeFirstLetter,
} from '../../../Helper/SystemManager';
import GroupMultiSelectWithMandatoryDate from '../../../Components/CreateEditComponents/GroupSelect';
import AssignRelatedMultiSelect from '../../../Components/CreateEditComponents/AssignRelatedMultiSelect';
import TagSelection from '../../../Components/CreateEditComponents/TagSelection';
import ProceedMessageModal from '../../../Components/Modal/ProceedMessageModal';
import Loader from '../../../Components/Loader/loader';
import { messageConfigs } from '../../../Helper/PopupMessageConfig';
import useFetchDataApi from '../../../Hooks/useFetchDataApi';
import ErrorMessageModal from '../../../Components/Modal/ErrorMessageModal';
import { validateFields } from '../../../Helper/ValidationsManager';
import PreviewImageView from '../../../Components/CreateEditComponents/PreviewImageView';
import PreviewVideoView from '../../../Components/CreateEditComponents/PreviewVideoView';
import TheatreModeVideoView from '../../../Components/CreateEditComponents/TheatreModeVideoView';
import MandatoryMark from '../../../Components/validation/MandatoryMark';
import SummaryBox from '../../../Components/CreateEditComponents/SummaryBox';
import useFileUpload from '../../../Hooks/useFileUpload';
import usePrivilegesApi from '../../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../../Common/UserPrivileges';
const summaryObj = {
  notes: '',
  startTimeMin: '',
  triggerTimeMin: '',
  startTimeSec: '',
  triggerTimeSec: '',
};

const groupsObj = {
  groups: [],
};
const selectedAssessmentObj = {
  isMidVideo: true,
  id: null,
  startTimeMin: '',
  triggerTimeMin: '',
  startTimeSec: '',
  triggerTimeSec: '',
};

const intialState = {
  title: '',
  description: '',
  author: '',
  tags: '',
  videoUrl: '',
  previewVideoUrl: '',
  previewImgUrl: '',
  previewImgFile: null,
  isVideoViaUrl: false,
  isPreviewVideoViaUrl: false,
  isPreviewImgViaUrl: false,
  subtitleFile: '',
  isPublished: '',
  summaries: [_.clone(summaryObj)],
  listOfGroups: [_.clone(groupsObj)],
  selectedAssessments: [_.clone(selectedAssessmentObj)],
  selectedDocuments: [],
};

const CreateVideo = ({ match, history }) => {
  const { t } = useTranslation();
  const videoId = match.params.id;
  const videoType = match.params.type;
  const groupId = match.params.groupId;
  const isHavePublishAccess = usePrivilegesApi(
    PrivilegeTypes.VIDEO.UPLOAD_VIDEO
  );
  const [videoData, setVideoData] = useState(_.cloneDeep(intialState));
  const [videoDataFiles, setVideoDataFiles] = useState({
    previewImage: '',
    previewVideo: '',
    theatreVideo: '',
  });
  const [isValidationError, setIsValidationError] = useState(null);
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [isPublishProcess, setIsPublishProcess] = useState(false);
  const [isSaveProcess, setIsSaveProcess] = useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(null);
  const [isUpdateProcess, setIsUpdateProcess] = useState(false);
  const [isValidPreImageUrl, setIsValidPreImageUrl] = useState(true);
  const [isValidPreVideoUrl, setIsValidPreVideoUrl] = useState(true);
  const [isValidTheatreVideoUrl, setIsValidTheatreVideoUrl] = useState(true);

  const dispatch = useDispatch();
  const [fetchData] = useFetchDataApi();
  const [createVideo] = useApi();
  const [updateVideo] = useApi();
  const [fetchAllGroups] = useFetchDataApi();
  const [fetchVideoDataById] = useFetchDataApi();

  const { groupInfo, userName } = useSelector(state => state?.auth?.userData);
  const { allGroupsFetchData, allGroupsFetchLoading } = useSelector(
    state => state?.group
  );
  const {
    findVideoData,
    intializeVideoData,
    updateVideoLoading,
    intializeVideoLoading,
    createVideoLoading,
    createVideoSuccess,
    createVideoFail,
    updateVideoSuccess,
    updateVideoFail,
    fileUploadingPercentage,
  } = useSelector(state => state?.video);

  const {
    documents = [],
    endAssessment = [],
    midAssessment = [],
  } = intializeVideoData;
  const [subtitleUpload, subtitleUploadUrl] = useFileUpload();
  useEffect(() => {
    subtitleUploadUrl && handleVideoData(subtitleUploadUrl, 'subtitleFile');
  }, [subtitleUploadUrl]);
  useEffect(() => {
    if (selectGroupOptions.length && groupId) {
      const createFromGroup = {
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
            name: selectGroupOptions.find(option => option.value === groupId)
              .label,
          },
        ],
      };

      const intialData = { ...videoData };
      intialData['listOfGroups'] = [_.clone(createFromGroup)];

      setVideoData(intialData);
    }
  }, [selectGroupOptions, groupId]);

  useEffect(() => {
    setTimeout(() => {
      var allPanels = $('.accordion .admin__createvideo--inner-container');
      $('.accordion h3').unbind();
      $('.accordion h3').click(function () {
        $(this)
          .parent()
          .children('.admin__createvideo--inner-container')
          .slideUp();
        $(this).addClass('closed');
        if (
          $(this)
            .parent()
            .children('.admin__createvideo--inner-container')
            .css('display') == 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__createvideo--inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });
    }, 0);
  });

  useEffect(() => {
    fetchAllGroups({ type: 'ALL_GROUPS' });
  }, []);

  useEffect(() => {
    if (createVideoSuccess) {
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message: isPublishProcess
          ? videoType === 'banner'
            ? messageConfigs.success.video.PUBLISH_BANNER
            : messageConfigs.success.video.PUBLISH_FEATURED
          : videoType === 'banner'
          ? messageConfigs.success.video.SAVE_BANNER
          : messageConfigs.success.video.SAVE_FEATURED,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () => dispatch({ type: CREATE_VIDEO_RESET });
    }
  }, [createVideoSuccess]);

  useEffect(() => {
    if (createVideoFail) {
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () => dispatch({ type: CREATE_VIDEO_RESET });
    }
  }, [createVideoFail]);

  useEffect(() => {
    if (updateVideoSuccess) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message:
          videoType === 'banner'
            ? messageConfigs.success.video.UPDATE_BANNER
            : messageConfigs.success.video.UPDATE_FEATURED,
      });

      return () => dispatch({ type: UPDATE_VIDEO_RESET });
    }
  }, [updateVideoSuccess]);

  useEffect(() => {
    if (updateVideoFail) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      return () => dispatch({ type: UPDATE_VIDEO_RESET });
    }
  }, [updateVideoFail]);

  useEffect(() => {
    if (!Array.isArray(allGroupsFetchData) && allGroupsFetchData && groupInfo) {
      const flattenedGroups = getAllGroupInfoByCurrentUser(
        userName,
        groupInfo,
        allGroupsFetchData
      );

      // Constructing select group dropdown values and removing current group
      const organizeSelectOptions = optionsArray => {
        return optionsArray.map(group => {
          return { value: group._id, label: group.title };
        });
      };

      const selectGroupOptions = organizeSelectOptions(flattenedGroups);

      setSelectGroupOptions(selectGroupOptions);

      fetchData({ type: 'INTIALIZE_CREATE_VIDEO' });
    }
  }, [allGroupsFetchData]);

  useEffect(() => {
    if (
      videoId &&
      Object.keys(intializeVideoData).length !== 0 &&
      intializeVideoData.constructor === Object
    ) {
      fetchVideoDataById({ objectId: videoId, type: 'FIND_VIDEO' });
    }
  }, [intializeVideoData]);

  useEffect(() => {
    if (findVideoData && videoId) {
      const {
        _id,
        title,
        author,
        description,
        tags,
        documentIds,
        endVideoAssessmentIds,
        isPreviewImgViaUrl,
        isPreviewVideoViaUrl,
        isVideoViaUrl,
        listOfReelIdCategoryIdAndGroups,
        midVideoAssessmentIds,
        previewImgUrl,
        previewVideoUrl,
        summaries,
        videoUrl,
        isPublished,
        subtitleS3BucketUrl,
      } = findVideoData;

      const selectedMidAssessmentsOptions = midAssessment
        .filter(({ _id }) => midVideoAssessmentIds.includes(_id))
        .map(assessment => ({
          id: assessment._id,
          startTimeMin:
            assessment.startTime !== ''
              ? assessment.startTime.split(':')[0]
              : '',
          startTimeSec:
            assessment.startTime !== ''
              ? assessment.startTime.split(':')[1]
              : '',
          triggerTimeMin:
            assessment.triggerTime !== ''
              ? assessment.triggerTime.split(':')[0]
              : '',
          triggerTimeSec:
            assessment.triggerTime !== ''
              ? assessment.triggerTime.split(':')[1]
              : '',
          isMidVideo: true,
        }));

      const selectedEndAssessmentsOptions = endAssessment
        .filter(({ _id }) => endVideoAssessmentIds.includes(_id))
        .map(assessment => ({
          id: assessment._id,
          isMidVideo: false,
          startTimeMin: '',
          startTimeSec: '',
          triggerTimeMin: '',
          triggerTimeSec: '',
        }));

      const selectedDocumentOptions = documents
        .filter(({ _id }) => documentIds.includes(_id))
        .map(documentFile => ({
          label: documentFile.title,
          value: documentFile._id,
        }));

      const selectedAssessmentOptions = [
        ...selectedEndAssessmentsOptions,
        ...selectedMidAssessmentsOptions,
      ];

      const selectedGroups = listOfReelIdCategoryIdAndGroups[0].groups.map(
        group => {
          const { _id, dueDate, isMandatory } = group;

          return {
            _id,
            dueDate,
            isMandatory,
            name:
              selectGroupOptions &&
              selectGroupOptions.length &&
              selectGroupOptions.find(option => option.value === _id).label,
          };
        }
      );

      const orgnaizedSummary = summaries.map(summary => {
        const { notes, endTime, startTime } = summary;

        return {
          notes,
          triggerTimeMin: endTime !== '' ? endTime.split(':')[0] : '',
          triggerTimeSec: endTime !== '' ? endTime.split(':')[1] : '',
          startTimeMin: startTime !== '' ? startTime.split(':')[0] : '',
          startTimeSec: startTime !== '' ? startTime.split(':')[1] : '',
        };
      });

      const editVideoData = { ...videoData };
      editVideoData['_id'] = _id;
      editVideoData['title'] = title;
      editVideoData['description'] = description;
      editVideoData['author'] = author;
      editVideoData['tags'] = tags.join();
      editVideoData['previewImgUrl'] = previewImgUrl;
      editVideoData['isPreviewImgViaUrl'] = isPreviewImgViaUrl;
      editVideoData['videoUrl'] = videoUrl;
      editVideoData['previewVideoUrl'] = previewVideoUrl;
      editVideoData['previewImgFile'] = previewImgFile;
      editVideoData['isVideoViaUrl'] = isVideoViaUrl;
      editVideoData['isPreviewVideoViaUrl'] = isPreviewVideoViaUrl;
      editVideoData['subtitleFile'] = subtitleS3BucketUrl;
      editVideoData['summaries'] = orgnaizedSummary;
      editVideoData['isPublished'] = isPublished;
      editVideoData['selectedDocuments'] = selectedDocumentOptions;
      editVideoData['selectedAssessments'] = selectedAssessmentOptions;
      editVideoData['listOfGroups'] = [{ groups: selectedGroups }];
      setVideoData(editVideoData);
    }
  }, [findVideoData]);

  const {
    _id,
    title,
    description,
    author,
    tags,
    videoUrl,
    previewVideoUrl,
    previewImgUrl,
    previewImgFile,
    isVideoViaUrl,
    isPreviewVideoViaUrl,
    isPreviewImgViaUrl,
    subtitleFile,
    summaries,
    listOfGroups,
    selectedAssessments,
    selectedDocuments,
  } = videoData;
  const { previewImage, previewVideo, theatreVideo } = videoDataFiles;

  const handleVideoData = (value, property) => {
    const tempVideoData = { ...videoData };
    tempVideoData[property] = value;
    setVideoData(tempVideoData);
  };

  const handleVideoFileData = (value, property) => {
    const tempVideoDataFiles = { ...videoDataFiles };
    tempVideoDataFiles[property] = value;
    setVideoDataFiles(tempVideoDataFiles);
  };

  const handleFileAccept = event => {
    const file = event.target.files[0];
    subtitleUpload(file);
  };

  const handleCreateVideo = isPublished => {
    const formErrors = validateFields({
      type: 'VIDEO',
      formData: videoData._id
        ? {
            title,
            listOfGroups,
          }
        : {
            title,
            listOfGroups,
            theatreVideoData: videoUrl,
          },
    });

    if (formErrors) {
      setIsValidationError(formErrors);
      return;
    }

    if (
      (!isValidPreImageUrl && isPreviewImgViaUrl) ||
      (!isValidPreVideoUrl && isPreviewVideoViaUrl) ||
      (!isValidTheatreVideoUrl && isVideoViaUrl)
    ) {
      return;
    }

    const formData = new FormData();

    const videoInfo = {
      title,
      description,
      author,
      isNativeBannerAndFeatured: true,
      isFeatured: videoType === 'featured',
      isBanner: videoType === 'banner',
      isEdit: !!videoId,
      tags: tags.split(','),
      videoUrl,
      previewVideoUrl,
      previewImgUrl,
      isVideoViaUrl: true,
      isPreviewVideoViaUrl: true,
      isPreviewImgViaUrl: isPreviewImgViaUrl,
      documentIds: selectedDocuments.map(({ value }) => value),
      summaries: summaries.map(summary => {
        const {
          notes,
          startTimeMin,
          startTimeSec,
          triggerTimeMin,
          triggerTimeSec,
        } = summary;

        return {
          notes,
          startTime: `${startTimeMin}:${startTimeSec}`,
          endTime: `${triggerTimeMin}:${triggerTimeSec}`,
        };
      }),
      listOfReelIdCategoryIdAndGroups: listOfGroups.map(value => {
        const groupData = value.groups.map(group => {
          return {
            _id: group._id,
            dueDate: group.dueDate,
            isMandatory: group.isMandatory,
          };
        });

        return { groups: groupData };
      }),
      midVideoAssessmentIds: selectedAssessments
        .filter(({ isMidVideo, id }) => id && isMidVideo)
        .map(({ id }) => id),
      endVideoAssessmentIds: selectedAssessments
        .filter(({ isMidVideo, id }) => id && !isMidVideo)
        .map(({ id }) => id),
      isPublished,
    };

    if (videoData._id) {
      videoInfo._id = videoData._id;
    }

    formData.append(
      'video',
      new Blob([JSON.stringify(videoInfo)], { type: 'application/json' })
    );

    let subTitleParam = '';

    if (subtitleFile) {
      subTitleParam = `?files=subtitle-${subtitleFile}`;
    }

    if (videoData._id) {
      updateVideo(
        `/video/add-video${subTitleParam}`,
        UPDATE_VIDEO_REQUEST,
        UPDATE_VIDEO_SUCCESS,
        UPDATE_VIDEO_FAIL,
        formData,
        '',
        'POST',
        null,
        'isReelService'
      );
    } else {
      createVideo(
        `/video/add-video${subTitleParam}`,
        CREATE_VIDEO_REQUEST,
        CREATE_VIDEO_SUCCESS,
        CREATE_VIDEO_FAIL,
        formData,
        '',
        'POST',
        null,
        'isReelService'
      );
    }
  };

  const resetFieldsWithoutInitialGroup = () => {
    const resetState = _.cloneDeep(intialState);
    const createFromGroup = {
      groups: [
        {
          _id: groupId,
          isMandatory: false,
          dueDate: '',
          name: selectGroupOptions.find(option => option.value === groupId)
            .label,
        },
      ],
    };

    resetState['listOfGroups'] = [_.clone(createFromGroup)];

    setVideoData(resetState);
  };

  const isLoading = intializeVideoLoading || allGroupsFetchLoading;

  return (
    <div>
      <div
        className={`admin__createvideo banner loading-screen-parent ${
          isLoading ? 'setheight' : ''
        }`}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="admin__createvideo--wrapper">
              <div className="admin__createvideo--page-title">
                <p className="btn--back" onClick={() => history.goBack()}>
                  {t('Back to Home')}
                </p>
                <h2>
                  {t(
                    `${videoId ? 'Edit' : 'Create'} ${capitalizeFirstLetter(
                      videoType
                    )} Video`
                  )}
                </h2>
              </div>
              <div className="admin__createvideo--container">
                <div className="admin__createvideo--left-col">
                  <div className="admin__createvideo--information">
                    <input
                      type="text"
                      id="video-titl"
                      name="video-titl"
                      placeholder={t('Video Title *')}
                      value={title}
                      onChange={e => handleVideoData(e.target.value, 'title')}
                    />
                    <input
                      type="text"
                      id="video-description"
                      name="video-description"
                      placeholder={t('Description')}
                      value={description}
                      onChange={e =>
                        handleVideoData(e.target.value, 'description')
                      }
                    />
                    <input
                      type="text"
                      id="video-author"
                      name="video-author"
                      placeholder={t('Author')}
                      value={author}
                      onChange={e => handleVideoData(e.target.value, 'author')}
                    />
                  </div>
                  <div className="admin__createvideo--reels accordion">
                    <h3>
                      {t('Groups')} <MandatoryMark />
                    </h3>
                    <GroupMultiSelectWithMandatoryDate
                      listOfGroups={listOfGroups}
                      selectGroupOptions={selectGroupOptions}
                      handleChange={handleVideoData}
                    />
                  </div>
                  <div className="admin__createvideo--assessments accordion">
                    <h3>{t('Assessment')}</h3>
                    {selectedAssessments &&
                      selectedAssessments.map(
                        (
                          {
                            isMidVideo,
                            id,
                            startTimeMin,
                            startTimeSec,
                            triggerTimeMin,
                            triggerTimeSec,
                          },
                          index
                        ) => (
                          <div className="admin__createvideo--inner-container">
                            <p className="label">{t('Assessment')}</p>
                            <input
                              type="radio"
                              className="form-input--checkbox"
                              checked={isMidVideo}
                            />
                            <label
                              onClick={() => {
                                const tempSelectedAssessments = [
                                  ...selectedAssessments,
                                ];
                                tempSelectedAssessments[
                                  index
                                ].isMidVideo = true;
                                handleVideoData(
                                  tempSelectedAssessments,
                                  'selectedAssessments'
                                );
                              }}
                            >
                              {t('Mid Video')}
                            </label>
                            <input
                              type="radio"
                              className="form-input--checkbox"
                              checked={!isMidVideo}
                            />
                            <label
                              onClick={() => {
                                const tempSelectedAssessments = [
                                  ...selectedAssessments,
                                ];
                                tempSelectedAssessments[
                                  index
                                ].isMidVideo = false;
                                handleVideoData(
                                  tempSelectedAssessments,
                                  'selectedAssessments'
                                );
                              }}
                            >
                              {t('End Video')}
                            </label>

                            <div className="section--right-column createassessment">
                              <Dropdown
                                title="Edit"
                                options={
                                  isMidVideo
                                    ? midAssessment.map(({ title, _id }) => ({
                                        label: title,
                                        value: _id,
                                      }))
                                    : endAssessment.map(({ title, _id }) => ({
                                        label: title,
                                        value: _id,
                                      }))
                                }
                                value={id}
                                onChange={({ value }) => {
                                  const tempSelectedAssessments = [
                                    ...selectedAssessments,
                                  ];
                                  const assessment = midAssessment.find(
                                    assessment => assessment._id === value
                                  );

                                  if (assessment) {
                                    const { startTime, triggerTime } =
                                      assessment;

                                    tempSelectedAssessments[index] = {
                                      isMidVideo: true,
                                      startTimeMin:
                                        startTime !== ''
                                          ? startTime.split(':')[0]
                                          : '',
                                      startTimeSec:
                                        startTime !== ''
                                          ? startTime.split(':')[1]
                                          : '',
                                      triggerTimeMin:
                                        triggerTime !== ''
                                          ? triggerTime.split(':')[0]
                                          : '',
                                      triggerTimeSec:
                                        triggerTime !== ''
                                          ? triggerTime.split(':')[1]
                                          : '',
                                    };
                                  }
                                  tempSelectedAssessments[index].id = value;
                                  handleVideoData(
                                    tempSelectedAssessments,
                                    'selectedAssessments'
                                  );
                                }}
                                placeholder={t('Select an option')}
                              />
                              {isMidVideo && (
                                <>
                                  <div className="admin__createassessment--placement-time">
                                    <p>{t('Start Time')}</p>
                                    <input
                                      type="number"
                                      id="starttime min"
                                      name="starttime min"
                                      placeholder="00"
                                      value={startTimeMin}
                                      disabled
                                    />
                                    <p>{t('min')}</p>
                                    <input
                                      type="number"
                                      id="starttime sec"
                                      name="starttime sec"
                                      placeholder="00"
                                      value={startTimeSec}
                                      disabled
                                    />
                                    <p>{t('sec')}</p>
                                  </div>
                                  <div className="admin__createassessment--placement-time">
                                    <p>{t('Trigger Time')}</p>
                                    <input
                                      type="number"
                                      id="triggertime min"
                                      name="triggertime min"
                                      placeholder="00"
                                      value={triggerTimeMin}
                                      disabled
                                    />
                                    <p>{t('min')}</p>
                                    <input
                                      type="number"
                                      id="triggertime sec"
                                      name="triggertime sec"
                                      placeholder="00"
                                      disabled
                                      value={triggerTimeSec}
                                    />
                                    <p>{t('sec')}</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    <p
                      className="addanother"
                      onClick={() => {
                        handleVideoData(
                          [
                            ...selectedAssessments,
                            _.clone(selectedAssessmentObj),
                          ],
                          'selectedAssessments'
                        );
                      }}
                    >
                      {t('Add to another Assessment')}
                    </p>
                  </div>
                  <div className="admin__createvideo--documents accordion">
                    <h3>{t('Assign to Documents')}</h3>
                    <div className="admin__createvideo--inner-container">
                      <AssignRelatedMultiSelect
                        title={'Documents'}
                        selectOptions={documents}
                        selectedValues={selectedDocuments}
                        valueType={'selectedDocuments'}
                        handleChange={handleVideoData}
                      />
                    </div>
                  </div>
                </div>
                <div className="admin__createvideo--right-col">
                  <PreviewImageView
                    title={'Video Preview Image'}
                    imageSrc={previewImgUrl || previewimg}
                    isImageViaUrl={isPreviewImgViaUrl}
                    imageUrl={previewImgUrl}
                    handleData={handleVideoData}
                    isImageViaUrlPropertyName={'isPreviewImgViaUrl'}
                    previewImgUrlPropertyName={'previewImgUrl'}
                    previewImagePropertyName={'previewImage'}
                    setIsValidUrl={setIsValidPreImageUrl}
                    isValidUrl={isValidPreImageUrl}
                  />
                  <PreviewVideoView
                    title={'Video Preview'}
                    videoUrl={previewVideoUrl}
                    videoUrlPropertyName={'previewVideoUrl'}
                    isVideoViaUrl={isPreviewVideoViaUrl}
                    isVideoViaUrlPropertyName={'isPreviewVideoViaUrl'}
                    handleData={handleVideoData}
                    videoFilepropertyName={'previewVideo'}
                    setIsValidUrl={setIsValidPreVideoUrl}
                    isValidUrl={isValidPreVideoUrl}
                  />
                  <TheatreModeVideoView
                    title={'Theatre Mode Video'}
                    videoUrl={videoUrl}
                    videoUrlPropertyName={'videoUrl'}
                    isVideoViaUrl={isVideoViaUrl}
                    isVideoViaUrlPropertyName={'isVideoViaUrl'}
                    handleData={handleVideoData}
                    videoFilepropertyName={'theatreVideo'}
                    setIsValidUrl={setIsValidTheatreVideoUrl}
                    isValidUrl={isValidTheatreVideoUrl}
                    subtitleFile={subtitleFile}
                  />
                  <div className="admin__createvideo--summary">
                    <SummaryBox
                      summaries={summaries}
                      handleChange={handleVideoData}
                      initialObject={summaryObj}
                    />
                  </div>
                  {!isVideoViaUrl && (
                    <div className="admin__createvideo--subtitle">
                      <p>{t('Subtitle')}</p>
                      <input
                        type="file"
                        id="upload-subtitle"
                        name="upload-subtitle"
                        placeholder={t('Upload Subtitle')}
                        accept=".srt"
                        onChange={e => handleFileAccept(e)}
                      />
                    </div>
                  )}
                  <div className="admin__createvideo--tags">
                    <p>{t('Tags')}</p>
                    <TagSelection tags={tags} handleChange={handleVideoData} />
                  </div>
                </div>
                <div className="admin__createvideo--buttons">
                  {!videoId ? (
                    <>
                      <button
                        className="btn btn--tertiary"
                        onClick={() => {
                          resetFieldsWithoutInitialGroup();
                        }}
                      >
                        {t('Reset')}
                      </button>
                      <button
                        className={`
                        btn btn--secondary
                        ${
                          createVideoLoading && isSaveProcess
                            ? 'btn--loader'
                            : ''
                        }
                      `}
                        onClick={() => {
                          setIsSaveProcess(true);
                          handleCreateVideo(false);
                        }}
                      >
                        {t('Save')}
                      </button>
                      {isHavePublishAccess && (
                        <button
                          className={`
                        btn btn--primary btn--last
                        ${
                          createVideoLoading && isPublishProcess
                            ? 'btn--loader'
                            : ''
                        }
                      `}
                          onClick={() => {
                            setIsPublishProcess(true);
                            handleCreateVideo(true);
                          }}
                        >
                          {t('Publish')}
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className={`btn btn--secondary ${
                        updateVideoLoading && isUpdateProcess
                          ? 'btn--loader'
                          : ''
                      }`}
                      onClick={() => {
                        setIsUpdateProcess(true);
                        handleCreateVideo(videoData.isPublished);
                      }}
                      disabled={updateVideoLoading && isUpdateProcess}
                    >
                      {t('Update')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isSubmitSuccessModalOpen && (
        <ProceedMessageModal
          isSuccess={isSubmitSuccessModalOpen.isSuccess}
          message={isSubmitSuccessModalOpen.message}
          handleClose={() => setIsSubmitSuccessModalOpen(null)}
          handleSuccess={() => {
            setIsSubmitSuccessModalOpen(null);
            history.goBack();
          }}
          handleError={() => setIsSubmitSuccessModalOpen(null)}
        />
      )}
      {isValidationError && (
        <ErrorMessageModal
          errorData={isValidationError}
          handleSubmit={() => setIsValidationError(null)}
        />
      )}
    </div>
  );
};

export default CreateVideo;

import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import awsConfiguration from '../config/awsCognitoSettings';
import { UPDATE_FILE_UPLOADING_PERCENTAGE } from '../redux/actionTypes/video';

const useFileUpload = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { s3BucketData } = useSelector(state => state?.auth);

  const urlTemplate = `https://${
    s3BucketData?.bucketName || awsConfiguration?.Storage?.AWSS3?.bucket || ''
  }.s3.amazonaws.com${s3BucketData?.fileKey || `/public/`}{FILE_NAME}`;

  useEffect(() => {
    if (uploadingFile) {
      const sanitizedFileName = uploadingFile.name
        .split('.')
        .slice(0, -1)
        .join('_')
        .replace(/\W/g, '');
      const fileExtension = uploadingFile.name.split('.').pop();
      Storage.put(
        `${Date.now()}-${sanitizedFileName}.${fileExtension}`,
        uploadingFile,
        {
          contentType: uploadingFile.type,
          progressCallback(progress) {
            const { loaded, total } = progress;

            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch({
              type: UPDATE_FILE_UPLOADING_PERCENTAGE,
              payload: percentageProgress,
            });
            setUploadProgress(percentageProgress);
          },
        }
      )
        .then(result => setUrl(urlTemplate.replace('{FILE_NAME}', result?.key)))
        .catch(err => {
          console.log(err);
        });
      setUploadingFile(null);
    }
  }, [uploadingFile]);
  return [setUploadingFile, url, uploadProgress];
};

export default useFileUpload;

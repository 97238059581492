import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useApi from '../../../../Hooks/useApi';
import {
  UPDATE_REEL_REQUEST,
  UPDATE_REEL_SUCCESS,
  UPDATE_REEL_FAIL,
} from '../../../../redux/actionTypes/reels';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import DottedLoader from '../../../../Components/Loader/DottedLoader';

export default function UpdateReelModal({
  type,
  selectedReel,
  handleClose,
  fetchReels,
  currentReelNames,
  groupId,
}) {
  const { t } = useTranslation();
  const [groupUserCount, setGroupUserCount] = useState([]);
  const [isReelNameExist, setIsReelNameExist] = useState(false);
  const [updateReelData, setUpdateReelData] = useState({
    type,
    _id: selectedReel._id,
    name: selectedReel.name,
  });

  const {
    updateReelSuccess,
    updateReelLoading,
    updateReelError,
    reelRenameWarningData,
    reelRenameWarningLoading,
  } = useSelector(state => state?.reel);

  const dispatch = useDispatch();
  const [updateReelName] = useApi();
  const [fetchReelRenameData] = useFetchDataApi();

  useEffect(() => {
    if (
      updateReelData.name !== '' &&
      currentReelNames &&
      currentReelNames.length
    ) {
      setIsReelNameExist(
        currentReelNames.some(
          reelName =>
            reelName.toLowerCase() === updateReelData.name.toLowerCase()
        )
      );
    }
  }, [updateReelData, currentReelNames]);

  useEffect(() => {
    fetchReelRenameData({
      groupId,
      type: 'REEL_RENAME_WARNINGS',
      reelId: selectedReel._id,
    });
  }, []);

  useEffect(() => {
    if (reelRenameWarningData) {
      setGroupUserCount(
        reelRenameWarningData.map(({ title, users }) => ({
          groupName: title,
          userCount: users,
        }))
      );
    }
  }, [reelRenameWarningData]);

  useEffect(() => {
    if (updateReelSuccess) {
      setGroupUserCount([]);
      dispatch({ type: UPDATE_REEL_SUCCESS, payload: null });
      handleClose();
      fetchReels();
    }
  }, [updateReelSuccess]);

  const handleUpdateReelName = value => {
    if (updateReelError) {
      dispatch({ type: UPDATE_REEL_SUCCESS, payload: null });
    }

    const tempData = { ...updateReelData };

    tempData['name'] = value;

    setUpdateReelData(tempData);
  };

  const handleSubmit = () => {
    updateReelName(
      '/reel/add-reel',
      UPDATE_REEL_REQUEST,
      UPDATE_REEL_SUCCESS,
      UPDATE_REEL_FAIL,
      updateReelData,
      '',
      'POST',
      null,
      'isReelService'
    );
  };

  const { name } = updateReelData;

  const renderRenameReelForm = () => {
    return (
      <div className="reel-view--popup-body">
        <div className="reel-view--popup-row">
          <label>{t('Reel Name')}</label>
          <div className="reel-view--popup-field">
            <input
              type="text"
              placeholder={t('Reel Name')}
              value={name}
              onChange={event => handleUpdateReelName(event.target.value)}
            />
          </div>
          {(isReelNameExist || updateReelError) && (
            <div className="reel-view--popup-row warning">
              <h4>
                <span>{t('Error:')}</span> {t('Reel Name Already Exists!')}
              </h4>
            </div>
          )}
        </div>
        {groupUserCount.length ? (
          <div className="reel-view--popup-row warning">
            <h4>
              <span>{t('Warning:')}</span>{' '}
              {t('Changing a reel name will affect below groups as well!')}
            </h4>
            <div className="reel-view--popup-row-innerrow--wrapper">
              {groupUserCount.map(({ groupName, userCount }, index) => (
                <div className="reel-view--popup-row-innerrow" key={index}>
                  <p>{groupName}</p>
                  <p>
                    {userCount || '0'} {t('Users have access to this group')}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div className="reel-view--popup-row">
          <button
            className={`
              btn btn--primary
              ${updateReelLoading ? 'btn--loader' : ''}
            `}
            onClick={handleSubmit}
          >
            {t('Change Name')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="reel-view--popup changereelname">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('Change Reel Name')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={event => {
              if (reelRenameWarningLoading) {
                event.preventDefault();
              } else {
                dispatch({ type: UPDATE_REEL_SUCCESS, payload: null });
                setGroupUserCount([]);
                handleClose();
              }
            }}
          >
            &times;
          </a>
        </div>
        {reelRenameWarningLoading ? <DottedLoader /> : renderRenameReelForm()}
      </div>
    </div>
  );
}

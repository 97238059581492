import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import deleteIcon_white from '../../../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../../../assets/images/svg-images/icon-edit-white.svg';
import warning from '../../../../assets/images/svg-images/icon-warning.svg';
import publish from '../../../../assets/images/svg-images/icon-upload.svg';

import useDeleteApi from '../../../../Hooks/useDeleteApi';
import useChangePublishedStatusApi from '../../../../Hooks/useChangePublishedStatusApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import useCheckPermission from '../../../../Hooks/useCheckPermission';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import Loader from '../../../../Components/Loader/loader';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import { ToolTipConfig } from '../../../../Common/ToolTipConfig';
import AdminDashboardSelectionDropDown from '../../../../Components/Admin/AdminDashboardSelectionDropDown';
import ActionDropdown from '../../../../Components/Admin/ActionDropdown';

const SUPER_ADMIN_ID = 4;

export default function AllAssessments({ history, match }) {
  const { t } = useTranslation();

  const canEditAssessment = useCheckPermission(
    PrivilegeTypes.ASSESSMENT.EDIT_ASSESSMENT_ON_MASTER
  );
  const canDeleteAssessment = useCheckPermission(
    PrivilegeTypes.ASSESSMENT.DELETE_ASSESSMENT_ON_MASTER
  );
  const canSuspendAssessment = useCheckPermission(
    PrivilegeTypes.ASSESSMENT.SUSPEND_PUBLISH_ASSESSMENT_ON_MASTER
  );

  const [isGridView, setIsGridView] = useState(true);
  const [assessmentType, setAssessmentType] = useState('mid-video');
  const [searchAssessmentName, setSearchAssessmentName] = useState('');
  const [organizedAssessments, setOrganizedAssessments] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(
    null
  );
  const { roleList } = useSelector(state => state?.auth?.userData);
  const {
    suspendAssessmentLoading,
    midEndAssementsByGroupFetchData,
    standaloneAssementsByGroupFetchData,
    midEndAssementsByGroupFetchDataLoading,
    standaloneAssementsByGroupFetchDataLoading,
    deleteMidEndAssessmentLoading,
    deleteStandaloneAssessmentLoading,
  } = useSelector(state => state?.assessment);
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;

  const currentUrl = window.location.pathname.split('/').slice(0, -1).join('/');

  const [fetchData] = useFetchDataApi();
  const [updateIsPublishedStatus] = useChangePublishedStatusApi();
  const [deleteAssessment] = useDeleteApi();

  const assessmentTypeName = match.params.assessmentType;

  useEffect(() => {
    if (roleList && roleList.length > 0) {
      setIsSuperAdmin(
        [...roleList].map(({ id }) => id).includes(SUPER_ADMIN_ID)
      );
    }
  }, [roleList]);

  const getAllAssessments = placementType => fetchData({ type: placementType });

  useEffect(() => {
    if (standaloneAssementsByGroupFetchData) {
      const organizedAssessments = standaloneAssementsByGroupFetchData.map(
        assessment => {
          const { title, tileImageUrl, isBanner } = assessment;

          return {
            ...assessment,
            name: title,
            imageUrl: tileImageUrl || defaultAssessment,
            bannerOrFeatured: isBanner ? 'banner' : 'featured',
          };
        }
      );

      setOrganizedAssessments(organizedAssessments);
    }
  }, [standaloneAssementsByGroupFetchData]);

  useEffect(() => {
    if (midEndAssementsByGroupFetchData) {
      const organizedAssessments = midEndAssementsByGroupFetchData.map(
        assessment => {
          const { title, previewImgUrl } = assessment;

          return {
            ...assessment,
            name: title,
            imageUrl: previewImgUrl || defaultAssessment,
          };
        }
      );

      setOrganizedAssessments(organizedAssessments);
    }
  }, [midEndAssementsByGroupFetchData]);

  useEffect(() => {
    if (!deleteStandaloneAssessmentLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteStandaloneAssessmentLoading]);

  useEffect(() => {
    if (!deleteMidEndAssessmentLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteMidEndAssessmentLoading]);

  const setActiveTab = assessmentType => {
    setAssessmentType(assessmentType);
    setOrganizedAssessments([]);

    switch (assessmentType) {
      case 'mid-video':
        getAllAssessments('MID');
        break;

      case 'end-video':
        getAllAssessments('END');
        break;

      case 'standalone':
        getAllAssessments('STANDALONE');
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setActiveTab(assessmentTypeName);
  }, [assessmentTypeName]);

  const hanldeIsPublishedStatus = assessmentId => {
    updateIsPublishedStatus({
      _id: assessmentId,
      type: 'ASSESSMENT',
    });
  };

  const handleEdit = assessment => {
    if (assessment.isNativeBannerAndFeatured) {
      history.push(
        `/edit-assessment/${assessment.bannerOrFeatured}/${assessment._id}`
      );
    } else {
      history.push(`/edit-assessment/${assessment._id}`);
    }
  };

  const setAssessmentListTableActions = (value, assessment) => {
    switch (value) {
      case 'Edit':
        handleEdit(assessment);
        break;

      case 'Suspend':
        hanldeIsPublishedStatus(assessment._id);
        break;

      case 'Publish':
        hanldeIsPublishedStatus(assessment._id);
        break;

      case 'Delete':
        handleDelete(assessment._id, assessment.videoId);
        break;

      default:
        break;
    }
  };

  const handleDelete = (id, videoId) => {
    let deleteRequestData = {
      viewType: 'ALL_ASSESSMENTS',
    };

    switch (assessmentType) {
      case 'standalone':
        deleteRequestData = {
          ...deleteRequestData,
          vid_doc_standalone_Id: id,
          type: 'STANDALONE_ASSESSMENT',
        };
        break;

      case 'mid-video':
        deleteRequestData = {
          ...deleteRequestData,
          videoId,
          assessmentId: id,
          placement: 'MID',
          type: 'MID_END_ASSESSMENT',
        };
        break;

      case 'end-video':
        deleteRequestData = {
          ...deleteRequestData,
          videoId,
          assessmentId: id,
          placement: 'END',
          type: 'MID_END_ASSESSMENT',
        };
        break;

      default:
        break;
    }

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const renderAllAssessmentsView = () => {
    return organizedAssessments
      .filter(
        ({ name }) =>
          searchAssessmentName === '' ||
          name?.toUpperCase().includes(searchAssessmentName.toUpperCase())
      )
      .map((assessment, index) => {
        if (isGridView) {
          return renderGridTile(assessment, index);
        } else {
          return renderTableRow(assessment, index);
        }
      });
  };

  const renderGridTile = (gridObject, index) => {
    const { _id, name, imageUrl, videoId, groupIds, isPublished } = gridObject;

    const isHaveEditAccess =
      groupIds &&
      groupIds.some(id => {
        return canEditAssessment[id];
      });

    const isHaveSuspendPublishAccess =
      groupIds &&
      groupIds.some(id => {
        return canSuspendAssessment[id];
      });

    const isHaveDeleteAccess =
      groupIds &&
      groupIds.some(id => {
        return canDeleteAssessment[id];
      });

    return (
      <div className="reel-view--grid-video-wrapper" key={index}>
        <div
          className={`reel-view--grid-video ${
            !isPublished ? 'unpublished' : ''
          }`}
        >
          <div
            className="reel-view--grid-video-unpublished"
            onClick={() => {
              if (videoId) {
                history.push(`/theatre/${videoId}`);
              } else {
                history.push(`/assessment/${_id}`);
              }
            }}
          ></div>
          <img
            src={imageUrl}
            alt="reel-thumbnail"
            className="reel-thumbnail"
            onError={event => {
              event.target.onerror = null;
              event.target.src = defaultAssessment;
            }}
            onClick={() => {
              if (videoId) {
                history.push(`/theatre/${videoId}`);
              } else {
                history.push(`/assessment/${_id}`);
              }
            }}
          />
          <div className="reel-view--grid-video-settings">
            {(isSuperAdmin || isHaveEditAccess) && (
              <img
                src={edit_white}
                alt="edit-icon"
                className="icon--edit-white"
                onClick={() => handleEdit(gridObject)}
              />
            )}
            {(isSuperAdmin || isHaveSuspendPublishAccess) && (
              <img
                src={isPublished ? warning : publish}
                alt={isPublished ? 'warning-icon' : 'publish-icon'}
                className={isPublished ? 'icon--warning' : 'icon--publish'}
                onClick={() => hanldeIsPublishedStatus(_id)}
              />
            )}
            {(isSuperAdmin || isHaveDeleteAccess) && (
              <img
                src={deleteIcon_white}
                alt="delete-icon"
                className="icon--delete-white"
                onClick={() => handleDelete(_id, videoId)}
              />
            )}
            {/* <div className="reel-view--grid-video-settings-stats">
              <img
                src={stats}
                alt="stats-icon"
                className="icon--stats"
                onClick={() => handleViewStats(_id)}
              />
            </div> */}
          </div>
        </div>
        <p>{name}</p>
      </div>
    );
  };

  const renderTableRow = (rowItem, index) => {
    const {
      name,
      imageUrl,
      isPublished,
      groupIds,
      date,
      numberOfVideos,
      completedCount,
    } = rowItem;

    const isHaveEditAccess =
      groupIds &&
      groupIds.some(id => {
        return canEditAssessment[id];
      });

    const isHaveSuspendPublishAccess =
      groupIds &&
      groupIds.some(id => {
        return canSuspendAssessment[id];
      });

    const isHaveDeleteAccess =
      groupIds &&
      groupIds.some(id => {
        return canDeleteAssessment[id];
      });

    return (
      <div key={index} className="reel-view--table-row">
        <div className="reel-view--table-cell">
          <img
            src={imageUrl}
            className="reel-view--table-backgroundimg"
            onError={event => {
              event.target.onerror = null;
              event.target.src = defaultAssessment;
            }}
          />
        </div>
        <div className="reel-view--table-cell">
          <p>{name}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>
            {date
              ? moment.utc(date).local().format('DD/MM/YYYY')
              : '-'}
          </p>
        </div>
        {assessmentType !== 'standalone' && (
          <div className="reel-view--table-cell">
            <p>{numberOfVideos}</p>
          </div>
        )}
        {assessmentType === 'standalone' && (
          <div className="reel-view--table-cell">
            <p>{completedCount || '0'}</p>
          </div>
        )}
        <div className="reel-view--table-cell">
          {(isSuperAdmin ||
            isHaveSuspendPublishAccess ||
            isHaveDeleteAccess ||
            isHaveEditAccess) && (
            <ActionDropdown
              ishaveEdit={isSuperAdmin || isHaveEditAccess}
              ishaveSuspend={
                (isSuperAdmin || isHaveSuspendPublishAccess) && isPublished
              }
              ishavePublish={
                (isSuperAdmin || isHaveSuspendPublishAccess) && !isPublished
              }
              ishaveDelete={isSuperAdmin || isHaveDeleteAccess}
              handleOnchange={option =>
                setAssessmentListTableActions(option.value, rowItem)
              }
            />
          )}
        </div>
      </div>
    );
  };

  // const handleViewStats = (id) => {
  //   switch (assessmentType) {
  //     case 'standalone':
  //       history.push(`/search-assessments/standalone/${id}`);
  //       break;

  //     default:
  //       break;
  //   }
  // }

  const isLoading =
    midEndAssementsByGroupFetchDataLoading ||
    standaloneAssementsByGroupFetchDataLoading ||
    suspendAssessmentLoading;

  return (
    <div
      className={`all-users-table allreels allassessments loading-screen-parent ${
        isLoading ? 'setheight' : ''
      }`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="all-users-table--wrapper">
            <div className="all-users-table--page-title">
              <div className="all-users-table--page-selection">
                <AdminDashboardSelectionDropDown
                  history={history}
                  selected={'ASSESSMENTS'}
                />
              </div>
              <div className="all-users-table--page-settings">
                <input
                  className="form-input form-input--search"
                  type="search"
                  id="search"
                  name="search"
                  placeholder={t('Search')}
                  value={searchAssessmentName}
                  onChange={event =>
                    setSearchAssessmentName(event.target.value)
                  }
                />
                {/* <Dropdown title="Filter By" options={[]} /> */}
                <button
                  className="btn btn--primary btn--createnew btn--long"
                  onClick={() =>
                    history.push(`/create-assessment/${assessmentType}`)
                  }
                >
                  {t('Create Assessment')}
                </button>
                <div
                  className={`all-users-table--image grid ${
                    isGridView ? 'active' : ''
                  }`}
                  title={t(ToolTipConfig.general.gridView)}
                  onClick={() => setIsGridView(true)}
                />
                <div
                  className={`all-users-table--image list ${
                    !isGridView ? 'active' : ''
                  }`}
                  title={t(ToolTipConfig.general.tableView)}
                  onClick={() => setIsGridView(false)}
                />
              </div>
            </div>
            <div className="reel-view--page-reelselection">
              <p
                className={assessmentType === 'mid-video' ? 'active' : ''}
                onClick={() => history.push(`${currentUrl}/mid-video`)}
              >
                {t('Mid Video')}
              </p>
              <p
                className={assessmentType === 'end-video' ? 'active' : ''}
                onClick={() => history.push(`${currentUrl}/end-video`)}
              >
                {t('End Video')}
              </p>
              <p
                className={assessmentType === 'standalone' ? 'active' : ''}
                onClick={() => history.push(`${currentUrl}/standalone`)}
              >
                {t('Standalone')}
              </p>
            </div>
            {isGridView ? (
              <div className="all-users-table--grid">
                <div className="all-users-table--grid-wrapper">
                  {renderAllAssessmentsView()}
                </div>
              </div>
            ) : (
              <div className="all-users-table--table">
                <div className="all-users-table--table-header">
                  <div className="reel-view--table-cell">
                    <h5>{t('Preview')}</h5>
                  </div>
                  <div className="reel-view--table-cell">
                    <h5>{t('Title')}</h5>
                  </div>
                  <div className="reel-view--table-cell">
                    <h5>{t('Date Created')}</h5>
                  </div>
                  {assessmentType !== 'standalone' && (
                    <div className="reel-view--table-cell">
                      <h5>{t('No. of Videos')}</h5>
                    </div>
                  )}
                  {assessmentType === 'standalone' && (
                    <div className="reel-view--table-cell">
                      <h5>{t('Total Views')}</h5>
                    </div>
                  )}
                  <div className="all-users-table--table-cell">
                    {/* Dropdown column */}
                  </div>
                </div>
                <div className="all-users-table--table-body">
                  {renderAllAssessmentsView()}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={messageConfigs.deleteConfirm.title}
          message={messageConfigs.deleteConfirm.assessment}
          handleSuccess={() => deleteAssessment(isDeleteConfirmModalOpen)}
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
          isLoading={
            deleteMidEndAssessmentLoading || deleteStandaloneAssessmentLoading
          }
        />
      )}
    </div>
  );
}

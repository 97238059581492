import React from 'react';

export default function DottedLoader() {
  return (
    <div className="loading--animation-area">
      <div className="loading-dotted-circle">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

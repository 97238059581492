import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

// import "../../assets/scss/scss_web/style.scss";

const StatisticsModal = ({ videoId, onHandleConfirm, onHandleCancel }) => {
  const [statisticsData, setStatisticsData] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const video = useSelector((state) => state.video);
  useEffect(() => {
    axios
      .get(
        `http://ec2-15-206-164-217.ap-south-1.compute.amazonaws.com:9085/video/getCounterObject?videoId=${videoId}`
      )
      .then((response) => {
        const countArray = response.data.data;
        const user1CountArray = countArray.filter(
          (countObj) => countObj.userEmail === "vtpuser1@gmail.com"
        );
        const user2CountArray = countArray.filter(
          (countObj) => countObj.userEmail === "vtpuser2@gmail.com"
        );
        const createUserRecord = (userName, countArray) => {
          console.log(countArray, "countArray");
          return {
            userName,
            date: countArray.length
              ? moment(countArray[countArray.length - 1].Date).format("MMMM Do YYYY")
              : "-",
            status: countArray.length
              ? countArray[countArray.length - 1].assessmentResult
                ? "Completed"
                : "Watched"
              : "-",
            views: countArray.length,
            assessmentScore:
              countArray.length && countArray[countArray.length - 1].assessmentResult
                ? countArray[countArray.length - 1].assessmentResult
                : "-",
            timeSpent:
              countArray.length && countArray[countArray.length - 1].assessmentDurationTaken
                ? countArray[countArray.length - 1].assessmentDurationTaken
                : "-",
          };
        };
        const statisticsData = {
          totalViews: user1CountArray.length + user2CountArray.length,
          totalUsers: 2,
          viewRecords: [],
        };

        statisticsData.viewRecords[0] = createUserRecord("vtpuser1@gmail.com", user1CountArray);
        statisticsData.viewRecords[1] = createUserRecord("vtpuser2@gmail.com", user2CountArray);
        setStatisticsData(statisticsData);
      })
      .catch(function (error) {
        console.log(error);
      });
    const body = document.getElementsByTagName("BODY")[0];
    setTimeout(() => {
      body.className.concat("popup-active");
      setIsActive(true);
    }, 0);
    return () => {
      body.className.replace("popup-active", "");
    };
  }, []);
  console.log(statisticsData, "statisticsData");
  return (
    <div className={`modal-popup ${isActive ? "active" : ""}`} aria-hidden="true">
      <div className={`modal-popup__dialog statisticspopup`}>
        <>
          <div className="modal-popup__header">
            <h2>Statistics - {video.data.videos[videoId - 1].title}</h2>
          </div>
          <div className="statspopup">
            {statisticsData && (
              <div className="statspopup__wrapper">
                <div className="statspopup__header">
                  <div className="statspopup__header--content">
                    <div className="statspopup__header--content-title">
                      <h4>Total Views</h4>
                    </div>
                    <div className="statspopup__header--content-count">
                      <h4>{statisticsData.totalViews}</h4>
                    </div>
                  </div>
                  <div className="statspopup__header--content">
                    <div className="statspopup__header--content-title">
                      <h4>Total Users</h4>
                    </div>
                    <div className="statspopup__header--content-count">
                      <h4>{statisticsData.totalUsers}</h4>
                    </div>
                  </div>
                  <div className="statspopup__header--content">
                    <div className="statspopup__header--content-title">
                      <h4>Total Reels</h4>
                    </div>
                    <div className="statspopup__header--content-count">
                      <h4>2</h4>
                    </div>
                  </div>
                  <div className="statspopup__header--content">
                    <div className="statspopup__header--content-title">
                      <h4>Total Groups</h4>
                    </div>
                    <div className="statspopup__header--content-count">
                      <h4>6</h4>
                    </div>
                  </div>
                  <div className="statspopup__header--content">
                    <div className="statspopup__header--content-title">
                      <h4>Published date</h4>
                    </div>
                    <div className="statspopup__header--content-count">
                      <h4>02/03/2020</h4>
                    </div>
                  </div>
                </div>
                <div className="statspopup__data">
                  <div className="statspopup__data--table">
                    <div className="statspopup__data--table-header">
                      <div className="statspopup__data--table-row">
                        <div className="statspopup__data--table-cell">
                          <h4>Username</h4>
                        </div>
                        <div className="statspopup__data--table-cell">
                          <h4>Time/Date</h4>
                        </div>
                        <div className="statspopup__data--table-cell">
                          <h4>Status</h4>
                        </div>
                        <div className="statspopup__data--table-cell">
                          <h4>Views</h4>
                        </div>
                        <div className="statspopup__data--table-cell">
                          <h4>Assessment Score</h4>
                        </div>
                        <div className="statspopup__data--table-cell">
                          <h4>Time Spent</h4>
                        </div>
                      </div>
                    </div>
                    <div className="statspopup__data--table-body">
                      {statisticsData.viewRecords.map((viewRecord, index) => (
                        <div className="statspopup__data--table-row" key={index}>
                          <div className="statspopup__data--table-cell">
                            <p>{viewRecord.userName}</p>
                          </div>
                          <div className="statspopup__data--table-cell">
                            <p>{viewRecord.date}</p>
                          </div>
                          <div className="statspopup__data--table-cell">
                            <p>{viewRecord.status}</p>
                          </div>
                          <div className="statspopup__data--table-cell">
                            <p>{viewRecord.views}</p>
                          </div>
                          <div className="statspopup__data--table-cell">
                            <p>{viewRecord.assessmentScore}</p>
                          </div>
                          <div className="statspopup__data--table-cell">
                            <p>{viewRecord.timeSpent}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="assessment__endvideo--submission">
            <button className="btn" onClick={onHandleCancel}>
              Cancel
            </button>

            <button className="btn btn--primary" onClick={onHandleConfirm}>
              Download CSV
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default StatisticsModal;

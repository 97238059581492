import React from 'react';
import useNotificationRoute from '../../Hooks/useNotificationRoutes';

const Notification = ({ notificationsList }) => {
  const [linkNotification] = useNotificationRoute();

  return (
    <>
      {notificationsList?.map((notificationObject, index) => (
        <div
          key={index}
          className={`notification--alert ${
            !notificationObject.seenAt ? 'new' : ''
          }`}
          onClick={() => linkNotification(notificationObject)}
        >
          <p>{notificationObject.header}</p>
          <span>{notificationObject.message}</span>
        </div>
      ))}
    </>
  );
};

export default Notification;

import {
  INTIALIZE_HOME_DATA_REQUEST,
  INTIALIZE_HOME_DATA_SUCCESS,
  INTIALIZE_HOME_DATA_FAIL,
  FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST,
  FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS,
  FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL,
  FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST,
  FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS,
  FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL,
  FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST,
  FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS,
  FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL,
} from '../actionTypes/homeData';

const homeDataReducer = (
  state = {
    intializehomeDataLoading: false,
    intializehomeDataData: null,
    intializehomeDataError: null,
    videoReelsForLandingPageLoading: false,
    videoReelsForLandingPageData: null,
    videoReelsForLandingPageError: null,
    documentReelsForLandingPageLoading: false,
    documentReelsForLandingPageData: null,
    documentReelsForLandingPageError: null,
    bannerFeaturedForLandingPageLoading: false,
    bannerFeaturedForLandingPageData: null,
    bannerFeaturedForLandingPageError: null,
  },
  action
) => {
  switch (action.type) {
    case INTIALIZE_HOME_DATA_REQUEST:
      return {
        ...state,
        intializehomeDataLoading: true,
        intializehomeDataData: null,
        intializehomeDataError: null,
      };

    case INTIALIZE_HOME_DATA_SUCCESS:
      return {
        ...state,
        intializehomeDataLoading: false,
        intializehomeDataData: action.payload,
        intializehomeDataError: null,
      };

    case INTIALIZE_HOME_DATA_FAIL:
      return {
        ...state,
        intializehomeDataLoading: false,
        intializehomeDataData: null,
        intializehomeDataError: true,
      };

    case FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST:
      return {
        ...state,
        videoReelsForLandingPageLoading: true,
        videoReelsForLandingPageData: null,
        videoReelsForLandingPageError: null,
      };

    case FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS:
      return {
        ...state,
        videoReelsForLandingPageLoading: false,
        videoReelsForLandingPageData: action.payload,
        videoReelsForLandingPageError: null,
      };

    case FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL:
      return {
        ...state,
        videoReelsForLandingPageLoading: false,
        videoReelsForLandingPageData: null,
        videoReelsForLandingPageError: true,
      };

    case FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST:
      return {
        ...state,
        documentReelsForLandingPageLoading: true,
        documentReelsForLandingPageData: null,
        documentReelsForLandingPageError: null,
      };

    case FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS:
      return {
        ...state,
        documentReelsForLandingPageLoading: false,
        documentReelsForLandingPageData: action.payload,
        documentReelsForLandingPageError: null,
      };

    case FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL:
      return {
        ...state,
        documentReelsForLandingPageLoading: false,
        documentReelsForLandingPageData: null,
        documentReelsForLandingPageError: true,
      };

    case FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST:
      return {
        ...state,
        bannerFeaturedForLandingPageLoading: true,
        bannerFeaturedForLandingPageData: null,
        bannerFeaturedForLandingPageError: null,
      };

    case FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS:
      return {
        ...state,
        bannerFeaturedForLandingPageLoading: false,
        bannerFeaturedForLandingPageData: action.payload,
        bannerFeaturedForLandingPageError: null,
      };

    case FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL:
      return {
        ...state,
        bannerFeaturedForLandingPageLoading: false,
        bannerFeaturedForLandingPageData: null,
        bannerFeaturedForLandingPageError: true,
      };

    default:
      return state;
  }
};

export default homeDataReducer;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useApi from '../../../../Hooks/useApi';
import {
  CREATE_REEL_REQUEST,
  CREATE_REEL_SUCCESS,
  CREATE_REEL_FAIL,
} from '../../../../redux/actionTypes/reels';
import messageConfigs from '../../../../Helper/PopupMessageConfig';

export default function CreateReelModal({
  type,
  groupId,
  fetchReels,
  currentReelNames,
  handleClose,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addReelToGroup] = useApi();
  const [isReelNameExist, setIsReelNameExist] = useState(false);
  const [isCreateReelClicked, setIsCreateReelClicked] = useState(false);
  const [addReelData, setAddReelData] = useState({
    type,
    name: '',
    groupId: groupId ? groupId : null,
    isPublished: true,
  });

  useEffect(() => {
    if (
      addReelData.name !== '' &&
      currentReelNames &&
      currentReelNames.length
    ) {
      setIsReelNameExist(
        currentReelNames.some(
          reelName => reelName.toLowerCase() === addReelData.name.toLowerCase()
        )
      );
    }
  }, [addReelData, currentReelNames]);

  const { createReelSuccess, createReelLoading, createReelError } = useSelector(
    state => state?.reel
  );
  useEffect(() => {
    if (createReelSuccess) {
      setIsCreateReelClicked(false);
      dispatch({ type: CREATE_REEL_REQUEST });
      handleClose();
      fetchReels();
    }
  }, [createReelSuccess]);

  const handleAddReelData = (value, fieldType) => {
    if (createReelError) {
      dispatch({ type: CREATE_REEL_SUCCESS, payload: null });
    }

    const tempData = { ...addReelData };

    tempData[fieldType] = value;

    setAddReelData(tempData);
  };

  const handleSubmit = () => {
    setIsCreateReelClicked(true);
    addReelToGroup(
      '/reel/add-reel',
      CREATE_REEL_REQUEST,
      CREATE_REEL_SUCCESS,
      CREATE_REEL_FAIL,
      addReelData,
      '',
      'POST',
      null,
      'isReelService'
    );
  };

  const closeModal = () => {
    setIsCreateReelClicked(false);
    dispatch({ type: CREATE_REEL_SUCCESS, payload: null });
    handleClose();
  };

  const { name } = addReelData;

  return (
    <div className="reel-view--popup createreel">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t(`Create ${type === 'VIDEO' ? 'Reel' : 'Document Reel'}`)}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => closeModal()}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>
              {t(`${type === 'VIDEO' ? 'Reel' : 'Document Reel'} Name`)}
            </label>
            <div className="reel-view--popup-field">
              <input
                type="text"
                placeholder={
                  type === 'VIDEO' ? t('Reel Name') : t('Document Reel Name')
                }
                value={name}
                onChange={event =>
                  handleAddReelData(event.target.value, 'name')
                }
              />
            </div>
            {isReelNameExist && (
              <div className="reel-view--popup-row warning">
                <h4>
                  <span>{t('Warning:')}</span> {t('Reel Name Already Exists!')}
                </h4>
              </div>
            )}
            {createReelError && (
              <div className="reel-view--popup-row warning">
                <h4>
                  <span>{t('Error:')}</span>{' '}
                  {t(messageConfigs.error.REEL.ALREADY_EXISTS)}
                </h4>
              </div>
            )}
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`
                btn btn--primary
                ${createReelLoading && isCreateReelClicked ? 'btn--loader' : ''}
              `}
              onClick={handleSubmit}
              disabled={name === '' || isReelNameExist}
            >
              {t(`Create ${type === 'VIDEO' ? 'Reel' : 'Document Reel'}`)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

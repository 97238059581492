export const INTIALIZE_HOME_DATA_REQUEST = 'INTIALIZE_HOME_DATA_REQUEST';
export const INTIALIZE_HOME_DATA_SUCCESS = 'INTIALIZE_HOME_DATA_SUCCESS';
export const INTIALIZE_HOME_DATA_FAIL = 'INTIALIZE_HOME_DATA_FAIL';

export const FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST
  = "FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST";
export const FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS
  = "FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS";
export const FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL
  = "FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL";

export const FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST
  = "FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST";
export const FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS
  = "FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS";
export const FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL
  = "FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL";

export const FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST
  = "FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST";
export const FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS
  = "FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS";
export const FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL
  = "FETCH_BANNER_FEATURED_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL";

export const ORGANIZE_VIDEO = "ORGANIZE_VIDEO";

export const CHANGE_LIKES_VIDEOS = "CHANGE_LIKES_VIDEOS";
export const CHANGE_WATCH_LATER_VIDEOS = "CHANGE_ADD_VIDEOS";
export const UPDATE_AUTH = "UPDATE_AUTH";

export const ADD_ANSWER = "ADD_ANSWER";
export const ADD_ASSIGNMENT_SUBMITION = "ADD_ASSIGNMENT_SUBMITION";
export const ADD_END_ASSIGNMENT_ANSWER = "ADD_END_ASSIGNMENT_ANSWER";
export const ADD_END_ASSIGNMENT_SUBMITION = "ADD_END_ASSIGNMENT_SUBMITION";

export const ADD_SMOOTH_TRANSITION_MODE = "ADD_SMOOTH_TRANSITION_MODE";
export const REMOVE_SMOOTH_TRANSITION_MODE = "REMOVE_SMOOTH_TRANSITION_MODE";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const ADD_PLAYING_INFO = "ADD_PLAYING_INFO";

export const CHANGE_WELCOME_VIDEO_SHOW = "CHANGE_WELCOME_VIDEO_SHOW";

export const CHANGE_ASSESSMENT_STATUS = "CHANGE_ASSESSMENT_STATUS";

export const ADD_ADMIN_USER = "ADD_ADMIN_USER";
export const CHANGE_ADMIN_VIEW = "CHANGE_ADMIN_VIEW";

export const ADD_DISCARDCHANGE_POPUP_OPEN = "ADD_DISCARDCHANGE_POPUP_OPEN";

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useApi from '../../../../Hooks/useApi';
import useCheckPermission from '../../../../Hooks/useCheckPermission';
import useDeleteApi from '../../../../Hooks/useDeleteApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';

import {
  SUSPEND_VIDEO_REQUEST,
  SUSPEND_VIDEO_SUCCESS,
  SUSPEND_VIDEO_FAIL,
} from '../../../../redux/actionTypes/video';
import {
  SUSPEND_DOCUMENT_REQUEST,
  SUSPEND_DOCUMENT_SUCCESS,
  SUSPEND_DOCUMENT_FAIL,
} from '../../../../redux/actionTypes/documentData';
import {
  SUSPEND_ASSESSMENT_REQUEST,
  SUSPEND_ASSESSMENT_SUCCESS,
  SUSPEND_ASSESSMENT_FAIL,
} from '../../../../redux/actionTypes/assesment';

import deleteIcon_white from '../../../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../../../assets/images/svg-images/icon-edit-white.svg';
import stats from '../../../../assets/images/svg-images/icon-stats.svg';
import warning from '../../../../assets/images/svg-images/icon-warning.svg';
import publish from '../../../../assets/images/svg-images/icon-upload.svg';

import CreateCategoryModal from '../../Group/Modal/createCategoryModal';
import ViewPDFModal from '../../Group/Modal/viewPDFModal';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import BreadCrumbView from '../../../../Components/BreadCrumbView';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import { getBannerFeaturedEditRoute } from '../../../../Helper/SystemManager';
import { ToolTipConfig } from '../../../../Common/ToolTipConfig.js';
import ActionDropdown from '../../../../Components/Admin/ActionDropdown';
import Loader from '../../../../Components/Loader/loader';

const SUPER_ADMIN_ID = 4;

export default function SingleReel({ history, match }) {
  const { t } = useTranslation();
  const canSuspendPublishVideo = useCheckPermission(
    PrivilegeTypes.VIDEO.SUSPEND_PUBLISH_VIDEO_ON_MASTER
  );
  const canEditVideo = useCheckPermission(
    PrivilegeTypes.VIDEO.EDIT_VIDEO_ON_MASTER
  );
  const canDeleteVideo = useCheckPermission(
    PrivilegeTypes.VIDEO.DELETE_VIDEO_ON_MASTER
  );

  const reelId = match.params.reelId;
  const reelType = match.params.reelType
    ? match.params.reelType.toUpperCase()
    : '';
  const [isGridView, setIsGridView] = useState(true);
  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState(
    false
  );
  const [isPdfViewModalOpen, setIsPdfViewModalOpen] = useState(false);
  const [organizedCategories, setOrganizedCategories] = useState([]);
  const [reelName, setReelName] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(
    null
  );
  const { roleList } = useSelector(state => state?.auth?.userData);
  const {
    allCategoriesFetchDataSuccess,
    allCategoriesFetchDataLoading,
  } = useSelector(state => state?.reel);
  const { deleteVideoLoading } = useSelector(state => state?.video);
  const { deleteDocumentLoading } = useSelector(state => state?.documentData);
  const { deleteStandaloneAssessmentLoading } = useSelector(
    state => state?.assessment
  );
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;
  const defaultDocument = themeInfo?.documentImageUrl;
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;
  const defaultDocumentCategory = themeInfo?.documentCategoryImageUrl;

  const [fetchData] = useFetchDataApi();
  const [updateVideoIsPublishedStatus] = useApi();
  const [deletReelItem] = useDeleteApi();

  useEffect(() => {
    if (roleList && roleList.length > 0) {
      setIsSuperAdmin(
        [...roleList].map(({ id }) => id).includes(SUPER_ADMIN_ID)
      );
    }
  }, [roleList]);

  useEffect(() => {
    fetchCategoriesDataByReel();
  }, []);

  const fetchCategoriesDataByReel = () =>
    fetchData({ reelId, type: 'REEL_DATA' });

  useEffect(() => {
    if (allCategoriesFetchDataSuccess) {
      const organizedArray = [];

      allCategoriesFetchDataSuccess.forEach(allCategories => {
        setReelName(allCategories.name);

        for (let category of allCategories['category']) {
          const { _id, name, videos, documents, assessments } = category;

          if (!name || !_id) {
            break;
          }

          const files = [];

          if (videos.length > 0) {
            videos.forEach(video => {
              const { title, previewImgUrl } = video;

              files.push({
                ...video,
                name: title,
                previewImage: previewImgUrl || defaultVideo,
                type: 'VIDEO',
              });
            });
          }

          if (assessments.length > 0) {
            assessments.forEach(assessment => {
              const { title, tileImageUrl } = assessment;

              files.push({
                ...assessment,
                name: title,
                previewImage: tileImageUrl || defaultAssessment,
                type: 'ASSESSMENT',
              });
            });
          }

          if (documents.length > 0) {
            documents.forEach(documentFile => {
              const { title, previewImgUrl, documentUrl } = documentFile;

              files.push({
                ...documentFile,
                name: title,
                type: 'DOCUMENT',
                documentUrl: documentUrl ? documentUrl : '',
                previewImage: previewImgUrl || defaultDocument,
              });
            });
          }

          organizedArray.push({
            _id,
            name,
            files,
          });
        }
      });

      setOrganizedCategories(organizedArray);
    }
  }, [allCategoriesFetchDataSuccess]);

  useEffect(() => {
    if (!deleteVideoLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteVideoLoading]);

  useEffect(() => {
    if (!deleteDocumentLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteDocumentLoading]);

  useEffect(() => {
    if (!deleteStandaloneAssessmentLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteStandaloneAssessmentLoading]);

  const renderCategoryGridViews = () => {
    return organizedCategories.map((category, index) => {
      const { _id, name, files } = category;

      return (
        <div key={index}>
          {reelType === 'VIDEO' && <h3>{name}</h3>}
          <div className="reel-view--grid">
            {reelType === 'DOCUMENT' && (
              <div
                className="reel-view--grid__reel-title"
                style={{
                  backgroundImage: `url(${defaultDocumentCategory})`,
                }}
              >
                <p>{name}</p>
              </div>
            )}
            <div className="reel-view--grid-wrapper">
              {files.length > 0 ? (
                renderGridViewTiles(files)
              ) : (
                <p className="reel-view--grid-noitem">{t('No Items')}</p>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const handleIsPublishedStatus = (id, reelType) => {
    switch (reelType) {
      case 'VIDEO':
        updateVideoIsPublishedStatus(
          `/video/update-video-published?videoId=${id}`,
          SUSPEND_VIDEO_REQUEST,
          SUSPEND_VIDEO_SUCCESS,
          SUSPEND_VIDEO_FAIL,
          null,
          '',
          'PUT',
          null,
          'isReelService'
        );
        break;

      case 'DOCUMENT':
        updateVideoIsPublishedStatus(
          `/document/update-document-published?documentId=${id}`,
          SUSPEND_DOCUMENT_REQUEST,
          SUSPEND_DOCUMENT_SUCCESS,
          SUSPEND_DOCUMENT_FAIL,
          null,
          '',
          'PUT',
          null,
          'isReelService'
        );
        break;

      case 'ASSESSMENT':
        updateVideoIsPublishedStatus(
          `/assessment/update-assessment-published?assessmentId=${id}`,
          SUSPEND_ASSESSMENT_REQUEST,
          SUSPEND_ASSESSMENT_SUCCESS,
          SUSPEND_ASSESSMENT_FAIL,
          null,
          '',
          'PUT',
          null,
          'isReelService'
        );
        break;

      default:
        break;
    }
  };

  const renderGridViewTiles = files => {
    return files.map((file, index) => {
      const { _id, name, type, previewImage, groupIds, isPublished } = file;

      const isHaveEditAccess = groupIds.some(id => {
        return canEditVideo[id];
      });

      const isHaveSuspendPublishAccess = groupIds.some(id => {
        return canSuspendPublishVideo[id];
      });

      const isHaveDeleteAccess = groupIds.some(id => {
        return canDeleteVideo[id];
      });

      return (
        <div className="reel-view--grid-video-wrapper" key={index}>
          <div
            className={`reel-view--grid-video ${
              !isPublished ? 'unpublished' : ''
            }`}
          >
            <div
              className="reel-view--grid-video-unpublished"
              onClick={
                reelType === 'VIDEO'
                  ? type === 'ASSESSMENT'
                    ? () => history.push(`/assessment/${_id}`)
                    : () => history.push(`/theatre/${_id}`)
                  : () => handleViewPDF(file)
              }
            ></div>
            <div
              className="reel-thumbnail"
              onClick={
                reelType === 'VIDEO'
                  ? type === 'ASSESSMENT'
                    ? () => history.push(`/assessment/${_id}`)
                    : () => history.push(`/theatre/${_id}`)
                  : () => handleViewPDF(file)
              }
              style={{
                backgroundImage: `url(${previewImage})`,
              }}
            ></div>
            <div className="reel-view--grid-video-settings">
              {(isSuperAdmin || isHaveEditAccess) && (
                <img
                  src={edit_white}
                  alt="edit-icon"
                  className="icon--edit-white"
                  onClick={() => handleEdit(file)}
                />
              )}
              {(isSuperAdmin || isHaveSuspendPublishAccess) && (
                <img
                  src={isPublished ? warning : publish}
                  alt={isPublished ? 'warning-icon' : 'publish-icon'}
                  className={isPublished ? 'icon--warning' : 'icon--publish'}
                  onClick={() => handleIsPublishedStatus(_id, type)}
                />
              )}
              {(isSuperAdmin || isHaveDeleteAccess) && (
                <img
                  src={deleteIcon_white}
                  alt="delete-icon"
                  className="icon--delete-white"
                  onClick={() => handleDelete(_id, type)}
                />
              )}
              {type === 'VIDEO' && (
                <div className="reel-view--grid-video-settings-stats">
                  <img
                    src={stats}
                    alt="stats-icon"
                    className="icon--stats"
                    onClick={() => handleViewStats(_id, type)}
                  />
                </div>
              )}
            </div>
          </div>
          <p>{name}</p>
        </div>
      );
    });
  };

  const renderCategoryListViews = () => {
    return organizedCategories.map((category, index) => {
      const { name, files } = category;

      return (
        <div className="reel-view--table" key={index}>
          <p>{name}</p>
          {renderListTableHeaders()}
          <div className="reel-view--table-body">
            {files.length > 0 ? (
              renderListViewTableRows(files)
            ) : (
              <p className="reel-view--grid-noitem">{t('No Items')}</p>
            )}
          </div>
        </div>
      );
    });
  };

  const renderListTableHeaders = () => {
    return (
      <div className="reel-view--table-header">
        <div className="reel-view--table-cell">
          <h5>{t('Preview')}</h5>
        </div>
        <div className="reel-view--table-cell">
          <h5>{t('Title')}</h5>
        </div>
        <div className="reel-view--table-cell">
          <h5>{t('Date Created')}</h5>
        </div>
        <div className="reel-view--table-cell">
          <h5>{t('Total Views')}</h5>
        </div>
        <div className="reel-view--table-cell">{/* Dropdown column */}</div>
      </div>
    );
  };

  const renderListViewTableRows = files => {
    return files.map((file, index) => {
      const { name, previewImage, groupIds, isPublished, date, views } = file;

      const isHaveEditAccess = groupIds.some(id => {
        return canEditVideo[id];
      });

      const isHaveSuspendPublishAccess = groupIds.some(id => {
        return canSuspendPublishVideo[id];
      });

      const isHaveDeleteAccess = groupIds.some(id => {
        return canDeleteVideo[id];
      });

      return (
        <div className="reel-view--table-row" key={index}>
          <div className="reel-view--table-cell">
            <img
              src={previewImage}
              className="reel-view--table-backgroundimg"
            />
          </div>
          <div className="reel-view--table-cell">
            <p>{name}</p>
          </div>
          <div className="reel-view--table-cell">
            <p>{date ? moment.utc(date).local().format('DD/MM/YYYY') : '-'}</p>
          </div>
          <div className="reel-view--table-cell">
            <p>{views}</p>
          </div>
          <div className="reel-view--table-cell">
            {(isSuperAdmin ||
              isHaveSuspendPublishAccess ||
              isHaveEditAccess ||
              isHaveDeleteAccess) && (
              <ActionDropdown
                ishaveEdit={isSuperAdmin || isHaveEditAccess}
                ishaveSuspend={
                  (isSuperAdmin || isHaveSuspendPublishAccess) && isPublished
                }
                ishavePublish={
                  (isSuperAdmin || isHaveSuspendPublishAccess) && !isPublished
                }
                ishaveDelete={isSuperAdmin || isHaveDeleteAccess}
                handleOnchange={option => setTableActions(option.value, file)}
              />
            )}
          </div>
        </div>
      );
    });
  };

  const setTableActions = (value, data) => {
    switch (value) {
      case 'Suspend':
        handleIsPublishedStatus(data._id, data.type);
        break;

      case 'Publish':
        handleIsPublishedStatus(data._id, data.type);
        break;

      case 'Edit':
        handleEdit(data);
        break;

      case 'Delete':
        handleDelete(data._id, data.type);
        break;

      default:
        break;
    }
  };

  const handleViewPDF = documentReel => {
    if (documentReel.type === 'DOCUMENT') {
      setIsPdfViewModalOpen(true);
      setPdfUrl(documentReel.documentUrl);
      setPdfTitle(documentReel.name);
    }
  };

  const handleEdit = data => {
    switch (data.type) {
      case 'DOCUMENT':
        history.push(
          `/edit-document/${getBannerFeaturedEditRoute(data)}${data._id}`
        );
        break;

      case 'VIDEO':
        history.push(
          `/edit-video/${getBannerFeaturedEditRoute(data)}${data._id}`
        );
        break;

      case 'ASSESSMENT':
        history.push(
          `/edit-assessment/${getBannerFeaturedEditRoute(data)}${data._id}`
        );
        break;

      default:
        break;
    }
  };

  const handleDelete = (id, type) => {
    let deleteRequestData = {
      vid_doc_standalone_Id: id,
      viewType: 'REEL_CATEGORY_VIEW',
    };

    switch (type) {
      case 'DOCUMENT':
        deleteRequestData = {
          ...deleteRequestData,
          type: 'DOCUMENT',
        };
        break;

      case 'VIDEO':
        deleteRequestData = {
          ...deleteRequestData,
          type: 'VIDEO',
        };
        break;

      case 'ASSESSMENT':
        deleteRequestData = {
          ...deleteRequestData,
          type: 'STANDALONE_ASSESSMENT',
        };
        break;

      default:
        break;
    }

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const handleViewStats = (id, type) => {
    switch (type) {
      case 'VIDEO':
        history.push(`/search-reels/video/${reelId}/${id}`);
        break;

      default:
        break;
    }
  };

  return allCategoriesFetchDataLoading ? (
    <Loader />
  ) : (
    <div className="container">
      <div className="reel-view--wrapper singlereel">
        <div className="reel-view--wrapper-column">
          <div className="reel-view--page-title">
            <div className="reel-view--page-selection">
              <BreadCrumbView
                history={history}
                routeOptions={[
                  {
                    title:
                      (reelType === 'DOCUMENT' ? 'Document ' : '') + 'Reels',
                    path: `/search-reels/${reelType.toLowerCase()}`,
                  },
                  {
                    title: reelName,
                    path: '#',
                  },
                ]}
              />
              <h2>{reelName}</h2>
            </div>
            <div className="reel-view--page-settings">
              <input
                className="form-input form-input--search"
                type="search"
                id="search"
                name="search"
                placeholder={t('Search')}
              />
              {/* <Dropdown title="Filter By" options={[]} /> */}
              <button
                className="btn btn--primary btn--createnew btn--long"
                onClick={
                  reelType === 'VIDEO'
                    ? () => history.push('/create-video')
                    : () => history.push('/create-document')
                }
              >
                {reelType === 'VIDEO' ? 'Create Video' : 'Create Document'}
              </button>
              <div
                className={`reel-view--image grid ${
                  isGridView ? 'active' : ''
                }`}
                title={t(ToolTipConfig.general.gridView)}
                onClick={() => setIsGridView(true)}
              />
              <div
                className={`reel-view--image list ${
                  !isGridView ? 'active' : ''
                }`}
                title={t(ToolTipConfig.general.tableView)}
                onClick={() => setIsGridView(false)}
              />
            </div>
          </div>
          {isGridView ? renderCategoryGridViews() : renderCategoryListViews()}
        </div>
      </div>

      {/*<button
        className="btn btn--primary"
        onClick={() => setIsCreateCategoryModalOpen(true)}
      >Create Category</button>*/}

      <p
        className="addanother"
        onClick={() => setIsCreateCategoryModalOpen(true)}
      >
        {t('Create Category')}
      </p>

      {isCreateCategoryModalOpen && (
        <CreateCategoryModal
          reelId={reelId}
          handleClose={() => setIsCreateCategoryModalOpen(false)}
          fetchCategories={() => fetchCategoriesDataByReel()}
        />
      )}
      {isPdfViewModalOpen && (
        <ViewPDFModal
          pdfTitle={pdfTitle}
          pdfUrl={pdfUrl}
          handleClose={() => setIsPdfViewModalOpen(false)}
        />
      )}
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={messageConfigs.deleteConfirm.title}
          message={
            isDeleteConfirmModalOpen.type === 'VIDEO'
              ? messageConfigs.deleteConfirm.video
              : isDeleteConfirmModalOpen.type === 'DOCUMENT'
              ? messageConfigs.deleteConfirm.document
              : messageConfigs.deleteConfirm.assessment
          }
          handleSuccess={() => deletReelItem(isDeleteConfirmModalOpen)}
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
          isLoading={
            deleteVideoLoading ||
            deleteDocumentLoading ||
            deleteStandaloneAssessmentLoading
          }
        />
      )}
    </div>
  );
}

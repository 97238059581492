import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MultiSelect from 'react-multi-select-component';
import {
  ADD_USER_TO_GROUP_REQUEST,
  ADD_USER_TO_GROUP_SUCCESS,
  ADD_USER_TO_GROUP_FAIL,
} from '../../../../redux/actionTypes/Group';

import useApi from '../../../../Hooks/useApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import { useTranslation } from 'react-i18next';
import { FETCH_ELIGIBLE_USERS_FOR_GROUP_SUCCESS } from '../../../../redux/actionTypes/createUser';
import { systemConfig } from '../../../../Common/SystemConstConfigs';

const AddUserModal = ({ handleClose, fetchUsers, groupId }) => {
  const { t } = useTranslation();
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userRolesOption, setUserRolesOption] = useState([]);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [AddUserToGroup] = useApi();
  const [fetchUserRoles] = useFetchDataApi();
  const [fetchEligibleUsers] = useFetchDataApi();

  const dispatch = useDispatch();
  const {
    userRolesFetchData,
    eligibleUsersForGroupLoading,
    eligibleUsersForGroup,
    eligibleUsersForGroupError,
  } = useSelector(state => state?.user);
  const {
    addUserToGroupLoading,
    addUserToGroupSuccess,
    userRolesFetchLoading,
  } = useSelector(state => state?.group);

  useEffect(() => {
    if (addUserToGroupSuccess) {
      dispatch({ type: ADD_USER_TO_GROUP_REQUEST });
      handleClose();
      fetchUsers();
    }
  }, [addUserToGroupSuccess]);

  useEffect(() => {
    fetchUserRoles({ type: 'FETCH_USER_ROLES' });

    return () => dispatch({ type: FETCH_ELIGIBLE_USERS_FOR_GROUP_SUCCESS });
  }, []);

  useEffect(() => {
    if (userRolesFetchData && userRolesFetchData.length) {
      setUserRolesOption(
        userRolesFetchData
          .filter(userRole => userRole.id !== 4)
          .map(role => ({
            label: role.name === 'Admin' ? 'Administrator' : role.name,
            value: role.id,
            disabled: false,
          }))
      );
    }
  }, [userRolesFetchData]);

  useEffect(() => {
    if (selectedUserRoles?.length) {
      fetchEligibleUsers({
        groupId,
        idList: selectedUserRoles.map(({ value }) => value),
        type: 'FETCH_ELIGIBLE_USERS_FOR_GROUP',
      });
    }
  }, [selectedUserRoles]);

  useEffect(() => {
    if (!selectedUserRoles?.length) {
      dispatch({ type: FETCH_ELIGIBLE_USERS_FOR_GROUP_SUCCESS });
    }
  }, [selectedUserRoles]);

  const handleUserRoleSelection = selectedRoles => {
    setSelectedUsers([]);

    const { user } = systemConfig.roleIdByName;

    const filterRoles = (roleId, roleReferenceIds) => {
      return (
        !roleReferenceIds.includes(roleId) && roleId !== user.roleReferenceId
      );
    };

    const handleDisableRoles = () => {
      setUserRolesOption(
        userRolesOption.map(data => ({
          ...data,
          disabled: filterRoles(
            data.value,
            selectedRoles.map(({ value }) => value)
          ),
        }))
      );
    };

    if (
      !selectedRoles?.length ||
      (selectedRoles?.length === 1 &&
        selectedRoles.find(({ value }) => value === user.roleReferenceId))
    ) {
      setUserRolesOption(
        userRolesOption.map(data => ({ ...data, disabled: false }))
      );
    } else {
      handleDisableRoles();
    }

    setSelectedUserRoles(selectedRoles);
  };

  const handleSubmit = () => {
    setIsSubmitClicked(true);
    if (
      selectedUsers?.length &&
      selectedUserRoles?.length &&
      !eligibleUsersForGroupError
    ) {
      AddUserToGroup(
        '/division/assignUser',
        ADD_USER_TO_GROUP_REQUEST,
        ADD_USER_TO_GROUP_SUCCESS,
        ADD_USER_TO_GROUP_FAIL,
        {
          divisionId: groupId,
          roleReferenceWrapper: selectedUserRoles.map(userRole => ({
            roleName:
              userRole.label === 'Administrator' ? 'Admin' : userRole.label,
            roleReferenceId: userRole.value,
          })),
          userId: selectedUsers.map(({ value }) => value),
        },
        '',
        'POST',
        null,
        'isGroupService'
      );
    }
  };

  const renderOption = ({ checked, option, onClick, disabled }) => {
    return (
      <div className={`item-renderer ${disabled ? 'suspend-user' : ''}`}>
        <input
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
        />
        <span>{option.label}</span>
      </div>
    );
  };

  return (
    <div className="group-users-table--popup">
      <div className="group-users-table--popup-container">
        <div className="group-users-table--popup-header">
          <h3>Add users to the group</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
            disabled={addUserToGroupLoading}
          >
            &times;
          </a>
        </div>
        <div className="group-users-table--popup-body">
          <div className="group-users-table--popup-row">
            <label>User roles</label>
            <div
              className="group-users-table--popup-field"
              style={{ zIndex: 10 }}
            >
              <MultiSelect
                isLoading={userRolesFetchLoading}
                options={userRolesOption}
                value={selectedUserRoles}
                onChange={handleUserRoleSelection}
                labelledBy={'Select'}
                hasSelectAll={false}
                disabled={
                  !(userRolesFetchData && userRolesFetchData.length) ||
                  eligibleUsersForGroupLoading
                }
              />
            </div>
          </div>
          <div className="group-users-table--popup-row">
            <label>Users</label>
            <div className="group-users-table--popup-field">
              <MultiSelect
                isLoading={eligibleUsersForGroupLoading}
                options={
                  (eligibleUsersForGroup &&
                    eligibleUsersForGroup.map(user => ({
                      label: user.preferredUserName || user.username,
                      value: user.id,
                      disabled: !user.enabled,
                    }))) ||
                  []
                }
                value={selectedUsers}
                onChange={options => setSelectedUsers(options)}
                labelledBy={'Select'}
                hasSelectAll={false}
                ItemRenderer={renderOption}
              />
            </div>
          </div>
          {eligibleUsersForGroupError && (
            <div className="group-users-table--popup-row">
              <span className="error-message">
                {t('No users in this role')}
              </span>
            </div>
          )}
          <div className="group-users-table--popup-row">
            <button
              className={`
                btn btn--primary
                ${addUserToGroupLoading && isSubmitClicked ? 'btn--loader' : ''}
              `}
              onClick={handleSubmit}
              disabled={
                !(selectedUserRoles.length && selectedUsers.length) ||
                eligibleUsersForGroupError
              }
            >
              Add User{selectedUsers?.length > 1 ? 's' : ''}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;

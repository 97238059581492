import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import deleteIcon_white from '../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../assets/images/svg-images/icon-edit-white.svg';
import stats from '../../assets/images/svg-images/icon-stats.svg';
import warning from '../../assets/images/svg-images/icon-warning.svg';
import publish from '../../assets/images/svg-images/icon-upload.svg';

import MiniPlayer from '../HomePage/Player/MiniPlayer';
import WatchReminderModal from '../../Components/Modal/WatchReminderModal';
import useApi from '../../Hooks/useApi';

import usePrivilegesApi from '../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../Common/UserPrivileges';
import {
  REMIND_LATER_REQUEST,
  REMIND_LATER_SUCCESS,
  REMIND_LATER_FAIL,
} from '../../redux/actionTypes/assesment';
import ConfirmActionModal from '../../Components/Modal/ConfirmActionModal';
import useDeleteApi from '../../Hooks/useDeleteApi';
import messageConfigs from '../../Helper/PopupMessageConfig';
import useChangePublishedStatusApi from '../../Hooks/useChangePublishedStatusApi';
import useFetchDataApi from '../../Hooks/useFetchDataApi';
import { getBannerFeaturedEditRoute } from '../../Helper/SystemManager';
import Loader from '../../Components/Loader/loader';
import { ToolTipConfig } from '../../Common/ToolTipConfig.js';

const ReelBulkView = ({ history, match }) => {
  const { t } = useTranslation();
  const { groupId = null, reelId, type } = match.params;
  const deleteVideoPermission = usePrivilegesApi(
    PrivilegeTypes.VIDEO.DELETE_VIDEO,
    groupId
  );
  const editVideoPermission = usePrivilegesApi(
    PrivilegeTypes.VIDEO.EDIT_VIDEO,
    groupId
  );
  const suspenPublishVideoPermission = usePrivilegesApi(
    PrivilegeTypes.VIDEO.SUSPEND_PUBLISH_VIDEO,
    groupId
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isViewAll, setIsViewAll] = useState(true);
  const [videosToShow, setVideosToShow] = useState([]);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(
    null
  );
  const [watchLaterVideoId, setWatchLaterVideoId] = useState(null);
  const [doAssessmentLaterId, setDoAssessmentLaterId] = useState(null);
  const [reelName, setReelName] = useState('');
  const [organizedCategories, setOrganizedCategories] = useState([]);

  const {
    intializehomeDataData = null,
    intializehomeDataLoading,
  } = useSelector(state => state?.homeData);
  const { deleteVideoSuccess, suspendVideoLoading } = useSelector(
    state => state?.video
  );
  const {
    deleteStandaloneAssessmentSuccess,
    remindLaterSuccess,
    suspendAssessmentLoading,
  } = useSelector(state => state?.assessment);
  const { suspendDocumentLoading, deleteDocumentSuccess } = useSelector(
    state => state?.documentData
  );
  const { suspendImageLoading, deleteImageSuccess } = useSelector(
    state => state?.bannerAndFeatured
  );
  const {
    allCategoriesFetchDataSuccess,
    allCategoriesFetchDataLoading,
  } = useSelector(state => state?.reel);
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;
  const defaultDocument = themeInfo?.documentImageUrl;
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;

  const [fetchData] = useFetchDataApi();
  const [updateIsPublishedStatus] = useChangePublishedStatusApi();
  const [deleteFromBulkView] = useDeleteApi();
  const [sendRemindLaterRequest] = useApi();
  const [sendFavoriteRequest] = useApi();

  const sendRemindLaterDetails = (
    timestamp = '',
    removeWatchLater = false,
    id = null
  ) => {
    sendRemindLaterRequest(
      removeWatchLater
        ? `/video/remove-watch-later?videoId=${id}`
        : '/video/update-watch-later',
      REMIND_LATER_REQUEST,
      REMIND_LATER_SUCCESS,
      REMIND_LATER_FAIL,
      {
        videoId: id?._id || id || watchLaterVideoId,
        timestamp: timestamp
          ? moment(timestamp).format('YYYY-MM-DDTHH:mm:ss:SSZ')
          : '',
      },
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  const sendFavorite = (id, voteType) => {
    sendFavoriteRequest(
      '/video/update-video-vote',
      REMIND_LATER_REQUEST,
      REMIND_LATER_SUCCESS,
      REMIND_LATER_FAIL,
      {
        _id: id?._id || id,
        voteType,
      },
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  const getHomeData = () => fetchData({ groupId, type: 'ADMIN_HOME_INIT' });
  const getHomeVideoReelData = () =>
    fetchData({ groupId, reelId, type: 'REEL_DATA' });

  useEffect(() => {
    if (deleteVideoSuccess || remindLaterSuccess) {
      if (deleteVideoSuccess) {
        setIsDeleteConfirmModalOpen(null);
      }
      if (type) {
        getHomeData();
      } else {
        getHomeVideoReelData();
      }
    }
  }, [deleteVideoSuccess, remindLaterSuccess]);

  useEffect(() => {
    if (deleteStandaloneAssessmentSuccess) {
      setIsDeleteConfirmModalOpen(null);
      if (type) {
        getHomeData();
      } else {
        getHomeVideoReelData();
      }
    }
  }, [deleteStandaloneAssessmentSuccess]);

  useEffect(() => {
    if (deleteDocumentSuccess) {
      setIsDeleteConfirmModalOpen(null);
      getHomeData();
    }
  }, [deleteDocumentSuccess]);

  useEffect(() => {
    if (deleteImageSuccess) {
      setIsDeleteConfirmModalOpen(null);
      getHomeData();
    }
  }, [deleteImageSuccess]);

  useEffect(() => {
    if (type) {
      getHomeData();
    } else {
      getHomeVideoReelData();
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [groupId, reelId, type]);

  useEffect(() => {
    const root = document.getElementById('root');
    root.className = 'bulkreel-page';
    return () => {
      root.className = '';
    };
  }, []);

  useEffect(() => {
    if (allCategoriesFetchDataSuccess) {
      const organizedArray = [];

      allCategoriesFetchDataSuccess.forEach(allCategories => {
        setReelName(allCategories.name);

        for (let category of allCategories['category']) {
          const { _id, name, videos, assessments } = category;

          if (!name || !_id) {
            break;
          }

          const tiles = [];

          if (videos.length > 0) {
            videos.forEach(video => {
              tiles.push({
                ...video,
                type: 'VIDEO',
              });
            });
          }

          if (assessments.length > 0) {
            assessments.forEach(assessment => {
              tiles.push({
                ...assessment,
                type: 'ASSESSMENT',
              });
            });
          }

          organizedArray.push({
            _id,
            name,
            tiles,
          });
        }
      });

      setOrganizedCategories(organizedArray);
    }
  }, [allCategoriesFetchDataSuccess]);

  const videoAssessmentData = () => {
    if (type && intializehomeDataData) {
      if (type === 'Finish Later') {
        return intializehomeDataData.combinedWatchLater;
      }
      if (type === 'Mandatory Training') {
        return intializehomeDataData['mandatory'];
      }
      if (type === 'Favourite') {
        return intializehomeDataData['favourite'];
      }
    }

    return [];
  };

  const hanldeIsPublishedStatus = (type, _id, viewType) => {
    updateIsPublishedStatus({
      _id: _id?._id || _id,
      type,
      viewType,
    });
  };

  const handleDelete = (data, isMandatory) => {
    let deleteRequestData = {
      groupId,
    };

    if (isMandatory) {
      deleteRequestData = {
        ...deleteRequestData,
        reelId: data.reelId,
        isMandatory: true,
        categoryId: data.categoryId,
        vid_doc_standalone_Id: data.id?._id || data.id,
      };
    }

    switch (data.type) {
      case 'VIDEO':
        deleteRequestData = {
          ...deleteRequestData,
          reelId: data.reelId,
          categoryId: data.categoryId,
          vid_doc_standalone_Id: data.id?._id || data.id,
          type: 'VIDEO',
        };
        break;

      case 'STANDALONE_ASSESSMENT':
        deleteRequestData = {
          ...deleteRequestData,
          vid_doc_standalone_Id: data.id?._id || data.id,
          type: 'STANDALONE_ASSESSMENT',
        };
        break;

      case 'DOCUMENT':
        deleteRequestData = {
          ...deleteRequestData,
          reelId: data.reelId,
          categoryId: data.categoryId,
          vid_doc_standalone_Id: data.id?._id || data.id,
          type: 'DOCUMENT',
        };
        break;

      case 'IMAGE':
        deleteRequestData = {
          ...deleteRequestData,
          id: data.id?._id || data.id,
          type: 'IMAGE',
        };
        break;

      default:
        break;
    }

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const videoList = useMemo(
    (() =>
      videoAssessmentData().map((video, key) => {
        if (
          video.type === 'ASSESSMENT' ||
          video.type === 'DOCUMENT' ||
          video.type === 'IMAGE'
        ) {
          let altText = '';
          let srcImage = '';

          switch (video.type) {
            case 'ASSESSMENT':
              altText = 'assesment';
              srcImage = video.tileImageUrl || defaultAssessment;
              break;

            case 'DOCUMENT':
              altText = 'document';
              srcImage = video.previewImgUrl || defaultDocument;
              break;

            case 'IMAGE':
              altText = 'image';
              srcImage = video.imageUrl || defaultVideo;
              break;

            default:
              break;
          }

          return (
            <div className="featured-video__item " key={key}>
              <div
                className={`featured-video__item--assessment ${
                  !video.isPublished ? 'unpublished' : ''
                }`}
              >
                {video.dueDate && (
                  <div className="miniPlayer__info--duedate">
                    <img
                      src="/static/media/icon-clock-white.8e210b40.svg"
                      alt="clock-icon"
                      className="icon--clock"
                    />
                    <span>Due before {moment(video.dueDate).format('ll')}</span>
                  </div>
                )}

                <div
                  className="miniPlayer__click"
                  onClick={
                    video.type === 'ASSESSMENT'
                      ? () =>
                          history.push(
                            `/assessment/${video?._id?._id || video?._id}`
                          )
                      : event => {
                          if (video.documentUrl || video.assignUrl) {
                            window.open(
                              video.documentUrl || video.assignUrl,
                              '_blank'
                            );
                          } else {
                            event.preventDefault();
                          }
                        }
                  }
                ></div>

                <div className="miniPlayer__action">
                  <div
                    className={`miniPlayer__action-icon miniPlayer__action--audio-off`}
                  >
                    <svg className="icon icon--red">
                      <use
                        xlinkHref={
                          require('../../assets/images/sprite/sprite.svg') +
                          '#icon-audio-off'
                        }
                      ></use>
                    </svg>
                  </div>
                  <div
                    className={`miniPlayer__action-icon miniPlayer__action--audio-on`}
                  >
                    <svg className="icon icon--red">
                      <use
                        xlinkHref={
                          require('../../assets/images/sprite/sprite.svg') +
                          '#icon-audio-on'
                        }
                      ></use>
                    </svg>
                  </div>

                  {video.isFavourite ? (
                    <div
                      className={`miniPlayer__action-icon miniPlayer__action--thumb-down likeBtn`}
                      onClick={() =>
                        sendFavorite(video._id, 'unfavourite', true)
                      }
                    >
                      <svg className="icon icon--red">
                        <use
                          xlinkHref={
                            require('../../assets/images/sprite/sprite.svg') +
                            '#icon-thumb-down'
                          }
                        ></use>
                      </svg>
                    </div>
                  ) : (
                    <div
                      className={`miniPlayer__action-icon miniPlayer__action--thumb-up likeBtn`}
                      onClick={() => {
                        sendFavorite(video._id, 'favourite', true);
                      }}
                    >
                      <svg className="icon icon--red">
                        <use
                          xlinkHref={
                            require('../../assets/images/sprite/sprite.svg') +
                            '#icon-thumb-up'
                          }
                        ></use>
                      </svg>
                    </div>
                  )}

                  <span className={`miniPlayer__action--myList`}>
                    {!video.isDoAssessmentLater ? (
                      <div
                        className={`miniPlayer__action-icon myList-add `}
                        onClick={() =>
                          setDoAssessmentLaterId(video?._id?._id || video?._id)
                        }
                      >
                        <svg className="icon icon--red">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-mylist-add'
                            }
                          ></use>
                        </svg>
                      </div>
                    ) : (
                      <div
                        className={`miniPlayer__action-icon myList-added`}
                        onClick={() =>
                          sendRemindLaterDetails(null, true, video._id)
                        }
                      >
                        <svg className="icon icon--red">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-mylist-added'
                            }
                          ></use>
                        </svg>
                      </div>
                    )}
                  </span>
                </div>

                <div className="admin-reel_video--icons assessment">
                  {editVideoPermission && (
                    <img
                      src={edit_white}
                      alt="edit-icon"
                      className="icon--edit-white"
                      title={t(ToolTipConfig.mandatoryReelBulkView.edit)}
                      onClick={() =>
                        history.push(
                          video.type === 'ASSESSMENT'
                            ? `/edit-assessment/${getBannerFeaturedEditRoute(
                                video
                              )}${video?._id?._id || video?._id}`
                            : video.type === 'DOCUMENT'
                            ? `/edit-document/${getBannerFeaturedEditRoute(
                                video
                              )}${video?._id?._id || video?._id}`
                            : `/edit-image/${getBannerFeaturedEditRoute(
                                video
                              )}${video?._id?._id || video?._id}`
                        )
                      }
                    />
                  )}
                  {deleteVideoPermission && (
                    <img
                      src={deleteIcon_white}
                      alt="delete-icon"
                      className="icon--delete-white"
                      title={t(ToolTipConfig.mandatoryReelBulkView.bin)}
                      onClick={() =>
                        handleDelete(
                          {
                            type:
                              video.type === 'ASSESSMENT'
                                ? 'STANDALONE_ASSESSMENT'
                                : video.type,
                            id: video?._id?._id || video?._id,
                            reelId: video.reelId || reelId,
                            categoryId: video.categoryId,
                          },
                          video.reelId || video.type === 'IMAGE'
                        )
                      }
                    />
                  )}
                </div>
                <div className="assessment__image-wrapper">
                  <img src={srcImage} alt={altText} />
                </div>
              </div>
            </div>
          );
        }
        let videoData = {
          ...video,
          videoId: video?._id?._id || video?._id,
        };

        return (
          <div
            className={`featured-video__item ${
              !video.isPublished ? 'unpublished' : ''
            }`}
            key={key}
          >
            <MiniPlayer
              videoInfo={videoData}
              isBulkView
              groupId={groupId}
              reelId={reelId}
              categoryId={video.categoryId}
              hanldeIsPublishedStatus={(type, id) =>
                hanldeIsPublishedStatus(
                  type,
                  id?._id || id,
                  video.reelId ? 'BULK_MANDATORY' : 'BULK_VIEW_VIDEO_REEL'
                )
              }
              handleDelete={data => handleDelete(data, video.reelId)}
              deleteVideoPermission={deleteVideoPermission}
              editVideoPermission={editVideoPermission}
              suspenPublishVideoPermission={suspenPublishVideoPermission}
              addToWatchLater={id => setWatchLaterVideoId(id?._id || id)}
              removeWatchLater={id => sendRemindLaterDetails(null, true, id)}
              addToFavorite={id => sendFavorite(id, 'favourite')}
              removeFavorite={id => sendFavorite(id, 'unfavourite')}
            />
          </div>
        );
      }): []),
    [intializehomeDataData, type]
  );

  const renderVideoTile = (video, key, categoryId) => {
    return (
      <div
        className={`featured-video__item ${
          !video.isPublished ? 'unpublished' : ''
        }`}
        key={key}
      >
        <MiniPlayer
          videoInfo={video}
          isBulkView
          groupId={groupId}
          reelId={reelId}
          categoryId={categoryId}
          hanldeIsPublishedStatus={(type, id) =>
            hanldeIsPublishedStatus(
              type,
              id,
              video.reelId ? 'BULK_MANDATORY' : 'BULK_VIEW_VIDEO_REEL'
            )
          }
          handleDelete={data => handleDelete(data, video.reelId)}
          deleteVideoPermission={deleteVideoPermission}
          editVideoPermission={editVideoPermission}
          suspenPublishVideoPermission={suspenPublishVideoPermission}
          addToWatchLater={id => setWatchLaterVideoId(id?._id || id)}
          removeWatchLater={id => sendRemindLaterDetails(null, true, id)}
          addToFavorite={id => sendFavorite(id, 'favourite')}
          removeFavorite={id => sendFavorite(id, 'unfavourite')}
        />
      </div>
    );
  };

  const renderAssessmentTile = (assessment, key) => {
    return (
      <div className="featured-video__item " key={key}>
        <div
          className={`featured-video__item--assessment ${
            !assessment.isPublished ? 'unpublished' : ''
          }`}
        >
          <div
            className="miniPlayer__click"
            onClick={() =>
              history.push(
                `/assessment/${assessment?._id?._id || assessment?._id}`
              )
            }
          ></div>

          <div className="miniPlayer__action">
            <div
              className={`miniPlayer__action-icon miniPlayer__action--audio-off`}
            >
              <svg className="icon icon--red">
                <use
                  xlinkHref={
                    require('../../assets/images/sprite/sprite.svg') +
                    '#icon-audio-off'
                  }
                ></use>
              </svg>
            </div>
            <div
              className={`miniPlayer__action-icon miniPlayer__action--audio-on`}
            >
              <svg className="icon icon--red">
                <use
                  xlinkHref={
                    require('../../assets/images/sprite/sprite.svg') +
                    '#icon-audio-on'
                  }
                ></use>
              </svg>
            </div>

            {assessment.isFavourite ? (
              <div
                className={`miniPlayer__action-icon miniPlayer__action--thumb-down likeBtn`}
                onClick={() =>
                  sendFavorite(assessment._id, 'unfavourite', true)
                }
              >
                <svg className="icon icon--red">
                  <use
                    xlinkHref={
                      require('../../assets/images/sprite/sprite.svg') +
                      '#icon-thumb-down'
                    }
                  ></use>
                </svg>
              </div>
            ) : (
              <div
                className={`miniPlayer__action-icon miniPlayer__action--thumb-up likeBtn`}
                onClick={() => {
                  sendFavorite(assessment._id, 'favourite', true);
                }}
              >
                <svg className="icon icon--red">
                  <use
                    xlinkHref={
                      require('../../assets/images/sprite/sprite.svg') +
                      '#icon-thumb-up'
                    }
                  ></use>
                </svg>
              </div>
            )}

            <span className={`miniPlayer__action--myList`}>
              {!assessment.isDoAssessmentLater ? (
                <div
                  className={`miniPlayer__action-icon myList-add `}
                  onClick={() =>
                    setDoAssessmentLaterId(
                      assessment?._id?._id || assessment?._id
                    )
                  }
                >
                  <svg className="icon icon--red">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-mylist-add'
                      }
                    ></use>
                  </svg>
                </div>
              ) : (
                <div
                  className={`miniPlayer__action-icon myList-added`}
                  onClick={() =>
                    sendRemindLaterDetails(null, true, assessment._id)
                  }
                >
                  <svg className="icon icon--red">
                    <use
                      xlinkHref={
                        require('../../assets/images/sprite/sprite.svg') +
                        '#icon-mylist-added'
                      }
                    ></use>
                  </svg>
                </div>
              )}
            </span>
          </div>

          <div className="admin-reel_video--icons assessment">
            {editVideoPermission && (
              <img
                src={edit_white}
                alt="edit-icon"
                title={t(ToolTipConfig.reelBulkView.edit)}
                className="icon--edit-white"
                onClick={() =>
                  history.push(
                    `/edit-assessment/${getBannerFeaturedEditRoute(
                      assessment
                    )}${assessment._id}`
                  )
                }
              />
            )}
            {suspenPublishVideoPermission && (
              <img
                src={assessment.isPublished ? warning : publish}
                alt={assessment.isPublished ? 'warning-icon' : 'publish-icon'}
                className={
                  assessment.isPublished ? 'icon--warning' : 'icon--publish'
                }
                title={
                  assessment.isPublished
                    ? ToolTipConfig.reelBulkView.suspend
                    : ToolTipConfig.reelBulkView.publish
                }
                onClick={() =>
                  hanldeIsPublishedStatus(
                    assessment.type,
                    assessment._id,
                    'BULK_VIEW_VIDEO_REEL'
                  )
                }
              />
            )}
            {deleteVideoPermission && (
              <img
                src={deleteIcon_white}
                alt="delete-icon"
                className="icon--delete-white"
                title={t(ToolTipConfig.reelBulkView.bin)}
                onClick={() =>
                  handleDelete(
                    {
                      type: 'STANDALONE_ASSESSMENT',
                      id: assessment._id,
                      reelId: assessment.reelId,
                      categoryId: assessment.categoryId,
                    },
                    false
                  )
                }
              />
            )}
            <img
              src={stats}
              alt="stats-icon"
              className="icon--stats"
              title={t(ToolTipConfig.reelBulkView.stats)}
              onClick={() =>
                history.push(
                  `/group-dashboard/${groupId}/assessments/standalone/${reelId}/${assessment._id}`
                )
              }
            />
          </div>
          <div className="assessment__image-wrapper">
            <img
              src={assessment.tileImageUrl || defaultAssessment}
              alt={'assessment_image'}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderGridViewTiles = (tiles, categoryId) => {
    return tiles.map((tile, key) => {
      return tile.type === 'ASSESSMENT'
        ? renderAssessmentTile(tile, key, categoryId)
        : renderVideoTile(
            {
              ...tile,
              videoId: tile._id,
            },
            key,
            categoryId
          );
    });
  };

  const renderCategerizedView = () => {
    return organizedCategories.map((category, index) => {
      const { _id, name, tiles } = category;

      return tiles.length > 0 ? (
        <div className="tilegrid__wrapper" key={index}>
          <h2 className="tilegrid__subtitle">{name}</h2>
          <div className="tilegrid__row">
            <div className="tilegrid__row--bottom">
              {renderGridViewTiles(tiles, _id)}
            </div>
          </div>
        </div>
      ) : null;
    });
  };

  const renderView = () => {
    return type ? (
      <div className="tilegrid__wrapper">
        <h2 className="tilegrid__title">{t(type)}</h2>
        <div className="tilegrid__row">
          <div className="tilegrid__row--bottom">{videoList}</div>
        </div>
      </div>
    ) : (
      <>
        <h2 className="tilegrid__title">{t(reelName)}</h2>
        {renderCategerizedView()}
      </>
    );
  };

  return isLoading ||
    suspendVideoLoading ||
    suspendAssessmentLoading ||
    suspendDocumentLoading ||
    allCategoriesFetchDataLoading ||
    suspendImageLoading ? (
    <Loader />
  ) : (
    <div className="tilegrid reel">
      <div className="container">{renderView()}</div>
      {watchLaterVideoId && (
        <WatchReminderModal
          onHandleConfirm={value => {
            const date = new Date(
              value.year,
              value.month,
              value.date,
              value.meridiem === 'AM' ? value.hr : parseInt(value.hr) + 12,
              value.min,
              value.sec
            );
            sendRemindLaterDetails(date);
            setWatchLaterVideoId(null);
          }}
          onHandleExit={() => {
            sendRemindLaterDetails();
            setWatchLaterVideoId(null);
          }}
          onHandleCancel={() => setWatchLaterVideoId(null)}
        />
      )}
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={
            isDeleteConfirmModalOpen.isMandatory
              ? messageConfigs.mandatoryReelRemoveConfirm.title
              : messageConfigs.deleteConfirm.title
          }
          message={
            isDeleteConfirmModalOpen.isMandatory
              ? isDeleteConfirmModalOpen.type === 'VIDEO'
                ? messageConfigs.mandatoryReelRemoveConfirm.video
                : isDeleteConfirmModalOpen.type === 'DOCUMENT'
                ? messageConfigs.mandatoryReelRemoveConfirm.document
                : isDeleteConfirmModalOpen.type === 'IMAGE'
                ? messageConfigs.mandatoryReelRemoveConfirm.image
                : messageConfigs.mandatoryReelRemoveConfirm.assessment
              : isDeleteConfirmModalOpen.type === 'VIDEO'
              ? messageConfigs.deleteConfirm.video
              : isDeleteConfirmModalOpen.type === 'DOCUMENT'
              ? messageConfigs.deleteConfirm.document
              : isDeleteConfirmModalOpen.type === 'IMAGE'
              ? messageConfigs.deleteConfirm.image
              : messageConfigs.deleteConfirm.assessment
          }
          handleSuccess={() => deleteFromBulkView(isDeleteConfirmModalOpen)}
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
        />
      )}
    </div>
  );
};

export default ReelBulkView;

export const PrivilegeTypes = {
  USER: {
    CREATE_USER: 'CREATE_USER',
    EDIT_USER: 'EDIT_USER',
    EDIT_USER_FROM_GROUP: 'EDIT_USER_FROM_GROUP',
    SUSPEND_USER: 'SUSPEND_USER',
    SUSPEND_USER_FROM_GROUP: 'SUSPEND_USER_FROM_GROUP',
    DELETE_USER: 'DELETE_USER',
    VIEW_USER_REQUESTS: 'VIEW_USER_REQUESTS',
  },
  GROUP: {
    CREATE_USER_GROUP: 'CREATE_USER_GROUP',
    EDIT_USER_GROUP: 'EDIT_USER_GROUP',
    SUSPEND_GROUP: 'SUSPEND_GROUP',
    DELETE_GROUP: 'DELETE_GROUP',
    ADD_USER_TO_GROUP: 'ADD_USER_TO_GROUP',
    EDIT_USERS_OF_GROUP: 'EDIT_USERS_OF_GROUP',
    REMOVE_USER_FROM_GROUP: 'REMOVE_USER_FROM_GROUP',
    HAVE_GROUP_ROLE: 'HAVE_GROUP_ROLE',
    HAVE_GROUP_ROLE: 'HAVE_GROUP_ROLE',
    ALLOW_SELF_SIGNUP: 'ALLOW_SELF_SIGNUP',
  },
  VIDEO: {
    UPLOAD_VIDEO: 'UPLOAD_VIDEO',
    SUSPEND_PUBLISH_VIDEO: 'SUSPEND_PUBLISH_VIDEO',
    EDIT_VIDEO: 'EDIT_VIDEO',
    DELETE_VIDEO: 'DELETE_VIDEO',
    EDIT_VIDEO_ON_MASTER: 'EDIT_VIDEO_ON_MASTER',
    DELETE_VIDEO_ON_MASTER: 'DELETE_VIDEO_ON_MASTER',
    SUSPEND_PUBLISH_VIDEO_ON_MASTER: 'SUSPEND_PUBLISH_VIDEO_ON_MASTER',
  },
  DOCUMENT: {
    UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
    SUSPEND_PUBLISH_DOCUMENT: 'SUSPEND_PUBLISH_DOCUMENT',
    EDIT_DOCUMENT: 'EDIT_DOCUMENT',
    DELETE_DOCUMENT: 'DELETE_DOCUMENT',
    EDIT_DOCUMENT_ON_MASTER: 'EDIT_DOCUMENT_ON_MASTER',
    DELETE_DOCUMENT_ON_MASTER: 'DELETE_DOCUMENT_ON_MASTER',
    SUSPEND_PUBLISH_DOCUMENT_ON_MASTER: 'SUSPEND_PUBLISH_DOCUMENT_ON_MASTER',
  },
  ASSESSMENT: {
    CREATE_ASSESSMENT: 'CREATE_ASSESSMENT',
    SUSPEND_PUBLISH_ASSESSMENT: 'SUSPEND_PUBLISH_ASSESSMENT',
    EDIT_ASSESSMENT: 'EDIT_ASSESSMENT',
    DELETE_ASSESSMENT: 'DELETE_ASSESSMENT',
    EDIT_ASSESSMENT_ON_MASTER: 'EDIT_ASSESSMENT_ON_MASTER',
    DELETE_ASSESSMENT_ON_MASTER: 'DELETE_ASSESSMENT_ON_MASTER',
    SUSPEND_PUBLISH_ASSESSMENT_ON_MASTER:
      'SUSPEND_PUBLISH_ASSESSMENT_ON_MASTER',
  },
  IMAGE: {
    UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  },
  MANDATORY_REEL_AND_DEAD_LINE: {
    ADD_TO_MANDATORY_REEL: 'ADD_TO_MANDATORY_REEL',
    SET_DEAD_LINE: 'SET_DEAD_LINE',
    EDIT_MANDATORY_REEL: 'EDIT_MANDATORY_REEL',
    DELETE_MANDATORY_REEL: 'DELETE_MANDATORY_REEL',
  },
  REEL: {
    CREATE_REEL: 'CREATE_REEL',
    SUSPEND_PUBLISH_REEL: 'SUSPEND_PUBLISH_REEL',
    ASSIGN_REEL: 'ASSIGN_REEL',
    RENAME_REEL: 'RENAME_REEL',
    EDIT_REEL: 'EDIT_REEL',
    REMOVE_REEL: 'REMOVE_REEL',
    DELETE_REEL: 'DELETE_REEL',
  },
  BANNER: {
    ASSIGN_UPLOAD_TO_BANNER: 'ASSIGN_UPLOAD_TO_BANNER',
    DELETE_BANNER_ITEM: 'DELETE_BANNER_ITEM',
    EDIT_BANNER_ITEM: 'EDIT_BANNER_ITEM',
    SUSPEND_BANNER_ITEM: 'SUSPEND_BANNER_ITEM',
  },
  FEATURED: {
    ASSIGN_UPLOAD_TO_FEATURED: 'ASSIGN_UPLOAD_TO_FEATURED',
    DELETE_FEATURED_ITEM: 'DELETE_FEATURED_ITEM',
    EDIT_FEATURED_ITEM: 'EDIT_FEATURED_ITEM',
    SUSPEND_FEATURED_ITEM: 'SUSPEND_FEATURED_ITEM',
  },
  USER_ACTIVITY: {
    ACCESS_USER_ACTIVITY: 'ACCESS_USER_ACTIVITY',
  },
  STATISTICS: {
    ACCESS_USER_STATISTICS: 'ACCESS_USER_STATISTICS',
  },
  SPECIAL_NOTIFICATIONS: {
    COMPLETED_CERTAIN_TASK: 'COMPLETED_CERTAIN_TASK',
  },
  APPROVE: {
    APPROVE_PUBLISH_VIDEO: 'APPROVE_PUBLISH_VIDEO',
    APPROVE_PUBLISH_DOCUMENT: 'APPROVE_PUBLISH_DOCUMENT',
    APPROVE_PUBLISH_ASSESSMENT: 'APPROVE_PUBLISH_ASSESSMENT',
  },
};

/**
 * RoleForIDs = {
 *  1: 'Admin,
 *  2: 'Editor,
 *  3: 'User',
 *  5: 'Moderator',
 * }
 */
export const UserPrivilegesForRoles = {
  1: [
    'CREATE_USER',
    'EDIT_USER_FROM_GROUP',
    'SUSPEND_USER_FROM_GROUP',
    'CREATE_USER_GROUP',
    'EDIT_USER_GROUP',
    'SUSPEND_GROUP',
    'DELETE_GROUP',
    'ADD_USER_TO_GROUP',
    'EDIT_USERS_OF_GROUP',
    'REMOVE_USER_FROM_GROUP',
    'UPLOAD_VIDEO',
    'SUSPEND_PUBLISH_VIDEO',
    'EDIT_VIDEO',
    'DELETE_VIDEO',
    'UPLOAD_DOCUMENT',
    'SUSPEND_PUBLISH_DOCUMENT',
    'EDIT_DOCUMENT',
    'DELETE_DOCUMENT',
    'CREATE_ASSESSMENT',
    'SUSPEND_PUBLISH_ASSESSMENT',
    'EDIT_ASSESSMENT',
    'DELETE_ASSESSMENT',
    'ADD_TO_MANDATORY_REEL',
    'SET_DEAD_LINE',
    'CREATE_REEL',
    'SUSPEND_PUBLISH_REEL',
    'ASSIGN_REEL',
    'EDIT_REEL',
    'REMOVE_REEL',
    'ASSIGN_UPLOAD_TO_BANNER',
    'ASSIGN_UPLOAD_TO_FEATURED',
    'ACCESS_USER_ACTIVITY',
    'ACCESS_USER_STATISTICS',
    'COMPLETED_CERTAIN_TASK',
    'APPROVE_PUBLISH_VIDEO',
    'APPROVE_PUBLISH_DOCUMENT',
    'APPROVE_PUBLISH_ASSESSMENT',
    'DELETE_BANNER_ITEM',
    'EDIT_BANNER_ITEM',
    'SUSPEND_BANNER_ITEM',
    'DELETE_FEATURED_ITEM',
    'EDIT_FEATURED_ITEM',
    'SUSPEND_FEATURED_ITEM',
    'EDIT_MANDATORY_REEL',
    'EDIT_VIDEO_ON_MASTER',
    'EDIT_DOCUMENT_ON_MASTER',
    'EDIT_ASSESSMENT_ON_MASTER',
    'HAVE_GROUP_ROLE',
    'UPLOAD_IMAGE',
    'VIEW_USER_REQUESTS',
  ],
  2: [
    'EDIT_VIDEO',
    'EDIT_DOCUMENT',
    'EDIT_ASSESSMENT',
    'ADD_TO_MANDATORY_REEL',
    'SET_DEAD_LINE',
    'CREATE_REEL',
    'EDIT_REEL',
    'ACCESS_USER_STATISTICS',
    'COMPLETED_CERTAIN_TASK',
    'EDIT_MANDATORY_REEL',
    'EDIT_VIDEO_ON_MASTER',
    'EDIT_DOCUMENT_ON_MASTER',
    'EDIT_ASSESSMENT_ON_MASTER',
    'HAVE_GROUP_ROLE',
  ],
  5: [
    'UPLOAD_VIDEO',
    'SUSPEND_PUBLISH_VIDEO',
    'EDIT_VIDEO',
    'DELETE_VIDEO',
    'UPLOAD_DOCUMENT',
    'SUSPEND_PUBLISH_DOCUMENT',
    'EDIT_DOCUMENT',
    'DELETE_DOCUMENT',
    'CREATE_ASSESSMENT',
    'SUSPEND_PUBLISH_ASSESSMENT',
    'EDIT_ASSESSMENT',
    'DELETE_ASSESSMENT',
    'ADD_TO_MANDATORY_REEL',
    'SET_DEAD_LINE',
    'CREATE_REEL',
    'SUSPEND_PUBLISH_REEL',
    'ASSIGN_REEL',
    'EDIT_REEL',
    'REMOVE_REEL',
    'ASSIGN_UPLOAD_TO_BANNER',
    'ASSIGN_UPLOAD_TO_FEATURED',
    'ACCESS_USER_STATISTICS',
    'COMPLETED_CERTAIN_TASK',
    'APPROVE_PUBLISH_VIDEO',
    'APPROVE_PUBLISH_DOCUMENT',
    'APPROVE_PUBLISH_ASSESSMENT',
    'DELETE_BANNER_ITEM',
    'EDIT_BANNER_ITEM',
    'SUSPEND_BANNER_ITEM',
    'DELETE_FEATURED_ITEM',
    'EDIT_FEATURED_ITEM',
    'SUSPEND_FEATURED_ITEM',
    'EDIT_MANDATORY_REEL',
    'EDIT_VIDEO_ON_MASTER',
    'EDIT_DOCUMENT_ON_MASTER',
    'EDIT_ASSESSMENT_ON_MASTER',
    'HAVE_GROUP_ROLE',
    'UPLOAD_IMAGE',
  ],
};

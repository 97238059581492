export const CREATE_GROUP_INITIAL = 'CREATE_GROUP_INITIAL';
export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL';

export const FETCH_ALL_GROUPS_REQUEST = 'FETCH_ALL_GROUPS_REQUEST';
export const FETCH_ALL_GROUPS_SUCCESS = 'FETCH_ALL_GROUPS_SUCCESS';
export const FETCH_ALL_GROUPS_FAIL = 'FETCH_ALL_GROUPS_FAIL';

export const FETCH_GROUP_USER_ID_REQUEST = 'FETCH_GROUP_USER_ID_REQUEST';
export const FETCH_GROUP_USER_ID_SUCCESS = 'FETCH_GROUP_USER_ID_SUCCESS';
export const FETCH_GROUP_USER_ID_FAIL = 'FETCH_GROUP_USER_ID_FAIL';

export const ADD_USER_TO_GROUP_REQUEST = 'ADD_USER_TO_GROUP_REQUEST';
export const ADD_USER_TO_GROUP_SUCCESS = 'ADD_USER_TO_GROUP_SUCCESS';
export const ADD_USER_TO_GROUP_FAIL = 'ADD_USER_TO_GROUP_FAIL';

export const FETCH_GROUP_DETAILS_REQUEST = 'FETCH_GROUP_DETAILS_REQUEST';
export const FETCH_GROUP_DETAILS_SUCCESS = 'FETCH_GROUP_DETAILS_SUCCESS';
export const FETCH_GROUP_DETAILS_FAIL = 'FETCH_GROUP_DETAILS_FAIL';

export const FETCH_USER_LIST_REQUEST = 'FETCH_USER_LIST_REQUEST';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL';

export const FETCH_PARENT_GROUP_ROLES_BY_USER_REQUEST =
  'FETCH_PARENT_GROUP_ROLES_BY_USER_REQUEST';
export const FETCH_PARENT_GROUP_ROLES_BY_USER_SUCCESS =
  'FETCH_PARENT_GROUP_ROLES_BY_USER_SUCCESS';
export const FETCH_PARENT_GROUP_ROLES_BY_USER_FAIL =
  'FETCH_PARENT_GROUP_ROLES_BY_USER_FAIL';

export const SET_CURRENT_GROUP_ID = 'SET_CURRENT_GROUP_ID';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL';

export const FETCH_SELF_ASSIGNED_ENABLED_GROUPS_REQUEST =
  'FETCH_SELF_ASSIGNED_ENABLED_GROUPS_REQUEST';
export const FETCH_SELF_ASSIGNED_ENABLED_GROUPS_SUCCESS =
  'FETCH_SELF_ASSIGNED_ENABLED_GROUPS_SUCCESS';
export const FETCH_SELF_ASSIGNED_ENABLED_GROUPS_FAIL =
  'FETCH_SELF_ASSIGNED_ENABLED_GROUPS_FAIL';

export const FETCH_SIGNUP_ENABLED_GROUPS_REQUEST =
  'FETCH_SIGNUP_ENABLED_GROUPS_REQUEST';
export const FETCH_SIGNUP_ENABLED_GROUPS_SUCCESS =
  'FETCH_SIGNUP_ENABLED_GROUPS_SUCCESS';
export const FETCH_SIGNUP_ENABLED_GROUPS_FAIL =
  'FETCH_SIGNUP_ENABLED_GROUPS_FAIL';

export const FETCH_GROUPS_BY_USER_REQUEST = 'FETCH_GROUPS_BY_USER_REQUEST';
export const FETCH_GROUPS_BY_USER_SUCCESS = 'FETCH_GROUPS_BY_USER_SUCCESS';
export const FETCH_GROUPS_BY_USER_FAIL = 'FETCH_GROUPS_BY_USER_FAIL';

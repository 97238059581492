import React, { useCallback, useState, useEffect } from 'react';

import SettingsNavigation from '../../Components/Settings/SettingsNavigation';
import NotificationSettings from './Notification/index.js';

const Settings = ({ match, history }) => {
  const [settingsView, setSettingsView] = useState();

  useEffect(() => {
    switch (match?.params?.settingsType) {
      case 'notification':
        setSettingsView('NOTIFICATION_SETTINGS');
        break;

      default:
        break;
    }
  }, [match]);

  const changeCurrentUrl = value => {
    const currentUrl = window.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');

    switch (value) {
      case 'NOTIFICATION_SETTINGS':
        history.push(`${currentUrl}/notification`);
        break;

      default:
        break;
    }
  };

  const ChangeSettingsView = useCallback(
    viewType => {
      changeCurrentUrl(viewType);
      setSettingsView(viewType);
    },
    [settingsView]
  );

  const renderContent = () => {
    switch (settingsView) {
      case 'NOTIFICATION_SETTINGS':
        return <NotificationSettings />;

      default:
        break;
    }
  };

  return (
    <div className="notification-screen">
      <div className="container">
        <div className="notification-screen__wrapper">
          <div className="notification-screen__leftcol">
            <SettingsNavigation
              view={settingsView}
              changeView={ChangeSettingsView}
            />
          </div>
          <div className="notification-screen__rightcol">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

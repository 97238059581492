import React from 'react';
import { useSelector } from 'react-redux';

import logo from '../../assets/images/owlonly.png';

export default function Loader() {
  const { themeInfo } = useSelector(state => state?.auth);
  const pageLoadingLogo = themeInfo?.pageLoadingLogoUrl;

  return (
    <div className="loading-screen wrapped">
      <div
        className="loading-screen-before"
        style={{ backgroundImage: `url(${pageLoadingLogo || logo})` }}
      />
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SmallScreenMessage({ width, logo }) {
  const { t } = useTranslation();
  return (
    <div className="screensize">
      <div className="container">
        <div className="screensize--wrapper">
          <div className="screensize--logo">
            <img
              src={logo}
              alt="screen-message-logo"
              className="screensize--logo-img"
            />
          </div>
          <div className="screensize--messagearea">
            <h3>{t('Looks like you are logged in as an Admin.')}</h3>
            <p>
              {t(
                'The system only works on small screen sizes with an User login.'
              )}
            </p>
            <p>{t('Your current screen size is')}</p>
            <p>{`${width}px`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export const validatonErrors = {
  emptyFields: {
    TITLE: 'Title',
    GROUP: 'Group',
    REEL: 'Reel',
    MANDATORY_DUE_DATE: 'Mandatory Due Date',
    DOCUMENT: 'Document',
    IMAGE: 'Image',
    QUESTIONS: 'One Question with Answers',
    START_TIME: 'Start time',
    TRIGGER_TIME: 'Trigger time',
    ASSIGNED_VIDEOS: 'Assigned videos',
    THEATRE_MODE_VIDEO: 'Theatre Mode Video',
  },
  invalidValues: {
    THEATRE_MODE_VIDEO_URL:
      'The URL provided for the Theatre Mode Video is invalid',
    DOCUMENT_PDF_URL: 'The URL provided for the Document is invalid.',
    IMAGE_ASSIGN_URL: 'The Assigned URL is invalid',
    IMAGE_URL: 'The URL provided for the Image is invalid.',
    QUESTIONS_WITH_ANSWERS: 'Not a valid question with atleast two answers',
    QUESTION_IMAGE: 'Not a valid question image',
    ASSESSMENT_START_TIME: 'Start Time value is invalid',
    ASSESSMENT_TRIGGER_TIME: 'Trigger Time value is invalid',
  },
};

/**
 * Checking if due date for mandatory is selected.
 *
 * @param {array} groupData
 */
export const isMandatoryDueDateNotSelected = groupData => {
  return (
    Array.isArray(groupData) &&
    groupData[0]['groups'].length &&
    groupData[0]['groups'].some(
      ({ dueDate, isMandatory }) => isMandatory && dueDate === ''
    )
  );
};

/**
 * Checking if reel is selected.
 *
 * @param {array} reelData
 */
export const isReelNotSelected = reelData => {
  return Array.isArray(reelData) && reelData[0]['reelId'] === '';
};

/**
 * Checking if groups are selected.
 *
 * @param {array} groupData
 */
export const isGroupsNotSelected = groupData => {
  return Array.isArray(groupData) && groupData[0]['groups'].length === 0;
};

/**
 * Validating url string
 *
 * @param {string} urlString
 */
export const isValidUrl = urlString => {
  const urlRegex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  const urlPattern = new RegExp(urlRegex);

  return urlPattern.test(urlString);
};

/**
 * Checking for questions in assessments are valid.
 *
 * @param {array} questionsArray
 */
export const isValidQuestionsNotAdded = questionsArray => {
  if (!questionsArray.length) {
    return true;
  } else {
    return questionsArray.some(
      ({ options, quiz }) =>
        quiz === '' || options.length < 2 || options.some(value => value === '')
    );
  }
};

/**
 * Checking if questions with image has a valid image file or URL.
 *
 * @param {array} questionsArray
 */
export const isQuestionHaveInvalidImage = questionsArray => {
  return questionsArray.some(
    ({ questionImageS3BucketURL, isTextOnly }) =>
      !isTextOnly && questionImageS3BucketURL === ''
  );
};

/**
 * Validating form fields
 *
 * @param {object} type, formData
 */
export const validateFields = ({ type, formData }) => {
  let erroMessages = {
    emptyFields: [],
    invalidValues: [],
  };

  const {
    TITLE,
    GROUP,
    REEL,
    MANDATORY_DUE_DATE,
    DOCUMENT,
    IMAGE,
    QUESTIONS,
    START_TIME,
    TRIGGER_TIME,
    ASSIGNED_VIDEOS,
    THEATRE_MODE_VIDEO,
  } = validatonErrors.emptyFields;

  const {
    THEATRE_MODE_VIDEO_URL,
    DOCUMENT_PDF_URL,
    IMAGE_ASSIGN_URL,
    IMAGE_URL,
    QUESTIONS_WITH_ANSWERS,
    QUESTION_IMAGE,
    ASSESSMENT_START_TIME,
    ASSESSMENT_TRIGGER_TIME,
  } = validatonErrors.invalidValues;

  switch (type) {
    case 'VIDEO':
      for (const fieldType in formData) {
        switch (fieldType) {
          case 'title':
            if (formData[fieldType] === '') {
              erroMessages.emptyFields.push(TITLE);
            }
            break;

          case 'listOfReelIdCategoryIdAndGroups':
            if (isGroupsNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(GROUP);
            }

            if (isReelNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(REEL);
            }

            if (isMandatoryDueDateNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(MANDATORY_DUE_DATE);
            }
            break;

          case 'theatreVideoData':
            if (formData[fieldType] === '') {
              erroMessages.emptyFields.push(THEATRE_MODE_VIDEO);
            } else if (
              typeof formData[fieldType] === 'string' &&
              !isValidUrl(formData[fieldType])
            ) {
              erroMessages.invalidValues.push(THEATRE_MODE_VIDEO_URL);
            }
            break;

          case 'listOfGroups':
            if (isGroupsNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(GROUP);
            }

            if (isMandatoryDueDateNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(MANDATORY_DUE_DATE);
            }
            break;

          default:
            break;
        }
      }
      break;

    case 'DOCUMENT':
      for (const fieldType in formData) {
        switch (fieldType) {
          case 'title':
            if (formData[fieldType] === '') {
              erroMessages.emptyFields.push(TITLE);
            }
            break;

          case 'listOfReelIdCategoryIdAndGroups':
            if (isGroupsNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(GROUP);
            }

            if (isReelNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(REEL);
            }

            if (isMandatoryDueDateNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(MANDATORY_DUE_DATE);
            }
            break;

          case 'documentFileData':
            if (formData[fieldType] === '') {
              erroMessages.emptyFields.push(DOCUMENT);
            } else if (
              typeof formData[fieldType] === 'string' &&
              !isValidUrl(formData[fieldType])
            ) {
              erroMessages.invalidValues.push(DOCUMENT_PDF_URL);
            }
            break;

          case 'listOfGroups':
            if (isGroupsNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(GROUP);
            }

            if (isMandatoryDueDateNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(MANDATORY_DUE_DATE);
            }
            break;

          default:
            break;
        }
      }
      break;

    case 'IMAGE':
      for (const fieldType in formData) {
        switch (fieldType) {
          case 'title':
            if (formData[fieldType] === '') {
              erroMessages.emptyFields.push(TITLE);
            }
            break;

          case 'assignUrl':
            if (
              formData[fieldType] !== '' &&
              !isValidUrl(formData[fieldType])
            ) {
              erroMessages.invalidValues.push(IMAGE_ASSIGN_URL);
            }
            break;

          case 'bannerFeaturedImageData':
            if (formData[fieldType] === '') {
              erroMessages.emptyFields.push(IMAGE);
            } else if (
              typeof formData[fieldType] === 'string' &&
              !isValidUrl(formData[fieldType])
            ) {
              erroMessages.invalidValues.push(IMAGE_URL);
            }
            break;

          case 'listOfGroups':
            if (isGroupsNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(GROUP);
            }

            if (isMandatoryDueDateNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(MANDATORY_DUE_DATE);
            }
            break;

          default:
            break;
        }
      }
      break;

    case 'STANDALONE_ASSESSMENT':
      for (const fieldType in formData) {
        switch (fieldType) {
          case 'title':
            if (formData[fieldType] === '') {
              erroMessages.emptyFields.push(TITLE);
            }
            break;

          case 'questions':
            if (!formData[fieldType].length) {
              erroMessages.emptyFields.push('One Question with Answers');
            } else {
              if (isValidQuestionsNotAdded(formData[fieldType])) {
                erroMessages.invalidValues.push(QUESTIONS_WITH_ANSWERS);
              }

              if (isQuestionHaveInvalidImage(formData[fieldType])) {
                erroMessages.invalidValues.push(QUESTION_IMAGE);
              }
            }
            break;

          case 'listOfGroups':
            if (isGroupsNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(GROUP);
            }

            if (isMandatoryDueDateNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(GROUP);
            }
            break;

          case 'listOfReelIdCategoryIdAndGroups':
            if (isGroupsNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(GROUP);
            }

            if (isReelNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(GROUP);
            }

            if (isMandatoryDueDateNotSelected(formData[fieldType])) {
              erroMessages.emptyFields.push(MANDATORY_DUE_DATE);
            }
            break;

          default:
            break;
        }
      }
      break;

    case 'MID_ASSESSMENT':
      for (const fieldType in formData) {
        switch (fieldType) {
          case 'title':
            if (formData[fieldType] === '') {
              erroMessages.emptyFields.push(TITLE);
            }
            break;

          case 'questions':
            if (!formData[fieldType].length) {
              erroMessages.emptyFields.push(QUESTIONS);
            } else {
              if (isValidQuestionsNotAdded(formData[fieldType])) {
                erroMessages.invalidValues.push(QUESTIONS_WITH_ANSWERS);
              }

              if (isQuestionHaveInvalidImage(formData[fieldType])) {
                erroMessages.invalidValues.push(QUESTION_IMAGE);
              }
            }
            break;

          case 'startTime':
            if (formData[fieldType].some(value => value === '')) {
              erroMessages.emptyFields.push(START_TIME);
            }

            if (
              formData[fieldType].some(
                value => isNaN(value) || parseInt(value) < 0
              )
            ) {
              erroMessages.invalidValues.push(ASSESSMENT_START_TIME);
            }
            break;

          case 'triggerTime':
            if (formData[fieldType].some(value => value === '')) {
              erroMessages.emptyFields.push(TRIGGER_TIME);
            }

            if (
              formData[fieldType].some(
                value => isNaN(value) || parseInt(value) < 0
              )
            ) {
              erroMessages.invalidValues.push(ASSESSMENT_TRIGGER_TIME);
            }
            break;

          case 'assignedVideos':
            if (!formData[fieldType].length) {
              erroMessages.emptyFields.push(ASSIGNED_VIDEOS);
            }
            break;

          default:
            break;
        }
      }
      break;

    case 'END_ASSESSMENT':
      for (const fieldType in formData) {
        switch (fieldType) {
          case 'title':
            if (formData[fieldType] === '') {
              erroMessages.emptyFields.push(TITLE);
            }
            break;

          case 'questions':
            if (!formData[fieldType].length) {
              erroMessages.emptyFields.push(QUESTIONS);
            } else {
              if (isValidQuestionsNotAdded(formData[fieldType])) {
                erroMessages.invalidValues.push(QUESTIONS_WITH_ANSWERS);
              }

              if (isQuestionHaveInvalidImage(formData[fieldType])) {
                erroMessages.invalidValues.push(QUESTION_IMAGE);
              }
            }
            break;

          case 'assignedVideos':
            if (!formData[fieldType].length) {
              erroMessages.emptyFields.push(ASSIGNED_VIDEOS);
            }
            break;

          default:
            break;
        }
      }
      break;

    default:
      break;
  }

  return erroMessages.emptyFields.length || erroMessages.invalidValues.length
    ? erroMessages
    : null;
};

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dropdown from 'react-dropdown';
import useApi from '../../../../Hooks/useApi';
import CheckBoxPanel from '../../../../Components/CheckBoxPanel/CheckBoxPanel';
import {
  ADD_VIDEOS_TO_CURRENT_CATEGORY_REQUEST,
  ADD_VIDEOS_TO_CURRENT_CATEGORY_SUCCESS,
  ADD_VIDEOS_TO_CURRENT_CATEGORY_FAIL,
  ADD_DOCUMENTS_TO_CURRENT_CATEGORY_REQUEST,
  ADD_DOCUMENTS_TO_CURRENT_CATEGORY_SUCCESS,
  ADD_DOCUMENTS_TO_CURRENT_CATEGORY_FAIL,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_SUCCESS,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_SUCCESS,
  FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_SUCCESS,
  ADD_VIDEOS_TO_CURRENT_CATEGORY_RESET,
  ADD_DOCUMENTS_TO_CURRENT_CATEGORY_RESET,
} from '../../../../redux/actionTypes/reels'
import {
  ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_REQUEST,
  ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_SUCCESS,
  ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_FAIL,
  ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_RESET
} from '../../../../redux/actionTypes/assesment';
import { getAllGroupInfoByCurrentUser } from '../../../../Helper/SystemManager';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';

export default function AddVideoToReelModal({
  categoryId,
  handleClose,
  reelId,
  handleSuccess,
  reelType,
  groupId,
}) {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [selectedReel, setSelectedReel] = useState(null);
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [isSelectByCatagoryView, setIsSelectByCatagoryView] = useState(false);
  const [organizedReelData, setOrganizedReelData] = useState([]);
  const [checkedCategoryValues, setCheckedCategoryValues] = useState([]);
  const [checkedContentByCategory, setCheckedContentByCategory] = useState(null);
  const [childContentCountByCategory, setChildContentCountByCategory] = useState(null);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectNone, setSelectNone] = useState(false);

  const dispatch = useDispatch();
  const { groupInfo, userName } = useSelector((state) => state?.auth?.userData);
  const { allGroupsFetchData } = useSelector((state) => state?.group);
  const {
      addVideosToCurrentCategoryLoading,
      addVideosToCurrentCategorySuccess,
      addDocumentsToCurrentCategoryLoading,
      addDocumentsToCurrentCategorySuccess,
      allStandaloneAssessmentCategoryReelSuccess,
      allReelsCategoriesVideosFetchDataSuccess,
      allReelsCategoriesDocumentsFetchDataSuccess,
      allStandaloneAssessmentCategoryReelLoading,
      allReelsCategoriesDocumentsFetchDataLoading,
      allReelsCategoriesVideosFetchDataLoading,
  } = useSelector(((state) => state?.reel));
  const {
    addAssessmentToCategorySuccess,
    addAssessmentToCategoryLoading,
  } = useSelector(((state) => state?.assessment));
  const [fetchAllGroups] = useFetchDataApi();
  const [addContentToGroup] = useApi();
  const [fetchData] = useFetchDataApi();

  useEffect(() => {
    if (reelType === 'DOCUMENT') {
      setSelectedType({
        value: reelType,
      });
    }
  }, [reelType]);

  useEffect(() => {
    fetchAllGroups({ type: 'ALL_GROUPS' });
  }, []);

  useEffect(() => {
    if (!Array.isArray(allGroupsFetchData) && allGroupsFetchData && groupInfo) {
      const flattenedGroups = getAllGroupInfoByCurrentUser(
        userName,
        groupInfo,
        allGroupsFetchData
      );

      // Constructing select group dropdown values
      const organizeSelectOptions = (optionsArray) => {
        return optionsArray.map((group) => {
          return { value: group._id, label: group.title };
        });
      }

      const selectGroupOptions = organizeSelectOptions(flattenedGroups);

      setSelectGroupOptions(selectGroupOptions);
    }
  }, [allGroupsFetchData]);

  useEffect(() => {
    if (selectedGroup && selectedType) {
      setSelectedReel(null);
      setOrganizedReelData([]);

      switch (selectedType.value) {
        case 'VIDEO':
          fetchData({
            type: 'ADD_VIDEO_TO_REEL_DATA',
            groupId: selectedGroup.value,
          });
          break;

        case 'DOCUMENT':
          fetchData({
            type: 'ADD_DOCUMENTS_TO_REEL_DATA',
            groupId: selectedGroup.value,
          });
          break;

        case 'ASSESSMENT':
          fetchData({
            type: 'ADD_STANDALONE_ASSESSMENTS_TO_REEL_DATA',
            groupId: selectedGroup.value,
          });
          break;

        default:
          break;
      }
    }
  }, [selectedGroup, selectedType]);

  useEffect(() => {
    if (allReelsCategoriesVideosFetchDataSuccess) {
      organizeReelData(
        allReelsCategoriesVideosFetchDataSuccess
      );

      dispatch({
        type: FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_SUCCESS,
        payload: null,
      });
    }
  }, [allReelsCategoriesVideosFetchDataSuccess]);

  useEffect(() => {
    if (allStandaloneAssessmentCategoryReelSuccess) {
      organizeReelData(
        allStandaloneAssessmentCategoryReelSuccess
      );

      dispatch({
        type: FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_SUCCESS,
        payload: null,
      });
    }
  }, [allStandaloneAssessmentCategoryReelSuccess]);

  useEffect(() => {
    if (allReelsCategoriesDocumentsFetchDataSuccess) {
      organizeReelData(
        allReelsCategoriesDocumentsFetchDataSuccess
      );

      dispatch({
        type: FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_SUCCESS,
        payload: null,
      });
    }
  }, [allReelsCategoriesDocumentsFetchDataSuccess]);

  useEffect(() => {
    if (selectedReel) {
      const contentByCategory = {};
      const childContentCount = {};
      selectedReel.categories.forEach(category => {
        contentByCategory[category.id] = [];
        childContentCount[category.id] = category.childrenCheckBoxes.length;
      })

      setCheckedContentByCategory(contentByCategory);
      setChildContentCountByCategory(childContentCount);
    }
  }, [selectedReel]);

  useEffect(() => {
    if (addVideosToCurrentCategorySuccess) {
      setSelectedReel(null);
      setOrganizedReelData([]);
      setIsAddButtonClicked(false);
      dispatch({ type: ADD_VIDEOS_TO_CURRENT_CATEGORY_RESET });
      handleClose();
      handleSuccess();
    }
  }, [addVideosToCurrentCategorySuccess]);

  useEffect(() => {
    if (addAssessmentToCategorySuccess) {
      setSelectedReel(null);
      setOrganizedReelData([]);
      setIsAddButtonClicked(false);
      dispatch({ type: ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_RESET });
      handleClose();
      handleSuccess();
    }
  }, [addAssessmentToCategorySuccess]);

  useEffect(() => {
    if (addDocumentsToCurrentCategorySuccess) {
      setSelectedReel(null);
      setOrganizedReelData([]);
      setIsAddButtonClicked(false);
      dispatch({ type: ADD_DOCUMENTS_TO_CURRENT_CATEGORY_RESET });
      handleClose();
      handleSuccess();
    }
  }, [addDocumentsToCurrentCategorySuccess]);

  const setAllChecked = () => {
    const tempCheckedContentByCategory = {};
    const tempCheckedCategoryValues = [];

    selectedReel.categories.forEach(category => {
      tempCheckedCategoryValues.push(category.id);

      tempCheckedContentByCategory[category.id] = category
        .childrenCheckBoxes
        .map(({ id }) => id);
    });

    setCheckedContentByCategory(tempCheckedContentByCategory);
    setCheckedCategoryValues(tempCheckedCategoryValues);
    setSelectNone(false);
  }

  const setAllUnChecked = () => {
    const tempCheckedContentByCategory = { ...checkedContentByCategory };

    Object.keys(tempCheckedContentByCategory).forEach((key) => {
      tempCheckedContentByCategory[key] = [];
    });

    setCheckedContentByCategory(tempCheckedContentByCategory);
    setCheckedCategoryValues([]);
    setSelectAll(false);
  }

  const organizeReelData = (dataArray) => {
    const organizedReelData = dataArray.map((reel) => {
      const { _id, reelName, category } = reel;

      const categoryData = category.map(value => {
        const {
          _id,
          name,
          vid_doc,
          assessments,
        } = value;

        let childrenCheckBoxes = [];

        if (vid_doc) {
          childrenCheckBoxes = vid_doc.map(vidDoc => {
            const {
              _id,
              title,
            } = vidDoc;

            return { id: _id, label: title };
          });
        }

        if (assessments) {
          childrenCheckBoxes = assessments.map(assessment => {
            const {
              _id,
              title,
            } = assessment;

            return { id: _id, label: title };
          });
        }

        return { id: _id, label: name, childrenCheckBoxes }; 
      });

      return { value: _id, label: reelName, categories: categoryData };
    });

    setOrganizedReelData(organizedReelData);
  }

  const handleNext = () => {
    setIsSelectByCatagoryView(true);
  }

  const addContent = () => {
    setIsAddButtonClicked(true);
    const vid_doc_sta_ids = Object.values(checkedContentByCategory).map(
      (value) => {
        return value.map(ids => ids)
    });

    let addToCategoryData = {
      groupId,
      reelId,
      categoryId,
      vid_doc_sta: [...new Set(vid_doc_sta_ids.flat())],
    };

    switch (selectedType.value) {
      case 'VIDEO':
        addContentToGroup(
          '/reel/add-videos-to-current-category',
          ADD_VIDEOS_TO_CURRENT_CATEGORY_REQUEST,
          ADD_VIDEOS_TO_CURRENT_CATEGORY_SUCCESS,
          ADD_VIDEOS_TO_CURRENT_CATEGORY_FAIL,
          addToCategoryData,
          '',
          'PUT',
          null,
          'isReelService'
        );
        break;

      case 'ASSESSMENT':
        addContentToGroup(
          '/assessment/add-standalone-assessment-to-category',
          ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_REQUEST,
          ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_SUCCESS,
          ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_FAIL,
          addToCategoryData,
          '',
          'PUT',
          null,
          'isReelService'
        );
        break;

      case 'DOCUMENT':
        addContentToGroup(
          '/reel/add-document-to-current-category',
          ADD_DOCUMENTS_TO_CURRENT_CATEGORY_REQUEST,
          ADD_DOCUMENTS_TO_CURRENT_CATEGORY_SUCCESS,
          ADD_DOCUMENTS_TO_CURRENT_CATEGORY_FAIL,
          addToCategoryData,
          '',
          'PUT',
          null,
          'isReelService'
        );
        break;

      default:
        break;
    }
  }

  const getCategoryCheckedValues = (categoryId) => {
    const tempCheckedCategoryValues = [ ...checkedCategoryValues ];
    const tempCheckedContentByCategory = { ...checkedContentByCategory };

    if (tempCheckedCategoryValues.includes(categoryId)) {
      let index = tempCheckedCategoryValues.indexOf(categoryId);

      tempCheckedCategoryValues[categoryId] = [];

      tempCheckedCategoryValues.splice(index, 1);
    } else {
      tempCheckedCategoryValues.push(categoryId);
     
      const selectedCategoryData = selectedReel.categories.find(
        category => category.id === categoryId
      );

      tempCheckedContentByCategory[categoryId] = selectedCategoryData
        .childrenCheckBoxes
        .map(({ id }) => id);
    }

    setSelectAll(false);
    setSelectNone(false);
    setCheckedCategoryValues(tempCheckedCategoryValues);
    setCheckedContentByCategory(tempCheckedContentByCategory);
  }

  const getContentCheckedValues = (contentId, categoryId) => {
    const tempCheckedCategoryValues = [ ...checkedCategoryValues ];
    const tempCheckedContentByCategory = { ...checkedContentByCategory };

    if (tempCheckedContentByCategory[categoryId].includes(contentId)) {
      let index = tempCheckedContentByCategory[categoryId].indexOf(contentId);

      tempCheckedContentByCategory[categoryId].splice(index, 1);
    } else {
      tempCheckedContentByCategory[categoryId].push(contentId);
    }

    if (childContentCountByCategory[categoryId] === tempCheckedContentByCategory[categoryId].length) {
      tempCheckedCategoryValues.push(categoryId);
    } else {
      let index = tempCheckedCategoryValues.indexOf(categoryId);

      tempCheckedCategoryValues.splice(index, 1);
    }

    setSelectAll(false);
    setSelectNone(false);
    setCheckedCategoryValues(tempCheckedCategoryValues);
    setCheckedContentByCategory(tempCheckedContentByCategory);
  }

  const renderDropDownView = () => {
    return (
      <>
        <div className="reel-view--popup-row">
          <label>Group</label>
          <div className="reel-view--popup-field">
            <Dropdown
              title="Select Group"
              value={selectedGroup ? selectedGroup.value : ''}
              options={selectGroupOptions}
              onChange={(option) =>
                setSelectedGroup(option)
              }
            />
          </div>
        </div>
        <div className="reel-view--popup-row">
          <label>Reel</label>
          <div className="reel-view--popup-field">
            {
              allStandaloneAssessmentCategoryReelLoading ||
              allReelsCategoriesDocumentsFetchDataLoading ||
              allReelsCategoriesVideosFetchDataLoading ?
                <button className="btn btn--primary btn--loader"></button> :
                <Dropdown
                  title="Select Reel"
                  value={selectedReel ? selectedReel.value : ''}
                  options={organizedReelData.map((reel) => {
                    const { value, label, } = reel;

                    return { value, label};
                  })}
                  onChange={(option) =>
                    setSelectedReel(
                      organizedReelData.find(reel => reel.value === option.value)
                    )
                  }
                />
            }
          </div>
        </div>
      </>
    );
  }

  const renderSelectByCategoryView = () => {
    return (
      <div className="reel-view--popup-row"  style={{ maxHeight: "300px"}}>
        <div
          className="reel-view--popup-field"
          style={{ maxHeight: "250px" , overflowX: 'hidden', overflowY: 'scroll'}}
        >
        {
          selectedReel.categories.map((category, index) => {
            return <CheckBoxPanel
              key={index}
              parentCheckBox={category}
              checkedParentValues={checkedCategoryValues}
              checkedChildrenValues={checkedContentByCategory}
              getParentCheckedValues={getCategoryCheckedValues}
              getChildrenCheckedValues={getContentCheckedValues}
            />
          })
        }
        </div>
      </div>
    );
  }

  const renderAddButtonLabel = () => {
    switch (selectedType.value) {
      case 'ASSESSMENT':
        return 'Assessments';

      case 'VIDEOS':
        return 'Videos';

      case 'DOCUMENT':
        return 'Documents';

      default:
        break;
    }
  }

  return (
    <div className="reel-view--popup addvideoassessment">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          {
            isSelectByCatagoryView ?
              <h3>Select Content</h3> :
              reelType === 'DOCUMENT' ?
                <h3>Add Documents</h3> :
                <Dropdown
                  title="Select Reel Type"
                  value={selectedType ? selectedType.value : ''}
                  onChange={(option) => setSelectedType(option)}
                  options={[
                    {value: 'VIDEO', label: 'Add Video'},
                    {value: 'ASSESSMENT', label: 'Add Assessment'}
                  ]}
                />
          }
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => {
              setOrganizedReelData([]);
              handleClose();
            }}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          {
            isSelectByCatagoryView ?
              renderSelectByCategoryView():
              renderDropDownView()
          }
          <div className="reel-view--popup-row">
            {
              isSelectByCatagoryView ?
                <>
                  <input
                    type="checkbox"
                    className="form-input--checkbox"
                    checked={selectAll}
                    onChange={() => setSelectAll(!selectAll)}
                  />
                  <label onClick={() => {
                    setSelectAll(!selectAll);
                    setAllChecked();
                  }}>
                    Select All
                  </label>
                  <input
                    type="checkbox"
                    className="form-input--checkbox"
                    checked={selectNone}
                    onChange={() => setSelectNone(!selectNone)}
                  />
                  <label onClick={() => {
                    setSelectNone(!selectNone);
                    setAllUnChecked();
                  }}>
                    Select None
                  </label>
                  <button
                    className={`btn btn--primary ${
                      (addVideosToCurrentCategoryLoading ||
                      addAssessmentToCategoryLoading ||
                      addDocumentsToCurrentCategoryLoading) && isAddButtonClicked ?
                      'btn--loader' :
                      ''}`
                    }
                    onClick={() => addContent()}
                    disabled={
                      addVideosToCurrentCategoryLoading ||
                      addAssessmentToCategoryLoading ||
                      addDocumentsToCurrentCategoryLoading
                    }
                  >
                    Add {renderAddButtonLabel()}
                  </button>
                </> :
                <button
                  className="btn btn--primary"
                  disabled={selectedReel === null || selectedGroup === null}
                  onClick={() => handleNext()}
                >
                  Next
                </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useDeleteApi from '../../../../Hooks/useDeleteApi';
import useChangePublishedStatusApi from '../../../../Hooks/useChangePublishedStatusApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';

import deleteIcon_white from '../../../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../../../assets/images/svg-images/icon-edit-white.svg';
import stats from '../../../../assets/images/svg-images/icon-stats.svg';
import warning from '../../../../assets/images/svg-images/icon-warning.svg';
import publish from '../../../../assets/images/svg-images/icon-upload.svg';
import copy from '../../../../assets/images/svg-images/icon-copy.svg';

import CreateReelModal from '../../Group/Modal/createReelModal';
import UpdateReelModal from '../../Group/Modal/updateReelModal';
import CloneReelModal from '../../Group/Modal/cloneReelModal';
import { organizeDate } from '../../../../Helper/SystemManager';
import ReelStatsModal from '../../Group/Modal/reelStatsModal';
import UserControlModal from '../../Group/Modal/userControlModal';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import Loader from '../../../../Components/Loader/loader';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import { ToolTipConfig } from '../../../../Common/ToolTipConfig.js';
import AdminDashboardSelectionDropDown from '../../../../Components/Admin/AdminDashboardSelectionDropDown';
import ActionDropdown from '../../../../Components/Admin/ActionDropdown';

const SUPER_ADMIN_ID = 4;

export default function SearchUser({ match, history }) {
  const { t } = useTranslation();
  const urlType = match.params.reelType;
  const canCreateReel = false;

  const [isGridView, setIsGridView] = useState(true);
  const [reelType, setReelType] = useState();
  const [selectedReel, setSelectedReel] = useState(null);
  const [searchReelName, setSearchReelName] = useState('');
  const [isCreateReelOpen, setIsCreateReelOpen] = useState(false);
  const [isUpdateReelModalOpen, setIsUpdateReelModalOpen] = useState(false);
  const [isCloneReelModalOpen, setIsCloneReelModalOpen] = useState(false);
  const [isViewStatsModalOpen, setIsViewStatsModalOpen] = useState(null);
  const [isUserControlModalOpen, setIsUserControlModalOpen] = useState(false);
  const [organizedVideoReels, setOrganizedVideoReels] = useState([]);
  const [organizedDocumentReels, setOrganizedDocumentReels] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(null);
  const { roleList } = useSelector(state => state?.auth?.userData);
  const {
    reelsByGroupSuccess,
    documentReelsFetchDataSuccess,
    suspendReelLoading,
    reelsByGroupLoading,
    documentReelsFetchDataLoading,
    deleteReelLoading,
  } = useSelector(state => state?.reel);
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;
  const defaultDocument = themeInfo?.documentImageUrl;

  const [fetchData] = useFetchDataApi();
  const [updateIsPublishedStatus] = useChangePublishedStatusApi();
  const [deleteReel] = useDeleteApi();

  useEffect(() => {
    if (roleList && roleList.length > 0) {
      setIsSuperAdmin(
        [...roleList].map(({ id }) => id).includes(SUPER_ADMIN_ID)
      );
    }
  }, [roleList]);

  const getVideoReels = () => fetchData({ type: 'VIDEO_REELS' });
  const getDocumentReels = () => fetchData({ type: 'DOCUMENT_REELS' });

  useEffect(() => {
    if (urlType) {
      setReelType(urlType.toUpperCase());
    }
  }, [urlType]);

  useEffect(() => {
    if (reelsByGroupSuccess) {
      const organizedVideoReels = reelsByGroupSuccess.map(videoReel => {
        const {
          _id,
          date,
          includeUser,
          noOfGroups,
          isPublished,
          noOfVideos,
          views,
          name,
          previewImageUrl,
          groupId,
        } = videoReel;

        return {
          _id,
          name,
          groupId,
          isPublished,
          isEmpty: noOfVideos === 0,
          dateCreated: organizeDate(date),
          totalViews: views,
          numberOfUsers: includeUser,
          numberOfGroups: noOfGroups,
          numberOfVideos: noOfVideos,
          imageUrl: previewImageUrl || defaultVideo,
        };
      });

      setOrganizedVideoReels(organizedVideoReels);
    }
  }, [reelsByGroupSuccess]);

  useEffect(() => {
    if (documentReelsFetchDataSuccess) {
      const organizedDocumentReels = documentReelsFetchDataSuccess.map(
        documentReel => {
          const {
            _id,
            name,
            date,
            image,
            views,
            isPublished,
            noOfGroups,
            includeUser,
            noOfDocuments,
            groupId,
          } = documentReel;

          return {
            _id,
            name,
            groupId,
            isPublished,
            isEmpty: noOfDocuments === 0,
            dateCreated: organizeDate(date),
            totalViews: views,
            numberOfUsers: includeUser,
            numberOfGroups: noOfGroups,
            numberOfDocuments: noOfDocuments,
            imageUrl: image || defaultDocument,
          };
        }
      );

      setOrganizedDocumentReels(organizedDocumentReels);
    }
  }, [documentReelsFetchDataSuccess]);

  useEffect(() => {
    const currentUrl = window.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');
    switch (reelType) {
      case 'VIDEO':
        history.push(`${currentUrl}/video`);
        getVideoReels();
        break;

      case 'DOCUMENT':
        history.push(`${currentUrl}/document`);
        getDocumentReels();
        break;

      default:
        break;
    }
  }, [reelType]);

  useEffect(() => {
    if (!deleteReelLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteReelLoading]);

  const handleEditReelNameData = (modalStatus, reel) => {
    setIsUpdateReelModalOpen(modalStatus);

    if (modalStatus) {
      setSelectedReel(reel);
    } else {
      setSelectedReel(null);
    }
  };

  const handleCloneReelData = (modalStatus, reel) => {
    setIsCloneReelModalOpen(modalStatus);

    if (modalStatus) {
      setSelectedReel(reel);
    } else {
      setSelectedReel(null);
    }
  };

  const hanldeIsPublishedStatus = reelId => {
    updateIsPublishedStatus({
      _id: reelId,
      type: 'REEL',
      reelType: reelType,
    });
  };

  const setReelListTableActions = (value, reel) => {
    switch (value) {
      case 'Rename':
        handleEditReelNameData(true, reel);
        break;

      case 'Duplicate':
        handleCloneReelData(true, reel);
        break;

      case 'Suspend':
        hanldeIsPublishedStatus(reel._id);
        break;

      case 'Publish':
        hanldeIsPublishedStatus(reel._id);
        break;

      case 'Delete':
        handleDelete(reel._id);
        break;

      default:
        break;
    }
  };

  const handleDelete = id => {
    let deleteRequestData = {
      id,
      type: 'REEL',
      reelType,
    };

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const handleClickOnTableRow = (event, id) => {
    if (
      event.target.classList.contains('Dropdown-placeholder') ||
      event.target.classList.contains('Dropdown-control')
    ) {
      event.preventDefault();
    } else {
      const attachUrl = reelType === 'VIDEO' ? 'video' : 'document';

      history.push(`/search-reels/${attachUrl}/${id}`);
    }
  };

  const handleViewStats = reel => {
    setIsViewStatsModalOpen(reel._id);
    setSelectedReel(reel);
  };

  const renderReelsView = () => {
    switch (reelType) {
      case 'VIDEO':
        return organizedVideoReels
          .filter(
            ({ name }) =>
              searchReelName === '' ||
              name?.toUpperCase().includes(searchReelName.toUpperCase())
          )
          .map((reel, index) => {
            if (isGridView) {
              return renderGridTile(reel, index);
            } else {
              return renderTableRow(reel, index);
            }
          });

      case 'DOCUMENT':
        return organizedDocumentReels
          .filter(
            ({ name }) =>
              searchReelName === '' ||
              name?.toUpperCase().includes(searchReelName.toUpperCase())
          )
          .map((documentReel, index) => {
            if (isGridView) {
              return renderGridTile(documentReel, index);
            } else {
              return renderTableRow(documentReel, index);
            }
          });

      default:
        break;
    }
  };

  const renderGridTile = (gridObject, index) => {
    const { _id, name, imageUrl, isPublished, isEmpty } = gridObject;

    const attachUrl = reelType === 'VIDEO' ? 'video' : 'document';

    return (
      <div className="reel-view--grid-video-wrapper" key={index}>
        <div
          className={`reel-view--grid-video ${
            !isPublished ? 'unpublished' : ''
          }`}
        >
          <div
            className="reel-view--grid-video-unpublished"
            onClick={() => history.push(`/search-reels/${attachUrl}/${_id}`)}
          ></div>
          {!isEmpty ? (
            <img
              src={imageUrl}
              onError={event => {
                event.target.onerror = null;
                event.target.src =
                  reelType === 'VIDEO' ? defaultVideo : defaultDocument;
              }}
              alt="reel-thumbnail"
              className="reel-thumbnail"
              onClick={() => history.push(`/search-reels/${attachUrl}/${_id}`)}
            />
          ) : (
            <div
              className={`reel-view--grid-video ${
                reelType === 'VIDEO' ? 'emptyreel' : 'emptyreel-document'
              }`}
              onClick={() => history.push(`/search-reels/${attachUrl}/${_id}`)}
            ></div>
          )}
          <div
            className={`reel-view--grid-video-settings ${
              isViewStatsModalOpen === _id ? 'dataModal' : ''
            }`}
          >
            {isSuperAdmin && (
              <img
                src={edit_white}
                alt="edit-icon"
                className="icon--edit-white"
                onClick={() => handleEditReelNameData(true, gridObject)}
              />
            )}
            {isSuperAdmin && (
              <img
                src={isPublished ? warning : publish}
                alt={isPublished ? 'warning-icon' : 'publish-icon'}
                className={isPublished ? 'icon--warning' : 'icon--publish'}
                onClick={() => hanldeIsPublishedStatus(_id)}
              />
            )}
            {isSuperAdmin && (
              <img
                src={deleteIcon_white}
                alt="delete-icon"
                className="icon--delete-white"
                onClick={() => handleDelete(_id)}
              />
            )}
            <div className="reel-view--grid-video-settings-stats">
              <img
                src={stats}
                alt="stats-icon"
                className="icon--stats"
                onClick={() => handleViewStats(gridObject)}
              />
            </div>
            <img
              src={copy}
              alt="delete-icon"
              className="icon--copy"
              onClick={() => handleCloneReelData(true, gridObject)}
            />
          </div>
          {isViewStatsModalOpen && isViewStatsModalOpen === _id && (
            <ReelStatsModal
              reel={selectedReel}
              isOpen={isViewStatsModalOpen === _id}
              handleUserAccessControl={() => setIsUserControlModalOpen(true)}
              handleClose={() => setIsViewStatsModalOpen(null)}
              fetchReels={
                reelType === 'VIDEO'
                  ? () => getVideoReels()
                  : () => getDocumentReels()
              }
            />
          )}
        </div>
        <p>{name}</p>
      </div>
    );
  };

  const renderTableRow = (rowItem, index) => {
    const {
      _id,
      name,
      dateCreated,
      imageUrl,
      totalViews,
      numberOfUsers,
      numberOfDocuments,
      numberOfVideos,
      isPublished,
    } = rowItem;

    return (
      <div
        key={index}
        className="reel-view--table-row"
        onClick={event => handleClickOnTableRow(event, _id)}
      >
        <div className="reel-view--table-cell">
          <img
            src={imageUrl}
            className="reel-view--table-backgroundimg"
            onError={event => {
              event.target.onerror = null;
              event.target.src =
                reelType === 'VIDEO' ? defaultVideo : defaultDocument;
            }}
          />
        </div>
        <div className="reel-view--table-cell">
          <p>{name}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>{dateCreated}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>{numberOfUsers}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>
            {numberOfVideos !== undefined ? numberOfVideos : numberOfDocuments}
          </p>
        </div>
        {reelType === 'VIDEO' && (
          <div className="reel-view--table-cell">
            <p>{totalViews}</p>
          </div>
        )}
        <div className="reel-view--table-cell">
          <ActionDropdown
            ishaveRename={isSuperAdmin}
            ishaveSuspend={isSuperAdmin && isPublished}
            ishavePublish={isSuperAdmin && !isPublished}
            ishaveDelete={isSuperAdmin}
            ishaveDuplicate={true}
            handleOnchange={option =>
              setReelListTableActions(option.value, rowItem)
            }
          />
        </div>
      </div>
    );
  };

  const isLoading =
    reelsByGroupLoading || documentReelsFetchDataLoading || suspendReelLoading;

  return (
    <div
      className={`all-users-table allreels mainscreen loading-screen-parent ${
        isLoading ? 'setheight' : ''
      }`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="all-users-table--wrapper">
            <div className="all-users-table--page-title">
              <div className="all-users-table--page-selection">
                <AdminDashboardSelectionDropDown
                  history={history}
                  selected={'REELS'}
                />
              </div>
              <div className="all-users-table--page-settings">
                <input
                  className="form-input form-input--search"
                  type="search"
                  id="search"
                  name="search"
                  placeholder={t('Search')}
                  value={searchReelName}
                  onChange={event => setSearchReelName(event.target.value)}
                />
                {/* <Dropdown title="Filter By" options={[]} /> */}
                {canCreateReel && (
                  <button
                    className="btn btn--primary btn--createnew"
                    onClick={() => setIsCreateReelOpen(true)}
                  >
                    {t('Create Reel')}
                  </button>
                )}
                <div
                  className={`all-users-table--image grid ${
                    isGridView ? 'active' : ''
                  }`}
                  title={t(ToolTipConfig.general.gridView)}
                  onClick={() => setIsGridView(true)}
                />
                <div
                  className={`all-users-table--image list ${
                    !isGridView ? 'active' : ''
                  }`}
                  title={t(ToolTipConfig.general.tableView)}
                  onClick={() => setIsGridView(false)}
                />
              </div>
            </div>
            <div className="reel-view--page-reelselection">
              <p
                className={reelType === 'VIDEO' ? 'active' : ''}
                onClick={() => setReelType('VIDEO')}
              >
                {t('Reels')}
              </p>
              <p
                className={reelType === 'DOCUMENT' ? 'active' : ''}
                onClick={() => setReelType('DOCUMENT')}
              >
                {t('Document Reels')}
              </p>
            </div>
            {isGridView ? (
              <div className="all-users-table--grid">
                <div className="all-users-table--grid-wrapper">
                  {renderReelsView()}
                </div>
              </div>
            ) : (
              <div className="all-users-table--table">
                <div className="all-users-table--table-header">
                  <div className="reel-view--table-cell">
                    <h5>{t('Preview')}</h5>
                  </div>
                  <div className="reel-view--table-cell">
                    <h5>{t('Title')}</h5>
                  </div>
                  <div className="reel-view--table-cell">
                    <h5>{t('Date Created')}</h5>
                  </div>
                  <div className="reel-view--table-cell">
                    <h5>{t('User Access')}</h5>
                  </div>
                  <div className="reel-view--table-cell">
                    <h5>
                      {t('No. of')}{' '}
                      {reelType === 'VIDEO' ? 'Videos' : 'Documents'}
                    </h5>
                  </div>
                  {reelType === 'VIDEO' && (
                    <div className="reel-view--table-cell">
                      <h5>{t('Total Views')}</h5>
                    </div>
                  )}
                  <div className="reel-view--table-cell">
                    {/* Dropdown column */}
                  </div>
                </div>
                <div className="all-users-table--table-body">
                  {renderReelsView()}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isCreateReelOpen && (
        <CreateReelModal
          type={reelType}
          handleClose={() => setIsCreateReelOpen(false)}
          fetchReels={
            reelType === 'VIDEO'
              ? () => getVideoReels()
              : () => getDocumentReels()
          }
        />
      )}
      {isUpdateReelModalOpen && (
        <UpdateReelModal
          type={reelType}
          selectedReel={selectedReel}
          handleClose={() => setIsUpdateReelModalOpen(false)}
          fetchReels={
            reelType === 'VIDEO'
              ? () => getVideoReels()
              : () => getDocumentReels()
          }
        />
      )}
      {isCloneReelModalOpen && (
        <CloneReelModal
          cloningReelId={selectedReel._id}
          handleClose={() => setIsCloneReelModalOpen(false)}
          fetchReels={
            reelType === 'VIDEO'
              ? () => getVideoReels()
              : () => getDocumentReels()
          }
        />
      )}
      {isUserControlModalOpen && (
        <UserControlModal
          reelId={selectedReel._id}
          reelName={selectedReel.name}
          handleClose={() => setIsUserControlModalOpen(false)}
          handleSuccess={
            reelType === 'VIDEO'
              ? () => getVideoReels()
              : () => getDocumentReels()
          }
        />
      )}
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={messageConfigs.deleteConfirm.title}
          message={messageConfigs.deleteConfirm.reel}
          handleSuccess={() => deleteReel(isDeleteConfirmModalOpen)}
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
          isLoading={deleteReelLoading}
        />
      )}
    </div>
  );
}

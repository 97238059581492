import React from 'react';
import { useState } from 'react';
import messageConfigs from '../../Helper/PopupMessageConfig';
import { useTranslation } from 'react-i18next';

const DeleteGroupModal = ({
  onConfirm,
  handleSuccess,
  handleClose,
  isLoading,
  isHaveChildGroup,
  isSuccess,
  isError,
  groupName,
}) => {
  const { t } = useTranslation();
  const [groupNameText, setGroupNameText] = useState('');

  const renderConfirmMessage = () => {
    return isHaveChildGroup ? (
      <>
        <div className="reel-view--popup-row">
          <label>
            {t(
              'This group has child groups under it, please delete all child groups before attempting to delete this group!'
            )}
          </label>
        </div>
        <div className="reel-view--popup-row">
          <button className="btn btn--primary" onClick={handleClose}>
            {t('Close')}
          </button>
        </div>
      </>
    ) : (
      <>
        <div className="reel-view--popup-row">
          <label>{t('Are you sure you want to delete this group?')}</label>
          <label>
            {t(
              'Deleting a group will remove all users, reels, videos, documents, assessments, banner and featured content.'
            )}
          </label>
          <label>{t('This action is not reversible!')}</label>
        </div>
        <div className="reel-view--popup-row">
          <label>
            {t('if you want to proceed please type the group name')}
          </label>
          <input
            type="text"
            placeholder={t('Group name')}
            value={groupNameText}
            onChange={event => setGroupNameText(event.target.value)}
          />
        </div>
        {groupName !== groupNameText && groupNameText !== '' && (
          <div className="reel-view--popup-row warning">
            <h4>
              <span>{t('Warning:')}</span> {t('Wrong Group Name')}
            </h4>
          </div>
        )}
        <div className="reel-view--popup-row">
          {groupName === groupNameText && groupNameText !== '' && (
            <button
              className={`
                btn btn--primary
                ${isLoading ? 'btn--loader' : ''}
              `}
              onClick={onConfirm}
            >
              {t('Confirm')}
            </button>
          )}
          {!isLoading && (
            <button className="btn btn--secondary" onClick={handleClose}>
              {t('Cancel')}
            </button>
          )}
        </div>
      </>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <>
        <div className="reel-view--popup-row">
          <label>
            {t('The')} {groupName} {t('group has successfully been deleted.')}
          </label>
        </div>
        <div className="reel-view--popup-row">
          <button className="btn btn--primary" onClick={handleSuccess}>
            {t('Proceed')}
          </button>
        </div>
      </>
    );
  };

  const renderErrorMessage = () => {
    return (
      <>
        <div className="reel-view--popup-row">
          <label>{t(messageConfigs.error.TRY_LATER)}</label>
        </div>
        <div className="reel-view--popup-row">
          <button className="btn btn--primary" onClick={handleClose}>
            {t('Close')}
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="reel-view--popup createreel">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('Delete Group!')}</h3>
        </div>
        <div className="reel-view--popup-body">
          {isSuccess
            ? renderSuccessMessage()
            : isError
            ? renderErrorMessage()
            : renderConfirmMessage()}
        </div>
      </div>
    </div>
  );
};

export default DeleteGroupModal;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DottedLoader from '../Loader/DottedLoader';

export default function AssignedVideosViewModal({
  handleClose,
  isLoading,
  assignedVideos,
  numberOfVideos,
  assessmentTitle,
}) {
  const { t } = useTranslation();
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;

  return (
    <div className="reel-view--popup createreel">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Assigned Videos')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            ×
          </a>
        </div>
        {isLoading ? (
          <DottedLoader />
        ) : (
          <div className="reel-view--popup-body noSidePadding">
            <div className="reel-view--popup-row body--row1">
              <div className="reel-view--popup-innerRow">
                <label className="reel-view--popup-title">
                  {t('Name of the Assessment ')}
                </label>
                <label className="reel-view--popup-title2">
                  {assessmentTitle}
                </label>
              </div>
              <div className="reel-view--popup-innerRow2">
                <label className="reel-view--popup-title3">
                  {t('No. of Videos')}
                </label>
                <label className="reel-view--popup-title4">
                  {numberOfVideos}
                </label>
              </div>
            </div>
            <div className="reel-view--popup-row body--row2">
              <div className="all-users-table--table">
                <div className="all-users-table--table-header">
                  <div className="reel-view--table-cell">
                    <h5>{t('Preview')}</h5>
                  </div>
                  <div className="reel-view--table-cell">
                    <h5>{t('Title')}</h5>
                  </div>
                  <div className="reel-view--table-cell">
                    <h5>{t('Position')}</h5>
                  </div>
                </div>
                <div className="all-users-table--table-body">
                  {assignedVideos.map(({ id, title, previewImgUrl }) => (
                    <div className="reel-view--table-row" key={id}>
                      <div className="reel-view--table-cell">
                        <img
                          src={previewImgUrl || defaultVideo}
                          className="reel-view--table-backgroundimg"
                          onError={event => {
                            event.target.onerror = null;
                            event.target.src = defaultVideo;
                          }}
                        />
                      </div>
                      <div className="reel-view--table-cell">
                        <p>{title}</p>
                      </div>
                      <div className="reel-view--table-cell">
                        <p>{t('Mid-video')}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorMessageModal = ({ errorData, handleSubmit }) => {
  const { t } = useTranslation();
  const loadEmptyFieldList = () => {
    return errorData['emptyFields'].map((value, index) => (
      <li key={index}>{value}</li>
    ));
  };

  const loadInvalidValuesList = () => {
    return errorData['invalidValues'].map((value, index) => (
      <li key={index}>{value}</li>
    ));
  };

  return (
    <div className="reel-view--popup message 123">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('Error')}</h3>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            {errorData['emptyFields'] && errorData['emptyFields'].length !== 0 && (
              <>
                <label>
                  {t("You haven't filled the following required fields - ")}
                </label>
                <ul>{loadEmptyFieldList()}</ul>
              </>
            )}
            {errorData['invalidValues'] &&
              errorData['invalidValues'].length !== 0 && (
                <ul>{loadInvalidValuesList()}</ul>
              )}
          </div>
          <div className="reel-view--popup-row">
            <div className="reel-view--popup-field">
              <button className="btn btn--primary" onClick={handleSubmit}>
                {t('Close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessageModal;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import validIcon from '../../assets/images/svg-images/valid.svg';
import invalidIcon from '../../assets/images/svg-images/Invalid.svg';
import { FETCH_SSO_USER_VERIFY_RESET } from '../../redux/actionTypes/auth.js';
import { useDispatch } from 'react-redux';

const SsoVerifyModal = ({
  handleClose,
  onClickVerifySsoUser,
  onClickContinueSsoUser,
}) => {
  const [emailAddress, setEmailAddress] = useState('');
  const { t } = useTranslation();
  const { ssoUserVerifyLoading, ssoUserVerified, ssoUserVerifyError } =
    useSelector(state => state?.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    !emailAddress?.length &&
      ssoUserVerifyError &&
      dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
  }, [emailAddress]);

  return (
    <div className="reel-view--popup message ssoverify">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('Email Verification')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            ×
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{t('Enter your SSO email address')}</label>
            <div className="inputField">
              <input
                type="text"
                placeholder={'Email address'}
                value={emailAddress}
                disabled={ssoUserVerified}
                onChange={event => setEmailAddress(event.target.value)}
              />
              {ssoUserVerifyError ? <img src={invalidIcon} /> : null}
              {ssoUserVerified && !ssoUserVerifyError ? (
                <img src={validIcon} />
              ) : null}
            </div>

            {ssoUserVerifyError ? (
              <span>
                {t(
                  'You do not have a valid account. Please contact the admin.'
                )}
              </span>
            ) : null}
          </div>

          <div className="reel-view--popup-row">
            <div className="reel-view--popup-field">
              {!ssoUserVerified && !ssoUserVerifyError ? (
                <button
                  className={`
                btn btn--primary btn--curved
                ${ssoUserVerifyLoading ? 'btn--loader' : ''}
              `}
                  disabled={!emailAddress}
                  onClick={() => onClickVerifySsoUser(emailAddress)}
                >
                  {t('Verify')}
                </button>
              ) : null}
              {ssoUserVerified && !ssoUserVerifyError ? (
                <button
                  className="btn btn--primary btn--curved"
                  onClick={() => onClickContinueSsoUser()}
                >
                  {t('Continue')}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SsoVerifyModal;

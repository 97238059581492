import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { UserPrivilegesForRoles } from "../Common/UserPrivileges";
import { getChildGroups } from "../Helper/SystemManager";

const SUPER_ADMIN_ID = 4;

export const useCheckPermission = (permissionType) => {
  const { groupInfo, roleList } = useSelector((state) => state?.auth?.userData);
  const [havePrivilege, setHavePrivileges] = useState(false);
  const { allGroupsFetchData } = useSelector((state) => state?.group);
  const flattenedGroups = [];

  useEffect(() => {
    if (!Array.isArray(allGroupsFetchData) && allGroupsFetchData && groupInfo && groupInfo.length) {
      const tempRoleList = [...roleList];

      if (tempRoleList.map(({id})=> id).includes(SUPER_ADMIN_ID)) {
        setHavePrivileges(true);
      } else {
        checkUserPrivilegesByGroup(allGroupsFetchData);
      }
    }
  }, [allGroupsFetchData]);

  const checkUserPrivilegesByGroup = (allGroupsData) => {
    const tempAllGroupsData = {...allGroupsData};
    const tempGroupInfo = [...groupInfo];
    const privilegesByGroup = {};
    const childPrivileges = {};

    tempGroupInfo.forEach((group) => {
      privilegesByGroup[group.divisionId] = group.userData.map((data) => data.roleReferenceId).some(id => {
        return checkPermission(id, permissionType);
      });
    });

    Object.keys(privilegesByGroup).forEach(key => {
      if (privilegesByGroup[key]) {
        flatteningTreeNodes(getChildGroups(tempAllGroupsData, key));

        flattenedGroups.forEach((group) => {
            childPrivileges[group._id] = true;
        });
      }
    });

    setHavePrivileges({...privilegesByGroup, ...childPrivileges});
  }

  const checkPermission = (roleId, privilegeType) => {
    // Checking for super admin.
    if (roleId === 4) {
      return true;
    }

    // Checking if given id is included in user privileges.
    if (!UserPrivilegesForRoles[roleId]) {
      return false;
    }

    // Checking if privilege type is a string value.
    if (typeof privilegeType !== 'string') {
      return false;
    }

    return UserPrivilegesForRoles[roleId].includes(privilegeType);
  };

  // Flattening tree nodes to one array
  const flatteningTreeNodes = (node) => {
    flattenedGroups.push(node);

    // Recursive for all childrens
    node.children && node.children.forEach(flatteningTreeNodes);
  }

  return havePrivilege;
};

export default useCheckPermission;

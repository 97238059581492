import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../Components/Loader/loader';
import ProceedMessageModal from '../../../Components/Modal/ProceedMessageModal';
import useFetchDataApi from '../../../Hooks/useFetchDataApi';
import useUpdateApi from '../../../Hooks/useUpdateApi';
import {
  FETCH_EMAIL_NOTICATION_STATUS_SUCCESS,
  UPDATE_EMAIL_NOTICATION_STATUS_RESET,
} from '../../../redux/actionTypes/notification';

const NotificationSettings = () => {
  const { t } = useTranslation();
  const [emailStatus, setEmailStatus] = useState(false);

  const {
    emailNotificationStatus,
    emailNotificationStatusloading,
    updateEmailNotificationStatus,
  } = useSelector(state => state?.notification);

  const [fetchEmailNotificationStatusAPI] = useFetchDataApi();
  const [updateEmailNotificationStatusAPI] = useUpdateApi();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchEmailNotificationStatusAPI({
      type: 'EMAIL_NOTIFICATION_STATUS',
    });

    return () => {
      dispatch({
        type: UPDATE_EMAIL_NOTICATION_STATUS_RESET,
      });
      dispatch({
        type: FETCH_EMAIL_NOTICATION_STATUS_SUCCESS,
      });
    };
  }, []);

  useEffect(() => {
    if (
      !updateEmailNotificationStatus?.isSuccess &&
      updateEmailNotificationStatus?.message
    ) {
      setEmailStatus(!emailStatus);
    }
  }, [updateEmailNotificationStatus]);

  useEffect(() => {
    if (emailNotificationStatus?.length) {
      setEmailStatus(
        emailNotificationStatus[0].notifyMethods.includes('EMAIL')
      );
    }
  }, [emailNotificationStatus]);

  const renderView = () => {
    return (
      <div>
        <div className="notification-screen__rightcol--title">
          <h2>{t('Notification Settings')}</h2>
        </div>
        <div>
          <p>{t('Email Notifications')}</p>
          <div className="header__switch">
            <label className="switch">
              <input
                readOnly
                id="headerSwitch"
                type="checkbox"
                checked={emailStatus}
                onClick={() => {
                  setEmailStatus(!emailStatus);
                  updateEmailNotificationStatusAPI({
                    type: 'UPDATE_EMAIL_NOTIFICATION_STATUS',
                    data: emailNotificationStatus.map(({ eventType }) => ({
                      eventType,
                      notifyMethods: !emailStatus ? ['WEB', 'EMAIL'] : ['WEB'],
                    })),
                  });
                }}
              />
              <span className="slider round"></span>
            </label>
            <p>{t(emailStatus ? t('On') : t('Off'))}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`loading-screen-parent ${
          emailNotificationStatusloading ? 'setheight' : ''
        }`}
      >
        {emailNotificationStatusloading ? <Loader /> : renderView()}
      </div>
      {updateEmailNotificationStatus && (
        <ProceedMessageModal
          isSuccess={updateEmailNotificationStatus.isSuccess}
          message={updateEmailNotificationStatus.message}
          handleClose={() =>
            dispatch({
              type: UPDATE_EMAIL_NOTICATION_STATUS_RESET,
            })
          }
          handleSuccess={() =>
            dispatch({
              type: UPDATE_EMAIL_NOTICATION_STATUS_RESET,
            })
          }
          handleError={() =>
            dispatch({
              type: UPDATE_EMAIL_NOTICATION_STATUS_RESET,
            })
          }
        />
      )}
    </>
  );
};

export default NotificationSettings;

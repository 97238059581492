const { REACT_APP_CODE } = process.env;

let api = '';

if (REACT_APP_CODE !== 'prod') {
  api = 'http://koach-dev-alb-1499347836.us-east-1.elb.amazonaws.com';
  } else {
  api = 'http://knod-prod-alb-103558827.eu-west-1.elb.amazonaws.com';
}
export { api };

import React from 'react';
import { useTranslation } from 'react-i18next';

const TagSelection = ({ tags, customClassName, handleChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <input
        type="text"
        id="upload-tags"
        name="upload-tags"
        placeholder={t('Seperate your tags with a comma')}
        value={tags}
        onChange={e => handleChange(e.target.value, 'tags')}
      />
      <div
        className={
          customClassName
            ? customClassName
            : 'admin__adddocument--tags-selected'
        }
      >
        {tags &&
          tags.split(',').map((tag, index) => (
            <p key={index}>
              {tag}
              <span className="close-icon">
                <a
                  className="btn-close"
                  aria-hidden="true"
                  onClick={() => {
                    const tempTags = tags.split(',');
                    handleChange(
                      tempTags.filter(tempTag => tempTag !== tag).join(),
                      'tags'
                    );
                  }}
                >
                  &times;
                </a>
              </span>
            </p>
          ))}
      </div>
    </>
  );
};

export default TagSelection;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useApi from '../Hooks/useApi';
import { removePropertyFromObject } from '../Helper/SystemManager';
import {
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAIL,
  FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS,
} from '../redux/actionTypes/video';
import {
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAIL,
  FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS,
} from '../redux/actionTypes/documentData';
import {
  DELETE_STANDALONE_ASSESSMENT_REQUEST,
  DELETE_STANDALONE_ASSESSMENT_SUCCESS,
  DELETE_STANDALONE_ASSESSMENT_FAIL,
  DELETE_MID_END_ASSESSMENT_REQUEST,
  DELETE_MID_END_ASSESSMENT_SUCCESS,
  DELETE_MID_END_ASSESSMENT_FAIL,
  FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA,
  FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS,
} from '../redux/actionTypes/assesment';
import {
  DELETE_BANNER_FEATURED_IMAGE_REQUEST,
  DELETE_BANNER_FEATURED_IMAGE_SUCCESS,
  DELETE_BANNER_FEATURED_IMAGE_FAIL,
} from '../redux/actionTypes/bannerAndFeatured';
import {
  DELETE_REEL_BY_ID_REQUEST,
  DELETE_REEL_BY_ID_SUCCESS,
  DELETE_REEL_BY_ID_FAIL,
  FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS,
  FETCH_REELS_BY_GROUP_SUCCESS,
  FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
  FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS,
} from '../redux/actionTypes/reels';
import {
  REMOVE_NOTIFICATION_FAIL,
  REMOVE_NOTIFICATION_REQUEST,
  REMOVE_NOTIFICATION_SUCCESS,
} from '../redux/actionTypes/notification';

function useDeleteApi() {
  const [deleteRequestData, setDeleteRequestData] = useState(null);
  const [viewType, setViewType] = useState('');
  const dispatch = useDispatch();
  const [deleteData] = useApi();

  const {
    reelsByGroupSuccess,
    documentReelsFetchDataSuccess,
    deleteReelSuccess,
    allCategoriesFetchDataSuccess,
    mandatoryReelsFetchData,
  } = useSelector(state => state?.reel);
  const { fetchAllDocumentsByGroupData, deleteDocumentSuccess } = useSelector(
    state => state?.documentData
  );
  const { fetchAllVideosByGroupData, deleteVideoSuccess } = useSelector(
    state => state?.video
  );
  const {
    midEndAssementsByGroupFetchData,
    standaloneAssementsByGroupFetchData,
    deleteStandaloneAssessmentSuccess,
    deleteMidEndAssessmentSuccess,
  } = useSelector(state => state?.assessment);
  const { deleteImageSuccess } = useSelector(state => state?.bannerAndFeatured);

  useEffect(() => {
    if (deleteRequestData) {
      switch (deleteRequestData.type) {
        case 'VIDEO':
          deleteData(
            `/video/delete-video`,
            DELETE_VIDEO_REQUEST,
            DELETE_VIDEO_SUCCESS,
            DELETE_VIDEO_FAIL,
            removePropertyFromObject(deleteRequestData, 'type'),
            '',
            'DELETE',
            null,
            'isReelService'
          );
          break;

        case 'DOCUMENT':
          deleteData(
            `/document/delete-document`,
            DELETE_DOCUMENT_REQUEST,
            DELETE_DOCUMENT_SUCCESS,
            DELETE_DOCUMENT_FAIL,
            removePropertyFromObject(deleteRequestData, 'type'),
            '',
            'DELETE',
            null,
            'isReelService'
          );
          break;

        case 'STANDALONE_ASSESSMENT':
          deleteData(
            `/assessment/delete-standalone`,
            DELETE_STANDALONE_ASSESSMENT_REQUEST,
            DELETE_STANDALONE_ASSESSMENT_SUCCESS,
            DELETE_STANDALONE_ASSESSMENT_FAIL,
            removePropertyFromObject(deleteRequestData, 'type'),
            '',
            'PUT',
            null,
            'isReelService'
          );
          break;

        case 'MID_END_ASSESSMENT':
          deleteData(
            `/assessment/delete-mid-end-assessment`,
            DELETE_MID_END_ASSESSMENT_REQUEST,
            DELETE_MID_END_ASSESSMENT_SUCCESS,
            DELETE_MID_END_ASSESSMENT_FAIL,
            removePropertyFromObject(deleteRequestData, 'type'),
            '',
            'DELETE',
            null,
            'isReelService'
          );
          break;

        case 'IMAGE':
          deleteData(
            `/image/delete-image?imageId=${deleteRequestData.id}&groupId=${deleteRequestData.groupId}`,
            DELETE_BANNER_FEATURED_IMAGE_REQUEST,
            DELETE_BANNER_FEATURED_IMAGE_SUCCESS,
            DELETE_BANNER_FEATURED_IMAGE_FAIL,
            null,
            '',
            'DELETE',
            null,
            'isReelService'
          );
          break;

        case 'REEL':
          let deleteReelUrl = `/reel/delete-reel?reelId=${deleteRequestData.id}`;

          if (deleteRequestData.groupId) {
            deleteReelUrl += `&groupId=${deleteRequestData.groupId}`;
          }

          deleteData(
            deleteReelUrl,
            DELETE_REEL_BY_ID_REQUEST,
            DELETE_REEL_BY_ID_SUCCESS,
            DELETE_REEL_BY_ID_FAIL,
            null,
            '',
            'DELETE',
            null,
            'isReelService'
          );
          break;

        case 'NOTIFICATION':
          deleteData(
            `/users/${
              deleteRequestData.userId
            }/notifications?notificationIds=${deleteRequestData.notificationIds.toString()}`,
            REMOVE_NOTIFICATION_REQUEST,
            REMOVE_NOTIFICATION_SUCCESS,
            REMOVE_NOTIFICATION_FAIL,
            null,
            '',
            'DELETE',
            null,
            'isWebsocketService'
          );
          break;

        default:
          break;
      }
    }
  }, [deleteRequestData]);

  useEffect(() => {
    if (deleteReelSuccess && deleteRequestData) {
      switch (deleteRequestData.reelType) {
        case 'VIDEO':
          const updatedVideoReels = reelsByGroupSuccess.filter(
            reel => reel._id !== deleteRequestData.id
          );

          dispatch({
            type: FETCH_REELS_BY_GROUP_SUCCESS,
            payload: updatedVideoReels,
          });
          break;

        case 'DOCUMENT':
          const updatedDocReels = documentReelsFetchDataSuccess.filter(
            reel => reel._id !== deleteRequestData.id
          );

          dispatch({
            type: FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS,
            payload: updatedDocReels,
          });
          break;

        default:
          break;
      }
    }
  }, [deleteReelSuccess]);

  useEffect(() => {
    if (deleteDocumentSuccess && deleteRequestData) {
      switch (viewType) {
        case 'REEL_CATEGORY_VIEW':
          const updatedDocumentsByCategory = updateCategoryViewData(
            allCategoriesFetchDataSuccess,
            'documents',
            deleteRequestData.vid_doc_standalone_Id
          );

          dispatch({
            type: FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
            payload: updatedDocumentsByCategory,
          });
          break;

        case 'ALL_DOCUMENTS':
          const updatedDocuments = fetchAllDocumentsByGroupData.filter(
            documentData =>
              documentData._id !== deleteRequestData.vid_doc_standalone_Id
          );

          dispatch({
            type: FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS,
            payload: updatedDocuments,
          });
          break;

        case 'MANDATORY_GROUP_VIEW':
          const updatedGroupMandatoryReels = mandatoryReelsFetchData.filter(
            mandatory =>
              mandatory._id !== deleteRequestData.vid_doc_standalone_Id
          );

          dispatch({
            type: FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS,
            payload: updatedGroupMandatoryReels,
          });
          break;

        default:
          break;
      }
    }
  }, [deleteDocumentSuccess]);

  useEffect(() => {
    if (deleteVideoSuccess && deleteRequestData) {
      switch (viewType) {
        case 'REEL_CATEGORY_VIEW':
          const updatedVideosByCategory = updateCategoryViewData(
            allCategoriesFetchDataSuccess,
            'videos',
            deleteRequestData.vid_doc_standalone_Id
          );

          dispatch({
            type: FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
            payload: updatedVideosByCategory,
          });
          break;

        case 'ALL_VIDEOS':
          const updatedVideos = fetchAllVideosByGroupData.filter(
            video => video._id !== deleteRequestData.vid_doc_standalone_Id
          );

          dispatch({
            type: FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS,
            payload: updatedVideos,
          });
          break;

        case 'MANDATORY_GROUP_VIEW':
          const updatedGroupMandatoryReels = mandatoryReelsFetchData.filter(
            mandatory =>
              mandatory._id !== deleteRequestData.vid_doc_standalone_Id
          );

          dispatch({
            type: FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS,
            payload: updatedGroupMandatoryReels,
          });
          break;

        default:
          break;
      }
    }
  }, [deleteVideoSuccess]);

  useEffect(() => {
    if (deleteMidEndAssessmentSuccess && deleteRequestData) {
      const updatedMidEndAssessments = midEndAssementsByGroupFetchData.filter(
        assessment => assessment._id !== deleteRequestData.assessmentId
      );

      dispatch({
        type: FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA,
        payload: updatedMidEndAssessments,
      });
    }
  }, [deleteMidEndAssessmentSuccess]);

  useEffect(() => {
    if (deleteStandaloneAssessmentSuccess && deleteRequestData) {
      switch (viewType) {
        case 'REEL_CATEGORY_VIEW':
          const updatedAssessmentsByCategory = updateCategoryViewData(
            allCategoriesFetchDataSuccess,
            'assessments',
            deleteRequestData.vid_doc_standalone_Id
          );

          dispatch({
            type: FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
            payload: updatedAssessmentsByCategory,
          });
          break;

        case 'ALL_ASSESSMENTS':
          const updatedStandAloneAssessments =
            standaloneAssementsByGroupFetchData.filter(
              assessment =>
                assessment._id !== deleteRequestData.vid_doc_standalone_Id
            );

          dispatch({
            type: FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS,
            payload: updatedStandAloneAssessments,
          });
          break;

        case 'MANDATORY_GROUP_VIEW':
          const updatedGroupMandatoryReels = mandatoryReelsFetchData.filter(
            mandatory =>
              mandatory._id !== deleteRequestData.vid_doc_standalone_Id
          );

          dispatch({
            type: FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS,
            payload: updatedGroupMandatoryReels,
          });
          break;

        default:
          break;
      }
    }
  }, [deleteStandaloneAssessmentSuccess]);

  useEffect(() => {
    if (deleteImageSuccess && deleteRequestData) {
      switch (viewType) {
        case 'MANDATORY_GROUP_VIEW':
          const updatedGroupMandatoryReels = mandatoryReelsFetchData.filter(
            mandatory =>
              mandatory._id !== deleteRequestData.vid_doc_standalone_Id
          );

          dispatch({
            type: FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS,
            payload: updatedGroupMandatoryReels,
          });
          break;

        default:
          break;
      }
    }
  }, [deleteImageSuccess]);

  const updateCategoryViewData = (categoryData, itemType, updatedItemId) => {
    return (
      categoryData &&
      categoryData.map(categoryValue => {
        const tempCategory = { ...categoryValue };

        const tempUpdatedCategory = tempCategory['category'].map(itemValues => {
          const tempItemValues = { ...itemValues };

          const tempUpdatedItemValues = tempItemValues[itemType].filter(
            itemData => itemData._id !== updatedItemId
          );

          if (itemType === 'documents') {
            return { ...tempItemValues, documents: tempUpdatedItemValues };
          } else if (itemType === 'videos') {
            return { ...tempItemValues, videos: tempUpdatedItemValues };
          } else {
            return { ...tempItemValues, assessments: tempUpdatedItemValues };
          }
        });

        return { ...tempCategory, category: tempUpdatedCategory };
      })
    );
  };

  const submitForm = deleteRequestData => {
    let requestData = null;

    if (deleteRequestData.viewType) {
      setViewType(deleteRequestData.viewType);
      requestData = removePropertyFromObject(deleteRequestData, 'viewType');
    }

    setDeleteRequestData(requestData || deleteRequestData);
  };

  return [submitForm];
}

export default useDeleteApi;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import deleteIcon_white from '../../../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../../../assets/images/svg-images/icon-edit-white.svg';
import warning from '../../../../assets/images/svg-images/icon-warning.svg';
import publish from '../../../../assets/images/svg-images/icon-upload.svg';

import useCheckPermission from '../../../../Hooks/useCheckPermission';
import useDeleteApi from '../../../../Hooks/useDeleteApi';
import { PrivilegeTypes } from '../../../../Common/UserPrivileges';
import ViewPDFModal from '../../Group/Modal/viewPDFModal';
import ConfirmActionModal from '../../../../Components/Modal/ConfirmActionModal';
import Loader from '../../../../Components/Loader/loader';
import messageConfigs from '../../../../Helper/PopupMessageConfig';
import useChangePublishedStatusApi from '../../../../Hooks/useChangePublishedStatusApi';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import { ToolTipConfig } from '../../../../Common/ToolTipConfig';
import AdminDashboardSelectionDropDown from '../../../../Components/Admin/AdminDashboardSelectionDropDown';
import ActionDropdown from '../../../../Components/Admin/ActionDropdown';

const SUPER_ADMIN_ID = 4;

export default function AllDocuments({ history }) {
  const { t } = useTranslation();
  const canEditDocument = useCheckPermission(
    PrivilegeTypes.DOCUMENT.EDIT_DOCUMENT_ON_MASTER
  );
  const canDeleteDocument = useCheckPermission(
    PrivilegeTypes.DOCUMENT.DELETE_DOCUMENT_ON_MASTER
  );
  const canSuspendPublishDocument = useCheckPermission(
    PrivilegeTypes.DOCUMENT.DELETE_DOCUMENT_ON_MASTER
  );

  const [isGridView, setIsGridView] = useState(true);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [searchDocumentName, setSearchDocumentName] = useState('');
  const [organizedDocuments, setOrganizedDocuments] = useState([]);
  const [isPdfViewModalOpen, setIsPdfViewModalOpen] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(null);
  const { roleList } = useSelector(state => state?.auth?.userData);
  const {
    suspendDocumentLoading,
    fetchAllDocumentsByGroupData,
    fetchAllDocumentsByGroupLoading,
    deleteDocumentLoading,
  } = useSelector(state => state?.documentData);
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultDocument = themeInfo?.documentImageUrl;

  const [updateIsPublishedStatus] = useChangePublishedStatusApi();
  const [fetchData] = useFetchDataApi();
  const [deleteDocuments] = useDeleteApi();

  useEffect(() => {
    if (roleList && roleList.length > 0) {
      setIsSuperAdmin(
        [...roleList].map(({ id }) => id).includes(SUPER_ADMIN_ID)
      );
    }
  }, [roleList]);

  useEffect(() => {
    getAllDocuments();
  }, []);

  const getAllDocuments = () => fetchData({ type: 'DOCUMENTS' });

  useEffect(() => {
    if (fetchAllDocumentsByGroupData) {
      const organizedDocuments = fetchAllDocumentsByGroupData.map(
        documentData => {
          const {
            _id,
            title,
            groupIds,
            documentUrl,
            previewImgUrl,
            isNativeBannerAndFeatured,
            isBanner,
            isPublished,
            dateCreated,
            reelIds,
          } = documentData;

          return {
            _id,
            groupIds,
            isPublished,
            name: title,
            pdfUrl: documentUrl,
            imageUrl: previewImgUrl || defaultDocument,
            isNativeBannerAndFeatured,
            bannerOrFeatured: isBanner ? 'banner' : 'featured',
            dateCreated,
            reelIds,
          };
        }
      );

      setOrganizedDocuments(organizedDocuments);
    }
  }, [fetchAllDocumentsByGroupData]);

  useEffect(() => {
    if (!deleteDocumentLoading && isDeleteConfirmModalOpen) {
      setIsDeleteConfirmModalOpen(null);
    }
  }, [deleteDocumentLoading]);

  const hanldeIsPublishedStatus = documentId => {
    updateIsPublishedStatus({
      _id: documentId,
      type: 'DOCUMENT',
    });
  };

  const setDocumentListTableActions = (value, document) => {
    switch (value) {
      case 'Suspend':
        hanldeIsPublishedStatus(document._id);
        break;

      case 'Publish':
        hanldeIsPublishedStatus(document._id);
        break;

      case 'Edit':
        if (document.isNativeBannerAndFeatured) {
          history.push(
            `/edit-document/${document.bannerOrFeatured}/${document._id}`
          );
        } else {
          history.push(`/edit-document/${document._id}`);
        }
        break;

      case 'Delete':
        handleDelete(document._id);
        break;

      default:
        break;
    }
  };

  const handleDelete = id => {
    let deleteRequestData = {
      vid_doc_standalone_Id: id,
      type: 'DOCUMENT',
      viewType: 'ALL_DOCUMENTS',
    };

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const renderAllDocumentsView = () => {
    return organizedDocuments
      .filter(
        ({ name }) =>
          searchDocumentName === '' ||
          name?.toUpperCase().includes(searchDocumentName.toUpperCase())
      )
      .map((document, index) => {
        if (isGridView) {
          return renderGridTile(document, index);
        } else {
          return renderTableRow(document, index);
        }
      });
  };

  const renderGridTile = (gridObject, index) => {
    const {
      _id,
      name,
      groupIds,
      imageUrl,
      pdfUrl,
      isPublished,
      isNativeBannerAndFeatured,
      bannerOrFeatured,
    } = gridObject;

    const isHaveEditAccess = groupIds.some(id => {
      return canEditDocument[id];
    });

    const isHaveSuspendPublishAccess = groupIds.some(id => {
      return canSuspendPublishDocument[id];
    });

    const isHaveDeleteAccess = groupIds.some(id => {
      return canDeleteDocument[id];
    });

    return (
      <div className="reel-view--grid-video-wrapper" key={index}>
        <div
          className={`reel-view--grid-video ${
            !isPublished ? 'unpublished' : ''
          }`}
        >
          <div
            className="reel-view--grid-video-unpublished"
            onClick={() => handleViewPDF(name, pdfUrl)}
          ></div>
          <img
            src={imageUrl}
            alt="reel-thumbnail"
            className="reel-thumbnail"
            onClick={() => handleViewPDF(name, pdfUrl)}
            onError={event => {
              event.target.onerror = null;
              event.target.src = defaultDocument;
            }}
          />
          <div className="reel-view--grid-video-settings">
            {(isSuperAdmin || isHaveEditAccess) && (
              <img
                src={edit_white}
                alt="edit-icon"
                className="icon--edit-white"
                onClick={
                  isNativeBannerAndFeatured
                    ? () =>
                        history.push(
                          `/edit-document/${bannerOrFeatured}/${_id}`
                        )
                    : () => history.push(`/edit-document/${_id}`)
                }
              />
            )}
            {(isSuperAdmin || isHaveSuspendPublishAccess) && (
              <img
                src={isPublished ? warning : publish}
                alt={isPublished ? 'warning-icon' : 'publish-icon'}
                className={isPublished ? 'icon--warning' : 'icon--publish'}
                onClick={() => hanldeIsPublishedStatus(_id)}
              />
            )}
            {(isSuperAdmin || isHaveDeleteAccess) && (
              <img
                src={deleteIcon_white}
                alt="delete-icon"
                className="icon--delete-white"
                onClick={() => handleDelete(_id)}
              />
            )}
          </div>
        </div>
        <p>{name}</p>
      </div>
    );
  };

  const renderTableRow = (rowItem, index) => {
    const {
      _id,
      name,
      imageUrl,
      groupIds,
      isPublished,
      reelIds,
      dateCreated,
    } = rowItem;

    const isHaveEditAccess = groupIds.some(id => {
      return canEditDocument[id];
    });

    const isHaveSuspendPublishAccess = groupIds.some(id => {
      return canSuspendPublishDocument[id];
    });

    const isHaveDeleteAccess = groupIds.some(id => {
      return canDeleteDocument[id];
    });

    return (
      <div key={index} className="reel-view--table-row">
        <div className="reel-view--table-cell">
          <img
            src={imageUrl}
            className="reel-view--table-backgroundimg"
            onError={event => {
              event.target.onerror = null;
              event.target.src = defaultDocument;
            }}
          />
        </div>
        <div className="reel-view--table-cell">
          <p>{name}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>
            {dateCreated
              ? moment.utc(dateCreated).local().format('DD/MM/YYYY')
              : '-'}
          </p>
        </div>
        <div className="reel-view--table-cell">
          <p>{groupIds ? groupIds.length : '0'}</p>
        </div>
        <div className="reel-view--table-cell">
          <p>{reelIds?.length || '-'}</p>
        </div>
        <div className="reel-view--table-cell">
          {(isSuperAdmin ||
            isHaveSuspendPublishAccess ||
            isHaveDeleteAccess ||
            isHaveEditAccess) && (
            <ActionDropdown
              ishaveEdit={isSuperAdmin || isHaveEditAccess}
              ishaveSuspend={
                (isSuperAdmin || isHaveSuspendPublishAccess) && isPublished
              }
              ishavePublish={
                (isSuperAdmin || isHaveSuspendPublishAccess) && !isPublished
              }
              ishaveDelete={isSuperAdmin || isHaveDeleteAccess}
              handleOnchange={option =>
                setDocumentListTableActions(option.value, rowItem)
              }
            />
          )}
        </div>
      </div>
    );
  };

  const handleViewPDF = (title, pdfUrl) => {
    setIsPdfViewModalOpen(true);
    setPdfUrl(pdfUrl);
    setPdfTitle(title);
  };

  const isPageLoading =
    fetchAllDocumentsByGroupLoading || suspendDocumentLoading;

  return (
    <div
      className={`all-users-table allreels alldocuments loading-screen-parent ${
        isPageLoading ? 'setheight' : ''
      }`}
    >
      {isPageLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="all-users-table--wrapper">
            <div className="all-users-table--page-title">
              <div className="all-users-table--page-selection">
                <AdminDashboardSelectionDropDown
                  history={history}
                  selected={'DOCUMENTS'}
                />
              </div>
              <div className="all-users-table--page-settings">
                <input
                  className="form-input form-input--search"
                  type="search"
                  id="search"
                  name="search"
                  placeholder={t('Search')}
                  value={searchDocumentName}
                  onChange={event => setSearchDocumentName(event.target.value)}
                />
                {/* <Dropdown title="Filter By" options={[]} /> */}
                <button
                  className="btn btn--primary btn--createnew btn--long"
                  onClick={() => history.push('/create-document')}
                >
                  {t('Create Document')}
                </button>
                <div
                  className={`all-users-table--image grid ${
                    isGridView ? 'active' : ''
                  }`}
                  title={t(ToolTipConfig.general.gridView)}
                  onClick={() => setIsGridView(true)}
                />
                <div
                  className={`all-users-table--image list ${
                    !isGridView ? 'active' : ''
                  }`}
                  title={t(ToolTipConfig.general.tableView)}
                  onClick={() => setIsGridView(false)}
                />
              </div>
            </div>
            {isGridView ? (
              <div className="all-users-table--grid">
                <div className="all-users-table--grid-wrapper">
                  {renderAllDocumentsView()}
                </div>
              </div>
            ) : (
              <div className="all-users-table--table">
                <div className="all-users-table--table-header">
                  <div className="all-users-table--table-cell">
                    <h5>{t('Preview')}</h5>
                  </div>
                  <div className="all-users-table--table-cell">
                    <h5>{t('Title')}</h5>
                  </div>
                  <div className="all-users-table--table-cell">
                    <h5>{t('Date Created')}</h5>
                  </div>
                  <div className="all-users-table--table-cell">
                    <h5>{t('No. of Groups')}</h5>
                  </div>
                  <div className="all-users-table--table-cell">
                    <h5>{t('No. of Reels')}</h5>
                  </div>
                  <div className="all-users-table--table-cell">
                    <h5>{t('Actions')}</h5>
                  </div>
                </div>
                <div className="all-users-table--table-body">
                  {renderAllDocumentsView()}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isPdfViewModalOpen && (
        <ViewPDFModal
          pdfTitle={pdfTitle}
          pdfUrl={pdfUrl}
          handleClose={() => setIsPdfViewModalOpen(false)}
        />
      )}
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={messageConfigs.deleteConfirm.title}
          message={messageConfigs.deleteConfirm.document}
          handleSuccess={() => deleteDocuments(isDeleteConfirmModalOpen)}
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
          isLoading={deleteDocumentLoading}
        />
      )}
    </div>
  );
}

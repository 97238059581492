import React from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';

export default function ActionDropdown({
  handleOnchange,
  ishaveRename,
  ishaveSuspend,
  ishavePublish,
  ishaveDelete,
  ishaveDuplicate,
  ishaveEdit,
}) {
  const { t } = useTranslation();

  const renderOptions = () => {
    const optionsArray = [];

    if (ishaveRename) {
      optionsArray.push({
        label: t('Rename'),
        value: 'Rename',
      });
    }

    if (ishaveEdit) {
      optionsArray.push({
        label: t('Edit'),
        value: 'Edit',
      });
    }

    if (ishaveSuspend) {
      optionsArray.push({
        label: t('Suspend'),
        value: 'Suspend',
      });
    }

    if (ishavePublish) {
      optionsArray.push({
        label: t('Publish'),
        value: 'Publish',
      });
    }

    if (ishaveDelete) {
      optionsArray.push({
        label: t('Delete'),
        value: 'Delete',
      });
    }

    if (ishaveDuplicate) {
      optionsArray.push({
        label: t('Duplicate'),
        value: 'Duplicate',
      });
    }

    return optionsArray;
  };

  return (
    <Dropdown
      title="Edit"
      placeholder={t('Select')}
      options={renderOptions()}
      onChange={handleOnchange}
    />
  );
}

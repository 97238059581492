import React from 'react';
import { useTranslation } from 'react-i18next';

import image from '../../../assets/images/svg-images/icon-popup-image.svg';
import video from '../../../assets/images/svg-images/icon-popup-videocam.svg';
import documentImage from '../../../assets/images/svg-images/icon-popup-document.svg';
import assessment from '../../../assets/images/svg-images/icon-popup-assessment.svg';

export default function SelectBannerType({
  history,
  handleClose,
  isBanner,
  groupId,
}) {
  const type = isBanner ? 'banner' : 'featured';
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup selectBannerType">
      <div className="reel-view--popup-container">
        <h3>{t(isBanner ? 'Select Banner Type' : 'Select Feature Type')}</h3>
        <div className="reel-view--popup-header">
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <div
              className="reel-view--popup-field"
              onClick={() =>
                history.push(`/create-image/${type}/group/${groupId}`)
              }
            >
              <img
                src={image}
                alt="document-preview-img"
                className="admin__adddocument--document-preview-img"
              />
              <p>{t('Image')}</p>
            </div>
            <div
              className="reel-view--popup-field"
              onClick={() =>
                history.push(`/create-video/${type}/group/${groupId}`)
              }
            >
              <img
                src={video}
                alt="document-preview-img"
                className="admin__adddocument--document-preview-img"
              />
              <p>{t('Video')}</p>
            </div>
            <div
              className="reel-view--popup-field"
              onClick={() =>
                history.push(`/create-document/${type}/group/${groupId}`)
              }
            >
              <img
                src={documentImage}
                alt="document-preview-img"
                className="admin__adddocument--document-preview-img"
              />
              <p>{t('Document')}</p>
            </div>
            <div
              className="reel-view--popup-field"
              onClick={() =>
                history.push(
                  `/create-assessment/standalone/${type}/group/${groupId}`
                )
              }
            >
              <img
                src={assessment}
                alt="document-preview-img"
                className="admin__adddocument--document-preview-img"
              />
              <p>{t('Assessment')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEng from './locales/en/translation.json';
import translationZh from './locales/zh/translation.json';
import translationTa from './locales/ta/translation.json';
import translationAr from './locales/ar/translation.json';
import translationSi from './locales/si/translation.json';

//RMS translations
import rmsTranslationEng from './rms/locales/en/translation.json';
import rmsTranslationZh from './rms/locales/zh/translation.json';
import rmsTranslationTa from './rms/locales/ta/translation.json';
import rmsTranslationAr from './rms/locales/ar/translation.json';
import rmsTranslationSi from './rms/locales/si/translation.json';

const DETECTION_OPTIONS = {
  order: ['navigator'],
  caches: ['localStorage'],
};

/**
 * Vs Code
 *
 * \{t\('[1-9 a-z A-Z]*[-!$%^&*()_+|~={}\[\]:";'<>?,.\/]*'\)\}
 *
 * https://regex101.com/r/jFKi84/3
 */

// const missingKeys = JSON.parse(localStorage.getItem('missingKeys')) || {};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: { ...translationEng, ...rmsTranslationEng },
      },
      zh: {
        translations: { ...translationZh, ...rmsTranslationZh },
      },
      ta: {
        translations: { ...translationTa, ...rmsTranslationTa },
      },
      ar: {
        translations: { ...translationAr, ...rmsTranslationAr },
      },
      si: {
        translations: { ...translationSi, ...rmsTranslationSi },
        translations: translationEng,
      },
      zh: {
        translations: translationZh,
      },
      ta: {
        translations: translationTa,
      },
      ar: {
        translations: translationAr,
      },
      si: {
        translations: translationSi,
      },
    },
    load: 'languageOnly',
    saveMissingTo: 'current',
    fallbackLng: false,
    saveMissing: true, // send not translated keys to endpoint
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    nsSeparator: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    detection: DETECTION_OPTIONS,
    react: {
      wait: true,
    },
    // missingKeyHandler: (lng, ns, key, fallbackValue) => {
    //   missingKeys[key] = fallbackValue;
    //   // console.log(JSON.stringify(missingKeys));
    //   localStorage.setItem('missingKeys', JSON.stringify(missingKeys));
    // },
  });

export default i18n;

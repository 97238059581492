import React from 'react';

const VideoInfoModal = ({ title, authorName, description, handleClose }) => {
  return (
    <div className="reel-view--popup videoInfo">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{title}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{authorName}</label>
          </div>
          <div className="reel-view--popup-row">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoInfoModal;

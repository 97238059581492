import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

import {
  AUTH_REQUEST,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAIL,
  FETCH_RESET_PASSWORD_DATA_REQUEST,
  FETCH_RESET_PASSWORD_DATA_SUCCESS,
  FETCH_RESET_PASSWORD_DATA_FAIL,
  CLEAR_SESSION,
  AUTH_SUCCESS,
  SYNC_FEDERATE_LOGIN_DATA_REQUEST,
  SYNC_FEDERATE_LOGIN_DATA_SUCCESS,
  SYNC_FEDERATE_LOGIN_DATA_FAIL,
  SET_IS_FEDERATED_LOGIN,
  SET_GEO_LOCATION,
  FETCH_SSO_USER_VERIFY_REQUEST,
  FETCH_SSO_USER_VERIFY_SUCCESS,
  FETCH_SSO_USER_VERIFY_FAIL,
  FETCH_SSO_USER_VERIFY_RESET,
} from '../../redux/actionTypes/auth.js';
import {
  CHANGE_ADMIN_VIEW,
  ADD_ADMIN_USER,
} from '../../redux//action/actionTypes';
import useApi from '../../Hooks/useApi';
import ProceedMessageModal from '../../Components/Modal/ProceedMessageModal.js';
import ContactSupportModal from '../../Components/Modal/ContactSupportModal.js';
import messageConfigs from '../../Helper/PopupMessageConfig.js';
import DottedLoader from '../../Components/Loader/DottedLoader.js';
import useAuth from '../../Hooks/useAuth.js';
import { systemConfig } from '../../Common/SystemConstConfigs.js';
import { getCountryFromTimeZone } from '../../Helper/SystemManager.js';
import useFetchDataApi from '../../Hooks/useFetchDataApi';
import SsoVerifyModal from '../../Components/Modal/SsoVerifyModal.js';
import {
  UPDATE_LAST_ACTIVE_FAIL,
  UPDATE_LAST_ACTIVE_REQUEST,
  UPDATE_LAST_ACTIVE_SUCCESS,
} from '../../redux/actionTypes/createUser.js';

const {
  REACT_APP_IS_FEDETRATE_LOGIN_AVAILABLE,
  REACT_APP_CLIENT_ID,
  REACT_APP_IDENTITY_PROVIDER,
} = process.env;

const Login = ({ history, location }) => {
  const isLoginScreen = location.pathname !== '/reset/changePassword';
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginErrorModalOpen, setIsLoginErrorModalOpen] = useState(null);
  const [isContactSUpportModalOpen, setIsContactSUpportModalOpen] =
    useState(null);
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [isPasswordTokenExpired, setIsPasswordTokenExpired] = useState(null);
  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    token: '',
    userId: '',
    newPassword: '',
    confirmPassword: '',
    profileImage: '',
    userName: '',
    userEmail: '',
  });
  const [isSsoVerifyModalOpen, setIsSsoVerifyModalOpen] = useState(false);
  const dispatch = useDispatch();

  const signIn = useAuth();
  const [userDataRequest] = useApi();
  const [getResetPasswordUserData] = useApi();
  const [resetPasswordRequest] = useApi('/');
  const [syncFederatedLoginData] = useApi();
  const [updateLoginTime] = useApi();
  const [getS3bucketData] = useFetchDataApi();
  const [verifySsoUser] = useApi();
  const {
    access_token,
    userData,
    resetPasswordUserData,
    auth_error,
    authUserDataLoading,
    resetPasswordUserDataRequestLoading,
    resetPasswordUserDataError,
    themeInfo,
    federateLoginData,
    isFederateLogin,
    federatedLoginError,
    authUserDataError,
    ssoUserVerifyLoading,
    ssoUserVerified,
    ssoUserVerifyError,
  } = useSelector(state => state?.auth);

  const logo = themeInfo?.headerLogoUrl;
  const welcomeMsg = themeInfo?.welcomeText;
  const defaultProfileImageUrl = themeInfo?.defaultProfileImageUrl;
  const supportEmail = themeInfo?.supportEmail;
  let loginBackground = themeInfo?.loginBackgroundUrl;

  const {
    token,
    userId,
    newPassword,
    confirmPassword,
    profileImage,
    name,
    userEmail,
  } = resetPasswordInfo;

  // Changing site language according to backend language code
  useEffect(() => {
    if (themeInfo?.languageCode) {
      i18n.changeLanguage(themeInfo?.languageCode);
    }
  }, [themeInfo]);

  useEffect(() => {
    position();
    dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
  }, []);

  const sendLastLoginTime = () => {
    updateLoginTime(
      '/user/updateUser',
      UPDATE_LAST_ACTIVE_REQUEST,
      UPDATE_LAST_ACTIVE_SUCCESS,
      UPDATE_LAST_ACTIVE_FAIL,
      null,
      null,
      'PUT',
      '',
      'isUserService'
    );
  };

  const position = async () => {
    const locationZoneData = getCountryFromTimeZone();
    await navigator.geolocation.getCurrentPosition(
      position => {
        dispatch({
          type: SET_GEO_LOCATION,
          payload: {
            ...locationZoneData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        });
      },
      err => {
        dispatch({
          type: SET_GEO_LOCATION,
          payload: {
            ...locationZoneData,
            latitude: null,
            longitude: null,
          },
        });
      }
    );
  };

  useEffect(() => {
    if (resetPasswordUserData) {
      const { email, profilePicUrl, username } = resetPasswordUserData;

      setResetPasswordInfo({
        ...resetPasswordInfo,
        profileImage: profilePicUrl || defaultProfileImageUrl,
        name: username,
        userEmail: email,
      });
    }
  }, [resetPasswordUserData]);

  useEffect(() => {
    if (resetPasswordUserDataError) {
      setIsPasswordTokenExpired(
        messageConfigs.error.USER.RESET_PASSWORD_TOKEN_EXPRIRED
      );
    }
  }, [resetPasswordUserDataError]);

  useEffect(() => {
    if (!isLoginScreen) {
      const { token, userId } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        parseNumbers: true,
      });
      setResetPasswordInfo({ ...resetPasswordInfo, token, userId });
      getResetPasswordUserData(
        `/reset/getUserDataForResetPassword?userId=${userId}`,
        FETCH_RESET_PASSWORD_DATA_REQUEST,
        FETCH_RESET_PASSWORD_DATA_SUCCESS,
        FETCH_RESET_PASSWORD_DATA_FAIL,
        null,
        null,
        'GET',
        '',
        'isUserService'
      );
    }
  }, [isLoginScreen]);

  const fetchUserData = uuidUserName => {
    userDataRequest(
      `/user/getUserData?username=${uuidUserName}`,
      FETCH_USER_DATA_REQUEST,
      FETCH_USER_DATA_SUCCESS,
      FETCH_USER_DATA_FAIL,
      null,
      null,
      'GET',
      '',
      'isUserService'
    );
  };

  useEffect(() => {
    if (
      !isFederateLogin &&
      userData?.userName &&
      access_token &&
      isLoginScreen
    ) {
      sendLastLoginTime();
      fetchUserData(userData?.userName);
      getS3bucketData({ type: 'GET_S3_BUCKET_NAME' });
    }
  }, [access_token, userData]);

  useEffect(() => {
    if (authUserDataError && isLoginClicked) {
      setIsContactSUpportModalOpen(authUserDataError?.message);

      setIsLoginClicked(false);
    }
  }, [authUserDataError]);

  useEffect(() => {
    if (isFederateLogin && access_token && !federateLoginData) {
      syncFederatedLoginData(
        `/user/syncFederatedUserData`,
        SYNC_FEDERATE_LOGIN_DATA_REQUEST,
        SYNC_FEDERATE_LOGIN_DATA_SUCCESS,
        SYNC_FEDERATE_LOGIN_DATA_FAIL,
        null,
        '',
        'PUT',
        '',
        'isUserService'
      );
    }
  }, [access_token]);

  useEffect(() => {
    if (isFederateLogin && access_token && federateLoginData?.username) {
      sendLastLoginTime();
      fetchUserData(federateLoginData?.username);
    }
  }, [access_token, federateLoginData]);

  useEffect(() => {
    if (userData) {
      const { userName, groupInfo, roleList } = userData;

      if (groupInfo?.length && access_token && isLoginScreen) {
        if (
          roleList &&
          roleList.length &&
          roleList.some(({ id }) => id === 3)
        ) {
          if (roleList.some(({ id }) => id === 1 || id === 2 || id === 5)) {
            dispatch({ type: ADD_ADMIN_USER, payload: true });
          }
          dispatch({ type: CHANGE_ADMIN_VIEW, payload: false });
          history.push('/home');
        } else {
          dispatch({ type: CHANGE_ADMIN_VIEW, payload: true });
          dispatch({ type: ADD_ADMIN_USER, payload: true });
          history.push('/node-tree');
        }
      } else if (
        access_token &&
        (!groupInfo || (groupInfo && groupInfo.length === 0)) &&
        roleList &&
        !roleList.length &&
        !authUserDataLoading
      ) {
        setIsLoginClicked(false);
        setIsContactSUpportModalOpen(
          messageConfigs.error.USER.GROUPS_NOT_ASSIGNED
        );
      }
    }
  }, [userData, access_token, authUserDataLoading]);

  useEffect(() => {
    if (auth_error) {
      setIsLoginClicked(false);

      // Suspended user message
      if (
        auth_error.code === systemConfig.errorcode.NotAuthorizedException &&
        auth_error.message === messageConfigs.error.USER.DISABLED
      ) {
        setIsContactSUpportModalOpen(messageConfigs.error.USER.SUSPENDED_USER);
        // Incorrect login creditials message
      } else if (
        auth_error.code === systemConfig.errorcode.NotAuthorizedException &&
        auth_error.message ===
          messageConfigs.error.USER.INCORRECT_USERNAME_PASSWORD
      ) {
        setIsLoginErrorModalOpen({
          isSuccess: false,
          message: messageConfigs.error.USER.INCORRECT_USERNAME_PASSWORD,
        });
        // Other login failures
      } else {
        setIsLoginErrorModalOpen({
          isSuccess: false,
          message: messageConfigs.error.TRY_LATER,
        });
      }

      dispatch({ type: AUTH_REQUEST });
    }
  }, [auth_error]);

  const onLogin = () => {
    if (userName && password) {
      setIsLoginClicked(true);
      signIn(userName, password);
    }
  };
  const handleResetPasswordInfo = (value, field) => {
    setIsPasswordTokenExpired(null);

    const tempResetPasswordInfo = { ...resetPasswordInfo };
    tempResetPasswordInfo[field] = value;
    setResetPasswordInfo(tempResetPasswordInfo);
  };

  const handleChangePassword = () => {
    if (
      confirmPassword &&
      newPassword &&
      confirmPassword === newPassword &&
      newPassword.length >= 8
    ) {
      resetPasswordRequest(
        `/reset/savePassword?token=${token}&newPassword=${newPassword}`,
        FETCH_RESET_PASSWORD_DATA_REQUEST,
        FETCH_RESET_PASSWORD_DATA_SUCCESS,
        FETCH_RESET_PASSWORD_DATA_FAIL,
        null,
        history,
        'POST',
        '',
        'isUserService'
      );
    }
  };

  useEffect(() => {
    if (!access_token) {
      getUser();
    }
  }, [access_token]);

  async function getUser() {
    await Auth.currentAuthenticatedUser()
      .then(userData => {
        Auth.currentCredentials().then(credential => {
          const { accessKeyId, secretAccessKey, sessionToken } = credential;
          dispatch({
            type: AUTH_SUCCESS,
            payload: {
              accessKeyId,
              secretAccessKey,
              sessionToken,
              userName: userData.getUsername(),
              preferredUsername: userData?.attributes?.preferred_username,
              refreshToken: userData
                .getSignInUserSession()
                .getRefreshToken()
                .getToken(),
              accessToken: userData
                .getSignInUserSession()
                .getAccessToken()
                .getJwtToken(),
            },
          });
        });
      })
      .catch();
  }

  const onClickSsoUserContinue = () => {
    dispatch({
      type: SET_IS_FEDERATED_LOGIN,
    });

    Auth.federatedSignIn({
      provider: REACT_APP_IDENTITY_PROVIDER,
    });
    setIsSsoVerifyModalOpen(false);
    dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
  };

  const onClickVerifySsoUser = email => {
    verifySsoUser(
      `/user/${email}/federatedLogin`,
      FETCH_SSO_USER_VERIFY_REQUEST,
      FETCH_SSO_USER_VERIFY_SUCCESS,
      FETCH_SSO_USER_VERIFY_FAIL,
      null,
      null,
      'GET',
      '',
      'isUserService'
    );
  };

  const renderResetPasswordModal = () => {
    return (
      <div className="loginscreen__resetpassword">
        <div className="loginscreen__resetpassword--page-title">
          <p>{t('Reset Password')}</p>
        </div>
        {resetPasswordUserDataRequestLoading ? (
          <DottedLoader />
        ) : (
          <div className="loginscreen__resetpassword--page-body">
            <div className="loginscreen__resetpassword--page-row">
              <div className="loginscreen__resetpassword--page-column">
                <img
                  src={profileImage || defaultProfileImageUrl}
                  alt="userimage"
                  className="loginscreen__resetpassword--page-userimage"
                  onError={event => {
                    event.target.onerror = null;
                    event.target.src = defaultProfileImageUrl;
                  }}
                />
              </div>
              <div className="loginscreen__resetpassword--page-column">
                <h4>{name}</h4>
                <p>{userEmail}</p>
              </div>
            </div>
            <div className="loginscreen__resetpassword--page-row password-field">
              {confirmPassword && confirmPassword !== newPassword && (
                <h3>
                  <span>{t('Warning')}:</span>
                  {messageConfigs.error.USER.PASSWORD_NOT_MATCH}
                </h3>
              )}
              {isPasswordTokenExpired && (
                <h3>
                  <span>{t('Warning')}:</span> {isPasswordTokenExpired}
                </h3>
              )}
              {newPassword && newPassword.length < 8 && (
                <h3>
                  <span>{t('Warning')}:</span>
                  {messageConfigs.error.USER.INVALID_PASSWORD}
                </h3>
              )}
              <label>{t('Password')}</label>
              <input
                id="resetpassword"
                type="password"
                name=""
                value={newPassword}
                onChange={e =>
                  handleResetPasswordInfo(e.target.value, 'newPassword')
                }
                autoComplete={false}
              />
              <button
                className="top-button"
                onClick={() => {
                  const x = document.getElementById('resetpassword');
                  if (x.type === 'password') {
                    x.type = 'text';
                  } else {
                    x.type = 'password';
                  }
                }}
              >
                {t('show/hide password')}
              </button>
            </div>
            <div className="loginscreen__resetpassword--page-row password-field">
              <label>{t('Confirm Password')}</label>
              <input
                id="confirmpassword"
                type="password"
                name=""
                value={confirmPassword}
                onChange={e =>
                  handleResetPasswordInfo(e.target.value, 'confirmPassword')
                }
              />
              <button
                onClick={() => {
                  const x = document.getElementById('confirmpassword');
                  if (x.type === 'password') {
                    x.type = 'text';
                  } else {
                    x.type = 'password';
                  }
                }}
              >
                {t(' show/hide password')}
              </button>
            </div>
            <div className="loginscreen__resetpassword--page-row">
              <button
                className="btn btn--primary"
                onClick={handleChangePassword}
              >
                {t('Reset Password')}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const Tabs = () => {
    const [ToggleState, setToggleState] = useState(1);

    const toggleTab = index => {
      setToggleState(index);
    };

    const getActiveClass = (index, className) =>
      ToggleState === index ? className : '';

    return (
      <div className="container">
        <ul className="tab-list">
          <li
            className={`tabs ${getActiveClass(1, 'active-tabs')}`}
            onClick={() => toggleTab(1)}
          >
            About Us
          </li>
          <li
            className={`tabs ${getActiveClass(2, 'active-tabs')}`}
            onClick={() => toggleTab(2)}
          >
            Business Membership
          </li>
          <li
            className={`tabs ${getActiveClass(3, 'active-tabs')}`}
            onClick={() => toggleTab(3)}
          >
            University Partnerships Programme
          </li>
          <li
            className={`tabs ${getActiveClass(4, 'active-tabs')}`}
            onClick={() => toggleTab(4)}
          >
            Sports Consultancy
          </li>
          <li
            className={`tabs ${getActiveClass(5, 'active-tabs')}`}
            onClick={() => toggleTab(5)}
          >
            Contact Us
          </li>
          {/* <li
            className={`tabs ${getActiveClass(6, 'active-tabs')}`}
            onClick={() => toggleTab(6)}
          >
            Scouts & Agents
          </li>
          <li
            className={`tabs ${getActiveClass(7, 'active-tabs')}`}
            onClick={() => toggleTab(7)}
          >
            Contact Us
          </li> */}
        </ul>
        <div className="content-container">
          <div className={`content ${getActiveClass(1, 'active-content')}`}>
            <p>Third<span>EYE</span> are an international student-led business who, since 2018, have invested financially into 24 different sporting disciplines in 8 countries, across 3 continents by investing membership fees.</p>
            <p>As a social enterprise we invest both time and resource into innovative and impactful services that benefit global sporting communities, whilst at the same time elevating our members standing within the world of sport, sports business, and sports education.</p>
            <p>Dedicated to delivering immense value for our members, grassroots, semi-professional and professional athletes and coaches, sports clubs, sports executives, sports universities, sports sector businesses, and sports scouts and agents, we offer a package of member benefits that deliver a high-level return on investment.</p>
            <p>To learn more about the benefits of becoming a Third<span>EYE</span> member, please book a “Discovery Call” using the button below, or for more information please visit the relevant pages of our website.</p>
            <p>Social Responsibility:</p>
            <p>Regulated by the UK Government to reinvest 100% of our profits back into the global sports sector, we believe strongly in the transformative power of sport to uplift local communities, with our mission being to sustain and grow the global grassroots sports sector through strategic financial investments, social mobility initiatives, giving back, and a high-level return on investment.</p>
            
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(2, 'active-content')}`}>
            <p>Join us and become an integral part of a dynamic network of sport sector leaders.</p>
            <p>Third<span>EYE</span> business members benefit from our innovative growth strategies, cutting-edge marketing technology integration, and mutually beneficial partnerships, leveraging our expertise to boost their customer and employee satisfaction, whilst offering support to convert pipeline prospects into long-term loyal clients.</p>
            <p>Pitch<span>In</span> Business Membership offers a host of business benefits that not only boost your business operations but also enhances your all important “giving back” strategies.</p>
            <p>As a business member you are licensed to use the prestigious “Pitch<span>In</span>” logo, signaling trust and credibility to your clients, partners and sales pipeline, as well as a place on our new online interactive marketing platform (launching January 2025) to promote your brand, increase sales, and expand your national and international market reach.</p>
            <p>Additionally, members can leverage fresh new marketing and PR opportunities through their own “Pitch<span>In</span>” member podcast and networking events, ensuring that they gain maximum exposure from their investment.</p>
            <p>By becoming a member, businesses not only grow and innovate, but they contribute positively to their local community.</p>
            
            <div className="loginscreen__membership-wrapper">
              <a href='https://www.canva.com/design/DAF45rBFOxs/c58mJtfLFL_iPZRX5X4pzA/view?utm_content=DAF45rBFOxs&utm_campaign=designshare&utm_medium=link&utm_source=editor' className='discoveryButton'>Download the eBrochure</a>
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='buyButton'>Book a Discovery Call</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(3, 'active-content')}`}>
            <p>Stand out in student recruitment with a unique selling proposition offered through our University Partnerships programme.</p>
            <p>Partnering with Third<span>EYE</span> enhances sports student CVs and creates clear progression pathways upon graduation.</p>
            <p>By adopting our “Complimenting the Curriculum” programme your students gain hands-on experience of running a sports-based business, access to national and international work placement opportunities, and an extensive network of contacts from within the global sports sector, resulting in them becoming more competitive when entering the jobs market.</p>
            <p>Equip your students with the skills and opportunities they need to excel in their sports careers by booking a call today.</p>
            
            <div className="loginscreen__membership-wrapper">
              <a href='https://www.canva.com/design/DAGH5iSH6NQ/Rk6ZgxUNbv2UGWT6unQyGg/view?utm_content=DAGH5iSH6NQ&utm_campaign=designshare&utm_medium=link&utm_source=editor' className='discoveryButton'>Download the eBrochure</a>
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='buyButton'>Book a Discovery Call</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(4, 'active-content')}`}>
            <p>Maximise your potential with ThirdEYE’s comprehensive coaching and mentoring services.</p>
            <p>Third<span>EYE</span> International Sports Consultants, former and current professionals from the world of sport, offer tailored support to athletes, coaches, sports academies, sports teams, sports club owners, parents, scouts and agents, helping them to achieve peak performance whilst acquiring a competitive edge.</p>
            <p>Our expert team of consultants draw upon lived experience and provide personalised strategies to ensure that you and/or your children have the tools needed for continuous improvement and success within the world of sport.</p>
            <p>Available in over 10 different languages our consultancy services are truly international.</p>
            
            <div className="loginscreen__membership-wrapper">
              <a href='https://www.canva.com/design/DAFeSZRxfcE/PRynqa65GHEDkMTDt2PQdQ/view?utm_content=DAFeSZRxfcE&utm_campaign=designshare&utm_medium=link&utm_source=editor' className='discoveryButton'>Download the eBrochure</a>
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='buyButton'>Book a Discovery Call</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(5, 'active-content')}`}>
            <p>Please contact us, we’d love to hear from you!</p>
            <p>Whether you have questions, feedback, or need assistance, our team is here to help.</p>
            <p>You can email us, or book time in our diaries, by clicking the “Discovery Call” button below.</p>
            <p>Email: <span><a href = "mailto:CtC.ThirdEYE@KoiSports.co.uk">CtC.ThirdEYE@KoiSports.co.uk</a></span></p>
            <p>Your thoughts are important to us, and we strive to provide you with the best possible experience.</p>
            <p>We appreciate your interest and look forward to connecting with you.</p>
            <p>Thank you for reaching out!</p>
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
            </div>
          </div>
          {/* <div className={`content ${getActiveClass(6, 'active-content')}`}>
            <p>Step into a realm of endless opportunities as sports scouts and agents become an integral part of our online and interactive sports ecosystem. With access to our vast database of talented athletes, your ability to discover and represent promising prospects becomes unparalleled.</p>
            <p>Our state-of-the-art interactive online member platform and expert coaching and mentoring services enable you to provide personalised guidance and support to your clients, enhancing their potential for success.</p>
            <p>Collaborate with other professionals within our thriving community, expanding your network and creating mutually beneficial partnerships.</p>
            <p>Our ecosystem's cutting-edge sports technology empowers you to make informed decisions and negotiate advantageous contracts.</p>
            <p>By joining our sports ecosystem, you gain a competitive advantage, enabling you to excel in talent acquisition and representation, shaping the careers of athletes destined for greatness in the world of sports.</p>
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
              <a href='https://buy.stripe.com/5kA2aa2SObFu13icMS' className='buyButton'>Become A Member</a>
            </div>
          </div> */}
          {/* <div className={`content ${getActiveClass(7, 'active-content')}`}>
            <p>
              We strongly believe in a "get to know our members first" approach to how we conduct our business, and to this end we operate a Monday to Saturday video-call enquiry service that allows you to "get to know us", and for us to find out how we can best serve you, before you become a ThirdEYE Member.
            </p>
            <p>
              Book your <span><a href="https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call?month=2023-07">"DiscoveryCall"</a></span> here to arrange a date and time that
              best suits you for a no obligation chat.
            </p>
            <p>
              In the unlikely event that our "DiscoveryCall" facility is
              unavailable, please email <span><a href = "mailto:thirdeye@koisports.co.uk">ThirdEYE@koisports.co.uk</a></span>{' '}
              for assistance.
            </p>
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  const renderLoginModal = () => {
    const renderLoginLogo = () => {
      switch (process.env.REACT_APP_LOGIN_PAGE) {
        case 'TYPE-1':
          return (
            <div className="loginscreen__logo">
              <img
                src={
                  'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/eye_logo-new2.png'
                }
                alt="logo-eye"
                className="eye--logo"
              />
              <div className='logo-title'>
                <h2>The Home of Sports Excellence</h2>
              </div>
            </div>
          );

        default:
          return (
            <div className="loginscreen__logo">
               <img
                src={
                  'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/eye_logo-new2.png'
                }
                alt="logo-eye"
                className="eye--logo"
              />
              <div className='logo-title'>
                <h1>The Home of Sports Excellence</h1>
              </div>
            </div>
          );
      }
    };

    return (
      <>
        {renderLoginLogo()}
        <div className="loginscreen__container-wrapper">
          <div className="loginscreen__section-one">
            <div className="loginscreen__section-one--left">
              <h3>
              Register with ThirdEYE today and receive a £200 international discount on the purchase of a Veo Sports Cam3 for you or your chosen grassroots sports club.
              </h3>
              <p>Welcome to the Third<span>EYE</span> International Sports Consultancy website!</p>
              <p>Whether you play, coach, manage or run a sports club, serve within a national governing body, scout or represent professional athletes, work within sports education, own a sports sector business, or are simply a business that loves sport, the benefits of joining Third<span>EYE</span> are endless.</p>
              <p>Membership includes the following and much more:</p>
              <ul>
                <li>Engage with our professional sports consultants</li>
                <li>Access to our off the shelf and/or bespoke virtual immersive events platform</li>
                <li>Discover “win win” partnership opportunities</li>
                <li>Promote your organisation within our online trading community (launching 2025)</li>
                <li>Benefit from innovative business marketing programmes</li>
                <li>Explore our “giving-back” initiatives</li>
              </ul>
              <p>Why not begin your Third<span>EYE</span> journey by registering for free to meet our sports consultants, and in return we’ll provide you, or chosen grassroots sports club, with a £200 discount on a brand new Veo Sports Cam 3, direct from the supplier.</p>
              <div className="loginscreen__section-one--buttons">
                <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
              </div>
              <div className="loginscreen__bradning">
                <img
                  src={
                    'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/koach_power.png'
                  }
                  alt="logo-koach"
                  className="koach--logo"
                />
                <p>Version 2.0</p>
              </div>
            </div>
            <div className="loginscreen__section-one--right loginscreen__form form">
              <div className="form__form--fields">
                <div className="form--field">
                  <label>{t('Username or Email Address')}</label>
                  <input
                    className="form-input"
                    value={userName}
                    onChange={e => setUserName(e.target.value.trim())}
                  />
                </div>
                <div className="form--field password-field">
                  <label>{t('Password')}</label>
                  <input
                    id="password"
                    className="form-input"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <button
                    onClick={() => {
                      const x = document.getElementById('password');
                      if (x.type === 'password') {
                        x.type = 'text';
                      } else {
                        x.type = 'password';
                      }
                    }}
                  >
                    {t('show/hide password')}
                  </button>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push('/forgotPassword')}
                  >
                    Forgot your password?
                  </a>
                </div>
                {/* <div className="form--field last">
                <input
                  className="form-input--checkbox"
                  type="checkbox"
                  id="remember-me"
                  name="remember-me"
                  value="Bike"
                />
                <label for="remember-me">Remember Me</label>
              </div> */}
                <div className="form--buttons">
                  <div className='social--wrapper'>
                    <a href='https://www.instagram.com/thirdeyesports/'>
                      <img
                        src={
                          'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/instagram.png'
                        }
                        alt="instagram icon"
                        className="igIcon"
                      />
                    </a>
                    <a href='https://twitter.com/ThirdEYESport'>
                      <img
                        src={
                          'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/twitter.png'
                        }
                        alt="twitter icon"
                        className="twitterIcon"
                      />
                    </a>
                  </div>
                  <div>
                    <button
                      className={`
                      btn btn--primary
                      ${isLoginClicked ? 'btn--loader' : ''}
                    `}
                      onClick={onLogin}
                    >
                      {t('Login')}
                    </button>
                    <button
                      className="btn btn--primary"
                      onClick={() => history.push('/signup')}
                    >
                      {t('Sign Up')}
                    </button>
                  </div>
                </div>
                {REACT_APP_IS_FEDETRATE_LOGIN_AVAILABLE && (
                  <>
                    <div className="form--field text">Or</div>

                    <button
                      className="btn btn--primary form--field federate-sign-button"
                      onClick={() => setIsSsoVerifyModalOpen(true)}
                    >
                      Sign in with your {REACT_APP_CLIENT_ID} account
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="loginscreen"
        style={{
          backgroundImage: `url(${loginBackground})`,
        }}
      >
        <div className="container">
          {/* Add class "password" to wrapper on reset password */}
          <div className="loginscreen__wrapper">
            {isLoginScreen ? renderLoginModal() : renderResetPasswordModal()}
          </div>
        </div>
        {isLoginErrorModalOpen && (
          <ProceedMessageModal
            isSuccess={isLoginErrorModalOpen.isSuccess}
            message={isLoginErrorModalOpen.message}
            handleClose={() => setIsLoginErrorModalOpen(null)}
            handleSuccess={() => setIsLoginErrorModalOpen(null)}
            handleError={() => setIsLoginErrorModalOpen(null)}
          />
        )}
        {isContactSUpportModalOpen && (
          <ContactSupportModal
            message={isContactSUpportModalOpen}
            supportEmail={supportEmail}
            handleClose={() => {
              setIsContactSUpportModalOpen(null);
              Auth.signOut();
              dispatch({
                type: CLEAR_SESSION,
              });
            }}
          />
        )}
        {federatedLoginError && (
          <ContactSupportModal
            message={messageConfigs.error.USER.FEDERATE_LOGIN_FAIL}
            supportEmail={supportEmail}
            handleClose={() => {
              Auth.signOut();
              setIsContactSUpportModalOpen(null);
              dispatch({
                type: CLEAR_SESSION,
              });
            }}
          />
        )}
        {isSsoVerifyModalOpen && (
          <SsoVerifyModal
            handleClose={() => {
              setIsSsoVerifyModalOpen(false);
              dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
            }}
            onClickVerifySsoUser={email => onClickVerifySsoUser(email)}
            onClickContinueSsoUser={onClickSsoUserContinue}
          />
        )}
      </div>
      <div className="loginscreen__section-two">
        <Tabs />
      </div>
    </>
  );
};

export default Login;

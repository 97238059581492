import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { Provider } from "react-redux"; // Import React Redux
// import { store, persistor } from "./store/store"; // Import Redux Store
// import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();

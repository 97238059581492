import React, { useState, useEffect, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MiniPlayer from './Player/MiniPlayer';
import VideoPreview from '../VideoPreview';
import WatchReminderModal from '../../Components/Modal/WatchReminderModal';
import StatisticsModal from './Modal/StatisticsModal';
import {
  ADD_PLAYING_INFO,
} from '../../redux/action/actionTypes';
import FeedBackModal from '../TrainingRequest/FeedBackModal';


import leftArrow from '../../assets/images/svg-images/reel-left-arrow.svg';
import leftArrow_light from '../../assets/images/svg-images/reel-left-arrow-light.svg';
import rightArrow from '../../assets/images/svg-images/reel-right-arrow.svg';
import rightArrow_light from '../../assets/images/svg-images/reel-right-arrow-light.svg';

import deleteIcon_white from '../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../assets/images/svg-images/icon-edit-white.svg';
import upload_video from '../../assets/images/svg-images/icon-videoupload.svg';
import stats from '../../assets/images/svg-images/icon-stats.svg';
import warning from '../../assets/images/svg-images/icon-warning.svg';
import publish from '../../assets/images/svg-images/icon-upload.svg';

import useApi from '../../Hooks/useApi';

import {
  REMIND_LATER_REQUEST,
  REMIND_LATER_SUCCESS,
  REMIND_LATER_FAIL,
} from '../../redux/actionTypes/assesment';
import { getBannerFeaturedEditRoute } from '../../Helper/SystemManager';
import { ToolTipConfig } from '../../Common/ToolTipConfig';

const Videoslider = ({
  handleDelete,
  hanldeIsPublishedStatus,
  videos = [],
  itemKey,
  title,
  groupId,
  reelId,
  isMandatoryReel,
  deleteReelPermission,
  deleteVideoPermission,
  editVideoPermission,
  suspenPublishVideoPermission,
}) => {
  const { t } = useTranslation();
  const [featuredVideoData, setFeaturedVideoData] = useState({
    isMouseOver: '',
    zoomEffect: 'zoomEffect',
    zoomEffectRow: 'zoomEffectRow',
    videoInfo: '--close',
    videoInfoData: '',
    currentSlide: 0,
    videoInfoAnimationClass: '',
    videoInfoActiveClass: '',
  });
  const [isMiniVideoHover, setIsMiniVideoHover] = useState(false);
  const [isVideoInfoOpen, setIsVideoInfoOpen] = useState(null);
  const [isStaticsModalOpen, setIsStaticModalOpen] = useState(false);
  const [watchLaterVideoId, setWatchLaterVideoId] = useState(null);
  const [doAssessmentLaterId, setDoAssessmentLaterId] = useState(null);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [feedBackModalObj, setFeedBackModalObj] = useState(false);
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;
  const defaultDocument = themeInfo?.documentImageUrl;
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;

  const dispatch = useDispatch();
  const history = useHistory();

  const [sendRemindLaterRequest] = useApi();
  const [sendFavoriteRequest] = useApi();

  const sendRemindLaterDetails = (
    timestamp = '',
    id = null,
    isAssessment = false
  ) => {
    let api = '';
    let requestObj = {};
    if (watchLaterVideoId || doAssessmentLaterId) {
      if (watchLaterVideoId) {
        api = '/video/update-watch-later';
        requestObj = {
          videoId: id || watchLaterVideoId,
          timestamp: timestamp
            ? moment(timestamp).format('YYYY-MM-DDTHH:mm:ss:SSZ')
            : '',
        };
      } else {
        api = '/assessment/update-do-assessment-later';
        requestObj = {
          assessmentId: doAssessmentLaterId,
          timestamp: timestamp
            ? moment(timestamp).format('YYYY-MM-DDTHH:mm:ss:SSZ')
            : '',
        };
      }
    } else if (isAssessment) {
      api = `/assessment/remove-do-assessment-later?assessmentId=${id}`;
    } else {
      api = `/video/remove-watch-later?videoId=${id}`;
    }
    sendRemindLaterRequest(
      api,
      REMIND_LATER_REQUEST,
      REMIND_LATER_SUCCESS,
      REMIND_LATER_FAIL,
      {
        ...requestObj,
      },
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  const sendFavorite = (id, voteType, isAssessment = false) => {
    sendFavoriteRequest(
      isAssessment
        ? '/assessment/update-assessment-vote'
        : '/video/update-video-vote',
      REMIND_LATER_REQUEST,
      REMIND_LATER_SUCCESS,
      REMIND_LATER_FAIL,
      {
        _id: id,
        voteType,
      },
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  const openVideoInfoBox = videoInfoData => {
    if (
      !isVideoInfoOpen ||
      (isVideoInfoOpen && isVideoInfoOpen !== videoInfoData.videoId)
    ) {
      dispatch({
        type: ADD_PLAYING_INFO,
        payload: [
          {
            id: videoInfoData.videoId,
            title: videoInfoData.title,
            preview: true,
          },
        ],
      });

      setFeaturedVideoData({
        ...featuredVideoData,
        videoInfoData,
        // zoomEffect: "",
        // zoomEffectRow: "",
        // videoInfo: "--open",
        // videoInfo: "--close",
        // videoInfoActiveClass: "",
        videoInfoAnimationClass: 'animation-on',
      });
      setIsVideoInfoOpen(videoInfoData.videoId || videoInfoData._id);
    } else {
      dispatch({
        type: ADD_PLAYING_INFO,
        payload: [],
      });
      setFeaturedVideoData({
        ...featuredVideoData,
        videoInfoData: '',
        videoInfoAnimationClass: '',
        videoInfoActiveClass: 'active',
      });
      setIsVideoInfoOpen(null);
    }
  };
  // close video info box
  const closeVideoInfoBox = () => {
    setTimeout(() => {
      setFeaturedVideoData({
        ...featuredVideoData,
        zoomEffect: 'zoomEffect',
        zoomEffectRow: 'zoomEffectRow',
        videoInfo: '--close',
      });
    }, 900);
    setIsVideoInfoOpen(false);
    setFeaturedVideoData({
      ...featuredVideoData,
      videoInfoAnimationClass: 'animation-off',
    });
  };

  const pushRightClass = (elem, option) => {
    const previousSibling = elem.previousSibling;
    if (previousSibling) {
      if (option) {
        previousSibling.classList.add('push-right');
      } else {
        previousSibling.classList.remove('push-right');
      }
      pushRightClass(previousSibling, option);
    }
  };

  const pushLeftClass = (elem, option) => {
    const nextSibling = elem.nextSibling;
    if (nextSibling) {
      if (option) {
        nextSibling.classList.add('push-left');
      } else {
        nextSibling.classList.remove('push-left');
      }
      pushLeftClass(nextSibling, option);
    }
  };

  const pushElementRight = (nodes, option, node) => {
    if (option) {
      if (node.classList.value.includes('last-element')) {
        if (!isVideoInfoOpen) {
          nodes[0].childNodes[0].classList.add('push-element-right');
        }
      }
    } else {
      nodes[0].childNodes[0].classList.remove('push-element-right');
    }
  };

  const findFirstAndLastElement = option => {
    const nodes = document.getElementsByClassName(`item-${itemKey}`);
    const elements = nodes[0].childNodes[0].childNodes[1].childNodes[1];
    if (elements) {
      let elementsNew = elements.childNodes[0].childNodes;
      let singleElementWidth = elementsNew[0].style.width.match(/\d+/g);
      singleElementWidth = singleElementWidth[0];
      let moveElement = elements.childNodes[0].style.msTransform.match(/\d+/g);
      let firstElement = moveElement[0] / singleElementWidth + 1;
      let i = 0;
      elementsNew.forEach(node => {
        i++;
        node.classList.remove('first-element');
        node.classList.remove('last-element');

        if (option) {
          if (firstElement == i) {
            node.classList.add('first-element');
          }
          let lastElement = firstElement + 4;

          if (lastElement == i) {
            node.classList.add('last-element');

            node.addEventListener('mouseenter', () => {
              pushElementRight(nodes, true, node);
            });

            node.addEventListener('mouseleave', () => {
              pushElementRight(nodes, false, node);
            });
          }
        }
      });
    }
  };
  useEffect(() => {
    const nodes = document.getElementsByClassName(`item-${itemKey}`);
    const elements = nodes[0].childNodes[0].childNodes[1].childNodes[1];
    if (elements) {
      let elementsNew = elements.childNodes[0].childNodes;
      //  calculation part
      let i = 0;
      elementsNew.forEach(node => {
        i++;
        if (12 == i) {
          node.classList.add('last-element');
          node.addEventListener('mouseenter', () => {
            pushElementRight(nodes, true, node);
          });

          node.addEventListener('mouseleave', () => {
            pushElementRight(nodes, false, node);
          });
        }

        node.addEventListener('mouseenter', () => {
          pushRightClass(node, true);
          pushLeftClass(node, true);
          findFirstAndLastElement(true);
        });
        node.addEventListener('mouseleave', () => {
          pushRightClass(node, false);
          pushLeftClass(node, false);
          findFirstAndLastElement(false);
        });
      });
    }
  }, []);
  useEffect(() => {
    const root = document.getElementById('root');
    return () => {
      root.className = '';
    };
  }, []);
  const {
    zoomEffect,
    zoomEffectRow,
    videoInfoData,
    videoInfo,
    videoInfoAnimationClass,
    videoInfoActiveClass,
  } = featuredVideoData;

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <div className="slick-next" onClick={onClick}>
        <img
          src={rightArrow}
          alt="rightArrow-icon"
          className="dark-icon icon--rightArrow"
        />
        <img
          src={rightArrow_light}
          alt="rightArrow-icon"
          className="light-icon icon--rightArrow"
        />
      </div>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <div className="slick-prev" onClick={onClick}>
        <img
          src={leftArrow}
          alt="leftArrow-icon"
          className="dark-icon icon--leftArrow"
        />
        <img
          src={leftArrow_light}
          alt="leftArrow-icon"
          className="light-icon icon--leftArrow"
        />
      </div>
    );
  };

  const settings = {
    className: 'left',
    dots: false,
    infinite: videos.length > 5,
    slidesToScroll: 5,
    slidesToShow: 5,
    speed: 500,
    lazyLoad: true,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setSliderIndex(next / 5),
    afterChange: currentSlide => findFirstAndLastElement(currentSlide),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: videos.length > 5,
          dots: false,
          arrows: false,
          beforeChange: (current, next) => setSliderIndex(next / 4),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: videos.length > 4,
          dots: false,
          arrows: false,
          beforeChange: (current, next) => setSliderIndex(next / 4),
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          infinite: videos.length > 3,
          slidesToScroll: 3,
          dots: false,
          arrows: false,
          beforeChange: (current, next) => setSliderIndex(next / 3),
        },
      },
    ],
  };
  const videoList = () =>
    videos
      ? videos.map((video, key) => {
          if (
            video.type === 'ASSESSMENT' ||
            video.type === 'DOCUMENT' ||
            video.type === 'IMAGE'
          ) {
            let altText = '';
            let srcImage = '';

            switch (video.type) {
              case 'ASSESSMENT':
                altText = 'assesment';
                srcImage = video.tileImageUrl || defaultAssessment;
                break;

              case 'DOCUMENT':
                altText = 'document';
                srcImage = video.previewImgUrl || defaultDocument;
                break;

              case 'IMAGE':
                altText = 'image';
                srcImage = video.imageUrl || defaultVideo;
                break;

              default:
                break;
            }

            return (
              <div className={`featured-video__item ${zoomEffect}`} key={key}>
                <div
                  className={`featured-video__item--assessment ${
                    !video.isPublished ? 'unpublished' : ''
                  }`}
                >
                  <div
                    className="miniPlayer__click"
                    onClick={
                      video.type === 'ASSESSMENT'
                        ? () =>
                            history.push(
                              `/assessment/${video?._id?._id || video?._id}`
                            )
                        : event => {
                            if (video.documentUrl || video.assignUrl) {
                              window.open(
                                video.documentUrl || video.assignUrl,
                                '_blank'
                              );
                            } else {
                              event.preventDefault();
                            }
                          }
                    }
                  ></div>
                  {video.dueDate && (
                    <div className="miniPlayer__info--duedate">
                      <img
                        src="/static/media/icon-clock-white.8e210b40.svg"
                        alt="clock-icon"
                        className="icon--clock"
                      />
                      <span>{moment(video.dueDate).format('ll')}</span>
                    </div>
                  )}

                  <div className="miniPlayer__action">
                    <div
                      className={`miniPlayer__action-icon miniPlayer__action--audio-off`}
                    >
                      <svg className="icon icon--red">
                        <use
                          xlinkHref={
                            require('../../assets/images/sprite/sprite.svg') +
                            '#icon-audio-off'
                          }
                        ></use>
                      </svg>
                    </div>
                    <div
                      className={`miniPlayer__action-icon miniPlayer__action--audio-on`}
                    >
                      <svg className="icon icon--red">
                        <use
                          xlinkHref={
                            require('../../assets/images/sprite/sprite.svg') +
                            '#icon-audio-on'
                          }
                        ></use>
                      </svg>
                    </div>

                    {video.isFavourite ? (
                      <div
                        className={`miniPlayer__action-icon miniPlayer__action--thumb-down likeBtn`}
                        onClick={() =>
                          sendFavorite(
                            video?._id?._id || video?._id,
                            'unfavourite',
                            true
                          )
                        }
                      >
                        <svg className="icon icon--red">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-thumb-down'
                            }
                          ></use>
                        </svg>
                      </div>
                    ) : (
                      <div
                        className={`miniPlayer__action-icon miniPlayer__action--thumb-up likeBtn`}
                        onClick={() => {
                          sendFavorite(
                            video?._id?._id || video?._id,
                            'favourite',
                            true
                          );
                        }}
                      >
                        <svg className="icon icon--red">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-thumb-up'
                            }
                          ></use>
                        </svg>
                      </div>
                    )}

                    <span className={`miniPlayer__action--myList`}>
                      {!video.isDoAssessmentLater ? (
                        <div
                          className={`miniPlayer__action-icon myList-add `}
                          onClick={() =>
                            setDoAssessmentLaterId(
                              video?._id?._id || video?._id
                            )
                          }
                        >
                          <svg className="icon icon--red">
                            <use
                              xlinkHref={
                                require('../../assets/images/sprite/sprite.svg') +
                                '#icon-mylist-add'
                              }
                            ></use>
                          </svg>
                        </div>
                      ) : (
                        <div
                          className={`miniPlayer__action-icon myList-added`}
                          onClick={() =>
                            sendRemindLaterDetails(
                              null,
                              video?._id?._id || video?._id,
                              true
                            )
                          }
                        >
                          <svg className="icon icon--red">
                            <use
                              xlinkHref={
                                require('../../assets/images/sprite/sprite.svg') +
                                '#icon-mylist-added'
                              }
                            ></use>
                          </svg>
                        </div>
                      )}
                    </span>
                  </div>

                  <div className="admin-reel_video--icons assessment">
                    {editVideoPermission && (
                      <img
                        src={edit_white}
                        alt="edit-icon"
                        className="icon--edit-white"
                        title={t(ToolTipConfig.adminHome.reel.edit)}
                        onClick={() =>
                          history.push(
                            video.type === 'ASSESSMENT'
                              ? `/edit-assessment/${getBannerFeaturedEditRoute(
                                  video
                                )}${video?._id?._id || video?._id}`
                              : video.type === 'DOCUMENT'
                              ? `/edit-document/${getBannerFeaturedEditRoute(
                                  video
                                )}${video?._id?._id || video?._id}`
                              : `/edit-image/${getBannerFeaturedEditRoute(
                                  video
                                )}${video?._id?._id || video?._id}`
                          )
                        }
                      />
                    )}
                    {!video.reelId &&
                      video.type !== 'IMAGE' &&
                      suspenPublishVideoPermission && (
                        <img
                          src={video.isPublished ? warning : publish}
                          alt={
                            video.isPublished ? 'warning-icon' : 'publish-icon'
                          }
                          className={
                            video.isPublished
                              ? 'icon--warning'
                              : 'icon--publish'
                          }
                          title={
                            video.isPublished
                              ? ToolTipConfig.adminHome.reel.suspend
                              : ToolTipConfig.adminHome.reel.publish
                          }
                          onClick={() =>
                            hanldeIsPublishedStatus(
                              video.type,
                              video?._id?._id || video?._id
                            )
                          }
                        />
                      )}
                    {deleteVideoPermission && (
                      <img
                        src={deleteIcon_white}
                        alt="delete-icon"
                        className="icon--delete-white"
                        title={
                          video.reelId
                            ? ToolTipConfig.adminHome.mandatoryReel.bin
                            : ToolTipConfig.adminHome.reel.bin
                        }
                        onClick={() =>
                          handleDelete({
                            type:
                              video.type === 'ASSESSMENT'
                                ? 'STANDALONE_ASSESSMENT'
                                : video.type,
                            id: video?._id?._id || video?._id,
                            reelId: video.reelId || reelId,
                            categoryId: video.categoryId,
                          })
                        }
                      />
                    )}
                    {video.type === 'ASSESSMENT' && (
                      <div className="assessment__image-wrapper">
                        <img
                          src={stats}
                          alt="stats-icon"
                          className="icon--stats"
                          title={t(ToolTipConfig.adminHome.reel.stats)}
                          onClick={() =>
                            history.push(
                              `/group-dashboard/${groupId}/assessments/standalone/${reelId}/${
                                video?._id?._id || video?._id
                              }`
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="assessment__image-unpublished"></div>
                  <img
                    className="admin-reel_video--icons-background"
                    src={srcImage}
                    alt={altText}
                  />
                </div>
                <div className="miniPlayer__info--title">{video.title}</div>
              </div>
            );
          }
          return (
            // Add class unpublished when the video is unpublished
            <div
              className={`featured-video__item ${
                !video.isPublished ? 'unpublished' : ''
              } ${zoomEffect}`}
              key={key}
            >
              <MiniPlayer
                videoInfo={video}
                isVideoInfoOpen={isVideoInfoOpen}
                closeVideoInfoBox={closeVideoInfoBox}
                title={title}
                openVideoInfoBox={videoInfoData =>
                  openVideoInfoBox(videoInfoData)
                }
                groupId={groupId}
                reelId={reelId}
                categoryId={video.categoryId}
                handleDelete={handleDelete}
                deleteVideoPermission={deleteVideoPermission}
                suspenPublishVideoPermission={suspenPublishVideoPermission}
                editVideoPermission={editVideoPermission}
                hanldeIsPublishedStatus={hanldeIsPublishedStatus}
                addToWatchLater={id => setWatchLaterVideoId(id)}
                removeWatchLater={id => sendRemindLaterDetails(null, id)}
                addToFavorite={id => sendFavorite(id, 'favourite')}
                removeFavorite={id => sendFavorite(id, 'unfavourite')}
                // getLikedvideos={getLikedvideos}
                // getWatchLatervideos={(action, videoInfoId, category) => {
                //   if (action === 'add') {
                //     setWatchLaterVideoId(videoInfoId);
                //   } else {
                //     getWatchLatervideos(action, videoInfoId, category);
                //   }
                // }}
                // handlePlayerHover={status => setIsMiniVideoHover(status)}
                // handleStatics={() => setIsStaticModalOpen(video.id)}
              />
            </div>
          );
        })
      : [];

  const VideoInfoFunction = () => {
    return (
      <VideoPreview
        closeVideoInfoBox={closeVideoInfoBox}
        videoInfoData={videoInfoData}
        videoInfoActiveClass={videoInfoActiveClass}
        videoInfoAnimationClass={videoInfoAnimationClass}
        addToWatchLater={id => setWatchLaterVideoId(id)}
        removeWatchLater={id => sendRemindLaterDetails(null, id)}
        addToFavorite={id => sendFavorite(id, 'favourite')}
        removeFavorite={id => sendFavorite(id, 'unfavourite')}
      />
    );
  };

  return (
    <div
      className={`featured-video__info${videoInfo} item-${itemKey}`}
      id={itemKey}
    >
      <div className={`featured-video ${zoomEffectRow}`}>
        <div
          className={`featured-video__title ${
            isMiniVideoHover ? '' : 'moveup'
          }`}
        >
          <p>
            {t(title)}
            <span
              onClick={() => {
                const root = document.getElementById('root');
                root.className = 'fade';
                setTimeout(() => {
                  history.push(
                    `/reel-bulk-view${groupId ? `/${groupId}` : ''}/video/${
                      reelId || `reel/${title}`
                    }`
                  );
                }, 1000);
              }}
            >
              {t('Explore All')}
            </span>
            <span className="admin-reel-title--icons">
              <img
                src={edit_white}
                alt="edit-icon"
                className="icon--edit"
                title={t(ToolTipConfig.adminHome.reel.editAbove)}
                onClick={() => {
                  if (isMandatoryReel) {
                    history.push(`/group-dashboard/${groupId}/reels/mandatory`);
                  } else {
                    history.push(
                      `/group-dashboard/${groupId}/reels/video/${reelId}`
                    );
                  }
                }}
              />
              {!isMandatoryReel && deleteReelPermission ? (
                <>
                  <hr />
                  <img
                    src={deleteIcon_white}
                    alt="delete-icon"
                    className="icon--delete"
                    title={t(ToolTipConfig.adminHome.reel.binAbove)}
                    onClick={() => handleDelete({ type: 'REEL', id: reelId })}
                  />
                </>
              ) : null}
            </span>
          </p>
          <ul className="theatre__assessment-pagination">
            {videos.length > 5
              ? videos.map((video, index) => {
                  if (!index || !(index % 5)) {
                    const paginationNo = index ? index / 5 : index;
                    return (
                      <li
                        className={sliderIndex === paginationNo ? 'active' : ''}
                        key={uuidv4()}
                      />
                    );
                  }
                })
              : null}
          </ul>
          {!isMandatoryReel && (
            <div
              className="admin-reel-upload--icons"
              onClick={() =>
                history.push(`/create-video/group/${groupId}/reel/${reelId}`)
              }
            >
              <img
                src={upload_video}
                alt="upload_video-icon"
                className="icon--upload_video"
              />
              <p>{t('Upload Video')}</p>
            </div>
          )}
        </div>

        <Slider {...settings}>{videoList()}</Slider>
        {isVideoInfoOpen && VideoInfoFunction()}
      </div>

      {(watchLaterVideoId || doAssessmentLaterId) && (
        <WatchReminderModal
          onHandleConfirm={value => {
            const date = new Date(
              value.year,
              value.month,
              value.date,
              value.meridiem === 'AM' ? value.hr : parseInt(value.hr) + 12,
              value.min,
              value.sec
            );
            sendRemindLaterDetails(date);
            if (watchLaterVideoId) {
              setWatchLaterVideoId(null);
            } else {
              setDoAssessmentLaterId(null);
            }
          }}
          onHandleExit={() => {
            sendRemindLaterDetails();
            if (watchLaterVideoId) {
              setWatchLaterVideoId(null);
            } else {
              setDoAssessmentLaterId(null);
            }
          }}
          onHandleCancel={() => {
            if (watchLaterVideoId) {
              setWatchLaterVideoId(null);
            } else {
              setDoAssessmentLaterId(null);
            }
          }}
        />
      )}
      {isStaticsModalOpen && (
        <StatisticsModal
          videoId={isStaticsModalOpen}
          onHandleCancel={() => setIsStaticModalOpen(false)}
        />
      )}
      {/* {feedBackModalObj && (
        <FeedBackModal
          TrainingRequestObj={feedBackModalObj}
          onHandleCancel={feedBackModalObj => {
            if (feedBackModalObj) {
              trainingRequestFetch(feedBackModalObj);
            }
            setFeedBackModalObj(false);
          }}
        />
      )} */}
    </div>
  );
};
export default Videoslider;

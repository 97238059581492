import {
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAIL,
  CREATE_DOCUMENT_RESET,
  INTIALIZE_DOCUMENT_REQUEST,
  INTIALIZE_DOCUMENT_SUCCESS,
  INTIALIZE_DOCUMENT_FAIL,
  SUSPEND_DOCUMENT_REQUEST,
  SUSPEND_DOCUMENT_SUCCESS,
  SUSPEND_DOCUMENT_FAIL,
  FETCH_DOCUMENT_TO_VIEW_REQUEST,
  FETCH_DOCUMENT_TO_VIEW_SUCCESS,
  FETCH_DOCUMENT_TO_VIEW_FAIL,
  FIND_DOCUMENT_BY_ID_REQUEST,
  FIND_DOCUMENT_BY_ID_DATA,
  FIND_DOCUMENT_BY_ID_FAIL,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAIL,
  UPDATE_DOCUMENT_RESET,
  FETCH_ALL_DOCUMENTS_BY_GROUP_REQUEST,
  FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS,
  FETCH_ALL_DOCUMENTS_BY_GROUP_FAIL,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAIL,
  FETCH_DOCUMENT_URL_BY_ID_REQUEST,
  FETCH_DOCUMENT_URL_BY_ID_SUCCESS,
  FETCH_DOCUMENT_URL_BY_ID_FAIL,
} from '../actionTypes/documentData';

const documentDataReducer = (
  state = {
    createDocumentLoading: false,
    createDocumentSuccess: null,
    createDocumentError: null,
    initializeDocumentLoading: false,
    initializeDocumentData: {},
    initializeDocumentError: null,
    suspendDocumentLoading: false,
    suspendDocumentSuccess: null,
    suspendDocumentError: null,
    fetchDocumentToViewLoading: false,
    fetchDocumentToViewData: null,
    fetchDocumentToViewError: null,
    fetchDocumentToEditLoading: false,
    fetchDocumentToEditData: null,
    fetchDocumentToEditError: null,
    updateDocumentLoading: false,
    updateDocumentSuccess: null,
    updateDocumentError: null,
    fetchAllDocumentsByGroupLoading: false,
    fetchAllDocumentsByGroupData: null,
    fetchAllDocumentsByGroupFail: false,
    deleteDocumentLoading: false,
    deleteDocumentSuccess: null,
    deleteDocumentError: null,
    notificationdDocumentUrlLoading: false,
    notificationdDocumentUrl: null,
    notificationdDocumentUrlError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_DOCUMENT_REQUEST:
      return {
        ...state,
        createDocumentLoading: true,
        createDocumentSuccess: null,
        createDocumentError: null,
      };

    case CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        createDocumentLoading: false,
        createDocumentSuccess: true,
        createDocumentError: null,
      };

    case CREATE_DOCUMENT_FAIL:
      return {
        ...state,
        createDocumentLoading: false,
        createDocumentSuccess: null,
        createDocumentError: true,
      };

    case CREATE_DOCUMENT_RESET:
      return {
        ...state,
        createDocumentLoading: false,
        createDocumentSuccess: null,
        createDocumentError: null,
      };

    case INTIALIZE_DOCUMENT_REQUEST:
      return {
        ...state,
        initializeDocumentLoading: true,
        initializeDocumentData: {},
        initializeDocumentError: null,
      };

    case INTIALIZE_DOCUMENT_SUCCESS:
      return {
        ...state,
        initializeDocumentLoading: false,
        initializeDocumentData: action.payload,
        initializeDocumentError: null,
      };

    case INTIALIZE_DOCUMENT_FAIL:
      return {
        ...state,
        initializeDocumentLoading: false,
        initializeDocumentData: null,
        initializeDocumentError: true,
      };

    case SUSPEND_DOCUMENT_REQUEST:
      return {
        ...state,
        suspendDocumentLoading: true,
        suspendDocumentSuccess: null,
        suspendDocumentError: null,
      };

    case SUSPEND_DOCUMENT_SUCCESS:
      return {
        ...state,
        suspendDocumentLoading: false,
        suspendDocumentSuccess: action.payload,
        suspendDocumentError: null,
      };

    case SUSPEND_DOCUMENT_FAIL:
      return {
        ...state,
        suspendDocumentLoading: false,
        suspendDocumentSuccess: null,
        suspendDocumentError: true,
      };

    case FETCH_DOCUMENT_TO_VIEW_REQUEST:
      return {
        ...state,
        fetchDocumentToViewLoading: true,
        fetchDocumentToViewData: null,
        fetchDocumentToViewError: null,
      };

    case FETCH_DOCUMENT_TO_VIEW_SUCCESS:
      return {
        ...state,
        fetchDocumentToViewLoading: false,
        fetchDocumentToViewData: action.payload,
        fetchDocumentToViewError: null,
      };

    case FETCH_DOCUMENT_TO_VIEW_FAIL:
      return {
        ...state,
        fetchDocumentToViewLoading: false,
        fetchDocumentToViewData: null,
        fetchDocumentToViewError: true,
      };

    case FIND_DOCUMENT_BY_ID_REQUEST:
      return {
        ...state,
        fetchDocumentToEditLoading: true,
        fetchDocumentToEditData: null,
        fetchDocumentToEditError: null,
      };

    case FIND_DOCUMENT_BY_ID_DATA:
      return {
        ...state,
        fetchDocumentToEditLoading: false,
        fetchDocumentToEditData: action.payload,
        fetchDocumentToEditError: null,
      };

    case FIND_DOCUMENT_BY_ID_FAIL:
      return {
        ...state,
        fetchDocumentToEditLoading: false,
        fetchDocumentToEditData: null,
        fetchDocumentToEditError: true,
      };

    case UPDATE_DOCUMENT_REQUEST:
      return {
        ...state,
        updateDocumentLoading: true,
        updateDocumentSuccess: null,
        updateDocumentError: null,
      };

    case UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        updateDocumentLoading: false,
        updateDocumentSuccess: true,
        updateDocumentError: null,
      };

    case UPDATE_DOCUMENT_FAIL:
      return {
        ...state,
        updateDocumentLoading: false,
        updateDocumentSuccess: null,
        updateDocumentError: true,
      };

    case UPDATE_DOCUMENT_RESET:
      return {
        ...state,
        updateDocumentLoading: false,
        updateDocumentSuccess: null,
        updateDocumentError: null,
      };

    case FETCH_ALL_DOCUMENTS_BY_GROUP_REQUEST:
      return {
        ...state,
        fetchAllDocumentsByGroupLoading: true,
        fetchAllDocumentsByGroupData: null,
        fetchAllDocumentsByGroupFail: false,
      };

    case FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS:
      return {
        ...state,
        fetchAllDocumentsByGroupLoading: false,
        fetchAllDocumentsByGroupData: action.payload,
        fetchAllDocumentsByGroupFail: null,
      };

    case FETCH_ALL_DOCUMENTS_BY_GROUP_FAIL:
      return {
        ...state,
        fetchAllDocumentsByGroupLoading: false,
        fetchAllDocumentsByGroupData: null,
        fetchAllDocumentsByGroupFail: true,
      };

    case DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        deleteDocumentLoading: true,
        deleteDocumentSuccess: null,
        deleteDocumentError: null,
      };

    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteDocumentLoading: false,
        deleteDocumentSuccess: true,
        deleteDocumentError: null,
      };

    case DELETE_DOCUMENT_FAIL:
      return {
        ...state,
        deleteDocumentLoading: false,
        deleteDocumentSuccess: null,
        deleteDocumentError: true,
      };

    case FETCH_DOCUMENT_URL_BY_ID_REQUEST:
      return {
        ...state,
        notificationdDocumentUrlLoading: true,
        notificationdDocumentUrl: null,
        notificationdDocumentUrlError: null,
      };

    case FETCH_DOCUMENT_URL_BY_ID_SUCCESS:
      return {
        ...state,
        notificationdDocumentUrlLoading: false,
        notificationdDocumentUrl: action.payload,
        notificationdDocumentUrlError: null,
      };

    case FETCH_DOCUMENT_URL_BY_ID_FAIL:
      return {
        ...state,
        notificationdDocumentUrlLoading: false,
        notificationdDocumentUrl: null,
        notificationdDocumentUrlError: true,
      };

    default:
      return state;
  }
};

export default documentDataReducer;

import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { systemConfig } from '../../../Common/SystemConstConfigs';

const WelcomeVideoModal = ({
  onHandleCancel,
  welcomeVideoUrl,
  userName,
  welcomeVideoText,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    setTimeout(() => {
      body.className.concat('popup-active');
      setIsActive(true);
    }, 0);
    return () => {
      body.className.replace('popup-active', '');
    };
  }, []);

  return (
    <div
      className={`modal-popup ${isActive ? 'active' : ''}`}
      aria-hidden="true"
    >
      <div className={`modal-popup__dialog welcome-video`}>
        <>
          <div className="modal-popup__header">
            <h2>
              {welcomeVideoText
                ? welcomeVideoText
                : `${t('Welcome')} ${userName || ''}`}
            </h2>
            <a
              href="javascript:void(0)"
              className="btn-close closemodale"
              aria-hidden="true"
              onClick={onHandleCancel}
            >
              &times;
            </a>
          </div>

          <div className="video-info__container">
            <ReactPlayer
              width="100%"
              height="100%"
              url={welcomeVideoUrl}
              controls
              autoPlay
              playing
              muted={isMuted}
              onError={() => {
                setIsMuted(true);
              }}
              config={systemConfig.playerNoDownload}
              className="theatre__video-player"
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default WelcomeVideoModal;

import React, { memo } from 'react';
import Dropdown from 'react-dropdown';
import MultiSelect from 'react-multi-select-component';
import _ from 'lodash';
import { getNumberOfDaysDateAddedFromToday } from '../../../../Helper/SystemManager';
import MandatoryMark from '../../../../Components/validation/MandatoryMark';
import { useTranslation } from 'react-i18next';

const AddReelCategoryGroup = memo(
  ({
    reels,
    listOfReelIdCategoryIdAndGroups,
    reelCategoryGroupObj,
    handleData,
  }) => {
    const { t } = useTranslation();
    const tempListOfReelIdCategoryIdAndGroups = [
      ...listOfReelIdCategoryIdAndGroups,
    ];
    const handleDataChange = () =>
      handleData(
        tempListOfReelIdCategoryIdAndGroups,
        'listOfReelIdCategoryIdAndGroups'
      );
    return (
      <div className="admin__createvideo--reels accordion">
        <h3>{t('Reels')}</h3>

        {listOfReelIdCategoryIdAndGroups.map((reelObj, index) => (
          <div className="admin__createvideo--inner-container" key={index}>
            {listOfReelIdCategoryIdAndGroups.length > 1 ? (
              <span
                className="inner-close-btn"
                onClick={() => {
                  tempListOfReelIdCategoryIdAndGroups.splice(index, 1);
                  handleDataChange();
                }}
              >
                ×
              </span>
            ) : null}
            <p className="label">
              {t('Reels')} <MandatoryMark />
            </p>

            <Dropdown
              title="Edit"
              options={
                reels && reels.length
                  ? reels
                      .filter(
                        reel =>
                          tempListOfReelIdCategoryIdAndGroups.filter(
                            selectedReel =>
                              selectedReel.reelId !== reel._id ||
                              selectedReel.reelId === reelObj.reelId
                          ).length ===
                          tempListOfReelIdCategoryIdAndGroups.length
                      )
                      .map(({ name, _id }) => ({
                        label: name,
                        value: _id,
                      }))
                  : []
              }
              onChange={({ value }) => {
                tempListOfReelIdCategoryIdAndGroups[index].reelId = value;
                tempListOfReelIdCategoryIdAndGroups[index].categoryId = '';
                tempListOfReelIdCategoryIdAndGroups[index].groups = [];
                handleDataChange();
              }}
              value={reelObj.reelId}
              placeholder={t('Assign to a Reel')}
            />

            <div className="admin__createvideo--common">
              <p className="label">{t('Category')}</p>
              <Dropdown
                title="Edit"
                options={
                  reelObj.reelId &&
                  reels.length &&
                  reels.filter(({ _id }) => _id === reelObj.reelId)[0]?.category
                    .length
                    ? reels
                        .filter(({ _id }) => _id === reelObj.reelId)[0]
                        .category.map(({ name, _id }) => ({
                          label: name,
                          value: _id,
                        }))
                    : []
                }
                onChange={({ value }) => {
                  tempListOfReelIdCategoryIdAndGroups[index].categoryId = value;
                  handleDataChange();
                }}
                value={reelObj.categoryId}
                placeholder={t('Select a Category')}
              />

              <p className="label">
                {t('Group')} <MandatoryMark />
              </p>

              <div className="section--right-column reel">
                <MultiSelect
                  title="Edit"
                  options={
                    reelObj.reelId &&
                    reels.length &&
                    reels.filter(({ _id }) => _id === reelObj.reelId)[0]
                      ?.allGroups.length
                      ? reels
                          .filter(({ _id }) => _id === reelObj.reelId)[0]
                          .allGroups.map(({ name, _id }) => ({
                            label: name,
                            value: _id,
                          }))
                      : []
                  }
                  labelledBy={'Select'}
                  hasSelectAll={false}
                  onChange={selectedOptions => {
                    tempListOfReelIdCategoryIdAndGroups[
                      index
                    ].groups = selectedOptions.map(selectedOption => {
                      const selectedGroup = listOfReelIdCategoryIdAndGroups.map(
                        groupObject =>
                          groupObject['groups'].find(
                            group => group._id === selectedOption.value
                          )
                      )[0];

                      return {
                        _id: selectedOption.value,
                        name: selectedOption.label,
                        isMandatory:
                          (selectedGroup && selectedGroup.isMandatory) || false,
                        dueDate: (selectedGroup && selectedGroup.dueDate) || '',
                      };
                    });

                    handleDataChange();
                  }}
                  value={reelObj.groups.map(({ name, _id }) => ({
                    label: name,
                    value: _id,
                  }))}
                  disableSearch
                  placeholder={t('Select Group / Sub Group')}
                />

                {reelObj.groups.length
                  ? reelObj.groups.map((group, groupIndex) => (
                      <div
                        className="admin__createvideo--common-sub"
                        key={groupIndex}
                      >
                        <p className="label">{group.name}</p>
                        <a
                          className="btn-close closemodale"
                          aria-hidden="true"
                          onClick={() => {
                            tempListOfReelIdCategoryIdAndGroups[
                              index
                            ].groups.splice(groupIndex, 1);
                            handleDataChange();
                          }}
                        >
                          &times;
                        </a>
                        <p className="label">{t('Mandatory')}</p>
                        <input
                          readOnly
                          type="checkbox"
                          className="form-input--checkbox"
                          id="mandatory-yes"
                          name="mandatory-yes"
                          checked={group.isMandatory}
                        />
                        <label
                          onClick={() => {
                            tempListOfReelIdCategoryIdAndGroups[index].groups[
                              groupIndex
                            ].isMandatory = true;
                            handleDataChange();
                          }}
                        >
                          {t('Yes')}
                        </label>
                        <input
                          type="date"
                          min={getNumberOfDaysDateAddedFromToday(1)}
                          id="mandatory-duedate"
                          name="mandatory-duedate"
                          value={group.dueDate || ''}
                          onChange={e => {
                            tempListOfReelIdCategoryIdAndGroups[index].groups[
                              groupIndex
                            ].dueDate = e.target.value;
                            handleDataChange();
                          }}
                        />
                        <input
                          readOnly
                          type="checkbox"
                          className="form-input--checkbox"
                          id="mandatory-no"
                          name="mandatory-no"
                          checked={!group.isMandatory}
                        />
                        <label
                          onClick={() => {
                            tempListOfReelIdCategoryIdAndGroups[index].groups[
                              groupIndex
                            ].isMandatory = false;
                            handleDataChange();
                          }}
                        >
                          {t('No')}
                        </label>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        ))}

        <p
          className="addanother"
          onClick={() =>
            handleData(
              [
                ...listOfReelIdCategoryIdAndGroups,
                _.clone(reelCategoryGroupObj),
              ],
              'listOfReelIdCategoryIdAndGroups'
            )
          }
        >
          {t('Add to another Reel')}
        </p>
      </div>
    );
  }
);

export default AddReelCategoryGroup;

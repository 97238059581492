import {
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
} from "../actionTypes/category";

const categoryReducer = (
  state = {
    addCategoryLoading: false,
    addCategorySuccess: null,
    addCategoryError: null,
  },
  action
) => {
  switch (action.type) {
    case ADD_CATEGORY_REQUEST:
      return {
        ...state,
        addCategoryLoading: true,
        addCategorySuccess: null,
        addCategoryError: null,
      };

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        addCategoryLoading: false,
        addCategorySuccess: action.payload,
        addCategoryError: null,
      };

    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        addCategoryLoading: false,
        addCategorySuccess: null,
        addCategoryError: action.payload,
      };

    default:
      return state;
  }
};

export default categoryReducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import edit_white from '../../assets/images/svg-images/icon-edit-white.svg';
import deleteIcon_white from '../../assets/images/svg-images/icon-delete-white.svg';
import clock from '../../assets/images/svg-images/icon-clock-white.svg';

import { organizeDate } from '../../Helper/SystemManager';
import { ToolTipConfig } from '../../Common/ToolTipConfig';

const MandatoryReelGridView = ({
  isSuperAdmin,
  canEditMandatoryReel,
  canDeleteMandatoryReel,
  mandatoryReels,
  handleGridOnclick,
  handleEdit,
  handleDelete,
  seachName,
}) => {
  const { t } = useTranslation();
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;
  const defaultDocument = themeInfo?.documentImageUrl;
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;

  const currentReels = mandatoryReels['currentReels']
    .filter(
      ({ title }) =>
        seachName === '' ||
        title?.toUpperCase().includes(seachName.toUpperCase())
    )
    .map((mandatoryReel, index) => {
      const { imageUrl, title, dueDate, isPublished, type } = mandatoryReel;

      return (
        <div className="reel-view--grid-video-wrapper" key={index}>
          <div
            className={`reel-view--grid-video ${
              !isPublished ? 'unpublished' : ''
            }`}
          >
            <div
              className="reel-view--grid-video-unpublished"
              onClick={() => handleGridOnclick(mandatoryReel)}
            ></div>
            <img
              src={imageUrl}
              alt="reel-thumbnail"
              className="reel-thumbnail"
              onClick={() => handleGridOnclick(mandatoryReel)}
              onError={event => {
                event.target.onerror = null;
                event.target.src =
                  type === 'VIDEO'
                    ? defaultVideo
                    : type === 'DOCUMENT'
                    ? defaultDocument
                    : defaultAssessment;
              }}
            />
            <div className="reel-view--grid-video-settings">
              {(isSuperAdmin || canEditMandatoryReel) && (
                <img
                  src={edit_white}
                  alt="edit-icon"
                  className="icon--edit-white"
                  title={t(ToolTipConfig.adminSettings.mandatoryReel.edit)}
                  onClick={() => handleEdit(mandatoryReel)}
                />
              )}
              {(isSuperAdmin || canDeleteMandatoryReel) && (
                <img
                  src={deleteIcon_white}
                  alt="delete-icon"
                  className="icon--delete-white"
                  title={t(ToolTipConfig.adminSettings.mandatoryReel.bin)}
                  onClick={() => handleDelete(mandatoryReel)}
                />
              )}
              {/* <div className="reel-view--grid-video-settings-stats">
              <img
                src={stats}
                alt="stats-icon"
                title={ToolTipConfig.adminSettings.mandatoryReel.stats}
                className="icon--stats"
              />
            </div> */}
            </div>
            <div className="miniPlayer__info--duedate">
              <img src={clock} alt="clock-icon" className="icon--clock" />
              <span>
                {t('Due before')} {organizeDate(dueDate)}
              </span>
            </div>
          </div>
          <p>{title}</p>
        </div>
      );
    });

  const deadLineExceededReels = mandatoryReels['deadLineExceeded']
    .filter(
      ({ title }) =>
        seachName === '' ||
        title?.toUpperCase()?.includes(seachName.toUpperCase())
    )
    .map((mandatoryReel, index) => {
      const { imageUrl, title, isPublished, type, dueDate } = mandatoryReel;

      return (
        <div className="reel-view--grid-video-wrapper" key={index}>
          <div
            className={`reel-view--grid-video ${
              !isPublished ? 'unpublished' : ''
            }`}
          >
            <div
              className="reel-view--grid-video-unpublished"
              onClick={() => handleGridOnclick(mandatoryReel)}
            ></div>
            <img
              src={imageUrl}
              alt="reel-thumbnail"
              className="reel-thumbnail"
              onClick={() => handleGridOnclick(mandatoryReel)}
              onError={event => {
                event.target.onerror = null;
                event.target.src =
                  type === 'VIDEO'
                    ? defaultVideo
                    : type === 'DOCUMENT'
                    ? defaultDocument
                    : defaultAssessment;
              }}
            />
            <div className="reel-view--grid-video-settings">
              {(isSuperAdmin || canEditMandatoryReel) && (
                <img
                  src={edit_white}
                  alt="edit-icon"
                  className="icon--edit-white"
                  title={t(ToolTipConfig.adminSettings.mandatoryReel.edit)}
                  onClick={() => handleEdit(mandatoryReel)}
                />
              )}
              {(isSuperAdmin || canDeleteMandatoryReel) && (
                <img
                  src={deleteIcon_white}
                  alt="delete-icon"
                  className="icon--delete-white"
                  title={t(ToolTipConfig.adminSettings.mandatoryReel.bin)}
                  onClick={() => handleDelete(mandatoryReel)}
                />
              )}
              {/* <div className="reel-view--grid-video-settings-stats">
              <img
                src={stats}
                alt="stats-icon"
                title={ToolTipConfig.adminSettings.mandatoryReel.stats}
                className="icon--stats"
              />
            </div> */}
            </div>
            <div className="miniPlayer__info--duedate">
              <img src={clock} alt="clock-icon" className="icon--clock" />
              <span>Due before {organizeDate(dueDate)}</span>
            </div>
          </div>
          <p>{title}</p>
        </div>
      );
    });

  return (
    <>
      <div className="reel-view--grid">
        <h3>{t('CURRENT')}</h3>
        <div className="reel-view--grid-wrapper">
          {currentReels.length ? (
            currentReels
          ) : (
            <p className="reel-view--grid-noitem">{t('No Image')}</p>
          )}
        </div>
      </div>
      <div className="reel-view--grid">
        <h3>{t('DEADLINE EXCEEDED')}</h3>
        <div className="reel-view--grid-wrapper">
          {deadLineExceededReels.length ? (
            deadLineExceededReels
          ) : (
            <p className="reel-view--grid-noitem">{t('No Image')}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default MandatoryReelGridView;

export const FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_REQUEST =
  'FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_REQUEST';
export const FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS =
  'FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS';
export const FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_FAIL =
  'FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_FAIL';

export const ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_REQUEST =
  'ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_REQUEST';
export const ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_SUCCESS =
  'ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_SUCCESS';
export const ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_FAIL =
  'ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_FAIL';
export const ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_RESET =
  'ADD_STANDALONE_ASSESSMENT_TO_CATEGORY_RESET';

export const FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_REQUEST =
  'FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_REQUEST';
export const FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_SUCCESS =
  'FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_SUCCESS';
export const FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_FAIL =
  'FETCH_ALL_ASSESSMENTS_BY_PLACEMENT_FAIL';

export const FETCH_ALL_STANDALONE_ASSESSMENTS_REQUEST =
  'FETCH_ALL_STANDALONE_ASSESSMENTS_REQUEST';
export const FETCH_ALL_STANDALONE_ASSESSMENTS_SUCCESS =
  'FETCH_ALL_STANDALONE_ASSESSMENTS_SUCCESS';
export const FETCH_ALL_STANDALONE_ASSESSMENTS_FAIL =
  'FETCH_ALL_STANDALONE_ASSESSMENTS_FAIL';

export const FIND_ASSESSMENT_BY_ID_REQUEST = 'FIND_ASSESSMENT_BY_ID_REQUEST';
export const FIND_ASSESSMENT_BY_ID_SUCCESS = 'FIND_ASSESSMENT_BY_ID_SUCCESS';
export const FIND_ASSESSMENT_BY_ID_FAIL = 'FIND_ASSESSMENT_BY_ID_FAIL';

export const CREATE_ASSESSMENT_REQUEST = 'CREATE_ASSESSMENT_REQUEST';
export const CREATE_ASSESSMENT_SUCCESS = 'CREATE_ASSESSMENT_SUCCESS';
export const CREATE_ASSESSMENT_FAIL = 'CREATE_ASSESSMENT_FAIL';
export const CREATE_ASSESSMENT_RESET = 'CREATE_ASSESSMENT_RESET';

export const UPDATE_ASSESSMENT_REQUEST = 'UPDATE_ASSESSMENT_REQUEST';
export const UPDATE_ASSESSMENT_SUCCESS = 'UPDATE_ASSESSMENT_SUCCESS';
export const UPDATE_ASSESSMENT_FAIL = 'UPDATE_ASSESSMENT_FAIL';
export const UPDATE_ASSESSMENT_RESET = 'UPDATE_ASSESSMENT_RESET';

export const SUSPEND_ASSESSMENT_REQUEST = 'SUSPEND_ASSESSMENT_REQUEST';
export const SUSPEND_ASSESSMENT_SUCCESS = 'SUSPEND_ASSESSMENT_SUCCESS';
export const SUSPEND_ASSESSMENT_FAIL = 'SUSPEND_ASSESSMENT_FAIL';

export const FETCH_CREATE_ASSESSMENT_RELATED_INFO_REQUEST =
  'FETCH_CREATE_ASSESSMENT_RELATED_INFO_REQUEST';
export const FETCH_CREATE_ASSESSMENT_RELATED_INFO_DATA =
  'FETCH_CREATE_ASSESSMENT_RELATED_INFO_DATA';
export const FETCH_CREATE_ASSESSMENT_RELATED_INFO_FAIL =
  'FETCH_CREATE_ASSESSMENT_RELATED_INFO_FAIL';

export const FETCH_MID_END_ASSESSMENTS_BY_GROUP_REQUEST =
  'FETCH_MID_END_ASSESSMENTS_BY_GROUP_REQUEST';
export const FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA =
  'FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA';
export const FETCH_MID_END_ASSESSMENTS_BY_GROUP_FAIL =
  'FETCH_MID_END_ASSESSMENTS_BY_GROUP_FAIL';

export const DELETE_STANDALONE_ASSESSMENT_REQUEST =
  'DELETE_STANDALONE_ASSESSMENT_REQUEST';
export const DELETE_STANDALONE_ASSESSMENT_SUCCESS =
  'DELETE_STANDALONE_ASSESSMENT_SUCCESS';
export const DELETE_STANDALONE_ASSESSMENT_FAIL =
  'DELETE_STANDALONE_ASSESSMENT_FAIL';

export const DELETE_MID_END_ASSESSMENT_REQUEST =
  'DELETE_MID_END_ASSESSMENT_REQUEST';
export const DELETE_MID_END_ASSESSMENT_SUCCESS =
  'DELETE_MID_END_ASSESSMENT_SUCCESS';
export const DELETE_MID_END_ASSESSMENT_FAIL = 'DELETE_MID_END_ASSESSMENT_FAIL';

export const SEND_ASSESSMENT_RESULT_REQUEST = 'SEND_ASSESSMENT_RESULT_REQUEST';
export const SEND_ASSESSMENT_RESULT_SUCCESS = 'SEND_ASSESSMENT_RESULT_SUCCESS';
export const SEND_ASSESSMENT_RESULT_FAIL = 'SEND_ASSESSMENT_RESULT_FAIL';

export const REMIND_LATER_REQUEST = 'REMIND_LATER_REQUEST';
export const REMIND_LATER_SUCCESS = 'REMIND_LATER_SUCCESS';
export const REMIND_LATER_FAIL = 'REMIND_LATER_FAIL';

export const FETCH_ASSESSMENT_STATS_BY_GROUP_REQUEST =
  'FETCH_ASSESSMENT_STATS_BY_GROUP_REQUEST';
export const FETCH_ASSESSMENT_STATS_BY_GROUP_SUCCESS =
  'FETCH_ASSESSMENT_STATS_BY_GROUP_SUCCESS';
export const FETCH_ASSESSMENT_STATS_BY_GROUP_FAIL =
  'FETCH_ASSESSMENT_STATS_BY_GROUP_FAIL';
export const FETCH_ASSESSMENT_STATS_BY_GROUP_RESET =
  'FETCH_ASSESSMENT_STATS_BY_GROUP_RESET';

export const UPDATE_ASSESSMENT_VIEWS_REQUEST =
  'UPDATE_ASSESSMENT_VIEWS_REQUEST';
export const UPDATE_ASSESSMENT_VIEWS_SUCCESS =
  'UPDATE_ASSESSMENT_VIEWS_SUCCESS';
export const UPDATE_ASSESSMENT_VIEWS_FAIL = 'UPDATE_ASSESSMENT_VIEWS_FAIL';

export const FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_REQUEST =
  'FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_REQUEST';
export const FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_SUCCESS =
  'FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_SUCCESS';
export const FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_FAIL =
  'FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_FAIL';

export const DOWNLOAD_ASSESSMENT_STATS_REQUEST =
  'DOWNLOAD_ASSESSMENT_STATS_REQUEST';
export const DOWNLOAD_ASSESSMENT_STATS_SUCCESS =
  'DOWNLOAD_ASSESSMENT_STATS_SUCCESS';
export const DOWNLOAD_ASSESSMENT_STATS_FAIL = 'DOWNLOAD_ASSESSMENT_STATS_FAIL';

export const SET_STATS_CURRENT_ASSESSMENT = 'SET_STATS_CURRENT_ASSESSMENT';

export const DOWNLOAD_ASSESSMENT_TRANSCRIPT_REQUEST =
  'DOWNLOAD_ASSESSMENT_TRANSCRIPT_REQUEST';
export const DOWNLOAD_ASSESSMENT_TRANSCRIPT_SUCCESS =
  'DOWNLOAD_ASSESSMENT_TRANSCRIPT_SUCCESS';
export const DOWNLOAD_ASSESSMENT_TRANSCRIPT_FAIL =
  'DOWNLOAD_ASSESSMENT_TRANSCRIPT_FAIL';
export const DOWNLOAD_ASSESSMENT_TRANSCRIPT_RESET =
  'DOWNLOAD_ASSESSMENT_TRANSCRIPT_RESET';

export const FETCH_ASSESSMENT_SCORES_REQUEST =
  'FETCH_ASSESSMENT_SCORES_REQUEST';
export const FETCH_ASSESSMENT_SCORES_SUCCESS =
  'FETCH_ASSESSMENT_SCORES_SUCCESS';
export const FETCH_ASSESSMENT_SCORES_FAIL = 'FETCH_ASSESSMENT_SCORES_FAIL';
export const FETCH_ASSESSMENT_SCORES_RESET = 'FETCH_ASSESSMENT_SCORES_RESET';

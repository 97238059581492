import React, { useState, useEffect, useRef } from 'react';
import { Range } from 'react-range';
import VTTConverter from 'srt-webvtt';
import { findDOMNode } from 'react-dom';
import Scrollbar from 'react-smooth-scrollbar';
import moment from 'moment';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import Scrollbars from 'react-scrollbars-custom';
import { Element } from 'react-scroll';
import ReactPlayer from 'react-player';
// import { Player } from 'video-react';
import screenfull from 'screenfull';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { useTranslation } from 'react-i18next';

import play from '../../assets/images/svg-images/play-solid.svg';
import pause from '../../assets/images/svg-images/pause-icon.svg';
import resolution from '../../assets/images/svg-images/icon-resolution.svg';
import rewind from '../../assets/images/player/rewind-icon.svg';
import rewind_light from '../../assets/images/player/rewind-icon-light.svg';
import forward from '../../assets/images/player/forward-icon.svg';
import forward_light from '../../assets/images/player/forward-icon-light.svg';
import next from '../../assets/images/player/next-icon.svg';
import next_light from '../../assets/images/player/next-icon-light.svg';
import fullscreen from '../../assets/images/player/fullscreen-icon.svg';
import fullscreen_light from '../../assets/images/player/fullscreen-icon-light.svg';
import watchlater from '../../assets/images/player/watchlater-icon.svg';
import watchlater_light from '../../assets/images/player/watchlater-icon-light.svg';
import volume from '../../assets/images/player/volume-icon.svg';
import volume_mute from '../../assets/images/player/volume-mute.svg';
import subtitle from '../../assets/images/player/subtitle-icon.svg';
import subtitle_light from '../../assets/images/player/subtitle-icon-light.svg';
import pdf from '../../assets/images/svg-images/icon-pdf.svg';
import editWhite from '../../assets/images/svg-images/icon-edit-white.svg';
import upload from '../../assets/images/svg-images/icon-upload.svg';
import warning from '../../assets/images/svg-images/icon-warning.svg';
import deleteIconWhite from '../../assets/images/svg-images/icon-delete-white.svg';
import { convertToCdnUrl } from '../../Helper/convertToCdnUrl';

import info from '../../assets/images/svg-images/icon-info.svg';

import WatchReminderModal from '../../Components/Modal/WatchReminderModal';
import {
  CHANGE_LIKES_VIDEOS,
  CHANGE_WATCH_LATER_VIDEOS,
  REMOVE_SMOOTH_TRANSITION_MODE,
} from '../../redux/action/actionTypes';
import {
  REMIND_LATER_REQUEST,
  REMIND_LATER_SUCCESS,
  REMIND_LATER_FAIL,
} from '../../redux/actionTypes/assesment';

import {
  FETCH_VIDEO_TO_PLAY_REQUEST,
  FETCH_VIDEO_TO_PLAY_SUCCESS,
  FETCH_VIDEO_TO_PLAY_FAIL,
  UPDATE_VIDEO_VIEW_REQUEST,
  UPDATE_VIDEO_VIEW_SUCCESS,
  UPDATE_VIDEO_VIEW_FAIL,
} from '../../redux/actionTypes/video';
import {
  INTIALIZE_HOME_DATA_REQUEST,
  INTIALIZE_HOME_DATA_SUCCESS,
  INTIALIZE_HOME_DATA_FAIL,
} from '../../redux/actionTypes/homeData';
import MidVideoAssessment from './MidVideoAssessment';

import useApi from '../../Hooks/useApi';
import axios from 'axios';
import VideoInfoModal from '../../Components/Modal/VideoInfoModal';
import ViewPDFModal from '../AdminDashboard/Group/Modal/viewPDFModal';
import Loader from '../../Components/Loader/loader';
import useAnalytics from '../../Hooks/useAnalytics';
import { secondsToMMSS } from '../../Helper/SystemManager';

const Theatre = ({ history, match }) => {
  const { t } = useTranslation();
  const sendVideoAnalytics = useAnalytics();
  const [theatreData, setTheatreData] = useState({
    player: {},
    playTimer: '',
    duration: 0.0,
    playerVolume: 0.05,
    hdMenu: 'hide',
    activeC: '',
    activeContactCall: 'active',
    activeContactMessaging: '',
    activeContactSchedule: '',
    playerType: 'mini',
    playIcon: 'pause',
    muted: false,
  });
  const [playingTimeInPlayer, setPlayingTimeInPlayer] = useState(0);

  const { isAdminView } = useSelector(state => state?.auth);
  const { videoPlayFetchData = {} } = useSelector(state => state?.video);
  const [playerScreen, setPlayerScreen] = useState('smallScreen');
  const [currentMidVideAssessementIndex, setCurrentMidVideAssessementIndex] =
    useState(0);
  const [midVideoAssessmentData, setMidVideoAssessmentData] = useState([]);
  const [midVideoTriggerTimes, setMidVideoTriggerTimes] = useState([]);
  const [isMidVideoAssessmentVisible, setMidVideoAssessmentVisible] =
    useState(false);
  const [endVideoAssessmentData, setEndVideoAssessmentData] = useState(null);
  const [isPlayStart, setIsPlayStart] = useState(false);
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabActiveStatus, setTabActiveStatus] = useState({
    activeD: 'active',
    activeQ: '',
  });
  const [pdfFileUrl, setPdfFileUrl] = useState('');
  const video = useSelector(state => state.video);
  const dispatch = useDispatch();
  const [watchedLaterVideoId, setWatchedLaterVideoId] = useState(null);
  const [isPlay, setIsPlay] = useState(false);
  const [playerVolume, setPlayerVolume] = useState(0);
  const [isVideoPlayerLoaded, setVideoPlayerLoaded] = useState(false);
  const [isVideoPlayStarted, setIsVideoPlayStarted] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [isSendWatchLaterCount, setIsSendWatchLaterCount] = useState(false);
  const [videoPlayingTime, setVideoPlayingTime] = useState(-2);
  const [subtitleUrl, setSubtitleUrl] = useState(null);
  const [showVideoInfo, setShowVideoInfo] = useState(false);
  const [playedTimeStamps, setPlayedTimeStamps] = useState([]);
  const [fetchHomeData] = useApi();
  const [sendRemindLaterRequest] = useApi();

  const { intializehomeDataData } = useSelector(state => state?.homeData);
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;

  const { player, hdMenu, playerType } = theatreData;

  const isIOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

  const {
    assessments = [],
    documents = [],
    description,
    author,
    subtitleS3BucketUrl,
  } = videoPlayFetchData || {};
  const endVideoAssessmentInfo = assessments.length
    ? assessments.find(assessment => assessment.placement === 'END')
    : null;
  const convertTimeToSec = time =>
    parseInt(time.split(':')[0] * 60) + parseInt(time.split(':')[1]);

  useEffect(() => {
    if (assessments.length) {
      const midVideoAssessmentsInfo = assessments.length
        ? assessments
            .filter(assessment => assessment.placement === 'MID')
            .map(assessment => {
              const tempAssessment = { ...assessment };
              tempAssessment.startTime = convertTimeToSec(assessment.startTime);
              tempAssessment.triggerTime = convertTimeToSec(
                assessment.triggerTime
              );
              tempAssessment.isMidVideoAssessmentAttempt = false;
              tempAssessment.isMidVideoQuesionEnd = false;
              tempAssessment.isAllAnswersCorrect = false;
              return tempAssessment;
            })
            .sort(
              (assessment1, assessment2) =>
                assessment1.triggerTime - assessment2.triggerTime
            )
        : null;
      const tempMidVideoTriggerTimes = midVideoAssessmentsInfo.map(
        midVideoAssessment => midVideoAssessment.triggerTime
      );
      setMidVideoTriggerTimes(tempMidVideoTriggerTimes);
      setMidVideoAssessmentData(midVideoAssessmentsInfo);
    }
  }, [assessments]);

  const [fetchVideo] = useApi();
  const [postVideoViews] = useApi();

  let playerElement = useRef(null);
  let scrollbar = useRef(null);
  const theatreVideoId = parseInt(match.params.id);

  const summaryStartingTimes =
    videoPlayFetchData && videoPlayFetchData.summaries
      ? videoPlayFetchData.summaries.map(summary =>
          convertTimeToSec(summary.startTime)
        )
      : [];

  const { videoId, groupId } = match.params;
  const postVideoCount = () => {
    postVideoViews(
      `/video/update-video-views?videoId=${videoId}`,
      UPDATE_VIDEO_VIEW_REQUEST,
      UPDATE_VIDEO_VIEW_SUCCESS,
      UPDATE_VIDEO_VIEW_FAIL,
      null,
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  useEffect(() => {
    fetchVideo(
      `/video/play-video-on-click?videoId=${videoId}`,
      FETCH_VIDEO_TO_PLAY_REQUEST,
      FETCH_VIDEO_TO_PLAY_SUCCESS,
      FETCH_VIDEO_TO_PLAY_FAIL,
      null,
      '',
      'GET',
      null,
      'isReelService'
    );
  }, [videoId]);

  const addRefToPlayerElement = elem => {
    playerElement = elem;
  };
  const timeConverter = time => {
    return (time - (time %= 60)) / 60 + (9 < time ? ':' : ':0') + time;
  };

  const getHomeData = () => {
    fetchHomeData(
      `/admin/init${groupId ? `?groupId=${groupId}` : ''}`,
      INTIALIZE_HOME_DATA_REQUEST,
      INTIALIZE_HOME_DATA_SUCCESS,
      INTIALIZE_HOME_DATA_FAIL,
      null,
      '',
      'GET',
      null,
      'isReelService'
    );
  };

  useEffect(() => {
    if (intializehomeDataData && Object.keys(intializehomeDataData).length) {
      const {
        videoReels,
        listBanners,
        listFeatured,
        mandatory,
        favourite,
        watchLaterVideo,
      } = intializehomeDataData;
      let tempVideoList = [
        ...listBanners.filter(({ type }) => type === 'VIDEO'),
        ...listFeatured.filter(({ type }) => type === 'VIDEO'),
        ...mandatory.filter(({ type }) => type === 'VIDEO'),
        ...favourite.filter(({ type }) => type === 'VIDEO'),
        ...watchLaterVideo.filter(({ type }) => type === 'VIDEO'),
      ];
      videoReels.map(({ videos }) => {
        tempVideoList = [
          ...tempVideoList,
          ...videos.filter(({ type }) => type === 'VIDEO'),
        ];
      });
      setVideoList(tempVideoList);
    }
  }, [intializehomeDataData]);

  useEffect(() => getHomeData(), []);

  useEffect(() => {
    if (isIOSDevice) {
      setTimeout(() => {
        setIsVideoPlayStarted(true);
      }, 3000);
    }
    return () => {
      dispatch({
        type: FETCH_VIDEO_TO_PLAY_SUCCESS,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if ($('.theatre__player')) {
      $(
        '.fb-video.fb_iframe_widget.fb_iframe_widget_fluid_desktop'
      ).removeClass('fb_iframe_widget');
    }

    $('.theatre__controllers').insertAfter('.theatre__video-player video');
    $('.theatre__controllers').insertAfter('.theatre__video-player iframe');

    if ($('.theatre__player.fullScreen .theatre__video-player video')) {
      $('.theatre__player.fullScreen .theatre__video-player video').mousemove(
        function (event) {
          $(
            '.theatre__player.fullScreen .theatre__video-player .theatre__controllers'
          ).css('display', 'flex');
        }
      );

      setTimeout(function () {
        $(
          '.theatre__player.fullScreen .theatre__video-player .theatre__controllers'
        ).css('display', 'none');
      }, 2000);
    }

    if ($('.theatre__player.fullScreen .theatre__video-player iframe')) {
      $('.theatre__player.fullScreen .theatre__video-player iframe').mousemove(
        function (event) {
          $(
            '.theatre__player.fullScreen .theatre__video-player .theatre__controllers'
          ).css('display', 'flex');
        }
      );

      setTimeout(function () {
        $(
          '.theatre__player.fullScreen .theatre__video-player .theatre__controllers'
        ).css('display', 'none');
      }, 2000);
    }
  });

  useEffect(() => {
    if (isVideoPlayerLoaded) {
      const reactPlayer = document.getElementById('reactPlayer');
      reactPlayer.addEventListener('onClick', () => console.log('clicked'));
    }
  }, [isVideoPlayerLoaded]);

  useEffect(() => {
    if (
      !isLoading &&
      summaryStartingTimes.length &&
      isPlay &&
      window.innerWidth > 800
    ) {
      const nextSummaryScrollTime = summaryStartingTimes
        .filter(startTime => playingTimeInPlayer > startTime)
        .pop();
      const elmnt = document.getElementById(nextSummaryScrollTime);
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [theatreData]);

  useEffect(() => {
    const root = document.getElementById('root');
    root.className = 'theater-page';

    dispatch({
      type: REMOVE_SMOOTH_TRANSITION_MODE,
    });
  }, [theatreVideoId, isLoading]);
  // play video
  const playVideo = () => {
    if (!isMidVideoAssessmentVisible) {
      setIsPlay(!isPlay);
    }
  };

  // full screen
  const fullScreen = () => {
    if (playerScreen !== 'smallScreen') {
      screenfull.exit(findDOMNode(playerElement));
      setPlayerScreen('smallScreen');
    } else {
      screenfull.request(findDOMNode(playerElement));
      setPlayerScreen('fullScreen');
    }
    // let { player } = theatreData;

    // player.fullscreen.enter();
  };
  const muteVideoSound = boolean => {
    if (!boolean) {
      setIsAudioOn(false);
      setPlayerVolume(0);
    } else {
      setIsAudioOn(true);
      setPlayerVolume(1);
    }
  };

  const handleChangePlayerVolume = event => {
    const volumeValue = event.target.value;
    setPlayerVolume(volumeValue);
  };

  const handleChangePlayerQuality = quality => {
    const { player } = theatreData;
    player.quality = quality;
  };

  // open HD menu
  const openHdMenu = () => {
    const { hdMenu } = theatreData;

    if (hdMenu == 'hide') {
      setTheatreData({ ...theatreData, hdMenu: 'show' });
    } else {
      setTheatreData({ ...theatreData, hdMenu: 'hide' });
    }
  };

  const clickOnTab = tab => {
    switch (tab) {
      case 'summary':
        setTabActiveStatus({
          activeD: 'active',
          activeQ: '',
        });
        break;
      case 'policies':
        setTabActiveStatus({
          activeD: '',
          activeQ: 'active',
        });
        break;
      default:
    }
  };

  const { activeD, activeQ } = tabActiveStatus;
  const moveTimeStamp = starTime => {
    if (!isMidVideoAssessmentVisible) {
      const startTimeInSec = convertTimeToSec(starTime);
      playerElement.seekTo(parseFloat(startTimeInSec));
    }
  };
  const listItems =
    videoPlayFetchData &&
    videoPlayFetchData.summaries &&
    videoPlayFetchData.summaries.length &&
    videoPlayFetchData.summaries.map(data => (
      <Element
        id={convertTimeToSec(data.startTime)}
        className="theatre__info--item"
        key={uuidv4()}
        onClick={() => moveTimeStamp(data.startTime)}
      >
        {data.notes}
        <span
          className="theatre__info--timing"
          onClick={() => moveTimeStamp(data.startTime)}
        >
          {data.startTime + ' - ' + data.endTime}
        </span>
      </Element>
    ));

  const pdfView = (url, title, allowDownload) => {
    if (isPlay && !isMidVideoAssessmentVisible) {
      playVideo();
    }
    setPdfFileUrl({ url, title, allowDownload });
  };

  let DocumentList;
  if (documents.length) {
    DocumentList = documents.map(({ title, documentUrl, download }) => (
      <div className={`theatre__info__message_item`} key={uuidv4()}>
        <img
          src={pdf}
          alt="pause-icon"
          className="icon--pdf"
          onClick={() => pdfView(documentUrl, title, download)}
        />
        <p onClick={() => pdfView(documentUrl, title, download)}>{title}</p>
      </div>
    ));
  }

  const scrollElement = document.getElementById('scrollContent');

  const handleSliderChange = newValue => {
    setPlayingTimeInPlayer(Math.floor((newValue * player.duration) / 100));
    if (playerElement) {
      playerElement.seekTo(parseFloat((newValue * player.duration) / 100));
    }
  };

  const handleMidVideoAssessmentData = (property, value) => {
    const tempMidVideoAssessmentData = { ...midVideoAssessmentData };
    tempMidVideoAssessmentData[currentMidVideAssessementIndex][property] =
      value;
    setMidVideoAssessmentData(tempMidVideoAssessmentData);
  };

  let nextVideoInfo = null;
  const currentVideoIndex = videoList.findIndex(({ _id }) => _id === videoId);

  if (currentVideoIndex > -1) {
    if (currentVideoIndex < videoList.length - 1) {
      nextVideoInfo = videoList[currentVideoIndex + 1];
    } else {
      nextVideoInfo = videoList[0];
    }
  }

  const sendRemindLaterDetails = (timestamp = '') => {
    const api = '/video/update-watch-later';
    const requestObj = {
      videoId,
      timestamp: timestamp
        ? moment(timestamp).format('YYYY-MM-DDTHH:mm:ss:SSZ')
        : '',
    };
    sendRemindLaterRequest(
      api,
      REMIND_LATER_REQUEST,
      REMIND_LATER_SUCCESS,
      REMIND_LATER_FAIL,
      {
        ...requestObj,
      },
      '',
      'PUT',
      null,
      'isReelService'
    );
  };
  useEffect(() => {
    if (subtitleS3BucketUrl) {
      axios({
        method: 'get',
        url: subtitleS3BucketUrl,
        responseType: 'blob',
      }).then(function (response) {
        const vttConverter = new VTTConverter(response.data);
        vttConverter
          .getURL()
          .then(function (url) {
            if (url) {
              setSubtitleUrl(url);
            }
          })
          .catch(function (err) {
            console.error(err);
          });
      });
    }
  }, [subtitleS3BucketUrl]);

  /**
   * Send theatre page analytics data for AWS Kinesis
   *
   * @param {string} redirectPath
   */
  const sendAnalytics = async redirectPath => {
    const playedTimeRounded = [
      ...new Set(playedTimeStamps.map(timeStamp => Math.floor(timeStamp))),
    ];

    const skippedTimeStampData = playedTimeRounded
      .slice(1)
      .map((x, i) => ({
        timeStamp: `${secondsToMMSS(playedTimeRounded[i])}-${secondsToMMSS(x)}`,
        differenceInSeconds: x - playedTimeRounded[i],
      }))
      .filter(
        ({ differenceInSeconds }) =>
          differenceInSeconds > 2 || differenceInSeconds < 0
      );

    await sendVideoAnalytics({
      videoId,
      videoTitle: videoPlayFetchData?.title,
      groupId: groupId || '',
      totalVideoDuration: secondsToMMSS(player?.duration),
      totalWatchTime: secondsToMMSS(
        player?.duration -
          skippedTimeStampData.reduce(
            (sumOfDifference, nextValue) =>
              sumOfDifference + nextValue.differenceInSeconds,
            0
          )
      ),
      lastWatchedTimeStamp: secondsToMMSS(
        skippedTimeStampData.length
          ? playedTimeRounded[playedTimeRounded.length - 1]
          : player?.duration
      ),
      skippedTimeStamps: skippedTimeStampData,
      type: 'THEATRE_VIDEO_VIEW',
    });

    if (redirectPath) {
      history.push(redirectPath);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={`theatre ${playerType}`}>
      <div
        className={`theatre__player ${playerScreen} ${
          isMidVideoAssessmentVisible ? 'disable_click' : ''
        }`}
      >
        {isVideoPlayStarted && (
          <div className="theatre__controllers">
            <p className="btn--back" onClick={() => sendAnalytics('/home')}>
              Back to Home
            </p>
            {window.innerWidth < 1500 ? (
              <Range
                step={player.duration / 100}
                min={0}
                max={100}
                values={
                  typeof playingTimeInPlayer === 'number'
                    ? [(playingTimeInPlayer * 100) / player.duration]
                    : [0]
                }
                onChange={values => {
                  handleSliderChange(values[0]);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                    }}
                    className="theatre-video-slider-track"
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                    }}
                    className="theatre-video-slider"
                  />
                )}
              />
            ) : (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <Slider
                    value={
                      typeof playingTimeInPlayer === 'number'
                        ? (playingTimeInPlayer * 100) / player.duration
                        : 0
                    }
                    onChange={(event, value) => handleSliderChange(value)}
                    aria-labelledby="input-slider"
                  />
                </Grid>
              </Grid>
            )}
            <div
              id="playElement"
              className="theatre__controllers_play theatre__controllers--item"
            >
              <div onClick={() => playVideo()}>
                {isPlay ? (
                  <img
                    src={pause}
                    alt="pause-icon"
                    className="dark-icon icon--pause"
                  />
                ) : (
                  <img
                    src={play}
                    alt="play-icon"
                    className="dark-icon icon--play"
                  />
                )}
              </div>
            </div>

            <div className="theatre__controllers_rewind theatre__controllers--item">
              <img
                src={rewind}
                alt="rewind-icon"
                onClick={() => {
                  if (
                    !isMidVideoAssessmentVisible &&
                    playingTimeInPlayer - 10 > 0
                  ) {
                    playerElement.seekTo(parseFloat(playingTimeInPlayer - 10));
                  }
                }}
                className="dark-icon icon--rewind"
              />
            </div>

            <div className="theatre__controllers_forward theatre__controllers--item">
              <img
                src={forward}
                alt="forward-icon"
                onClick={() => {
                  if (
                    !isMidVideoAssessmentVisible &&
                    playingTimeInPlayer + 10 < player.duration
                  ) {
                    playerElement.seekTo(parseFloat(playingTimeInPlayer + 10));
                  }
                }}
                className="dark-icon icon--forward"
              />
            </div>

            <div className="theatre__controllers_volume-icon theatre__controllers--item">
              {isAudioOn ? (
                <img
                  src={volume}
                  alt="volume-icon"
                  className="dark-icon icon--volume"
                  onClick={() => muteVideoSound(false)}
                />
              ) : (
                <img
                  src={volume_mute}
                  alt="volume-mute"
                  className="dark-icon icon--mute"
                  onClick={() => muteVideoSound(true)}
                />
              )}
            </div>

            <div className="theatre__controllers_volume  theatre__controllers--item">
              <div className="slidecontainer">
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.001"
                  value={playerVolume}
                  className="slider"
                  id="myRange"
                  onChange={handleChangePlayerVolume}
                ></input>
              </div>
            </div>

            <div className="theatre__player_playTime">
              <div className="theatre__player_startTime">
                {timeConverter(Math.trunc(playingTimeInPlayer || 0))}
              </div>
              <span> / </span>
              <div className="theatre__player_endTime">
                {timeConverter(Math.trunc(player.duration || 0))}
              </div>
            </div>

            <div className="theatre__controllers_title  theatre__controllers--item">
              {/* <span>Sample Video :</span> */}
              {videoPlayFetchData && videoPlayFetchData.title}
            </div>

            <div className="theatre__controllers_info theatre__controllers--item">
              <img
                src={info}
                alt="info-icon"
                className="icon--info"
                onClick={() =>
                  setShowVideoInfo(showVideoInfo => !showVideoInfo)
                }
              />
            </div>

            <div
              className="theatre__controllers_watchlater theatre__controllers--item"
              onClick={() => setWatchedLaterVideoId(videoId)}
            >
              <img
                src={watchlater}
                alt="watchlater-icon"
                className="dark-icon icon--watchlater"
              />
            </div>

            <div
              className="theatre__controllers_next theatre__controllers--item"
              onClick={() => {
                if (nextVideoInfo) {
                  history.push(
                    `/theatre${groupId ? `/${groupId}` : ''}/${
                      nextVideoInfo._id
                    }`
                  );
                  window.location.reload();
                }
              }}
            >
              {/* <Link to={`/theatre/${parseInt(match.params.id) + 1}`}> */}
              <img
                src={next}
                alt="next-icon"
                className="dark-icon icon--next"
              />
              <img
                src={next_light}
                alt="next-icon"
                className="light-icon icon--next"
              />
              {/* </Link> */}

              <div
                className="theatre__controllers_next-hover theatre__controllers--item"
                // onClick={() => {
                //   if (nextVideoInfo) {
                //     history.push(`/theatre${groupId ? `/${groupId}` : ''}/${nextVideoInfo._id}`);
                //   }
                // }}
              >
                <div
                  className="theatre__controllers_next-hover--thumbnail"
                  style={{
                    backgroundImage: `url(${
                      nextVideoInfo?.previewImgUrl || defaultVideo
                    })`,
                  }}
                ></div>
              </div>
            </div>

            <div className="theatre__controllers_subtitle theatre__controllers--item">
              <img
                src={subtitle}
                alt="subtitle-icon"
                className="dark-icon icon--subtitle"
              />
              <img
                src={subtitle_light}
                alt="subtitle-icon"
                className="light-icon icon--subtitle"
              />
            </div>

            <div className="theatre__controllers_quality  theatre__controllers--item">
              <div className={`theatre__controllers_menu ${hdMenu}`}>
                <ul>
                  <li onClick={() => handleChangePlayerQuality(1080)}>1080p</li>
                  <li onClick={() => handleChangePlayerQuality(720)}>720p</li>
                  <li onClick={() => handleChangePlayerQuality(576)}>576p</li>
                </ul>
              </div>

              <div className="svg" onClick={() => openHdMenu()}>
                <img
                  src={resolution}
                  alt="resolution-icon"
                  className="dark-icon icon--resolution"
                />
                {/*<svg className="icon icon--red">
                  <use
                    xlinkHref={require('../../assets/images/sprite/sprite.svg') + '#icon-quality'}
                  ></use>
                </svg>*/}
              </div>
            </div>

            <div className="theatre__controllers_fullScreen  theatre__controllers--item">
              <div onClick={() => fullScreen()}>
                <img
                  src={fullscreen}
                  alt="fullscreen-icon"
                  className="dark-icon icon--fullscreen"
                />
                <img
                  src={fullscreen_light}
                  alt="fullscreen-icon"
                  className="light-icon icon--fullscreen"
                />
              </div>
            </div>
          </div>
        )}

        {videoPlayFetchData &&
        videoPlayFetchData.videoUrl &&
        (!subtitleS3BucketUrl || (subtitleS3BucketUrl && subtitleUrl)) ? (
          <ReactPlayer
            id="reactPlayer"
            ref={elem => addRefToPlayerElement(elem)}
            config={
              subtitleUrl
                ? {
                    file: {
                      tracks: [
                        {
                          kind: 'subtitles',
                          src: subtitleUrl,
                          srcLang: 'en',
                          default: true,
                        },
                      ],
                      attributes: { controlsList: 'nodownload' },
                    },
                  }
                : {}
            }
            width="100%"
            height="auto%"
            playsinline
            // url="https://www.youtube.com/watch?v=jCGY9oJrSh0&ab_channel=HowtoADHD"
            url={convertToCdnUrl(videoPlayFetchData.videoUrl)}
            onPlay={() => setIsVideoPlayStarted(true)}
            onReady={() => {
              setVideoPlayerLoaded(true);
              if (!isIOSDevice) {
                setTimeout(() => {
                  setPlayerVolume(0.5);
                  setIsPlay(true);
                  setIsAudioOn(true);
                }, 1500);
              }
            }}
            playing={
              videoPlayFetchData && videoPlayFetchData.videoUrl && isPlay
            }
            muted={!isAudioOn}
            volume={playerVolume}
            onProgress={({ playedSeconds }) => {
              setPlayedTimeStamps(prevPlayedSeconds => {
                prevPlayedSeconds.push(playedSeconds);
                return prevPlayedSeconds;
              });
              setVideoPlayingTime(videoPlayingTime + 1);
              setPlayingTimeInPlayer(Math.floor(playedSeconds));
              if (midVideoTriggerTimes.includes(Math.floor(playedSeconds))) {
                setMidVideoAssessmentVisible(true);
                setCurrentMidVideAssessementIndex(
                  midVideoTriggerTimes.indexOf(Math.floor(playedSeconds))
                );
                setIsPlay(false);
              }
              if (
                !isAdminView &&
                !isSendWatchLaterCount &&
                videoPlayingTime === 45
              ) {
                postVideoCount();
                setIsSendWatchLaterCount(true);
              }
            }}
            onDuration={duration => {
              setTheatreData({
                ...theatreData,
                player: { duration: Math.floor(duration) },
              });
            }}
            onEnded={() => {
              setIsPlay(false);
              sendAnalytics();
              setVideoPlayingTime(-2);
              if (!isSendWatchLaterCount && !isAdminView) {
                postVideoCount();
                setIsSendWatchLaterCount(true);
              }
              if (endVideoAssessmentInfo) {
                setEndVideoAssessmentData(endVideoAssessmentInfo);
              }
            }}
            className="theatre__video-player"
            onContextMenu={event => event.preventDefault()}
          />
        ) : null}

        <div className="admin-theater--icons">
          <img src={editWhite} alt="edit-icon" className="icon--edit-white" />
          <hr />
          <img src={warning} alt="warning-icon" className="icon--warning" />
          <hr />
          <img
            src={deleteIconWhite}
            alt="delete-icon"
            className="icon--delete-white"
          />
          <hr />
          <img src={upload} alt="upload-icon" className="icon--upload" />
        </div>
        {isMidVideoAssessmentVisible ? (
          <MidVideoAssessment
            assessmentId={
              midVideoAssessmentData[currentMidVideAssessementIndex]._id
            }
            midVideoQuestions={
              midVideoAssessmentData[currentMidVideAssessementIndex].questions
            }
            isMidVideoQuesionEnd={
              midVideoAssessmentData[currentMidVideAssessementIndex]
                .isMidVideoQuesionEnd
            }
            isAllAnswersCorrect={
              midVideoAssessmentData[currentMidVideAssessementIndex]
                .isAllAnswersCorrect
            }
            isMidVideoAssessmentAttempt={
              midVideoAssessmentData[currentMidVideAssessementIndex]
                .isMidVideoAssessmentAttempt
            }
            hanldeMidVideoEnd={() => {
              setIsPlayStart(false);
              playerElement.seekTo(playingTimeInPlayer + 1);
              setIsPlay(true);
              setMidVideoAssessmentVisible(false);
            }}
            handleMidVideoStart={() => {
              setIsPlayStart(true);
              playerElement.seekTo(
                midVideoAssessmentData[currentMidVideAssessementIndex].startTime
              );
              setIsPlay(true);
              setMidVideoAssessmentVisible(false);
            }}
            handleContinueMidVideo={() => {
              setIsPlayStart(false);
              setMidVideoAssessmentVisible(false);
              playerElement.seekTo(playingTimeInPlayer + 1);
              setIsPlay(true);
            }}
            handleMideoVideoEnd={status =>
              handleMidVideoAssessmentData('isMidVideoQuesionEnd', status)
            }
            handleResult={result => {
              handleMidVideoAssessmentData('isAllAnswersCorrect', result);
              console.log(result, 'result');
            }}
            handleAttemptExam={() =>
              handleMidVideoAssessmentData('isMidVideoAssessmentAttempt', true)
            }
            videoId={videoId}
          />
        ) : null}
      </div>

      {/* Hidden for applying new modal a solution for summary scrolling bug. */}
      {/* <div className="theatre__tutor-info">
        <div className="theatre__tutor-info_image">
          <div></div>
        </div> */}
      {/* Description and Author should come inside the div below*/}
      {/* <div className="theatre__tutor-info_name">{author}</div>
        <div className="theatre__tutor-info_description">{description}</div>
      </div> */}

      <div className="theatre__info-contact">
        <div className="theatre__info">
          <div className="theatre__info_tab">
            <div className="theatre__info_tab-title_wrapper">
              <div
                className={`theatre__info_tab-title ${activeD}`}
                onClick={() => clickOnTab('summary')}
              >
                {t('Summary')}
              </div>
              <div
                className={`theatre__info_tab-title ${activeQ}`}
                onClick={() => clickOnTab('policies')}
              >
                {t('Documents')}
              </div>
            </div>
            <div className="theatre__info_tab_data_wrapper">
              {videoPlayFetchData &&
              videoPlayFetchData.summaries &&
              videoPlayFetchData.summaries.length &&
              videoPlayFetchData.summaries.filter(({ notes }) => notes)
                .length ? (
                <div className={`theatre__info_tab_data ${activeD}`}>
                  <Scrollbars
                    noDefaultStyles={false}
                    style={{ height: 500, width: 300 }}
                    ref={elem => (scrollbar = elem)}
                    thumbYProps={{ className: 'thumbY' }}
                    trackXProps={{ className: 'trackX' }}
                  >
                    {listItems}
                  </Scrollbars>
                </div>
              ) : null}
              <div className={`theatre__info_tab_data ${activeQ}`}>
                <Scrollbar className="theatre__info_scroll">
                  <Element name="test7" className="" id="containerElement">
                    {DocumentList}
                  </Element>
                </Scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
      {watchedLaterVideoId && (
        <WatchReminderModal
          onHandleConfirm={value => {
            const date = new Date(
              value.year,
              value.month,
              value.date,
              value.meridiem === 'AM' ? value.hr : parseInt(value.hr) + 12,
              value.min,
              value.sec
            );
            sendRemindLaterDetails(date);
            setWatchedLaterVideoId(null);
          }}
          onHandleExit={() => {
            sendRemindLaterDetails();
            setWatchedLaterVideoId(null);
          }}
          onHandleCancel={() => {
            setWatchedLaterVideoId(null);
          }}
        />
      )}
      {endVideoAssessmentData && (
        <WatchReminderModal
          endVideoAssessmentData={endVideoAssessmentData}
          onHandleConfirm={() => {
            history.push(
              `/assessment/${endVideoAssessmentData._id}/true/${videoId}`
            );
          }}
          onHandleExit={() => setEndVideoAssessmentData(null)}
          onHandleCancel={() => {
            !isSendWatchLaterCount && !isAdminView && postVideoCount();
            setEndVideoAssessmentData(null);
          }}
        />
      )}
      {pdfFileUrl && (
        <ViewPDFModal
          pdfTitle={pdfFileUrl.title}
          pdfUrl={pdfFileUrl.url}
          allowDownload={pdfFileUrl.allowDownload}
          handleClose={() => {
            setPdfFileUrl('');
            const { player } = theatreData;
            if (player.currentTime !== 0) {
              playVideo();
            }
          }}
        />
      )}
      {videoPlayFetchData && showVideoInfo && (
        <VideoInfoModal
          title={videoPlayFetchData.title}
          authorName={author}
          description={description}
          handleClose={() => setShowVideoInfo(showVideoInfo => !showVideoInfo)}
        />
      )}
    </div>
  );
};

export default Theatre;

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ImageDocumentAsssessmentViewer = ({
  history,
  content = {},
  addToWatchLater,
  removeWatchLater,
  addToFavorite,
  removeFavorite,
  groupId,
  showDocument,
}) => {
  const { t } = useTranslation();
  const {
    _id = null,
    imageUrl = '',
    tileImageUrl = '',
    previewImgUrl = '',
    title = '',
    description = '',
    isPublished,
    type = '',
    assignUrl = null,
    isFavourite,
    isWatchLater,
    documentUrl,
    download,
  } = content;

  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;
  const defaultDocument = themeInfo?.documentImageUrl;
  const defaultAssessment = themeInfo?.defaultAssessmentImageUrl;

  const loadPreviewImage = (contentType, isError) => {
    switch (contentType) {
      case 'IMAGE':
        return imageUrl && !isError ? imageUrl : defaultVideo;

      case 'ASSESSMENT':
        return tileImageUrl && !isError ? tileImageUrl : defaultAssessment;

      case 'DOCUMENT':
        return previewImgUrl && !isError ? previewImgUrl : defaultDocument;

      default:
        return defaultVideo;
    }
  };

  return (
    <div>
      <section className="player_section assessmentplayer">
        <div className="container">
          <div className="player_section--wrapper policy">
            <div
              className={`player_section__video ${
                !isPublished ? 'unpublished' : ''
              }`}
            >
              <img
                src={loadPreviewImage(type, false)}
                alt="assessment"
                onError={event => {
                  event.target.onerror = null;
                  event.target.src = loadPreviewImage(type, true);
                }}
                onClick={() => {
                  if (type === 'ASSESSMENT') {
                    history.push(`/assessment/${_id}`);
                  } else if (type === 'DOCUMENT') {
                    showDocument(documentUrl, download);
                  }
                }}
              />
            </div>
            <div className="player_section__info">
              <div className="player_section__title">{title}</div>
              <div className="player_section__synopsis">{description}</div>

              {!groupId && type === 'ASSESSMENT' && (
                <div className="player_section__controls">
                  {!isFavourite ? (
                    <div
                      className="player_section__controls--thumb-up likeBtn"
                      onClick={() => addToFavorite()}
                    >
                      <svg className="icon icon--white">
                        <use
                          xlinkHref={
                            require('../../assets/images/sprite/sprite.svg') +
                            '#icon-thumb-up'
                          }
                        ></use>
                      </svg>
                    </div>
                  ) : (
                    <div
                      className="player_section__controls--thumb-down likeBtn"
                      onClick={() => removeFavorite()}
                    >
                      <svg className="icon icon--white">
                        <use
                          xlinkHref={
                            require('../../assets/images/sprite/sprite.svg') +
                            '#icon-thumb-down'
                          }
                        ></use>
                      </svg>
                    </div>
                  )}

                  <div className="player_section__controls--myList">
                    {!isWatchLater ? (
                      <div
                        className={`player_section__controls--myList-add`}
                        onClick={() => addToWatchLater()}
                      >
                        <svg className="icon icon--white">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-mylist-add'
                            }
                          ></use>
                        </svg>
                      </div>
                    ) : (
                      <div
                        className={`player_section__controls--myList-added`}
                        onClick={() => removeWatchLater()}
                      >
                        <svg className="icon icon--white">
                          <use
                            xlinkHref={
                              require('../../assets/images/sprite/sprite.svg') +
                              '#icon-mylist-added'
                            }
                          ></use>
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="player_section__readmore">
                {assignUrl && (
                  <button
                    className="btn btn--primary"
                    onClick={() => window.open(assignUrl, '_blank')}
                  >
                    {t('Read More')}
                  </button>
                )}
                {type === 'DOCUMENT' && (
                  <button
                    className="btn btn--primary"
                    onClick={() => showDocument(documentUrl, download)}
                  >
                    {t('View Document')}
                  </button>
                )}
                {type === 'ASSESSMENT' && (
                  <button
                    className="btn btn--primary"
                    onClick={() => history.push(`/assessment/${_id}`)}
                  >
                    {t('View Assessment')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImageDocumentAsssessmentViewer;

import React from 'react';
import { useTranslation } from 'react-i18next';

const SettingsNavigation = ({ view, changeView }) => {
  const { t } = useTranslation();
  return (
    <div className="notification-screen__nav">
      <ul>
        {/* Functionality still not implemented */}
        {/* <li
          className={`${view === 'GROUP_SETTINGS' ? 'active' : ''}`}
          onClick={() => changeView('GROUP_SETTINGS')}
        >
          {t('Groups')}
        </li> */}
        <li
          className={`${view === 'NOTIFICATION_SETTINGS' ? 'active' : ''}`}
          onClick={() => changeView('NOTIFICATION_SETTINGS')}
        >
          {t('Notification Settings')}
        </li>
        {/* Functionality still not implemented */}
        {/* <li
          className={`${view === 'OTHER_SETTINGS' ? 'active' : ''}`}
          onClick={() => changeView('OTHER_SETTINGS')}
        >
          {t('Settings')}
        </li> */}
      </ul>
    </div>
  );
};

export default React.memo(SettingsNavigation);

import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import policyCategory from '../../Helper/policyCategory';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MiniDocument from './MiniDocument';

import leftArrow from '../../assets/images/svg-images/reel-left-arrow.svg';
import leftArrow_light from '../../assets/images/svg-images/reel-left-arrow-light.svg';
import rightArrow from '../../assets/images/svg-images/reel-right-arrow.svg';
import rightArrow_light from '../../assets/images/svg-images/reel-right-arrow-light.svg';

import deleteIcon_white from '../../assets/images/svg-images/icon-delete-white.svg';
import edit_white from '../../assets/images/svg-images/icon-edit-white.svg';
import move_up from '../../assets/images/svg-images/icon-moveup.svg';
import move_down from '../../assets/images/svg-images/icon-movedown.svg';
import { ToolTipConfig } from '../../Common/ToolTipConfig';
import { useSelector } from 'react-redux';

const DocumentReel = ({
  handleDelete,
  documentReel,
  groupId,
  deleteReelPermission,
}) => {
  const { t } = useTranslation();
  const [isMiniVideoHover, setIsMiniVideoHover] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);

  const { category = [], reelName = '', _id = '' } = documentReel;
  const { themeInfo } = useSelector(state => state?.auth);
  const defaultDocumentCategory = themeInfo?.documentCategoryImageUrl;

  const documentCategories = category;
  const history = useHistory();

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <div className="slick-next" onClick={onClick}>
        {/*<svg className="icon icon--red">
          <use xlinkHref={require("../../assets/images/sprite/sprite.svg") + "#icon-left-arrow"}></use>
        </svg>*/}
        <img
          src={rightArrow}
          alt="rightArrow-icon"
          className="dark-icon icon--rightArrow"
        />
        <img
          src={rightArrow_light}
          alt="rightArrow-icon"
          className="light-icon icon--rightArrow"
        />
      </div>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <div className="slick-prev" onClick={onClick}>
        {/* <svg className="icon icon--red">
          <use xlinkHref={require("../../assets/images/sprite/sprite.svg") + "#icon-left-arrow"}></use>
        </svg> */}
        <img
          src={leftArrow}
          alt="leftArrow-icon"
          className="dark-icon icon--leftArrow"
        />
        <img
          src={leftArrow_light}
          alt="leftArrow-icon"
          className="light-icon icon--leftArrow"
        />
      </div>
    );
  };

  const settings = {
    className: 'center',
    dots: false,
    infinite: documentCategories.length > 5,
    slidesToScroll: 5,
    slidesToShow: 5,
    speed: 500,
    // lazyLoad: true,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // afterChange: currentSlide => {
    //   console.log(currentSlide, 'currentSlide');
    //   if (typeof currentSlide === 'number') {
    //     setSliderIndex(currentSlide / 5);
    //   }
    // },
    beforeChange: (current, next) => setSliderIndex(next / 5),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const documentList = () =>
    documentCategories && documentCategories.length
      ? documentCategories.map((documentCategory, key) => {
          return (
            <div className="featured-video__item" key={key}>
              <MiniDocument
                title={documentCategory.name}
                handlePlayerHover={() => setIsMiniVideoHover(!isMiniVideoHover)}
                history={history}
                reelId={_id}
                categoryId={documentCategory._id}
                groupId={groupId}
                image={documentCategory.image || defaultDocumentCategory}
              />
            </div>
          );
        })
      : [];

  return (
    <div className={`featured-video__info item-policy`}>
      <div
        className="admin-policy--icon"
        onClick={() =>
          history.push(`/create-document/group/${groupId}/reel/${_id}`)
        }
      >
        <div className="icon--pdf"></div>
        <p>{t('Add Documents')}</p>
      </div>
      <div className={`featured-video`}>
        <div
          className={`featured-video__title ${
            isMiniVideoHover ? '' : 'moveup'
          }`}
        >
          {/* <div className="admin-reel-position--icons">
            <img src={move_up} alt="move_up-icon" className="icon--move_up" />
            <img src={move_down} alt="move_down-icon" className="icon--move_down" />
          </div> */}
          <p>
            {reelName}
            <span
              onClick={() => {
                history.push(
                  `/reel-bulk-view${
                    groupId ? `/${groupId}` : ''
                  }/document/${_id}`
                );
              }}
            >
              {t('Explore All')}
            </span>
            <span className="admin-reel-title--icons">
              <img
                src={edit_white}
                alt="edit-icon"
                className="icon--edit"
                title={t(ToolTipConfig.adminHome.documentReel.editAbove)}
                onClick={() =>
                  history.push(
                    `/group-dashboard${
                      groupId ? `/${groupId}` : ''
                    }/reels/document/${_id}`
                  )
                }
              />
              <hr />
              {deleteReelPermission && (
                <img
                  src={deleteIcon_white}
                  alt="delete-icon"
                  className="icon--delete"
                  title={t(ToolTipConfig.adminHome.documentReel.binAbove)}
                  onClick={() => handleDelete(_id)}
                />
              )}
            </span>
          </p>
          <ul className="theatre__assessment-pagination">
            {documentCategories.length > 5
              ? documentCategories.map((documentCategory, index) => {
                  if (!index || !(index % 5)) {
                    const paginationNo = index ? index / 5 : index;
                    return (
                      <li
                        className={sliderIndex === paginationNo ? 'active' : ''}
                        key={uuidv4()}
                      />
                    );
                  }
                })
              : null}
          </ul>
        </div>

        <Slider {...settings}>{documentList()}</Slider>
      </div>
    </div>
  );
};
export default DocumentReel;

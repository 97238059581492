import React from 'react';

/**
 * CheckBoxPanel: Display parent and child boxes as a field
 *
 * props :
 *      getParentCheckedValue (f()): Return parent checkbox onChanged value.
 *      parentCheckBox: Parent check box data.
 *      checkedParentValues: Checked values for the parent checkbox.
 *      getChildrenCheckedValues (f()): Return children checkbox onChanged value.
 *      checkedChildrenValues: Checked values for the children checkboxes.
 *
 *      example for props:
 *          parentCheckBox (object): {}
 *              {
 *                  id: string,
 *                  label: string,
 *                  childrenCheckBoxes (array): []
 *                  [
 *                      id: string,
 *                      label: string,
 *                  ]
 *              }
 *          checkedParentValues (array): []
 *              ["1", "2", "3"]
 *          checkedChildrenValues (two-dimensional array): [[], []]
 *              [
 *                  'parentId1': ["1", "2", "3"],
 *              ]
 */

const CheckBoxPanel = (props) => {
  return (
    <div>
        <div>
            {renderParentCheckBox(props)}
        </div>
        <div>
            {renderChildrenCheckBoxes(props)}
        </div>
    </div>
  );
}

const renderParentCheckBox = (props) => {
  const {
    parentCheckBox,
    checkedParentValues,
    getParentCheckedValues,
  } = props;

  return (
    <>
      <input
        type="checkbox"
        className="form-input--checkbox"
        onChange={() => getParentCheckedValues(parentCheckBox.id)}
        checked={
            checkedParentValues.includes(
                parentCheckBox.id
            )
        }
      />
      <label onClick={() => getParentCheckedValues(parentCheckBox.id)}>
        {parentCheckBox.label}
      </label>
    </>
  );
}

const renderChildrenCheckBoxes = (props) => {
  const {
    getChildrenCheckedValues,
    checkedChildrenValues,
    parentCheckBox,
  } = props;

  const childrenCheckBoxes = parentCheckBox.childrenCheckBoxes;

  return(
    <ul>
      {
        Object.keys(childrenCheckBoxes).map((key, index) => (
          <li key={index}>
            <input
              type="checkbox"
              className="form-input--checkbox"
              checked={
                checkedChildrenValues[parentCheckBox.id].includes(
                  childrenCheckBoxes[key].id
                )
              }
              onChange={
                () => getChildrenCheckedValues(
                childrenCheckBoxes[key].id,
                parentCheckBox.id
              )}
            />
            <label
              onClick={
              () => getChildrenCheckedValues(
                childrenCheckBoxes[key].id,
                parentCheckBox.id
              )}
            >
              {childrenCheckBoxes[key].label}
            </label>
          </li>
        ))
      }
    </ul>
  );
}

export default CheckBoxPanel;

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { post, get, put, deleteRequest } from '../api/commonApi';
import {
  CLEAR_SESSION,
  FETCH_SSO_USER_VERIFY_REQUEST,
} from '../redux/actionTypes/auth';
import { downloadFile } from '../Helper/SystemManager';

function useApi(redirectPath) {
  const dispatch = useDispatch();
  const [actions, setActions] = useState();
  const [baseUrl, setBaseUrl] = useState(null);
  const [postValues, setPostValues] = useState({});
  const [history, setRedirect] = useState({});
  const [method, setMethod] = useState('POST');
  const [isEnCoded, setEnCoded] = useState(false);
  const [portType, setPortType] = useState('');
  const [customResponse, setCustomResponse] = useState(null);

  const accessToken = useSelector(state => state?.auth?.access_token);
  const callAPI = () => {
    switch (method) {
      case 'POST':
        return post(
          baseUrl,
          accessToken,
          postValues,
          isEnCoded,
          portType,
          dispatch
        );

      case 'GET':
        return get(
          baseUrl,
          accessToken,
          portType,
          customResponse?.responseType
        );

      case 'PUT':
        return put(baseUrl, accessToken, postValues, isEnCoded, portType);

      case 'DELETE':
        return deleteRequest(baseUrl, accessToken, postValues, portType);

      default:
        return get(
          baseUrl,
          accessToken,
          portType,
          customResponse?.responseType
        );
    }
  };

  useEffect(() => {
    if (baseUrl) {
      const requestApiCall = async () => {
        dispatch({ type: actions.request, customResponse });
        try {
          const result = await callAPI();

          if (result.status === 200 || result.status === 201) {
            // File Downloading custom response type
            if (customResponse?.responseType === 'arraybuffer') {
              downloadFile(
                result.data,
                result.headers['content-type'],
                customResponse?.fileName
              );
            }

            dispatch({
              type: actions.success,
              payload: result.data,
              customResponse,
            });
            if (redirectPath && Object.values(history).length) {
              history.push(redirectPath);
            }
          } else if (result.status === 202) {
            dispatch({
              type: actions.success,
              payload: result.data,
              customResponse,
            });
          } else if (result.status === 204) {
            dispatch({
              type: actions.success,
              payload: [],
              customResponse,
            });
          } else {
            if (result.status === 401) {
              Auth.signOut();
              dispatch({
                type: CLEAR_SESSION,
              });
            } else if (
              actions.request === FETCH_SSO_USER_VERIFY_REQUEST &&
              result?.response?.status === 409
            ) {
              dispatch({
                type: actions.fail,
                payload:
                  result.data &&
                  (result.data.details || result.data.message || result.data),
                customResponse,
                status: result?.response?.status,
              });
            }

            dispatch({
              type: actions.fail,
              payload:
                result.data &&
                (result.data.details || result.data.message || result.data),
              customResponse,
            });
          }
        } catch (error) {
          dispatch({
            type: actions.fail,
            payload: error,
            customResponse,
          });
        }
        setBaseUrl(null);
      };

      requestApiCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUrl]);

  const getServiceType = portType => {
    switch (portType) {
      case 'isUserService':
        return process.env.REACT_APP_USER_MANAGEMENT_SERVICE;

      case 'isGroupService':
        return process.env.REACT_APP_DIVISION_SERVICE;

      case 'isReelService':
        return process.env.REACT_APP_VIDEO_SERVICE;

      case 'isNotificationService':
        return process.env.REACT_APP_NOTIFICATION_SERVICE;

      case 'isWebsocketService':
        return process.env.REACT_APP_WEBSOCKET_SERVICE;
      case 'isRmsService':
        return process.env.REACT_APP_RMS_SERVICE;

      default:
        return '';
    }
  };

  const submitForm = (
    url,
    request,
    success,
    fail,
    postValues,
    history,
    method,
    isEnCoded = false,
    portType,
    customResponse
  ) => {
    const newActions = { request, success, fail };
    const mergedActions = { ...actions, ...newActions };

    setMethod(method);
    setActions(mergedActions);
    setPostValues(postValues);
    setRedirect(history);
    setEnCoded(isEnCoded);
    setPortType(getServiceType(portType));
    setCustomResponse(customResponse);
    setBaseUrl(url);
  };

  return [submitForm];
}

export default useApi;

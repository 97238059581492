import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ConfirmActionModal({
  title,
  message,
  handleSuccess,
  handleClose,
  isLoading,
  isConfirmationModal,
  isJustErrorMessage = false,
}) {
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup createreel">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t(title)}</h3>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{t(message)}</label>
          </div>
          {!isJustErrorMessage ? (
            <div className="reel-view--popup-row">
              <button
                className={`
                btn btn--primary
                ${isLoading ? 'btn--loader' : ''}
              `}
                onClick={handleSuccess}
              >
                {t(!isConfirmationModal ? 'Yes' : 'Confirm')}
              </button>
              {!isLoading && (
                <button className="btn btn--secondary" onClick={handleClose}>
                  {t(!isConfirmationModal ? 'No' : 'Cancel')}
                </button>
              )}
            </div>
          ) : (
            <div className="reel-view--popup-row">
              <button className={`btn btn--primary`} onClick={handleSuccess}>
                {t('Okay')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

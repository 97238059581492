import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactSupportModal = ({ handleClose, message, supportEmail }) => {
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup message">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('Sorry...')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            ×
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{t(message)}</label>
          </div>
          <div className="reel-view--popup-row">
            <div className="reel-view--popup-field">
              <button
                className="btn btn--primary"
                onClick={() =>
                  (window.location.href = `mailto:${supportEmail}`)
                }
              >
                {t('Contact Support')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSupportModal;

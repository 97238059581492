import { useState, useEffect } from 'react';
import { UserPrivilegesForRoles } from '../Common/UserPrivileges';
import { useSelector } from 'react-redux';

const SUPER_ADMIN_ID = 4;

function usePrivilegesApi (privilegeType, groupId) {
  const { groupInfo, roleList } = useSelector((state) => state?.auth?.userData);
  const [havePrivilege, setHavePrivileges] = useState(true);
  const { allGroupsFetchData } = useSelector((state) => state?.group);

  useEffect(() => {
    if (!Array.isArray(allGroupsFetchData) && allGroupsFetchData && Array.isArray(roleList)) {
      const tempRoleList = [...roleList];
      if (groupId) {
        if (tempRoleList.map(({id})=> id).includes(SUPER_ADMIN_ID)) {
          setHavePrivileges(true);
        } else {
          if (groupInfo && groupInfo.length) {
            checkUserPrivilegesByGroup(privilegeType, groupId);
          } else {
            setHavePrivileges(false);
          }
        }
      } else {
        if (tempRoleList.map(({id})=> id).includes(SUPER_ADMIN_ID)) {
          setHavePrivileges(true);
        } else {
          if (groupInfo && groupInfo.length) {
            checkUserPrivileges(privilegeType);
          } else {
            setHavePrivileges(false);
          }
        }
      }
    }
  }, [groupId, groupInfo, roleList, allGroupsFetchData]);

  const checkUserPrivileges = (privilege) => {
    const tempGroupInfo = [...groupInfo];

    const userRoleIds = tempGroupInfo.map((group) => {
      return group.userData.map((data) => data.roleReferenceId);
    });

    setHavePrivileges(userRoleIds.flat().some(id => {
      return checkPermission(id, privilege);
    }));
  }

  const checkUserPrivilegesByGroup = (privilege, groupId) => {
    const tempGroupInfo = [...groupInfo];

    const userRoleIds = tempGroupInfo
      .filter(({ divisionId }) => groupId === divisionId)
      .map((group) => {
        return group.userData.map((data) => data.roleReferenceId);
      });

    const isHavePrivileges = userRoleIds.flat().some(id => {
      return checkPermission(id, privilege);
    });

    if (!isHavePrivileges) {
      const isParentHaveAccesss = checkIfParentNodesHasPermission(tempGroupInfo, groupId, privilege);

      setHavePrivileges(isParentHaveAccesss);
    } else {
      setHavePrivileges(isHavePrivileges);
    }
  }

  const checkPermission = (roleId, privilegeType) => {
    // Checking for super admin.
    if (roleId === 4) {
      return true;
    }

    // Checking if given id is included in user privileges.
    if (!UserPrivilegesForRoles[roleId]) {
      return false;
    }

    // Checking if privilege type is a string value.
    if (typeof privilegeType !== 'string') {
      return false;
    }

    return UserPrivilegesForRoles[roleId].includes(privilegeType);
  };

  const checkIfParentNodesHasPermission = (tempGroupInfo, nodeId, privilege) => {
    return findParentNodeIds(allGroupsFetchData, nodeId)?.some((parentId) => {
      return tempGroupInfo.some(
        ({ divisionId, userData }) =>
          parentId === divisionId &&
          userData.length &&
          userData.some(
            (roleInfo) => {
              return checkPermission(roleInfo.roleReferenceId, privilege);
            }
          )
      );
    });
  };

  const findParentNodeIds = (node, nodeId) => {
    if (node._id === nodeId) {
      return [];
    }

    if (Array.isArray(node.children)) {
      for (let data of node.children) {
        const childNode = findParentNodeIds(data, nodeId);

        if (Array.isArray(childNode)) {
          return [node._id].concat(childNode);
        }
      }
    }
  };

  return havePrivilege;
};

export default usePrivilegesApi;

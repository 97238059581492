import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Auth from '@aws-amplify/auth';
import {
  CHANGE_ADMIN_VIEW,
  ADD_ADMIN_USER,
} from '../../../redux/action/actionTypes';
import { CLEAR_SESSION } from '../../../redux/actionTypes/auth';

import upload from '../../../assets/images/svg-images/icon-upload.svg';
import { systemConfig } from '../../../Common/SystemConstConfigs';

const { admin, user, editor, superAdmin, moderator } =
  systemConfig.roleIdByName;

const NavDropdown = ({
  isAssessmentInprogress,
  isAdminUser,
  isAdminView,
  isSuperAdmin,
  handleAminView,
  handleUserView,
  history,
  isMobileView,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeManagePage, setActiveManagePage] = useState(
    history?.location?.pathname
  );
  const [isLightMode, setIsLightMode] = useState(false);
  const [isUserView, setIsUserView] = useState(false);

  const { roleList } = useSelector(state => state?.auth?.userData);

  const isRmsPage = useMemo(
    () => activeManagePage.includes('/rms'),
    [activeManagePage]
  );

  useEffect(() => {
    if (roleList) {
      const tempRoleList = [...roleList];
      const roleIds = tempRoleList.map(({ id }) => id);

      if (
        roleIds.includes(user.roleReferenceId) ||
        roleIds.includes(superAdmin.roleReferenceId)
      ) {
        setIsUserView(true);
      }
    }
  }, [roleList]);

  const renderDropdown = () => {
    return (
      <div className="userdropdown">
        <div className="userdropdown--wrapper">
          {!isRmsPage && (
            <div className="userdropdown--settings">
              <div className="userdropdown--settings_manage">
                <h4>{t('Manage')}</h4>
                <ul>
                  <li>
                    <a
                      className={
                        activeManagePage === '/profile' ? 'active' : ''
                      }
                      onClick={() => {
                        setActiveManagePage('/profile');
                        history.push('/profile');
                      }}
                    >
                      {t('Profile')}
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        activeManagePage === '/settings' ? 'active' : ''
                      }
                      onClick={() => {
                        setActiveManagePage('/settings');
                        history.push('/settings/notification');
                      }}
                    >
                      {t('Settings')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="userdropdown--settings_loggedin">
                <h4>{t('Logged in as')}</h4>
                <ul>
                  {(isAdminUser || isSuperAdmin) && (
                    <li
                      onClick={() => {
                        history.push('/node-tree');
                        handleAminView();
                      }}
                    >
                      <a className={isAdminView ? 'active' : ''}>
                        {t('Admin Mode')}
                      </a>
                    </li>
                  )}
                  {!isSuperAdmin && isUserView && (
                    <li
                      onClick={() => {
                        history.push('/home');
                        handleUserView();
                      }}
                    >
                      <a className={isAdminView ? '' : 'active'}>
                        {t('User Mode')}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
          <div className="userdropdown--logout">
            <Link
              key={0}
              style={{
                pointerEvents: isAssessmentInprogress ? 'none' : 'all',
              }}
              to={'/'}
              onClick={() => {
                Auth.signOut();
                dispatch({
                  type: CLEAR_SESSION,
                });
                dispatch({
                  type: CHANGE_ADMIN_VIEW,
                  payload: false,
                });
                dispatch({
                  type: ADD_ADMIN_USER,
                  payload: false,
                });
              }}
            >
              <img src={upload} alt="upload-icon" className="icon--upload" />
              {t('logout')}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return !isMobileView ? (
    <div className="dropdown">
      <div className="dropdown-content">{renderDropdown()}</div>
    </div>
  ) : (
    renderDropdown()
  );
};

export default NavDropdown;

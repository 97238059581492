import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Tree from 'react-d3-tree';
import useCheckPermission from '../../../Hooks/useCheckPermission';
import { PrivilegeTypes } from '../../../Common/UserPrivileges';
import useFetchDataApi from '../../../Hooks/useFetchDataApi';
import AdminDashboardSelectionDropDown from '../../../Components/Admin/AdminDashboardSelectionDropDown';

const SUPER_ADMIN_ID = 4;

const NodeTree = ({ history }) => {
  const createGroupPrivilege = useCheckPermission(PrivilegeTypes.GROUP.CREATE_USER_GROUP);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [translate, setTranslate] = useState({
    x: 1,
    y: 1,
  });

  const { allGroupsFetchData } = useSelector((state) => state?.group);
  const { userName, groupInfo, roleList } = useSelector((state) => state?.auth?.userData);
  const [fetchData] = useFetchDataApi();

  useEffect(() => {
    if (roleList && roleList.length > 0) {
      setIsSuperAdmin([...roleList].map(({id})=> id).includes(SUPER_ADMIN_ID));
    }
  }, [roleList]);

  useEffect(() => {
    fetchData({ type: 'ALL_GROUPS'});
  }, []);

  useEffect(() => {
    const treeWrapper = document.getElementById('treeWrapper');
    const dimensions = treeWrapper.getBoundingClientRect();
    setTranslate({
      x: dimensions.width / 2,
      y: dimensions.height / 8,
    });
  }, [allGroupsFetchData]);

  const svgSquare = {
    shape: 'rect',
    shapeProps: {
      width: 130,
      height: 70,
      x: -65,
      y: -30,
      rx: 10,
    },
  };

  const findParentNodeIds = (node, nodeId) => {
    // eslint-disable-next-line no-underscore-dangle
    if (node._id === nodeId) {
      return [];
    }

    if (Array.isArray(node.children)) {
      for (let data of node.children) {
        const childNode = findParentNodeIds(data, nodeId);

        if (Array.isArray(childNode)) {
          return [node._id].concat(childNode);
        }
      }
    }
  };

  const checkIfParentNodesHasPermission = (nodeId) => {
    return findParentNodeIds(allGroupsFetchData, nodeId).some((parentId) => {
      return checkNodeHasPermission(parentId);
    });
  };

  const checkNodeHasPermission = (nodeId) => {
    return groupInfo.some(
      ({ divisionId, userData }) =>
        nodeId === divisionId &&
        userData.length &&
        userData.some(
          (roleInfo) =>
            roleInfo.roleReferenceId === 1 ||
            roleInfo.roleReferenceId === 2 ||
            roleInfo.roleReferenceId === 5
        )
    );
  };

  const findUserHasPermission = (nodeId) => {
    if (
      isSuperAdmin ||
      checkNodeHasPermission(nodeId) ||
      checkIfParentNodesHasPermission(nodeId)
    ) {
      return true;
    }
    return false;
  };

  const NodeLabel = ({ className, nodeData }) => {
    return (
      <div className={className}>
        <h2
          onClick={() => {
            findUserHasPermission(nodeData._id) && history.push(`/home/${nodeData._id}`);
          }}
        >
          {nodeData.title}
        </h2>
        {(isSuperAdmin || (createGroupPrivilege && createGroupPrivilege[nodeData._id])) && (
          <button
            className="nodeTree--chart-button expand"
            onClick={() =>
              findUserHasPermission(nodeData._id) &&
              history.push(`/group-dashboard/create-group/${nodeData._id}`)
            }
          >
            {nodeData._collapsed ? 'expand' : ''}
          </button>
        )}
        {/*<button onClick={() => console.log("click plus")}>+</button>*/}
      </div>
    );
  };
  return (
    <div className="nodeTree">
      <div className="container">
        <div className="nodeTree--wrapper">
          <div className="nodeTree--page-title all-users-table--page-title">
            <div className="all-users-table--page-selection">
              <AdminDashboardSelectionDropDown history={history} selected={'GROUPS'}/>
            </div>
          </div>
          {/* <div className="nodeTree--chart">{nodeData && <OrgChartComponent data={nodeData} />}</div> */}
          <div className="nodeTree--chart" id="treeWrapper">
            {allGroupsFetchData && (
              <Tree
                data={[allGroupsFetchData]}
                nodeSvgShape={svgSquare}
                orientation="vertical"
                pathFunc="step"
                allowForeignObjects
                collapsible={false}
                translate={translate}
                transitionDuration={0}
                nodeLabelComponent={{
                  render: <NodeLabel className="nodeTree--chart-nodetitle" />,
                  foreignObjectWrapper: {
                    width: 110,
                    height: 60,
                    x: -55,
                    y: -30,
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NodeTree;

export const FETCH_BANNER_AND_FEATURED_DATA_REQUEST = "FETCH_BANNER_AND_FEATURED_DATA_REQUEST";
export const FETCH_BANNER_AND_FEATURED_DATA_SUCCESS = "FETCH_BANNER_AND_FEATURED_DATA_SUCCESS";
export const FETCH_BANNER_AND_FEATURED_DATA_FAIL = "FETCH_BANNER_AND_FEATURED_DATA_FAIL";

export const CREATE_BANNER_FEATURED_IMAGE_REQUEST = 'CREATE_BANNER_FEATURED_IMAGE_REQUEST';
export const CREATE_BANNER_FEATURED_IMAGE_SUCCESS = 'CREATE_BANNER_FEATURED_IMAGE_SUCCESS';
export const CREATE_BANNER_FEATURED_IMAGE_FAIL = 'CREATE_BANNER_FEATURED_IMAGE_FAIL';

export const FETCH_BANNER_FEATURED_IMAGE_BY_ID_REQUEST = 'CREATE_BANNER_FEATURED_IMAGE_REQUEST';
export const FETCH_BANNER_FEATURED_IMAGE_BY_ID_SUCCESS = 'FETCH_BANNER_FEATURED_IMAGE_BY_ID_SUCCESS';
export const FETCH_BANNER_FEATURED_IMAGE_BY_ID_FAIL = 'FETCH_BANNER_FEATURED_IMAGE_BY_ID_FAIL';

export const UPDATE_BANNER_FEATURED_IMAGE_REQUEST = 'UPDATE_BANNER_FEATURED_IMAGE_REQUEST';
export const UPDATE_BANNER_FEATURED_IMAGE_SUCCESS = 'UPDATE_BANNER_FEATURED_IMAGE_SUCCESS';
export const UPDATE_BANNER_FEATURED_IMAGE_FAIL = 'UPDATE_BANNER_FEATURED_IMAGE_FAIL';

export const SUSPEND_IMAGE_REQUEST = 'SUSPEND_IMAGE_REQUEST';
export const SUSPEND_IMAGE_SUCCESS = 'SUSPEND_IMAGE_SUCCESS';
export const SUSPEND_IMAGE_FAIL = 'SUSPEND_IMAGE_FAIL';

export const DELETE_BANNER_FEATURED_IMAGE_REQUEST = 'DELETE_BANNER_FEATURED_IMAGE_REQUEST';
export const DELETE_BANNER_FEATURED_IMAGE_SUCCESS = 'DELETE_BANNER_FEATURED_IMAGE_SUCCESS';
export const DELETE_BANNER_FEATURED_IMAGE_FAIL = 'DELETE_BANNER_FEATURED_IMAGE_FAIL';

export const FETCH_IMAGE_URL_BY_ID_REQUEST = 'FETCH_IMAGE_URL_BY_ID_REQUEST';
export const FETCH_IMAGE_URL_BY_ID_SUCCESS = 'FETCH_IMAGE_URL_BY_ID_SUCCESS';
export const FETCH_IMAGE_URL_BY_ID_FAIL = 'FETCH_IMAGE_URL_BY_ID_FAIL';

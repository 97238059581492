import {
  CREATE_GROUP_INITIAL,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  FETCH_ALL_GROUPS_REQUEST,
  FETCH_ALL_GROUPS_SUCCESS,
  FETCH_ALL_GROUPS_FAIL,
  FETCH_GROUP_USER_ID_REQUEST,
  FETCH_GROUP_USER_ID_SUCCESS,
  FETCH_GROUP_USER_ID_FAIL,
  ADD_USER_TO_GROUP_REQUEST,
  ADD_USER_TO_GROUP_SUCCESS,
  ADD_USER_TO_GROUP_FAIL,
  FETCH_GROUP_DETAILS_REQUEST,
  FETCH_GROUP_DETAILS_SUCCESS,
  FETCH_GROUP_DETAILS_FAIL,
  FETCH_USER_LIST_REQUEST,
  FETCH_USER_LIST_SUCCESS,
  FETCH_USER_LIST_FAIL,
  FETCH_PARENT_GROUP_ROLES_BY_USER_REQUEST,
  FETCH_PARENT_GROUP_ROLES_BY_USER_SUCCESS,
  FETCH_PARENT_GROUP_ROLES_BY_USER_FAIL,
  SET_CURRENT_GROUP_ID,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  FETCH_SELF_ASSIGNED_ENABLED_GROUPS_REQUEST,
  FETCH_SELF_ASSIGNED_ENABLED_GROUPS_SUCCESS,
  FETCH_SELF_ASSIGNED_ENABLED_GROUPS_FAIL,
  FETCH_SIGNUP_ENABLED_GROUPS_SUCCESS,
  FETCH_SIGNUP_ENABLED_GROUPS_REQUEST,
  FETCH_SIGNUP_ENABLED_GROUPS_FAIL,
  FETCH_GROUPS_BY_USER_REQUEST,
  FETCH_GROUPS_BY_USER_SUCCESS,
  FETCH_GROUPS_BY_USER_FAIL,
} from '../actionTypes/Group';

const groupReducer = (
  state = {
    createGroupLoading: false,
    createGroupSuccessData: null,
    createGroupError: null,
    allGroupsFetchLoading: false,
    allGroupsFetchData: [],
    allGroupsFetchFail: null,
    groupUsersIdFetchLoading: false,
    groupUsersIdFetchData: [],
    groupUsersIdFetchFail: null,
    addUserToGroupLoading: false,
    addUserToGroupSuccess: false,
    addUserToGroupFail: false,
    groupDetailsLoading: false,
    groupDetailsData: null,
    groupDetailsFail: null,
    parentGroupRolesLoading: false,
    parentGroupRolesData: null,
    parentGroupRolesFail: null,
    userListFetchLoading: false,
    userListFetchData: [],
    userListFetchFail: null,
    currentGroupId: null,
    deleteGroupLoading: false,
    deleteGroupSuccess: null,
    deleteGroupError: null,
    selfAssignedEnabledGroupsLoading: false,
    selfAssignedEnabledGroups: [],
    selfAssignedEnabledGroupsError: null,
    signupEnabledGroupsLoading: false,
    signupEnabledGroups: [],
    signupEnabledGroupsError: null,
    groupsByUserLoading: false,
    groupsByUser: [],
    groupsByUserError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_GROUP_INITIAL:
      return {
        ...state,
        createGroupLoading: false,
        createGroupSuccessData: null,
        createGroupError: null,
      };
    case CREATE_GROUP_REQUEST:
      return {
        ...state,
        createGroupLoading: true,
        createGroupSuccessData: null,
        createGroupError: null,
      };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        createGroupLoading: false,
        createGroupSuccessData: action.payload,
        createGroupError: null,
      };
    case CREATE_GROUP_FAIL:
      return {
        ...state,
        createGroupLoading: false,
        createGroupSuccessData: null,
        createGroupError: true,
      };

    case FETCH_ALL_GROUPS_REQUEST:
      return {
        ...state,
        allGroupsFetchLoading: true,
        allGroupsFetchData: [],
        allGroupsFetchFail: null,
      };
    case FETCH_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        allGroupsFetchLoading: false,
        allGroupsFetchData: action.payload,
        allGroupsFetchFail: null,
      };
    case FETCH_ALL_GROUPS_FAIL:
      return {
        ...state,
        allGroupsFetchLoading: false,
        allGroupsFetchData: [],
        allGroupsFetchFail: true,
      };

    case FETCH_GROUP_USER_ID_REQUEST:
      return {
        ...state,
        groupUsersIdFetchLoading: true,
        groupUsersIdFetchData: [],
        groupUsersIdFetchFail: null,
      };
    case FETCH_GROUP_USER_ID_SUCCESS:
      return {
        ...state,
        groupUsersIdFetchLoading: false,
        groupUsersIdFetchData: action.payload,
        groupUsersIdFetchFail: null,
      };
    case FETCH_GROUP_USER_ID_FAIL:
      return {
        ...state,
        groupUsersIdFetchLoading: false,
        groupUsersIdFetchData: [],
        groupUsersIdFetchFail: true,
      };

    case ADD_USER_TO_GROUP_REQUEST:
      return {
        ...state,
        addUserToGroupLoading: true,
        addUserToGroupSuccess: false,
        addUserToGroupFail: false,
      };
    case ADD_USER_TO_GROUP_SUCCESS:
      return {
        ...state,
        addUserToGroupLoading: false,
        addUserToGroupSuccess: true,
        addUserToGroupFail: false,
      };
    case ADD_USER_TO_GROUP_FAIL:
      return {
        ...state,
        addUserToGroupLoading: false,
        addUserToGroupSuccess: false,
        addUserToGroupFail: true,
      };

    case FETCH_GROUP_DETAILS_REQUEST:
      return {
        ...state,
        groupDetailsLoading: false,
        groupDetailsData: null,
        groupDetailsFail: null,
      };
    case FETCH_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        groupDetailsLoading: false,
        groupDetailsData: action.payload,
        groupDetailsFail: null,
      };
    case FETCH_GROUP_DETAILS_FAIL:
      return {
        ...state,
        groupDetailsLoading: false,
        groupDetailsData: null,
        groupDetailsFail: null,
      };

    case FETCH_USER_LIST_REQUEST:
      return {
        ...state,
        userListFetchLoading: true,
        userListFetchData: [],
        userListFetchFail: null,
      };
    case FETCH_USER_LIST_SUCCESS:
      return {
        ...state,
        userListFetchLoading: false,
        userListFetchData: action.payload,
        userListFetchFail: null,
      };
    case FETCH_USER_LIST_FAIL:
      return {
        ...state,
        userListFetchLoading: false,
        userListFetchData: [],
        userListFetchFail: true,
      };

    case FETCH_PARENT_GROUP_ROLES_BY_USER_REQUEST:
      return {
        ...state,
        parentGroupRolesLoading: true,
        parentGroupRolesData: null,
        parentGroupRolesFail: null,
      };
    case FETCH_PARENT_GROUP_ROLES_BY_USER_SUCCESS:
      return {
        ...state,
        parentGroupRolesLoading: false,
        parentGroupRolesData: action.payload,
        parentGroupRolesFail: null,
      };
    case FETCH_PARENT_GROUP_ROLES_BY_USER_FAIL:
      return {
        ...state,
        parentGroupRolesLoading: false,
        parentGroupRolesData: null,
        parentGroupRolesFail: true,
      };

    case SET_CURRENT_GROUP_ID:
      return {
        ...state,
        currentGroupId: action.payload,
      };

    case DELETE_GROUP_REQUEST:
      return {
        ...state,
        deleteGroupLoading: true,
        deleteGroupSuccess: null,
        deleteGroupError: null,
      };

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        deleteGroupLoading: false,
        deleteGroupSuccess: action.payload,
        deleteGroupError: null,
      };

    case DELETE_GROUP_FAIL:
      return {
        ...state,
        deleteGroupLoading: false,
        deleteGroupSuccess: null,
        deleteGroupError: action.payload,
      };

    case FETCH_SELF_ASSIGNED_ENABLED_GROUPS_REQUEST:
      return {
        ...state,
        selfAssignedEnabledGroupsLoading: true,
        selfAssignedEnabledGroups: [],
        selfAssignedEnabledGroupsError: null,
      };

    case FETCH_SELF_ASSIGNED_ENABLED_GROUPS_SUCCESS:
      return {
        ...state,
        selfAssignedEnabledGroupsLoading: false,
        selfAssignedEnabledGroups: action?.payload?.data || [],
        selfAssignedEnabledGroupsError: null,
      };

    case FETCH_SELF_ASSIGNED_ENABLED_GROUPS_FAIL:
      return {
        ...state,
        selfAssignedEnabledGroupsLoading: false,
        selfAssignedEnabledGroups: [],
        selfAssignedEnabledGroupsError: action?.payload,
      };

    case FETCH_SIGNUP_ENABLED_GROUPS_REQUEST:
      return {
        ...state,
        signupEnabledGroupsLoading: true,
        signupEnabledGroups: [],
        signupEnabledGroupsError: null,
      };

    case FETCH_SIGNUP_ENABLED_GROUPS_SUCCESS:
      return {
        ...state,
        signupEnabledGroupsLoading: false,
        signupEnabledGroups: action?.payload?.data || [],
        signupEnabledGroupsError: null,
      };

    case FETCH_SIGNUP_ENABLED_GROUPS_FAIL:
      return {
        ...state,
        signupEnabledGroupsLoading: false,
        signupEnabledGroups: [],
        signupEnabledGroupsError: action?.payload,
      };

    case FETCH_GROUPS_BY_USER_REQUEST:
      return {
        ...state,
        groupsByUserLoading: true,
        groupsByUser: [],
        groupsByUserError: null,
      };

    case FETCH_GROUPS_BY_USER_SUCCESS:
      return {
        ...state,
        groupsByUserLoading: false,
        groupsByUser: action?.payload?.data || [],
        groupsByUserError: null,
      };

    case FETCH_GROUPS_BY_USER_FAIL:
      return {
        ...state,
        groupsByUserLoading: false,
        groupsByUser: [],
        groupsByUserError: action?.payload,
      };

    default:
      return state;
  }
};

export default groupReducer;

import React, { useState, useEffect, useRef, memo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

import { ADD_SMOOTH_TRANSITION_MODE } from '../../../redux/action/actionTypes';
import clock from '../../../assets/images/svg-images/icon-clock-white.svg';

import edit_white from '../../../assets/images/svg-images/icon-edit-white.svg';
import stats from '../../../assets/images/svg-images/icon-stats.svg';
import warning from '../../../assets/images/svg-images/icon-warning.svg';
import publish from '../../../assets/images/svg-images/icon-upload.svg';
import deleteIcon_white from '../../../assets/images/svg-images/icon-delete-white.svg';

import { convertToCdnUrl } from '../../../Helper/convertToCdnUrl';
import { getBannerFeaturedEditRoute } from '../../../Helper/SystemManager';
import { ToolTipConfig } from '../../../Common/ToolTipConfig.js';
import useAnalytics from '../../../Hooks/useAnalytics';

const MiniPlayer = ({
  videoInfo,
  isVideoInfoOpen,
  openVideoInfoBox,
  isBulkView = false,
  handlePlayerHover = null,
  handleStatics,
  groupId,
  reelId,
  categoryId,
  hanldeIsPublishedStatus,
  handleDelete,
  deleteVideoPermission,
  editVideoPermission,
  suspenPublishVideoPermission,
  addToWatchLater,
  removeWatchLater,
  addToFavorite,
  removeFavorite,
}) => {
  const { t } = useTranslation();
  const sendMouseOverVideoTime = useAnalytics();
  const [miniPlayer, setMiniPlayer] = useState({
    isMouseHover: false,
    player: {},
    audio: false,
    isLikeButtonClick: false,
    dislikeBtn: 'active',
    likeBtn: 'active',
    myList: 'inactive',
  });
  const [isPlay, setIsPlay] = useState(false);
  const [isVideoLoad, setIsVideoLoad] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [isMiniPlayerActive, setIsMiniPlayerActive] = useState('active');
  const [mouseHoverOverStartTime, setMouseHoverOverStartTime] = useState(0);
  const [mouseHoverOverEndTime, setMouseHoverOverEndTime] = useState(0);
  const [isVideoTileClicked, setIsVideoTileClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { themeInfo } = useSelector(state => state?.auth);
  const defaultVideo = themeInfo?.defaultVideoImageUrl;

  const playerInfo = useSelector(
    state => state.video && state.video.playerInfo
  );
  const {
    previewVideoUrl,
    previewImgUrl,
    title,
    isPublished,
    _id = null,
    videoId = null,
    dueDate,
    isFavourite,
    isWatchLater,
    isWatched,
  } = videoInfo;

  const mandatoryReelId = videoInfo.reelId;
  const mandatoryCategoryId = videoInfo.categoryId;

  const backgroundImage = () => {
    const imgUrl = previewImgUrl
      ? convertToCdnUrl(previewImgUrl)
      : defaultVideo;
    const backgroundBg = {
      backgroundImage: 'url(' + imgUrl + ')',
    };
    if (process.env.REACT_APP_IS_REEL_PREVIEW_DISABLE) {
      backgroundBg.display = 'block';
    }

    return backgroundBg;
  };
  /**
   * On mouse hover on mini player decresc sounds to zero and play video
   */

  useEffect(() => {
    const timeSpentOnHover = mouseHoverOverEndTime - mouseHoverOverStartTime;

    if (timeSpentOnHover > 3000 && !isVideoTileClicked) {
      // Time spent on hovering video without clicked.
      sendMiniVideoTileAnaylitics(false, timeSpentOnHover);

      setMouseHoverOverEndTime(0);
      setMouseHoverOverStartTime(0);
    }
  }, [mouseHoverOverEndTime]);

  const sendMiniVideoTileAnaylitics = (isClicked, timeSpentOnHover) => {
    sendMouseOverVideoTime({
      videoId: videoId || _id?._id || _id,
      categoryId: categoryId || mandatoryCategoryId,
      reelId: reelId || mandatoryReelId,
      groupId: groupId || '',
      title: title,
      timeInMilliseconds: timeSpentOnHover,
      type: 'HOVERED_OVER_VIDEO',
      isClicked,
    });
  };

  const handleHoverOn = () => {
    setMouseHoverOverStartTime(new Date().getTime());

    setIsPlay(true);

    setMiniPlayer({
      ...miniPlayer,
      isMouseHover: true,
      audio: false,
    });

    if (handlePlayerHover) {
      handlePlayerHover(true);
    }
  };

  /**
   * On mouse out on mini player  play pause
   */
  const handleHoverOff = () => {
    setMouseHoverOverEndTime(new Date().getTime());

    setIsPlay(false);
    setMiniPlayer({
      ...miniPlayer,
      isMouseHover: false,
    });

    if (handlePlayerHover) {
      handlePlayerHover(false);
    }
  };

  /**
   * On click on down arrow on mini player and open video info box
   */
  const onClickDownArrow = () => {
    openVideoInfoBox({ ...videoInfo });
  };

  /**
   * @param {boolean} boolean
   *  true -mute clip / false - unmute clip
   */
  const muteVideoSound = boolean => {
    if (boolean) {
      setMiniPlayer({
        ...miniPlayer,
        audio: false,
      });
    } else {
      setMiniPlayer({
        ...miniPlayer,
        audio: true,
      });
    }
  };

  /**
   *
   * @param {boolean} boolean
   * true -add clip to my list / false - remove clip from my list
   */
  const addToMyList = isAddToWatchLater => {
    if (isAddToWatchLater) {
      addToWatchLater(videoId || _id);
    } else {
      removeWatchLater(videoId || _id);
    }
  };

  const likeBtnClick = isLike => {
    if (isLike) {
      addToFavorite(videoId || _id);
    } else {
      removeFavorite(videoId || _id);
    }
  };

  const { id } = videoInfo;

  const { audio } = miniPlayer;
  let audioOn = 'active';
  let audioOff = '';
  if (audio) {
    audioOn = 'active';
    audioOff = '';
  } else {
    audioOn = '';
    audioOff = 'active';
  }

  useEffect(() => {
    if (
      isVideoInfoOpen &&
      playerInfo &&
      playerInfo.length &&
      playerInfo[0].id === videoInfo.id &&
      playerInfo[0].preview
    ) {
      setIsMiniPlayerActive('inactive');
    } else {
      setIsMiniPlayerActive('active');
    }
  }, [isVideoInfoOpen, playerInfo]);

  const playVideoInTheatre = () => {
    dispatch({
      type: ADD_SMOOTH_TRANSITION_MODE,
    });

    // Time spent on hovering video before clicked.
    const timeSpentOnHover = new Date().getTime() - mouseHoverOverStartTime;
    sendMiniVideoTileAnaylitics(true, timeSpentOnHover);
    setIsVideoTileClicked(true);

    if (isBulkView) {
      const root = document.getElementById('root');
      root.className = 'fade';
    }
    setTimeout(() => {
      history.push(`/theatre${groupId ? `/${groupId}` : ''}/${videoId || _id}`);
    }, 2000);
  };

  return (
    <>
      <div
        // ref={elem => addRefToMiniPlayerElement(elem)}
        className={`miniPlayer ${isMiniPlayerActive}`}
        onMouseEnter={handleHoverOn}
        onMouseLeave={handleHoverOff}
      >
        <div className="miniPlayer__teaserimg" style={backgroundImage()}></div>

        {isPlay && !process.env.REACT_APP_IS_REEL_PREVIEW_DISABLE && (
          <ReactPlayer
            className="miniPlayer__player-wrapper"
            width="100%"
            height="auto"
            muted={!audio}
            url={convertToCdnUrl(previewVideoUrl)}
            playing={isVideoLoad && isPlay}
            onReady={() => setIsVideoLoad(true)}
          />
        )}

        <div className="miniPlayer__content miniPlayer__mini-player-items">
          <div className="miniPlayer__info-action">
            <div
              className="miniPlayer__click"
              onClick={playVideoInTheatre}
            ></div>
            <div className="miniPlayer__info">
              <div
                className="miniPlayer__info--play-icon"
                onClick={playVideoInTheatre}
              >
                <svg className="icon icon--orange">
                  <use
                    xlinkHref={
                      require('../../../assets/images/sprite/sprite.svg') +
                      '#icon-play-solid'
                    }
                  ></use>
                </svg>
              </div>
              {/* <div className="miniPlayer__info--stats">{title}</div> */}
              {/* <div className='miniPlayer__info--info'>{info}</div> */}
            </div>

            <div className="miniPlayer__action">
              {!process.env.REACT_APP_IS_REEL_PREVIEW_DISABLE && (
                <div
                  className={`miniPlayer__action-icon miniPlayer__action--audio-off  ${audioOff}`}
                  onClick={() => muteVideoSound(false)}
                >
                  <svg className="icon icon--red">
                    <use
                      xlinkHref={
                        require('../../../assets/images/sprite/sprite.svg') +
                        '#icon-audio-off'
                      }
                    ></use>
                  </svg>
                </div>
              )}
              <div
                className={`miniPlayer__action-icon miniPlayer__action--audio-on ${audioOn}`}
                onClick={() => muteVideoSound(true)}
              >
                <svg className="icon icon--red">
                  <use
                    xlinkHref={
                      require('../../../assets/images/sprite/sprite.svg') +
                      '#icon-audio-on'
                    }
                  ></use>
                </svg>
              </div>
              {isFavourite && (
                <div
                  className={`miniPlayer__action-icon miniPlayer__action--thumb-down likeBtn ${!isFavourite}`}
                  onClick={() => likeBtnClick(false)}
                >
                  <svg className="icon icon--red">
                    <use
                      xlinkHref={
                        require('../../../assets/images/sprite/sprite.svg') +
                        '#icon-thumb-down'
                      }
                    ></use>
                  </svg>
                </div>
              )}
              {!isFavourite && (
                <div
                  className={`miniPlayer__action-icon miniPlayer__action--thumb-up likeBtn ${isFavourite}`}
                  onClick={() => likeBtnClick(true)}
                >
                  <svg className="icon icon--red">
                    <use
                      xlinkHref={
                        require('../../../assets/images/sprite/sprite.svg') +
                        '#icon-thumb-up'
                      }
                    ></use>
                  </svg>
                </div>
              )}
              <span
                className={`miniPlayer__action--myList ${
                  isWatchLater ? 'active' : 'inactive'
                }`}
              >
                <div
                  className={`miniPlayer__action-icon myList-add `}
                  onClick={() => addToMyList(true)}
                >
                  <svg className="icon icon--red">
                    <use
                      xlinkHref={
                        require('../../../assets/images/sprite/sprite.svg') +
                        '#icon-mylist-add'
                      }
                    ></use>
                  </svg>
                </div>

                <div
                  className={`miniPlayer__action-icon myList-added`}
                  onClick={() => addToMyList(false)}
                >
                  <svg className="icon icon--red">
                    <use
                      xlinkHref={
                        require('../../../assets/images/sprite/sprite.svg') +
                        '#icon-mylist-added'
                      }
                    ></use>
                  </svg>
                </div>
              </span>
            </div>

            <div className="admin-reel_video--icons">
              {editVideoPermission && (
                <img
                  src={edit_white}
                  alt="edit-icon"
                  className="icon--edit-white"
                  title={t(ToolTipConfig.adminHome.reel.edit)}
                  onClick={() =>
                    history.push(
                      `/edit-video/${getBannerFeaturedEditRoute(videoInfo)}${
                        videoId || _id
                      }`
                    )
                  }
                />
              )}
              {!mandatoryReelId && suspenPublishVideoPermission && (
                <img
                  src={isPublished ? warning : publish}
                  alt={isPublished ? 'warning-icon' : 'publish-icon'}
                  className={isPublished ? 'icon--warning' : 'icon--publish'}
                  title={
                    isPublished
                      ? ToolTipConfig.adminHome.reel.suspend
                      : ToolTipConfig.adminHome.reel.publish
                  }
                  onClick={() => {
                    hanldeIsPublishedStatus('VIDEO', videoId || _id);
                  }}
                />
              )}
              {deleteVideoPermission && (
                <img
                  src={deleteIcon_white}
                  alt="delete-icon"
                  className="icon--delete-white"
                  title={
                    mandatoryReelId
                      ? ToolTipConfig.adminHome.mandatoryReel.bin
                      : ToolTipConfig.adminHome.reel.bin
                  }
                  onClick={() =>
                    handleDelete({
                      type: 'VIDEO',
                      id: videoId || _id,
                      reelId: reelId || mandatoryReelId,
                      categoryId: categoryId || mandatoryCategoryId,
                    })
                  }
                />
              )}
              {!mandatoryReelId && (
                <img
                  src={stats}
                  alt="stats-icon"
                  className="icon--stats"
                  title={t(ToolTipConfig.adminHome.reel.stats)}
                  onClick={() =>
                    history.push(
                      `/group-dashboard/${groupId}/reels/${
                        reelId || mandatoryReelId
                      }/video/${videoId || _id}`
                    )
                  }
                />
              )}
            </div>

            {!isBulkView && (
              <div
                className=" miniPlayer__info-arrow"
                onClick={() => onClickDownArrow()}
              >
                <svg className="icon icon--red">
                  <use
                    xlinkHref={
                      require('../../../assets/images/sprite/sprite.svg') +
                      '#icon-arrow-down'
                    }
                  ></use>
                </svg>
              </div>
            )}
          </div>
          <div
            className={`miniPlayer__info--title ${
              !groupId && isWatched ? 'watched' : ''
            }`}
          >
            {title}
          </div>
          {dueDate && (
            <div className="miniPlayer__info--duedate">
              <img src={clock} alt="clock-icon" className="icon--clock" />
              <span>Due before {moment(dueDate).format('ll')}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MiniPlayer;

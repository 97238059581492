import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import previewimg from '../../../assets/images/sample-img.png';

import {
  CREATE_BANNER_FEATURED_IMAGE_REQUEST,
  CREATE_BANNER_FEATURED_IMAGE_SUCCESS,
  CREATE_BANNER_FEATURED_IMAGE_FAIL,
  UPDATE_BANNER_FEATURED_IMAGE_REQUEST,
  UPDATE_BANNER_FEATURED_IMAGE_SUCCESS,
  UPDATE_BANNER_FEATURED_IMAGE_FAIL,
} from '../../../redux/actionTypes/bannerAndFeatured';
import {
  getAllGroupInfoByCurrentUser,
  capitalizeFirstLetter,
} from '../../../Helper/SystemManager';
import useApi from '../../../Hooks/useApi';
import GroupMultiSelectWithMandatoryDate from '../../../Components/CreateEditComponents/GroupSelect';
import TagSelection from '../../../Components/CreateEditComponents/TagSelection';
import ProceedMessageModal from '../../../Components/Modal/ProceedMessageModal';
import Loader from '../../../Components/Loader/loader';
import { messageConfigs } from '../../../Helper/PopupMessageConfig';
import useFetchDataApi from '../../../Hooks/useFetchDataApi';
import ErrorMessageModal from '../../../Components/Modal/ErrorMessageModal';
import { validateFields } from '../../../Helper/ValidationsManager';
import PreviewImageView from '../../../Components/CreateEditComponents/PreviewImageView';
import MandatoryMark from '../../../Components/validation/MandatoryMark';
import usePrivilegesApi from '../../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../../Common/UserPrivileges';

const groupsObj = {
  groups: [],
};

const initialState = {
  title: '',
  description: '',
  author: '',
  isNativeBannerAndFeatured: true,
  tags: '',
  imageUrl: '',
  assignUrl: '',
  isPublished: '',
  isImageViaUrl: false,
  listOfGroups: [_.clone(groupsObj)],
};

const CreateImage = ({ match, history }) => {
  const { t } = useTranslation();
  const imageType = match.params.type;
  const imageId = match.params.id;
  const groupId = match.params.groupId;
  const isHavePublishAccess = usePrivilegesApi(
    PrivilegeTypes.IMAGE.UPLOAD_IMAGE
  );

  const [imageData, setImageData] = useState(_.cloneDeep(initialState));
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [imageDataFiles, setImageDataFiles] = useState({
    previewImage: '',
  });
  const [isPublishProcess, setIsPublishProcess] = useState(false);
  const [isSaveProcess, setIsSaveProcess] = useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(null);
  const [isUpdateProcess, setIsUpdateProcess] = useState(false);
  const [isValidationError, setIsValidationError] = useState(null);
  const [isValidUrl, setIsValidUrl] = useState(true);

  const { groupInfo, userName } = useSelector(state => state?.auth?.userData);
  const { allGroupsFetchData, allGroupsFetchLoading } = useSelector(
    state => state?.group
  );
  const {
    fetchBannerAndFeaturedImageByIdData,
    creatBannerFeatureImageLoading,
    creatBannerFeatureImageSuccess,
    creatBannerFeatureImageError,
    updateBannerFeatureImageLoading,
    updateBannerFeatureImageSuccess,
    updateBannerFeatureImageError,
  } = useSelector(state => state?.bannerAndFeatured);
  const dispatch = useDispatch();
  const [fetchAllGroups] = useFetchDataApi();
  const [createImage] = useApi();
  const [updateImage] = useApi();
  const [fetchImageDataById] = useFetchDataApi();

  const handleImageFileData = (value, property) => {
    const tempImageDataFiles = { ...imageDataFiles };
    tempImageDataFiles[property] = value;
    setImageDataFiles(tempImageDataFiles);
  };

  const handleImageData = (value, property) => {
    const tempImageData = { ...imageData };
    tempImageData[property] = value;
    setImageData(tempImageData);
  };

  const handleFileAccept = (event, property) => {
    handleImageFileData(event.target.files[0], property);

    let imageDataProperty = 'imageUrl';

    handleImageData(
      URL.createObjectURL(event.target.files[0]),
      imageDataProperty
    );
  };

  useEffect(() => {
    if (selectGroupOptions.length && groupId) {
      const createFromGroup = {
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
            name: selectGroupOptions.find(option => option.value === groupId)
              .label,
          },
        ],
      };

      const intialImageData = { ...imageData };
      intialImageData['listOfGroups'] = [_.clone(createFromGroup)];

      setImageData(intialImageData);
    }
  }, [selectGroupOptions, groupId]);

  useEffect(() => {
    if (creatBannerFeatureImageSuccess) {
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message: isPublishProcess
          ? imageType === 'banner'
            ? messageConfigs.success.image.PUBLISH_BANNER
            : messageConfigs.success.image.PUBLISH_FEATURED
          : imageType === 'banner'
          ? messageConfigs.success.image.SAVE_BANNER
          : messageConfigs.success.image.SAVE_FEATURED,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () =>
        dispatch({ type: CREATE_BANNER_FEATURED_IMAGE_SUCCESS, payload: null });
    }
  }, [creatBannerFeatureImageSuccess]);

  useEffect(() => {
    if (creatBannerFeatureImageError) {
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () =>
        dispatch({ type: CREATE_BANNER_FEATURED_IMAGE_SUCCESS, payload: null });
    }
  }, [creatBannerFeatureImageError]);

  useEffect(() => {
    if (updateBannerFeatureImageSuccess) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message:
          imageType === 'banner'
            ? messageConfigs.success.image.UPDATE_BANNER
            : messageConfigs.success.image.UPDATE_FEATURED,
      });

      return () =>
        dispatch({ type: UPDATE_BANNER_FEATURED_IMAGE_SUCCESS, payload: null });
    }
  }, [updateBannerFeatureImageSuccess]);

  useEffect(() => {
    if (updateBannerFeatureImageError) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      return () =>
        dispatch({ type: UPDATE_BANNER_FEATURED_IMAGE_SUCCESS, payload: null });
    }
  }, [updateBannerFeatureImageError]);

  useEffect(() => {
    setTimeout(() => {
      var allPanels = $('.accordion .admin__adddocument--inner-container');
      $('.accordion h3').unbind();
      $('.accordion h3').click(function () {
        $(this)
          .parent()
          .children('.admin__adddocument--inner-container')
          .slideUp();
        $(this).addClass('closed');
        if (
          $(this)
            .parent()
            .children('.admin__adddocument--inner-container')
            .css('display') == 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__adddocument--inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });
    }, 0);
  });

  useEffect(() => {
    fetchAllGroups({ type: 'ALL_GROUPS' });
  }, []);

  useEffect(() => {
    if (!Array.isArray(allGroupsFetchData) && allGroupsFetchData && groupInfo) {
      const flattenedGroups = getAllGroupInfoByCurrentUser(
        userName,
        groupInfo,
        allGroupsFetchData
      );

      // Constructing select group dropdown values and removing current group
      const organizeSelectOptions = optionsArray => {
        return optionsArray.map(group => {
          return { value: group._id, label: group.title };
        });
      };

      const selectGroupOptions = organizeSelectOptions(flattenedGroups);

      setSelectGroupOptions(selectGroupOptions);

      if (imageId) {
        fetchImageDataById({ type: 'FIND_IMAGE', objectId: imageId });
      }
    }
  }, [allGroupsFetchData]);

  useEffect(() => {
    if (fetchBannerAndFeaturedImageByIdData && imageId) {
      const {
        _id,
        tags,
        author,
        title,
        description,
        assignUrl,
        imageUrl,
        isBanner,
        isFeatured,
        isPublished,
        isImageViaUrl,
        isNativeBannerAndFeatured,
        listOfReelIdCategoryIdAndGroups,
      } = fetchBannerAndFeaturedImageByIdData;

      const selectedGroups = listOfReelIdCategoryIdAndGroups[0].groups.map(
        group => {
          const { _id, dueDate, isMandatory } = group;

          return {
            _id,
            dueDate,
            isMandatory,
            name:
              selectGroupOptions &&
              selectGroupOptions.length &&
              selectGroupOptions.find(option => option.value === _id).label,
          };
        }
      );

      const editImageData = { ...imageData };
      editImageData['_id'] = _id;
      editImageData['title'] = title;
      editImageData['description'] = description;
      editImageData['author'] = author;
      editImageData['isNativeBannerAndFeatured'] = isNativeBannerAndFeatured;
      editImageData['isFeatured'] = isFeatured;
      editImageData['isBanner'] = isBanner;
      editImageData['tags'] = tags.join();
      editImageData['assignUrl'] = assignUrl;
      editImageData['imageUrl'] = imageUrl;
      editImageData['isImageViaUrl'] = isImageViaUrl;
      editImageData['isPublished'] = isPublished;
      editImageData['listOfGroups'] = [{ groups: selectedGroups }];

      setImageData(editImageData);
    }
  }, [fetchBannerAndFeaturedImageByIdData]);

  const {
    title,
    description,
    author,
    isNativeBannerAndFeatured,
    isFeatured,
    isBanner,
    tags,
    assignUrl,
    imageUrl,
    isImageViaUrl,
    listOfGroups,
  } = imageData;
  const { previewImage } = imageDataFiles;

  const handleCreateImage = isPublished => {
    const formErrors = validateFields({
      type: 'IMAGE',
      formData: imageData._id
        ? {
            title,
            listOfGroups,
            assignUrl,
          }
        : {
            title,
            listOfGroups,
            assignUrl,
            bannerFeaturedImageData: imageUrl,
          },
    });

    if (formErrors) {
      setIsValidationError(formErrors);
      return;
    }

    if (!isValidUrl && isImageViaUrl) {
      return;
    }

    const formData = new FormData();

    const imageInfo = {
      title,
      description,
      author,
      isEdit: !!imageId,
      isNativeBannerAndFeatured,
      isFeatured: imageType === 'featured',
      isBanner: imageType === 'banner',
      assignUrl,
      tags: tags.split(','),
      isPublished,
      imageUrl,
      isImageViaUrl: isImageViaUrl,
      listOfReelIdCategoryIdAndGroups: listOfGroups.map(value => {
        const groupData = value.groups.map(group => {
          return {
            _id: group._id,
            dueDate: group.dueDate,
            isMandatory: group.isMandatory,
          };
        });

        return { groups: groupData };
      }),
    };

    if (imageData._id) {
      imageInfo._id = imageData._id;
    }

    formData.append(
      'image',
      new Blob([JSON.stringify(imageInfo)], { type: 'application/json' })
    );

    if (imageData._id) {
      updateImage(
        '/image/add-image',
        UPDATE_BANNER_FEATURED_IMAGE_REQUEST,
        UPDATE_BANNER_FEATURED_IMAGE_SUCCESS,
        UPDATE_BANNER_FEATURED_IMAGE_FAIL,
        formData,
        '',
        'POST',
        null,
        'isReelService'
      );
    } else {
      createImage(
        '/image/add-image',
        CREATE_BANNER_FEATURED_IMAGE_REQUEST,
        CREATE_BANNER_FEATURED_IMAGE_SUCCESS,
        CREATE_BANNER_FEATURED_IMAGE_FAIL,
        formData,
        '',
        'POST',
        null,
        'isReelService'
      );
    }
  };

  const resetFields = () => {
    const resetState = _.cloneDeep(initialState);

    if (groupId && selectGroupOptions) {
      const createFromGroup = {
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
            name: selectGroupOptions.find(option => option.value === groupId)
              .label,
          },
        ],
      };

      resetState['listOfGroups'] = [_.clone(createFromGroup)];
    }

    setImageData(resetState);
  };

  return (
    <div>
      <div
        className={`admin__adddocument loading-screen-parent ${
          allGroupsFetchLoading ? 'setheight' : ''
        }`}
      >
        {allGroupsFetchLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="admin__adddocument--wrapper">
              <div className="admin__adddocument--page-title">
                <p className="btn--back" onClick={() => history.goBack()}>
                  {t('Back to Home')}
                </p>
                <h2>
                  {imageId ? 'Edit' : 'Create'}{' '}
                  {capitalizeFirstLetter(imageType)} {t('Image')}
                </h2>
              </div>
              <div className="admin__adddocument--container">
                <div className="admin__adddocument--left-col">
                  <div className="admin__adddocument--information">
                    <input
                      type="text"
                      id="document-titl"
                      name="document-titl"
                      placeholder={t('Image Title *')}
                      value={title}
                      onChange={e => handleImageData(e.target.value, 'title')}
                    />
                    <input
                      type="text"
                      id="document-description"
                      name="document-description"
                      placeholder={t('Description')}
                      onChange={e =>
                        handleImageData(e.target.value, 'description')
                      }
                    />
                  </div>
                  <div className="admin__adddocument--reels accordion">
                    <h3>
                      {t('Groups')} <MandatoryMark />
                    </h3>
                    <GroupMultiSelectWithMandatoryDate
                      listOfGroups={listOfGroups}
                      selectGroupOptions={selectGroupOptions}
                      handleChange={handleImageData}
                    />
                  </div>
                  <div className="admin__createvideo--documents accordion">
                    <h3>Assign Url</h3>
                    <div className="admin__createvideo--inner-container">
                      <p className="label">URL</p>
                      <input
                        type="text"
                        id="upload-preview-url"
                        name="upload-preview-url"
                        placeholder={t('url')}
                        onChange={e =>
                          handleImageData(e.target.value, 'assignUrl')
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="admin__adddocument--right-col">
                  <PreviewImageView
                    title={'Image'}
                    imageSrc={imageUrl || previewimg}
                    isImageViaUrl={isImageViaUrl}
                    imageUrl={imageUrl}
                    handleData={handleImageData}
                    handleFileAccept={handleFileAccept}
                    isImageViaUrlPropertyName={'isImageViaUrl'}
                    previewImgUrlPropertyName={'imageUrl'}
                    previewImagePropertyName={'previewImage'}
                    setIsValidUrl={setIsValidUrl}
                    isValidUrl={isValidUrl}
                  />
                  <div className="admin__adddocument--tags">
                    <p>{t('Tags')}</p>
                    <TagSelection tags={tags} handleChange={handleImageData} />
                  </div>
                </div>
                <div className="admin__adddocument--buttons">
                  {!imageId ? (
                    <>
                      <button
                        className="btn btn--tertiary"
                        onClick={() => resetFields()}
                      >
                        {t('Reset')}
                      </button>
                      <button
                        className={`
                        btn btn--secondary
                        ${
                          creatBannerFeatureImageLoading && isSaveProcess
                            ? 'btn--loader'
                            : ''
                        }
                      `}
                        onClick={() => {
                          setIsSaveProcess(true);
                          handleCreateImage(false);
                        }}
                      >
                        {t('Save')}
                      </button>
                      {isHavePublishAccess && (
                        <button
                          className={`
                        btn btn--primary btn--last
                        ${
                          creatBannerFeatureImageLoading && isPublishProcess
                            ? 'btn--loader'
                            : ''
                        }
                      `}
                          onClick={() => {
                            setIsPublishProcess(true);
                            handleCreateImage(true);
                          }}
                        >
                          {t('Publish')}
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className={`btn btn--secondary ${
                        updateBannerFeatureImageLoading && isUpdateProcess
                          ? 'btn--loader'
                          : ''
                      }`}
                      onClick={() => {
                        setIsUpdateProcess(true);
                        handleCreateImage(imageData.isPublished);
                      }}
                      disabled={
                        updateBannerFeatureImageLoading && isUpdateProcess
                      }
                    >
                      {t('Update')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isSubmitSuccessModalOpen && (
        <ProceedMessageModal
          isSuccess={isSubmitSuccessModalOpen.isSuccess}
          message={isSubmitSuccessModalOpen.message}
          handleClose={() => setIsSubmitSuccessModalOpen(null)}
          handleSuccess={() => {
            setIsSubmitSuccessModalOpen(null);
            history.goBack();
          }}
          handleError={() => setIsSubmitSuccessModalOpen(null)}
        />
      )}
      {isValidationError && (
        <ErrorMessageModal
          errorData={isValidationError}
          handleSubmit={() => setIsValidationError(null)}
        />
      )}
    </div>
  );
};

export default CreateImage;

import messageConfigs from '../../Helper/PopupMessageConfig';
import {
  ADD_VIDEO_REQUEST,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_FAIL,
  ADD_VIDEO_TO_CURRENT_CATEGORY_REQUEST,
  ADD_VIDEO_TO_CURRENT_CATEGORY_SUCCESS,
  ADD_VIDEO_TO_CURRENT_CATEGORY_FAIL,
  FIND_VIDEO_BY_ID_REQUEST,
  FIND_VIDEO_BY_ID_SUCCESS,
  FIND_VIDEO_BY_ID_FAIL,
  INTIALIZE_VIDEO_REQUEST,
  INTIALIZE_VIDEO_SUCCESS,
  INTIALIZE_VIDEO_FAIL,
  FETCH_VIDEO_TO_PLAY_REQUEST,
  FETCH_VIDEO_TO_PLAY_SUCCESS,
  FETCH_VIDEO_TO_PLAY_FAIL,
  CREATE_VIDEO_REQUEST,
  CREATE_VIDEO_SUCCESS,
  CREATE_VIDEO_FAIL,
  CREATE_VIDEO_RESET,
  SUSPEND_VIDEO_REQUEST,
  SUSPEND_VIDEO_SUCCESS,
  SUSPEND_VIDEO_FAIL,
  FETCH_ALL_VIDEOS_BY_GROUP_REQUEST,
  FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS,
  FETCH_ALL_VIDEOS_BY_GROUP_FAIL,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAIL,
  UPDATE_VIDEO_RESET,
  UPDATE_VIDEO_VIEW_REQUEST,
  UPDATE_VIDEO_VIEW_SUCCESS,
  UPDATE_VIDEO_VIEW_FAIL,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAIL,
  FETCH_VIDEO_STATS_BY_GROUP_REQUEST,
  FETCH_VIDEO_STATS_BY_GROUP_SUCCESS,
  FETCH_VIDEO_STATS_BY_GROUP_FAIL,
  FETCH_VIDEO_STATS_BY_GROUP_RESET,
  UPDATE_FILE_UPLOADING_PERCENTAGE,
  DOWNLOAD_STATS_REQUEST,
  DOWNLOAD_STATS_SUCCESS,
  DOWNLOAD_STATS_FAIL,
} from '../actionTypes/video';

const videoReducer = (
  state = {
    addVideoLoading: false,
    addVideoSuccess: null,
    addVideoError: null,
    addVideoToCategoryLoading: false,
    addVideoToCategorySuccess: null,
    addVideoToCategoryError: null,
    findVideoLoading: false,
    findVideoData: null,
    findVideoError: null,
    intializeVideoLoading: false,
    intializeVideoData: {},
    intializeVideoError: null,
    videoPlayFetchDataLoading: false,
    videoPlayFetchData: {},
    videoPlayFetchDataError: null,
    createVideoLoading: false,
    createVideoSuccess: false,
    createVideoFail: false,
    suspendVideoLoading: false,
    suspendVideoSuccess: null,
    suspendVideoFail: false,
    fetchAllVideosByGroupLoading: false,
    fetchAllVideosByGroupData: null,
    fetchAllVideosByGroupFail: false,
    updateVideoLoading: false,
    updateVideoSuccess: false,
    updateVideoFail: false,
    updateVideoViewsLoading: false,
    updateVideoViewsSuccess: false,
    updateVideoViewsFail: false,
    deleteVideoLoading: false,
    deleteVideoSuccess: null,
    deleteVideoError: null,
    videoStatsByGroupLoading: false,
    videoStatsByGroupData: null,
    videoStatsByGroupError: null,
    fileUploadingPercentage: 0,
    downloadingStatsLoading: false,
    downloadingStatsError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_VIDEO_REQUEST:
      return {
        ...state,
        createVideoLoading: true,
        createVideoSuccess: false,
        createVideoFail: false,
      };

    case CREATE_VIDEO_SUCCESS:
      return {
        ...state,
        createVideoLoading: false,
        createVideoSuccess: true,
        createVideoFail: false,
        fileUploadingPercentage: 0,
      };

    case CREATE_VIDEO_FAIL:
      return {
        ...state,
        createVideoLoading: false,
        createVideoSuccess: false,
        createVideoFail: true,
        fileUploadingPercentage: 0,
      };

    case CREATE_VIDEO_RESET:
      return {
        ...state,
        createVideoLoading: false,
        createVideoSuccess: false,
        createVideoFail: false,
        fileUploadingPercentage: 0,
      };

    case ADD_VIDEO_REQUEST:
      return {
        ...state,
        addVideoLoading: true,
        addVideoSuccess: null,
        addVideoError: null,
      };

    case ADD_VIDEO_SUCCESS:
      return {
        ...state,
        addVideoLoading: false,
        addVideoSuccess: action.payload,
        addVideoError: null,
      };

    case ADD_VIDEO_FAIL:
      return {
        ...state,
        addVideoLoading: false,
        addVideoSuccess: null,
        addVideoError: true,
      };

    case ADD_VIDEO_TO_CURRENT_CATEGORY_REQUEST:
      return {
        ...state,
        addVideoToCategoryLoading: true,
        addVideoToCategorySuccess: null,
        addVideoToCategoryError: null,
      };

    case ADD_VIDEO_TO_CURRENT_CATEGORY_SUCCESS:
      return {
        ...state,
        addVideoToCategoryLoading: false,
        addVideoToCategorySuccess: action.payload,
        addVideoToCategoryError: null,
      };

    case ADD_VIDEO_TO_CURRENT_CATEGORY_FAIL:
      return {
        ...state,
        addVideoToCategoryLoading: false,
        addVideoToCategorySuccess: null,
        addVideoToCategoryError: true,
      };

    case FIND_VIDEO_BY_ID_REQUEST:
      return {
        ...state,
        findVideoLoading: true,
        findVideoData: null,
        findVideoError: null,
      };

    case FIND_VIDEO_BY_ID_SUCCESS:
      return {
        ...state,
        findVideoLoading: false,
        findVideoData: action.payload,
        findVideoError: null,
      };

    case FIND_VIDEO_BY_ID_FAIL:
      return {
        ...state,
        findVideoLoading: false,
        findVideoData: null,
        findVideoError: true,
      };

    case INTIALIZE_VIDEO_REQUEST:
      return {
        ...state,
        intializeVideoLoading: true,
        intializeVideoData: {},
        intializeVideoError: null,
      };

    case INTIALIZE_VIDEO_SUCCESS:
      return {
        ...state,
        intializeVideoLoading: false,
        intializeVideoData: action.payload,
        intializeVideoError: null,
      };

    case INTIALIZE_VIDEO_FAIL:
      return {
        ...state,
        intializeVideoLoading: false,
        intializeVideoData: {},
        intializeVideoError: true,
      };

    case FETCH_VIDEO_TO_PLAY_REQUEST:
      return {
        ...state,
        videoPlayFetchDataLoading: true,
        videoPlayFetchData: {},
        videoPlayFetchDataError: null,
      };

    case FETCH_VIDEO_TO_PLAY_SUCCESS:
      return {
        ...state,
        videoPlayFetchDataLoading: false,
        videoPlayFetchData: action.payload,
        videoPlayFetchDataError: null,
      };

    case FETCH_VIDEO_TO_PLAY_FAIL:
      return {
        ...state,
        videoPlayFetchDataLoading: false,
        videoPlayFetchData: null,
        videoPlayFetchDataError: true,
      };

    case SUSPEND_VIDEO_REQUEST:
      return {
        ...state,
        suspendVideoLoading: true,
        suspendVideoSuccess: null,
        suspendVideoFail: false,
      };

    case SUSPEND_VIDEO_SUCCESS:
      return {
        ...state,
        suspendVideoLoading: false,
        suspendVideoSuccess: action.payload,
        suspendVideoFail: false,
      };

    case SUSPEND_VIDEO_FAIL:
      return {
        ...state,
        suspendVideoLoading: false,
        suspendVideoSuccess: null,
        suspendVideoFail: true,
      };

    case FETCH_ALL_VIDEOS_BY_GROUP_REQUEST:
      return {
        ...state,
        fetchAllVideosByGroupLoading: true,
        fetchAllVideosByGroupData: null,
        fetchAllVideosByGroupFail: null,
      };

    case FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS:
      return {
        ...state,
        fetchAllVideosByGroupLoading: false,
        fetchAllVideosByGroupData: action.payload,
        fetchAllVideosByGroupFail: null,
      };

    case FETCH_ALL_VIDEOS_BY_GROUP_FAIL:
      return {
        ...state,
        fetchAllVideosByGroupLoading: false,
        fetchAllVideosByGroupData: null,
        fetchAllVideosByGroupFail: true,
      };

    case UPDATE_VIDEO_REQUEST:
      return {
        ...state,
        updateVideoLoading: true,
        updateVideoSuccess: false,
        updateVideoFail: false,
      };

    case UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        updateVideoLoading: false,
        updateVideoSuccess: true,
        updateVideoFail: false,
        fileUploadingPercentage: 0,
      };

    case UPDATE_VIDEO_FAIL:
      return {
        ...state,
        updateVideoLoading: false,
        updateVideoSuccess: false,
        updateVideoFail: true,
        fileUploadingPercentage: 0,
      };

    case UPDATE_VIDEO_RESET:
      return {
        ...state,
        updateVideoLoading: false,
        updateVideoSuccess: false,
        updateVideoFail: false,
        fileUploadingPercentage: 0,
      };

    case UPDATE_VIDEO_VIEW_REQUEST:
      return {
        ...state,
        updateVideoViewsLoading: true,
        updateVideoViewsSuccess: false,
        updateVideoViewsFail: false,
      };

    case UPDATE_VIDEO_VIEW_SUCCESS:
      return {
        ...state,
        updateVideoViewsLoading: false,
        updateVideoViewsSuccess: true,
        updateVideoViewsFail: false,
      };

    case UPDATE_VIDEO_VIEW_FAIL:
      return {
        ...state,
        updateVideoViewsLoading: false,
        updateVideoViewsSuccess: false,
        updateVideoViewsFail: true,
      };

    case DELETE_VIDEO_REQUEST:
      return {
        ...state,
        deleteVideoLoading: true,
        deleteVideoSuccess: null,
        deleteVideoError: null,
      };

    case DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        deleteVideoLoading: false,
        deleteVideoSuccess: true,
        deleteVideoError: null,
      };

    case DELETE_VIDEO_FAIL:
      return {
        ...state,
        deleteVideoLoading: false,
        deleteVideoSuccess: null,
        deleteVideoError: true,
      };

    case FETCH_VIDEO_STATS_BY_GROUP_REQUEST:
      return {
        ...state,
        videoStatsByGroupLoading: true,
        videoStatsByGroupData: null,
        videoStatsByGroupError: null,
      };

    case FETCH_VIDEO_STATS_BY_GROUP_SUCCESS:
      return {
        ...state,
        videoStatsByGroupLoading: false,
        videoStatsByGroupData: action?.payload,
        videoStatsByGroupError: null,
      };

    case FETCH_VIDEO_STATS_BY_GROUP_FAIL:
      return {
        ...state,
        videoStatsByGroupLoading: false,
        videoStatsByGroupData: null,
        videoStatsByGroupError: true,
      };

    case FETCH_VIDEO_STATS_BY_GROUP_RESET:
      return {
        ...state,
        videoStatsByGroupLoading: false,
        videoStatsByGroupData: null,
        videoStatsByGroupError: null,
      };

    case UPDATE_FILE_UPLOADING_PERCENTAGE:
      return {
        ...state,
        fileUploadingPercentage: action.payload,
      };

    case DOWNLOAD_STATS_REQUEST:
      return {
        ...state,
        downloadingStatsLoading: true,
        downloadingStatsError: null,
      };

    case DOWNLOAD_STATS_SUCCESS:
      return {
        ...state,
        downloadingStatsLoading: false,
        downloadingStatsError: null,
      };

    case DOWNLOAD_STATS_FAIL:
      return {
        ...state,
        downloadingStatsLoading: false,
        downloadingStatsError: {
          message: messageConfigs.error.STATS.VIDEO,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default videoReducer;

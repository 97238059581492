import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbar from 'react-smooth-scrollbar';
import Loader from '../../Components/Loader/loader';
import WatchReminderModal from '../../Components/Modal/WatchReminderModal';
import useApi from '../../Hooks/useApi';
import {
  ADD_DISCARDCHANGE_POPUP_OPEN,
  CHANGE_ASSESSMENT_STATUS,
  CHANGE_WATCH_LATER_VIDEOS,
} from '../../redux/action/actionTypes';
import {
  FIND_ASSESSMENT_BY_ID_FAIL,
  FIND_ASSESSMENT_BY_ID_REQUEST,
  FIND_ASSESSMENT_BY_ID_SUCCESS,
  REMIND_LATER_FAIL,
  REMIND_LATER_REQUEST,
  REMIND_LATER_SUCCESS,
  SEND_ASSESSMENT_RESULT_FAIL,
  SEND_ASSESSMENT_RESULT_REQUEST,
  SEND_ASSESSMENT_RESULT_SUCCESS,
  UPDATE_ASSESSMENT_VIEWS_FAIL,
  UPDATE_ASSESSMENT_VIEWS_REQUEST,
  UPDATE_ASSESSMENT_VIEWS_SUCCESS,
} from '../../redux/actionTypes/assesment';
import ViewPDFModal from '../AdminDashboard/Group/Modal/viewPDFModal';
import AssessmentCountDown from './Modal/AssessmentCountDown';
import DiscardChangesApplyModal from './Modal/DiscardChangeApplyModal';

// import backgroundImage from '../../assets/images/ipad-apiit.png';

const Assessment = ({ history, match }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [assessmentData, setAssessmentData] = useState({});
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [lastAnsweredQuestionIndex, setLastAnsweredQuestionIndex] =
    useState(-1);
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] =
    useState(false);
  const [pdfDoucmentUrl, setPdfDocumentUrl] = useState(null);
  const [addWatchLaterModalOpen, setWatchLaterModalOpen] = useState(false);
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [isAssessmentStart, setAssessmentStart] = useState(false);
  const [assessmentStartTime, setAssessmentStartTime] = useState(null);
  const [isAssesmentCountDownShow, setIsAssesmentCountDownShow] =
    useState(false);
  const [assessmentAttendingTime, setAssessmentAttendingTime] = useState(0);
  const dispatch = useDispatch();

  const { isAdminView, themeInfo } = useSelector(state => state?.auth);
  const {
    isDiscardChangePopupOpen,
    findAssessmentData,
    sendAssessmentResultSuccess,
    updateAssessmentViewSuccess,
  } = useSelector(state => state?.assessment);
  const watchLaterVideoIds = useSelector(state =>
    state.video && state.video.watchLaterVideoIds
      ? state.video.watchLaterVideoIds
      : []
  );
  const { assessmentId, isEndVideo, videoId } = match.params;

  const loadDefaultPreviewImage = content => {
    if (Object.keys(content).includes('documentUrl')) {
      return themeInfo?.documentImageUrl;
    } else if (Object.keys(content).includes('previewVideoUrl')) {
      return themeInfo?.defaultVideoImageUrl;
    } else {
      return themeInfo?.documentImageUrl;
    }
  };

  useEffect(() => {
    if (isEndVideo) {
      handleAssessmentStart(true);
      setAssessmentStart(true);
    }
  }, [isEndVideo]);

  const [fetchAssessment] = useApi();
  const [sendAssessmentResult] = useApi();
  const [sendRemindLaterRequest] = useApi();
  const [sendAssessmentViewRequest] = useApi();
  const {
    description,
    documents = [],
    questions = [],
    duration,
    title,
    videos = [],
  } = assessmentData;
  const relatedContents = [...documents, ...videos];

  useEffect(() => {
    fetchAssessment(
      `/assessment/get-standalone-assessment-on-click?assessmentId=${assessmentId}`,
      FIND_ASSESSMENT_BY_ID_REQUEST,
      FIND_ASSESSMENT_BY_ID_SUCCESS,
      FIND_ASSESSMENT_BY_ID_FAIL,
      null,
      '',
      'GET',
      null,
      'isReelService'
    );
  }, [assessmentId]);

  useEffect(() => {
    if (findAssessmentData) {
      const tempFindAssessmentData = { ...findAssessmentData };
      tempFindAssessmentData.questions = findAssessmentData.questions.map(
        question => {
          const tempQuestion = { ...question };
          tempQuestion.provideAnswer = '';
          tempQuestion.correctAnswer = parseInt(question.correctAnswer);
          return tempQuestion;
        }
      );
      setAssessmentData(tempFindAssessmentData);

      if (!isAdminView) {
        sendAssessmentViewRequest(
          `/assessment/update-standalone-views?assessmentId=${assessmentId}`,
          UPDATE_ASSESSMENT_VIEWS_REQUEST,
          UPDATE_ASSESSMENT_VIEWS_SUCCESS,
          UPDATE_ASSESSMENT_VIEWS_FAIL,
          null,
          '',
          'PUT',
          null,
          'isReelService'
        );

        if (isEndVideo) {
          handleSaveAssessmentResult();
        }
      }
    }
  }, [findAssessmentData]);

  useEffect(() => {
    if (isEndVideo && updateAssessmentViewSuccess) {
      startAssessmentResult();
    }
  }, [updateAssessmentViewSuccess]);

  useEffect(() => {
    setIsDiscardChangesModalOpen(isDiscardChangePopupOpen);
  }, [isDiscardChangePopupOpen]);

  useEffect(() => {
    const root = document.getElementById('root');
    root.className = 'assessment-page';

    setTimeout(() => {
      setIsLoading(false);
    }, 100);

    dispatch({ type: SEND_ASSESSMENT_RESULT_SUCCESS, payload: null });
    return () => {
      dispatch({ type: SEND_ASSESSMENT_RESULT_SUCCESS, payload: null });
      dispatch({ type: UPDATE_ASSESSMENT_VIEWS_SUCCESS, payload: null });
    };
  }, []);

  const sendRemindLaterDetails = (timestamp = '') => {
    sendRemindLaterRequest(
      '/assessment/update-do-assessment-later',
      REMIND_LATER_REQUEST,
      REMIND_LATER_SUCCESS,
      REMIND_LATER_FAIL,
      {
        assessmentId,
        timestamp: timestamp
          ? moment(timestamp).format('YYYY-MM-DDTHH:mm:ss:SSZ')
          : '',
      },
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  const onHandleAnswer = (providedAnsweredIndex, questionIndex) => {
    const tempAssessmentData = { ...assessmentData };
    tempAssessmentData.questions[questionIndex].provideAnswer =
      providedAnsweredIndex;
    setAssessmentData(tempAssessmentData);
  };
  const ConverTimeInMinAndSec = time => {
    const timeInMins = Math.floor(time / 60);
    const timeInSecs = time - timeInMins * 60;
    return { timeInMins, timeInSecs };
  };

  const commonAssessmentMethod = () => {
    let updatedResult = {
      score: `${
        questions.filter(
          question => question.correctAnswer === question.provideAnswer
        ).length
      }/${questions.length}`,
      spentTime: `${
        ConverTimeInMinAndSec(assessmentAttendingTime).timeInMins
      }:${ConverTimeInMinAndSec(assessmentAttendingTime).timeInSecs} min`,
      assessmentId,
    };

    // Adding video Id for end video assessment
    if (isEndVideo) {
      updatedResult = {
        ...updatedResult,
        videoId,
      };
    }

    // If Assessment started iniate session with empty provided answers
    // Else set user provided answers on submit
    if (assessmentAttendingTime > 0) {
      updatedResult = {
        ...updatedResult,
        answers: questions.map(({ _id, provideAnswer }) => ({
          questionId: _id,
          userAnswer: provideAnswer,
        })),
      };
    } else {
      updatedResult = {
        ...updatedResult,
        answers: [],
      };
    }

    return updatedResult;
  };

  const sendAssessmentResultMethod = updatedResult => {
    sendAssessmentResult(
      '/assessment/update-result',
      SEND_ASSESSMENT_RESULT_REQUEST,
      SEND_ASSESSMENT_RESULT_SUCCESS,
      SEND_ASSESSMENT_RESULT_FAIL,
      updatedResult,
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  const handleSaveAssessmentResult = () => {
    if (!isAdminView) {
      let updatedResult = commonAssessmentMethod();

      // Adding result id on submit
      if (sendAssessmentResultSuccess) {
        updatedResult = {
          ...updatedResult,
          _id: sendAssessmentResultSuccess?._id,
        };
      }

      sendAssessmentResultMethod(updatedResult);
    }
  };

  const startAssessmentResult = () => {
    if (!isAdminView) {
      let updatedResult = commonAssessmentMethod();

      sendAssessmentResultMethod(updatedResult);
    }
  };

  const handleAssessmentOver = () => {
    handleSaveAssessmentResult();
    setResultModalOpen(true);
  };

  /**
   * Show Assessment count down then, Disatch
   * 4 Seconds - 4000ms
   */
  const handleAssessmentStart = status => {
    setIsAssesmentCountDownShow(true);

    setTimeout(() => {
      setIsAssesmentCountDownShow(false);
      setAssessmentStartTime(new Date());

      return dispatch({
        type: CHANGE_ASSESSMENT_STATUS,
        payload: status,
      });
    }, 3000);
  };
  useEffect(() => {
    let interval = null;
    if (!resultModalOpen && assessmentStartTime) {
      interval = setInterval(() => {
        setAssessmentAttendingTime(secounds => secounds + 1);
      }, 1000);
    }
    if (assessmentAttendingTime && assessmentAttendingTime === duration * 60) {
      handleAssessmentOver();
    }

    return () => clearInterval(interval);
  }, [assessmentAttendingTime, assessmentStartTime]);

  if (isLoading) {
    return <Loader />;
  }

  return isAssesmentCountDownShow ? (
    <AssessmentCountDown />
  ) : (
    <div className="assessment" style={{ color: 'white' }}>
      <div className="container">
        {isAssessmentStart ? (
          <div id="start" className="assessment__wrapper">
            <div className="assessment__questions--wrapper">
              {questions.length &&
                questions.map(
                  (question, index) =>
                    index === selectedQuestionIndex && (
                      <div key={index} className="assessment__question-column">
                        <div className="assessment__question-column--timer">
                          {
                            ConverTimeInMinAndSec(
                              parseInt(duration)
                                ? duration * 60 - assessmentAttendingTime
                                : assessmentAttendingTime
                            ).timeInMins
                          }{' '}
                          :{' '}
                          {
                            ConverTimeInMinAndSec(
                              parseInt(duration)
                                ? duration * 60 - assessmentAttendingTime
                                : assessmentAttendingTime
                            ).timeInSecs
                          }
                        </div>
                        <div
                          className={`assessment__question-column--number ${
                            question.orientation
                              ? `oriented orientation-${question.orientation}`
                              : ''
                          }`}
                        >
                          <div className="assessment__question-column--question_img">
                            {question.questionImageS3BucketURL && (
                              <img src={question.questionImageS3BucketURL} />
                            )}
                          </div>

                          <div className="assessment__question-column--question">
                            <h4>
                              {t('Question 0')}
                              {index + 1}
                            </h4>
                            <h3>{question.quiz}</h3>
                            <form className="form">
                              {question.options.map((answer, key) => (
                                <div
                                  className={`form__form--fields 
                              ${
                                (selectedQuestionIndex <
                                  lastAnsweredQuestionIndex ||
                                  selectedQuestionIndex ===
                                    lastAnsweredQuestionIndex) &&
                                question.provideAnswer ===
                                  question.correctAnswer
                                  ? 'correct'
                                  : ''
                              }
                              ${
                                (selectedQuestionIndex <
                                  lastAnsweredQuestionIndex ||
                                  selectedQuestionIndex ===
                                    lastAnsweredQuestionIndex) &&
                                question.provideAnswer !==
                                  question.correctAnswer
                                  ? 'incorrect'
                                  : ''
                              }`}
                                  key={key}
                                >
                                  <input
                                    type="checkbox"
                                    checked={question.provideAnswer === key}
                                    onChange={() => console.log('')}
                                  />
                                  <label
                                    onClick={() => {
                                      selectedQuestionIndex >
                                        lastAnsweredQuestionIndex &&
                                        onHandleAnswer(key, index);
                                    }}
                                  >
                                    {answer}
                                  </label>
                                </div>
                              ))}
                            </form>
                          </div>
                        </div>

                        <div className="assessment__question-column--answer"></div>
                      </div>
                    )
                )}
              <div className="assessment__questions-column">
                <Scrollbar className="assessment__scrollbar">
                  <div className="assessment__scrollbar">
                    <div className="assessment__questions--counter">
                      <p>
                        {resultModalOpen
                          ? questions.length
                          : lastAnsweredQuestionIndex + 1}
                        /{questions.length}
                      </p>
                    </div>
                    <div className="assessment__questions--list">
                      {questions.length &&
                        questions.map((question, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              (lastAnsweredQuestionIndex > index ||
                                lastAnsweredQuestionIndex === index) &&
                              setSelectedQuestionIndex(index)
                            }
                            className={`assessment__question ${
                              selectedQuestionIndex === index ? 'selected' : ''
                            }`}
                          >
                            <h5>
                              {t('Question 0')}
                              {index + 1}
                            </h5>
                            {(lastAnsweredQuestionIndex > index ||
                              lastAnsweredQuestionIndex === index ||
                              selectedQuestionIndex === index) && (
                              <p>{question.quiz}</p>
                            )}
                            {(lastAnsweredQuestionIndex > index ||
                              lastAnsweredQuestionIndex === index) && (
                              <div className="assessment__question--result">
                                <p
                                  className={`assessment__question--result-${
                                    question.provideAnswer ===
                                    question.correctAnswer
                                      ? ''
                                      : 'in'
                                  }correct`}
                                >
                                  {' '}
                                  {question.provideAnswer ===
                                  question.correctAnswer
                                    ? 'Correct'
                                    : 'Incorrect'}
                                </p>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </Scrollbar>
              </div>
            </div>
            <div className="form--buttons">
              <button
                className="btn btn--primary"
                disabled={
                  selectedQuestionIndex < questions.length &&
                  questions.length &&
                  typeof questions[selectedQuestionIndex].provideAnswer !==
                    'number'
                }
                onClick={() => {
                  if (selectedQuestionIndex < questions.length - 1) {
                    if (selectedQuestionIndex > lastAnsweredQuestionIndex) {
                      setLastAnsweredQuestionIndex(selectedQuestionIndex);
                    }
                    setSelectedQuestionIndex(selectedQuestionIndex + 1);
                  } else {
                    handleAssessmentOver();
                  }
                }}
              >
                {selectedQuestionIndex === questions.length - 1
                  ? t('Submit')
                  : t('Next')}
              </button>
              <button
                className="btn btn--secondary btn--last"
                onClick={() => {
                  setIsDiscardChangesModalOpen(true);
                }}
                style={{
                  visibility: isEndVideo ? 'hidden' : '',
                }}
              >
                {t('Do it Later')}
              </button>
              <div className="assessment--btn" />
            </div>
          </div>
        ) : (
          <div
            className="assessment"
            // style={{
            //   backgroundImage: `url(${backgroundImage})`,
            // }}
          >
            <div className="assessment__start">
              <div className="assessment__start--heading">
                <h2>{title}</h2>
              </div>
              <div className="assessment__start--context">
                <p>{description}</p>
              </div>
              <div className="assessment__start--info">
                <div className="assessment__start--duration">
                  <p>{t('Duration')}</p>
                  <span>
                    {parseInt(duration)
                      ? `${duration} ${t('minutes')}`
                      : 'Time not applicable'}
                  </span>
                </div>
                <div className="assessment__start--questions-count">
                  <p>{t('No. of Questions')}</p>
                  <span>
                    {questions.length} {t('Questions')}
                  </span>
                </div>
              </div>
              <div className="assessment__start--related-content">
                <h4 className="assessment__start--related-content--title">
                  {t('Related Content')}
                </h4>
                <div className="assessment__start--reel">
                  {relatedContents.length
                    ? relatedContents.map((relatedContent, index) => (
                        <div
                          className="assessment__start--reel-tile"
                          key={index}
                        >
                          <img
                            src={
                              relatedContent.previewImgUrl ||
                              loadDefaultPreviewImage(relatedContent)
                            }
                            onError={event => {
                              event.target.onerror = null;
                              event.target.src =
                                loadDefaultPreviewImage(relatedContent);
                            }}
                            alt="related-video-doc-image"
                            className="assessment--image"
                            onClick={() => {
                              if (relatedContent.documentUrl) {
                                setPdfDocumentUrl({
                                  documentUrl: relatedContent.documentUrl,
                                  allowDownload: relatedContent.download,
                                });
                              } else {
                                history.push(`/theatre/${relatedContent._id}`);
                              }
                            }}
                          />
                          <p>{relatedContent.title} </p>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              <div className="assessment__start--submission">
                <button
                  className="btn btn--secondary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {t('Back to Home')}
                </button>
                <button
                  className="btn btn--primary"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    startAssessmentResult();
                    setAssessmentStart(true);
                    handleAssessmentStart(true);
                  }}
                >
                  {t('Start')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {isDiscardChangesModalOpen && (
        <DiscardChangesApplyModal
          onHandleConfirm={() => {
            setWatchLaterModalOpen(true);
            setIsDiscardChangesModalOpen(false);
            dispatch({
              type: ADD_DISCARDCHANGE_POPUP_OPEN,
              payload: false,
            });
          }}
          onHandleExit={() => {
            setIsDiscardChangesModalOpen(false);
            dispatch({
              type: ADD_DISCARDCHANGE_POPUP_OPEN,
              payload: false,
            });
          }}
          onHandleCancel={() => setIsDiscardChangesModalOpen(false)}
        />
      )}
      {addWatchLaterModalOpen && (
        <WatchReminderModal
          onHandleConfirm={value => {
            const date = new Date(
              value.year,
              value.month,
              value.date,
              value.meridiem === 'AM' ? value.hr : parseInt(value.hr) + 12,
              value.min,
              value.sec
            );
            date.setSeconds(0);
            sendRemindLaterDetails(date);
            history.goBack();
          }}
          onHandleExit={() => {
            sendRemindLaterDetails();
            history.goBack();
          }}
          onHandleCancel={() => setWatchLaterModalOpen(false)}
        />
      )}
      {resultModalOpen && (
        <WatchReminderModal
          assessmentName={assessmentData.title}
          formatedSpendTime={{
            spendTimeInMins: ConverTimeInMinAndSec(assessmentAttendingTime)
              .timeInMins,
            spendTimeInSecs: ConverTimeInMinAndSec(assessmentAttendingTime)
              .timeInSecs,
          }}
          onHandleExit={() => {
            const index = watchLaterVideoIds.indexOf(assessmentId);
            const tempWatchLaterIds = [...watchLaterVideoIds];
            tempWatchLaterIds.splice(index, 1);
            dispatch({
              type: CHANGE_WATCH_LATER_VIDEOS,
              payload: {
                watchLaterVideoIds: [...tempWatchLaterIds],
              },
            });
            // setResultModalOpen(false);
            // handleAssessmentStart(false);
            isAdminView ? history.goBack() : history.push('/home');
          }}
          assessmentResult={questions}
        />
      )}
      {pdfDoucmentUrl && (
        <ViewPDFModal
          pdfUrl={pdfDoucmentUrl.documentUrl}
          allowDownload={isAdminView || pdfDoucmentUrl.allowDownload}
          handleClose={() => setPdfDocumentUrl(null)}
        />
      )}
    </div>
  );
};

export default Assessment;

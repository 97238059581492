import React, { useEffect, useState } from 'react';
import PickyDateTime from 'react-picky-date-time';
import Scrollbar from 'react-smooth-scrollbar';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import PDFViewer from './PDFViewerModal';
import fullScreen from '../../assets/images/svg-images/icon-bigscreen.svg';
import zoomIn from '../../assets/images/svg-images/icon-zoom.svg';
import zoomOut from '../../assets/images/svg-images/icon-zoomout.svg';

const convertNumToAlph = num => {
  switch (num) {
    case 0:
      return 'a';
    case 1:
      return 'b';
    case 2:
      return 'c';
    case 3:
      return 'd';
    default:
      return 'a';
  }
};

const WatchReminderModal = ({
  onHandleConfirm,
  onHandleCancel,
  onHandleExit,
  assessmentResult = null,
  pdfURL = null,
  pdfTitle = null,
  endVideoAssessmentData = null,
  assessmentName = null,
  formatedSpendTime = {},
}) => {
  const { t } = useTranslation();
  const { spendTimeInMins, spendTimeInSecs } = formatedSpendTime;

  const [isActive, setIsActive] = useState(false);
  const [scale, setScale] = useState(0.8);
  const [date, setDate] = useState({
    year: moment().year(),
    month: moment().month(),
    date: moment().date(),
    meridiem: 'AM',
    hr: moment().hour(),
    min: moment().minute(),
    sec: moment().second(),
  });
  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    setTimeout(() => {
      body.className.concat('popup-active');
      setIsActive(true);
    }, 0);
    return () => {
      body.className.replace('popup-active', '');
    };
  }, []);

  const renderFooterButton = () => (
    <div className="modal-popup__footer reminder__footer">
      <div className="form-actions form-actions--right">
        <button className="btn" onClick={onHandleExit}>
          {t('Add without reminder')}
        </button>
        <button
          className="btn btn--primary"
          onClick={() => onHandleConfirm(date)}
        >
          {t('Confirm')}
        </button>
      </div>
    </div>
  );
  const renderModalContent = () => {
    if (!assessmentResult && !pdfURL) {
      return (
        <>
          <div className="modal-popup__header">
            {!endVideoAssessmentData && (
              <h2>{t('Reminder me later about this')}</h2>
            )}
            <a
              href="javascript:void(0)"
              className="btn-close closemodale"
              aria-hidden="true"
              onClick={onHandleCancel}
            >
              &times;
            </a>
          </div>
          <div>
            {endVideoAssessmentData ? (
              <div className="assessment__endvideo">
                <div className="assessment__endvideo--heading">
                  <h3>{t('Please complete the end-video Assessment')}</h3>
                </div>
                <div className="assessment__endvideo--context">
                  <p>{endVideoAssessmentData.description}</p>
                </div>
                <div className="assessment__endvideo--info">
                  {/* <div className="assessment__endvideo--duration">
                    <p>Duration</p>
                    <span>{endVideoAssessmentData.duration} minutes</span>
                  </div> */}
                  <div className="assessment__endvideo--questions-count">
                    <p>{t('No. of Questions')}</p>
                    <span>
                      {endVideoAssessmentData.questions.length} Questions
                    </span>
                  </div>
                </div>
                <div className="assessment__endvideo--submission">
                  <button
                    className="btn btn--primary"
                    onClick={onHandleConfirm}
                  >
                    {t('Start')}
                  </button>
                </div>
              </div>
            ) : (
              <div className="reminder">
                <PickyDateTime
                  size="xs"
                  mode={1}
                  show
                  // isDisabled
                  locale="en-us"
                  onYearPicked={res => setDate({ ...date, ...res })}
                  onMonthPicked={res => setDate({ ...date, ...res })}
                  // onDatePicked={(res) =>
                  //   // setDate({ ...date, ...res })
                  // }
                  onSecondChange={res => setDate({ ...date, sec: res.value })}
                  onMinuteChange={res => setDate({ ...date, min: res.value })}
                  onHourChange={res => setDate({ ...date, hr: res.value })}
                  onMeridiemChange={res => setDate({ ...date, meridiem: res })}
                />
                {renderFooterButton()}
              </div>
            )}
          </div>
        </>
      );
    }
    if (pdfURL) {
      return (
        <>
          <div className="modal-popup__title">
            <p>{pdfTitle || 'Equal opportunity and non-discrimination'}</p>
          </div>
          <div className="modal-popup__header">
            <a
              href="#"
              className="btn-close closemodale"
              aria-hidden="true"
              onClick={onHandleCancel}
            >
              &times;
            </a>
            <a
              href="#"
              className="btn-close btn-bigscreen"
              onClick={() => window.open(pdfURL, '_blank')}
            >
              <img
                src={fullScreen}
                alt="fullscreen-icon"
                className="icon--fullscreen"
              />
            </a>
            <a
              href="#"
              className="btn-close btn-zoomin"
              onClick={() => setScale(scale < 3.3 ? scale + 0.5 : scale)}
            >
              <img src={zoomIn} alt="zoomin-icon" className="icon--zoomin" />
            </a>
            <a
              href="#"
              className="btn-close btn-zoomout"
              onClick={() => setScale(scale > 0.8 ? scale - 0.5 : scale)}
            >
              <img src={zoomOut} alt="zoomout-icon" className="icon--zoomout" />
            </a>
          </div>
          <PDFViewer pdfURL={pdfURL} scale={scale} />
        </>
      );
    }
    return (
      <>
        <div className="modal-popup__header">
          <div className="assessment__result--title">
            <h2>
              {assessmentName}
              <span>
                {t('Completed within')}{' '}
                {spendTimeInMins ? `${spendTimeInMins}min ` : null}
                {spendTimeInSecs ? `${spendTimeInSecs}sec` : null}
              </span>
            </h2>
          </div>
          <div className="assessment__result--count">
            <p>{t('Result')}</p>
            <h2>
              {
                assessmentResult.filter(
                  question => question.provideAnswer === question.correctAnswer
                ).length
              }
              {''}/{assessmentResult.length}
            </h2>
          </div>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={onHandleExit}
          >
            &times;
          </a>
        </div>
        <div>
          <div className="assessment__result--list">
            <Scrollbar className="assessment__scrollbar">
              <div className="assessment__result--wrapper">
                {assessmentResult.length &&
                  assessmentResult.map((question, key) => (
                    <div className="assessment__result--question" key={key}>
                      <h5>
                        {t('Question 0')}
                        {key + 1}
                      </h5>
                      <p>{question.quiz}</p>
                      <div className="assessment__result--result">
                        <p
                          className={`assessment__result--result-${
                            question.correctAnswer === question.provideAnswer
                              ? ''
                              : 'in'
                          }correct`}
                        >
                          $
                          {question.correctAnswer === question.provideAnswer
                            ? 'Correct'
                            : 'Incorrect'}
                        </p>
                      </div>
                      <div className="assessment__result--answer">
                        <p className="assessment__result--answer-youranswer">
                          {question.correctAnswer !== question.provideAnswer &&
                            'Your'}{' '}
                          {t('Answer')}:{' '}
                          {Number.isInteger(question.provideAnswer)
                            ? `${convertNumToAlph(question.provideAnswer)}.`
                            : '--'}
                          {question.options[question.provideAnswer]}
                        </p>
                        {question.correctAnswer !== question.provideAnswer && (
                          <p className="assessment__result--answer-correct">
                            {t('Correct Answer')}:{' '}
                            {convertNumToAlph(question.correctAnswer)}.
                            {question.options[question.correctAnswer]}
                          </p>
                        )}
                      </div>{' '}
                    </div>
                  ))}
              </div>
            </Scrollbar>
          </div>
        </div>
        <div className="modal-popup__footer assessment__result--footer">
          <div className="form-actions form-actions--right">
            <button className="btn btn--primary" onClick={onHandleExit}>
              {t('Back to home')}
            </button>
          </div>
        </div>
      </>
    );
  };
  return (
    <div
      className={`modal-popup ${isActive ? 'active' : ''}`}
      aria-hidden="true"
    >
      <div
        className={`modal-popup__dialog ${
          assessmentResult ? 'assessment__result' : ''
        }${endVideoAssessmentData ? 'endVideoAssessment' : ''} ${
          pdfURL ? 'pdfViewer' : ''
        } ${
          !endVideoAssessmentData && !pdfURL && !assessmentResult
            ? 'dateTimeReminder'
            : ''
        }`}
      >
        {renderModalContent()}
      </div>
    </div>
  );
};

export default WatchReminderModal;

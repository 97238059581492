import {
  FETCH_BANNER_AND_FEATURED_DATA_REQUEST,
  FETCH_BANNER_AND_FEATURED_DATA_SUCCESS,
  FETCH_BANNER_AND_FEATURED_DATA_FAIL,
  CREATE_BANNER_FEATURED_IMAGE_REQUEST,
  CREATE_BANNER_FEATURED_IMAGE_SUCCESS,
  CREATE_BANNER_FEATURED_IMAGE_FAIL,
  FETCH_BANNER_FEATURED_IMAGE_BY_ID_REQUEST,
  FETCH_BANNER_FEATURED_IMAGE_BY_ID_SUCCESS,
  FETCH_BANNER_FEATURED_IMAGE_BY_ID_FAIL,
  UPDATE_BANNER_FEATURED_IMAGE_REQUEST,
  UPDATE_BANNER_FEATURED_IMAGE_SUCCESS,
  UPDATE_BANNER_FEATURED_IMAGE_FAIL,
  SUSPEND_IMAGE_REQUEST,
  SUSPEND_IMAGE_SUCCESS,
  SUSPEND_IMAGE_FAIL,
  DELETE_BANNER_FEATURED_IMAGE_REQUEST,
  DELETE_BANNER_FEATURED_IMAGE_SUCCESS,
  DELETE_BANNER_FEATURED_IMAGE_FAIL,
  FETCH_IMAGE_URL_BY_ID_REQUEST,
  FETCH_IMAGE_URL_BY_ID_SUCCESS,
  FETCH_IMAGE_URL_BY_ID_FAIL,
} from '../actionTypes/bannerAndFeatured';

const bannerAndFeaturedReducer = (
  state = {
    fetchBannerAndFeaturedLoading: false,
    fetchBannerAndFeaturedSuccess: null,
    fetchBannerAndFeaturedError: null,
    creatBannerFeatureImageLoading: false,
    creatBannerFeatureImageSuccess: null,
    creatBannerFeatureImageError: null,
    fetchBannerAndFeaturedImageByIdLoading: false,
    fetchBannerAndFeaturedImageByIdData: null,
    fetchBannerAndFeaturedImageByIdError: null,
    updateBannerFeatureImageLoading: false,
    updateBannerFeatureImageSuccess: null,
    updateBannerFeatureImageError: null,
    suspendImageLoading: false,
    suspendImageSuccess: null,
    suspendImageError: null,
    deleteImageLoading: false,
    deleteImageSuccess: null,
    deleteImageError: null,
    bannerFeaturedImageUrlLoading: false,
    bannerFeaturedImageUrl: null,
    bannerFeaturedImageUrlError: null,
  },
  action
) => {
  switch (action.type) {
    case FETCH_BANNER_AND_FEATURED_DATA_REQUEST:
      return {
        ...state,
        fetchBannerAndFeaturedLoading: true,
        fetchBannerAndFeaturedSuccess: null,
        fetchBannerAndFeaturedError: null,
      };

    case FETCH_BANNER_AND_FEATURED_DATA_SUCCESS:
      return {
        ...state,
        fetchBannerAndFeaturedLoading: false,
        fetchBannerAndFeaturedSuccess: action.payload,
        fetchBannerAndFeaturedError: null,
      };

    case FETCH_BANNER_AND_FEATURED_DATA_FAIL:
      return {
        ...state,
        fetchBannerAndFeaturedLoading: false,
        fetchBannerAndFeaturedSuccess: null,
        fetchBannerAndFeaturedError: true,
      };

    case CREATE_BANNER_FEATURED_IMAGE_REQUEST:
      return {
        ...state,
        creatBannerFeatureImageLoading: true,
        creatBannerFeatureImageSuccess: null,
        creatBannerFeatureImageError: null,
      };

    case CREATE_BANNER_FEATURED_IMAGE_SUCCESS:
      return {
        ...state,
        creatBannerFeatureImageLoading: false,
        creatBannerFeatureImageSuccess: action.payload,
        creatBannerFeatureImageError: null,
      };

    case CREATE_BANNER_FEATURED_IMAGE_FAIL:
      return {
        ...state,
        creatBannerFeatureImageLoading: false,
        creatBannerFeatureImageSuccess: null,
        creatBannerFeatureImageError: true,
      };

    case UPDATE_BANNER_FEATURED_IMAGE_REQUEST:
      return {
        ...state,
        updateBannerFeatureImageLoading: true,
        updateBannerFeatureImageSuccess: null,
        updateBannerFeatureImageError: null,
      };

    case UPDATE_BANNER_FEATURED_IMAGE_SUCCESS:
      return {
        ...state,
        updateBannerFeatureImageLoading: false,
        updateBannerFeatureImageSuccess: action.payload,
        updateBannerFeatureImageError: null,
      };

    case UPDATE_BANNER_FEATURED_IMAGE_FAIL:
      return {
        ...state,
        updateBannerFeatureImageLoading: false,
        updateBannerFeatureImageSuccess: null,
        updateBannerFeatureImageError: true,
      };

    case FETCH_BANNER_FEATURED_IMAGE_BY_ID_REQUEST:
      return {
        ...state,
        fetchBannerAndFeaturedImageByIdLoading: true,
        fetchBannerAndFeaturedImageByIdData: null,
        fetchBannerAndFeaturedImageByIdError: null,
      };

    case FETCH_BANNER_FEATURED_IMAGE_BY_ID_SUCCESS:
      return {
        ...state,
        fetchBannerAndFeaturedImageByIdLoading: false,
        fetchBannerAndFeaturedImageByIdData: action.payload,
        fetchBannerAndFeaturedImageByIdError: null,
      };

    case FETCH_BANNER_FEATURED_IMAGE_BY_ID_FAIL:
      return {
        ...state,
        fetchBannerAndFeaturedImageByIdLoading: false,
        fetchBannerAndFeaturedImageByIdData: null,
        fetchBannerAndFeaturedImageByIdError: true,
      };

    case SUSPEND_IMAGE_REQUEST:
      return {
        ...state,
        suspendImageLoading: true,
        suspendImageSuccess: null,
        suspendImageError: null,
      };

    case SUSPEND_IMAGE_SUCCESS:
      return {
        ...state,
        suspendImageLoading: false,
        suspendImageSuccess: action.payload,
        suspendImageError: null,
      };

    case SUSPEND_IMAGE_FAIL:
      return {
        ...state,
        suspendImageLoading: false,
        suspendImageSuccess: null,
        suspendImageError: true,
      };

    case DELETE_BANNER_FEATURED_IMAGE_REQUEST:
      return {
        ...state,
        deleteImageLoading: true,
        deleteImageSuccess: null,
        deleteImageError: null,
      };

    case DELETE_BANNER_FEATURED_IMAGE_SUCCESS:
      return {
        ...state,
        deleteImageLoading: false,
        deleteImageSuccess: true,
        deleteImageError: null,
      };

    case DELETE_BANNER_FEATURED_IMAGE_FAIL:
      return {
        ...state,
        deleteImageLoading: false,
        deleteImageSuccess: null,
        deleteImageError: true,
      };

    case FETCH_IMAGE_URL_BY_ID_REQUEST:
      return {
        ...state,
        bannerFeaturedImageUrlLoading: true,
        bannerFeaturedImageUrl: null,
        bannerFeaturedImageUrlError: null,
      };

    case FETCH_IMAGE_URL_BY_ID_SUCCESS:
      return {
        ...state,
        bannerFeaturedImageUrlLoading: false,
        bannerFeaturedImageUrl: action.payload,
        bannerFeaturedImageUrlError: null,
      };

    case FETCH_IMAGE_URL_BY_ID_FAIL:
      return {
        ...state,
        bannerFeaturedImageUrlLoading: false,
        bannerFeaturedImageUrl: null,
        bannerFeaturedImageUrlError: true,
      };

    default:
      return state;
  }
};

export default bannerAndFeaturedReducer;

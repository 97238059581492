import React from 'react';
import { Provider } from 'react-redux'; // Import React Redux
import { store, persistor } from './redux/store'; // Import Redux Store
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Amplify from 'aws-amplify';
import 'react-dropdown/style.css';

import './assets/scss/scss_player/plyr.scss';

import Routes from './routes';
import awsConfiguration from './config/awsCognitoSettings';

require(`./assets/scss/scss_web/${process.env.REACT_APP_CLIENT_ID}-style.scss`);

const App = () => {
  Amplify.configure(awsConfiguration);
  return (
    <Provider store={store}>
      <PersistGate loading={<div>loading...</div>} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <Routes />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

const awsConfiguration = {
  cognito: {
    REGION: process.env.REACT_APP_AUTH_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_AUTH_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
  },
  Auth: {
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AUTH_COGNITO_REGION,
    identityPoolRegion: process.env.REACT_APP_AUTH_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTH_OAUTH_DOMAIN,
      AppClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
      Scopes: ['openid', 'email'],
      redirectSignIn: process.env.REACT_APP_REDIRECTION_URL,
      redirectSignOut: process.env.REACT_APP_REDIRECTION_URL,
      responseType: 'code',
      identity_provider: process.env.REACT_APP_IDENTITY_PROVIDER,
      grant_type: 'authorization_code',
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_STORAGE_BUCKET_NAME,
      region: process.env.REACT_APP_AUTH_COGNITO_REGION,
    },
  },
  API: {
    endpoints: [
      {
        name: 'execute-api',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_API_ENDPOINT_REGION,
      },
    ],
  },
  Analytics: {
    AWSKinesisFirehose: {
      region: process.env.REACT_APP_AWS_FIREHOUSE_REGION,
    },
  },
};

export default awsConfiguration;

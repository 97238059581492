import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useApi from '../../../../Hooks/useApi';
import {
  INCLUDE_EXCLUDE_USERS_TO_REEL_REQUEST,
  INCLUDE_EXCLUDE_USERS_TO_REEL_SUCCESS,
  INCLUDE_EXCLUDE_USERS_TO_REEL_FAIL,
} from '../../../../redux/actionTypes/reels';
import useFetchDataApi from '../../../../Hooks/useFetchDataApi';
import DottedLoader from '../../../../Components/Loader/DottedLoader';

export default function UserControlModal({
  reelId,
  reelName,
  groupId,
  handleClose,
  handleSuccess,
}) {
  const { t } = useTranslation();
  const [userList, setUserList] = useState([]);
  const [currentIncludedIds, setCurrentIncludedIds] = useState([]);
  const [currentExcludedIds, setCurrentExcludedIds] = useState([]);
  const [newIncludeUserIds, setNewIncludeUserIds] = useState([]);
  const [newExcludedIds, setNewExcludedIds] = useState([]);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const {
    usersForAccessControlData,
    includeExcludeUsersSuccess,
    includeExcludeUsersLoading,
    usersForAccessControlLoading,
  } = useSelector(state => state?.reel);
  const dispatch = useDispatch();
  const [fetchUsers] = useFetchDataApi();
  const [updateUserAccess] = useApi();

  useEffect(() => {
    if (reelId) {
      fetchUsers({
        reelId,
        groupId,
        type: 'FETCH_USERS_FOR_ACCESS_CONTROL',
      });
    }
  }, [reelId]);

  useEffect(() => {
    if (usersForAccessControlData) {
      const { excludeUsers, includeUsers } = usersForAccessControlData;

      const includedIds = [];
      const excludedIds = [];

      const allUsers = includeUsers
        .map(user => {
          includedIds.push(user._id);

          return {
            _id: user._id,
            name: user.preferredUserName || '',
            accessStatus: true,
          };
        })
        .concat(
          excludeUsers.map(user => {
            excludedIds.push(user._id);

            return {
              _id: user._id,
              name: user.preferredUserName || '',
              accessStatus: false,
            };
          })
        );

      setCurrentExcludedIds(excludedIds);
      setCurrentIncludedIds(includedIds);
      setUserList(allUsers);
    }
  }, [usersForAccessControlData]);

  useEffect(() => {
    if (userList.length) {
      const includeUserIds = [...newIncludeUserIds];
      const excludeUserIds = [...newExcludedIds];

      userList.forEach(user => {
        if (user.accessStatus) {
          if (excludeUserIds.indexOf(user._id) > -1) {
            excludeUserIds.splice(excludeUserIds.indexOf(user._id), 1);
          }

          if (
            !currentIncludedIds.includes(user._id) &&
            !includeUserIds.includes(user._id)
          ) {
            includeUserIds.push(user._id);
          }
        } else {
          if (includeUserIds.indexOf(user._id) > -1) {
            includeUserIds.splice(includeUserIds.indexOf(user._id), 1);
          }

          if (
            !currentExcludedIds.includes(user._id) &&
            !excludeUserIds.includes(user._id)
          ) {
            excludeUserIds.push(user._id);
          }
        }
      });

      setNewExcludedIds(excludeUserIds);
      setNewIncludeUserIds(includeUserIds);
    }
  }, [userList]);

  useEffect(() => {
    if (includeExcludeUsersSuccess) {
      setIsSubmitButtonClicked(false);
      handleClose();
      handleSuccess();

      return () => dispatch({ type: INCLUDE_EXCLUDE_USERS_TO_REEL_SUCCESS });
    }
  }, [includeExcludeUsersSuccess]);

  const handleUserAccess = (accessStatus, userId) => {
    const tempUserList = [...userList];

    tempUserList.map(tempUser => {
      if (tempUser._id === userId) {
        tempUser.accessStatus = accessStatus;
      }

      return tempUser;
    });

    setUserList(tempUserList);
  };

  const renderAccessStatusList = () => {
    return userList.map((user, index) => {
      return (
        <div className="reel-view--popup-row" key={index}>
          <div className="reel-view--popup-cell-left">
            <p>{user.name}</p>
          </div>
          <div className="reel-view--popup-cell-right">
            <div className="header__switch">
              <label className="switch">
                <input
                  id="headerSwitch"
                  type="checkbox"
                  checked={user.accessStatus}
                  onClick={event =>
                    handleUserAccess(event.target.checked, user._id)
                  }
                />
                <span className="slider round"></span>
              </label>
              <p>{user.accessStatus ? 'Allow' : 'Deny'}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  const handleSubmit = () => {
    setIsSubmitButtonClicked(true);

    const includeExcludeUserData = {
      reelId: reelId,
      groupId: groupId === undefined ? null : groupId,
      includeUserIds: newIncludeUserIds,
      excludeUserIds: newExcludedIds,
    };

    updateUserAccess(
      '/reel/include-exclude-user',
      INCLUDE_EXCLUDE_USERS_TO_REEL_REQUEST,
      INCLUDE_EXCLUDE_USERS_TO_REEL_SUCCESS,
      INCLUDE_EXCLUDE_USERS_TO_REEL_FAIL,
      includeExcludeUserData,
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  return (
    <div className="reel-view--popup useraccesscontrol">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('User Access Control')}</h3>
          <h4>
            {t('Reel Name:')} {reelName}
          </h4>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row top">
            <div className="reel-view--popup-cell-left">
              <p>{t('USER NAME')}</p>
            </div>
            <div className="reel-view--popup-cell-right">
              <p>{t('STATUS')}</p>
            </div>
          </div>
          <div className="reel-view--popup-row data">
            {usersForAccessControlLoading ? (
              <DottedLoader />
            ) : (
              renderAccessStatusList()
            )}
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`btn btn--primary
                ${
                  includeExcludeUsersLoading && isSubmitButtonClicked
                    ? 'btn--loader'
                    : ''
                }`}
              onClick={() => handleSubmit()}
              disabled={!(newIncludeUserIds.length || newExcludedIds.length)}
            >
              {t('Save Changes')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useApi from '../../../../Hooks/useApi';
import {
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
} from '../../../../redux/actionTypes/category';

export default function CreateCategoryModal({
  reelId,
  handleClose,
  fetchCategories,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [createCategory] = useApi();
  const [categoryName, setCategoryName] = useState('');

  const {
    addCategorySuccess,
    addCategoryLoading,
    addCategoryError,
  } = useSelector(state => state?.category);

  useEffect(() => {
    if (addCategorySuccess) {
      dispatch({ type: ADD_CATEGORY_SUCCESS, payload: null });
      handleClose();
      fetchCategories();
    }
  }, [addCategorySuccess]);

  const handleSubmit = () => {
    if (categoryName.trim() !== '') {
      createCategory(
        `/category/add-category?reelId=${reelId}&categoryName=${categoryName.trim()}`,
        ADD_CATEGORY_REQUEST,
        ADD_CATEGORY_SUCCESS,
        ADD_CATEGORY_FAIL,
        null,
        '',
        'POST',
        null,
        'isReelService'
      );
    }
  };

  return (
    <div className="reel-view--popup createcategory">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('Create Category')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => {
              dispatch({ type: ADD_CATEGORY_SUCCESS, payload: null });
              handleClose();
            }}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{t('Category Name')}</label>
            <div className="reel-view--popup-field">
              <input
                type="text"
                placeholder={t('Type Here')}
                onChange={event => {
                  if (addCategoryError) {
                    dispatch({ type: ADD_CATEGORY_SUCCESS, payload: null });
                  }
                  setCategoryName(event.target.value);
                }}
              />
            </div>
            {addCategoryError && (
              <div className="reel-view--popup-row warning">
                <h4>
                  <span>{t('Error:')}</span>{' '}
                  {t('Category Name Already Exists!')}
                </h4>
              </div>
            )}
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`
                btn btn--primary
                ${addCategoryLoading ? 'btn--loader' : ''}
              `}
              disabled={addCategoryError || categoryName.trim() === ''}
              onClick={handleSubmit}
            >
              {t('Create')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

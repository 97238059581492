import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';

import useFetchDataApi from '../../Hooks/useFetchDataApi';
import Loader from '../../Components/Loader/loader';
import PaginationView from '../../Components/Pagination/PaginationView';
import { REMOVE_NOTIFICATION_SUCCESS } from '../../redux/actionTypes/notification';
import useDeleteApi from '../../Hooks/useDeleteApi';
import useNotificationRoute from '../../Hooks/useNotificationRoutes';
import NoContent from '../../Components/NoContent/NoContent';
import { systemConfig } from '../../Common/SystemConstConfigs';

const { notificationFilters } = systemConfig;
const PAGE_SIZE = 10;

const NotificationBulkView = () => {
  const { t } = useTranslation();
  const [linkNotification] = useNotificationRoute();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilterType, setSelectedFilterType] = useState(
    notificationFilters.all
  );
  const {
    allNotificationsData,
    allNotificationsLoading,
    allNotificationPageCount,
    deleteNotificationSuccess,
  } = useSelector(state => state?.notification);
  const { userId } = useSelector(state => state?.auth?.userData);
  const { isAdminView } = useSelector(state => state?.auth);

  const dispatch = useDispatch();

  const [fetchAllNotificationAPI] = useFetchDataApi();
  const [removeNotificationAPI] = useDeleteApi();

  const getAllNotifications = () => {
    fetchAllNotificationAPI({
      type: 'FETCH_ALL_NOTIFICATIONS',
      paginationData: {
        pageNo: currentPage,
        pageSize: PAGE_SIZE,
      },
      requestParams: {
        filterType:
          selectedFilterType.value !== '-1' ? selectedFilterType.value : null,
      },
    });
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  useEffect(() => {
    getAllNotifications();
  }, [currentPage]);

  useEffect(() => {
    getAllNotifications();
  }, [selectedFilterType]);

  useEffect(() => {
    if (!allNotificationsData?.length && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [allNotificationsData]);

  useEffect(() => {
    if (deleteNotificationSuccess) {
      getAllNotifications();

      dispatch({
        type: REMOVE_NOTIFICATION_SUCCESS,
      });
    }
  }, [deleteNotificationSuccess]);

  const removeNotification = notificationId => {
    removeNotificationAPI({
      type: 'NOTIFICATION',
      userId,
      notificationIds: [notificationId],
    });
  };

  const renderView = () => {
    return allNotificationsData?.length ? (
      allNotificationsData?.map(notificationObject => (
        <div key={notificationObject.id} className="notification-list__content">
          <div
            className="notification-list__content--leftcol"
            onClick={() => linkNotification(notificationObject)}
          >
            <h3>{notificationObject.header}</h3>
            <p>{moment.utc(notificationObject.createdAt).local().fromNow()}</p>
            <span>{notificationObject.message}</span>
          </div>
          <div className="notification-list__content--rightcol">
            <div
              className="notification-list__content--closeBtn"
              onClick={() => removeNotification(notificationObject.id)}
            >
              <svg className="icon icon--red">
                <use
                  xlinkHref={
                    require('../../assets/images/sprite/sprite.svg') +
                    '#icon-close'
                  }
                ></use>
              </svg>
            </div>
          </div>
        </div>
      ))
    ) : (
      <NoContent />
    );
  };

  const renderPagination = () => {
    return (
      <div className="all-users-table--table-footer">
        <PaginationView
          pageCount={allNotificationPageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          currentPage={currentPage}
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
        />
      </div>
    );
  };

  return (
    <div className="notification-list">
      <div className="container">
        <div className="notification-list__wrapper">
          <div className="notification-list__title">
            <h2>{t('All Notifications')}</h2>
            <Dropdown
              title="Filter By"
              options={[notificationFilters.all].concat(
                isAdminView
                  ? notificationFilters.admin
                  : notificationFilters.user
              )}
              value={selectedFilterType && selectedFilterType.label}
              onChange={option => setSelectedFilterType(option)}
            />
          </div>
          <div
            className="notification-list__content--list"
            className={`notification-list__content--list loading-screen-parent ${
              allNotificationsLoading ? 'setheight' : ''
            }`}
          >
            {allNotificationsLoading ? <Loader /> : renderView()}
          </div>
        </div>
        {allNotificationsData?.length ? renderPagination() : null}
      </div>
    </div>
  );
};

export default React.memo(NotificationBulkView);

import React, { useState, useRef } from 'react';
import { Auth } from 'aws-amplify';
import useUpdateApi from '../../../../../Hooks/useUpdateApi';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import messageConfigs from '../../../../../Helper/PopupMessageConfig';
import {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
} from '../../../../../redux/actionTypes/createUser';

export default function ChangePasswordModal({ handleClose }) {
  const { t } = useTranslation();
  const {
    changePasswordRequestLoading,
    changePasswordSuccessData,
    changePasswordRequestError,
  } = useSelector(state => state?.user);
  const [changePassword] = useUpdateApi();
  const dispatch = useDispatch();
  const currentPasswordNode = useRef();
  const newPasswordNode = useRef();
  const confirmNewPasswordNode = useRef();
  const [isValidNewPassword, setIsValidNewPassword] = useState(false);
  const [isValidCurrentPassword, setIsValidCurrentPassword] = useState(true);
  const [isChangePasswordSuccess, setIsChangePasswordSuccess] = useState(false);
  const [changePasswordInfo, setChangePasswordInfo] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const {
    currentPassword,
    newPassword,
    confirmNewPassword,
  } = changePasswordInfo;

  useEffect(() => {
    if (changePasswordSuccessData) {
      // if (changePasswordSuccessData.status) {
      setIsChangePasswordSuccess(true);
      // } else {
      //   setIsValidCurrentPassword(false);
      // }
    }
    return () => {
      if (changePasswordSuccessData) {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: null,
        });
      }
    };
  }, [changePasswordSuccessData]);

  useEffect(() => {
    return () => {
      if (changePasswordRequestError) {
        dispatch({
          type: CHANGE_PASSWORD_FAIL,
          payload: null,
        });
      }
    };
  }, [changePasswordRequestError]);

  const handleChangePasswordRequest = () => {
    if (
      currentPassword !== '' &&
      newPassword !== '' &&
      confirmNewPassword !== '' &&
      newPassword === confirmNewPassword
    ) {
      dispatch({
        type: CHANGE_PASSWORD_REQUEST,
      });
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, currentPassword, newPassword);
        })
        .then(() => {
          dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: true,
          });
        })
        .catch(err => {
          dispatch({
            type: CHANGE_PASSWORD_FAIL,
            payload: err,
          });
        });
    }
  };

  const handleInputData = (value, field) => {
    if (field === 'currentPassword') {
      setIsValidCurrentPassword(true);
    }

    const tempChangePasswordInfo = { ...changePasswordInfo };

    tempChangePasswordInfo[field] = value;

    setChangePasswordInfo(tempChangePasswordInfo);
  };

  const showHidePassword = formElement => {
    if (formElement.type === 'password') {
      formElement.type = 'text';
    } else {
      formElement.type = 'password';
    }
  };

  const renerSuccessMessage = () => {
    return (
      <>
        <div className="reel-view--popup-header">
          <h3>Success</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{messageConfigs.success.user.PASSWORD_CHANGED}</label>
          </div>
          <div className="reel-view--popup-row">
            <button className="btn btn--primary" onClick={handleClose}>
              {t('Close')}
            </button>
          </div>
        </div>
      </>
    );
  };

  const validatePassword = passwordValue => {
    setIsValidNewPassword(passwordValue.length >= 8);
  };

  const renderChangePasswordForm = () => {
    return (
      <>
        <div className="reel-view--popup-header">
          <h3>{t('Change Password')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{t('Current Password')}</label>
            <div className="reel-view--popup-field">
              <input
                id="currentPassword"
                type="password"
                placeholder={t('Password')}
                value={currentPassword}
                ref={currentPasswordNode}
                onChange={event =>
                  handleInputData(event.target.value, 'currentPassword')
                }
              />
              <button
                className="top-button"
                onClick={() => showHidePassword(currentPasswordNode.current)}
              ></button>
            </div>
            {!isValidCurrentPassword && (
              <div className="reel-view--popup-row warning">
                <h4>
                  <span>{t('Warning:')}</span>{' '}
                  {messageConfigs.error.USER.WRONG_CURRENT_PASSWORD}
                </h4>
              </div>
            )}
          </div>
          <div className="reel-view--popup-row">
            <label>{t('New Password')}</label>
            <div className="reel-view--popup-field">
              <input
                id="newPassword"
                type="password"
                placeholder={t('Password')}
                value={newPassword}
                ref={newPasswordNode}
                onChange={event =>
                  handleInputData(event.target.value, 'newPassword')
                }
                onKeyUp={event => validatePassword(event.target.value)}
              />
              <button
                className="top-button"
                onClick={() => showHidePassword(newPasswordNode.current)}
              ></button>
            </div>
            {newPassword !== '' && !isValidNewPassword && (
              <div className="reel-view--popup-row warning">
                <h4>
                  <span>{t('Warning:')}</span>{' '}
                  {messageConfigs.error.USER.INVALID_PASSWORD}
                </h4>
              </div>
            )}
          </div>
          <div className="reel-view--popup-row">
            <label>{t('Confirm Password')}</label>
            <div className="reel-view--popup-field">
              <input
                id="confirmNewPassword"
                type="password"
                placeholder={t('Password')}
                value={confirmNewPassword}
                ref={confirmNewPasswordNode}
                onChange={event =>
                  handleInputData(event.target.value, 'confirmNewPassword')
                }
              />
              <button
                className="top-button"
                onClick={() => showHidePassword(confirmNewPasswordNode.current)}
              ></button>
            </div>
            {newPassword !== '' &&
              confirmNewPassword !== '' &&
              newPassword !== confirmNewPassword && (
                <div className="reel-view--popup-row warning">
                  <h4>
                    <span>{t('Warning:')}</span>{' '}
                    {messageConfigs.error.USER.PASSWORD_NOT_MATCH}
                  </h4>
                </div>
              )}
          </div>
          <div className="reel-view--popup-row">
            {changePasswordRequestError && (
              <div className="reel-view--popup-row warning">
                <h4>
                  <span>{t('Warning:')}</span>{' '}
                  {changePasswordRequestError?.message ||
                    messageConfigs.error.CONTACT_SUPPORT}
                </h4>
              </div>
            )}
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`
                btn btn--primary
                ${changePasswordRequestLoading ? 'btn--loader' : ''}
              `}
              onClick={handleChangePasswordRequest}
              disabled={!newPassword || !currentPassword || !confirmNewPassword}
            >
              {t('Submit')}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="reel-view--popup createreel changepassword-popup">
      <div className="reel-view--popup-container">
        {isChangePasswordSuccess
          ? renerSuccessMessage()
          : renderChangePasswordForm()}
      </div>
    </div>
  );
}

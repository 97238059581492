import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useApi from '../../../../Hooks/useApi';
import {
  COPY_REEL_REQUEST,
  COPY_REEL_SUCCESS,
  COPY_REEL_FAIL,
} from '../../../../redux/actionTypes/reels';

export default function CloneReelModal({
  cloningReelId,
  handleClose,
  fetchReels,
  currentReelNames,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cloneReel] = useApi();
  const [newReelName, setNewReelName] = useState('');
  const [isReelNameExist, setIsReelNameExist] = useState(false);

  const { copyReelSuccess, copyReelError, copyReelLoading } = useSelector(
    state => state?.reel
  );

  useEffect(() => {
    if (newReelName !== '' && currentReelNames && currentReelNames.length) {
      setIsReelNameExist(
        currentReelNames.some(
          reelName => reelName.toLowerCase() === newReelName.toLowerCase()
        )
      );
    }
  }, [newReelName, currentReelNames]);

  useEffect(() => {
    if (copyReelSuccess) {
      dispatch({ type: COPY_REEL_SUCCESS, payload: null });
      handleClose();
      fetchReels();
    }
  }, [copyReelSuccess]);

  const handleSubmit = () => {
    cloneReel(
      `/reel/copy-reel?reelId=${cloningReelId}&newReelName=${newReelName}`,
      COPY_REEL_REQUEST,
      COPY_REEL_SUCCESS,
      COPY_REEL_FAIL,
      null,
      '',
      'POST',
      null,
      'isReelService'
    );
  };

  const closeModal = () => {
    dispatch({ type: COPY_REEL_SUCCESS, payload: null });
    handleClose();
  };

  return (
    <div className="reel-view--popup createreel">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('Enter a new name for the cloned reel')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => closeModal()}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{t('Reel Name')}</label>
            <div className="reel-view--popup-field">
              <input
                type="text"
                placeholder={t('Reel Name')}
                onChange={event => {
                  if (copyReelError) {
                    dispatch({ type: COPY_REEL_SUCCESS, payload: null });
                  }
                  setNewReelName(event.target.value);
                }}
              />
            </div>
            {(isReelNameExist || copyReelError) && (
              <div className="reel-view--popup-row warning">
                <h4>
                  <span>{t('Error:')}</span> {t('Reel Name Already Exists!')}
                </h4>
              </div>
            )}
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`
                btn btn--primary
                ${copyReelLoading ? 'btn--loader' : ''}
              `}
              onClick={handleSubmit}
              disabled={isReelNameExist || copyReelError}
            >
              {t('Clone')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

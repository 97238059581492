import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import HomePlayer from './HomePlayer';
import WatchReminderModal from '../../Components/Modal/WatchReminderModal';
import ImageDocumentAsssessmentViewer from '../../Components/ImageDocumentAsssessmentViewer/ImageDocumentAsssessmentViewer';
import SelectBannerTypeModal from './Modal/SelectBannerType';

import leftArrow from '../../assets/images/svg-images/reel-left-arrow.svg';
import leftArrow_light from '../../assets/images/svg-images/reel-left-arrow-light.svg';
import rightArrow from '../../assets/images/svg-images/reel-right-arrow.svg';
import rightArrow_light from '../../assets/images/svg-images/reel-right-arrow-light.svg';

import add from '../../assets/images/svg-images/icon-add.svg';
import deleteIcon_white from '../../assets/images/svg-images/icon-delete-white.svg';

import edit_white from '../../assets/images/svg-images/icon-edit-white.svg';
import upload from '../../assets/images/svg-images/icon-upload.svg';
import warning from '../../assets/images/svg-images/icon-warning.svg';
import stats from '../../assets/images/svg-images/icon-stats.svg';

// import backgroundImage from '../../assets/images/ipad-apiit.png';

import {
  REMIND_LATER_REQUEST,
  REMIND_LATER_SUCCESS,
  REMIND_LATER_FAIL,
} from '../../redux/actionTypes/assesment';

import useApi from '../../Hooks/useApi';
import { ToolTipConfig } from '../../Common/ToolTipConfig.js';
import ViewPDFModal from '../AdminDashboard/Group/Modal/viewPDFModal';

const BannerScrollPlayer = ({
  getSliderData,
  handleDelete,
  hanldeIsPublishedStatus,
  handleBannerItemEdit,
  history,
  bannerFeatureData = [],
  isBanner,
  groupId,
  assignUploadPermission,
  sliderItemDeletePermission,
  sliderItemEditPermission,
  sliderItemSuspendPermission,
}) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [selectedBannerFeaturedData, setSelectedBannerFeaturedData] =
    useState(null);
  const [isSelectBannerTypeModalOpen, setIsSelectBannerTypeModalOpen] =
    useState(false);
  const [watchLaterVideoId, setWatchLaterVideoId] = useState(null);
  const [doAssessmentLaterId, setDoAssessmentLaterId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const { themeInfo } = useSelector(state => state?.auth);
  const [sendRemindLaterRequest] = useApi();
  const [sendFavoriteRequest] = useApi();

  //background image--------------------------------------------
  const backgroundImage = themeInfo?.bannerFeaturedBackgroundUrl;

  const sendRemindLaterDetails = (
    timestamp = '',
    id = null,
    isAssessment = false
  ) => {
    let api = '';
    let requestObj = {};
    if (watchLaterVideoId || doAssessmentLaterId) {
      if (watchLaterVideoId) {
        api = '/video/update-watch-later';
        requestObj = {
          videoId: id || watchLaterVideoId,
          timestamp: timestamp
            ? moment(timestamp).format('YYYY-MM-DDTHH:mm:ss:SSZ')
            : '',
        };
      } else {
        api = '/assessment/update-do-assessment-later';
        requestObj = {
          assessmentId: doAssessmentLaterId,
          timestamp: timestamp
            ? moment(timestamp).format('YYYY-MM-DDTHH:mm:ss:SSZ')
            : '',
        };
      }
    } else if (isAssessment) {
      api = `/assessment/remove-do-assessment-later?assessmentId=${id}`;
    } else {
      api = `/video/remove-watch-later?videoId=${id}`;
    }
    sendRemindLaterRequest(
      api,
      REMIND_LATER_REQUEST,
      REMIND_LATER_SUCCESS,
      REMIND_LATER_FAIL,
      {
        ...requestObj,
      },
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  const sendFavorite = (id, voteType, isAssessment = false) => {
    sendFavoriteRequest(
      isAssessment
        ? '/assessment/update-assessment-vote'
        : '/video/update-video-vote',
      REMIND_LATER_REQUEST,
      REMIND_LATER_SUCCESS,
      REMIND_LATER_FAIL,
      {
        _id: id,
        voteType,
      },
      '',
      'PUT',
      null,
      'isReelService'
    );
  };

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <div className="slick-next" onClick={onClick}>
        <img
          src={rightArrow}
          alt="rightArrow-icon"
          className="dark-icon icon--rightArrow"
          onClick={onClick}
        />
        <img
          src={rightArrow_light}
          alt="rightArrow-icon"
          className="light-icon icon--rightArrow"
          onClick={onClick}
        />
      </div>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <div className="slick-prev" onClick={onClick}>
        <img
          src={leftArrow}
          alt="leftArrow-icon"
          className="dark-icon icon--leftArrow"
        />
        <img
          src={leftArrow_light}
          alt="leftArrow-icon"
          className="light-icon icon--leftArrow"
        />
      </div>
    );
  };

  const settings = {
    className: 'center',
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (bannerFeatureData.length && sliderIndex > -1) {
      setSelectedBannerFeaturedData(bannerFeatureData[sliderIndex]);
    }
  }, [sliderIndex, bannerFeatureData]);

  const renderSliderContent = () => {
    const content = [];
    if (bannerFeatureData.length) {
      bannerFeatureData.map((bannerFeatureContent, key) => {
        if (bannerFeatureContent.type === 'VIDEO') {
          content.push(
            <HomePlayer
              key={key}
              history={history}
              isActiveSlide={sliderIndex === key}
              content={bannerFeatureContent}
              isBanner={isBanner}
              groupId={groupId}
              addToWatchLater={id => setWatchLaterVideoId(id)}
              removeWatchLater={id => sendRemindLaterDetails(null, id)}
              addToFavorite={id => sendFavorite(id, 'favourite')}
              removeFavorite={id => sendFavorite(id, 'unfavourite')}
            />
          );
        } else {
          content.push(
            <ImageDocumentAsssessmentViewer
              key={uuidv4()}
              content={bannerFeatureContent}
              type={bannerFeatureContent.type}
              history={history}
              groupId={groupId}
              addToWatchLater={() =>
                setDoAssessmentLaterId(bannerFeatureContent._id)
              }
              removeWatchLater={() =>
                sendRemindLaterDetails(null, bannerFeatureContent._id)
              }
              addToFavorite={() =>
                sendFavorite(bannerFeatureContent._id, 'favourite', true)
              }
              removeFavorite={() =>
                sendFavorite(bannerFeatureContent._id, 'unfavourite', true)
              }
              showDocument={(url, allowDownload) =>
                setPdfUrl({
                  documentUrl: url,
                  allowDownload,
                })
              }
            />
          );
        }
      });
    }

    return content;
  };

  return (
    <>
      <div
        className="middle-video-reel banner-video"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <ul className="bottom-player theatre__assessment-pagination">
          {bannerFeatureData.length
            ? bannerFeatureData.map((bannerFeatureContent, itemIndex) => (
                <li
                  className={sliderIndex === itemIndex ? 'active' : ''}
                  key={uuidv4()}
                />
              ))
            : null}
        </ul>
        <span className="admin-player--icons">
          {assignUploadPermission && (
            <img
              src={add}
              alt="add-icon"
              className="icon--add"
              onClick={() => setIsSelectBannerTypeModalOpen(true)}
              title={
                isBanner
                  ? ToolTipConfig.adminHome.banner.add
                  : ToolTipConfig.adminHome.featured.add
              }
            />
          )}
          {sliderItemDeletePermission && selectedBannerFeaturedData && (
            <img
              src={deleteIcon_white}
              alt="delete-icon"
              className="icon--delete-white"
              title={
                isBanner
                  ? ToolTipConfig.adminHome.banner.bin
                  : ToolTipConfig.adminHome.featured.bin
              }
              onClick={() =>
                handleDelete(
                  selectedBannerFeaturedData.type,
                  selectedBannerFeaturedData._id
                )
              }
            />
          )}
        </span>
        <span className="admin-player_II--icons">
          {sliderItemEditPermission && selectedBannerFeaturedData && (
            <>
              <img
                src={edit_white}
                alt="edit-icon"
                className="icon--edit-white"
                title={
                  isBanner
                    ? ToolTipConfig.adminHome.banner.edit
                    : ToolTipConfig.adminHome.featured.edit
                }
                onClick={() =>
                  handleBannerItemEdit(
                    selectedBannerFeaturedData.type,
                    selectedBannerFeaturedData.isNativeBannerAndFeatured
                      ? selectedBannerFeaturedData.isBanner
                        ? 'banner'
                        : 'featured'
                      : '',
                    selectedBannerFeaturedData._id
                  )
                }
              />
              <hr />
            </>
          )}
          {sliderItemSuspendPermission && selectedBannerFeaturedData && (
            <>
              <img
                src={selectedBannerFeaturedData.isPublished ? warning : upload}
                title={
                  selectedBannerFeaturedData.isPublished
                    ? isBanner
                      ? ToolTipConfig.adminHome.banner.unpublish
                      : ToolTipConfig.adminHome.featured.unpublish
                    : isBanner
                    ? ToolTipConfig.adminHome.banner.publish
                    : ToolTipConfig.adminHome.featured.publish
                }
                alt={
                  selectedBannerFeaturedData.isPublished
                    ? 'warning-icon'
                    : 'upload-icon'
                }
                className={
                  selectedBannerFeaturedData.isPublished
                    ? 'icon--warning'
                    : 'icon--upload'
                }
                onClick={() =>
                  hanldeIsPublishedStatus(
                    selectedBannerFeaturedData.type,
                    selectedBannerFeaturedData._id
                  )
                }
              />
              {/* <hr /> */}
            </>
          )}
          {/* <img src={stats} alt="stats-icon" className="icon--stats" /> */}
        </span>
        <Slider {...settings} className="bottom-player">
          {renderSliderContent()}
        </Slider>
        {isSelectBannerTypeModalOpen && (
          <SelectBannerTypeModal
            groupId={groupId}
            handleClose={() => setIsSelectBannerTypeModalOpen(false)}
            isBanner={isBanner}
            history={history}
          />
        )}
        {(watchLaterVideoId || doAssessmentLaterId) && (
          <WatchReminderModal
            onHandleConfirm={value => {
              const date = new Date(
                value.year,
                value.month,
                value.date,
                value.meridiem === 'AM' ? value.hr : parseInt(value.hr) + 12,
                value.min,
                value.sec
              );
              sendRemindLaterDetails(date);
              if (watchLaterVideoId) {
                setWatchLaterVideoId(null);
              } else {
                setDoAssessmentLaterId(null);
              }
            }}
            onHandleExit={() => {
              sendRemindLaterDetails();
              if (watchLaterVideoId) {
                setWatchLaterVideoId(null);
              } else {
                setDoAssessmentLaterId(null);
              }
            }}
            onHandleCancel={() => {
              if (watchLaterVideoId) {
                setWatchLaterVideoId(null);
              } else {
                setDoAssessmentLaterId(null);
              }
            }}
          />
        )}
        {pdfUrl && (
          <ViewPDFModal
            pdfUrl={pdfUrl.documentUrl}
            allowDownload={pdfUrl.allowDownload}
            handleClose={() => setPdfUrl(null)}
          />
        )}
      </div>
    </>
  );
};

export default BannerScrollPlayer;

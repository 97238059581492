import messageConfigs from '../../Helper/PopupMessageConfig';
import { getTotalPageCount } from '../../Helper/SystemManager';
import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  FETCH_WELCOME_VIDEOS_REQUEST,
  FETCH_WELCOME_VIDEOS_SUCCESS,
  FETCH_WELCOME_VIDEOS_FAIL,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_GROUP_USERS_REQUEST,
  FETCH_GROUP_USERS_SUCCESS,
  FETCH_GROUP_USERS_FAIL,
  FETCH_SELF_SIGUNUP_USERS_REQUEST,
  FETCH_SELF_SIGUNUP_USERS_SUCCESS,
  FETCH_SELF_SIGUNUP_USERS_FAIL,
  CHANGE_USER_STATUS_REQUEST,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  RESET_PASSWORD_LINK_REQUEST,
  RESET_PASSWORD_LINK_SUCCESS,
  RESET_PASSWORD_LINK_FAIL,
  FETCH_USERS_ROLES_REQUEST,
  FETCH_USERS_ROLES_SUCCESS,
  FETCH_USERS_ROLES_FAIL,
  REMOVE_USER_FROM_GROUP_REQUEST,
  REMOVE_USER_FROM_GROUP_SUCCESS,
  REMOVE_USER_FROM_GROUP_FAIL,
  FETCH_USER_BY_ID_REQUEST,
  FETCH_USER_BY_ID_SUCCESS,
  FETCH_USER_BY_ID_FAIL,
  FETCH_USER_BY_ID_RESET,
  SELF_SIGNUP_REQUEST,
  SELF_SIGNUP_SUCCESS,
  SELF_SIGNUP_FAIL,
  SELF_SIGNUP_INITIAL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  REMOVE_WELCOME_VIDEO_SUCCESS,
  REMOVE_WELCOME_VIDEO_REQUEST,
  REMOVE_WELCOME_VIDEO_FAIL,
  DOWNLOAD_USER_REPORT_REQUEST,
  DOWNLOAD_USER_REPORT_SUCCESS,
  DOWNLOAD_USER_REPORT_FAIL,
  DOWNLOAD_USERS_LIST_REQUEST,
  DOWNLOAD_USERS_LIST_SUCCESS,
  DOWNLOAD_USERS_LIST_FAIL,
  UPDATE_SIGNUP_REQUEST,
  UPDATE_SIGNUP_SUCCESS,
  UPDATE_SIGNUP_FAIL,
  FETCH_ELIGIBLE_USERS_FOR_GROUP_REQUEST,
  FETCH_ELIGIBLE_USERS_FOR_GROUP_SUCCESS,
  FETCH_ELIGIBLE_USERS_FOR_GROUP_FAIL,
  UPDATE_LAST_ACTIVE_SUCCESS,
  FETCH_GROUPS_ROLES_BY_USERS_REQUEST,
  FETCH_GROUPS_ROLES_BY_USERS_SUCCESS,
  FETCH_GROUPS_ROLES_BY_USERS_FAIL,
  FETCH_USERS_FOR_VALIDATION_REQUEST,
  FETCH_USERS_FOR_VALIDATION_SUCCESS,
  FETCH_USERS_FOR_VALIDATION_FAIL,
  RESET_DELETE_USER_MODAL_DETAILS,
} from '../actionTypes/createUser';

const userReducer = (
  state = {
    createUserLoading: false,
    createUserSuccess: null,
    createUserError: null,
    welcomeVideoFetchLoading: false,
    welcomeVideoFetchData: [],
    welcomeVideoFetchFail: null,
    allUsersDataFetchLoading: false,
    allUsersData: [],
    allUsersDataPageCount: 1,
    allUsersTotalCount: 0,
    allUsersDataFetchError: null,
    changeUserStatusLoading: false,
    changeUserStatusSuccess: null,
    changeUserStatusError: null,
    deleteUserLoading: false,
    deleteUserData: null,
    deleteUserFail: null,
    deleteUserMessage: '',
    updateUserLoading: false,
    updateUserRequestSuccess: false,
    updateUserFail: null,
    resetPasswordLinkRequestLoading: false,
    resetPasswordLinkRequestData: null,
    resetPasswordLinkRequestFail: null,
    usersRolesFetchLoading: false,
    usersRolesFetchData: [],
    usersRolesFetchFail: null,
    removeUserFromGroupLoading: false,
    removeUserFromGroupSuccess: null,
    removeUserFromGroupError: null,
    userFetchingRequest: false,
    userInfo: null,
    userFetchingFail: false,
    selfSignupRequestLoading: false,
    selfSignupRequestSuccessData: null,
    selfSignupRequestFail: false,
    groupUsersDataFetchLoading: false,
    groupUsersData: [],
    groupUsersDataFetchError: null,
    selfSignupUsersDataFetchLoading: false,
    selfSignupUsersData: [],
    selfSignupUserPageCount: 1,
    selfSignupUsersDataFetchError: null,
    changePasswordRequestLoading: false,
    changePasswordSuccessData: null,
    changePasswordRequestError: null,
    removeWelcomeVideoLoading: false,
    removeWelcomeVideoSuccess: null,
    removeWelcomeVideoError: null,
    userReportDownloadLoading: false,
    userReportDownloadError: null,
    usersListDownloadLoading: false,
    usersListDownloadError: null,
    updateSignupRequestLoading: false,
    updateSignupRequestSuccess: null,
    updateSignupRequestError: null,
    eligibleUsersForGroupLoading: false,
    eligibleUsersForGroup: null,
    eligibleUsersForGroupError: null,
    updateLastActiveTime: null,
    userGroupRolesLoading: false,
    userGroupRoles: [],
    userGroupRolesError: null,
    allUsersDataForValidationLoading: false,
    allUsersDataForValidation: [],
    allUsersDataForValidationError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return {
        ...state,
        createUserLoading: true,
        createUserSuccess: null,
        createUserError: null,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
        createUserSuccess: action?.payload,
        createUserError: null,
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        createUserLoading: false,
        createUserSuccess: null,
        createUserError: action?.payload,
      };
    case FETCH_WELCOME_VIDEOS_REQUEST:
      return {
        ...state,
        welcomeVideoFetchLoading: true,
        welcomeVideoFetchData: [],
        welcomeVideoFetchFail: null,
      };
    case FETCH_WELCOME_VIDEOS_SUCCESS:
      return {
        ...state,
        welcomeVideoFetchLoading: false,
        welcomeVideoFetchData: action.payload,
        welcomeVideoFetchFail: null,
      };
    case FETCH_WELCOME_VIDEOS_FAIL:
      return {
        ...state,
        welcomeVideoFetchLoading: false,
        welcomeVideoFetchData: [],
        welcomeVideoFetchFail: true,
      };
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        allUsersDataFetchLoading: true,
        allUsersData: [],
        allUsersDataFetchError: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        allUsersDataFetchLoading: false,
        allUsersData: action.payload?.data || [],
        allUsersDataPageCount: getTotalPageCount(
          action.payload?.totalRecords,
          action.payload?.size
        ),
        allUsersTotalCount: action.payload?.totalRecords || 0,
        allUsersDataFetchError: null,
      };
    case FETCH_USERS_FAIL:
      return {
        ...state,
        allUsersDataFetchLoading: false,
        allUsersData: [],
        allUsersDataPageCount: 0,
        allUsersTotalCount: 0,
        allUsersDataFetchError: true,
      };
    case CHANGE_USER_STATUS_REQUEST:
      return {
        ...state,
        changeUserStatusLoading: true,
        changeUserStatusData: null,
        changeUserStatusFail: null,
      };
    case CHANGE_USER_STATUS_SUCCESS:
      return {
        ...state,
        changeUserStatusLoading: false,
        changeUserStatusData: action.payload,
        changeUserStatusFail: null,
      };
    case CHANGE_USER_STATUS_FAIL:
      return {
        ...state,
        changeUserStatusLoading: false,
        changeUserStatusData: null,
        changeUserStatusFail: true,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        deleteUserLoading: true,
        deleteUserData: null,
        deleteUserFail: null,
        deleteUserMessage: '',
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserData: action.payload,
        deleteUserFail: null,
        deleteUserMessage: '',
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserData: null,
        deleteUserFail: true,
        deleteUserMessage: action?.payload || '',
      };
    case RESET_DELETE_USER_MODAL_DETAILS:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserData: null,
        deleteUserFail: null,
        deleteUserMessage: '',
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserLoading: true,
        updateUserRequestSuccess: false,
        updateUserFail: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        updateUserRequestSuccess: action?.payload,
        updateUserFail: null,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        updateUserLoading: false,
        updateUserRequestSuccess: false,
        updateUserFail: action?.payload,
      };
    case RESET_PASSWORD_LINK_REQUEST:
      return {
        ...state,
        resetPasswordLinkRequestLoading: true,
        resetPasswordLinkRequestData: null,
        resetPasswordLinkRequestFail: null,
      };
    case RESET_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        resetPasswordLinkRequestLoading: false,
        resetPasswordLinkRequestData: action.payload,
        resetPasswordLinkRequestFail: null,
      };
    case RESET_PASSWORD_LINK_FAIL:
      return {
        ...state,
        resetPasswordLinkRequestLoading: false,
        resetPasswordLinkRequestData: null,
        resetPasswordLinkRequestFail: action.payload,
      };

    case FETCH_USERS_ROLES_REQUEST:
      return {
        ...state,
        userRolesFetchLoading: true,
        userRolesFetchData: [],
        userRolesFetchFail: null,
      };
    case FETCH_USERS_ROLES_SUCCESS:
      return {
        ...state,
        userRolesFetchLoading: false,
        userRolesFetchData:
          action?.payload?.map(item => {
            const { _id: id, ...rest } = item;
            return { id, ...rest };
          }) || [],
        userRolesFetchFail: null,
      };
    case FETCH_USERS_ROLES_FAIL:
      return {
        ...state,
        userRolesFetchLoading: false,
        userRolesFetchData: [],
        userRolesFetchFail: true,
      };

    case REMOVE_USER_FROM_GROUP_REQUEST:
      return {
        ...state,
        removeUserFromGroupLoading: true,
        removeUserFromGroupSuccess: null,
        removeUserFromGroupError: null,
      };
    case REMOVE_USER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        removeUserFromGroupLoading: null,
        removeUserFromGroupSuccess: true,
        removeUserFromGroupError: null,
      };
    case REMOVE_USER_FROM_GROUP_FAIL:
      return {
        ...state,
        removeUserFromGroupLoading: null,
        removeUserFromGroupSuccess: null,
        removeUserFromGroupError: true,
      };

    case FETCH_USER_BY_ID_REQUEST:
      return {
        ...state,
        userFetchingRequest: true,
        userInfo: null,
        userFetchingFail: false,
      };
    case FETCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userFetchingRequest: false,
        userInfo: {
          ...action?.payload,
          userData: {
            ...action?.payload?.userData,
            id: action?.payload?.userData?._id,
          },
        },
        userFetchingFail: false,
      };
    case FETCH_USER_BY_ID_FAIL:
      return {
        ...state,
        userFetchingRequest: false,
        userInfo: null,
        userFetchingFail: true,
      };

    case FETCH_USER_BY_ID_RESET:
      return {
        ...state,
        userFetchingRequest: false,
        userInfo: null,
        userFetchingFail: null,
      };

    case SELF_SIGNUP_INITIAL:
      return {
        ...state,
        selfSignupRequestLoading: false,
        selfSignupRequestSuccessData: null,
        selfSignupRequestFail: false,
      };

    case SELF_SIGNUP_REQUEST:
      return {
        ...state,
        selfSignupRequestLoading: true,
        selfSignupRequestSuccessData: null,
        selfSignupRequestFail: false,
      };

    case SELF_SIGNUP_SUCCESS:
      return {
        ...state,
        selfSignupRequestLoading: false,
        selfSignupRequestSuccessData: true,
        selfSignupRequestFail: false,
      };

    case SELF_SIGNUP_FAIL:
      return {
        ...state,
        selfSignupRequestLoading: false,
        selfSignupRequestSuccessData: null,
        selfSignupRequestFail: action.payload,
      };

    case FETCH_GROUP_USERS_REQUEST:
      return {
        ...state,
        groupUsersDataFetchLoading: true,
        groupUsersData: [],
        groupUsersDataFetchError: null,
      };

    case FETCH_GROUP_USERS_SUCCESS:
      return {
        ...state,
        groupUsersDataFetchLoading: false,
        groupUsersData: action.payload,
        groupUsersDataFetchError: null,
      };

    case FETCH_GROUP_USERS_FAIL:
      return {
        ...state,
        groupUsersDataFetchLoading: false,
        groupUsersData: [],
        groupUsersDataFetchError: true,
      };

    case FETCH_SELF_SIGUNUP_USERS_REQUEST:
      return {
        ...state,
        selfSignupUsersDataFetchLoading: true,
        selfSignupUsersData: [],
        selfSignupUsersDataFetchError: null,
      };

    case FETCH_SELF_SIGUNUP_USERS_SUCCESS:
      return {
        ...state,
        selfSignupUsersDataFetchLoading: false,
        selfSignupUsersData: action.payload?.data || [],
        selfSignupUserPageCount: getTotalPageCount(
          action.payload?.totalRecords,
          action.payload?.size
        ),
        selfSignupUsersDataFetchError: null,
      };

    case FETCH_SELF_SIGUNUP_USERS_FAIL:
      return {
        ...state,
        selfSignupUsersDataFetchLoading: false,
        selfSignupUsersData: [],
        selfSignupUsersDataFetchError: true,
      };

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordRequestLoading: true,
        changePasswordSuccessData: null,
        changePasswordRequestError: null,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordRequestLoading: false,
        changePasswordSuccessData: action.payload,
        changePasswordRequestError: null,
      };

    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changePasswordRequestLoading: false,
        changePasswordSuccessData: null,
        changePasswordRequestError: action.payload,
      };

    case REMOVE_WELCOME_VIDEO_REQUEST:
      return {
        ...state,
        removeWelcomeVideoLoading: true,
        removeWelcomeVideoSuccess: null,
        removeWelcomeVideoError: null,
      };

    case REMOVE_WELCOME_VIDEO_SUCCESS:
      return {
        ...state,
        removeWelcomeVideoLoading: false,
        removeWelcomeVideoSuccess: action.payload,
        removeWelcomeVideoError: null,
      };

    case REMOVE_WELCOME_VIDEO_FAIL:
      return {
        ...state,
        removeWelcomeVideoLoading: false,
        removeWelcomeVideoSuccess: null,
        removeWelcomeVideoError: action.payload,
      };

    case DOWNLOAD_USER_REPORT_REQUEST:
      return {
        ...state,
        userReportDownloadLoading: true,
        userReportDownloadError: null,
      };

    case DOWNLOAD_USER_REPORT_SUCCESS:
      return {
        ...state,
        userReportDownloadLoading: false,
        userReportDownloadError: null,
      };

    case DOWNLOAD_USER_REPORT_FAIL:
      return {
        ...state,
        userReportDownloadLoading: false,
        userReportDownloadError: {
          message: messageConfigs.error.USER.DOWNLOAD_USER_REPORT,
          success: false,
        },
      };

    case DOWNLOAD_USERS_LIST_REQUEST:
      return {
        ...state,
        usersListDownloadLoading: true,
        usersListDownloadError: null,
      };

    case DOWNLOAD_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersListDownloadLoading: false,
        usersListDownloadError: null,
      };

    case DOWNLOAD_USERS_LIST_FAIL:
      return {
        ...state,
        usersListDownloadLoading: false,
        usersListDownloadError: {
          message: messageConfigs.error.USER.DOWNLOAD_USERS_LIST,
          success: false,
        },
      };

    case UPDATE_SIGNUP_REQUEST:
      return {
        ...state,
        updateSignupRequestLoading: true,
        updateSignupRequestSuccess: null,
        updateSignupRequestError: null,
      };

    case UPDATE_SIGNUP_SUCCESS:
      return {
        ...state,
        updateSignupRequestLoading: false,
        updateSignupRequestSuccess: action?.payload || {
          message: messageConfigs.success.user.USER_REQUEST_ACCEPTED,
          success: true,
        },
        updateSignupRequestError: null,
      };

    case UPDATE_SIGNUP_FAIL:
      return {
        ...state,
        updateSignupRequestLoading: false,
        updateSignupRequestSuccess: null,
        updateSignupRequestError: {
          isSuccess: false,
          message: action?.payload,
        },
      };

    case FETCH_ELIGIBLE_USERS_FOR_GROUP_REQUEST:
      return {
        ...state,
        eligibleUsersForGroupLoading: true,
        eligibleUsersForGroup: null,
        eligibleUsersForGroupError: null,
      };

    case FETCH_ELIGIBLE_USERS_FOR_GROUP_SUCCESS:
      return {
        ...state,
        eligibleUsersForGroupLoading: false,
        eligibleUsersForGroup: action?.payload,
        eligibleUsersForGroupError: null,
      };

    case FETCH_ELIGIBLE_USERS_FOR_GROUP_FAIL:
      return {
        ...state,
        eligibleUsersForGroupLoading: false,
        eligibleUsersForGroup: null,
        eligibleUsersForGroupError: action?.payload,
      };

    case UPDATE_LAST_ACTIVE_SUCCESS:
      return {
        ...state,
        updateLastActiveTime: action?.payload,
      };

    case FETCH_GROUPS_ROLES_BY_USERS_REQUEST:
      return {
        ...state,
        userGroupRolesLoading: true,
        userGroupRoles: [],
        userGroupRolesError: null,
      };

    case FETCH_GROUPS_ROLES_BY_USERS_SUCCESS:
      return {
        ...state,
        userGroupRolesLoading: false,
        userGroupRoles: action?.payload?.data || [],
        userGroupRolesError: null,
      };

    case FETCH_GROUPS_ROLES_BY_USERS_FAIL:
      return {
        ...state,
        userGroupRolesLoading: false,
        userGroupRoles: [],
        userGroupRolesError: action?.payload || true,
      };

    case FETCH_USERS_FOR_VALIDATION_REQUEST:
      return {
        ...state,
        allUsersDataForValidationLoading: true,
        allUsersDataForValidation: [],
        allUsersDataForValidationError: null,
      };

    case FETCH_USERS_FOR_VALIDATION_SUCCESS:
      return {
        ...state,
        allUsersDataForValidationLoading: false,
        allUsersDataForValidation: action?.payload?.data || [],
        allUsersDataForValidationError: null,
      };

    case FETCH_USERS_FOR_VALIDATION_FAIL:
      return {
        ...state,
        allUsersDataForValidationLoading: false,
        allUsersDataForValidation: [],
        allUsersDataForValidationError: action?.payload || true,
      };

    default:
      return state;
  }
};

export default userReducer;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import Auth from '@aws-amplify/auth';
import { useLocation } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { isMobile } from 'react-device-detect';

import NavDropdown from './NavDropdown/NavDropdown';
import NotificationDropdown from './NavDropdown/NotificationDropdown';
import notification from '../../assets/images/svg-images/icon-notification.svg';
import usePrivilegesApi from '../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../Common/UserPrivileges';
import Loader from '../../Components/Loader/loader';
import useApi from '../../Hooks/useApi';
import { systemConfig } from '../../Common/SystemConstConfigs';
import {
  CHANGE_ADMIN_VIEW,
  ADD_ADMIN_USER,
  ADD_DISCARDCHANGE_POPUP_OPEN,
} from '../../redux/action/actionTypes';
import {
  CLEAR_SESSION,
  FETCH_USER_DATA_FAIL,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
} from '../../redux/actionTypes/auth';

import {
  UPDATE_LAST_ACTIVE_FAIL,
  UPDATE_LAST_ACTIVE_REQUEST,
  UPDATE_LAST_ACTIVE_SUCCESS,
} from '../../redux/actionTypes/createUser';
import useFetchDataApi from '../../Hooks/useFetchDataApi';

const { superAdmin } = systemConfig.roleIdByName;

const Header = ({ history }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { currentGroupId } = useSelector(state => state?.group);
  const isHaveGroupAccess = usePrivilegesApi(
    PrivilegeTypes.GROUP.HAVE_GROUP_ROLE,
    currentGroupId
  );

  const [headerData, setHeaderDate] = useState({
    isMobileMenuOpen: false,
    mobileMenuClass: '',
    mobileMenu: 'icon-menu-icon',
  });
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [helpVideos, setHelpVideos] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isShowNavingationDropdown, setIsShowNavingationDropdown] =
    useState(false);
  const { access_token, userData, isAdminView, isAdminUser, themeInfo } =
    useSelector(state => state.auth && state.auth);

  const { headerLogoUrl, defaultProfileImageUrl, headerMenu = [] } = themeInfo;

  const isAssessmentInprogress = useSelector(
    state => state.assessment && state.assessment.isAssessmentInprogress
  );
  const { notificationIconCount } = useSelector(state => state?.notification);
  const { intializehomeDataData } = useSelector(state => state?.homeData);
  const { lastMessage } = useSelector(state => state?.websocket);

  const dispatch = useDispatch();

  const [getUnreadNotificationsCount] = useFetchDataApi();
  const [userDataRequest] = useApi();
  const [updateLastActiveTime] = useApi();

  useEffect(() => {
    if (lastMessage?.notificationType) {
      getUnreadNotificationsCount({ type: 'UNREAD_NOTIFICATION_COUNT' });
    }
  }, [JSON.stringify(lastMessage)]);

  useEffect(() => {
    setIsNotificationOpen(false);
    getUnreadNotificationsCount({ type: 'UNREAD_NOTIFICATION_COUNT' });
  }, [isAdminView]);

  // Checking if current user is a super admin
  useEffect(() => {
    if (userData?.roleList?.length > 0) {
      const tempRoleList = [...userData.roleList];
      const roleIds = tempRoleList.map(({ id }) => id);
      setIsSuperAdmin(roleIds.includes(superAdmin.roleReferenceId));

      // Updating user and admin mode switching changes when user data is updating
      if (tempRoleList.some(({ id }) => id === 1 || id === 2 || id === 5)) {
        dispatch({ type: ADD_ADMIN_USER, payload: true });
      } else {
        dispatch({ type: ADD_ADMIN_USER, payload: false });
      }
    }
  }, [dispatch, userData]);

  // Calling every minute to update the group and roles data
  useEffect(() => {
    let interval;

    if (access_token && userData) {
      interval = setInterval(() => {
        userDataRequest(
          `/user/getUserData?username=${userData.userName}`,
          FETCH_USER_DATA_REQUEST,
          FETCH_USER_DATA_SUCCESS,
          FETCH_USER_DATA_FAIL,
          null,
          null,
          'GET',
          '',
          'isUserService'
        );
      }, systemConfig.timeInterval.updateUserData);
    }
    return () => clearInterval(interval);
  }, [access_token, userData, userDataRequest]);

  // Calling every 30 minutes to update the last active time
  useEffect(() => {
    let updateLastActiveInterval;

    updateLastActiveInterval = setInterval(() => {
      updateLastActiveTime(
        '/user/updateUser',
        UPDATE_LAST_ACTIVE_REQUEST,
        UPDATE_LAST_ACTIVE_SUCCESS,
        UPDATE_LAST_ACTIVE_FAIL,
        null,
        null,
        'PUT',
        '',
        'isUserService'
      );
    }, systemConfig.timeInterval.updateLastActivity);

    return () => clearInterval(updateLastActiveInterval);
  }, [updateLastActiveTime]);

  // Check if current user's group roles
  useEffect(() => {
    if (userData && currentGroupId && !isSuperAdmin && !isHaveGroupAccess) {
      if (userData.groupInfo?.length > 0) {
        // If Current group dont have any admin roles for current user then re-route to home page.
        history.push('/node-tree');
      } else {
        // If current user dont have any groups assigned then logout.
        Auth.signOut();
        dispatch({
          type: CLEAR_SESSION,
        });
      }
    }
  }, [
    userData,
    currentGroupId,
    isSuperAdmin,
    isHaveGroupAccess,
    history,
    dispatch,
  ]);

  useEffect(() => {
    if (access_token) {
      getUnreadNotificationsCount({ type: 'UNREAD_NOTIFICATION_COUNT' });
    }
  }, [access_token, userData.userId]);

  useEffect(() => {
    if (
      !isAdminView &&
      access_token &&
      intializehomeDataData &&
      Object.keys(intializehomeDataData).length !== 0 &&
      intializehomeDataData.constructor === Object
    ) {
      const videoReelArray = [];
      const { videoReels } = intializehomeDataData;

      videoReels.forEach(data => {
        if (data.videos && data.videos.length) {
          videoReelArray.push({
            label: data.reelName,
            value: data._id,
          });
        }
      });

      setHelpVideos(videoReelArray);
    }
  }, [intializehomeDataData, access_token, isAdminView]);

  const mobileMenuClick = () => {
    if (headerData.isMobileMenuOpen) {
      setHeaderDate({
        ...headerData,
        isMobileMenuOpen: false,
        mobileMenuClass: 'off-canvas',
        mobileMenu: 'icon-menu-icon',
      });
    } else {
      setHeaderDate({
        ...headerData,
        isMobileMenuOpen: true,
        mobileMenuClass: 'on-canvas',
        mobileMenu: 'icon-close',
      });
    }
  };
  const onAdminView = () => {
    dispatch({
      type: CHANGE_ADMIN_VIEW,
      payload: true,
    });
  };
  const onUserView = () => {
    dispatch({
      type: CHANGE_ADMIN_VIEW,
      payload: false,
    });
  };
  const { mobileMenuClass, mobileMenu } = headerData;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset === 0) {
        setIsScrolled(false);
      } else if (!isScrolled) {
        setIsScrolled(true);
      }
    });
  }, [isScrolled]);

  useEffect(() => {
    setIsShowNavingationDropdown(false);
  }, [location]);

  return !access_token ? (
    <Loader />
  ) : (
    <div className={`header ${window.pageYOffset !== 0 ? 'background' : ''}`}>
      <div className="container">
        <div className="header__icons">
          <div className="header__mobile-menu" onClick={mobileMenuClick}>
            <svg className="icon icon--red">
              <use
                xlinkHref={`${require('../../assets/images/sprite/sprite.svg')}#${mobileMenu}`}
              />
            </svg>
          </div>
        </div>
        <div className="header__content">
          <div
            className="header__logo"
            onClick={() => {
              if (isAssessmentInprogress) {
                dispatch({
                  type: ADD_DISCARDCHANGE_POPUP_OPEN,
                  payload: true,
                });
              }
              !isAssessmentInprogress &&
                history.push(`/${isAdminView ? 'node-tree' : 'home'}`);
            }}
          >
            <img src={headerLogoUrl} alt="logo-icon" className="icon--logo" />
          </div>

          {!isAssessmentInprogress &&
          !isAdminView &&
          !history?.location?.pathname?.includes('rms') ? (
            <div className="header__nav">
              <ul>
                {headerMenu.map((menu, index) => (
                  <li
                    className="header__nav-item"
                    key={index}
                    onClick={() => {
                      if (menu.visible) {
                        const root = document.getElementById('root');
                        root.className = 'fade';
                        setTimeout(() => {
                          history.push(
                            `/reel-bulk-view/video/reel/${menu.value}`
                          );
                        }, 1000);
                      }
                    }}
                  >
                    {t(menu.label)}
                  </li>
                ))}
                <li>
                  <Dropdown
                    title="Select Reel"
                    options={helpVideos}
                    placeholder={t('Select Reel')}
                    onChange={({ value }) => {
                      history.push(`/reel-bulk-view/video/${value}`);
                    }}
                  />
                </li>
              </ul>
            </div>
          ) : null}

          <div className="header__user">
            <ul>
              {process.env.REACT_APP_IS_RMS_ENABLED?.toLowerCase() ===
              'true' ? (
                <li
                  className="header__nav-item"
                  onClick={() => {
                    history.push(`/rms`);
                  }}
                >
                  RMS
                </li>
              ) : null}
              <li
                className="header__nav-item notification"
                onClick={() => {
                  setIsNotificationOpen(!isNotificationOpen);
                }}
              >
                <img
                  src={notification}
                  alt="notification-icon"
                  className="notification__icon"
                />
                {notificationIconCount ? (
                  <span className="notification__count">
                    {notificationIconCount}
                  </span>
                ) : null}
                <div className="notification__dropdown">
                  {!isMobile && (
                    <NotificationDropdown
                      isNotificationOpen={isNotificationOpen}
                      history={history}
                    />
                  )}
                </div>
              </li>
              <li className="header__nav-item user">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setIsShowNavingationDropdown(false);
                  }}
                >
                  <div
                    className="user_avatar"
                    style={{
                      backgroundImage: `url(${
                        userData &&
                        (userData.profilePicUrl || defaultProfileImageUrl)
                      })`,
                    }}
                    onMouseEnter={() => setIsShowNavingationDropdown(true)}
                    onMouseLeave={() => setIsShowNavingationDropdown(false)}
                    onClick={event => {
                      if (event.target.classList.value === 'user_avatar') {
                        setIsShowNavingationDropdown(true);
                      }
                    }}
                  >
                    {isShowNavingationDropdown && (
                      <NavDropdown
                        isAssessmentInprogress={isAssessmentInprogress}
                        isAdminUser={isAdminUser}
                        handleAminView={onAdminView}
                        handleUserView={onUserView}
                        isAdminView={isAdminView}
                        history={history}
                        isSuperAdmin={isSuperAdmin}
                      />
                    )}
                  </div>
                </OutsideClickHandler>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Mobile Menu Start */}
      {isMobile && (
        <div className={`header__off-canvas-menu ${mobileMenuClass}`}>
          {!isAssessmentInprogress &&
          !isAdminView &&
          !history?.location?.pathname?.includes('rms') ? (
            <div className="header__nav">
              <ul>
                {headerMenu.map((menu, index) => (
                  <li
                    className="header__nav-item"
                    key={index}
                    onClick={() => {
                      const root = document.getElementById('root');
                      root.className = 'fade';
                      setTimeout(() => {
                        history.push(
                          `/reel-bulk-view/video/reel/${menu.value}`
                        );
                      }, 1000);
                    }}
                  >
                    {t(menu.label)}
                    {/*
                    <span className="admin-nav--icons">
                      <img src={edit} alt="edit-icon" className="icon--edit" />
                      <hr />
                      <img src={deleteIcon} alt="delete-icon" className="icon--delete" />
                    </span>
                  */}
                  </li>
                ))}
                <li>
                  <Dropdown
                    title="Select Reel"
                    options={helpVideos}
                    placeholder={t('Select Reel')}
                    onChange={({ value }) => {
                      history.push(`/reel-bulk-view/video/${value}`);
                    }}
                  />
                </li>
              </ul>
            </div>
          ) : null}

          <div className="header__user">
            <ul>
              <li
                className="header__nav-item notification"
                onClick={() => {
                  setIsNotificationOpen(!isNotificationOpen);
                }}
              >
                <img
                  src={notification}
                  alt="notification-icon"
                  className="notification__icon"
                />
                {notificationIconCount ? (
                  <span className="notification__count">
                    {notificationIconCount}
                  </span>
                ) : null}
                <div className="notification__dropdown">
                  <NotificationDropdown
                    isNotificationOpen={isNotificationOpen}
                    history={history}
                  />
                </div>
              </li>
              <li className="header__nav-item user">
                <div
                  className="user_avatar"
                  style={{
                    backgroundImage: `url(${
                      userData &&
                      (userData.profilePicUrl || defaultProfileImageUrl)
                    })`,
                  }}
                />
              </li>
              <li className="header__nav-item mobileMenu">
                <NavDropdown
                  isAssessmentInprogress={isAssessmentInprogress}
                  isAdminUser={isAdminUser}
                  handleAminView={onAdminView}
                  handleUserView={onUserView}
                  isAdminView={isAdminView}
                  history={history}
                  isSuperAdmin={isSuperAdmin}
                  isMobileView
                />
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

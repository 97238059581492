import React, { useEffect, useState } from 'react';
import MandatoryMark from '../validation/MandatoryMark';
import { useTranslation } from 'react-i18next';
import UploadItem from '../../Components/UploadProgressBar/UploadProgressBar';
import useFileUpload from '../../Hooks/useFileUpload';
const PreviewImageView = ({
  title,
  imageSrc,
  isImageViaUrl,
  imageUrl,
  handleData,
  isImageViaUrlPropertyName,
  previewImgUrlPropertyName,
  previewImagePropertyName,
  isValidUrl,
  setIsValidUrl,
}) => {
  const { t } = useTranslation();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [localFileUrl, setLocalFileUrl] = useState('');

  useEffect(() => {
    setIsValidUrl(true);
    setLocalFileUrl(imageSrc);
  }, [imageSrc]);

  const [uploadFile, fileUrl, fileUploadingProgress] = useFileUpload();

  const handleFileAccept = event => {
    if (event?.target?.files?.length) {
      const file = event.target.files[0];
      setLocalFileUrl(URL.createObjectURL(file));
      uploadFile(file);
    }
  };

  const addFileFromUrl = (value, name) => {
    setLocalFileUrl(value);
    handleData(value, name);
  };

  useEffect(() => {
    setShowProgressBar(false);
    if (fileUploadingProgress !== 0) {
      setShowProgressBar(true);
    } else if (showProgressBar) {
      setShowProgressBar(false);
    }
  }, [fileUploadingProgress]);

  useEffect(() => {
    handleData(fileUrl, previewImgUrlPropertyName);
  }, [fileUrl]);

  return (
    <div className="admin__createvideo--video-preview">
      <p>
        {t(title)} <MandatoryMark />
      </p>
      <img
        src={localFileUrl}
        alt="video-preview-img"
        className="admin__createvideo--video-preview-img"
        onError={event => {
          event.target.onerror = null;
          setIsValidUrl(false);
        }}
      />
      {showProgressBar && <UploadItem progress={fileUploadingProgress} />}
      <form>
        <input
          readOnly
          type="radio"
          className="form-input--checkbox"
          id="upload-preview-url"
          name="upload-preview-url"
          checked={!isImageViaUrl}
        />
        <label onClick={() => handleData(false, isImageViaUrlPropertyName)}>
          {t('Upload Preview Image')}
        </label>
        <input
          type="file"
          id="upload-preview-img"
          name="upload-preview-img"
          placeholder={t('Image Title')}
          accept="image/png, image/jpeg"
          onChange={e => handleFileAccept(e)}
        />
        <input
          readOnly
          type="radio"
          className="form-input--checkbox"
          id="upload-preview-url"
          name="upload-preview-url"
          checked={isImageViaUrl}
        />
        <label onClick={() => handleData(true, isImageViaUrlPropertyName)}>
          {t('Upload Preview Image via url')}
        </label>
        <input
          type="text"
          id="upload-preview-url"
          name="upload-preview-url"
          placeholder={t('URL')}
          value={imageUrl || ''}
          onChange={e =>
            addFileFromUrl(e.target.value, previewImgUrlPropertyName)
          }
        />
      </form>
      {!isValidUrl && (
        <h5 className="error-message">
          {t('Please provide a valid image url')}
        </h5>
      )}
    </div>
  );
};

export default PreviewImageView;

import { useState, useEffect } from 'react';

import useApi from '../Hooks/useApi';
import {
  FETCH_ALL_GROUPS_REQUEST,
  FETCH_ALL_GROUPS_SUCCESS,
  FETCH_ALL_GROUPS_FAIL,
  FETCH_USER_LIST_REQUEST,
  FETCH_USER_LIST_SUCCESS,
  FETCH_USER_LIST_FAIL,
  FETCH_PARENT_GROUP_ROLES_BY_USER_REQUEST,
  FETCH_PARENT_GROUP_ROLES_BY_USER_SUCCESS,
  FETCH_PARENT_GROUP_ROLES_BY_USER_FAIL,
  FETCH_SELF_ASSIGNED_ENABLED_GROUPS_REQUEST,
  FETCH_SELF_ASSIGNED_ENABLED_GROUPS_SUCCESS,
  FETCH_SELF_ASSIGNED_ENABLED_GROUPS_FAIL,
  FETCH_SIGNUP_ENABLED_GROUPS_REQUEST,
  FETCH_SIGNUP_ENABLED_GROUPS_SUCCESS,
  FETCH_SIGNUP_ENABLED_GROUPS_FAIL,
  FETCH_GROUPS_BY_USER_REQUEST,
  FETCH_GROUPS_BY_USER_SUCCESS,
  FETCH_GROUPS_BY_USER_FAIL,
} from '../redux/actionTypes/Group';
import {
  FETCH_ALL_VIDEOS_BY_GROUP_REQUEST,
  FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS,
  FETCH_ALL_VIDEOS_BY_GROUP_FAIL,
  INTIALIZE_VIDEO_REQUEST,
  INTIALIZE_VIDEO_SUCCESS,
  INTIALIZE_VIDEO_FAIL,
  FIND_VIDEO_BY_ID_REQUEST,
  FIND_VIDEO_BY_ID_SUCCESS,
  FIND_VIDEO_BY_ID_FAIL,
} from '../redux/actionTypes/video';
import {
  FETCH_ALL_DOCUMENTS_BY_GROUP_REQUEST,
  FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS,
  FETCH_ALL_DOCUMENTS_BY_GROUP_FAIL,
  INTIALIZE_DOCUMENT_REQUEST,
  INTIALIZE_DOCUMENT_SUCCESS,
  INTIALIZE_DOCUMENT_FAIL,
  FIND_DOCUMENT_BY_ID_REQUEST,
  FIND_DOCUMENT_BY_ID_DATA,
  FIND_DOCUMENT_BY_ID_FAIL,
  FETCH_DOCUMENT_URL_BY_ID_REQUEST,
  FETCH_DOCUMENT_URL_BY_ID_SUCCESS,
  FETCH_DOCUMENT_URL_BY_ID_FAIL,
} from '../redux/actionTypes/documentData';
import {
  FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_REQUEST,
  FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS,
  FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_FAIL,
  FETCH_MID_END_ASSESSMENTS_BY_GROUP_REQUEST,
  FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA,
  FETCH_MID_END_ASSESSMENTS_BY_GROUP_FAIL,
  FETCH_CREATE_ASSESSMENT_RELATED_INFO_REQUEST,
  FETCH_CREATE_ASSESSMENT_RELATED_INFO_DATA,
  FETCH_CREATE_ASSESSMENT_RELATED_INFO_FAIL,
  FIND_ASSESSMENT_BY_ID_REQUEST,
  FIND_ASSESSMENT_BY_ID_SUCCESS,
  FIND_ASSESSMENT_BY_ID_FAIL,
  FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_REQUEST,
  FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_SUCCESS,
  FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_FAIL,
  FETCH_ASSESSMENT_SCORES_SUCCESS,
  FETCH_ASSESSMENT_SCORES_REQUEST,
  FETCH_ASSESSMENT_SCORES_FAIL,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_REQUEST,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_SUCCESS,
  DOWNLOAD_ASSESSMENT_TRANSCRIPT_FAIL,
} from '../redux/actionTypes/assesment';
import {
  FETCH_REELS_BY_GROUP_REQUEST,
  FETCH_REELS_BY_GROUP_SUCCESS,
  FETCH_REELS_BY_GROUP_FAIL,
  FETCH_DOCUMENT_REELS_BY_GROUP_REQUEST,
  FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS,
  FETCH_DOCUMENT_REELS_BY_GROUP_FAIL,
  FETCH_MANDATORY_REELS_BY_GROUP_REQUEST,
  FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS,
  FETCH_MANDATORY_REELS_BY_GROUP_FAIL,
  FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_REQUEST,
  FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
  FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_FAIL,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_REQUEST,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_SUCCESS,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_FAIL,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_REQUEST,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_SUCCESS,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_FAIL,
  FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_FAIL,
  FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_REQUEST,
  FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_SUCCESS,
  FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_REQUEST,
  FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_SUCCESS,
  FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_FAIL,
  FETCH_USERS_FOR_ACCESS_CONTROL_REQUEST,
  FETCH_USERS_FOR_ACCESS_CONTROL_SUCCESS,
  FETCH_USERS_FOR_ACCESS_CONTROL_FAIL,
  FETCH_REEL_RENAME_WARNINGS_REQUEST,
  FETCH_REEL_RENAME_WARNINGS_SUCCESS,
  FETCH_REEL_RENAME_WARNINGS_FAIL,
} from '../redux/actionTypes/reels';
import {
  INTIALIZE_HOME_DATA_REQUEST,
  INTIALIZE_HOME_DATA_SUCCESS,
  INTIALIZE_HOME_DATA_FAIL,
  FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST,
  FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS,
  FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL,
  FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST,
  FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS,
  FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL,
} from '../redux/actionTypes/homeData';
import {
  FETCH_BANNER_FEATURED_IMAGE_BY_ID_REQUEST,
  FETCH_BANNER_FEATURED_IMAGE_BY_ID_SUCCESS,
  FETCH_BANNER_FEATURED_IMAGE_BY_ID_FAIL,
  FETCH_IMAGE_URL_BY_ID_REQUEST,
  FETCH_IMAGE_URL_BY_ID_FAIL,
  FETCH_IMAGE_URL_BY_ID_SUCCESS,
} from '../redux/actionTypes/bannerAndFeatured';
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_USERS_ROLES_REQUEST,
  FETCH_USERS_ROLES_SUCCESS,
  FETCH_USERS_ROLES_FAIL,
  FETCH_GROUP_USERS_REQUEST,
  FETCH_GROUP_USERS_SUCCESS,
  FETCH_GROUP_USERS_FAIL,
  FETCH_SELF_SIGUNUP_USERS_REQUEST,
  FETCH_SELF_SIGUNUP_USERS_SUCCESS,
  FETCH_SELF_SIGUNUP_USERS_FAIL,
  FETCH_WELCOME_VIDEOS_REQUEST,
  FETCH_WELCOME_VIDEOS_SUCCESS,
  FETCH_WELCOME_VIDEOS_FAIL,
  FETCH_USER_BY_ID_REQUEST,
  FETCH_USER_BY_ID_SUCCESS,
  FETCH_USER_BY_ID_FAIL,
  FETCH_ELIGIBLE_USERS_FOR_GROUP_REQUEST,
  FETCH_ELIGIBLE_USERS_FOR_GROUP_SUCCESS,
  FETCH_ELIGIBLE_USERS_FOR_GROUP_FAIL,
  FETCH_GROUPS_ROLES_BY_USERS_REQUEST,
  FETCH_GROUPS_ROLES_BY_USERS_SUCCESS,
  FETCH_GROUPS_ROLES_BY_USERS_FAIL,
  FETCH_USERS_FOR_VALIDATION_REQUEST,
  FETCH_USERS_FOR_VALIDATION_SUCCESS,
  FETCH_USERS_FOR_VALIDATION_FAIL,
} from '../redux/actionTypes/createUser';
import {
  FETCH_ALL_NOTIFICATION_FAIL,
  FETCH_ALL_NOTIFICATION_REQUEST,
  FETCH_ALL_NOTIFICATION_SUCCESS,
  FETCH_EMAIL_NOTICATION_STATUS_FAIL,
  FETCH_EMAIL_NOTICATION_STATUS_REQUEST,
  FETCH_EMAIL_NOTICATION_STATUS_SUCCESS,
  FETCH_NOTIFICATION_FAIL,
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SETTINGS_FAIL,
  FETCH_NOTIFICATION_SETTINGS_REQUEST,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_NOTIFICATION_SUCCESS,
  NOTIFICATION_ICON_COUNT_FAIL,
  NOTIFICATION_ICON_COUNT_REQUEST,
  NOTIFICATION_ICON_COUNT_SUCCESS,
} from '../redux/actionTypes/notification';
import {
  FETCH_COMPANY_PROFILE_FAIL,
  FETCH_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_S3_BUCKET_DATA_REQUEST,
  FETCH_S3_BUCKET_DATA_SUCCESS,
  FETCH_S3_BUCKET_DATA_FAIL,
} from '../redux/actionTypes/auth';
import { systemConfig } from '../Common/SystemConstConfigs';
import { useSelector } from 'react-redux';

const { notificationViewType } = systemConfig;

function useFetchDataApi() {
  const [fetchRequestData, setFetchRequestData] = useState({
    type: '',
    reelId: '',
    groupId: '',
    reelType: '',
    objectId: '',
    notificationType: '',
    idList: null,
    paginationData: {
      pageNo: 1,
      pageSize: 10,
      searchKey: '',
    },
    requestParams: {},
  });

  const { isAdminView } = useSelector(state => state?.auth);
  const { userId: loggedInUserId } = useSelector(
    state => state?.auth?.userData || {}
  );
  const [fetchData] = useApi();

  useEffect(() => {
    const {
      type,
      reelId,
      groupId,
      reelType,
      objectId,
      notificationType,
      idList,
      paginationData,
      requestParams,
    } = fetchRequestData;

    if (fetchRequestData) {
      switch (type) {
        case 'ALL_GROUPS':
          fetchData(
            '/division/getAllDivisions',
            FETCH_ALL_GROUPS_REQUEST,
            FETCH_ALL_GROUPS_SUCCESS,
            FETCH_ALL_GROUPS_FAIL,
            null,
            '',
            'GET',
            null,
            'isGroupService'
          );
          break;

        case 'VIDEO':
          fetchData(
            `/video/get-all-videos-by-group`,
            FETCH_ALL_VIDEOS_BY_GROUP_REQUEST,
            FETCH_ALL_VIDEOS_BY_GROUP_SUCCESS,
            FETCH_ALL_VIDEOS_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'DOCUMENTS':
          fetchData(
            `/document/get-all-documents-by-group`,
            FETCH_ALL_DOCUMENTS_BY_GROUP_REQUEST,
            FETCH_ALL_DOCUMENTS_BY_GROUP_SUCCESS,
            FETCH_ALL_DOCUMENTS_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'STANDALONE':
          fetchData(
            `/assessment/get-all-standalone-assessment-by-group${
              groupId !== '' ? `?groupId=${groupId}` : ''
            }`,
            FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_REQUEST,
            FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_SUCCESS,
            FETCH_STANDALONE_ASSESSMENTS_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'MID':
          fetchData(
            `/assessment/get-all-mid-end-assessment-by-group?placement=mid${
              groupId !== '' ? `&groupId=${groupId}` : ''
            }`,
            FETCH_MID_END_ASSESSMENTS_BY_GROUP_REQUEST,
            FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA,
            FETCH_MID_END_ASSESSMENTS_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'END':
          fetchData(
            `/assessment/get-all-mid-end-assessment-by-group?placement=end${
              groupId !== '' ? `&groupId=${groupId}` : ''
            }`,
            FETCH_MID_END_ASSESSMENTS_BY_GROUP_REQUEST,
            FETCH_MID_END_ASSESSMENTS_BY_GROUP_DATA,
            FETCH_MID_END_ASSESSMENTS_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'VIDEO_REELS':
          fetchData(
            `/reel/get-video-reel-by-group${
              groupId !== '' ? `?groupId=${groupId}` : ''
            }`,
            FETCH_REELS_BY_GROUP_REQUEST,
            FETCH_REELS_BY_GROUP_SUCCESS,
            FETCH_REELS_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'DOCUMENT_REELS':
          fetchData(
            `/reel/get-document-reel-by-group${
              groupId !== '' ? `?groupId=${groupId}` : ''
            }`,
            FETCH_DOCUMENT_REELS_BY_GROUP_REQUEST,
            FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS,
            FETCH_DOCUMENT_REELS_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'MANDATORY_REELS':
          fetchData(
            `/reel/get-mandatory-by-group${
              groupId !== '' ? `?groupId=${groupId}` : ''
            }`,
            FETCH_MANDATORY_REELS_BY_GROUP_REQUEST,
            FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS,
            FETCH_MANDATORY_REELS_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'REEL_DATA':
          fetchData(
            `/reel/get-all-categories-with-video-document-standalone-from-a-reel?${
              groupId !== '' ? `groupId=${groupId}&` : ''
            }reelId=${reelId}`,
            FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_REQUEST,
            FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
            FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'ADMIN_HOME_INIT':
          fetchData(
            `/admin/init${groupId !== '' ? `?groupId=${groupId}` : ''}`,
            INTIALIZE_HOME_DATA_REQUEST,
            INTIALIZE_HOME_DATA_SUCCESS,
            INTIALIZE_HOME_DATA_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'ADMIN_HOME_VIDEO_REELS':
          fetchData(
            `/reel/get-all-video-reel-for-landing-page${
              groupId !== '' ? `?groupId=${groupId}` : ''
            }`,
            FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST,
            FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS,
            FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'ADMIN_HOME_DOCUMENT_REELS':
          fetchData(
            `/reel/get-all-document-reel-for-landing-page?groupId=${groupId}`,
            FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST,
            FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS,
            FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'VIDEO_REEL_BULK_VIEW':
          fetchData(
            `/reel/get-all-video-reel-for-landing-page${
              groupId !== '' ? `?groupId=${groupId}` : ''
            }`,
            FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST,
            FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS,
            FETCH_VIDEO_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'DOCUMENT_REEL_BULK_VIEW':
          fetchData(
            `/reel/get-all-categories-with-video-document-standalone-from-a-reel?${
              groupId !== '' ? `groupId=${groupId}&` : ''
            }reelId=${reelId}`,
            FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_REQUEST,
            FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_SUCCESS,
            FETCH_DOUCMENT_REELS_FOR_LANDING_HOME_SCREEN_BY_GROUP_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'ADD_VIDEO_TO_REEL_DATA':
          fetchData(
            `/reel/get-all-reels-and-categories-and-videos?groupId=${groupId}`,
            FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_REQUEST,
            FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_SUCCESS,
            FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'ADD_DOCUMENTS_TO_REEL_DATA':
          fetchData(
            `/reel/get-all-reels-and-categories-and-documents?groupId=${groupId}`,
            FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_REQUEST,
            FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_SUCCESS,
            FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'ADD_STANDALONE_ASSESSMENTS_TO_REEL_DATA':
          fetchData(
            `/reel/get-all-standalone-category-reel?groupId=${groupId}`,
            FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_REQUEST,
            FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_SUCCESS,
            FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'ADD_REEL_DATA_TO_GROUP':
          fetchData(
            `/reel/get-all-categories-with-video-document-standalone-for-adding-reel-to-group?groupId=${groupId}&type=${reelType}`,
            FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_REQUEST,
            FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_SUCCESS,
            FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'INTIALIZE_CREATE_VIDEO':
          fetchData(
            '/video/init',
            INTIALIZE_VIDEO_REQUEST,
            INTIALIZE_VIDEO_SUCCESS,
            INTIALIZE_VIDEO_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'INTIALIZE_CREATE_DOCUMENT':
          fetchData(
            '/document/init',
            INTIALIZE_DOCUMENT_REQUEST,
            INTIALIZE_DOCUMENT_SUCCESS,
            INTIALIZE_DOCUMENT_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'INTIALIZE_CREATE_ASSESSMENT':
          fetchData(
            '/assessment/standalone-init',
            FETCH_CREATE_ASSESSMENT_RELATED_INFO_REQUEST,
            FETCH_CREATE_ASSESSMENT_RELATED_INFO_DATA,
            FETCH_CREATE_ASSESSMENT_RELATED_INFO_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'FIND_VIDEO':
          fetchData(
            `/video/find-by-id?videoId=${objectId}`,
            FIND_VIDEO_BY_ID_REQUEST,
            FIND_VIDEO_BY_ID_SUCCESS,
            FIND_VIDEO_BY_ID_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'FIND_DOCUMENT':
          fetchData(
            `/document/find-by-id?documentId=${objectId}`,
            FIND_DOCUMENT_BY_ID_REQUEST,
            FIND_DOCUMENT_BY_ID_DATA,
            FIND_DOCUMENT_BY_ID_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'FIND_ASSESSMENT':
          fetchData(
            `/assessment/find-by-id?assessmentId=${objectId}`,
            FIND_ASSESSMENT_BY_ID_REQUEST,
            FIND_ASSESSMENT_BY_ID_SUCCESS,
            FIND_ASSESSMENT_BY_ID_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'FIND_IMAGE':
          fetchData(
            `/image/find-by-id?imageId=${objectId}`,
            FETCH_BANNER_FEATURED_IMAGE_BY_ID_REQUEST,
            FETCH_BANNER_FEATURED_IMAGE_BY_ID_SUCCESS,
            FETCH_BANNER_FEATURED_IMAGE_BY_ID_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'FETCH_GROUPS_BY_ADMIN':
          const { userRoles } = requestParams;

          fetchData(
            `/division?userIds=${[loggedInUserId].toString()}&page=${
              paginationData.pageNo
            }&size=${
              paginationData.pageSize
            }&userRoles=${userRoles.toString()}`,
            FETCH_GROUPS_BY_USER_REQUEST,
            FETCH_GROUPS_BY_USER_SUCCESS,
            FETCH_GROUPS_BY_USER_FAIL,
            null,
            '',
            'GET',
            null,
            'isGroupService'
          );
          break;

        case 'FETCH_ALL_USERS_FOR_VALIDATION':
          fetchData(
            '/user/getAllUsers',
            FETCH_USERS_FOR_VALIDATION_REQUEST,
            FETCH_USERS_FOR_VALIDATION_SUCCESS,
            FETCH_USERS_FOR_VALIDATION_FAIL,
            null,
            '',
            'GET',
            null,
            'isUserService'
          );
          break;

        case 'FETCH_ALL_USERS_BY_ADMIN':
          const { role, groupIds, searchKeyWord } = requestParams;

          fetchData(
            `/user?groupIds=${groupIds.toString()}&page=${
              paginationData.pageNo
            }&size=${paginationData.pageSize}&searchKey=${searchKeyWord || ''}${
              role ? `&type=${role}` : ''
            }`,
            FETCH_USERS_REQUEST,
            FETCH_USERS_SUCCESS,
            FETCH_USERS_FAIL,
            null,
            '',
            'GET',
            null,
            'isUserService'
          );
          break;

        case 'FETCH_ROLES_AND_GROUPS_BY_USER_ID':
          const { userIdsForRoleGroups, groupIdsForRoleGroups } = requestParams;

          fetchData(
            `/user-groups/statistics?userIds=${userIdsForRoleGroups.toString()}&page=${
              paginationData.pageNo
            }&size=${
              paginationData.pageSize
            }&groupIds=${groupIdsForRoleGroups.toString()}`,
            FETCH_GROUPS_ROLES_BY_USERS_REQUEST,
            FETCH_GROUPS_ROLES_BY_USERS_SUCCESS,
            FETCH_GROUPS_ROLES_BY_USERS_FAIL,
            null,
            '',
            'GET',
            null,
            'isUserService'
          );
          break;

        case 'FETCH_USERS_BY_GROUP':
          fetchData(
            `/division/getGroupUserData?divisionId=${groupId}`,
            FETCH_GROUP_USERS_REQUEST,
            FETCH_GROUP_USERS_SUCCESS,
            FETCH_GROUP_USERS_FAIL,
            null,
            '',
            'POST',
            null,
            'isGroupService'
          );
          break;

        case 'FETCH_SELF_SIGNUP_USERS':
          fetchData(
            `/external-users?type=SELF_SIGNUP_USER&page=${paginationData.pageNo}&size=${paginationData.pageSize}&searchKey=${paginationData.searchKey}`,
            FETCH_SELF_SIGUNUP_USERS_REQUEST,
            FETCH_SELF_SIGUNUP_USERS_SUCCESS,
            FETCH_SELF_SIGUNUP_USERS_FAIL,
            null,
            '',
            'GET',
            null,
            'isUserService'
          );
          break;

        case 'FETCH_USER_ROLES':
          fetchData(
            '/userRole/getAllRoles',
            FETCH_USERS_ROLES_REQUEST,
            FETCH_USERS_ROLES_SUCCESS,
            FETCH_USERS_ROLES_FAIL,
            null,
            '',
            'GET',
            null,
            'isUserService'
          );
          break;

        case 'FETCH_WELCOME_VIDEOS':
          fetchData(
            '/user/existingVideos',
            FETCH_WELCOME_VIDEOS_REQUEST,
            FETCH_WELCOME_VIDEOS_SUCCESS,
            FETCH_WELCOME_VIDEOS_FAIL,
            null,
            '',
            'GET',
            null,
            'isUserService'
          );
          break;

        case 'FETCH_USER_BY_ID':
          let requestUrl = `/user/getUserById?userId=${objectId}`;

          if (groupId !== '') {
            requestUrl = `/user/getUserByIdAndDivision?userId=${objectId}&divisionId=${groupId}`;
          }

          fetchData(
            requestUrl,
            FETCH_USER_BY_ID_REQUEST,
            FETCH_USER_BY_ID_SUCCESS,
            FETCH_USER_BY_ID_FAIL,
            null,
            '',
            'GET',
            null,
            'isUserService'
          );
          break;

        case 'FETCH_USER_NOT_IN_GROUP':
          fetchData(
            `/division/getNotInGroupUserData?divisionId=${groupId}`,
            FETCH_USER_LIST_REQUEST,
            FETCH_USER_LIST_SUCCESS,
            FETCH_USER_LIST_FAIL,
            null,
            '',
            'POST',
            null,
            'isGroupService'
          );
          break;

        case 'FETCH_PARENT_GROUP_ROLES':
          fetchData(
            `/division/getAllParentRoles?divisionId=${groupId}&userId=${objectId}`,
            FETCH_PARENT_GROUP_ROLES_BY_USER_REQUEST,
            FETCH_PARENT_GROUP_ROLES_BY_USER_SUCCESS,
            FETCH_PARENT_GROUP_ROLES_BY_USER_FAIL,
            null,
            '',
            'GET',
            null,
            'isGroupService'
          );
          break;

        case 'FETCH_USERS_FOR_ACCESS_CONTROL':
          fetchData(
            `/reel/get-all-users-to-include-exclude?reelId=${reelId}${
              groupId ? `&groupId=${groupId}` : ''
            }`,
            FETCH_USERS_FOR_ACCESS_CONTROL_REQUEST,
            FETCH_USERS_FOR_ACCESS_CONTROL_SUCCESS,
            FETCH_USERS_FOR_ACCESS_CONTROL_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'REEL_RENAME_WARNINGS':
          fetchData(
            `/reel/get-all-groups-user-count?reelId=${reelId}${
              groupId ? `&groupId=${groupId}` : ''
            }`,
            FETCH_REEL_RENAME_WARNINGS_REQUEST,
            FETCH_REEL_RENAME_WARNINGS_SUCCESS,
            FETCH_REEL_RENAME_WARNINGS_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'FETCH_NOTIFICATION_SETTINGS':
          fetchData(
            `/notification/${
              notificationType === 'USER'
                ? 'getUserNotificationSettings'
                : 'getAdminNotificationSettings'
            }?userId=${objectId}`,
            FETCH_NOTIFICATION_SETTINGS_REQUEST,
            FETCH_NOTIFICATION_SETTINGS_SUCCESS,
            FETCH_NOTIFICATION_SETTINGS_FAIL,
            null,
            '',
            'GET',
            null,
            'isNotificationService'
          );
          break;

        case 'FETCH_ALL_NOTIFICATIONS':
          if (!requestParams) {
            break;
          }

          fetchData(
            `/users/${loggedInUserId}/notifications?page=${
              paginationData.pageNo
            }&size=${paginationData.pageSize}&types=${
              isAdminView
                ? notificationViewType.admin
                : notificationViewType.user
            }${
              process.env.REACT_APP_IS_RMS_ENABLED?.toLowerCase() === 'true'
                ? `,${notificationViewType.rms}`
                : ''
            }${
              requestParams.filterType
                ? `&filterBy=${requestParams.filterType}`
                : ''
            }`,
            FETCH_ALL_NOTIFICATION_REQUEST,
            FETCH_ALL_NOTIFICATION_SUCCESS,
            FETCH_ALL_NOTIFICATION_FAIL,
            null,
            '',
            'GET',
            null,
            'isWebsocketService'
          );
          break;

        case 'DOCUMENT_URL_BY_ID':
          fetchData(
            `/document/find-url-by-id?documentId=${objectId}`,
            FETCH_DOCUMENT_URL_BY_ID_REQUEST,
            FETCH_DOCUMENT_URL_BY_ID_SUCCESS,
            FETCH_DOCUMENT_URL_BY_ID_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'IMAGE_URL_BY_ID':
          fetchData(
            `/image/find-url-by-id?imageId=${objectId}`,
            FETCH_IMAGE_URL_BY_ID_REQUEST,
            FETCH_IMAGE_URL_BY_ID_SUCCESS,
            FETCH_IMAGE_URL_BY_ID_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'FETCH_THEME_DATA':
          fetchData(
            `/company/${objectId}/styles`,
            FETCH_COMPANY_PROFILE_REQUEST,
            FETCH_COMPANY_PROFILE_SUCCESS,
            FETCH_COMPANY_PROFILE_FAIL,
            null,
            '',
            'GET',
            null,
            'isUserService'
          );
          break;

        case 'ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENT':
          fetchData(
            `/assessment/get-assigned-videos-for-mid-end-assessment?assessmentId=${objectId}${
              groupId ? `&groupId=${groupId}` : ''
            }`,
            FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_REQUEST,
            FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_SUCCESS,
            FETCH_ASSIGNED_VIDEOS_FOR_MID_END_ASSESSMENTS_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'ASSESSMENT_SCORES_BY_USER':
          if (idList) {
            fetchData(
              `/assessment/get-assessment-scores?userId=${
                idList.userId
              }&assessmentId=${idList.assessmentId}${
                groupId ? `&groupId=${groupId}` : ''
              }${idList.videoId ? `&videoId=${idList.videoId}` : ''}`,
              FETCH_ASSESSMENT_SCORES_REQUEST,
              FETCH_ASSESSMENT_SCORES_SUCCESS,
              FETCH_ASSESSMENT_SCORES_FAIL,
              null,
              '',
              'GET',
              null,
              'isReelService'
            );
          }
          break;

        case 'FETCH_ELIGIBLE_USERS_FOR_GROUP':
          if (idList && groupId) {
            fetchData(
              `/division/${groupId}/users?roleIds=${idList.toString()}`,
              FETCH_ELIGIBLE_USERS_FOR_GROUP_REQUEST,
              FETCH_ELIGIBLE_USERS_FOR_GROUP_SUCCESS,
              FETCH_ELIGIBLE_USERS_FOR_GROUP_FAIL,
              null,
              '',
              'GET',
              null,
              'isGroupService'
            );
          }
          break;

        case 'SELF_ASSIGNED_GROUPS':
          fetchData(
            `/division/self-signup-divisions?page=1&size=100`,
            FETCH_SELF_ASSIGNED_ENABLED_GROUPS_REQUEST,
            FETCH_SELF_ASSIGNED_ENABLED_GROUPS_SUCCESS,
            FETCH_SELF_ASSIGNED_ENABLED_GROUPS_FAIL,
            null,
            '',
            'GET',
            null,
            'isGroupService'
          );
          break;

        case 'GET_S3_BUCKET_NAME':
          fetchData(
            '/cloud-storage/path/user-content',
            FETCH_S3_BUCKET_DATA_REQUEST,
            FETCH_S3_BUCKET_DATA_SUCCESS,
            FETCH_S3_BUCKET_DATA_FAIL,
            null,
            '',
            'GET',
            null,
            'isReelService'
          );
          break;

        case 'SIGNUP_ENABLED_GROUPS':
          fetchData(
            `/division?isAllowForSignup=true&page=1&size=100`,
            FETCH_SIGNUP_ENABLED_GROUPS_REQUEST,
            FETCH_SIGNUP_ENABLED_GROUPS_SUCCESS,
            FETCH_SIGNUP_ENABLED_GROUPS_FAIL,
            null,
            '',
            'GET',
            null,
            'isGroupService'
          );
          break;

        case 'UNREAD_NOTIFICATION_COUNT':
          fetchData(
            `/users/${loggedInUserId}/notifications?types=${
              isAdminView
                ? notificationViewType.admin
                : notificationViewType.user
            }${
              process.env.REACT_APP_IS_RMS_ENABLED?.toLowerCase() === 'true'
                ? `,${notificationViewType.rms}`
                : ''
            }&page=1&size=1&seen=false`,
            NOTIFICATION_ICON_COUNT_REQUEST,
            NOTIFICATION_ICON_COUNT_SUCCESS,
            NOTIFICATION_ICON_COUNT_FAIL,
            null,
            '',
            'GET',
            null,
            'isWebsocketService'
          );
          break;

        case 'RECENT_HEADER_NOTIFICATION':
          fetchData(
            `/users/${loggedInUserId}/notifications?types=${
              isAdminView
                ? notificationViewType.admin
                : notificationViewType.user
            }${
              process.env.REACT_APP_IS_RMS_ENABLED?.toLowerCase() === 'true'
                ? `,${notificationViewType.rms}`
                : ''
            }&page=1&size=8`,
            FETCH_NOTIFICATION_REQUEST,
            FETCH_NOTIFICATION_SUCCESS,
            FETCH_NOTIFICATION_FAIL,
            null,
            '',
            'GET',
            null,
            'isWebsocketService'
          );
          break;

        case 'EMAIL_NOTIFICATION_STATUS':
          fetchData(
            `/users/${loggedInUserId}/user-notification-settings?page=1&size=200`,
            FETCH_EMAIL_NOTICATION_STATUS_REQUEST,
            FETCH_EMAIL_NOTICATION_STATUS_SUCCESS,
            FETCH_EMAIL_NOTICATION_STATUS_FAIL,
            null,
            '',
            'GET',
            null,
            'isWebsocketService'
          );
          break;

        default:
          break;
      }
    }
  }, [fetchRequestData]);

  const setData = ({
    type,
    groupId,
    reelId,
    reelType,
    objectId,
    notificationType,
    idList,
    paginationData,
    requestParams,
  }) => {
    setFetchRequestData({
      type: type || '',
      reelId: reelId || '',
      groupId: groupId || '',
      reelType: reelType || '',
      objectId: objectId || '',
      notificationType: notificationType || '',
      idList: idList || null,
      paginationData,
      requestParams,
    });
  };

  return [setData];
}

export default useFetchDataApi;

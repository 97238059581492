import {
  CREATE_REEL_REQUEST,
  CREATE_REEL_SUCCESS,
  CREATE_REEL_FAIL,
  UPDATE_REEL_REQUEST,
  UPDATE_REEL_SUCCESS,
  UPDATE_REEL_FAIL,
  EDIT_REEL_NAME_REQUEST,
  EDIT_REEL_NAME_SUCCESS,
  EDIT_REEL_NAME_FAIL,
  SUSPEND_REEL_REQUEST,
  SUSPEND_REEL_SUCCESS,
  SUSPEND_REEL_FAIL,
  DELETE_REEL_BY_ID_REQUEST,
  DELETE_REEL_BY_ID_SUCCESS,
  DELETE_REEL_BY_ID_FAIL,
  FETCH_REELS_BY_GROUP_REQUEST,
  FETCH_REELS_BY_GROUP_SUCCESS,
  FETCH_REELS_BY_GROUP_FAIL,
  COPY_REEL_REQUEST,
  COPY_REEL_SUCCESS,
  COPY_REEL_FAIL,
  REMOVE_USER_FOR_REEL_REQUEST,
  REMOVE_USER_FOR_REEL_SUCCESS,
  REMOVE_USER_FOR_REEL_FAIL,
  FETCH_ALL_REELS_DATA_REQUEST,
  FETCH_ALL_REELS_DATA_SUCCESS,
  FETCH_ALL_REELS_DATA_FAIL,
  FETCH_DOCUMENT_REELS_BY_GROUP_REQUEST,
  FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS,
  FETCH_DOCUMENT_REELS_BY_GROUP_FAIL,
  FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_REQUEST,
  FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS,
  FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_FAIL,
  ADD_CATEGORY_TO_REEL_REQUEST,
  ADD_CATEGORY_TO_REEL_SUCCESS,
  ADD_CATEGORY_TO_REEL_FAIL,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_REQUEST,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_SUCCESS,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_FAIL,
  FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_REQUEST,
  FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_SUCCESS,
  FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_FAIL,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_REQUEST,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_SUCCESS,
  FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_FAIL,
  ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_REQUEST,
  ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_SUCCESS,
  ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_FAIL,
  ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_RESET,
  FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_REQUEST,
  FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_SUCCESS,
  FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_FAIL,
  ADD_VIDEOS_TO_CURRENT_CATEGORY_REQUEST,
  ADD_VIDEOS_TO_CURRENT_CATEGORY_SUCCESS,
  ADD_VIDEOS_TO_CURRENT_CATEGORY_FAIL,
  ADD_VIDEOS_TO_CURRENT_CATEGORY_RESET,
  ADD_DOCUMENTS_TO_CURRENT_CATEGORY_REQUEST,
  ADD_DOCUMENTS_TO_CURRENT_CATEGORY_SUCCESS,
  ADD_DOCUMENTS_TO_CURRENT_CATEGORY_FAIL,
  ADD_DOCUMENTS_TO_CURRENT_CATEGORY_RESET,
  FETCH_USERS_FOR_ACCESS_CONTROL_REQUEST,
  FETCH_USERS_FOR_ACCESS_CONTROL_SUCCESS,
  FETCH_USERS_FOR_ACCESS_CONTROL_FAIL,
  INCLUDE_EXCLUDE_USERS_TO_REEL_REQUEST,
  INCLUDE_EXCLUDE_USERS_TO_REEL_SUCCESS,
  INCLUDE_EXCLUDE_USERS_TO_REEL_FAIL,
  FETCH_MANDATORY_REELS_BY_GROUP_REQUEST,
  FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS,
  FETCH_MANDATORY_REELS_BY_GROUP_FAIL,
  FETCH_REEL_RENAME_WARNINGS_REQUEST,
  FETCH_REEL_RENAME_WARNINGS_SUCCESS,
  FETCH_REEL_RENAME_WARNINGS_FAIL,
} from '../actionTypes/reels';

const reelReducer = (
  state = {
    createReelLoading: false,
    createReelSuccess: null,
    createReelError: null,
    updateReelLoading: false,
    updateReelSuccess: null,
    updateReelError: null,
    editReelNameLoading: false,
    editReelNameSuccess: null,
    editReelNameError: null,
    suspendReelLoading: false,
    suspendReelSuccess: null,
    suspendReelError: null,
    deleteReelLoading: false,
    deleteReelSuccess: null,
    deleteReelError: null,
    reelsByGroupLoading: false,
    reelsByGroupSuccess: null,
    reelsByGroupError: null,
    copyReelLoading: false,
    copyReelSuccess: null,
    copyReelError: null,
    removeUserForReelLoading: false,
    removeUserForReelSuccess: null,
    removeUserForReelError: null,
    allReelsFetchDataLoading: false,
    allReelsFetchDataSuccess: null,
    allReelsFetchDataError: null,
    documentReelsFetchDataLoading: false,
    documentReelsFetchDataSuccess: null,
    documentReelsFetchDataError: null,
    allCategoriesFetchDataLoading: false,
    allCategoriesFetchDataSuccess: null,
    allCategoriesFetchDataError: null,
    addCategoryToReelLoading: false,
    addCategoryToReelSuccess: null,
    addCategoryToReelError: null,
    allReelsCategoriesDocumentsFetchDataLoading: false,
    allReelsCategoriesDocumentsFetchDataSuccess: null,
    allReelsCategoriesDocumentsFetchDataError: null,
    allReelsCategoriesFetchDataLoading: false,
    allReelsCategoriesFetchData: null,
    allReelsCategoriesFetchDataError: null,
    allReelsCategoriesVideosFetchDataLoading: false,
    allReelsCategoriesVideosFetchDataSuccess: null,
    allReelsCategoriesVideosFetchDataError: null,
    addVideoDocumentFromOtherReelLoading: false,
    addVideoDocumentFromOtherReelSuccess: null,
    addVideoDocumentFromOtherReelError: null,
    allStandaloneAssessmentCategoryReelLoading: false,
    allStandaloneAssessmentCategoryReelSuccess: null,
    allStandaloneAssessmentCategoryReelError: null,
    addVideosToCurrentCategoryLoading: false,
    addVideosToCurrentCategorySuccess: null,
    addVideosToCurrentCategoryError: null,
    addDocumentsToCurrentCategoryLoading: false,
    addDocumentsToCurrentCategorySuccess: null,
    addDocumentsToCurrentCategoryError: null,
    usersForAccessControlLoading: false,
    usersForAccessControlData: null,
    usersForAccessControlError: null,
    includeExcludeUsersLoading: false,
    includeExcludeUsersSuccess: null,
    includeExcludeUsersError: null,
    fetchMandatoryReelsLoading: false,
    mandatoryReelsFetchData: null,
    fetchMandatoryReelsError: null,
    reelRenameWarningLoading: false,
    reelRenameWarningData: null,
    reelRenameWarningError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_REEL_REQUEST:
      return {
        ...state,
        createReelLoading: true,
        createReelSuccess: null,
        createReelError: null,
      };

    case CREATE_REEL_SUCCESS:
      return {
        ...state,
        createReelLoading: false,
        createReelSuccess: action.payload,
        createReelError: null,
      };

    case CREATE_REEL_FAIL:
      return {
        ...state,
        createReelLoading: false,
        createReelSuccess: null,
        createReelError: action.payload,
      };

    case UPDATE_REEL_REQUEST:
      return {
        ...state,
        updateReelLoading: true,
        updateReelSuccess: null,
        updateReelError: null,
      };

    case UPDATE_REEL_SUCCESS:
      return {
        ...state,
        updateReelLoading: false,
        updateReelSuccess: action.payload,
        updateReelError: null,
      };

    case UPDATE_REEL_FAIL:
      return {
        ...state,
        updateReelLoading: false,
        updateReelSuccess: null,
        updateReelError: action.payload,
      };

    case EDIT_REEL_NAME_REQUEST:
      return {
        ...state,
        editReelNameLoading: true,
        editReelNameSuccess: null,
        editReelNameError: null,
      };

    case EDIT_REEL_NAME_SUCCESS:
      return {
        ...state,
        editReelNameLoading: false,
        editReelNameSuccess: action.payload,
        editReelNameError: null,
      };

    case EDIT_REEL_NAME_FAIL:
      return {
        ...state,
        editReelNameLoading: false,
        editReelNameSuccess: null,
        editReelNameError: true,
      };

    case SUSPEND_REEL_REQUEST:
      return {
        ...state,
        suspendReelLoading: true,
        suspendReelSuccess: null,
        suspendReelError: null,
      };

    case SUSPEND_REEL_SUCCESS:
      return {
        ...state,
        suspendReelLoading: false,
        suspendReelSuccess: action.payload,
        suspendReelError: null,
      };

    case SUSPEND_REEL_FAIL:
      return {
        ...state,
        suspendReelLoading: false,
        suspendReelSuccess: null,
        suspendReelError: true,
      };

    case DELETE_REEL_BY_ID_REQUEST:
      return {
        ...state,
        deleteReelLoading: true,
        deleteReelSuccess: null,
        deleteReelError: null,
      };

    case DELETE_REEL_BY_ID_SUCCESS:
      return {
        ...state,
        deleteReelLoading: false,
        deleteReelSuccess: true,
        deleteReelError: null,
      };

    case DELETE_REEL_BY_ID_FAIL:
      return {
        ...state,
        deleteReelLoading: false,
        deleteReelSuccess: null,
        deleteReelError: true,
      };

    case FETCH_REELS_BY_GROUP_REQUEST:
      return {
        ...state,
        reelsByGroupLoading: true,
        reelsByGroupSuccess: null,
        reelsByGroupError: null,
      };

    case FETCH_REELS_BY_GROUP_SUCCESS:
      return {
        ...state,
        reelsByGroupLoading: false,
        reelsByGroupSuccess: action.payload,
        reelsByGroupError: null,
      };

    case FETCH_REELS_BY_GROUP_FAIL:
      return {
        ...state,
        reelsByGroupLoading: false,
        reelsByGroupSuccess: null,
        reelsByGroupError: true,
      };

    case COPY_REEL_REQUEST:
      return {
        ...state,
        copyReelLoading: true,
        copyReelSuccess: null,
        copyReelError: null,
      };

    case COPY_REEL_SUCCESS:
      return {
        ...state,
        copyReelLoading: false,
        copyReelSuccess: action.payload,
        copyReelError: null,
      };

    case COPY_REEL_FAIL:
      return {
        ...state,
        copyReelLoading: false,
        copyReelSuccess: null,
        copyReelError: action.payload,
      };

    case REMOVE_USER_FOR_REEL_REQUEST:
      return {
        ...state,
        removeUserForReelLoading: true,
        removeUserForReelSuccess: null,
        removeUserForReelError: null,
      };

    case REMOVE_USER_FOR_REEL_SUCCESS:
      return {
        ...state,
        removeUserForReelLoading: false,
        removeUserForReelSuccess: action.payload,
        removeUserForReelError: null,
      };

    case REMOVE_USER_FOR_REEL_FAIL:
      return {
        ...state,
        removeUserForReelLoading: false,
        removeUserForReelSuccess: null,
        removeUserForReelError: true,
      };

    case FETCH_ALL_REELS_DATA_REQUEST:
      return {
        ...state,
        allReelsFetchDataLoading: true,
        allReelsFetchDataSuccess: null,
        allReelsFetchDataError: null,
      };

    case FETCH_ALL_REELS_DATA_SUCCESS:
      return {
        ...state,
        allReelsFetchDataLoading: false,
        allReelsFetchDataSuccess: action.payload,
        allReelsFetchDataError: null,
      };

    case FETCH_ALL_REELS_DATA_FAIL:
      return {
        ...state,
        allReelsFetchDataLoading: false,
        allReelsFetchDataSuccess: null,
        allReelsFetchDataError: true,
      };

    case FETCH_DOCUMENT_REELS_BY_GROUP_REQUEST:
      return {
        ...state,
        documentReelsFetchDataLoading: true,
        documentReelsFetchDataSuccess: null,
        documentReelsFetchDataError: null,
      };

    case FETCH_DOCUMENT_REELS_BY_GROUP_SUCCESS:
      return {
        ...state,
        documentReelsFetchDataLoading: false,
        documentReelsFetchDataSuccess: action.payload,
        documentReelsFetchDataError: null,
      };

    case FETCH_DOCUMENT_REELS_BY_GROUP_FAIL:
      return {
        ...state,
        documentReelsFetchDataLoading: false,
        documentReelsFetchDataSuccess: null,
        documentReelsFetchDataError: true,
      };

    case FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_REQUEST:
      return {
        ...state,
        allCategoriesFetchDataLoading: true,
        allCategoriesFetchDataSuccess: null,
        allCategoriesFetchDataError: null,
      };

    case FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        allCategoriesFetchDataLoading: false,
        allCategoriesFetchDataSuccess: action.payload,
        allCategoriesFetchDataError: null,
      };

    case FETCH_ALL_CATEGORIES_WITH_STANDALONE_ASSESSMENT_FAIL:
      return {
        ...state,
        allCategoriesFetchDataLoading: false,
        allCategoriesFetchDataSuccess: null,
        allCategoriesFetchDataError: true,
      };

    case ADD_CATEGORY_TO_REEL_REQUEST:
      return {
        ...state,
        addCategoryToReelLoading: true,
        addCategoryToReelSuccess: null,
        addCategoryToReelError: null,
      };

    case ADD_CATEGORY_TO_REEL_SUCCESS:
      return {
        ...state,
        addCategoryToReelLoading: false,
        addCategoryToReelSuccess: action.payload,
        addCategoryToReelError: null,
      };

    case ADD_CATEGORY_TO_REEL_FAIL:
      return {
        ...state,
        addCategoryToReelLoading: false,
        addCategoryToReelSuccess: null,
        addCategoryToReelError: true,
      };

    case FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_REQUEST:
      return {
        ...state,
        allReelsCategoriesDocumentsFetchDataLoading: true,
        allReelsCategoriesDocumentsFetchDataSuccess: null,
        allReelsCategoriesDocumentsFetchDataError: null,
      };

    case FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_SUCCESS:
      return {
        ...state,
        allReelsCategoriesDocumentsFetchDataLoading: false,
        allReelsCategoriesDocumentsFetchDataSuccess: action.payload,
        allReelsCategoriesDocumentsFetchDataError: null,
      };

    case FETCH_ALL_REELS_AND_CATEGORIES_AND_DOCUMENTS_FAIL:
      return {
        ...state,
        allReelsCategoriesDocumentsFetchDataLoading: false,
        allReelsCategoriesDocumentsFetchDataSuccess: null,
        allReelsCategoriesDocumentsFetchDataError: true,
      };

    case FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_REQUEST:
      return {
        ...state,
        allReelsCategoriesFetchDataLoading: true,
        allReelsCategoriesFetchData: null,
        allReelsCategoriesFetchDataError: null,
      };

    case FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_SUCCESS:
      return {
        ...state,
        allReelsCategoriesFetchDataLoading: false,
        allReelsCategoriesFetchData: action.payload,
        allReelsCategoriesFetchDataError: null,
      };

    case FETCH_ALL_REELS_AND_CATEGORIES_FOR_ADD_REEL_FAIL:
      return {
        ...state,
        allReelsCategoriesFetchDataLoading: false,
        allReelsCategoriesFetchData: null,
        allReelsCategoriesFetchDataError: true,
      };

    case FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_REQUEST:
      return {
        ...state,
        allReelsCategoriesVideosFetchDataLoading: true,
        allReelsCategoriesVideosFetchDataSuccess: null,
        allReelsCategoriesVideosFetchDataError: null,
      };

    case FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_SUCCESS:
      return {
        ...state,
        allReelsCategoriesVideosFetchDataLoading: false,
        allReelsCategoriesVideosFetchDataSuccess: action.payload,
        allReelsCategoriesVideosFetchDataError: null,
      };

    case FETCH_ALL_REELS_AND_CATEGORIES_AND_VIDEOS_FAIL:
      return {
        ...state,
        allReelsCategoriesVideosFetchDataLoading: false,
        allReelsCategoriesVideosFetchDataSuccess: null,
        allReelsCategoriesVideosFetchDataError: true,
      };

    case ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_REQUEST:
      return {
        ...state,
        addVideoDocumentFromOtherReelLoading: true,
        addVideoDocumentFromOtherReelSuccess: null,
        addVideoDocumentFromOtherReelError: null,
      };

    case ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_SUCCESS:
      return {
        ...state,
        addVideoDocumentFromOtherReelLoading: false,
        addVideoDocumentFromOtherReelSuccess: true,
        addVideoDocumentFromOtherReelError: null,
      };

    case ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_RESET:
      return {
        ...state,
        addVideoDocumentFromOtherReelLoading: false,
        addVideoDocumentFromOtherReelSuccess: null,
        addVideoDocumentFromOtherReelError: null,
      };

    case ADD_VIDEOS_DOCUMENTS_FROM_OTHER_REEL_TO_GROUP_FAIL:
      return {
        ...state,
        addVideoDocumentFromOtherReelLoading: false,
        addVideoDocumentFromOtherReelSuccess: null,
        addVideoDocumentFromOtherReelError: true,
      };

    case FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_REQUEST:
      return {
        ...state,
        allStandaloneAssessmentCategoryReelLoading: true,
        allStandaloneAssessmentCategoryReelSuccess: null,
        allStandaloneAssessmentCategoryReelError: null,
      };

    case FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_SUCCESS:
      return {
        ...state,
        allStandaloneAssessmentCategoryReelLoading: false,
        allStandaloneAssessmentCategoryReelSuccess: action.payload,
        allStandaloneAssessmentCategoryReelError: null,
      };

    case FETCH_ALL_STANDALONE_ASSESSMENT_CATEGORY_REEL_FAIL:
      return {
        ...state,
        allStandaloneAssessmentCategoryReelLoading: false,
        allStandaloneAssessmentCategoryReelSuccess: null,
        allStandaloneAssessmentCategoryReelError: true,
      };

    case ADD_VIDEOS_TO_CURRENT_CATEGORY_REQUEST:
      return {
        ...state,
        addVideosToCurrentCategoryLoading: true,
        addVideosToCurrentCategorySuccess: null,
        addVideosToCurrentCategoryError: null,
      };

    case ADD_VIDEOS_TO_CURRENT_CATEGORY_SUCCESS:
      return {
        ...state,
        addVideosToCurrentCategoryLoading: false,
        addVideosToCurrentCategorySuccess: true,
        addVideosToCurrentCategoryError: null,
      };

    case ADD_VIDEOS_TO_CURRENT_CATEGORY_FAIL:
      return {
        ...state,
        addVideosToCurrentCategoryLoading: false,
        addVideosToCurrentCategorySuccess: null,
        addVideosToCurrentCategoryError: true,
      };

    case ADD_VIDEOS_TO_CURRENT_CATEGORY_RESET:
      return {
        ...state,
        addVideosToCurrentCategoryLoading: false,
        addVideosToCurrentCategorySuccess: null,
        addVideosToCurrentCategoryError: null,
      };

    case ADD_DOCUMENTS_TO_CURRENT_CATEGORY_REQUEST:
      return {
        ...state,
        addDocumentsToCurrentCategoryLoading: true,
        addDocumentsToCurrentCategorySuccess: null,
        addDocumentsToCurrentCategoryError: null,
      };

    case ADD_DOCUMENTS_TO_CURRENT_CATEGORY_SUCCESS:
      return {
        ...state,
        addDocumentsToCurrentCategoryLoading: false,
        addDocumentsToCurrentCategorySuccess: true,
        addDocumentsToCurrentCategoryError: null,
      };

    case ADD_DOCUMENTS_TO_CURRENT_CATEGORY_RESET:
      return {
        ...state,
        addDocumentsToCurrentCategoryLoading: false,
        addDocumentsToCurrentCategorySuccess: null,
        addDocumentsToCurrentCategoryError: null,
      };

    case ADD_DOCUMENTS_TO_CURRENT_CATEGORY_FAIL:
      return {
        ...state,
        addDocumentsToCurrentCategoryLoading: false,
        addDocumentsToCurrentCategorySuccess: null,
        addDocumentsToCurrentCategoryError: true,
      };

    case FETCH_USERS_FOR_ACCESS_CONTROL_REQUEST:
      return {
        ...state,
        usersForAccessControlLoading: true,
        usersForAccessControlData: null,
        usersForAccessControlError: null,
      };

    case FETCH_USERS_FOR_ACCESS_CONTROL_SUCCESS:
      return {
        ...state,
        usersForAccessControlLoading: false,
        usersForAccessControlData: action.payload,
        usersForAccessControlError: null,
      };

    case FETCH_USERS_FOR_ACCESS_CONTROL_FAIL:
      return {
        ...state,
        usersForAccessControlLoading: false,
        usersForAccessControlData: null,
        usersForAccessControlError: true,
      };

    case INCLUDE_EXCLUDE_USERS_TO_REEL_REQUEST:
      return {
        ...state,
        includeExcludeUsersLoading: true,
        includeExcludeUsersSuccess: null,
        includeExcludeUsersError: null,
      };

    case INCLUDE_EXCLUDE_USERS_TO_REEL_SUCCESS:
      return {
        ...state,
        includeExcludeUsersLoading: false,
        includeExcludeUsersSuccess: action.payload,
        includeExcludeUsersError: null,
      };

    case INCLUDE_EXCLUDE_USERS_TO_REEL_FAIL:
      return {
        ...state,
        includeExcludeUsersLoading: false,
        includeExcludeUsersSuccess: null,
        includeExcludeUsersError: true,
      };

    case FETCH_MANDATORY_REELS_BY_GROUP_REQUEST:
      return {
        ...state,
        fetchMandatoryReelsLoading: true,
        mandatoryReelsFetchData: null,
        fetchMandatoryReelsError: null,
      };

    case FETCH_MANDATORY_REELS_BY_GROUP_SUCCESS:
      return {
        ...state,
        fetchMandatoryReelsLoading: false,
        mandatoryReelsFetchData: action.payload,
        fetchMandatoryReelsError: null,
      };

    case FETCH_MANDATORY_REELS_BY_GROUP_FAIL:
      return {
        ...state,
        fetchMandatoryReelsLoading: false,
        mandatoryReelsFetchData: null,
        fetchMandatoryReelsError: true,
      };

    case FETCH_REEL_RENAME_WARNINGS_REQUEST:
      return {
        ...state,
        reelRenameWarningLoading: true,
        reelRenameWarningData: null,
        reelRenameWarningError: null,
      };

    case FETCH_REEL_RENAME_WARNINGS_SUCCESS:
      return {
        ...state,
        reelRenameWarningLoading: false,
        reelRenameWarningData: action.payload,
        reelRenameWarningError: null,
      };

    case FETCH_REEL_RENAME_WARNINGS_FAIL:
      return {
        ...state,
        reelRenameWarningLoading: false,
        reelRenameWarningData: null,
        reelRenameWarningError: action.payload,
      };

    default:
      return state;
  }
};

export default reelReducer;

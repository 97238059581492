import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import previewimg from '../../../assets/images/document-preview-img-background.png';
import updocimg from '../../../assets/images/upload-document.png';

import {
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAIL,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAIL,
  CREATE_DOCUMENT_RESET,
  UPDATE_DOCUMENT_RESET,
} from '../../../redux/actionTypes/documentData';
import useApi from '../../../Hooks/useApi';
import ViewPDFModal from '../../AdminDashboard/Group/Modal/viewPDFModal';
import {
  getAllGroupInfoByCurrentUser,
  capitalizeFirstLetter,
} from '../../../Helper/SystemManager';
import GroupMultiSelectWithMandatoryDate from '../../../Components/CreateEditComponents/GroupSelect';
import TagSelection from '../../../Components/CreateEditComponents/TagSelection';
import AssignRelatedMultiSelect from '../../../Components/CreateEditComponents/AssignRelatedMultiSelect';
import ProceedMessageModal from '../../../Components/Modal/ProceedMessageModal';
import { messageConfigs } from '../../../Helper/PopupMessageConfig';
import useFetchDataApi from '../../../Hooks/useFetchDataApi';
import ErrorMessageModal from '../../../Components/Modal/ErrorMessageModal';
import { validateFields } from '../../../Helper/ValidationsManager';
import PreviewImageView from '../../../Components/CreateEditComponents/PreviewImageView';
import Loader from '../../../Components/Loader/loader';
import MandatoryMark from '../../../Components/validation/MandatoryMark';
import useFileUpload from '../../../Hooks/useFileUpload';
import UploadItem from '../../../Components/UploadProgressBar/UploadProgressBar';
import { systemConfig } from '../../../Common/SystemConstConfigs';
import { PrivilegeTypes } from '../../../Common/UserPrivileges';
import usePrivilegesApi from '../../../Hooks/usePrivilegesApi';

const groupsObj = {
  groups: [],
};

const initialState = {
  title: '',
  description: '',
  author: '',
  isNativeBannerAndFeatured: true,
  tags: '',
  previewImgUrl: '',
  documentUrl: '',
  isPublished: '',
  allowDownload: false,
  isPreviewImgViaUrl: false,
  isDocumentViaUrl: false,
  selectedVideos: [],
  selectedAssessments: [],
  listOfGroups: [_.clone(groupsObj)],
};

const CreateDocument = ({ match, history }) => {
  const { t } = useTranslation();
  const documentType = match.params.type;
  const documentId = match.params.id;
  const groupId = match.params.groupId;
  const isHavePublishAccess = usePrivilegesApi(
    PrivilegeTypes.DOCUMENT.UPLOAD_DOCUMENT
  );
  const [documentData, setDocumentData] = useState(_.cloneDeep(initialState));
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [isPdfViewModalOpen, setIsPdfViewModalOpen] = useState(false);
  const [documentDataFiles, setDocumentDataFiles] = useState({
    previewImage: '',
    documentFile: '',
  });
  const [isPublishProcess, setIsPublishProcess] = useState(false);
  const [isSaveProcess, setIsSaveProcess] = useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(null);
  const [isUpdateProcess, setIsUpdateProcess] = useState(false);
  const [isValidationError, setIsValidationError] = useState(null);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const [fetchReelVideosAssessments] = useFetchDataApi();
  const [createDocument] = useApi();
  const [fetchAllGroups] = useFetchDataApi();
  const [updateDocument] = useApi();
  const [findDocumentById] = useFetchDataApi();
  const dispatch = useDispatch();

  const [uploadDocument, uploadedDocumentUrl, documentUploadProgress] =
    useFileUpload();

  const { groupInfo, userName } = useSelector(state => state?.auth?.userData);
  const { allGroupsFetchData } = useSelector(state => state?.group);
  const {
    initializeDocumentData,
    fetchDocumentToEditData,
    updateDocumentLoading,
    initializeDocumentLoading,
    createDocumentLoading,
    createDocumentSuccess,
    updateDocumentSuccess,
    createDocumentError,
    updateDocumentError,
  } = useSelector(state => state?.documentData);
  const { themeInfo } = useSelector(state => state?.auth);
  const pdfSelected = themeInfo?.defaultUploadedDocumentUrl;

  const { relatedVideos = [], relatedAssessments = [] } =
    initializeDocumentData;

  const handleDocumentData = (value, property) => {
    const tempDocumentData = { ...documentData };
    tempDocumentData[property] = value;
    setDocumentData(tempDocumentData);
  };

  const handleDocumentFileData = (value, property) => {
    const tempDocumentDataFiles = { ...documentDataFiles };
    tempDocumentDataFiles[property] = value;
    setDocumentDataFiles(tempDocumentDataFiles);
  };

  const handleFileAccept = (event, property) => {
    if (event?.target?.files?.length) {
      handleDocumentFileData(event.target.files[0], property);
      uploadDocument(event.target.files[0]);
    }
  };

  useEffect(() => {
    setShowProgressBar(false);
    if (documentUploadProgress !== 0) {
      setShowProgressBar(true);
    } else if (showProgressBar) {
      setShowProgressBar(false);
    }
  }, [documentUploadProgress]);

  useEffect(() => {
    if (uploadedDocumentUrl) {
      handleDocumentData(uploadedDocumentUrl, 'documentUrl');
    }
  }, [uploadedDocumentUrl]);

  useEffect(() => {
    if (selectGroupOptions.length && groupId) {
      const createFromGroup = {
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
            name: selectGroupOptions.find(option => option.value === groupId)
              .label,
          },
        ],
      };

      const intialData = { ...documentData };
      intialData['listOfGroups'] = [_.clone(createFromGroup)];

      setDocumentData(intialData);
    }
  }, [selectGroupOptions, groupId]);

  useEffect(() => {
    if (createDocumentSuccess) {
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message: isPublishProcess
          ? documentType === 'banner'
            ? messageConfigs.success.document.PUBLISH_BANNER
            : messageConfigs.success.document.PUBLISH_FEATURED
          : documentType === 'banner'
          ? messageConfigs.success.document.SAVE_BANNER
          : messageConfigs.success.document.SAVE_FEATURED,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () => dispatch({ type: CREATE_DOCUMENT_RESET });
    }
  }, [createDocumentSuccess]);

  useEffect(() => {
    if (createDocumentError) {
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      setIsPublishProcess(false);
      setIsSaveProcess(false);

      return () => dispatch({ type: CREATE_DOCUMENT_RESET });
    }
  }, [createDocumentError]);

  useEffect(() => {
    if (updateDocumentSuccess) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: true,
        message:
          documentType === 'banner'
            ? messageConfigs.success.document.UPDATE_BANNER
            : messageConfigs.success.document.UPDATE_FEATURED,
      });

      return () => dispatch({ type: UPDATE_DOCUMENT_RESET });
    }
  }, [updateDocumentSuccess]);

  useEffect(() => {
    if (updateDocumentError) {
      setIsUpdateProcess(false);
      setIsSubmitSuccessModalOpen({
        isSuccess: false,
        message: messageConfigs.error.COMMON,
      });

      return () => dispatch({ type: UPDATE_DOCUMENT_RESET });
    }
  }, [updateDocumentError]);

  useEffect(() => {
    setTimeout(() => {
      var allPanels = $('.accordion .admin__adddocument--inner-container');
      $('.accordion h3').unbind();
      $('.accordion h3').click(function () {
        $(this)
          .parent()
          .children('.admin__adddocument--inner-container')
          .slideUp();
        $(this).addClass('closed');
        if (
          $(this)
            .parent()
            .children('.admin__adddocument--inner-container')
            .css('display') == 'none'
        ) {
          $(this)
            .parent()
            .children('.admin__adddocument--inner-container')
            .slideDown();
          $(this).removeClass('closed');
        }
        return false;
      });
    }, 0);
  });

  useEffect(() => {
    fetchAllGroups({ type: 'ALL_GROUPS' });
  }, []);

  useEffect(() => {
    if (!Array.isArray(allGroupsFetchData) && allGroupsFetchData && groupInfo) {
      const flattenedGroups = getAllGroupInfoByCurrentUser(
        userName,
        groupInfo,
        allGroupsFetchData
      );

      // Constructing select group dropdown values and removing current group
      const organizeSelectOptions = optionsArray => {
        return optionsArray.map(group => {
          return { value: group._id, label: group.title };
        });
      };

      const selectGroupOptions = organizeSelectOptions(flattenedGroups);

      setSelectGroupOptions(selectGroupOptions);

      fetchReelVideosAssessments({ type: 'INTIALIZE_CREATE_DOCUMENT' });
    }
  }, [allGroupsFetchData]);

  useEffect(() => {
    if (
      documentId &&
      Object.keys(initializeDocumentData).length !== 0 &&
      initializeDocumentData.constructor === Object
    ) {
      findDocumentById({ objectId: documentId, type: 'FIND_DOCUMENT' });
    }
  }, [initializeDocumentData]);

  useEffect(() => {
    if (fetchDocumentToEditData && selectGroupOptions.length && documentId) {
      const {
        _id,
        title,
        author,
        description,
        documentUrl,
        isBanner,
        isDocumentViaUrl,
        isFeatured,
        isNativeBannerAndFeatured,
        isPreviewImgViaUrl,
        listOfReelIdCategoryIdAndGroups,
        previewImgUrl,
        standaloneAssessmentIds,
        tags,
        videoIds,
        download,
        isPublished,
      } = fetchDocumentToEditData;

      const selectedAssessmentsOptions = relatedAssessments
        .filter(({ _id }) => standaloneAssessmentIds.includes(_id))
        .map(assessment => ({
          label: assessment.title,
          value: assessment._id,
        }));

      const selectedVideoOptions = relatedVideos
        .filter(({ _id }) => videoIds.includes(_id))
        .map(video => ({
          label: video.title,
          value: video._id,
        }));

      const selectedGroups = listOfReelIdCategoryIdAndGroups[0].groups.map(
        group => {
          const { _id, dueDate, isMandatory } = group;

          return {
            _id,
            dueDate,
            isMandatory,
            name:
              selectGroupOptions &&
              selectGroupOptions.length &&
              selectGroupOptions.find(option => option.value === _id).label,
          };
        }
      );

      const editDocumentData = { ...documentData };
      editDocumentData['_id'] = _id;
      editDocumentData['title'] = title;
      editDocumentData['description'] = description;
      editDocumentData['author'] = author;
      editDocumentData['isNativeBannerAndFeatured'] = isNativeBannerAndFeatured;
      editDocumentData['isFeatured'] = isFeatured;
      editDocumentData['isBanner'] = isBanner;
      editDocumentData['tags'] = tags.join();
      editDocumentData['previewImgUrl'] = previewImgUrl;
      editDocumentData['documentUrl'] = documentUrl;
      editDocumentData['allowDownload'] = download;
      editDocumentData['isPreviewImgViaUrl'] = isPreviewImgViaUrl;
      editDocumentData['isDocumentViaUrl'] = isDocumentViaUrl;
      editDocumentData['isPublished'] = isPublished;
      editDocumentData['selectedVideos'] = selectedVideoOptions;
      editDocumentData['selectedAssessments'] = selectedAssessmentsOptions;
      editDocumentData['listOfGroups'] = [{ groups: selectedGroups }];

      setDocumentData(editDocumentData);
    }
  }, [fetchDocumentToEditData, selectGroupOptions]);

  const {
    _id,
    tags,
    title,
    author,
    isBanner,
    isFeatured,
    documentUrl,
    description,
    previewImgUrl,
    selectedVideos,
    isDocumentViaUrl,
    isPreviewImgViaUrl,
    selectedAssessments,
    isNativeBannerAndFeatured,
    listOfGroups,
    allowDownload,
  } = documentData;
  const { previewImage, documentFile } = documentDataFiles;

  const handleCreateDocument = (isPublished = false) => {
    const formErrors = validateFields({
      type: 'DOCUMENT',
      formData: documentData._id
        ? {
            title,
            listOfGroups,
          }
        : {
            title,
            listOfGroups,
            documentFileData: isDocumentViaUrl
              ? documentUrl
              : uploadedDocumentUrl,
          },
    });

    if (formErrors) {
      setIsValidationError(formErrors);
      return;
    }

    if (!isValidUrl && isPreviewImgViaUrl) {
      return;
    }

    const formData = new FormData();

    const documentInfo = {
      title,
      description,
      author,
      isEdit: !!documentId,
      isNativeBannerAndFeatured,
      isFeatured: documentType === 'featured',
      isBanner: documentType === 'banner',
      tags: tags.split(','),
      isPublished,
      previewImgUrl,
      download: allowDownload,
      documentUrl: isDocumentViaUrl ? documentUrl : uploadedDocumentUrl || '',
      isPreviewImgViaUrl: isPreviewImgViaUrl,
      isDocumentViaUrl: true,
      standaloneAssessmentIds: selectedAssessments.map(({ value }) => value),
      videoIds: selectedVideos.map(({ value }) => value),
      listOfReelIdCategoryIdAndGroups: listOfGroups.map(value => {
        const groupData = value.groups.map(group => {
          return {
            _id: group._id,
            dueDate: group.dueDate,
            isMandatory: group.isMandatory,
          };
        });

        return { groups: groupData };
      }),
    };

    if (documentData._id) {
      documentInfo._id = documentData._id;
    }

    formData.append(
      'docs',
      new Blob([JSON.stringify(documentInfo)], { type: 'application/json' })
    );

    if (documentData._id) {
      updateDocument(
        `/document/add-document`,
        UPDATE_DOCUMENT_REQUEST,
        UPDATE_DOCUMENT_SUCCESS,
        UPDATE_DOCUMENT_FAIL,
        formData,
        '',
        'POST',
        null,
        'isReelService'
      );
    } else {
      createDocument(
        `/document/add-document`,
        CREATE_DOCUMENT_REQUEST,
        CREATE_DOCUMENT_SUCCESS,
        CREATE_DOCUMENT_FAIL,
        formData,
        '',
        'POST',
        null,
        'isReelService'
      );
    }
  };

  const resetFields = () => {
    const resetState = _.cloneDeep(initialState);

    if (groupId && selectGroupOptions) {
      const createFromGroup = {
        groups: [
          {
            _id: groupId,
            isMandatory: false,
            dueDate: '',
            name: selectGroupOptions.find(option => option.value === groupId)
              .label,
          },
        ],
      };

      resetState['listOfGroups'] = [_.clone(createFromGroup)];
    }

    setDocumentData(resetState);
  };

  return (
    <div>
      <div
        className={`admin__adddocument banner loading-screen-parent ${
          initializeDocumentLoading ? 'setheight' : ''
        }`}
      >
        {initializeDocumentLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="admin__adddocument--wrapper">
              <div className="admin__adddocument--page-title">
                <p className="btn--back" onClick={() => history.goBack()}>
                  {t('Back to Home')}
                </p>
                <h2>
                  {documentId ? 'Edit' : 'Create'}{' '}
                  {capitalizeFirstLetter(documentType)} Document
                </h2>
              </div>
              <div className="admin__adddocument--container">
                <div className="admin__adddocument--left-col">
                  <div className="admin__adddocument--information">
                    <input
                      type="text"
                      id="document-titl"
                      name="document-titl"
                      placeholder={t('Document Title *')}
                      value={title}
                      onChange={e =>
                        handleDocumentData(e.target.value, 'title')
                      }
                    />
                    <input
                      type="text"
                      id="document-description"
                      name="document-description"
                      placeholder={t('Description')}
                      value={description}
                      onChange={e =>
                        handleDocumentData(e.target.value, 'description')
                      }
                    />
                    <input
                      type="checkbox"
                      className="form-input--checkbox"
                      id="enddocument"
                      name="enddocument"
                      checked={isBanner}
                    />
                  </div>
                  <div className="admin__adddocument--reels accordion">
                    <h3>
                      {t('Groups')} <MandatoryMark />
                    </h3>
                    <GroupMultiSelectWithMandatoryDate
                      listOfGroups={listOfGroups}
                      selectGroupOptions={selectGroupOptions}
                      handleChange={handleDocumentData}
                    />
                  </div>
                  <div className="admin__adddocument--assessments accordion">
                    <h3>{t('Related Assessment')}</h3>
                    <div className="admin__adddocument--inner-container">
                      <AssignRelatedMultiSelect
                        title={'Assessments'}
                        selectOptions={relatedAssessments}
                        selectedValues={selectedAssessments}
                        valueType={'selectedAssessments'}
                        handleChange={handleDocumentData}
                      />
                    </div>
                  </div>
                  <div className="admin__adddocument--documents accordion">
                    <h3>{t('Related Videos')}</h3>
                    <div className="admin__adddocument--inner-container">
                      <AssignRelatedMultiSelect
                        title={'Video'}
                        selectOptions={relatedVideos}
                        selectedValues={selectedVideos}
                        valueType={'selectedVideos'}
                        handleChange={handleDocumentData}
                      />
                    </div>
                  </div>
                </div>
                <div className="admin__adddocument--right-col">
                  <PreviewImageView
                    title={'Document Preview Image'}
                    imageSrc={previewImgUrl || previewimg}
                    isImageViaUrl={isPreviewImgViaUrl}
                    imageUrl={previewImgUrl}
                    handleData={handleDocumentData}
                    // handleFileAccept={handleFileAccept}
                    isImageViaUrlPropertyName={'isPreviewImgViaUrl'}
                    previewImgUrlPropertyName={'previewImgUrl'}
                    previewImagePropertyName={'previewImage'}
                    setIsValidUrl={setIsValidUrl}
                    isValidUrl={isValidUrl}
                  />
                  <div className="admin__adddocument--document-theatermode">
                    <p>
                      {t('Upload Document')} <MandatoryMark />
                    </p>
                    <img
                      src={documentFile || documentUrl ? pdfSelected : updocimg}
                      alt=""
                      className="admin__adddocument--document-preview-img"
                      onClick={() => setIsPdfViewModalOpen(true)}
                    />
                    {showProgressBar && (
                      <UploadItem progress={documentUploadProgress} />
                    )}
                    <input
                      type="radio"
                      className="form-input--checkbox"
                      id="enddocument"
                      name="enddocument"
                      checked={!isDocumentViaUrl}
                    />
                    <label
                      onClick={() =>
                        handleDocumentData(false, 'isDocumentViaUrl')
                      }
                    >
                      {t('Upload Documents')}
                    </label>
                    <input
                      type="file"
                      id="upload-preview-vid"
                      name="upload-preview-vid"
                      placeholder={t('File Name')}
                      accept={systemConfig.documentFileTypes}
                      onChange={e => handleFileAccept(e, 'documentFile')}
                    />
                    <input
                      type="radio"
                      className="form-input--checkbox"
                      id="enddocument"
                      name="enddocument"
                      checked={isDocumentViaUrl}
                    />
                    <label
                      onClick={() =>
                        handleDocumentData(true, 'isDocumentViaUrl')
                      }
                    >
                      {t('Upload Document via URL')}
                    </label>
                    <input
                      type="text"
                      id="upload-preview-vid-url"
                      name="upload-preview-vid-url"
                      placeholder={t('URL')}
                      value={documentUrl}
                      onChange={e =>
                        handleDocumentData(e.target.value, 'documentUrl')
                      }
                    />
                  </div>
                  <div className="admin__adddocument--tags">
                    <p>{t('Tags')}</p>
                    <TagSelection
                      tags={tags}
                      handleChange={handleDocumentData}
                    />
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="form-input--checkbox"
                      id="enddocument"
                      name="enddocument"
                      checked={allowDownload}
                    />
                    <label
                      onClick={e =>
                        handleDocumentData(!allowDownload, 'allowDownload')
                      }
                    >
                      {t('Allow Download')}
                    </label>
                  </div>
                </div>
                <div className="admin__adddocument--buttons">
                  {!documentId ? (
                    <>
                      <button
                        className="btn btn--tertiary"
                        onClick={() => resetFields()}
                      >
                        {t('Reset')}
                      </button>
                      <button
                        className={`
                          btn btn--secondary
                          ${
                            createDocumentLoading && isSaveProcess
                              ? 'btn--loader'
                              : ''
                          }
                        `}
                        onClick={() => {
                          setIsSaveProcess(true);
                          handleCreateDocument(false);
                        }}
                      >
                        {t('Save')}
                      </button>
                      {isHavePublishAccess && (
                        <button
                          className={`
                          btn btn--primary btn--last
                          ${
                            createDocumentLoading && isPublishProcess
                              ? 'btn--loader'
                              : ''
                          }
                        `}
                          onClick={() => {
                            setIsPublishProcess(true);
                            handleCreateDocument(true);
                          }}
                        >
                          {t('Publish')}
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className={`btn btn--secondary ${
                        updateDocumentLoading && isUpdateProcess
                          ? 'btn--loader'
                          : ''
                      }`}
                      onClick={() => {
                        setIsUpdateProcess(true);
                        handleCreateDocument(documentData.isPublished);
                      }}
                      disabled={updateDocumentLoading && isUpdateProcess}
                    >
                      {t('Update')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isPdfViewModalOpen &&
        (documentFile !== '' ||
          (isDocumentViaUrl && documentUrl !== '') ||
          (documentId && documentUrl !== '')) && (
          <ViewPDFModal
            pdfUrl={documentUrl}
            handleClose={() => setIsPdfViewModalOpen(false)}
          />
        )}
      {isSubmitSuccessModalOpen && (
        <ProceedMessageModal
          isSuccess={isSubmitSuccessModalOpen.isSuccess}
          message={isSubmitSuccessModalOpen.message}
          handleClose={() => setIsSubmitSuccessModalOpen(null)}
          handleSuccess={() => {
            setIsSubmitSuccessModalOpen(null);
            history.goBack();
          }}
          handleError={() => setIsSubmitSuccessModalOpen(null)}
        />
      )}
      {isValidationError && (
        <ErrorMessageModal
          errorData={isValidationError}
          handleSubmit={() => setIsValidationError(null)}
        />
      )}
    </div>
  );
};

export default CreateDocument;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BannerFeatureSlider from './BannerFeatureSlider';
import VideoSliderWrapper from './VideoSliderWrapper';
import VideoSlider from './VideoSlider';
import DocmentSliderWrapper from './DocumentWrapper';

import { SET_WELCOME_VIDEO_VIEWED } from '../../redux/actionTypes/auth';
import WelcomeVideoModal from './Modal/WelcomeVideoModal';

import move_up from '../../assets/images/svg-images/icon-moveup.svg';
import move_down from '../../assets/images/svg-images/icon-movedown.svg';
import AddReelModal from '../AdminDashboard/Group/Modal/addReelModal';
import usePrivilegesApi from '../../Hooks/usePrivilegesApi';
import { PrivilegeTypes } from '../../Common/UserPrivileges';
import useDeleteApi from '../../Hooks/useDeleteApi';
import ConfirmActionModal from '../../Components/Modal/ConfirmActionModal';
import messageConfigs from '../../Helper/PopupMessageConfig';
import useChangePublishedStatusApi from '../../Hooks/useChangePublishedStatusApi';
import useFetchDataApi from '../../Hooks/useFetchDataApi';
import { ToolTipConfig } from '../../Common/ToolTipConfig';
import { SUSPEND_VIDEO_SUCCESS } from '../../redux/actionTypes/video';
import { SUSPEND_ASSESSMENT_SUCCESS } from '../../redux/actionTypes/assesment';
import { SUSPEND_DOCUMENT_SUCCESS } from '../../redux/actionTypes/documentData';
import { SUSPEND_IMAGE_SUCCESS } from '../../redux/actionTypes/bannerAndFeatured';
import { INTIALIZE_HOME_DATA_SUCCESS } from '../../redux/actionTypes/homeData';
import Loader from '../../Components/Loader/loader';
// const { REACT_APP_CODE } = process.env;

let home_player_background;

const HomeAdmin = ({ history, match }) => {
  const { t } = useTranslation();
  const groupId = match.params.groupId;
  const deleteReelPermission = usePrivilegesApi(
    PrivilegeTypes.REEL.DELETE_REEL,
    groupId
  );
  const deleteVideoPermission = usePrivilegesApi(
    PrivilegeTypes.VIDEO.DELETE_VIDEO,
    groupId
  );
  const editVideoPermission = usePrivilegesApi(
    PrivilegeTypes.VIDEO.EDIT_VIDEO,
    groupId
  );
  const suspenPublishVideoPermission = usePrivilegesApi(
    PrivilegeTypes.VIDEO.SUSPEND_PUBLISH_VIDEO,
    groupId
  );
  const assignUploadToBannerPermission = usePrivilegesApi(
    PrivilegeTypes.BANNER.ASSIGN_UPLOAD_TO_BANNER,
    groupId
  );
  const assignUploadToFeaturedPermission = usePrivilegesApi(
    PrivilegeTypes.FEATURED.ASSIGN_UPLOAD_TO_FEATURED,
    groupId
  );
  const bannerItemDeletePermission = usePrivilegesApi(
    PrivilegeTypes.BANNER.DELETE_BANNER_ITEM,
    groupId
  );
  const bannerItemEditPermission = usePrivilegesApi(
    PrivilegeTypes.BANNER.EDIT_BANNER_ITEM,
    groupId
  );
  const bannerItemSuspendPermission = usePrivilegesApi(
    PrivilegeTypes.BANNER.SUSPEND_BANNER_ITEM,
    groupId
  );
  const featuredItemDeletePermission = usePrivilegesApi(
    PrivilegeTypes.FEATURED.DELETE_FEATURED_ITEM,
    groupId
  );
  const featuredItemEditPermission = usePrivilegesApi(
    PrivilegeTypes.FEATURED.EDIT_FEATURED_ITEM,
    groupId
  );
  const featuredItemSuspendPermission = usePrivilegesApi(
    PrivilegeTypes.FEATURED.SUSPEND_FEATURED_ITEM,
    groupId
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isAddReelModalOpen, setIsAddReelModalOpen] = useState(null);
  const [documentReels, setDocumentReels] = useState([]);
  const [listBanners, setListBanners] = useState([]);
  const [listFeatured, setListFeatured] = useState([]);
  const [mandatory, setMandatory] = useState([]);
  const [videoReels, setVideoReels] = useState([]);
  const [favourite, setFavourite] = useState([]);
  const [combinedWatchLater, setCombinedWatchLater] = useState([]);
  const [isBannerFeatured, setIsBannerFeatured] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(
    null
  );
  const {
    isWelcomeVideoViewed,
    isAdminView,
    userData,
  } = useSelector(state => state?.auth);
  const {
    welcomeVideoUrl,
    userName,
    welcomeVideoText,
    preferredUsername,
  } = userData;
  const isSmoothTransitionModeOn = useSelector(
    state => state.video && state.video.isSmoothTransitionModeOn
  );

  const {
    intializehomeDataData,
    intializehomeDataLoading,
    videoReelsForLandingPageData,
    videoReelsForLandingPageLoading,
    documentReelsForLandingPageData,
    documentReelsForLandingPageLoading,
  } = useSelector(state => state?.homeData);

  const { deleteReelSuccess } = useSelector(state => state?.reel);
  const {
    deleteVideoSuccess,
    suspendVideoLoading,
    suspendVideoSuccess,
  } = useSelector(state => state?.video);
  const {
    deleteImageSuccess,
    suspendImageLoading,
    suspendImageSuccess,
  } = useSelector(state => state?.bannerAndFeatured);
  const {
    deleteDocumentSuccess,
    suspendDocumentLoading,
    suspendDocumentSuccess,
  } = useSelector(state => state?.documentData);
  const {
    deleteStandaloneAssessmentSuccess,
    remindLaterSuccess,
    suspendAssessmentLoading,
    suspendAssessmentSuccess,
  } = useSelector(state => state?.assessment);

  const [fetchData] = useFetchDataApi();
  const [updateIsPublishedStatus] = useChangePublishedStatusApi();
  const [deleteFromAdminHomeScreen] = useDeleteApi();
  const dispatch = useDispatch();

  const getHomeData = () => fetchData({ groupId, type: 'ADMIN_HOME_INIT' });
  const getHomeVideoReelData = () =>
    fetchData({ groupId, type: 'ADMIN_HOME_VIDEO_REELS' });
  const getHomeDocumentReelData = () =>
    fetchData({ groupId, type: 'ADMIN_HOME_DOCUMENT_REELS' });

  // Clearing init data on page unmount
  useEffect(() => {
    return () =>
      dispatch({
        type: INTIALIZE_HOME_DATA_SUCCESS,
        payload: null,
      });
  }, []);

  useEffect(() => {
    if (remindLaterSuccess) {
      getHomeData();
    }
  }, [remindLaterSuccess]);
  useEffect(() => {
    getHomeData();
  }, [groupId]);

  useEffect(() => {
    if (
      isBannerFeatured &&
      (suspendAssessmentSuccess ||
        suspendVideoSuccess ||
        suspendDocumentSuccess ||
        suspendImageSuccess)
    ) {
      setIsBannerFeatured(false);
      getHomeData();
    }

    return () => {
      dispatch({
        type: SUSPEND_ASSESSMENT_SUCCESS,
        payload: null,
      });
      dispatch({
        type: SUSPEND_VIDEO_SUCCESS,
        payload: null,
      });
      dispatch({
        type: SUSPEND_DOCUMENT_SUCCESS,
        payload: null,
      });
      dispatch({
        type: SUSPEND_IMAGE_SUCCESS,
        payload: null,
      });
    };
  }, [
    suspendAssessmentSuccess,
    suspendVideoSuccess,
    suspendDocumentSuccess,
    suspendImageSuccess,
  ]);

  useEffect(() => {
    if (
      intializehomeDataData &&
      Object.keys(intializehomeDataData).length !== 0 &&
      intializehomeDataData.constructor === Object
    ) {
      const {
        documentReels,
        videoReels,
        listBanners,
        listFeatured,
        mandatory,
        favourite,
        combinedWatchLater,
      } = intializehomeDataData;

      setDocumentReels(documentReels);
      setListBanners(listBanners);
      setListFeatured(listFeatured);
      setMandatory(mandatory);
      setVideoReels(videoReels);
      setFavourite(favourite);
      setCombinedWatchLater(combinedWatchLater);
    }
  }, [intializehomeDataData]);

  useEffect(() => {
    if (videoReelsForLandingPageData) {
      setVideoReels(videoReelsForLandingPageData);
    }
  }, [videoReelsForLandingPageData]);

  useEffect(() => {
    if (documentReelsForLandingPageData) {
      setDocumentReels(documentReelsForLandingPageData);
    }
  }, [documentReelsForLandingPageData]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
    const root = document.getElementById('root');
    root.className = 'homepage';
  }, []);

  useEffect(() => {
    if (deleteReelSuccess) {
      setIsDeleteConfirmModalOpen(null);
      getHomeData();
    }
  }, [deleteReelSuccess]);

  useEffect(() => {
    if (deleteVideoSuccess) {
      setIsDeleteConfirmModalOpen(null);
      getHomeData();
    }
  }, [deleteVideoSuccess]);

  useEffect(() => {
    if (deleteDocumentSuccess) {
      setIsDeleteConfirmModalOpen(null);
      getHomeData();
    }
  }, [deleteDocumentSuccess]);

  useEffect(() => {
    if (deleteImageSuccess) {
      setIsDeleteConfirmModalOpen(null);
      getHomeData();
    }
  }, [deleteImageSuccess]);

  useEffect(() => {
    if (deleteStandaloneAssessmentSuccess) {
      setIsDeleteConfirmModalOpen(null);
      getHomeData();
    }
  }, [deleteStandaloneAssessmentSuccess]);

  const hanldeIsPublishedStatus = (type, _id, itemPosition) => {
    setIsBannerFeatured(
      itemPosition === 'BANNER' || itemPosition === 'FEATURED'
    );
    updateIsPublishedStatus({
      _id,
      type,
      itemPosition,
      viewType: 'ADMIN_HOME',
    });
  };

  const handleDelete = (data, isBannerFeatured, isMandatory) => {
    setIsBannerFeatured(isBannerFeatured);

    let deleteRequestData = {
      groupId,
    };

    if (isMandatory) {
      deleteRequestData = {
        ...deleteRequestData,
        isMandatory,
      };
    }

    switch (data.type) {
      case 'VIDEO':
        deleteRequestData = {
          ...deleteRequestData,
          reelId: data.reelId,
          categoryId: data.categoryId,
          vid_doc_standalone_Id: data.id,
          type: 'VIDEO',
        };
        break;

      case 'ASSESSMENT':
        deleteRequestData = {
          ...deleteRequestData,
          vid_doc_standalone_Id: data.id,
          type: 'STANDALONE_ASSESSMENT',
        };
        break;

      case 'STANDALONE_ASSESSMENT':
        deleteRequestData = {
          ...deleteRequestData,
          vid_doc_standalone_Id: data.id,
          type: 'STANDALONE_ASSESSMENT',
        };
        break;

      case 'DOCUMENT':
        deleteRequestData = {
          ...deleteRequestData,
          reelId: data.reelId,
          categoryId: data.categoryId,
          vid_doc_standalone_Id: data.id,
          type: 'DOCUMENT',
        };
        break;

      case 'IMAGE':
        deleteRequestData = {
          ...deleteRequestData,
          id: data.id,
          type: 'IMAGE',
        };
        break;

      case 'REEL':
        deleteRequestData = {
          ...deleteRequestData,
          id: data.id,
          type: 'REEL',
        };
        break;

      default:
        break;
    }

    setIsDeleteConfirmModalOpen(deleteRequestData);
  };

  const handleBannerItemEdit = (bannerItemType, bannerOrFeatured, id) => {
    switch (bannerItemType) {
      case 'DOCUMENT':
        history.push(
          `/edit-document/${
            bannerOrFeatured !== '' ? `${bannerOrFeatured}/` : ''
          }${id}`
        );
        break;

      case 'VIDEO':
        history.push(
          `/edit-video/${
            bannerOrFeatured !== '' ? `${bannerOrFeatured}/` : ''
          }${id}`
        );
        break;

      case 'ASSESSMENT':
        history.push(
          `/edit-assessment/${
            bannerOrFeatured !== '' ? `${bannerOrFeatured}/` : ''
          }${id}`
        );
        break;

      case 'IMAGE':
        history.push(
          `/edit-image/${
            bannerOrFeatured !== '' ? `${bannerOrFeatured}/` : ''
          }${id}`
        );
        break;

      default:
        break;
    }
  };

  const renderDeleteConfirmMessage = data => {
    switch (data.type) {
      case 'VIDEO':
        return data.isMandatory
          ? messageConfigs.mandatoryReelRemoveConfirm.video
          : messageConfigs.deleteConfirm.video;

      case 'ASSESSMENT':
        return data.isMandatory
          ? messageConfigs.mandatoryReelRemoveConfirm.assessment
          : messageConfigs.deleteConfirm.assessment;

      case 'DOCUMENT':
        return data.isMandatory
          ? messageConfigs.mandatoryReelRemoveConfirm.document
          : messageConfigs.deleteConfirm.document;

      case 'IMAGE':
        return data.isMandatory
          ? messageConfigs.mandatoryReelRemoveConfirm.image
          : messageConfigs.deleteConfirm.image;

      case 'REEL':
        return messageConfigs.deleteConfirm.reel;

      case 'STANDALONE_ASSESSMENT':
        return data.isMandatory
          ? messageConfigs.mandatoryReelRemoveConfirm.assessment
          : messageConfigs.deleteConfirm.assessment;

      default:
        return messageConfigs.deleteConfirm.title;
    }
  };

  const isShowLoading =
    intializehomeDataLoading ||
    videoReelsForLandingPageLoading ||
    documentReelsForLandingPageLoading ||
    suspendDocumentLoading ||
    suspendImageLoading ||
    suspendAssessmentLoading ||
    suspendVideoLoading;

  return isShowLoading ? (
    <div className="homepage">
      <div className="container">
        <Loader />
      </div>
    </div>
  ) : (
    <div>
      <div className="homepage">
        <div className="container">
          <BannerFeatureSlider
            history={history}
            bannerFeatureData={listBanners}
            groupId={groupId}
            isBanner
            assignUploadPermission={assignUploadToBannerPermission}
            sliderItemDeletePermission={bannerItemDeletePermission}
            sliderItemEditPermission={bannerItemEditPermission}
            sliderItemSuspendPermission={bannerItemSuspendPermission}
            hanldeIsPublishedStatus={(type, id) =>
              hanldeIsPublishedStatus(type, id, 'BANNER')
            }
            handleDelete={(type, id) => handleDelete({ type, id }, true)}
            handleBannerItemEdit={(type, itemType, id) =>
              handleBannerItemEdit(type, itemType, id)
            }
          />
          {isAdminView ? (
            <button
              className="hompage--advance-settings btn btn--primary"
              onClick={() =>
                history.push(`/group-dashboard/${groupId}/group-details`)
              }
              title={t(ToolTipConfig.adminHome.adminSettings)}
            >
              {t('Admin Settings')}
            </button>
          ) : null}
          {!isAdminView && !isWelcomeVideoViewed && welcomeVideoUrl && (
            <WelcomeVideoModal
              welcomeVideoText={welcomeVideoText}
              welcomeVideoUrl={welcomeVideoUrl}
              userName={preferredUsername}
              onHandleCancel={() => {
                dispatch({
                  type: SET_WELCOME_VIDEO_VIEWED,
                });
              }}
            />
          )}
          {mandatory.length ? (
            <VideoSlider
              title="Mandatory Training"
              videos={mandatory}
              itemKey="Mandatory Training"
              groupId={groupId}
              isMandatoryReel
              deleteVideoPermission={deleteVideoPermission}
              editVideoPermission={editVideoPermission}
              suspenPublishVideoPermission={suspenPublishVideoPermission}
              hanldeIsPublishedStatus={(type, id) =>
                hanldeIsPublishedStatus(type, id, 'MANDATORY')
              }
              handleDelete={data => handleDelete(data, false, true)}
            />
          ) : null}

          <VideoSliderWrapper
            videoReels={videoReels}
            groupId={groupId}
            handleDelete={data => handleDelete(data, false)}
            deleteReelPermission={deleteReelPermission}
            deleteVideoPermission={deleteVideoPermission}
            editVideoPermission={editVideoPermission}
            suspenPublishVideoPermission={suspenPublishVideoPermission}
            hanldeIsPublishedStatus={(type, id) =>
              hanldeIsPublishedStatus(type, id, 'VIDEO_REELS')
            }
          />
          <p
            className="addanother homepageadmin"
            onClick={() => setIsAddReelModalOpen('VIDEO')}
          >
            {t('Add Reel')}
          </p>
          {!groupId && combinedWatchLater.length ? (
            <VideoSlider
              title="Finish Later"
              videos={combinedWatchLater}
              itemKey="Finish Later"
              groupId={groupId}
            />
          ) : null}
          {!groupId && favourite.length ? (
            <VideoSlider
              title="Favourites"
              videos={favourite}
              itemKey="favourite"
              groupId={groupId}
            />
          ) : null}
          <BannerFeatureSlider
            history={history}
            bannerFeatureData={listFeatured}
            groupId={groupId}
            assignUploadPermission={assignUploadToFeaturedPermission}
            sliderItemDeletePermission={featuredItemDeletePermission}
            sliderItemEditPermission={featuredItemEditPermission}
            sliderItemSuspendPermission={featuredItemSuspendPermission}
            hanldeIsPublishedStatus={(type, id) =>
              hanldeIsPublishedStatus(type, id, 'FEATURED')
            }
            handleDelete={(type, id) => handleDelete({ type, id }, true)}
            handleBannerItemEdit={(type, itemType, id) =>
              handleBannerItemEdit(type, itemType, id)
            }
          />
          <DocmentSliderWrapper
            documentReels={documentReels}
            groupId={groupId}
            handleDelete={id => handleDelete({ type: 'REEL', id }, false)}
            deleteReelPermission={deleteReelPermission}
          />
          <p
            className="addanother homepageadmin"
            onClick={() => setIsAddReelModalOpen('DOCUMENT')}
          >
            {t('Add Document Reel')}
          </p>
        </div>
      </div>
      {isAddReelModalOpen && (
        <AddReelModal
          reelType={isAddReelModalOpen}
          groupId={groupId}
          handleClose={() => setIsAddReelModalOpen(null)}
          handleSuccess={
            isAddReelModalOpen === 'VIDEO'
              ? () => getHomeVideoReelData()
              : () => getHomeDocumentReelData()
          }
        />
      )}
      {isDeleteConfirmModalOpen && (
        <ConfirmActionModal
          title={
            isDeleteConfirmModalOpen.isMandatory
              ? messageConfigs.mandatoryReelRemoveConfirm.title
              : messageConfigs.deleteConfirm.title
          }
          message={renderDeleteConfirmMessage(isDeleteConfirmModalOpen)}
          handleSuccess={() =>
            deleteFromAdminHomeScreen(isDeleteConfirmModalOpen)
          }
          handleClose={() => setIsDeleteConfirmModalOpen(null)}
        />
      )}
    </div>
  );
};

export default HomeAdmin;

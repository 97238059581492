import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { SET_CURRENT_GROUP_ID } from '../redux/actionTypes/Group';

function AuthenticatdRoute({
  component: Component,
  isAuthenticated,
  user,
  isAdminView,
  isCommon,
  match,
  ...rest
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (rest?.computedMatch?.params?.groupId) {
      dispatch({
        type: SET_CURRENT_GROUP_ID,
        payload: rest?.computedMatch?.params?.groupId,
      });
    } else {
      dispatch({
        type: SET_CURRENT_GROUP_ID,
        payload: null,
      });
    }
  }, [rest?.computedMatch?.params?.groupId]);

  const findRoute = props => {
    if (isAdminView && user && !isCommon) {
      return (
        <Redirect
          to={{
            pathname: '/node-tree',
          }}
        />
      );
    } else if (!isAdminView && !user && !isCommon) {
      return (
        <Redirect
          to={{
            pathname: '/home',
          }}
        />
      );
    } else {
      return <Component {...props} />;
    }
  };
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          findRoute(props)
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
}

export default AuthenticatdRoute;

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import useUpdateApi from './useUpdateApi';
import { systemConfig } from '../Common/SystemConstConfigs';
const { notificationViewType } = systemConfig;

const useNotificationRoute = () => {
  const [notificationData, setNotificationData] = useState();
  const [updateNotificationStatus] = useUpdateApi();
  const history = useHistory();

  const redirectTo = redirectUrl => {
    history.push(redirectUrl);
  };
  const redirectManageRequest = id => {
    history.push({
      pathname: `/rms/manage-request/requestform`,
      state: { trainingRequestId: id },
    });
  };
  const redirectManageRequestHistory = id => {
    history.push({
      pathname: `/rms/manage-request`,
      state: { searchText: id || '' },
    });
  };

  useEffect(() => {
    if (notificationData) {
      const { id, userId, data, contentType, eventType, notificationType } =
        notificationData;

      const {
        contentIds = [],
        reelIds = [],
        groupIds = [],
        videoIds = [],
        requestId = null,
      } = data;

      // Updating notification viewed status
      updateNotificationStatus({
        type: 'UPDATE_NOTIFICATION_STATUS',
        notificationIds: [
          {
            _id: id,
          },
        ],
        userId,
        notificationType,
      });

      if (notificationType === 'USER_NOTIFICATION') {
        switch (eventType) {
          case 'MANDATORY_REEL_CREATED':
          case 'MANDATORY_REEL_EDITED':
          case 'DEADLINE_EXCEEDED':
          case 'UPCOMING_DEADLINE':
            switch (contentType) {
              case 'VIDEO':
                redirectTo(`/theatre/${contentIds[0]}`);
                break;

              case 'ASSESSMENT':
                redirectTo(`/assessment/${contentIds[0]}`);
                break;

              case 'DOCUMENT':
                redirectTo(`/reel-bulk-view/video/reel/Mandatory Training}`);
                break;

              default:
                break;
            }
            break;

          case 'FINISH_LATER':
            switch (contentType) {
              case 'VIDEO':
                redirectTo(`/theatre/${contentIds[0]}`);
                break;

              case 'ASSESSMENT':
                redirectTo(`/assessment/${contentIds[0]}`);
                break;

              case 'DOCUMENT':
                redirectTo(`/reel-bulk-view/video/reel/Finish Later}`);
                break;

              default:
                break;
            }
            break;

          case 'REEL_ADDED_TO_GROUP':
            redirectTo(`/reel-bulk-view/video/${reelIds[0]}}`);
            break;

          case 'VIDEO_CREATED':
            redirectTo(`/theatre/${contentIds[0]}`);
            break;

          case 'DOCUMENT_CREATED':
            redirectTo(`/reel-bulk-view/document/${reelIds[0]}`);
            break;

          case 'ASSESSMENT_CREATED':
            redirectTo(`/assessment/${contentIds[0]}`);
            break;

          case 'CONTENT_CREATED':
            switch (contentType) {
              case 'VIDEO':
                redirectTo(`/theatre/${contentIds[0]}`);
                break;

              case 'ASSESSMENT':
                if (videoIds.length) {
                  redirectTo(`/theatre/${videoIds[0]}`);
                } else {
                  redirectTo(`/assessment/${contentIds[0]}`);
                }
                break;

              case 'DOCUMENT':
                redirectTo(`/reel-bulk-view/document/${reelIds[0]}`);
                break;

              default:
                break;
            }
            break;

          case 'FEATURED_REEL_CREATED':
            redirectTo(`/home`);
            break;

          case 'USER_REMOVED_FROM_GROUP':
            break;

          case 'USER_ASSIGNED_TO_GROUP':
            break;

          default:
            break;
        }
      } else if (notificationType === 'ADMIN_NOTIFICATION') {
        switch (eventType) {
          case 'USER_ASSIGNED_TO_GROUP':
            redirectTo(`/home/${groupIds[0]}`);
            break;

          case 'GROUP_CREATED':
            redirectTo(`/group-dashboard/${groupIds[0]}/group-details`);
            break;

          case 'SELF_SIGNUP_REQUESTED':
            redirectTo(`/search-users/signup`);
            break;

          default:
            break;
        }
      } else if (notificationType === notificationViewType.rms) {
        switch (eventType) {
          case 'RMS_TRAINING_REQUEST_APPROVAL_LEVEL_STATUS_UPDATED':
          case 'RMS_TRAINING_REQUEST_WITHDRAWN':
          case 'RMS_TRAINING_REQUEST_STATUS_UPDATED':
          case 'RMS_TRAINING_REQUEST_INITIATED':
            requestId && redirectManageRequest(requestId);
            break;
          case 'RMS_TRAINING_REQUEST_COMPLETED':
          case 'RMS_GROUP_TRAINING_REQUEST_INITIATED':
            redirectManageRequestHistory(requestId);
            break;
          case 'RMS_TRAINING_REQUEST_BOND_APPLIED':
          case 'RMS_TRAINING_REQUEST_BOND_ACTIVATED':
            break;
          default:
            break;
        }
      }
    }
  }, [notificationData]);

  const setData = notificationData => setNotificationData(notificationData);

  return [setData];
};

export default useNotificationRoute;

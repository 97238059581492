import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dropdown from 'react-dropdown';
import useApi from '../../../../../Hooks/useApi';

export default function SaveChangesModal({ handleClose, handleSubmit }) {
  const { addAssessmentSuccess, addAssessmentError } = useSelector(
    state => state?.assessment
  );
  const { t } = useTranslation();
  useEffect(() => {
    if (addAssessmentSuccess) {
      handleClose();
    } else if (addAssessmentError) {
      handleClose();
    }
  }, [addAssessmentSuccess, addAssessmentError]);
  return (
    <div className="reel-view--popup addreel">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('Save Changes')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={handleClose}
          >
            &times;
          </a>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{t('Do you want Save Changes')}</label>
          </div>
          <div className="reel-view--popup-row">
            <button className="btn btn--secondary" onClick={handleClose}>
              {t('Cancel')}
            </button>
            <button className="btn btn--primary" onClick={handleSubmit}>
              {t('Save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import { combineReducers } from 'redux'; // Import Redux
import { connectRouter } from 'connected-react-router';
import video from './videoReducer';
import auth from './authReducer';
import assessment from './assessmentReducer';
import notification from './notificationReducer';
import user from './userReducer';
import group from './groupReducer';
import reel from './reelReducer';
import documentData from './documentDataReducer';
import category from './categoryReducer';
import bannerAndFeatured from './bannerAndFeaturedReducer';
import homeData from './homeDataReducer';
import websocket from './webSocketReducer';
import rmsReducers from '../../rms/redux/reducers';

export default history =>
  combineReducers({
    router: connectRouter(history),
    video,
    auth,
    assessment,
    notification,
    user,
    group,
    reel,
    documentData,
    category,
    bannerAndFeatured,
    homeData,
    websocket,
    ...rmsReducers,
  });
